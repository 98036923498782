import React from 'react';
import { FormattedMessage } from 'react-intl';
import { nFormatter } from '../../Helpers';
import './style.scss';
import { ReactComponent as StartDateIcon } from '../../assets/images/calendar.svg';
import moment from 'moment';
import { ReactComponent as EndDateIcon } from '../../assets/images/calendar-end.svg';
import { ReactComponent as ManagerIcon } from '../../assets/images/manager.svg';

function Collapse(props) {
  const { item, handleRoute, locale, theme } = props;
  return (
    <section className="collaps-section">
      <div className="collaps-content cursor" onClick={handleRoute}>
        <div className="text-content">{item.Description}</div>
        <div className="info d-flex">
          <div className="start">
            <div className={`${locale === 'ar' ? 'rtl' : ''} icon`}>
              <StartDateIcon width="17px" height="17px" fill={theme.ThemeColor.item.Text} />
              <span className="project-details__label">
                <FormattedMessage id="program.tap.start-date" />:{' '}
                <span className="text normal-text project-details__label-value">
                  {moment(item.StartDate).format('DD-MMM-YYYY')}
                </span>
              </span>
            </div>
          </div>
          <div className="end">
            <div className={`${locale === 'ar' ? 'rtl' : ''} icon`}>
              <EndDateIcon width="17px" height="17px" fill={theme.ThemeColor.item.Text} />
              <span className="project-details__label">
                <FormattedMessage id="program.tap.finished-date" />:{' '}
                <span className="text normal-text project-details__label-value">
                  {moment(item.FinishDate).format('DD-MMM-YYYY')}
                </span>
              </span>
            </div>
          </div>
          <div className="manager d-flex">
            <div className={`${locale === 'ar' ? 'rtl' : ''} icon`}>
              <ManagerIcon
                width="17px"
                height="17px"
                fill={theme.ThemeColor.item.Text}
                stroke={theme.ThemeColor.item.Text}
              />
              <span className="project-details__label">
                <FormattedMessage id="program.tap.manager" />:{' '}
                <span className="text normal-text project-details__label-value">{item.Manager.DisplayName}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="footer-content">
          <div className="footer-items">
            <div className="budget">
              <div>
                {' '}
                <FormattedMessage id="portfolio.total-budget" />
              </div>
              <span className="budget-span" style={{ color: theme.ThemeColor.item.Text }}>
                {' '}
                {nFormatter(item.Budget, 1, locale)} <FormattedMessage id="common.SARShort" />
              </span>
            </div>
            <div className="risks">
              <div>
                <FormattedMessage id="portfolio.risks" />
              </div>
              <span className="risks-span">{item.Risks}</span>
            </div>
            <div className="issues">
              <div>
                <FormattedMessage id="portfolio.issues" />
              </div>
              <span className="issues-span">{item.Issues}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Collapse;
