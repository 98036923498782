import ProgramsAPIController from '../providers/controllers/ProgramsAPIController';

export const GET_ALL_DELIVERABLES = 'GET_ALL_DELIVERABLES';
export const POST_DELIVERABLE_START = 'POST_DELIVERABLE_START';
export const POST_DELIVERABLE = 'POST_DELIVERABLE';
export const DELETE_DELIVERABLE_START = 'DELETE_DELIVERABLE_START';
export const DELETE_DELIVERABLE = 'DELETE_DELIVERABLE';
export const START_DELIVERABLE_LOADING = 'START_DELIVERABLE_LOADING';
export const DELETE_DELIVERABLE_DATA = 'DELETE_DELIVERABLE_DATA';
export const GET_DELIVERABLE_BY_ID = 'GET_DELIVERABLE_BY_ID';
export const START_DELIVERABLE_BY_ID_LOADING = 'START_DELIVERABLE_BY_ID_LOADING';

const getAllDeliverablesAction = payload => {
  return {
    type: GET_ALL_DELIVERABLES,
    payload,
  };
};
const getDeliverableByIDAction = payload => {
  return {
    type: GET_DELIVERABLE_BY_ID,
    payload,
  };
};

const postDeliverableStartAction = () => {
  return {
    type: POST_DELIVERABLE_START,
  };
};

const postDeliverableAction = (payload, id) => {
  return {
    type: POST_DELIVERABLE,
    payload,
    id,
  };
};

const deleteDeliverableStartAction = () => {
  return {
    type: DELETE_DELIVERABLE_START,
  };
};

const deleteDeliverableAction = payload => {
  return {
    type: DELETE_DELIVERABLE,
    payload,
  };
};

const startDeliverableLoading = () => {
  return {
    type: START_DELIVERABLE_LOADING,
  };
};
const startDeliverableByIDLoading = () => {
  return {
    type: START_DELIVERABLE_BY_ID_LOADING,
  };
};

export const deleteDeliverablesData = payload => {
  return {
    type: DELETE_DELIVERABLE_DATA,
    payload,
  };
};
export const getAllDeliverableByID = (code, id) => {
  return dispatch => {
    dispatch(startDeliverableByIDLoading());
    new ProgramsAPIController().getDeliverableById(code, id).then(res => {
      dispatch(getDeliverableByIDAction(res.Data));
    });
  };
};

export const getAllDeliverables = code => {
  return dispatch => {
    dispatch(startDeliverableLoading());
    new ProgramsAPIController().getAllDeliverables(code).then(res => {
      dispatch(getAllDeliverablesAction(res.Data));
    });
  };
};

export const postDeliverable = (body, cb) => {
  return dispatch => {
    dispatch(postDeliverableStartAction());

    new ProgramsAPIController().postDeliverable(body).then(res => {
      dispatch(postDeliverableAction(body, res.Data));
      cb && cb();
    });
  };
};

export const deleteDeliverable = (code, id, cb) => {
  return dispatch => {
    dispatch(deleteDeliverableStartAction());
    new ProgramsAPIController().deleteDeliverable(code, id).then(() => {
      dispatch(deleteDeliverableAction({ id }));
      cb && cb();
    });
  };
};
