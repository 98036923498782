import React, { Component } from 'react';

import './style.scss';

class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.settings.error || false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { error } = state;
    const { settings } = props;
    if (settings.error !== error) {
      return {
        ...state,
        error: settings.error,
      };
    }
    return state;
  }

  defaultValidation = value => {
    return !value;
  };

  showError(show) {
    this.setState({ error: show });
  }

  render() {
    const {
      props: {
        settings: { value, onChangeAction, placeholder, validation, validateOnExite, min, max, disabled, onClick },
      },
      state: { error },
    } = this;
    const _validation = validation || this.defaultValidation;

    return (
      <div className="input-container">
        <input
          className={`input-control ${error ? 'error' : ''}`}
          value={value}
          onChange={e => onChangeAction(e)}
          type="number"
          min={min}
          max={max}
          placeholder={placeholder}
          onBlur={validateOnExite ? () => this.showError(_validation(value)) : null}
          disabled={disabled}
          onClick={onClick}
        />
      </div>
    );
  }
}

export default NumberInput;
