import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Table from '../../../Table';
import Panel from '../../../Panel';
import Controllers from '../Controllers';

import { getPhaseApprovals, postPhaseStatus } from '../../../../actions/tasksCenter';

import './styles.scss';

class PhaseApprovals extends React.Component {
  componentDidMount() {
    this.getPhaseApprovals();
  }

  getPhaseApprovals = () => {
    const { _getPhaseApprovals, phaseApprovals } = this.props;
    if (!phaseApprovals.length) {
      _getPhaseApprovals();
    }
  };

  getColumns = () => {
    const { intl, _postPhaseStatus, themeColor } = this.props;
    const titleKey = intl.locale === 'ar' ? 'TitleArabic' : 'Title';

    return [
      {
        accessor: '', // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.title' }),
        Cell: data => <p>{data.value[titleKey]}</p>,
      },
      {
        accessor: '',
        Header: '',
        Cell: data => (
          <Controllers
            _postTaskStatus={_postPhaseStatus}
            data={data.value}
            themeColor={themeColor}
            type="projectsTasks"
            phase
          />
        ),
      },
    ];
  };

  render() {
    const { phaseApprovals, loaders, intl, themeColor, history } = this.props;

    return (
      <div className="projectsTasks__container">
        <Panel
          title={intl.formatMessage({ id: 'taskCenter.phase-approvals' })}
          count={phaseApprovals.length}
          isCollapsable
          render={() => (
            <Table
              columns={this.getColumns()}
              data={phaseApprovals}
              showPagination={false}
              sortable={false}
              rowBorderColor={themeColor}
              isLoading={loaders.phaseApprovals}
              numOfLoadingRows={3}
              onRowClick={data => history.push(`/project/${data.original.ProjectUID}/phase-gate`)}
            />
          )}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  _getPhaseApprovals: getPhaseApprovals,
  _postPhaseStatus: postPhaseStatus,
};

const mapStateToProps = ({ tasksCenter, theme }) => ({
  phaseApprovals: tasksCenter.approvals.phaseApprovals,
  loaders: tasksCenter.loaders,
  themeColor: theme.ThemeColor.item.Text,
});

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PhaseApprovals)));
