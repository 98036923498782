import React from 'react';
import { ReactComponent as FolderClosed } from '../../../assets/images/folder-closed.svg';

import './style.scss';

export default ({ themeColor }) => (
  <div className="folderLoader__container">
    <FolderClosed fill={themeColor} />
    <p className="animated-background" />
  </div>
);
