import React from 'react';
import { FormattedMessage } from 'react-intl';

function Header() {
  return (
    <div className="change-form-request-impacts__header">
      <span className="factor">
        <FormattedMessage id="processes.change-request-factor" />
      </span>
      <span>
        <FormattedMessage id="processes.change-request-description" />
      </span>
    </div>
  );
}

export default Header;
