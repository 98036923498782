import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { formattedDate, getProjectLocalizedName } from '../../../Helpers';

import Loader from './Loader';

function InitiativeInfo({ intl, locale, loading, program }) {
  const footerData = [
    {
      id: 1,
      label: intl.formatMessage({ id: 'common.startDate' }),
      value: formattedDate(program.InitiativeStartDate),
      className: 'date',
    },
    {
      id: 2,
      label: intl.formatMessage({ id: 'common.finishDate' }),
      value: formattedDate(program.InitiativeFinishDate),
      className: 'date',
    },
    {
      id: 3,
      label: intl.formatMessage({ id: 'dashboard.program-name' }),
      value: getProjectLocalizedName(program, locale),
    },
    {
      id: 4,
      label: intl.formatMessage({ id: 'dashboard.program-manager' }),
      value: program.Manager?.DisplayName,
    },

    // {
    //   id: 6,
    //   label: intl.formatMessage({ id: 'dashboard.announced' }),
    //   value: intl.formatMessage({ id: program.CustomFields?.IsAnnounced === 'True' ? 'common.yes' : 'common.no' }),
    // },
  ];

  return loading ? (
    <Loader />
  ) : (
    <div className="initiative-details__info">
      <div className="row">
        <div className="details-section">
          <span className="title text">
            <FormattedMessage id="dashboard.initiative-details" />
          </span>
          <span className="content text">{program.Description}</span>
        </div>
      </div>
      <div className="row footer">
        {footerData.map(el => (
          <div key={el.id} className="footer-item">
            <span className="label">{el.label}</span>
            <span className="value">{el.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ language }) => ({
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps)(InitiativeInfo));
