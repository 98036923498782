import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumb';
import IDList from './IDList';
import IDAdd from './IDAdd';
import IDDetails from './IDDetails';
import routes from '../../../routes';
import './style.scss';

const Interdependencies = () => {
  return (
    <div className="interdependencies">
      <div className="bread">
        <Breadcrumbs title="common.interdependencies" hideCreateButton />
      </div>
      <div className="id-content">
        <Switch>
          <Route path={routes.add(routes.interdependencies)} component={IDAdd} />
          <Route path={routes.details(routes.interdependencies)} component={IDDetails} />
          <Route path="/" component={IDList} />
        </Switch>
      </div>
    </div>
  );
};

export default Interdependencies;
