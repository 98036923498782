import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// import { ReactComponent as PortfolioIcon } from '../../assets/images/portfolio.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/information.svg';
import { getStatusColor, nFormatter, getProjectLocalizedName, addToast } from '../../Helpers';
import routes from '../../routes';
import Badge from '../Badge';
import ProgressBar from '../ProgressBar';
import './style.scss';

class EntityCard extends Component {
  onWorkspaceClick = () => {
    const { item, history, isProgram } = this.props;

    if (item.DirectAccess && !isProgram) {
      history.push(routes.portfolio(item.Code));
    } else if (isProgram && item.DirectAccess) {
      history.push(routes.program(item.Code));
    } else {
      addToast.error('portfolio.workspace-permission', {
        toastId: 'direct-access-error',
      });
    }
  };

  handleRoute(codeItem) {
    const { history, onClick, item, inDashboard, code, isProgram } = this.props;
    if (onClick) {
      onClick(item);
    } else if (inDashboard && !isProgram) {
      history.push(routes.dashboardInitiative(codeItem));
    } else if (isProgram) {
      history.push(`/dashboard/initiatives/${code}/${codeItem}`);
    } else {
      history.push(routes.programs(codeItem));
    }
  }

  render() {
    const { theme, item, language } = this.props;
    return (
      <div
        className="entity-card cursor"
        onClick={() => this.handleRoute(item.Code)}
        style={{ borderColor: getStatusColor(theme, item?.Status) }}
      >
        <div className={`main-tooltip-container ${language.locale}`}>
          <div onClick={e => e.stopPropagation()} className={`work-space-button-container ${language.locale}`}>
            <button onClick={this.onWorkspaceClick}>
              <InfoIcon />
            </button>
          </div>

          <div className="tooltip-container">
            <FormattedMessage id="common.goToWorkSpace" defaultMessage="Go to workSpace" />
          </div>
        </div>
        <div className="entity-head">
          <div className="entity-title">
            <p>{getProjectLocalizedName(item, language.locale)}</p>
          </div>
        </div>

        <div className="entity-body">
          <div className="total-project">
            <div className="total-project-text medium-text" style={{ color: theme.ThemeColor.item.Text }}>
              <FormattedMessage id="projectrisk.administrator" />:
              <span className="project-numbers medium-text colorGray">{item?.Manager?.DisplayName}</span>
            </div>
          </div>
          <div className="total-project">
            <div className="total-project-text medium-text" style={{ color: theme.ThemeColor.item.Text }}>
              <FormattedMessage id="portfolio.total-project" />:{' '}
              <span className="project-numbers medium-text colorGray">{item?.ProjectsCount}</span>
            </div>
          </div>
          <Badge theme={theme} Status={item?.Status} locale={language.locale} />
          <div className="entity-progress">
            <ProgressBar
              firstLineValue={item?.Progress}
              firstLineColor={getStatusColor(theme, item?.Status)}
              asideNumber={`${item?.Progress}%`}
            />
          </div>
        </div>

        <div className="entity-footer">
          <div className={`${language.locale === 'ar' ? 'rtl' : ''} budget entity-footer__box`}>
            <div className="text normal-text">
              <FormattedMessage id="portfolio.total-budget" />
            </div>
            <span className="entity-footer__value" style={{ color: theme.ThemeColor.item.Text }}>
              {nFormatter(item?.Budget, 1, language.locale)} <FormattedMessage id="common.SARShort" />
            </span>
          </div>
          <div className={`${language.locale === 'ar' ? 'rtl' : ''} risks entity-footer__box`}>
            <div className="text normal-text">
              <FormattedMessage id="portfolio.risks" />
            </div>
            <span className="entity-footer__value">{item?.Risks}</span>
          </div>
          <div className="issues entity-footer__box">
            <div className="text normal-text entity-footer__title">
              <FormattedMessage id="portfolio.issues" />
            </div>
            <span className="entity-footer__value">{item?.Issues}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default EntityCard;
