import React from 'react';
import { FormattedMessage } from 'react-intl';
import Field from '../Field';

import './style.scss';

// const fields = [
//   {
//     type: 'date',
//     value: StartDateValue,
//     noIcons: true,
//     error: !StartDateValue,
//     onChangeAction: e => this.setState({ StartDateValue: e }),
//     locale: intl.locale === 'ar' ? 'ar' : 'en',
//     validateOnExite: true,
//   },
//   {
//     type: 'number',
//     onChangeAction: e => this.setState({ Budget: e.target.value }),
//     placeholder: intl.formatMessage({
//       id: 'common.projectBudget',
//     }),
//     value: Budget,
//     validateOnExite: true,
//     error: !Budget.trim().length,
//   },
//   {
//     notField: true,
//     CustomComponent: () => (
//       <Button color="white" backgroundColor={theme.ThemeColor.item.Text} onClick={() => history.push('/')}>
//         <FormattedMessage id="common.cancel" />
//       </Button>
//     ),
//   },
// ];
//
// const titles = [{ name: 'title' }, { name: 'date' }, { name: 'submit' }];
//
// const list = [
//   {
//     start: 'hello',
//
//     name: '11-11-2050',
//
//     CustomComponent: () => (
//       <Button color="white" backgroundColor={theme.ThemeColor.item.Text} onClick={() => history.push('/')}>
//         <FormattedMessage id="common.cancel" />
//       </Button>
//     ),
//   },
//   {
//     start: 'hello',
//
//     name: '11-11-2050',
//
//     CustomComponent: () => (
//       <Button color="white" backgroundColor={theme.ThemeColor.item.Text} onClick={() => history.push('/')}>
//         <FormattedMessage id="common.cancel" />
//       </Button>
//     ),
//   },
// ];

const FieldsList = props => {
  const { fields, titles, lists, titleStyles, title, showDeliverablesHint } = props;
  return (
    <div className="fields-list-container">
      <span className="label" style={titleStyles}>
        {typeof title === 'function' ? title() : title || <FormattedMessage id="program.deliverables" />}
        {showDeliverablesHint ? (
          <span className="deliverables-hint">
            <FormattedMessage id="project-closure.deliverables-hint" />
          </span>
        ) : null}
      </span>

      <div className="fields-list-inner">
        <div className="table-title">
          {Array.isArray(titles) &&
            titles.map((item, key) => (
              <div className="title" key={key}>
                {item.name}
              </div>
            ))}
        </div>

        <>
          {lists.map((list, index) => (
            <div className="fields-list" key={index}>
              {Object.keys(list).map((item, key) => (
                <div className="fields-list-section" key={key}>
                  {item === 'CustomComponent' ? list[item]() : list[item]}
                </div>
              ))}
            </div>
          ))}
        </>
        {fields && (
          <div className="fields-list">
            {Array.isArray(fields) &&
              fields.map((field, index) => (
                <div className="fields-list-section" key={index}>
                  {field.notField ? field.CustomComponent() : <Field control={{ ...field }} />}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FieldsList;
