import React from 'react';
import './style.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PhasesSteps from './PhasesSteps';
import CollapseContainer from '../CollapseContainer';
import PhaseGateCollapseContent from './PhaseGateCollapseContent';
import PhasesGateReviewLoader from './PhasesGateReviewLoader';
import ContentLoader from './ContentLoader';
import Button from '../Button';
import Modal from '../Modal';
import AddModal from './AddModal';
import CheckBox from '../Field/Control/CheckBox';
import { ReactComponent as Clipboard } from '../../assets/images/clipboard.svg';
import { ReactComponent as AddPlusButton } from '../../assets/images/add-plus-button.svg';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { getTitleName, mergeAttachments, addToast } from '../../Helpers';
import EmptyComponent from '../EmptyComponent';

class PhasesGateReview extends React.Component {
  state = {
    showModal: false,
    expanded: null,
    isCurrentExpanded: false,
    loading: false,
    data: [],
    commentsLoading: false,
    comments: [],
    phaseID: '',
    currentStepData: null,
    currentStep: false,
    createSaveLoading: false,
    createDeleteLoading: false,
    phaseGateSubmitLoading: false,
    islastStep: false,
    phaseGateDoActionLoading: false,
  };

  componentDidMount() {
    this.getPhaseGate();
  }

  showToast = error => {
    if (error)
      addToast.error(error.message, {
        toastId: 'phasegate',
      });
  };

  getPhaseGate = () => {
    const { match } = this.props;

    this.setState(
      {
        loading: true,
      },
      () => {
        new ProjectAPIController().getPhaseGate(match.params.code).then(res => {
          if (res.StatusCode === 'Success') {
            const data = res.Data.sort((a, b) => a.Order - b.Order);
            const currentStepData = this.currentStepData(data);
            if (currentStepData) {
              this.setState({
                loading: false,
                data,
                currentStepData: res.Data.length > 0 && currentStepData,
                currentStep: res.Data.length > 0 && this.checklist(currentStepData),
                islastStep: res.Data.length > 0 && data[data.length - 1].StatusID === currentStepData.StatusID,
              });
            } else {
              this.setState({
                loading: false,
                currentStepData: res.Data[res.Data.length - 1],
                islastStep: true,
                currentStep: true,
                data,
              });
            }
          }
        });
      },
    );
  };

  fetchComments = id => {
    this.setState({ commentsLoading: true }, () => {
      new ProjectAPIController()
        .getPhaseGateComments(id)
        .then(res => {
          if (res.StatusCode === 'Success') {
            this.setState({
              commentsLoading: false,
              comments: res.Data,
            });
          }
        })
        .catch(error => {
          this.showToast(error);
        });
    });
  };

  checklist = data => {
    const list =
      data.CheckListItems.length > 0
        ? data.CheckListItems.map(listItem => {
            return listItem.IsChecked;
          })
        : [];

    return !!list.includes(false);
  };

  handleSave = async items => {
    const _attachments = await mergeAttachments(items.Attachments || []);

    const { expanded, phaseID } = this.state;
    const body = {
      comment: {
        ...items,
        Attachments: _attachments,
      },
    };
    this.setState({ createSaveLoading: true }, () => {
      new ProjectAPIController()
        .savePhaseGateComment(body)
        .then(res => {
          if (res.StatusCode === 'Success') {
            addToast.success('common.changesHaveBeenSaved');

            if (phaseID === expanded) {
              this.fetchComments(phaseID);
            }

            this.setState({
              showModal: false,
              createSaveLoading: false,
            });
          }
        })
        .catch(error => {
          this.showToast(error);
        });
    });
  };

  deleteComment = id => {
    this.setState({ createDeleteLoading: true, commentId: id }, () => {
      new ProjectAPIController()
        .deleteComment(id)
        .then(res => {
          if (res.StatusCode === 'Success') {
            addToast.success('common.changesHaveBeenSaved');
            this.setState({
              comments: res.Data,
              createDeleteLoading: false,
            });
          }
        })
        .catch(error => {
          this.showToast(error);
        });
    });
  };

  getCurrentdDataArr = (id, isChecked) => {
    const { currentStepData } = this.state;
    const newcurrentStepData = currentStepData.CheckListItems.map(item => {
      if (item.ID === id) {
        return { ...item, IsChecked: isChecked };
      }
      return {
        ...item,
      };
    });

    return newcurrentStepData;
  };

  onChangeCheckBoxResolve = (id, isChecked, res) => {
    const { currentStepData } = this.state;
    const newcurrentStepData = this.getCurrentdDataArr(id, isChecked);
    if (res.StatusCode !== 'Success') {
      this.setState({
        currentStepData: { ...currentStepData, CheckListItems: newcurrentStepData },
        currentStep: this.checklist({ ...currentStepData, CheckListItems: newcurrentStepData }),
      });
    }
  };

  onChangeCheckBox = (id, isChecked) => {
    const { currentStepData } = this.state;
    const newcurrentStepData = this.getCurrentdDataArr(id, !isChecked);

    this.setState(
      {
        currentStepData: { ...currentStepData, CheckListItems: newcurrentStepData },
        currentStep: this.checklist({ ...currentStepData, CheckListItems: newcurrentStepData }),
      },
      () => {
        new ProjectAPIController()
          .phaseGateCheck({ id, isCheck: !isChecked })
          .then(res => this.onChangeCheckBoxResolve(id, isChecked, res))
          .catch(error => {
            this.showToast(error);
          });
      },
    );
  };

  currentStepData = currentStepItem => {
    return currentStepItem.find(data => {
      return data.StatusID === 2 || data.StatusID === 3 || data.StatusID === 5;
    });
  };

  phaseGateSubmit = () => {
    const { currentStepData } = this.state;
    this.setState({ phaseGateSubmitLoading: true }, () => {
      new ProjectAPIController()
        .phaseGateSubmit(currentStepData.ID)
        .then(res => {
          if (res.StatusCode === 'Success') {
            const data = res.Data.sort((a, b) => a.Order - b.Order);
            const newCurrentStepData = this.currentStepData(data);
            this.setState({
              phaseGateSubmitLoading: false,
              data,
              currentStepData: res.Data.length > 0 && newCurrentStepData,
              currentStep: res.Data.length > 0 && this.checklist(newCurrentStepData),
              islastStep: res.Data.length > 0 && data[data.length - 1].StatusID === newCurrentStepData.StatusID,
            });
          }
        })
        .catch(error => {
          this.showToast(error);
        });
    });
  };

  phaseGateDoAction = (id, statusID) => {
    this.setState({ phaseGateDoActionLoading: true }, () => {
      new ProjectAPIController()
        .postPhaseStatus({ id, statusID })
        .then(res => {
          if (res.StatusCode === 'Success') {
            this.getPhaseGate();
            this.setState({ phaseGateDoActionLoading: false });
          }
        })
        .catch(error => {
          this.showToast(error);
        });
    });
  };

  onProcessesChange = selected => {
    const {
      history,
      match: {
        params: { code },
      },
    } = this.props;
    const { value } = selected;
    const url = `/processes/${value.toLowerCase()}?ProjectUID=${code}`;
    history.push(url);
  };

  render() {
    const { themeColor, Username, intl, program, locale } = this.props;
    const {
      showModal,
      expanded,
      isCurrentExpanded,
      data,
      commentsLoading,
      comments,
      phaseID,
      currentStepData,
      currentStep,
      createSaveLoading,
      createDeleteLoading,
      commentId,
      loading,
      phaseGateSubmitLoading,
      islastStep,
      phaseGateDoActionLoading,
    } = this.state;

    if (Object.keys(program).length === 0 || loading) return <PhasesGateReviewLoader />;
    if ((data.length === 0 && !loading) || !currentStepData) return <EmptyComponent />;

    const isOwnerAndPending = Username === program.ProjectOwner.Username && currentStepData.StatusID === 3;

    return (
      <div className="phases-main-container">
        <div className="deliverables__container">
          <div className="deliverables__content">
            <div className="deliverables__header">
              <div className="deliverables__title">
                <FormattedMessage id="Phasesgatereview.title" />
              </div>
            </div>
            <PhasesSteps phases={data} locale={locale} />
            <div className="check-list">
              <div className="check-list-with-button">
                <div className="icon-with-text" style={{ color: themeColor }}>
                  {currentStepData && currentStepData.CheckListItems.length > 0 ? (
                    <>
                      <div className="icon">
                        <Clipboard style={{ fill: themeColor }} />
                      </div>
                      <div>
                        <FormattedMessage id="common.check-list" />
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="button-container">
                  {Username === program.ProjectManager.Username &&
                    !isOwnerAndPending &&
                    currentStepData.StatusID !== 4 && (
                      <Button
                        color="white"
                        backgroundColor={themeColor}
                        onClick={() => this.phaseGateSubmit()}
                        disabled={
                          currentStepData.StatusID === 3 ||
                          currentStep ||
                          phaseGateSubmitLoading ||
                          (islastStep && currentStepData.StatusID === 4)
                        }
                      >
                        {currentStepData.StatusID === 3 ? (
                          <FormattedMessage id="common.pending" />
                        ) : (
                          <FormattedMessage id={islastStep ? 'common.submit' : 'common.next-phase'} />
                        )}
                      </Button>
                    )}

                  {isOwnerAndPending && (
                    <div className="approve-reject-buttons">
                      <Button
                        color="white"
                        backgroundColor="#28a745"
                        onClick={() => this.phaseGateDoAction(currentStepData.ID, 4)}
                        disabled={phaseGateDoActionLoading}
                      >
                        <FormattedMessage id="common.approve" />
                      </Button>
                      <Button
                        color="white"
                        backgroundColor="#c62828"
                        onClick={() => this.phaseGateDoAction(currentStepData.ID, 5)}
                        disabled={phaseGateDoActionLoading}
                      >
                        <FormattedMessage id="common.reject" />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {currentStepData && currentStepData.CheckListItems.length > 0 ? (
              <div className="all-collapse-container">
                <div>
                  {currentStepData.CheckListItems.map((element, index) => (
                    <CollapseContainer
                      data={element}
                      key={index}
                      onClick={() => {
                        this.setState(prevState => ({
                          expanded: element.ID,
                          isCurrentExpanded: prevState.expanded === element.ID ? !prevState.isCurrentExpanded : true,
                        }));
                        this.fetchComments(element.ID);
                      }}
                      isExpanded={element.ID === expanded}
                      isCurrentExpanded={isCurrentExpanded}
                      CustomCollapseContent={() => {
                        return commentsLoading ? (
                          <ContentLoader />
                        ) : (
                          <>
                            {comments.length > 0 ? (
                              comments.map((item, indx) => (
                                <PhaseGateCollapseContent
                                  data={item}
                                  key={indx}
                                  deleteComment={deleteitem => this.deleteComment(deleteitem)}
                                  createDeleteLoading={commentId === item.ID && createDeleteLoading}
                                />
                              ))
                            ) : (
                              <EmptyComponent small />
                            )}
                          </>
                        );
                      }}
                      CustomCoponent={() => (
                        <div className="collpase-content">
                          <div className="first-section">
                            <CheckBox
                              label={getTitleName(element, locale)}
                              checked={element.IsChecked}
                              id={element.ID}
                              disabled={
                                Username !== program.ProjectManager.Username ||
                                (islastStep && currentStepData.StatusID === 4)
                              }
                              onChangeCheckBox={(id, isChecked) => this.onChangeCheckBox(id, isChecked)}
                            />
                          </div>
                          <div className="second-section">
                            <div className="text">
                              <Button
                                color="white"
                                backgroundColor={themeColor}
                                Icon={() => <AddPlusButton fill="white" />}
                                onClick={() => this.setState({ showModal: true, phaseID: element.ID })}
                              >
                                <FormattedMessage id="common.add" />
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {showModal && (
          <Modal
            title={intl.formatMessage({ id: 'common.Add-comment' })}
            isOpen
            onClose={() => this.setState({ showModal: false })}
          >
            <AddModal phaseID={phaseID} handleSave={items => this.handleSave(items)} loading={createSaveLoading} />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ theme, user, language }) => ({
  theme,
  themeColor: theme.ThemeColor.item.Text,
  Username: user.user.Username,
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps, null)(PhasesGateReview));
