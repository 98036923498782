import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as PropTypes from 'prop-types';
import Button from '../../Button';
import Table from '../../Table';
import { ReactComponent as ExtentionIcon } from '../../../assets/images/extention.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import Field from '../../Field';
import calculateMilestonesWeight from '../../../Helpers/Program';
import { formattedDate } from '../../../Helpers';

const initialValues = {
  CompletedWorkPercentage: '0',
  CompletedWorkWeight: '0',
  PlannedFinishDate: new Date(),
  ActualFinishDate: new Date(),
  Title: '',
};

class ProgramMilestonesMain extends Component {
  constructor(props) {
    super(props);
    const totalWorkWeight = calculateMilestonesWeight(props.milestones);
    this.state = {
      editMilestones: props.milestones.map(item => ({ ...item })),
      newMilestone: { ...initialValues },
      totalWorkWeight,
      toDelete: [],
      editMode: false,
      allowUpdates: totalWorkWeight === 100,
      allowNewMilestone: false,
    };
  }

  addNewMilestone = () => {
    this.setState(prevState => {
      const editMilestones = [...prevState.editMilestones, prevState.newMilestone];
      const totalWorkWeight = calculateMilestonesWeight(editMilestones);
      return {
        editMilestones,
        newMilestone: { ...initialValues },
        totalWorkWeight,
        allowUpdates: totalWorkWeight === 100,
        allowNewMilestone: false,
      };
    });
  };

  handleChange = (value, id, field, progress, index) => {
    const isProgressValidate = progress && value > 100;
    const { editMilestones } = this.state;
    const newMilestones = [...editMilestones];
    // const index = newMilestones.findIndex(milestone => milestone.ID === id);
    newMilestones[index][field] = value;
    const totalWorkWeight = calculateMilestonesWeight(newMilestones);
    this.setState({
      editMilestones: newMilestones,
      totalWorkWeight,
      allowUpdates: totalWorkWeight === 100 && !isProgressValidate,
    });
  };

  createCell = ({ value: { value, text, type, id, field, suffix, nameKey, progress, ...others }, index }) => {
    const { intl, locale } = this.props;
    const { editMode } = this.state;
    if (editMode)
      return (
        <>
          <Field
            control={{
              type,
              noIcons: true,
              key: `${id}-title-cell`,
              onChangeAction: e => this.handleChange(e.target ? e.target.value : e, id, field, progress, index),
              placeholder: intl.formatMessage({ id: nameKey }),
              locale: locale === 'ar' ? 'ar' : 'en',
              value,
              ...others,
              // error: progress && value > 100,
            }}
          />
        </>
      );
    return (
      <span className="table__cell-title-view">
        {text}
        {suffix}
      </span>
    );
  };

  shouldAllowNew = newMilestone => {
    return !!newMilestone.Title && !!newMilestone.PlannedFinishDate && !!newMilestone.CompletedWorkWeight;
  };

  removeMilestone = (index, id) => {
    this.setState(prevState => {
      const { editMilestones, toDelete } = prevState;
      const newMilestones = [...editMilestones];
      newMilestones.splice(index, 1);
      const totalWorkWeight = calculateMilestonesWeight(newMilestones);
      return {
        editMilestones: newMilestones,
        toDelete: id ? [...toDelete, id] : toDelete,
        totalWorkWeight,
        allowUpdates: totalWorkWeight === 100,
      };
    });
  };

  handleNewChange = (value, field) => {
    const { newMilestone } = this.state;
    const newerMilestone = { ...newMilestone };
    newerMilestone[field] = value;
    this.setState({
      newMilestone: newerMilestone,
      allowNewMilestone: this.shouldAllowNew(newerMilestone),
    });
  };

  createFooterCell = ({ value, text, type, id, field, suffix, editRender, nameKey, ...others }) => {
    const { intl, locale } = this.props;
    const { editMode } = this.state;
    if (editMode) {
      return (
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Field
              control={{
                type,
                noIcons: true,
                key: `${id}-title-cell`,
                onChangeAction: e => this.handleNewChange(e.target ? e.target.value : e, field),
                placeholder: intl.formatMessage({ id: nameKey }),
                locale: locale === 'ar' ? 'ar' : 'en',
                value,
                ...others,
              }}
            />
            {suffix}
          </div>
          {editRender ? editRender() : null}
        </div>
      );
    }
    return editRender;
  };

  handleDetailsClick = ({ original }) => {
    const { editMode } = this.state;
    if (editMode) return;
    const { changeView } = this.props;
    changeView('details', original.ID);
  };

  getColumns = () => {
    const { intl, theme, loading, milestones } = this.props;
    const { allowNewMilestone, editMode, newMilestone, totalWorkWeight } = this.state;

    return [
      {
        accessor: data => {
          return {
            value: data.Title,
            text: data.Title,
            nameKey: 'milestones.name',
            id: data.ID,
            type: 'text',
            field: 'Title',
          };
        },
        Header: intl.formatMessage({ id: 'milestones.name' }),
        id: 'Title',
        Cell: this.createCell,
        Footer: this.createFooterCell({
          value: newMilestone.Title,
          text: newMilestone.Title,
          nameKey: 'milestones.new-name',
          type: 'text',
          field: 'Title',
        }),
        width: '40%',
      },
      {
        accessor: data => ({
          value: data.PlannedFinishDate,
          text: formattedDate(data.PlannedFinishDate),
          id: data.ID,
          type: 'date',
          nameKey: 'milestones.planned-finish-date',
          field: 'PlannedFinishDate',
        }),
        Header: intl.formatMessage({ id: 'milestones.planned-finish-date' }),
        id: 'PlannedFinishDate',
        Cell: this.createCell,
        Footer: this.createFooterCell({
          value: newMilestone.PlannedFinishDate,
          text: formattedDate(newMilestone.PlannedFinishDate),
          nameKey: 'milestones.planned-finish-date',
          type: 'date',
          field: 'PlannedFinishDate',
        }),
        width: '20%',
      },
      {
        accessor: data => ({
          value: parseInt(data.CompletedWorkWeight, 10) || 0,
          text: parseInt(data.CompletedWorkWeight, 10) || 0,
          suffix: '%',
          id: data.ID,
          nameKey: 'milestones.weighted-completed-work',
          type: 'number',
          field: 'CompletedWorkWeight',
        }),
        Header: intl.formatMessage({ id: 'milestones.weighted-completed-work' }),
        id: 'CompletedWorkWeight',
        Cell: this.createCell,
        Footer: this.createFooterCell({
          value: newMilestone.CompletedWorkWeight,
          text: newMilestone.CompletedWorkWeight,
          type: 'number',
          field: 'CompletedWorkWeight',
          nameKey: 'milestones.weighted-completed-work',
          suffix: '%',
          editRender: () => (
            <div className={`work-weight-footer__total ${totalWorkWeight === 100 ? '' : 'warning-text'}`}>
              <strong>
                <FormattedMessage id="milestones.total" defaultMessage="Total" />: {totalWorkWeight} %
              </strong>
            </div>
          ),
        }),
        width: '17%',
      },
      {
        accessor: data => ({
          value: data.CompletedWorkPercentage,
          text: data.CompletedWorkPercentage,
          suffix: '%',
          id: data.ID,
          type: 'number',
          nameKey: 'milestones.completed-work',
          field: 'CompletedWorkPercentage',
          progress: true,
        }),
        Header: intl.formatMessage({ id: 'milestones.completed-work' }),
        id: 'CompletedWorkPercentage',
        Cell: this.createCell,
        Footer: this.createFooterCell({
          value: newMilestone.CompletedWorkPercentage,
          text: newMilestone.CompletedWorkPercentage,
          type: 'number',
          nameKey: 'milestones.completed-work',
          field: 'CompletedWorkPercentage',
          suffix: '%',
        }),
        width: '17%',
      },
      {
        minWidth: 40,
        width: '5%',
        id: 'actions',
        accessor: data => ({ ...data }),
        Cell: ({ index, original: { ID } }) =>
          editMode ? (
            <div className="milestone-actions">
              <button
                type="button"
                onClick={() => {
                  this.removeMilestone(index, ID);
                }}
                className="table-milestone-field__button"
              >
                <CloseIcon fill="white" stroke="white" width={10} height={10} />
              </button>
            </div>
          ) : null,
        Footer: editMode ? (
          <div className="milestone-add-btn-container">
            <button
              type="button"
              disabled={!allowNewMilestone || loading}
              onClick={() => {
                this.addNewMilestone();
              }}
              className="milestones-add-button"
              style={{ cursor: 'pointer' }}
            >
              <ExtentionIcon
                className="contact-icon"
                width="17px"
                height="17px"
                stroke={allowNewMilestone ? theme.ThemeColor.item.Text : 'gray'}
              />
            </button>
          </div>
        ) : null,
      },
    ];
  };

  toggleEditMode = () => {
    const { milestones } = this.props;
    this.setState(prevState => {
      const totalWorkWeight = calculateMilestonesWeight(prevState.editMode ? milestones : prevState.editMilestones);
      return {
        editMode: !prevState.editMode,
        totalWorkWeight,
        allowUpdates: totalWorkWeight === 100,
        editMilestones: milestones.map(item => ({ ...item })),
        toDelete: [],
      };
    });
  };

  render() {
    const { theme, updateMilestones, milestones, loading, readOnly } = this.props;
    const { editMilestones, toDelete, allowUpdates, editMode } = this.state;
    return (
      <section className="program-milestones-container">
        <div className="sec-header-container tab-header">
          <div className="title bold-text">
            <FormattedMessage id="projectdata.milestones" />
            <span className="milestone__length" style={{ background: theme.ThemeColor.item.Text }}>
              {' '}
              {milestones.length}
            </span>
          </div>
          {!readOnly && (
            <div className="section-actions">
              <Button type="button" onClick={this.toggleEditMode} backgroundColor={theme.ThemeColor.item.Text}>
                <FormattedMessage id="milestones.toggle-mode" />
              </Button>

              {editMode && (
                <div className="mi-button">
                  <Button
                    type="button"
                    className="action secondary-action"
                    backgroundColor={theme.ThemeColor.item.Text}
                    color="white"
                    loading={loading}
                    disabled={!allowUpdates || loading}
                    onClick={() => updateMilestones(editMilestones, toDelete)}
                  >
                    <FormattedMessage id="common.save" />
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`tTab-content-container ${loading ? 'loading' : ''}`}>
          <Table
            columns={this.getColumns()}
            data={editMode ? editMilestones : milestones}
            showPagination={false}
            renderNoDataComponent={false}
            rowBorderColor="transparent"
            sortable={false}
            isLoading={loading}
            numOfLoadingRows={4}
            onRowClick={!readOnly && this.handleDetailsClick}
          />
        </div>
      </section>
    );
  }
}

ProgramMilestonesMain.propTypes = {
  theme: PropTypes.any,
  intl: PropTypes.any,
};

export default injectIntl(ProgramMilestonesMain);
