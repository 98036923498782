import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../Button';
import Table from '../../Table';
import { ReactComponent as Clipboard } from '../../../assets/images/clipboard.svg';

import { formattedDate } from '../../../Helpers';

class Main extends React.Component {
  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        accessor: 'Title', // String-based value accessors!
        Header: intl.formatMessage({ id: 'deliverables.deliverable-name' }),
        width: 200,
      },
      {
        accessor: data => formattedDate(data.PlannedFinishDate),
        Header: intl.formatMessage({ id: 'deliverables.planned-finish-date' }),
        id: 'PlannedFinishDate',
      },
      {
        accessor: data => formattedDate(data.ActualFinishDate),
        Header: intl.formatMessage({ id: 'deliverables.actual-finish-date' }),
        id: 'assignedTo',
      },
      {
        accessor: 'CompletionProgress',
        Header: intl.formatMessage({ id: 'deliverables.completion-percentage' }),
        id: 'CompletionProgress',
      },
    ];
  };

  handleRowClick = ({ original }) => {
    const { changeView } = this.props;
    changeView('details', original.ID);
  };

  render() {
    const { loading, deliverables, changeView, themeColor } = this.props;

    return (
      <div className="deliverables__container">
        <div className="deliverables__content">
          <div className="deliverables__header">
            <div className="deliverables__title">
              <Clipboard />
              <FormattedMessage id="deliverables" />
            </div>
            <div className="deliverables__actions">
              <Button className="action" type="submit" onClick={() => changeView('add')} backgroundColor={themeColor}>
                <FormattedMessage id="deliverables.add-deliverable" />
              </Button>
            </div>
          </div>
          <div className={deliverables.length > 0 ? 'deliverables__body' : ''}>
            <Table
              rowBorderColor="transparent"
              onRowClick={this.handleRowClick}
              columns={this.getColumns()}
              data={deliverables}
              showPagination={false}
              sortable={false}
              isLoading={loading}
              numOfLoadingRows={5}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Main);
