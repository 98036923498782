import React from 'react';
import Modal from '../Modal';
import ProjectTable from '../ProjectTable';

function ProjectsPopUp({ projects, intl, locale, theme, isOpen, onClose, onProjectClick, history }) {
  return (
    <Modal
      title={intl.formatMessage({ id: 'common.projects' })}
      isOpen={isOpen}
      onClose={() => onClose()}
      withoutPadding
      dashBoardPopUp
    >
      <ProjectTable
        data={projects}
        intl={intl}
        locale={locale}
        theme={theme}
        history={history}
        backgroundColor={theme.ThemeColor.item.Text}
        onProjectClick={onProjectClick}
        headerBackgroundColor={theme.ThemeColor.item.Text}
      />
    </Modal>
  );
}

export default ProjectsPopUp;
