import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../components/Button';
import Table from '../../../../components/Table';
import ProjectAPIController from '../../../../providers/controllers/ProjectAPIController';
import { getStatusName } from '../../../../Helpers';
import routes from '../../../../routes';

const IDList = ({ history }) => {
  const [dependencies, setDependencies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const locale = useSelector(state => state.language.locale);
  const statuses = useSelector(state => state.theme.InterdependenceStatus.item.Items);

  useEffect(() => {
    new ProjectAPIController().getInterdependencies().then(res => {
      setDependencies(res.Data || []);
      setIsLoading(false);
    });
  }, []);

  const getColumns = () => {
    const suffix = locale === 'ar' ? 'Ar' : '';
    return [
      {
        accessor: 'LogNumber', // String-based value accessors!
        Header: <FormattedMessage id="common.number" />,
        width: 80,
      },
      {
        accessor: data => data.RequestBy?.DisplayName,
        Header: <FormattedMessage id="common.created-by" />,
        id: 'createdBy',
      },
      {
        accessor: `AffectingProjectName${suffix}`,
        Header: <FormattedMessage id="id.impact-project" />,
        id: 'impactProject',
      },
      {
        accessor: `AffectedProjectName${suffix}`,
        Header: <FormattedMessage id="id.affected-project" />,
        id: 'affectedProject',
      },
      // {
      // accessor: data => formattedDate(data.DueDate),
      // Header: <FormattedMessage id="common.due-date" />,
      // id: 'dueDate',
      // },
      {
        accessor: data => data.Owner?.DisplayName,
        Header: <FormattedMessage id="common.owner" />,
        id: 'Owner',
      },
      {
        accessor: data => getStatusName(statuses, data?.Active ? 'Active' : 'Closed', locale),
        // Header: intl.formatMessage({ id: 'common.status' }),
        Header: <FormattedMessage id="common.status" />,
        id: 'Status',
        width: 100,
      },
    ];
  };

  const handleRowClick = data => {
    history.push(routes.details(routes.interdependencies, data.original.ID));
  };

  return (
    <div>
      <div className="id-header">
        <h4>
          <FormattedMessage id="common.interdependencies" />
        </h4>
        <div className="header-actions">
          <Button onClick={() => history.push(routes.add(routes.interdependencies))} disabled={isLoading}>
            <FormattedMessage id="common.create-new" />
          </Button>
        </div>
      </div>
      <Table
        columns={getColumns()}
        data={dependencies}
        showPagination={false}
        sortable={false}
        onRowClick={data => handleRowClick(data)}
        isLoading={isLoading}
        numOfLoadingRows={4}
        rowBorderColor="transparent"
        customRowStyle={info => ({ background: info?.original?.AlertApplies ? '#FCECEC' : 'transparent' })}
      />
    </div>
  );
};

export default IDList;
