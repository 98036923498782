import React from 'react';
import { connect } from 'react-redux';
import Breadcrumbs from '../Breadcrumb';
import ProcessesPanelHeader from '../ProcessesPanelHeader';
import ProcessHistory from '../ProcessHistory';
import Panel from '../Panel';
import Steps from '../Steps';
import './style.scss';

function ProccessesFormLayout({
  title,
  children,
  readOnly,
  disabled,
  data,
  processType,
  refetchData,
  removeAttachments,
  locale,
}) {
  function getStepsData() {
    return data.Steps.sort((a, b) => a.Order - b.order).map((step, i) => {
      let Status = '';
      if (i !== 0) {
        // If it's not the first step (Order !== 1)
        const prevStep = data.Steps[i - 1];
        if (prevStep.StatusID === 1) {
          Status = 'Started';
        }
      }

      if (i === 0 || Status !== 'Started') {
        if (step.StatusID === 3 || step.StatusID === 5) {
          Status = 'Approved';
        } else if (step.StatusID === 1) {
          Status = 'Rejected';
        } else if (step.StatusID === 2) {
          Status = 'Pending';
        } else {
          Status = 'Started';
        }
      }

      return {
        ...step,
        Order: step.Order,
        TitleArabic: step.NameArabic,
        Title: step.Name,
        Status,
      };
    });
  }

  return (
    <div className="processes-form-layout">
      <div className="processes-form-layout__breadcrumb">
        <Breadcrumbs title={title} routes={[]} hideCreateButton />
      </div>
      <Panel
        count={null}
        title={() => (
          <ProcessesPanelHeader
            title={title}
            readOnly={readOnly}
            allDisabled={disabled}
            data={data}
            processType={processType}
            refetchData={refetchData}
            removeAttachments={removeAttachments}
          />
        )}
        render={() => (
          <div className="processes-form-layout__content">
            {readOnly && Boolean(data.Steps.length) && (
              <div className="steps-wrapper">
                <Steps steps={getStepsData()} locale={locale} />
              </div>
            )}
            <form onSubmit={e => e.preventDefault()}>{children}</form>
            {readOnly ? <ProcessHistory history={data.ActionHistory} /> : null}
          </div>
        )}
      />
    </div>
  );
}

const mapStateToProps = ({ language }) => ({
  locale: language.locale,
});

export default connect(mapStateToProps)(ProccessesFormLayout);
