import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import Panel from '../Panel';

import { formattedDate, getProjectLocalizedName, createMarkup } from '../../Helpers';

import './styles.scss';

function DashboardProjectInfo({ intl, locale, project = {} }) {
  const isAr = locale === 'ar';

  const footerData = [
    {
      id: 1,
      label: intl.formatMessage({ id: 'common.startDate' }),
      value: formattedDate(project.CustomFields?.ProjectStartDate),
    },
    {
      id: 2,
      label: intl.formatMessage({ id: 'common.finishDate' }),
      value: formattedDate(project.CustomFields?.ProjectFinishDate),
    },
    {
      id: 3,
      label: intl.formatMessage({ id: 'common.programTitle' }),
      value: getProjectLocalizedName(project.Program || {}, locale),
    },
  ];

  return (
    <Panel
      count={null}
      title={intl.formatMessage({ id: 'dashboard.project-info' })}
      render={() => (
        <div className="dashboard-project-info">
          <div className="dashboard-project-info__container">
            <div className="row title-container">
              <span className="title">
                <FormattedMessage id="dashboard.project-details" />
              </span>
              <span
                className="content"
                dangerouslySetInnerHTML={createMarkup(
                  isAr ? project.CustomFields?.DescriptionArabic : project.CustomFields?.Description,
                )}
              />
            </div>
            <div className="row footer">
              {footerData.map(el => (
                <div key={el.id} className="footer-item">
                  <span className="footer-title">{el.label}</span>
                  <span className="footer-value">{el.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    />
  );
}

const mapStateToProps = ({ language }) => ({
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps)(DashboardProjectInfo));
