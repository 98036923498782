/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowIcon } from '../../assets/images/down-arrow.svg';
import { getProjectLocalizedName } from '../../Helpers';
import * as styles from './styles';

const ProjectsList = ({
  items,
  handleListDragStart,
  listEnter,
  handleMouseLeave,
  locale,
  isCollapsed,
  setIsCollapsed,
  rtl,
  primaryColor,
}) => {
  return (
    <React.Fragment>
      <div css={styles.projectsList(isCollapsed)} onDragStart={handleListDragStart}>
        <div css={styles.listSection(isCollapsed, rtl)} onClick={() => setIsCollapsed(!isCollapsed)}>
          <p>
            <FormattedMessage id="pdb.projects" defaultMessage="Projects" />
          </p>
          <ArrowIcon css={styles.arrow(isCollapsed, rtl)} height={15} />
        </div>
        <div css={styles.list}>
          {!isCollapsed &&
            items.map((item, i) =>
              typeof item.Impact !== 'number' && typeof item.Urgency !== 'number' ? (
                <div
                  key={`oh-${i}`}
                  index={i}
                  draggable
                  css={styles.listItem(false, primaryColor)}
                  // onMouseEnter={listEnter}
                  // onMouseLeave={handleMouseLeave}
                >
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div css={styles.circle(item.Color)} />
                      <p>{getProjectLocalizedName(item, locale)}</p>
                    </div>
                    <p style={{ color: 'gray' }}>{item.Manager.DisplayName}</p>
                  </div>
                </div>
              ) : null,
            )}
          {!isCollapsed &&
            items.map((item, i) =>
              typeof item.Impact === 'number' && typeof item.Urgency === 'number' ? (
                <div
                  key={`oh-${i}`}
                  index={i}
                  draggable
                  css={styles.listItem(true, primaryColor)}
                  onMouseEnter={listEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div css={styles.circle(item.Color)} />
                      <p>{getProjectLocalizedName(item, locale)}</p>
                    </div>
                    <p style={{ color: 'gray' }}>{item.Manager.DisplayName}</p>
                  </div>
                </div>
              ) : null,
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectsList;
