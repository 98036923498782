import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ActionItem from './ActionItem';
import EmptyComponent from '../EmptyComponent';
import CommentInput from '../CommentInput';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { mergeAttachments } from '../../Helpers';
import './style.scss';

class ActionsLog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      comments: props.actionLogPage ? props.comments : props.comments.reverse(),
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.myRef.current.scrollTop = this.myRef.current.scrollHeight;
  }

  async onSubmit(comment, Attachments) {
    const { comments } = this.state;
    const _attachments = await mergeAttachments(Attachments);
    const { currentUser, escalationID, actionLogPage } = this.props;
    const data = {
      log: {
        Title: comment,
        Details: comment,
        CreaatedBy: currentUser,
        Attachments: _attachments,
      },
    };
    this.setState({ loading: true }, () => {
      new ProjectAPIController().addEEscalationLog(escalationID, data).then(res => {
        if (res) {
          this.setState({
            loading: false,
            comments: actionLogPage
              ? [...comments, { ...data.log, Attachments }]
              : [{ ...data.log, Attachments }, ...comments],
          });
          this.myRef.current.scrollTop = this.myRef.current.scrollHeight;
        }
      });
    });
  }

  render() {
    const { allowComment, escalatedData, themeColor, locale, changeView, id, actionLogPage, riskIssueId } = this.props;
    const { loading, comments } = this.state;
    const isClosed = escalatedData.Status === 3;

    const commentsList = actionLogPage ? comments : comments.slice(0, 2).reverse();
    return (
      <div className="actions-log">
        <div className="title-with-read-more">
          <p className="flex">
            <FormattedMessage id="project-details.escalated.actions" />
          </p>
          {comments.length > 2 && !actionLogPage && (
            <div onClick={() => changeView('actionLog', id, riskIssueId)}>
              <p className="flex flex-end" style={{ color: themeColor }}>
                <FormattedMessage id="common.read-more" />
              </p>
            </div>
          )}
        </div>

        {commentsList.length > 0 ? (
          <div className={actionLogPage ? 'action-log-items' : ''} ref={this.myRef}>
            {commentsList.map((item, indx) => (
              <ActionItem data={item} key={indx} usernameColor={themeColor} locale={locale} />
            ))}
          </div>
        ) : (
          <EmptyComponent small />
        )}

        {allowComment && !isClosed ? (
          <CommentInput onSubmit={(comment, attachments) => this.onSubmit(comment, attachments)} loading={loading} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ theme, user, language }) => {
  return {
    themeColor: theme.ThemeColor.item.Text,
    currentUser: user.user.Username,
    locale: language.locale,
  };
};

export default connect(mapStateToProps, null)(ActionsLog);
