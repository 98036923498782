import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import Modal from '../Modal';
import Button from '../Button';
import TitledContent from '../TitledContent';
import Field from '../Field';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { reduceUsersList, addToast } from '../../Helpers';
import { addNewTask, getMyRequests } from '../../actions/tasksCenter';

import './styles.scss';

const initialState = {
  saveDisabled: true,
  submitting: false,
  Title: '',
  TitleArabic: '',
  Description: '',
  FinishDateValue: new Date(),
  StartDateValue: new Date(),
  AssignTo: '',
};

class AddNewTaskModal extends React.Component {
  state = {
    ...initialState,
    users: [],
  };

  static getDerivedStateFromProps(props, state) {
    const requiredFields = ['Title', 'TitleArabic', 'Description', 'FinishDateValue', 'StartDateValue', 'AssignTo'];
    let saveDisabled = false;

    const newFinishDateValue = moment(state.FinishDateValue).format('MM-DD-YYYY');
    const newStartDateValue = moment(state.StartDateValue).format('MM-DD-YYYY');
    const isFinishDateAfter = moment(newStartDateValue).isAfter(newFinishDateValue);
    requiredFields.forEach(key => {
      const item = state[key];
      const isString = typeof item === 'string';
      const isDate = item && typeof item.getMonth === 'function';

      if (!item) saveDisabled = true;
      else if (isString && !item) saveDisabled = true;
      else if (!isDate && !Object.keys(item).length) saveDisabled = true;
      else if (isFinishDateAfter) saveDisabled = true;
    });

    if (state.saveDisabled !== saveDisabled) {
      return {
        saveDisabled,
      };
    }

    return null;
  }

  componentDidMount() {
    this.findAllUsers();
  }

  closeModal = () => {
    const { toggleModal } = this.props;
    this.setState(prevState => ({ ...initialState, users: prevState.users }));
    toggleModal();
  };

  handleSave = async () => {
    const { props, state } = this;
    const { addNewTaskAction, _getMyRequests } = props;
    this.setState({
      submitting: true,
      saveDisabled: true,
    });

    const body = {
      data: {
        ...state,
        AssignTo: { Username: state.AssignTo.value },
        StartDateValue: moment(state.StartDateValue)
          .locale('en')
          .format('DD-MM-YYYY'),
        FinishDateValue: moment(state.FinishDateValue)
          .locale('en')
          .format('DD-MM-YYYY'),
        Name: state.Title,
      },
    };
    addNewTaskAction(body, () => {
      this.setState({
        submitting: false,
      });
      _getMyRequests();

      addToast.success('common.changesHaveBeenSaved');
      this.closeModal();
    });
  };

  findAllUsers = () => {
    new ProjectAPIController()
      .getAllUsers()
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({
            users: reduceUsersList(res.Data),
          });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-users-error',
        });
      });
  };

  render() {
    const {
      props: { isOpen, intl, theme, locale },
      state: {
        saveDisabled,
        submitting,
        users,
        Title,
        TitleArabic,
        FinishDateValue,
        StartDateValue,
        Description,
        AssignTo,
      },
    } = this;
    if (!isOpen) return null;

    const themeColor = theme.ThemeColor.item.Text;

    const newFinishDateValue = moment(FinishDateValue).format('MM-DD-YYYY');
    const newStartDateValue = moment(StartDateValue).format('MM-DD-YYYY');

    return (
      <div className="new-task-modal">
        <Modal title={intl.formatMessage({ id: 'taskCenter.add-new-task' })} isOpen={isOpen} onClose={this.closeModal}>
          <div className={`modifyTaskModal__content${submitting ? ' loading' : ''}`}>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'taskCenter.title-in-english' })}>
                <Field
                  className="modifyTaskModal__dataSpan"
                  control={{
                    type: 'text',
                    onChangeAction: e => this.setState({ Title: e.target.value }),
                    value: Title,
                    error: !Title.length,
                    validateOnExite: true,
                  }}
                />
              </TitledContent>
              <TitledContent title={intl.formatMessage({ id: 'taskCenter.title-in-arabic' })}>
                <Field
                  className="modifyTaskModal__dataSpan"
                  control={{
                    type: 'text',
                    onChangeAction: e => this.setState({ TitleArabic: e.target.value }),
                    value: TitleArabic,
                    error: !TitleArabic.length,
                    validateOnExite: true,
                  }}
                />
              </TitledContent>
            </div>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'common.description' })}>
                <Field
                  className="modifyTaskModal__dataSpan"
                  control={{
                    type: 'textarea',
                    onChangeAction: e => this.setState({ Description: e.target.value }),
                    value: Description,
                    error: !Description.length,
                    validateOnExite: true,
                  }}
                />
              </TitledContent>
            </div>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'common.assignedTo' })}>
                <Field
                  control={{
                    type: 'select',
                    onChangeAction: e => this.setState({ AssignTo: e }),
                    value: AssignTo,
                    options: users,
                    menuPlacement: 'top',
                    className: 'users-select-container',
                    classNamePrefix: 'users-select',
                    validateOnExite: true,
                    error: !Object.keys(AssignTo).length,
                  }}
                />{' '}
              </TitledContent>
            </div>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'common.startDate' })}>
                <Field
                  className="modifyTaskModal__dataSpan"
                  control={{
                    type: 'date',
                    onChangeAction: e => this.setState({ StartDateValue: e }),
                    locale: locale === 'ar' ? 'ar' : 'en',
                    value: StartDateValue,
                    validateOnExite: true,
                    error: !StartDateValue,
                  }}
                />
              </TitledContent>
              <TitledContent title={intl.formatMessage({ id: 'common.finishDate' })}>
                <Field
                  className="modifyTaskModal__dataSpan"
                  control={{
                    type: 'date',
                    onChangeAction: e => this.setState({ FinishDateValue: e }),
                    locale: locale === 'ar' ? 'ar' : 'en',
                    value: FinishDateValue,
                    validateOnExite: true,
                    error: moment(newStartDateValue).isAfter(newFinishDateValue) || !FinishDateValue,
                  }}
                />
              </TitledContent>
            </div>

            <div className="modifyTaskModal__row modifyTaskModal__control">
              <Button
                type="button"
                className="modifyTaskModal__cancelButton"
                onClick={this.closeModal}
                disabled={submitting}
              >
                {intl.formatMessage({ id: 'common.cancel' })}
              </Button>
              <Button
                className={submitting ? ' loading' : ''}
                type="button"
                disabled={saveDisabled}
                onClick={this.handleSave}
                backgroundColor={themeColor}
                loading={submitting}
              >
                {intl.formatMessage({ id: 'common.save' })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ language }) => ({
  locale: language.locale,
});

export default connect(mapStateToProps, { addNewTaskAction: addNewTask, _getMyRequests: getMyRequests })(
  injectIntl(AddNewTaskModal),
);
