import React from 'react';

import Tab from './Tab';

import './styles.scss';

function Tabs({ activeTab, onTabChange, tabs }) {
  function handleChange(newTab) {
    if (onTabChange) {
      onTabChange(newTab);
    }
  }

  return (
    <div className="dashboard-tabs">
      {tabs.map(tab => (
        <Tab key={tab.id} data={tab} isActive={activeTab === tab.id} onChange={handleChange} />
      ))}
    </div>
  );
}

export default Tabs;
