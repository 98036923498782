import React from 'react';

function Loader() {
    return (
        <>
            <tr>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__btn-action animated-background" /></td>
            </tr>
            <tr>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__btn-action animated-background" /></td>
            </tr>
            <tr>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__btn-action animated-background" /></td>
            </tr>
            <tr>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__details animated-background" /></td>
                <td><div className="project-financial-loader__btn-action animated-background" /></td>
            </tr>
        </>
    );
}

export default Loader;
