import ProjectAPIController from '../providers/controllers/ProjectAPIController';

export const GET_LIBRARY_STRUCTURE = 'GET_LIBRARY_STRUCTURE';
export const GET_LIBRARY_FILES_LIST = 'GET_LIBRARY_FILES_LIST';
export const SET_LIST_TYPE_LOADING = 'SET_LIST_TYPE_LOADING';
export const POST_LIBRARY_FILE = 'POST_LIBRARY_FILE';
export const ADD_NEW_LIBRARY_SECTION = 'ADD_NEW_LIBRARY_SECTION';

const getLibraryStructure = payload => {
  return { type: GET_LIBRARY_STRUCTURE, payload };
};

const getLibraryFilesList = (data, listType) => {
  return {
    type: GET_LIBRARY_FILES_LIST,
    payload: {
      data,
      listType,
    },
  };
};

const setListTypeLoading = listType => {
  return {
    type: SET_LIST_TYPE_LOADING,
    payload: { listType },
  };
};

const postLibraryFile = payload => {
  return { type: POST_LIBRARY_FILE, payload };
};

export const addNewSection = key => {
  return { type: ADD_NEW_LIBRARY_SECTION, payload: key };
};

export const getLibraryStructureAction = () => {
  return dispatch => {
    dispatch(setListTypeLoading('structure'));
    new ProjectAPIController().getLibraryStructure().then(res => {
      dispatch(getLibraryStructure(res.Data));
    });
  };
};

export const getLibraryFilesListAction = (data, listType) => {
  return dispatch => {
    dispatch(setListTypeLoading(listType));
    new ProjectAPIController().getLibraryFilesList(data).then(res => {
      dispatch(getLibraryFilesList(res.Data, listType));
    });
  };
};

export const uploadLibraryFileAction = (data, listType) => {
  return dispatch => {
    dispatch(setListTypeLoading(listType));
    new ProjectAPIController().uploadLibraryFile(data).then(res => {
      dispatch(
        postLibraryFile({
          file: {
            AttachmentDownloadURL: res.Data,
            FileName: data.request.FileName,
          },
          listType,
        }),
      );
    });
  };
};
