import React from 'react';
import { connect } from 'react-redux';
import groupBy from 'lodash-es/groupBy';
import { Bar } from 'react-chartjs-2';
import { FormattedMessage } from 'react-intl';
import 'chartjs-plugin-datalabels';

import Panel from '../Panel';
import BarChartLoader from '../BarChartLoader/BarChartLoader';
import './styles.scss';
import { numberWithCommas, nFormatter } from '../../Helpers';

const DashboardBarChart = ({
  barData,
  projects = [],
  theme,
  locale,
  type,
  loading,
  customLabels,
  title,
  onClick,
  isProgram = false,
  backgroundColors,
  customLabel,
}) => {
  const typeMap = {
    byStatus: 'Status',
    byPhase: 'ProjectPhase',
    financial: 'Finiancial',
  };

  const themesMap = {
    byStatus: isProgram ? theme.Level_Statuses.item.Items : theme.Statuses.item.Items,
    // byPhase: isProgram ? theme.InitiativePhases.item.Items : theme.Project_Phases.item.Items,
    // byStatus: theme.Statuses.item.Items,
    byPhase: theme.Project_Phases.item.Items,
    financial: [],
  };

  let projectGroups = groupBy(projects, typeMap[type]);

  if (isProgram && type === 'byPhase') {
    projectGroups = groupBy(projects, 'PhaseID');
  }
  const themes = customLabels || themesMap[type];
  const datasets = [
    {
      data: barData || themes.map(item => (projectGroups[item.Value] ? projectGroups[item.Value].length : 0)),
      backgroundColor:
        backgroundColors || themes.map(themeItem => (customLabels ? themeItem.color.trim() : themeItem.Style.trim())),
      barThickness: 30,
    },
  ];
  const labels =
    customLabels ||
    themes.map(themeItem => ({
      name: locale === 'ar' ? themeItem.Arabic : themeItem.English,
      color: themeItem.Style,
    }));
  const values = themes.map(themeItem => themeItem.Value);
  const data = { labels: labels.map(label => label.name), datasets };
  const options = {
    tooltips: {
      callbacks: {
        label: tooltipItem => {
          return type === 'financial' ? numberWithCommas(tooltipItem.yLabel) : tooltipItem.yLabel;
        },
      },
    },
    plugins: {
      legend: false,
      datalabels: {
        align: 'end',
        anchor: 'end',
        font: {
          weight: 'bold',
        },
        textAlign: 'center',
        formatter: value =>
          type === 'financial' || customLabel
            ? (customLabel && customLabel(value)) || nFormatter(value, 2, locale)
            : value,
      },
    },
    scaleLineColor: 'none',
    gridLines: {
      drawBorder: false,
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: window.screen.width > 1024 ? 9 : 7,
            display: false,
            fontFamily: locale === 'ar' ? 'Cairo' : 'Roboto',
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            fontStyle: 'bold',
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          angleLines: {
            display: false,
          },

          gridLines: {
            display: true,
            drawBorder: false,
          },

          ticks: {
            max: Math.max(...data.datasets[0].data) + data.datasets[0].data.reduce((acc, item) => acc + item) / 5,
            beginAtZero: true,
            display: false,
          },
        },
      ],
    },
  };

  return (
    <div className="dashboard__card-chart">
      <Panel
        count={null}
        title={title}
        render={() => (
          <>
            {loading ? (
              <BarChartLoader />
            ) : (
              <>
                {projects.length ? (
                  <>
                    <Bar
                      height={300}
                      data={data}
                      options={options}
                      onElementsClick={el => onClick && onClick(el[0], values)}
                    />
                    <div className="labels-container-bar">
                      {data.labels.map((item, key) => (
                        <div className="labels-container-bar-text" key={key}>
                          {item}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="dashboard__no-data">
                    <FormattedMessage id="common.looksLikeThereIsNoDataHere" />
                  </div>
                )}
              </>
            )}
          </>
        )}
      />
    </div>
  );
};

const mapStateToProps = ({ theme, language }) => ({
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(DashboardBarChart);
