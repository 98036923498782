import React from 'react';
import { FormattedMessage } from 'react-intl';
import Field from '../../../../components/Field';
import { formattedDate, getProjectLocalizedName } from '../../../../Helpers';

const typeMap = {
  affecting: ['AffectingProject', 'AffectingTask', 'affectingTasks'],
  affected: ['AffectedProject', 'AffectedTask', 'affectedTasks'],
};

const ProjectFormSection = ({
  formData,
  setFormData,
  getTasks,
  projects,
  themeColor,
  locale,
  type,
  getRisksIssues,
  errors,
  ...props
}) => {
  const {
    [typeMap[type][2]]: { list, isLoading },
  } = props;

  return (
    <div className="id-section">
      <div className="id-subTitle">
        <h5>
          <FormattedMessage id={`id.${type}-project`} />
        </h5>
      </div>
      <div className="id-section-content">
        <div className="id-section-row">
          <div className="control">
            <span className="label flex-v-center">
              <FormattedMessage id="common.projectTitle" />
            </span>
            <Field
              control={{
                type: 'select',
                onChangeAction: e => {
                  if (formData[typeMap[type][0]].ProjectUID !== e.ProjectUID) {
                    setFormData({ ...formData, [typeMap[type][0]]: e, [typeMap[type][1]]: {} });
                    getTasks(e.ProjectUID, type);
                    getRisksIssues && getRisksIssues(e.ProjectUID);
                  }
                },
                value: formData[typeMap[type][0]],
                options: projects,
                getOptionLabel: option => getProjectLocalizedName(option, locale),
                getOptionValue: option => option.ProjectUID,
                className: 'users-select-container',
                classNamePrefix: 'users-select',
                isLoading: !projects.length,
                error: errors[typeMap[type][0]],
              }}
            />
          </div>
          <div className="control">
            <span className="label flex-v-center">
              <FormattedMessage id={`id.${type}-task`} />
            </span>
            <Field
              control={{
                type: 'select',
                onChangeAction: e => setFormData({ ...formData, [typeMap[type][1]]: e }),
                value: formData[typeMap[type][1]],
                options: list,
                getOptionLabel: option => option.Title,
                getOptionValue: option => option.TaskUID,
                className: 'users-select-container',
                classNamePrefix: 'users-select',
                isLoading,
                error: errors[typeMap[type][1]],
              }}
            />
          </div>
          <div className="control preview date">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.startDate" />
            </span>
            <div>
              {formData[typeMap[type][1]]?.StartDate ? formattedDate(formData[typeMap[type][1]].StartDate) : '---'}
            </div>
          </div>
          <div className="control preview date">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.finishDate" />
            </span>
            <div>
              {formData[typeMap[type][1]]?.FinishDate ? formattedDate(formData[typeMap[type][1]].FinishDate) : '---'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectFormSection;
