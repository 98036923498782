import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addToast } from '../../../Helpers';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import ResourcesUtilizationComponent from '../../../components/ResourcesUtilization';
import Loader from './Loading';

function ResourcesUtilization({ theme, history, locale }) {
  const [resources, setResources] = useState(null);
  const [loading, setLoading] = useState(true);

  function getResourcesCapacity() {
    new ProjectAPIController()
      .getResourcesCapacity()
      .then(res => {
        setResources(res.Data);
        setLoading(false);
      })
      .catch(() => {
        addToast.error('error-get-data');
      });
  }

  useEffect(() => {
    getResourcesCapacity();
  }, [history.location]);

  if (loading) return <Loader />;

  return (
    <div>
      <ResourcesUtilizationComponent theme={theme} locale={locale} resources={resources} />
    </div>
  );
}

const mapStateToProps = ({ language, theme }) => ({
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(ResourcesUtilization);
