import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Collapse from '../Collapse';
import ProgressBar from '../ProgressBar';
import { getStatusColor } from '../../Helpers';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/arrow-dwon.svg';

class TabItem extends Component {
  state = {
    isExpanded: false,
  };

  handleExpanding = event => {
    event.stopPropagation();
    this.setState(previousState => {
      return {
        isExpanded: !previousState.isExpanded,
      };
    });
  };

  getProjectTitle = ({ NameArabic, NameEnglish }) => {
    const { locale } = this.props;
    if (locale === 'ar') {
      if (NameArabic) return NameArabic;
      return NameEnglish;
    }
    if (NameEnglish) return NameEnglish;
    return NameArabic;
  };

  render() {
    const { handleRoute, item, theme, locale, intl } = this.props;

    const { isExpanded } = this.state;

    return (
      <div className="details-body">
        <div className="project-details">
          {/* TODO Fix */}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className="content cursor" onClick={() => handleRoute(item.ProjectUID)}>
            <div className="content-container">
              <div className="content-starting">
                <p className="entity-title">{`${this.getProjectTitle(item)}`}</p>
              </div>

              <div className="content-trailing">
                <div className="entity-progress">
                  <ProgressBar
                    firstLineValue={item.Progress}
                    firstLineColor={getStatusColor(theme, item.Status)}
                    firstLineTitle={intl.formatMessage({ id: 'portfolio.progress' })}
                    secondValue={`${item.Progress} %`}
                    bigNumber
                  />
                </div>
                <button
                  className={`${locale === 'ar' ? 'rtl ' : ''} btn-collapse cursor`}
                  onClick={event => this.handleExpanding(event)}
                >
                  {isExpanded ? (
                    <ArrowUp width="30px" height="30px" fill="#BFBFBF" />
                  ) : (
                    <ArrowDown width="30px" height="30px" fill="#BFBFBF" />
                  )}
                </button>
              </div>
            </div>
          </div>
          {isExpanded && (
            <div className="col-content">
              <Collapse
                className="cursor"
                locale={locale}
                theme={theme}
                item={item}
                handleRoute={() => handleRoute(item.ProjectUID)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(TabItem);
