import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import TasksPopUp from './TasksPopUp';
// import moment from 'moment';

import './style.scss';

function TableItem({ themeColor, intl, color, index, rowindex, tasks, lastIndex, locale, theItem }) {
  const [isShown, setIsShown] = useState(false);
  const [id, setid] = useState(index);
  const [rowid, setrowindex] = useState(rowindex);
  const [isOpen, setIsOpen] = useState(false);

  const projectTasks = tasks.filter(item => {
    return item.IsProjecttask;
  });
  const nonProjectTasks = tasks.filter(item => {
    return !item.IsProjecttask;
  });

  return (
    <>
      <div
        className="day-cell"
        style={{
          backgroundColor: color,
        }}
        onMouseOver={() => {
          setIsShown(true);
          setrowindex(rowindex);
          setid(index);
        }}
        onFocus={() => {
          setIsShown(true);
          setrowindex(rowindex);
          setid(index);
        }}
        onMouseOut={() => {
          setid(null);
          setrowindex(null);
          setIsShown(!isShown);
        }}
        onBlur={() => {
          setid(null);
          setrowindex(null);
          setIsShown(!isShown);
        }}
        onClick={() => setIsOpen(true)}
      />
      {isShown && index === id && rowid === rowindex ? (
        <div
          className={`tooltip ${
            id === lastIndex || id === lastIndex - 1 || id === lastIndex - 2 ? 'tooltip-last-index' : ''
          }`}
        >
          <div className="text-tooltip">
            <FormattedMessage id="common.projects-tasks-count" /> : {projectTasks.length}
          </div>
          <div className="text-tooltip">
            <FormattedMessage id="common.other-tasks-count" /> : {nonProjectTasks.length}
          </div>
        </div>
      ) : null}
      {isOpen && (
        <TasksPopUp
          toggleModal={() => setIsOpen(!isOpen)}
          projectTasks={projectTasks}
          nonProjectTasks={nonProjectTasks}
          themeColor={themeColor}
          intl={intl}
          locale={locale}
          item={theItem}
          day={`${theItem.Month}/${index+1}/${theItem.Year}`}
        />
      )}
    </>
  );
}
export default injectIntl(TableItem);
