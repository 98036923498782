import React from 'react';
import './style.scss';

export default () => (
  <div className="RiskLoading__container animated-background">
    <div className="RiskLoading__content">
      <div className="RiskLoading__content-status flex-h-v-center">
        <div className="shadow-background" />
      </div>
    </div>

    <div className="RiskLoading__details">
      <div className="RiskLoading__details-row">
        <div className="RiskLoading__details-item">
          <p className="RiskLoading__details-item-title shadow-background" />
          <p className="RiskLoading__details-item-title shadow-background" />
        </div>
      </div>

      <div className="RiskLoading__details-row">
        <div className="RiskLoading__details-item">
          <p className="RiskLoading__details-item-title shadow-background" />
          <p className="RiskLoading__details-item-title shadow-background" />
        </div>
        <div className="RiskLoading__details-item">
          <p className="RiskLoading__details-item-title shadow-background" />
          <p className="RiskLoading__details-item-title shadow-background" />
        </div>
        <div className="RiskLoading__details-item">
          <p className="RiskLoading__details-item-title shadow-background" />
          <p className="RiskLoading__details-item-title shadow-background" />
        </div>
        <div className="RiskLoading__details-item">
          <p className="RiskLoading__details-item-title shadow-background" />
          <p className="RiskLoading__details-item-title shadow-background" />
        </div>
      </div>
      <div className="RiskLoading__details-row">
        <div className="RiskLoading__details-item">
          <p className="RiskLoading__details-item-desc shadow-background" />
        </div>
      </div>
      <div className="RiskLoading__details-row">
        <div className="RiskLoading__details-item">
          <p className="RiskLoading__details-item-title shadow-background" />
          <p className="RiskLoading__details-item-title shadow-background" />
        </div>
        <div className="RiskLoading__details-item">
          <p className="RiskLoading__details-item-title shadow-background" />
          <p className="RiskLoading__details-item-title shadow-background" />
        </div>
      </div>
    </div>
  </div>
);
