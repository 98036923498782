import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Parse from 'parse';
import { connect } from 'react-redux';
import ChatWindow from './ChatWindow';
import launcherIcon from '../assets/logo-no-bg.svg';
import incomingMessageSound from '../assets/sounds/notification.mp3';
import launcherIconActive from '../assets/close-icon.svg';
import '../styles/launcher.css';
import '../styles/header.css';
import '../styles/emojiPicker.css';
import '../styles/message.css';
import '../styles/popup-window.css';
import '../styles/user-input.css';
import '../styles/chat-window.css';
import { convertParseObjectsToMessages, getChatFileType, toBase64 } from '../../../Helpers';

class Launcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatInitialized: false,
      messageList: [],
      isOpen: false,
    };
    this.chatRef = React.createRef();
  }

  componentDidMount() {
    const { chatInitialized } = this.state;
    if (!chatInitialized) {
      this.initializeChatConnection();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.mute) {
      return;
    }
    const { messageList } = this.state;
    const nextMessage = messageList[messageList.length - 1];
    const isIncoming = (nextMessage || {}).author === 'them';

    const isNew = messageList.length > prevState.messageList.length;

    if (isIncoming && isNew) {
      this.playIncomingMessageSound();
    }
  }

  componentWillUnmount() {
    const { chatInitialized } = this.state;

    if (chatInitialized) {
      Parse.LiveQuery.close();
    }
  }

  initializeChatConnection = async () => {
    const { user, code } = this.props;
    Parse.serverURL = window.env.CHAT_URL;
    Parse.initialize('splus', 'net.masterworks.apps.splus');
    const Message = Parse.Object.extend('Message');
    const query = new Parse.Query(Message)
      .equalTo('project', code)
      .descending('createdAt')
      .limit(50);
    let results = await query.find();
    results = results.reverse();

    this.setState({
      messageList: convertParseObjectsToMessages(results, user.user ? user.user.Username : ''),
      chatInitialized: true,
    });

    const messagesSubscription = await query.subscribe();
    messagesSubscription.on('create', parseObject => {
      this.setState(previousState => {
        return {
          messageList: [
            ...previousState.messageList,
            ...convertParseObjectsToMessages([parseObject], user.user ? user.user.Username : ''),
          ],
        };
      });
      this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
    });
    this.chatRef.current.scrollTop = this.chatRef.current.scrollHeight;
  };

  onMessageWasSent = async message => {
    const { user, code } = this.props;
    const Message = Parse.Object.extend('Message');
    const newMessage = new Message({
      creationDate: new Date().getTime(),
      body_text: message.data.text,
      sender_id: user.user.Username,
      display_name: user.user.DisplayName,
      project: code,
      type: 'TEXT',
    });
    newMessage.save();
  };

  onFileSelected = files => {
    const { user, code } = this.props;
    Object.keys(files).forEach(async key => {
      const file = files[key];
      const base64 = await toBase64(file);
      const uploadedFile = await new Parse.File(file.name, { base64 }).save();
      const Message = Parse.Object.extend('Message');
      const messageObject = {
        creationDate: new Date().getTime(),
        sender_id: user.user.Username,
        display_name: user.user.DisplayName,
        downloadUrl: uploadedFile._url,
        project: code,
        file_name: file.name,
        file_extension: getChatFileType(file.name),
      };
      let newMessage;
      if (file.type.startsWith('image')) {
        newMessage = new Message({
          ...messageObject,
          type: 'IMAGE',
        });
      } else {
        newMessage = new Message({
          ...messageObject,
          type: file.type.startsWith('audio') ? 'VOICE' : 'FILE',
        });
      }
      newMessage.save();
    });
  };

  handleOpenChat = () => {
    this.setState(previousState => {
      return {
        isOpen: !previousState.isOpen,
      };
    });
  };

  getMoreMessages = async page => {
    const { user, code } = this.props;
    const Message = Parse.Object.extend('Message');
    const query = new Parse.Query(Message)
      .equalTo('project', code)
      .descending('createdAt')
      .limit(50)
      .skip(50 * page);
    let results = await query.find();
    results = results.reverse();
    this.setState(prevState => ({
      messageList: [
        ...convertParseObjectsToMessages(results, user.user ? user.user.Username : ''),
        ...prevState.messageList,
      ],
    }));
  };

  playIncomingMessageSound = () => {
    const audio = new Audio(incomingMessageSound);
    audio.play();
  };

  handleClick = () => {
    const { handleClick: handleClick1 } = this.props;
    if (handleClick1 !== undefined) {
      handleClick1();
    } else {
      this.handleOpenChat();
    }
  };

  render() {
    const { theme, locale, newMessagesCount, showEmoji, agentProfile } = this.props;

    const { messageList, isOpen } = this.state;

    const classList = ['sc-launcher', isOpen ? 'opened' : ''];
    return (
      <div id="sc-launcher">
        <div
          className={classList.join(' ')}
          onClick={this.handleClick}
          style={{
            backgroundColor: theme.ThemeColor.item.Text,
            right: locale === 'en' ? '25px' : 'unset',
            left: locale === 'en' ? 'unset' : '25px',
          }}
        >
          <MessageCount count={newMessagesCount} isOpen={isOpen} />
          <img className="sc-open-icon" src={launcherIconActive} alt="launcherIconActive" />
          <img className="sc-closed-icon" src={launcherIcon} alt="launcherIcon" />
        </div>
        <ChatWindow
          theme={theme}
          getMoreMessages={this.getMoreMessages}
          locale={locale}
          messageList={messageList}
          handleUserInputSubmit={this.onMessageWasSent}
          onFilesSelected={this.onFileSelected}
          agentProfile={agentProfile}
          chatRef={this.chatRef}
          isOpen={isOpen}
          onClose={this.handleClick}
          showEmoji={showEmoji}
        />
      </div>
    );
  }
}

const MessageCount = props => {
  const { count, isOpen } = props;
  if (count === 0 || isOpen === true) {
    return null;
  }
  return <div className="sc-new-messages-count">{count}</div>;
};

Launcher.propTypes = {
  newMessagesCount: PropTypes.number,
  handleClick: PropTypes.func,
  mute: PropTypes.bool,
  showEmoji: PropTypes.bool,
};

Launcher.defaultProps = {
  newMessagesCount: 0,
  showEmoji: true,
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(Launcher);
