import React from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../Button';
import Modal from '../Modal';
import TitledContent from '../TitledContent';
import Field from '../Field';
import Slider from '../Slider';
import Controllers from '../Approvals/children/Controllers';
import AttachmentsList from '../AttachmentsList';

import { postMyUpdatedTasks } from '../../actions/tasksCenter';
import {
  encodeAttachments,
  getTaskClassification,
  taskClassificationName,
  taskClassificationColor,
  getTaskStatus,
  addToast,
} from '../../Helpers';

import './styles.scss';

class NonProjectTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveDisabled: true,
      submitting: false,
      percentage: props.task ? props.task.DraftPercentage : 0,
      attachments: props.task ? props.task.Attachments || [] : [],
    };
  }

  componentDidUpdate(prevProps) {
    const { task, isOpen } = this.props;
    if (task && !prevProps.isOpen && isOpen) {
      this.updateState();
    }
  }

  updateState = () => {
    const { task } = this.props;
    this.setState({
      percentage: task.DraftPercentage,
      attachments: task.Attachments,
    });
  };

  handlePercentageChange = val => {
    this.setState({
      percentage: val,
      saveDisabled: false,
    });
  };

  handleSave = async () => {
    const {
      state: { percentage, attachments },
      props: { task, postMyUpdatedTasksAction, onClose },
    } = this;
    this.setState({
      submitting: true,
      saveDisabled: true,
    });

    let _attachments = attachments.reduce(
      (accum, attachment) => {
        if (attachment instanceof File) {
          accum.newFiles.push(attachment);
        } else {
          accum.oldFiles.push({
            ...attachment,
            UID: 'some-unique-id',
          });
        }
        return accum;
      },
      { newFiles: [], oldFiles: [] },
    );

    let newFiles = await encodeAttachments(_attachments.newFiles);
    newFiles = newFiles.map((base64Attachment, index) => {
      return {
        AttachmentBase64: base64Attachment.replace(/^data:(.*,)?/, ''),
        AttachmentDownloadURL: '',
        Base64Data: base64Attachment,
        FileType: 'text/plain',
        ID: 0,
        Name: _attachments.newFiles[index].name,
        Type: 'text/plain',
        URL: base64Attachment,
        isDeleted: false,
      };
    });

    _attachments = [...newFiles, ..._attachments.oldFiles];

    const body = {
      data: [
        {
          ...task,
          Attachments: _attachments,
          DraftPercentage: percentage,
          StatusID: 3, // pending
        },
      ],
    };
    postMyUpdatedTasksAction(body, () => {
      this.setState({
        submitting: false,
      });
      addToast.success('common.changesHaveBeenSaved');
      onClose();
    });
  };

  render() {
    const {
      props: {
        isOpen,
        editable = true,
        onClose,
        task,
        intl,
        theme,
        taskApprovalStatuses,
        locale,
        tasksClassifications,
        _postTaskStatus,
        controllersLoading,
        removeControllersLoading,
      },
      state: { attachments, saveDisabled, percentage, submitting },
    } = this;
    if (!isOpen) return null;
    const themeColor = theme.ThemeColor.item.Text;
    const statusText = taskClassificationName(tasksClassifications, getTaskClassification(task), locale);
    const statusColor = taskClassificationColor(tasksClassifications, getTaskClassification(task));
    const lastChangeStatus = getTaskStatus(taskApprovalStatuses, task.StatusID, locale);

    return (
      <div>
        <Modal title={intl.formatMessage({ id: 'common.details' })} isOpen={isOpen} onClose={onClose}>
          <div className={`nonProjectTaskModal__content${submitting ? ' loading' : ''}`}>
            <div className="nonProjectTaskModal__row">
              <div className="nonProjectTaskModal__startingCol nonProjectTaskModal__col">
                <TitledContent title={intl.formatMessage({ id: 'common.title' })}>
                  <span className="nonProjectTaskModal__dataSpan">{task.Title}</span>
                </TitledContent>
                <TitledContent title={intl.formatMessage({ id: 'common.description' })}>
                  <span className="nonProjectTaskModal__dataSpan">{task.Description}</span>
                </TitledContent>
                <div className="nonProjectTaskModal__midCol">
                  <TitledContent title={intl.formatMessage({ id: 'common.startDate' })}>
                    <span className="nonProjectTaskModal__dataSpan">
                      {moment(task.StartDate).format('DD-MMM-YYYY')}
                    </span>
                  </TitledContent>
                  <TitledContent title={intl.formatMessage({ id: 'common.finishDate' })}>
                    <span className="nonProjectTaskModal__dataSpan">
                      {moment(task.FinishDate).format('DD-MMM-YYYY')}
                    </span>
                  </TitledContent>
                </div>
                <div className="nonProjectTaskModal__midCol">
                  <TitledContent title={intl.formatMessage({ id: 'task.taskActualCompletion' })}>
                    <span className="nonProjectTaskModal__dataSpan">{task.ActualPercentage || '0'}%</span>
                  </TitledContent>
                  <TitledContent title={intl.formatMessage({ id: 'task.lastChangeStatus' })}>
                    <span className="nonProjectTaskModal__dataSpan">
                      <span style={{ color: lastChangeStatus.style, fontWeight: 600 }}>
                        {` ${lastChangeStatus.name}`}
                      </span>
                    </span>
                  </TitledContent>
                </div>
              </div>
              <div className="nonProjectTaskModal__trailingCol nonProjectTaskModal__col">
                <TitledContent title={intl.formatMessage({ id: 'common.assignedTo' })}>
                  <span className="nonProjectTaskModal__dataSpan">{task.AssignTo && task.AssignTo.DisplayName}</span>
                </TitledContent>
                <TitledContent title={intl.formatMessage({ id: 'common.status' })}>
                  <span className="nonProjectTaskModal__dataSpan" style={{ color: statusColor, fontWeight: 600 }}>
                    {statusText}
                  </span>
                </TitledContent>
                {editable && (
                  <TitledContent title={intl.formatMessage({ id: 'common.completion' })}>
                    <div className="modifyTaskModal__slider-wrapper">
                      <Slider
                        value={percentage}
                        onChangePercent={e => this.handlePercentageChange(e)}
                        showPercentage={false}
                        width="260px"
                        theme={theme}
                        item={task}
                        miniPercentage
                        disabled={controllersLoading || _postTaskStatus}
                      />
                    </div>
                  </TitledContent>
                )}
              </div>
            </div>
            {editable && !_postTaskStatus ? (
              <div className="nonProjectTaskModal__row">
                <TitledContent title={intl.formatMessage({ id: 'common.attachments' })}>
                  <Field
                    control={{
                      type: 'attachment',
                      onChangeAction: e => {
                        this.setState({ saveDisabled: false, attachments: [...e] });
                      },
                      value: attachments,
                      // validation: this.inputValidation,
                      validateOnExite: false,
                    }}
                  />
                </TitledContent>
              </div>
            ) : null}

            {(_postTaskStatus || (!editable && !_postTaskStatus)) && task.Attachments && task.Attachments.length ? (
              <TitledContent title={intl.formatMessage({ id: 'common.attachments' })}>
                <AttachmentsList attachments={task.Attachments} />
              </TitledContent>
            ) : null}

            <div className="nonProjectTaskModal__row nonProjectTaskModal__control custom_control_approval">
              {_postTaskStatus ? (
                <Controllers
                  isEventModal
                  _postTaskStatus={_postTaskStatus}
                  data={task}
                  themeColor={themeColor}
                  onCloseModal={onClose}
                  type="nonProjectsTasks"
                  controllersLoading={controllersLoading}
                  removeControllersLoading={removeControllersLoading}
                />
              ) : (
                <div className="nonProjectTaskModal__buttons">
                  <Button
                    type="button"
                    className="nonProjectTaskModal__cancelButton"
                    onClick={onClose}
                    disabled={submitting || controllersLoading}
                  >
                    {intl.formatMessage({ id: 'common.cancel' })}
                  </Button>
                  {editable && (
                    <Button
                      className={submitting ? ' loading' : ''}
                      type="button"
                      disabled={saveDisabled || controllersLoading}
                      onClick={this.handleSave}
                      backgroundColor={themeColor}
                      loading={submitting}
                    >
                      {intl.formatMessage({ id: 'common.save' })}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { postMyUpdatedTasksAction: postMyUpdatedTasks })(injectIntl(NonProjectTaskModal));
