import React from 'react';

import Field from '../Field';

function FormItem({ label, options, value, onChangeAction, ...rest }) {
  return (
    <div className="dashboard-filters-popup__form-item">
      <Field
        label={{
          label,
        }}
        control={{
          type: 'select',
          options,
          value,
          onChangeAction,
          isClearable: true,
          ...rest,
        }}
      />
    </div>
  );
}

export default FormItem;
