import moment from 'moment';
import 'moment/locale/ar';

import { TOGGLE_LANGUAGE, TOGGLE_LANGUAGE_SETTINGS } from '../actions';

const language = {
  en: {
    locale: 'en',
    direction: 'ltr',
  },
  ar: {
    locale: 'ar',
    direction: 'rtl',
  },
};

const persistedLanguage = JSON.parse(localStorage.getItem('persist:root'));
const initialState = persistedLanguage ? JSON.parse(persistedLanguage.language) : { ...language.en };

function languageReducer(state = initialState, action) {
  const html = document.documentElement;

  switch (action.type) {
    case TOGGLE_LANGUAGE: {
      const { key } = action.payload;
      html.style.direction = key === 'en' ? 'ltr' : 'rtl';
      document.body.style.direction = key === 'en' ? 'ltr' : 'rtl';
      html.setAttribute('lang', key);
      moment.locale(key);

      return {
        ...state,
        locale: key,
        direction: key === 'en' ? 'ltr' : 'rtl',
      };
    }
    case TOGGLE_LANGUAGE_SETTINGS:
      html.style.direction = 'ltr';
      document.body.style.direction = 'ltr';
      html.setAttribute('lang', 'en');

      moment.locale('en');
      return {
        ...state,
        locale: 'en',
        direction: 'ltr',
      };
    default:
      document.body.style.direction = state.locale === 'ar' ? 'rtl' : 'ltr';
      html.style.direction = state.locale === 'ar' ? 'rtl' : 'ltr';
      html.setAttribute('lang', state.locale === 'ar' ? 'ar' : 'en');
      moment.locale(state.locale === 'ar' ? 'ar' : 'en');
      return state;
  }
}

export default languageReducer;
