import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Button from '../Button';
import FormItem from './FormItem';
import CheckBox from '../Field/Control/CheckBox';

import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { reduceUsersList } from '../../Helpers';

import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';

import './styles.scss';

function DashboardFiltersPopUp({ themeColor, intl, theme, locale, history, onApplyFilters, level3Only, project }) {
  const initialFilters = {};

  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState(initialFilters);

  const isAr = locale === 'ar';

  function toggleModal() {
    setOpen(!open);
  }

  function updateFilters(key, value) {
    setFilters({ ...filters, [key]: value });
  }

  function applyFilters(newFilters) {
    if (onApplyFilters) {
      onApplyFilters(newFilters);
    }
  }

  function clearAndClose() {
    setFilters(initialFilters);
    applyFilters(initialFilters);
    toggleModal();
  }

  function onChangeCheckBox() {
    updateFilters('isAnnounced', !filters.isAnnounced);
  }

  async function getUsers() {
    try {
      const res = await new ProjectAPIController().getAllUsers();
      if (Array.isArray(res.Data)) {
        setUsers(reduceUsersList(res.Data));
      }
    } catch (err) {
      console.log({ err });
    }
  }

  useEffect(() => {
    getUsers();
  }, [history]);

  return (
    <div className="dashboard-filters-popup">
      <div className="dashboard-filters-content">
        {open && (
          <div className="dashboard-filters-modal">
            <div className="dashboard-filters-title">
              <FormattedMessage id="dashboard.data-categories" />
              <CloseIcon style={{ cursor: 'pointer' }} onClick={toggleModal} />
            </div>
            <div className="items-container">
              <FormItem
                label={intl.formatMessage({ id: 'common.phase' })}
                options={[...theme.Project_Phases.item.Items]}
                getOptionLabel={data => (isAr ? data.Arabic : data.English)}
                getOptionValue={data => data.Value}
                onChangeAction={selected => updateFilters('phase', selected)}
                value={filters.phase}
              />

              <FormItem
                label={intl.formatMessage({ id: 'common.status' })}
                options={project ? theme.Statuses?.item.Items : theme.Level_Statuses?.item.Items}
                getOptionLabel={data => (isAr ? data.Arabic : data.English)}
                getOptionValue={data => data.Value}
                onChangeAction={selected => updateFilters('status', selected)}
                value={filters.status}
              />
              {!level3Only && (
                <>
                  <FormItem
                    options={[...users]}
                    label={intl.formatMessage({ id: 'programs.program-owner' })}
                    onChangeAction={selected => updateFilters('programManager', selected)}
                    value={filters.programManager}
                    menuPlacement="top"
                  />

                  {/* <div className="dashboard-filters-popup__checkbox-container">
                    <FormattedMessage id="dashboard.announced" />
                    <CheckBox onChangeCheckBox={onChangeCheckBox} value={filters.isAnnounced} />
                  </div> */}
                </>
              )}
              {level3Only && (
                <FormItem
                  options={[...users]}
                  label={intl.formatMessage({ id: 'common.projectManager' })}
                  onChangeAction={selected => updateFilters('manager', selected)}
                  value={filters.manager}
                />
              )}
              <div className="footer-btns">
                <Button backgroundColor={themeColor} className="footer-btns__btn" onClick={clearAndClose}>
                  <FormattedMessage id="common.cancel" />
                </Button>
                <Button backgroundColor={themeColor} className="footer-btns__btn" onClick={() => applyFilters(filters)}>
                  <FormattedMessage id="common.apply" />
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="dashboard-filters-btn-container">
          <Button className="dashboard-filters-btn" backgroundColor={themeColor} onClick={toggleModal}>
            <FilterIcon />
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ theme, language }) => ({
  locale: language.locale,
  themeColor: theme.ThemeColor.item.Text,
  theme,
});

export default injectIntl(connect(mapStateToProps)(withRouter(DashboardFiltersPopUp)));
