import React, { useState, useEffect } from 'react';
import AddProject from '.';
import ProjectPreview from './ProjectPreview';

const ProjectEdit = props => {
  const { readOnly } = props;
  const [view, setView] = useState('preview');

  function changeView(newView) {
    setView(newView);
  }

  useEffect(() => {
    setView(readOnly ? 'preview' : 'add');
  }, [readOnly]);

  if (readOnly && view === 'preview') {
    return <ProjectPreview {...props} changeView={changeView} />;
  }

  if (view === 'add') {
    return <AddProject {...props} />;
  }

  return null;
};

export default ProjectEdit;
