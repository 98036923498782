import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';

import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import AddProject from '../AddProject';
import { reduceCustomFields, addToast } from '../../../Helpers';
import { compsMap } from '../../../Helpers/processes';

import ProcessesLoader from '../../../components/ProcessesLoader';

function CreateProcess(props) {
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);

  const { history, match } = props;
  const {
    params: { process },
  } = match;

  const { location } = history;

  const { ProjectUID } = queryString.parse(history.location.search);

  const ProcessComponent = process !== 'newproject' ? compsMap[process] : AddProject;

  async function getProjectByCode() {
    try {
      const res = await new ProjectAPIController().getProjectByCode(ProjectUID);
      setProject(reduceCustomFields(res.Data));
      setLoading(false);
    } catch {
      addToast.error('common.fetch-failed-error');
    }
  }

  useEffect(() => {
    if (process !== 'newproject') {
      getProjectByCode();
    }
  }, [history]);

  if (!ProcessComponent) return <Redirect from={location.pathname} to="/" />;

  if (loading && process !== 'newproject') return <ProcessesLoader />;

  return <ProcessComponent {...props} code={ProjectUID} project={project} processType={process} />;
}

export default CreateProcess;
