import React from 'react';

import './styles.scss';

const Chip = ({ text, color }) => {
  return (
    <div className="chip__container" style={{ backgroundColor: color }}>
      {text}
    </div>
  );
};

export default Chip;
