import React, { useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { numberWithCommas } from '../../Helpers';
import Field from '../Field';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/save.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { connect } from 'react-redux';

const FinancialListItem = (props) => {
    const { data, isSelected, onSave, onSelect, onCancel, isEditMode, isAddMode, isSaving, locale } = props;
    const [SpentAmount, setSpentAmount] = useState(data.SpentAmount);
    const [SpentDate, setSpentDate] = useState(data.SpentDate);
    const [Comment, setComment] = useState(data.Comment);

    function handleSave() {
        delete data.SpentDate;
        const newData = {
            ...data,
            SpentAmount: parseFloat(SpentAmount),
            SpentDateValue: moment(SpentDate).format('DD-MM-YYYY'),
            Comment
        }
        return onSave(newData);
    }

    function handleCancel() {
        setSpentAmount(data.SpentAmount);
        setSpentDate(data.SpentDate);
        setComment(data.Comment);
        return onCancel();
    }

    function isValid() {
        return SpentAmount > 0 &&
            SpentDate &&
            Comment.length
    }

    return (
        (isEditMode || isAddMode) && isSelected ?
            <tr>
                <td dir="ltr">
                    <Field
                        control={{
                            disabled: isSaving,
                            type: 'date',
                            onChangeAction: e => setSpentDate(e),
                            locale: locale === 'ar' ? 'ar' : 'en',
                            value: SpentDate ? moment(SpentDate).locale('en').format('ll') : null,
                            validateOnExite: true,
                            error: !SpentDate,
                        }}
                    />
                </td>
                <td dir="ltr">
                    <Field
                        control={{
                            disabled: isSaving,
                            type: 'number',
                            tabIndex: "1",
                            onChangeAction: e => setSpentAmount(e.target.value),
                            value: SpentAmount,
                            error: !SpentAmount || SpentAmount < 1,
                            validateOnExite: true,
                        }}
                    />
                </td>
                <td>
                    <Field
                        control={{
                            disabled: isSaving,
                            type: 'text',
                            onChangeAction: e => setComment(e.target.value),
                            value: Comment,
                            error: !Comment.length,
                            validateOnExite: true,
                        }}
                    />
                </td>
                <td>
                    <button type="button" onClick={() => handleSave()} disabled={isSaving || !isValid()}>
                        {isSaving ? <span className="spinner" />
                            : <SaveIcon fill="white" stroke="white" width={13} height={13} />
                        }
                    </button>
                    <button type="button" className="btn-cancel" onClick={() => handleCancel()} disabled={isSaving}>
                        <CloseIcon fill="white" stroke="white" width={13} height={13} />
                    </button>
                </td>
            </tr>
            : <tr>
                <td>
                    {moment(data.SpentDate).format('ll')}
                </td>
                <td>
                    {numberWithCommas(data.SpentAmount)} <FormattedMessage id='common.SAR' />
                </td>
                <td>
                    {data.Comment}
                </td>
                <td>
                    <button type="button" disabled={(isEditMode && !isSelected) || isSaving} onClick={() => onSelect(data)} >
                        <EditIcon width={13} height={13} />
                    </button>
                </td>
            </tr>
    );
}
const mapStateToProps = (theme) => {
    return {
        locale: theme.locale
    }
}

export default connect(mapStateToProps)(FinancialListItem);