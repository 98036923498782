import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../Button';
import Field from '../../Field';
import Loader from '../Details/loader';
import { ReactComponent as BackIcon } from '../../../assets/images/back-arrow.svg';

import {
  postDeliverable,
  deleteDeliverable,
  deleteDeliverablesData,
  getAllDeliverables,
} from '../../../actions/deliverables';
import { mergeAttachments, addToast } from '../../../Helpers';

import './styles.scss';

const initialValues = {
  ActualFinishDate: moment(),
  Attachments: [],
  CompletionProgress: 0,
  HasAttachments: false,
  ID: 0,
  PlannedFinishDate: moment(),
  SuccessMeasure: '',
  Target: '',
  Title: '',
};
class Add extends React.Component {
  constructor(props) {
    super(props);
    if (props.editMode && Object.keys(props.deliverable).length) {
      this.state = {
        ...props.deliverable,
      };
    } else {
      this.state = {
        ...initialValues,
      };
    }
  }

  componentDidMount() {
    const { editMode, match, deliverable, getDeliverableById } = this.props;
    if (editMode && match.params.code && match.params.id) {
      if (
        (Object.keys(deliverable).length && deliverable.ID.toString() !== match.params.id) ||
        !Object.keys(deliverable).length
      ) {
        getDeliverableById(match.params.code, match.params.id);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { loading, editMode } = this.props;
    if (editMode && loading !== prevProps.loading) {
      this.setValues();
    }
  }

  setValues = () => {
    const { deliverable } = this.props;
    this.setState({ ...deliverable });
  };

  getSaveDisabled = () => {
    const {
      state: { Title, PlannedFinishDate, CompletionProgress, ActualFinishDate },
      props: {
        loaders: { postingDeliverable },
      },
    } = this;

    const CompletionProgressStatus = CompletionProgress > 100 || CompletionProgress < 0;

    if (!Title.length || !PlannedFinishDate || CompletionProgressStatus || !ActualFinishDate) {
      return true;
    }

    return postingDeliverable;
  };

  throwError = () => {
    addToast.error('common.fillRequiredFields', {
      toastId: 'submit-deliverable-error',
    });
  };

  handleDeliverableSubmit = async () => {
    if (this.getSaveDisabled()) this.throwError();
    else {
      const {
        state: { Attachments, PlannedFinishDate, ActualFinishDate, CompletionProgress, ...formData },
        props: { match, postDeliverableAction, changeView, getAllDeliverablesAction },
      } = this;
      const _attachments = await mergeAttachments(Attachments || []);
      const { code } = match.params;
      const body = {
        programUID: code,
        formData: {
          ...formData,
          CompletionProgress: Number.isNaN(CompletionProgress) ? 0 : CompletionProgress,
          Attachments: _attachments,
          HasAttachments: !!_attachments.length,
          PlannedFinishDate: moment(PlannedFinishDate)
            .locale('en')
            .format('DD-MM-YYYY'),
          ActualFinishDate: moment(ActualFinishDate)
            .locale('en')
            .format('DD-MM-YYYY'),
          ProjectUID: code,
        },
      };

      postDeliverableAction(body, () => {
        changeView('base');
        getAllDeliverablesAction(code);
        addToast.success('common.changesHaveBeenSaved');
      });
    }
  };

  handleDeliverableDelete = () => {
    const {
      props: { match, deleteDeliverableAction, changeView, deleteDeliverablesDataAction },
    } = this;

    deleteDeliverableAction(match.params.code, match.params.id, () => {
      deleteDeliverablesDataAction(match.params.id);
      addToast.success('common.itemHasBeenDeleted');
      changeView('base');
    });
  };

  render() {
    const {
      state: {
        ID,
        Title,
        PlannedFinishDate,
        SuccessMeasure,
        Target,
        CompletionProgress,
        ActualFinishDate,
        Attachments,
      },
      props: {
        editMode,
        changeView,
        themeColor,
        locale,
        intl,
        loading,
        loaders: { postingDeliverable, deletingDeliverable },
      },
    } = this;

    return (
      <div className="deliverables__container">
        <div className="deliverables__header">
          <div className="deliverables__title">
            <BackIcon fill={themeColor} onClick={() => (ID ? changeView('details', ID) : changeView('base'))} />
            <FormattedMessage id="deliverables.add-new-deliverable" />
          </div>
          <div className="deliverables__actions">
            <Button
              className="action secondary-action"
              type="submit"
              onClick={() => (ID ? changeView('details', ID) : changeView('base'))}
              color={themeColor}
              border
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            {editMode ? (
              <Button
                disabled={deletingDeliverable}
                className="action"
                loading={deletingDeliverable}
                type="submit"
                onClick={this.handleDeliverableDelete}
                style={{ backgroundColor: themeColor }}
              >
                <FormattedMessage id="common.delete" />
              </Button>
            ) : null}
            <Button
              disabled={this.getSaveDisabled()}
              className="action"
              type="submit"
              onClick={this.handleDeliverableSubmit}
              backgroundColor={themeColor}
              loading={postingDeliverable}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="deliverables__content deliverables__add-content">
            <form className={`deliverables__form ${postingDeliverable || deletingDeliverable ? 'loading' : ''}`}>
              <div className="deliverables__form-col">
                <div className="deliverables__form-control">
                  <span className="label flex-v-center">
                    <FormattedMessage id="deliverables.deliverable-name" />
                  </span>
                  <Field
                    control={{
                      type: 'input',
                      onChangeAction: e => this.setState({ Title: e.target.value }),
                      placeholder: intl.formatMessage({ id: 'deliverables.deliverable-name' }),
                      value: Title,
                      validateOnExite: true,
                      error: !Title.length,
                    }}
                  />
                </div>
                <div className="deliverables__form-control">
                  <span className="label flex-v-center">
                    <FormattedMessage id="deliverables.planned-finish-date" />
                  </span>
                  <Field
                    control={{
                      type: 'date',
                      onChangeAction: date => this.setState({ PlannedFinishDate: date }),
                      locale,
                      placeholder: intl.formatMessage({ id: 'deliverables.planned-finish-date' }),
                      value: PlannedFinishDate,
                      validateOnExite: true,
                      error: !PlannedFinishDate,
                    }}
                  />
                </div>
                <div className="deliverables__form-control">
                  <span className="label flex-v-center">
                    <FormattedMessage id="deliverables.target" />
                  </span>
                  <Field
                    control={{
                      type: 'input',
                      onChangeAction: e => this.setState({ Target: e.target.value }),
                      placeholder: intl.formatMessage({ id: 'deliverables.target' }),
                      value: Target,
                    }}
                  />
                </div>
              </div>
              <div className="deliverables__form-col">
                <div className="deliverables__form-control">
                  <span className="label flex-v-center">
                    <FormattedMessage id="deliverables.success-measure" />
                  </span>
                  <Field
                    control={{
                      type: 'input',
                      onChangeAction: e => this.setState({ SuccessMeasure: e.target.value }),
                      placeholder: intl.formatMessage({ id: 'deliverables.success-measure' }),
                      value: SuccessMeasure,
                    }}
                  />
                </div>
                <div className="deliverables__form-control">
                  <span className="label flex-v-center">
                    <FormattedMessage id="deliverables.completion-percentage" />
                  </span>
                  <Field
                    control={{
                      type: 'number',
                      onChangeAction: e => {
                        this.setState({ CompletionProgress: parseInt(e.target.value, 10) });
                      },
                      placeholder: intl.formatMessage({ id: 'deliverables.completion-percentage' }),
                      value: CompletionProgress,
                      error: CompletionProgress > 100 || CompletionProgress < 0,
                    }}
                  />
                </div>
                <div className="deliverables__form-control">
                  <span className="label flex-v-center">
                    <FormattedMessage id="deliverables.actual-finish-date" />
                  </span>
                  <Field
                    control={{
                      type: 'date',
                      onChangeAction: date => this.setState({ ActualFinishDate: date }),
                      placeholder: intl.formatMessage({ id: 'deliverables.actual-finish-date' }),
                      value: ActualFinishDate,
                      error: !ActualFinishDate,
                    }}
                  />
                </div>
              </div>
            </form>
            <div className="deliverables__form-control deliverables__form-control-standalone">
              <span className="label flex-v-center">
                <FormattedMessage id="projectrisk.attachments" />
              </span>
              <Field
                control={{
                  type: 'attachment',
                  onChangeAction: e => {
                    this.setState({ Attachments: [...e] });
                  },
                  value: Attachments || [],
                  validateOnExite: false,
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ deliverables, theme, language }) => ({
  ...deliverables,
  themeColor: theme.ThemeColor.item.Text,
  locale: language.locale,
});

const mapDispatchToProps = {
  getAllDeliverablesAction: getAllDeliverables,
  postDeliverableAction: postDeliverable,
  deleteDeliverableAction: deleteDeliverable,
  deleteDeliverablesDataAction: deleteDeliverablesData,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Add));
