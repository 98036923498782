import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '../../Button';
import TitledContent from '../../TitledContent';
import AttachmentsList from '../../AttachmentsList';

import { formattedDate } from '../../../Helpers';
import Loader from './loader';
import './styles.scss';

class Details extends React.Component {
  componentDidMount() {
    const { match, getDeliverableById } = this.props;
    if (match.params.code && match.params.id) {
      getDeliverableById(match.params.code, match.params.id);
    }
  }

  render() {
    const {
      props: { themeColor, changeView, deliverable, loading },
    } = this;
    if (!Object.keys(deliverable).length && !loading) {
      changeView('base');
      return null;
    }

    const {
      deliverable: {
        ID,
        Title,
        PlannedFinishDate,
        SuccessMeasure,
        Target,
        CompletionProgress,
        ActualFinishDate,
        Attachments,
      },
      // loaders,
    } = this.props;
    return (
      <div className="deliverables__container">
        <div className="deliverables__content">
          <div className="deliverables__header">
            <div className="deliverables__title">{Title}</div>
            <div className="deliverables__actions">
              <Button
                className="action secondary-action"
                type="submit"
                onClick={() => changeView('base')}
                color={themeColor}
                border
              >
                <FormattedMessage id="common.back" />
              </Button>
              <Button
                className="action"
                type="submit"
                onClick={() => changeView('edit', ID)}
                backgroundColor={themeColor}
              >
                <FormattedMessage id="common.edit" />
              </Button>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="deliverable__details">
              <div className="deliverable__details-body">
                <div className="deliverable__row">
                  <TitledContent fontSize="13px" title={<FormattedMessage id="deliverables.planned-finish-date" />}>
                    {formattedDate(PlannedFinishDate)}
                  </TitledContent>
                  <TitledContent fontSize="13px" title={<FormattedMessage id="deliverables.success-measure" />}>
                    {SuccessMeasure || '---'}
                  </TitledContent>
                  <TitledContent fontSize="13px" title={<FormattedMessage id="deliverables.target" />}>
                    {Target || '---'}
                  </TitledContent>
                  <TitledContent fontSize="13px" title={<FormattedMessage id="deliverables.completion-percentage" />}>
                    {CompletionProgress}
                  </TitledContent>
                </div>
                <div className="deliverable__row">
                  <TitledContent fontSize="13px" title={<FormattedMessage id="deliverables.actual-finish-date" />}>
                    {formattedDate(ActualFinishDate)}
                  </TitledContent>
                </div>
              </div>
              <div className="deliverable__row">
                <TitledContent fontSize="13px" title={<FormattedMessage id="common.support-documents" />}>
                  {Attachments && Attachments.length ? <AttachmentsList attachments={Attachments} /> : null}
                </TitledContent>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ deliverables, theme }) => ({
  ...deliverables,
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps, null)(Details);
