import {
  GET_LIBRARY_STRUCTURE,
  GET_LIBRARY_FILES_LIST,
  SET_LIST_TYPE_LOADING,
  POST_LIBRARY_FILE,
  ADD_NEW_LIBRARY_SECTION,
} from '../actions/docsLibrary';

const initialState = {
  loading: {
    structure: true,
  },
  structure: [],
  items: {},
};

const extractListTypes = structureArr => {
  return structureArr.map(item => item.ListType);
};

// returns an object of listTypes keys with an initialValue for each.
const initializeItems = (listTypes, initialValue) => {
  return listTypes.reduce((accum, listType) => {
    return {
      ...accum,
      [listType]: initialValue,
    };
  }, {});
};

function docsLibraryReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGOUT': {
      return {
        ...initialState,
      };
    }
    case GET_LIBRARY_STRUCTURE: {
      const listTypes = extractListTypes(action.payload);

      return {
        ...state,
        loading: {
          ...state.loading,
          ...initializeItems(listTypes, false),
          structure: false,
        },
        structure: action.payload,
        items: initializeItems(listTypes, []),
      };
    }
    case SET_LIST_TYPE_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.listType]: true,
        },
      };
    }
    case GET_LIBRARY_FILES_LIST: {
      const { listType, data } = action.payload;

      return {
        ...state,
        loading: {
          ...state.loading,
          [listType]: false,
        },
        items: {
          ...state.items,
          [listType]: data || [],
        },
      };
    }
    case POST_LIBRARY_FILE: {
      const { listType, file } = action.payload;
      const newFile = {
        ...file,
        isNew: true,
      };
      return {
        ...state,
        items: {
          ...state.items,
          [listType]: [...state.items[listType], newFile],
        },
        loading: {
          ...state.loading,
          [listType]: false,
        },
      };
    }
    case ADD_NEW_LIBRARY_SECTION: {
      const { payload } = action;
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload]: false,
        },
        items: {
          ...state.items,
          [payload]: [],
        },
      };
    }
    default:
      return state;
  }
}

export default docsLibraryReducer;
