import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Select from 'react-select';

import './style.scss';

class SelectControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { error } = state;
    const { settings } = props;
    if (settings.error !== error) {
      return {
        ...state,
        error: settings.error,
      };
    }
    return state;
  }

  defaultValidation = value => {
    return !value;
  };

  showError(show) {
    this.setState({ error: show });
  }

  render() {
    const {
      props: {
        settings: {
          value,
          options,
          className,
          classNamePrefix,
          onChangeAction,
          validation,
          validateOnExite,
          disabled,
          placeholder,
          optionValue,
          optionLabel,
          getOptionLabel,
          getOptionValue,
          defaultValue,
          menuPlacement = 'auto',
          isLoading = false,
        },
      },
      state: { error },
    } = this;

    const _validation = validation || this.defaultValidation;

    return (
      <div className={`select-container ${error ? 'error' : ''}`}>
        <Select
          className={className}
          classNamePrefix={classNamePrefix || ''}
          value={value}
          defaultValue={defaultValue}
          onChange={onChangeAction}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          options={options}
          optionValue={optionValue}
          optionLabel={optionLabel}
          onBlur={validateOnExite ? () => this.showError(_validation(value)) : null}
          isDisabled={disabled}
          placeholder={placeholder || <FormattedMessage id="common.select" />}
          menuPlacement={menuPlacement}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default SelectControl;
