import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SendIcon from './icons/SendIcon';
import FileIcon from './icons/FileIcon';
import EmojiIcon from './icons/EmojiIcon';
import PopupWindow from './popups/PopupWindow';
import EmojiPicker from './emoji-picker/EmojiPicker';

class UserInput extends Component {
  state = {
    inputActive: false,
    inputHasText: false,
    emojiPickerIsOpen: false,
    emojiFilter: '',
  };

  componentDidMount() {
    this.emojiPickerButton = document.querySelector('#sc-emoji-picker-button');
  }

  renderEmojiPopup = () => {
    const { emojiFilter, emojiPickerIsOpen } = this.state;
    return (
      <PopupWindow
        isOpen={emojiPickerIsOpen}
        onClickedOutside={this.closeEmojiPicker}
        onInputChange={this.handleEmojiFilterChange}
      >
        <EmojiPicker onEmojiPicked={this.handleEmojiPicked} filter={emojiFilter} />
      </PopupWindow>
    );
  };

  toggleEmojiPicker = e => {
    e.preventDefault();
    const { emojiPickerIsOpen } = this.state;
    if (!emojiPickerIsOpen) {
      this.setState({ emojiPickerIsOpen: true });
    }
  };

  closeEmojiPicker = e => {
    if (this.emojiPickerButton.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState({ emojiPickerIsOpen: false });
  };

  handleEmojiFilterChange = event => {
    const emojiFilter = event.target.value;
    this.setState({ emojiFilter });
  };

  handleEmojiPicked = emoji => {
    this.setState({ emojiPickerIsOpen: false });
    const { inputHasText } = this.state;
    if (inputHasText) {
      this.userInput.innerHTML += emoji;
    } else {
      const { onSubmit } = this.props;
      onSubmit({
        author: 'me',
        type: 'emoji',
        data: { emoji },
      });
    }
  };

  submitText = event => {
    event.preventDefault();
    const text = this.userInput.textContent;
    if (text && text.length > 0) {
      const { onSubmit } = this.props;
      onSubmit({
        author: 'me',
        type: 'text',
        data: { text },
      });
      this.userInput.innerHTML = '';
    }
  };

  handleFilesSelected = event => {
    if (event.target.files && event.target.files.length > 0) {
      const { onFilesSelected } = this.props;
      onFilesSelected(event.target.files);
    }
  };

  handleKeyDown = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      return this.submitText(event);
    }
    return null;
  };

  handleKeyUp = event => {
    const inputHasText = event.target.innerHTML.length !== 0 && event.target.innerText !== '\n';
    this.setState({ inputHasText });
  };

  showFilePicker = () => {
    this.fileUploadButton.click();
  };

  renderSendOrFileIcon = () => {
    const { locale, theme } = this.props;
    const { inputHasText } = this.state;
    if (inputHasText) {
      return (
        <div className="sc-user-input--button">
          <SendIcon
            style={{ fill: theme.ThemeColor.item.Text, stroke: theme.ThemeColor.item.Text }}
            locale={locale}
            onClick={this.submitText}
          />
        </div>
      );
    }

    return (
      <div className="sc-user-input--button">
        <FileIcon
          style={{ fill: theme.ThemeColor.item.Text, stroke: theme.ThemeColor.item.Text }}
          onClick={this.showFilePicker}
        />
        <input
          type="file"
          name="files[]"
          multiple
          ref={e => {
            this.fileUploadButton = e;
          }}
          onChange={this.handleFilesSelected}
        />
      </div>
    );
  };

  render() {
    const { emojiPickerIsOpen, inputActive } = this.state;
    const { showEmoji, locale } = this.props;
    return (
      <form className={`sc-user-input ${inputActive ? 'active' : ''}`}>
        <div
          role="button"
          tabIndex="0"
          onFocus={() => {
            this.setState({ inputActive: true });
          }}
          onBlur={() => {
            this.setState({ inputActive: false });
          }}
          ref={e => {
            this.userInput = e;
          }}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          contentEditable="true"
          placeholder={`${locale === 'ar' ? 'اكتب رسالتك' : 'Write a reply...'}`}
          className="sc-user-input--text"
        />
        <div className="sc-user-input--buttons">
          <div className="sc-user-input--button" />
          <div className="sc-user-input--button">
            {showEmoji && (
              <EmojiIcon
                onClick={this.toggleEmojiPicker}
                isActive={emojiPickerIsOpen}
                tooltip={this.renderEmojiPopup()}
              />
            )}
          </div>
          {this.renderSendOrFileIcon()}
        </div>
      </form>
    );
  }
}

UserInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool,
};

export default UserInput;
