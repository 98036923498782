import React from 'react';

import TaskRow from '../../../TaskRow';
import RiskIssueRow from '../../../RiskIssueRow';

import './styles.scss';

class ProjectItems extends React.Component {
  renderTasks = (tasks = []) => {
    if (!tasks.length) return null;

    return tasks.map((task, i) => <TaskRow task={task} key={`task#${i + 1}`} type="projectTasks" />);
  };

  renderRisks = (risks = []) => {
    const { history } = this.props;
    if (!risks.length) return null;
    return risks.map((risk, i) => <RiskIssueRow history={history} item={risk} type="risk" key={`risk#${i + 1}`} />);
  };

  renderIssues = (issues = []) => {
    const { history } = this.props;
    if (!issues.length) return null;
    return issues.map((issue, i) => (
      <RiskIssueRow history={history} item={issue} type="issue" key={`issue#${i + 1}`} />
    ));
  };

  render() {
    const { project } = this.props;

    return (
      <div className="projectItems__container">
        {this.renderTasks(project.tasks)}
        {this.renderRisks(project.risks)}
        {this.renderIssues(project.issues)}
      </div>
    );
  }
}

export default ProjectItems;
