import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as BackIcon } from '../../../assets/images/back-arrow.svg';
import { createMarkup } from '../../../Helpers';

import './style.scss';

function ProjectUpdateProgressDetails(props) {
  const { update, match, theme, changeView } = props;
  const isPlanned = match.params.id === 'planned';
  const key = isPlanned ? 'PlannedActivity' : 'ProgressActivity';

  return (
    <section className="project-update-progress-details-container">
      <div className="sec-header-container tab-header">
        <div className="back-button colorBlue cursor" onClick={() => changeView('base')}>
          <BackIcon width="15px" height="15px" fill={theme.ThemeColor.item.Text} />
        </div>
        <div className="title bold-text">
          {isPlanned ? (
            <FormattedMessage id="progressmain.planned-label" />
          ) : (
            <FormattedMessage id="progressmain.progress-label" />
          )}
        </div>
      </div>
      <div className="tTab-content-container">
        <div className="main-content">
          {update && update[key] ? (
            <div className="update-source" dangerouslySetInnerHTML={createMarkup(update[key])} />
          ) : (
            <div className="update-source">
              <FormattedMessage
                id={isPlanned ? 'progressmain.no-planned' : 'progressmain.no-progress'}
                defaultMessage={`No ${key}`}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default ProjectUpdateProgressDetails;
