import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ReactComponent as NotificationIcon } from '../../../assets/images/notification_icon.svg';
import NotificationItem from './NotificationItem';
import { getNotificationsAction } from '../../../actions/userActions';

import Badge from './Badge';

function Notifications({
  handleDropNotifications,
  language,
  dropNotificationOpen,
  theme,
  notifications = [],
  locale,
  getNotificationsAction: getNotifications,
}) {
  let oldNotifications = true;
  let unReadCount = 0;
  useEffect(() => {
    getNotifications();
  }, [getNotifications, dropNotificationOpen]);

  const notificationsBody = notifications.map(notification => {
    if (!notification.IsRead) {
      unReadCount++;
    }
    if (oldNotifications) {
      oldNotifications = !(notification.IsRead === false);
    }
    return <NotificationItem key={notification.ID} notification={notification} theme={theme} locale={locale} />;
  });

  return (
    <div onClick={handleDropNotifications} className={`${language.locale === 'ar' ? 'rtl ' : ''} not-icon cursor`}>
      <div className="notifications-wrapper">
        <Badge count={unReadCount} />
        <NotificationIcon
          fill="black"
          style={{
            height: '21px',
            width: '23px',
          }}
        />
      </div>
      {dropNotificationOpen ? (
        <div className="display-block  dropdown-content">
          <div className="notification-title">
            <FormattedMessage id="header.notification" defaultMessage="header.notification" />
          </div>
          <div className="notification-body">
            <div className="body-container">{notificationsBody}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    notifications: state.user.notifications,
    locale: state.language.locale,
  };
};

const mapDispatchToProps = {
  getNotificationsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
