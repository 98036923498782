import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../components/Button';
import { ReactComponent as CheckIcon } from '../../../../assets/images/check.svg';
import ProjectAPIController from '../../../../providers/controllers/ProjectAPIController';
import DashboardAPIController from '../../../../providers/controllers/DashboardAPIController';
import ProjectFormSection from './ProjectFormSection';
import DetailsFormSection from './DetailsFormSection';
import IDTable from '../IDTable';
import { formattedDate, addToast } from '../../../../Helpers';
import routes from '../../../../routes';
import initialState from '../InitialState';

const IDAdd = ({ locale, theme, currentUser, history }) => {
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [affectingTasks, setAffectingTasks] = useState({ isLoading: false, list: [] });
  const [affectedTasks, setAffectedTasks] = useState({ isLoading: false, list: [] });
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [risks, setRisks] = useState({ isLoading: false, list: [] });
  const [issues, setIssues] = useState({ isLoading: false, list: [] });
  const [isSaving, setIsSaving] = useState(false);
  const themeColor = theme.ThemeColor.item.Text;

  useEffect(() => {
    try {
      new ProjectAPIController().getAllUsers().then(res => {
        if (res.StatusCode === 'Success') {
          setUsers(res?.Data || []);
        } else {
          throw new Error('service error');
        }
      });
      new DashboardAPIController().getAllProjects().then(res => {
        if (res.StatusCode === 'Success') {
          setProjects(res?.Data || []);
        } else {
          throw new Error('service error');
        }
      });
    } catch {
      addToast.error('errorFetchingData', {
        toastId: 'dependency-fetchError-error',
      });
    }
  }, []);

  const getTasks = (projectUID, type) => {
    let setter = () => {};
    if (type === 'affecting') {
      setter = setAffectingTasks;
    } else if (type === 'affected') {
      setter = setAffectedTasks;
    }
    setter({ isLoading: true, list: [] });
    new ProjectAPIController().getAllProjectTasks(projectUID).then(res => {
      setter({ list: res?.Data || [], isLoading: false });
    });
  };

  const getRisksIssues = projectUID => {
    setRisks({ list: [], isLoading: true });
    setIssues({ list: [], isLoading: true });
    new ProjectAPIController().getProjectRisks(projectUID).then(res => {
      setRisks({ list: res?.Data || [], isLoading: false });
    });
    new ProjectAPIController().getProjectIssues(projectUID).then(res => {
      setIssues({ list: res?.Data || [], isLoading: false });
    });
  };

  const cleanFormData = data => {
    const {
      AffectingProject,
      AffectedProject,
      AffectingTask,
      AffectedTask,
      Risks,
      Issues,
      LogNumber,
      Category,
      Owner,
      Relationship,
    } = data;
    return {
      AffectingProjectUID: AffectingProject.ProjectUID,
      AffectingProjectName: AffectingProject.NameEnglish,
      AffectingProjectNameAr: AffectingProject.NameArabic,
      AffectedProjectUID: AffectedProject.ProjectUID,
      AffectedProjectName: AffectedProject.NameEnglish,
      AffectedProjectNameAr: AffectedProject.NameArabic,
      AffectingTaskID: AffectingTask.TaskUID,
      AffectingTaskName: AffectingTask.Title,
      AffectingTaskStartDate: formattedDate(AffectingTask.StartDate),
      AffectingTaskFinishDate: formattedDate(AffectingTask.FinishDate),
      AffectedTaskID: AffectedTask.TaskUID,
      AffectedTaskName: AffectedTask.Title,
      AffectedTaskStartDate: formattedDate(AffectedTask.FinishDate),
      AffectedTaskFinishDate: formattedDate(AffectedTask.FinishDate),
      Risks: Risks.map(risk => ({ ...risk, ItemType: 1 })),
      Issues: Issues.map(issue => ({ ...issue, ItemType: 2 })),
      Category: parseInt(Category?.Value, 10) || '',
      RequestDate: formattedDate(new Date()),
      Relationship: parseInt(Relationship?.Value, 10) || '',
      OwnerUsername: Owner.Username,
      LogNumber,
    };
  };

  const validateForm = data => {
    const newErrors = {};
    if (!Object.keys(data.AffectingProject).length) {
      newErrors.AffectingProject = true;
    }
    if (!Object.keys(data.AffectedProject).length) {
      newErrors.AffectedProject = true;
    }
    if (!Object.keys(data.AffectingTask).length) {
      newErrors.AffectingTask = true;
    }
    if (!Object.keys(data.AffectedTask).length) {
      newErrors.AffectedTask = true;
    }
    if (!Object.keys(data.Owner).length) {
      newErrors.Owner = true;
    }
    if (!Object.keys(data.Category).length) {
      newErrors.Category = true;
    }
    if (!Object.keys(data.Relationship).length) {
      newErrors.Relationship = true;
    }
    if (!data.LogNumber.trim()) {
      newErrors.LogNumber = true;
    }
    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  };

  const saveForm = () => {
    if (validateForm(formData)) {
      setIsSaving(true);
      new ProjectAPIController()
        .submitInterdependency({ formData: cleanFormData(formData) })
        .then(res => {
          if (res.StatusCode === 'Success') {
            addToast.success('common.changesHaveBeenSaved');
            history.replace(routes.interdependencies);
          } else {
            throw new Error('service error');
          }
        })
        .catch(() => {
          addToast.error('common.errorSavingChanges', {
            toastId: 'dependency-add-error',
          });
        })
        .finally(() => {
          setIsSaving(false);
        });
    } else {
      addToast.error('common.fillRequiredFields', {
        toastId: 'dependency-add-error',
      });
    }
  };

  return (
    <div>
      <div className="id-header">
        <h4>
          <FormattedMessage id="common.interdependencies" />
        </h4>
        <div className="header-actions">
          <Button onClick={saveForm} Icon={CheckIcon} loading={isSaving}>
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      </div>
      <div className="id-form">
        <ProjectFormSection
          projects={projects}
          formData={formData}
          setFormData={setFormData}
          locale={locale}
          themeColor={themeColor}
          getTasks={getTasks}
          // getRisksIssues={getRisksIssues}
          affectingTasks={affectingTasks}
          type="affecting"
          errors={errors}
        />
        <ProjectFormSection
          projects={projects}
          formData={formData}
          setFormData={setFormData}
          locale={locale}
          themeColor={themeColor}
          getTasks={getTasks}
          getRisksIssues={getRisksIssues}
          affectedTasks={affectedTasks}
          type="affected"
          errors={errors}
        />
        {formData.AffectedProject?.ProjectUID ? (
          <div className="id-section">
            <div className="id-section-content">
              <div className="id-section-row align-start">
                <IDTable
                  type="Risks"
                  list={risks.list}
                  isLoading={risks.isLoading}
                  formData={formData}
                  setFormData={setFormData}
                />
                <IDTable
                  type="Issues"
                  list={issues.list}
                  isLoading={issues.isLoading}
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </div>
          </div>
        ) : null}
        <DetailsFormSection
          currentUser={currentUser}
          users={users}
          formData={formData}
          setFormData={setFormData}
          locale={locale}
          themeColor={themeColor}
          theme={theme}
          errors={errors}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme, language, user }) => ({
  theme,
  locale: language.locale,
  currentUser: user.user,
});

export default connect(mapStateToProps)(IDAdd);
