import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EntityCard from '../../../components/EntityCard';
import ChatBot from '../../../components/ChatBot';
import EntityLoader from '../../../components/EntityCard/loader';
import Breadcrumbs from '../../../components/Breadcrumb';
import PortfolioaAPIController from '../../../providers/controllers/PortfolioAPIController';

import { isInputArabic, renderMultiple, addToast } from '../../../Helpers';

import './style.scss';

class Portfolios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entitiesList: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getAllPortfolios();
  }

  getAllPortfolios() {
    new PortfolioaAPIController()
      .getAllPortfolio()
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({ entitiesList: res.Data, loading: false });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-entities',
        });
      });
  }

  render() {
    const { loading } = this.state;
    let { entitiesList } = this.state;
    const { keyWord } = this.props;
    if (keyWord !== '') {
      entitiesList = entitiesList.filter(entity => {
        const nameLanguage = isInputArabic(keyWord) ? 'NameArabic' : 'NameEnglish';
        return entity[nameLanguage].toLowerCase().indexOf(keyWord.toLowerCase()) !== -1;
      });
    }

    return (
      <div className="container">
        <div className="bread">
          <Breadcrumbs title="Portfolios.Portfolios" />
        </div>
        <div className="entities-container">
          <div className="entities-container-card">
            {loading
              ? renderMultiple(key => <EntityLoader key={key} />, 4)
              : entitiesList.map((item, index) => <EntityCard {...this.props} key={index} item={item} />)}
          </div>
        </div>
        <ChatBot />
      </div>
    );
  }
}

Portfolios.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
};

const mapStateToProps = (state /* , ownProps */) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Portfolios);
