import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import Button from '../../Button';
import { createMarkup } from '../../../Helpers';
import ContentLoading from './ContentLoading';
import EmptyComponent from '../../EmptyComponent';
import './style.scss';

function ProjectUpdateProgressHistory(props) {
  const { updateProgressList, theme, intl, changeView, loading } = props;

  return (
    <section className="project-update-progress-history-container">
      <div className="sec-header-container tab-header">
        <div className="title bold-text">
          <FormattedMessage id="progressmain.update-progress" />
        </div>
        <div className={`section-actions ${loading ? ' loading' : ''}`}>
          <Button
            className="action"
            backgroundColor={theme.ThemeColor.item.Text}
            color="white"
            onClick={() => changeView('add')}
          >
            <FormattedMessage id="progressmain.edit-label" />
          </Button>
        </div>
      </div>
      <>
        {loading ? (
          <ContentLoading />
        ) : (
          <>
            {updateProgressList.length === 0 ? (
              <EmptyComponent small />
            ) : (
              <div className="tTab-content-container">
                {updateProgressList.map((item, i) => (
                  <div className="main-content" key={i}>
                    <div className="timeline">
                      <div className="date" style={{ backgroundColor: theme.ThemeColor.item.Text }}>
                        <div className="day">{moment(item.CreatedDate).format('DD')}</div>
                        <div className="month">{moment(item.CreatedDate).format('MMM')}</div>
                      </div>
                      <div className="line" />
                      <div className="point" />
                    </div>
                    <div className="week-updates">
                      <div className="update-info colorGray">
                        {intl.formatMessage({
                          id: 'project-issues-add.update-btn-label',
                          defaultMessage: 'Update',
                        })}
                        : {moment(item.CreatedDate).format('LL')}{' '}
                        {intl.formatMessage({
                          id: 'common.toDate',
                          defaultMessage: 'to',
                        })}{' '}
                        {moment(item.CreatedDate).format('hh:mm a')}
                      </div>
                      <div className="sec-title bold-text" style={{ color: theme.ThemeColor.item.Text }}>
                        <FormattedMessage
                          id="progressmain.progress-label"
                          defaultMessage="Progress/ completed activities from previous week"
                        />
                      </div>
                      <div className="update-body" dangerouslySetInnerHTML={createMarkup(item.ProgressActivity)} />

                      <div className="sec-title bold-text" style={{ color: theme.ThemeColor.item.Text }}>
                        <FormattedMessage
                          id="progressmain.planned-label"
                          defaultMessage="Planned Activities for Following Week"
                        />
                      </div>
                      <div className="update-source" dangerouslySetInnerHTML={createMarkup(item.PlannedActivity)} />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </>
    </section>
  );
}

export default injectIntl(ProjectUpdateProgressHistory);
