import React from 'react';
import { FormattedMessage } from 'react-intl';
import { addToast } from '../../Helpers';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import StateNavigator from '../StateNavigator';
import FinancialListItem from './ListItem';
import Loader from './loader';
import './loader.scss';
import './styles.scss';

class ProjectFinancial extends React.Component {
  state = {
    selectedComponent: 'main',
    financialList: [],
    originalList: [],
    loadingFinancials: true,
    selectedItem: null,
    isEditMode: false,
    isAddNew: false,
    isSaving: false
  };

  componentDidMount() {
    this.getProjectFinancial();
  }

  getProjectFinancial = () => {
    const { match } = this.props;
    new ProjectAPIController()
      .getProjectFinancial(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({
            originalList: res.Data,
            financialList: res.Data,
            loadingFinancials: false,
          });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-financial-error',
        });
      });
  }

  saveProjectFinancial = (data) => {
    const { match } = this.props;
    const { financialList, isAddNew } = this.state;
    this.setState({ isSaving: true });

    new ProjectAPIController()
      .saveProjectFinancial(match.params.code, { "ProjectUID": match.params.code, "formData": data })
      .then(res => {
        if (res.StatusCode === 'Success' && res.Data) {
          const _data = data;
          _data.ID = res.Data.ID;
          _data.SpentDate = res.Data.SpentDate;
          const rowIndex = financialList.findIndex(item => item.ID === (isAddNew ? 0 : _data.ID));
          if (rowIndex >= 0) {
            financialList[rowIndex] = _data;
          }

          addToast.success('common.changesHaveBeenSaved');
          this.setState({
            isSaving: false,
            isEditMode: false,
            isAddNew: false,
            selectedItem: null,
            financialList,
            originalList: financialList
          });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'save-financial-error',
        });
      });
  }

  handleSelectItem = (data) => {
    this.setState({ selectedItem: data, isEditMode: true })
  }

  handleCancelEdit = () => {
    const { originalList } = this.state;
    this.setState({ selectedItem: null, isEditMode: false, isAddNew: false, financialList: [...originalList] })
  }

  getListItem = (item) => {
    const { selectedItem, isEditMode, isAddNew, isSaving } = this.state;
    return (
      <FinancialListItem
        key={item.ID}
        data={item}
        onSelect={(e) => this.handleSelectItem(e)}
        onSave={(e) => this.saveProjectFinancial(e)}
        onCancel={(e) => this.handleCancelEdit(e)}
        onFieldChanged={(e, value, data) => this.handleFieldChange(e, value, data)}
        isSelected={item.ID > 0 ? item?.ID === selectedItem?.ID : true}
        isEditMode={isEditMode}
        isAddNew={isAddNew}
        isSaving={isSaving}
      />
    )
  }

  handleAddNew = () => {
    const { financialList } = this.state;

    const _financialList = [...financialList, {
      SpentAmount: 0,
      SpentDate: new Date(),
      Comment: '',
      ID: 0,
      Title: "",
      Attachments: null,
    }]
    this.setState({
      isAddNew: true,
      isEditMode: true,
      financialList: _financialList
    }, () => {
      window.scrollTo(0, document.body.scrollHeight);
    })
  }

  render() {
    const {
      state: { selectedComponent, financialList, loadingFinancials, isAddNew, isEditMode },
    } = this;
    const componentsList = [
      {
        name: 'main',
        component: (
          <div className="project-financial__container">
            <div className="project-financial__content">
              <div className="project-financial__header">
                <div className="project-financial__title">
                  <FormattedMessage id="projectdata.financial" />
                  <button className="main-button-container " disabled={isEditMode || loadingFinancials} type="button" onClick={() => this.handleAddNew()}>
                    <FormattedMessage id="project-financial.add-new" />
                  </button>
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}><FormattedMessage id="project-financial.spentDate" /></th>
                    <th style={{ width: "20%" }}><FormattedMessage id="project-financial.spentAmount" /></th>
                    <th style={{ width: "50%" }}><FormattedMessage id="common.comment" /></th>
                    <th style={{ width: "10%" }} />
                  </tr>
                </thead>
                <tbody>
                  {!loadingFinancials ?
                    <>
                      <>{financialList && !!(financialList.length) && financialList.map(item => (
                        item.ID !== 0 && this.getListItem(item)
                      )
                      )}</>
                      <>{isAddNew ? this.getListItem(financialList[financialList.length - 1]) : null}</>
                    </>
                    : <Loader />}
                </tbody>
              </table>
            </div>
          </div>

        ),
      }
    ];

    return <StateNavigator componentsList={componentsList} selectedComponentName={selectedComponent} />;
  }
}

export default ProjectFinancial;