import UrlAPIControllers from '../config/urlServiceConfig';
import HttpServiceConfig from '../config/httpServiceConfig';

export default class TaskCenterAPIController {
  getTaskCenterCount = () => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/TaskCenterCounts`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectsTasksApprovals = () => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/Assignemnts/Approval`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getNonProjectsTasksApprovals = () => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/NonProjectTasks/Approvals`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getMyRequests = () => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/NonProjectTasks/MyTasks`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getPMOProcesses = () => {
    const FETCH_URL = `${new UrlAPIControllers().wfServiceURL()}/mytasks`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectsTasks = () => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/Tasks`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectsRisks = () => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/risks`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectsIssues = () => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/issues`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getNonProjectsTasks = () => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/NonProjectTasks/Tasks`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  postNonProjectsTasks = body => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/NonProjectTasks/Submitlist`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  postProjectsTasksStatus = body => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/Assignemnts/DoAction`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  postNonProjectsTasksStatus = body => {
    const FETCH_URL = `${new UrlAPIControllers().tasksCenterURL()}/NonProjectTasks/DoAction`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };
}
