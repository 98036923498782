import React from 'react';
import marked from 'marked';
import { createMarkup } from '../../../Helpers';

import './styles.scss';

const Message = ({ message }) => {
  const text = message.text
    .split('\n')
    .map((line, i) => <p key={`msg-#${i + 1}`} dangerouslySetInnerHTML={createMarkup(marked(line))} />);

  return <div className="message__container">{text}</div>;
};

export default Message;
