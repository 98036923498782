import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Chip from '../Chip';
import TitledContent from '../TitledContent';
import Slider from '../Slider';
import ModifyTaskModal from '../ModifyTaskModal';
import NonProjectTaskModal from '../NonProjectTaskModal';

import { updateTaskPercentage, updateNonProjectTaskPercentage } from '../../actions/tasksCenter';
import { taskClassificationColor, taskClassificationName, getTaskClassification, getTaskStatus } from '../../Helpers';

import './styles.scss';
import { css } from 'emotion';

class TaskRow extends React.Component {
  constructor(props) {
    super(props);
    const { task, type } = props;
    const isNonProjectTask = type === 'nonProjectTask';
    const percentage = !isNonProjectTask ? task.PercentComplete : task.DraftPercentage;
    this.state = {
      taskModalOpen: false,
      isCompleted: percentage === 100,
    };
  }

  handleUpdateSlider = val => {
    const { task, updateNonProjectTaskPercentageAction, updateTaskPercentageAction, type } = this.props;
    this.setState(
      {
        isCompleted: val === 100,
      },
      async () => {
        if (type === 'nonProjectTask') {
          await updateNonProjectTaskPercentageAction(task, val);
        } else {
          await updateTaskPercentageAction(task, val);
        }
      },
    );
  };

  handleCheck = () => {
    const { isCompleted } = this.state;
    const { type, task, updateNonProjectTaskPercentageAction, updateTaskPercentageAction } = this.props;
    const isNonProjectTask = type === 'nonProjectTask';
    this.setState(
      {
        isCompleted: !isCompleted,
      },
      () => {
        let percentage = !isNonProjectTask ? task.PercentComplete : task.DraftPercentage;
        percentage = !isCompleted ? 100 : percentage;

        if (isNonProjectTask) updateNonProjectTaskPercentageAction(task, percentage);
        else updateTaskPercentageAction(task, percentage);
      },
    );
  };

  handleTaskModalOpen = () => {
    this.setState(prevState => ({
      taskModalOpen: !prevState.taskModalOpen,
    }));
  };

  render() {
    const {
      props: {
        task,
        editable = true,
        tasksClassifications,
        taskApprovalStatuses,
        nonProjectTaskApprovalStatuses,
        locale,
        theme,
        type,
      },
      state: { taskModalOpen, isCompleted },
      handleTaskModalOpen,
    } = this;

    const daysDiff = moment().diff(task.FinishDate, 'days');
    const isLate = daysDiff > 0;
    const statusText = taskClassificationName(tasksClassifications, getTaskClassification(task), locale);
    const statusColor = taskClassificationColor(tasksClassifications, getTaskClassification(task));
    const isNonProjectTask = type === 'nonProjectTask';
    const lastChangeStatus = getTaskStatus(
      isNonProjectTask ? nonProjectTaskApprovalStatuses : taskApprovalStatuses,
      task.StatusID,
      locale,
    );
    const percentage = !isNonProjectTask ? task.PercentComplete : task.DraftPercentage;
    return (
      <div className="taskRow__container">
        <div className="taskRow__starting" role="button" onClick={handleTaskModalOpen} tabIndex={0}>
          <div className="taskRow__status">
            <Chip text={statusText} color={statusColor} />
          </div>
          <TitledContent title={task.Title} fontSize="12px">
            <div className="taskRow__details">
              <span className="taskRow__details-item">
                <FormattedMessage id="common.startDate" />
                :&ensp;
                {moment(task.StartDate).format('DD-MMM-YYYY')}
              </span>
              <span className="taskRow__details-item">
                <FormattedMessage id="common.finishDate" />
                :&ensp;
                {moment(task.FinishDate).format('DD-MMM-YYYY')}
              </span>
              {!isNonProjectTask ? (
                <span className="taskRow__details-item">
                  <FormattedMessage id="common.expectedDate" />
                  :&ensp;
                  {task.ExpectedDate ? (
                    moment(task.ExpectedDate).format('DD-MMM-YYYY')
                  ) : (
                    <FormattedMessage id="common.not-available" />
                  )}
                </span>
              ) : null}
              <span className="taskRow__details-item">
                <FormattedMessage id="task.lastChangeStatus" />
                :&ensp;
                <span className="taskRow__details-item-lastChangeStatus" style={{ color: lastChangeStatus.style }}>
                  {lastChangeStatus.name}
                </span>
              </span>
              {isLate ? (
                <span className="taskRow__details-item">
                  <FormattedMessage id="task.delayDuration" />
                  :&ensp;
                  {daysDiff} Days
                </span>
              ) : null}
            </div>
          </TitledContent>
        </div>
        <div className="taskRow__trailing">
          <div className="d-flex pc-cont">
            {isCompleted ? (
              <div className="slider-container" />
            ) : (
              <Slider
                value={percentage}
                onChangePercent={e => this.handleUpdateSlider(e)}
                showPercentage={false}
                disabled={!editable}
                width="80%"
                theme={theme}
                item={task}
                miniPercentage
              />
            )}
            <div
              className={`check-container ${css`
                          .round input[type="checkbox"]:checked + label{
                            background-color: ${theme.ThemeColor.item.Text};
                            border-color: ${theme.ThemeColor.item.Text};
                        }
                                                }
                          `}`}
            >
              <div className="round">
                <input
                  type="checkbox"
                  id={`${task.ID}checkbox`}
                  checked={isCompleted}
                  onChange={this.handleCheck}
                  disabled={!editable}
                />
                <label htmlFor={`${task.ID}checkbox`} />
              </div>
            </div>
          </div>
        </div>
        {type === 'nonProjectTask' ? (
          <NonProjectTaskModal
            isOpen={taskModalOpen}
            task={task}
            editable={editable}
            onClose={handleTaskModalOpen}
            theme={theme}
            locale={locale}
            taskApprovalStatuses={nonProjectTaskApprovalStatuses}
            tasksClassifications={tasksClassifications}
            type={type}
          />
        ) : (
          <ModifyTaskModal
            isOpen={taskModalOpen}
            task={task}
            onClose={handleTaskModalOpen}
            theme={theme}
            locale={locale}
            taskApprovalStatuses={taskApprovalStatuses}
            tasksClassifications={tasksClassifications}
            type={type}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ theme, language }) => ({
  locale: language.locale,
  tasksClassifications: theme.TasksClassifications.item.Items,
  taskApprovalStatuses: theme.task_approval_statuses.item.Items,
  nonProjectTaskApprovalStatuses: theme.non_project_task_approval_statuses.item.Items,
  theme,
});

const mapDispatchToProps = {
  updateTaskPercentageAction: updateTaskPercentage,
  updateNonProjectTaskPercentageAction: updateNonProjectTaskPercentage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskRow);
