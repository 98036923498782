// TODO FILE NEEDS CLEANING UP and remove the eslint-disable
/* eslint-disable */
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';
import Avatar from '../Avatar';
import Notifications from './Notifications';
import { ReactComponent as DashboardIcon } from '../../assets/images/dashboard_icon.svg';
import { ReactComponent as DownArrowIcon } from '../../assets/images/down-arrow.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/logout.svg';
import { ReactComponent as TaskCenterIcon } from '../../assets/images/clipboard.svg';
import { ReactComponent as DepenencyIcon } from '../../assets/images/interdependencies.svg';
import { ReactComponent as ReportsIcon } from '../../assets/images/reports.svg';

import routes from '../../routes';
import { headerLogoURL } from '../../providers/config/urlServiceConfig';
import { addToast } from '../../Helpers';
import { dashboardsRoutes } from '../../routes/routes';
import UsersAPIController from '../../providers/controllers/UsersAPIController';

import './style.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropNotificationOpen: false,
      dropProfileOpen: false,
      dropLanguageOpen: false,
      dropDashboardsOpen: false,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleLanguage(key) {
    const { dispatch } = this.props;
    dispatch({ type: 'TOGGLE_LANGUAGE', payload: { key } });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        dropNotificationOpen: false,
        dropProfileOpen: false,
        dropLanguageOpen: false,
        dropDashboardsOpen: false,
      });
    }
  }

  handleLogout() {
    const { dispatch, user } = this.props;
    new UsersAPIController().removeDeviceId(user.DeviceID);

    localStorage.clear();
    localStorage.removeItem('persist:root');
    dispatch(LogUserOut => {
      LogUserOut({ type: 'LOGOUT', payload: {} });
    });
  }

  handleDropNotifications = () => {
    this.setState(previousState => {
      return {
        dropNotificationOpen: !previousState.dropNotificationOpen,
        dropLanguageOpen: false,
        dropProfileOpen: false,
        dropDashboardsOpen: false,
      };
    });
  };

  render() {
    const {
      theme,
      language,
      history,
      user: { user },
    } = this.props;
    const { notifications } = this.props.user;
    const { dropNotificationOpen, dropProfileOpen, dropLanguageOpen, dropDashboardsOpen } = this.state;

    const languageStyle = language.locale === 'ar' ? 'dropdown-content rtl' : 'dropdown-content';
    let DisplayName = null;

    if (user) {
      DisplayName = user.DisplayName;
    }

    const locationName = history.location.pathname.split('/')[2];
    const isDashboard = history.location.pathname.split('/')[1] === 'dashboard';

    return (
      <div className="header" ref={this.setWrapperRef} style={{ borderBottomColor: theme.ThemeColor.item.Text }}>
        <div className="header-container">
          <div className="left-side-header">
            <div className={`${language.locale === 'ar' ? 'rtl ' : ''} logo-icon cursor`}>
              <a href="/#/portfolios">
                <img src={headerLogoURL} alt="ppluslogo" style={{ width: '45px', height: '45px' }} />
              </a>
            </div>
            <div className={`${language.locale === 'ar' ? 'rtl ' : ''} logo-name`}>PMO Digital Solution</div>
          </div>
          <div className="right-side-header">
            <div className="header__icon-link">
              <Link to={routes.reports}>
                <ReportsIcon />
                <FormattedMessage id="common.reports" />
              </Link>
            </div>
            <div
              className="language-content cursor"
              onClick={() =>
                this.setState({
                  dropLanguageOpen: !dropLanguageOpen,
                  dropProfileOpen: false,
                  dropNotificationOpen: false,
                  dropDashboardsOpen: false,
                })
              }
              onClick={() => this.handleLanguage(language.locale === 'en' ? 'ar' : 'en')}
            >
              <div className={`${language.locale === 'ar' ? 'rtl ' : ''} lang-icon`}>
                <div className={'english-icon'}>
                  <FormattedMessage id="common.language" defaultMessage="common.language" />
                </div>
              </div>
            </div>
            <div
              className="profile-content dashboards-content cursor"
              onClick={() =>
                this.setState({
                  dropDashboardsOpen: !dropDashboardsOpen,
                  dropProfileOpen: false,
                  dropLanguageOpen: false,
                  dropNotificationOpen: false,
                })
              }
            >
              <div className="dashboards-title">
                <FormattedMessage
                  id={
                    isDashboard && locationName && locationName !== 'initiatives'
                      ? `common.${locationName}`
                      : 'common.dashboards'
                  }
                />
              </div>
              <div className="drop-down-arrow ">
                <DownArrowIcon
                  style={{
                    height: '10px',
                    width: '13px',
                    fill: '#8a8a8b',
                  }}
                />
                <div className={`${dropDashboardsOpen ? 'display-block ' : 'display-none '} ${languageStyle}`}>
                  {routes.dashboardsRoutes.map(({ to, IconComponent, localeId }) => (
                    <div className="profile-body" key={to}>
                      <div className="profile-items">
                        <div className="item">
                          <Link to={routes.dashboards(to)}>
                            <div className="link-icon">
                              <IconComponent
                                style={{
                                  width: '15px',
                                  height: '20px',
                                  fill: theme.ThemeColor.item.Text,
                                  stroke: theme.ThemeColor.item.Text,
                                  color: theme.ThemeColor.item.Text,
                                }}
                              />
                            </div>
                            <div className="link-text">
                              <FormattedMessage id={localeId} />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {false && (
              <div
                className="profile-content dashboards-content cursor"
                onClick={() =>
                  this.setState({
                    dropDashboardsOpen: !dropDashboardsOpen,
                    dropProfileOpen: false,
                    dropLanguageOpen: false,
                    dropNotificationOpen: false,
                  })
                }
              >
                <div className="dashboards-title">
                  <FormattedMessage
                    id={
                      isDashboard && locationName && locationName !== 'initiatives'
                        ? `common.${locationName}`
                        : 'common.dashboards'
                    }
                  />
                </div>

                <div className="drop-down-arrow ">
                  <DownArrowIcon
                    style={{
                      height: '10px',
                      width: '13px',
                      fill: '#8a8a8b',
                    }}
                  />
                  <div className={`${dropDashboardsOpen ? 'display-block ' : 'display-none '} ${languageStyle}`}>
                    {dashboardsRoutes.map(({ to, IconComponent, localeId }) => (
                      <div className="profile-body" key={to}>
                        <div className="profile-items">
                          <div className="item">
                            <Link to={routes.dashboards(to)}>
                              <div className="link-icon">
                                <IconComponent
                                  style={{
                                    width: '15px',
                                    height: '20px',
                                    fill: theme.ThemeColor.item.Text,
                                    stroke: theme.ThemeColor.item.Text,
                                    color: theme.ThemeColor.item.Text,
                                  }}
                                />
                              </div>
                              <div className="link-text">
                                <FormattedMessage id={localeId} />
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="header-icons">
              <Notifications
                handleDropNotifications={this.handleDropNotifications}
                language={language}
                notifications={notifications}
                dropNotificationOpen={dropNotificationOpen}
                theme={theme}
              />
            </div>
            <div
              className="profile-content cursor"
              onClick={() =>
                this.setState({
                  dropProfileOpen: !dropProfileOpen,
                  dropLanguageOpen: false,
                  dropNotificationOpen: false,
                })
              }
            >
              <div className={`${language.locale === 'ar' ? 'rtl ' : ''} profile-icon`}>
                <Avatar user={user} />
              </div>
              <div className={`${language.locale === 'ar' ? 'rtl ' : ''} profile-name`}>{DisplayName}</div>
              <div className="drop-down-arrow ">
                <DownArrowIcon
                  style={{
                    height: '10px',
                    width: '13px',
                    fill: '#8a8a8b',
                  }}
                />
                <div className={`${dropProfileOpen ? 'display-block ' : 'display-none '} ${languageStyle}`}>
                  <div className="profile-body">
                    <div className="profile-items">
                      <div className="item">
                        <Link to="/tasks">
                          <div className="link-icon">
                            <TaskCenterIcon style={{ transform: 'scale(1.3)' }} fill={theme.ThemeColor.item.Text} />
                          </div>
                          <div className="link-text">
                            <FormattedMessage id="header.tasksCenter" defaultMessage="header.tasksCenter" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* <div className="profile-items">
                      <div className="item">
                        <Link to="/dashboard">
                          <div className="link-icon">
                            <DashboardIcon
                              style={{
                                width: '15px',
                                height: '20px',
                                fill: theme.ThemeColor.item.Text,
                              }}
                            />
                          </div>
                          <div className="link-text">
                            <FormattedMessage id="common.dashboard" defaultMessage="common.dashboard" />
                          </div>
                        </Link>
                      </div>
                    </div> */}
                    {window.env.INTERDEPENDENCIES ? (
                      <div className="profile-items">
                        <div className="item">
                          <Link to="/interdependencies">
                            <div className="link-icon">
                              <DepenencyIcon width={15} height={15} fill={theme.ThemeColor.item.Text} />
                            </div>
                            <div className="link-text">
                              <FormattedMessage id="common.interdependencies" defaultMessage="Interdependencies" />
                            </div>
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="profile-footer" onClick={() => this.handleLogout()}>
                    <div className="content">
                      <div className={`${language.locale === 'ar' ? 'rtl ' : ''} icon`}>
                        <LogoutIcon style={{ color: theme.ThemeColor.item.Text }} />
                      </div>
                      <div className="logout cursor">
                        <FormattedMessage id="header.view-logout" defaultMessage="header.view-logout" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme, language, user }) => {
  return {
    theme,
    language,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Header));
