import React from 'react';
import moment from 'moment';
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import Toast from '../components/Toast';
import { baseURL } from '../providers/config/urlServiceConfig';
import AttachmentsAPIController from '../providers/controllers/AttachmentsAPIController';

export * from './Project';
export * from './Dashboard';

// return Status Color From Handshake

export function groupBy(objects, key) {
  return objects.reduce((object, group) => {
    const returnedValue = {};
    (returnedValue[group[key]] = object[group[key]] || []).push(group);
    return returnedValue;
  });
}

export function trimFileName(text, length = 40) {
  if (text.length <= length) return text;
  return `${text.substring(0, length - 10)}...${text.substring(text.length - 7)}`;
}

export function getStatusColor(theme, statusValue) {
  const listStatuses = theme?.Statuses.item.Items;
  const status = listStatuses?.find(_status => _status.Value === statusValue);
  if (status) return status.Style;
  return theme?.ThemeColor.item.Text;
}

export function getPhaseColor(theme, phaseValue) {
  const listPhases = theme.Project_Phases.item.Items;
  const phase = listPhases.find(_phase => _phase.Value === phaseValue);
  if (phase) return phase.Style;
  return theme.ThemeColor.item.Text;
}

// return Status Name From Handshaentity-progresske
export function getStatusName(listStatuses, statusValue, locale) {
  const isArabic = locale === 'ar';
  const status = listStatuses.find(_status => _status.Value === statusValue);
  if (status) {
    return isArabic && status.Arabic ? status.Arabic : status.English;
  }
  return statusValue;
}

// Detect if given string is Arabic or english string
export function isInputArabic(s) {
  const ltrChars =
    'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' +
    '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
  const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
  const rtlDirCheck = new RegExp(`^[^${ltrChars}]*[${rtlChars}]`);

  return rtlDirCheck.test(s);
}

// format number
export function nFormatter(num, digits, locale) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: locale === 'ar' ? 'ألف' : 'K' },
    { value: 1e6, symbol: locale === 'ar' ? 'مليون' : 'M' },
    { value: 1e9, symbol: locale === 'ar' ? 'مليار' : 'B' },
    { value: 1e9, symbol: locale === 'ar' ? 'تريلون' : 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; ) {
    if (num >= si[i].value) {
      break;
    }
    i -= 1;
  }
  return `${(num / si[i].value).toFixed(digits).replace(rx, '$1')} ${si[i].symbol}`;
}

// Clean Handshake Reduce Data
export function cleanHandshakeData(data) {
  const cleanData = {};
  data.forEach(item => {
    cleanData[item.Name] = { item };
  });
  return cleanData;
}

export function cleanHandshakeDataWithKey(data, key) {
  const cleanData = {};
  data.forEach(item => {
    cleanData[item[key]] = { item };
  });
  return cleanData;
}

export function reduceStatus(listStatuses, index) {
  const newList = [];
  listStatuses.map((item, i) => {
    const obj = {
      Arabic: item.Arabic,
      English: item.English,
      Id: item.Id,
      LookupId: item.LookupId,
      Order: item.Order,
      Style: item.Style,
      Value: item.Value,
      isActive: index !== i,
    };
    return newList.push(obj);
  });
  return newList;
}

export function cleanCustomFields(data) {
  const cleanData = {};
  data.forEach(item => {
    cleanData[item.Key] = { item };
  });
  return cleanData;
}

export function devreduceStatus(listStatuses) {
  const newList = listStatuses
    .map(item => {
      const obj = {
        Arabic: item.Arabic,
        English: item.English,
        Id: item.Id,
        LookupId: item.LookupId,
        Order: item.Order,
        Style: item.Style,
        Value: item.Value,
        isActive: false,
      };
      return obj;
    })
    .sort((a, b) => a.Order - b.Order);
  return newList;
}

export const getProjectLocalizedName = ({ NameArabic, NameEnglish }, locale) => {
  if (locale === 'en') {
    return NameEnglish || NameArabic;
  }
  return NameArabic || NameEnglish;
};

export const getTitleName = ({ TitleArabic, Title }, locale) => {
  if (locale === 'en') {
    return Title || TitleArabic;
  }
  return TitleArabic || Title;
};

export function getProjectCardField(cardFieldList, projectData) {
  const newList = [];
  cardFieldList.map(item => {
    const itemData = projectData && projectData.find(listItem => listItem.Key === item.Value);
    const obj = {
      Arabic: item.Arabic,
      English: item.English,
      Id: item.Id,
      LookupId: item.LookupId,
      Order: item.Order,
      Style: item.Style,
      Value: item.Value,
      itemData: itemData ? itemData.Value : '',
    };
    return newList.push(obj);
  });
  return newList;
}

export function numberWithCommas(number) {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function createMarkup(text) {
  return { __html: DOMPurify.sanitize(text) };
}

// Classification
export const classifications = {
  LOW: 'low',
  HIGH: 'high',
  MEDIUM: 'medium',
};

export const impactList = [
  {
    value: 'low',
    label: 'low',
  },
  {
    value: 'medium',
    label: 'medium',
  },
  {
    value: 'high',
    label: 'high',
  },
];
export const probabilityList = [
  {
    value: 'low',
    label: 'low',
  },
  {
    value: 'medium',
    label: 'medium',
  },
  {
    value: 'high',
    label: 'high',
  },
];

export const risksConstants = {
  statusClosed: '(3) Closed',
  statusActive: '(1) Active',
  statusTransferred: '(4) Transferred',
};

export const issuesConstants = {
  statusClosed: '(3) Closed',
  statusActive: '(1) Active',
  statusTransferred: '(4) Transferred',
};

export const categoriesText = {
  '(1) Category1': 'high',
  '(2) Category2': 'medium',
  '(3) Category3': 'low',
};

export const impactNumbers = {
  low: 3,
  medium: 5,
  high: 8,
};

export const probabilityNumbers = {
  low: 0.3,
  medium: 0.5,
  high: 0.8,
};

export const tasksConstants = {
  completed: 'completed',
  delayed: 'delayed',
  active: 'in progress',
};

export const milestoneConstants = {
  completed: 'completed',
  delayed: 'delayed',
  active: 'in progress',
};

export const issuePriorities = {
  HIGH: '(1) High',
  MEDIUM: '(2) Medium',
  LOW: '(3) Low',
};

export const getCategoryText = category => {
  return categoriesText[category];
};

export const getIsClosed = risk => {
  return risk.Status === risksConstants.statusClosed;
};

export const classes = ['High', 'Medium', 'Low', 'عالية', 'متوسطة', 'منخفضة'];

// find Impact String

export function getImpactString(number) {
  switch (true) {
    case number <= 3:
      return classifications.LOW;
    case number >= 8:
      return classifications.HIGH;
    default:
      return classifications.MEDIUM;
  }
}

export function getProbabiltyString(number) {
  switch (true) {
    case number <= 0.3:
      return classifications.LOW;
    case number >= 0.8:
      return classifications.HIGH;
    default:
      return classifications.MEDIUM;
  }
}

export const getImpactNumber = impact => {
  return impactNumbers[impact];
};

export const getProbabilityNumber = probability => {
  return probabilityNumbers[probability];
};

export function getClassificationSelect(Impact, Probability) {
  switch (true) {
    // high
    case Impact === classifications.MEDIUM && Probability === classifications.HIGH:
    case Impact === classifications.HIGH && Probability === classifications.MEDIUM:
    case Impact === classifications.HIGH && Probability === classifications.HIGH:
      return classifications.HIGH;
    // medium
    case Impact === classifications.HIGH && Probability === classifications.LOW:
    case Impact === classifications.MEDIUM && Probability === classifications.MEDIUM:
    case Impact === classifications.LOW && Probability === classifications.HIGH:
      return classifications.MEDIUM;
    default:
      return classifications.LOW;
  }
}

export const getIssueClassification = ({ Priority, Status }) => {
  switch (true) {
    case Status === risksConstants.statusClosed:
      return 'closed';
    case Status === risksConstants.statusTransferred:
      return 'transferred ';
    case Priority === issuePriorities.HIGH:
      return classifications.HIGH;
    case Priority === issuePriorities.MEDIUM:
      return classifications.MEDIUM;
    default:
      return classifications.LOW;
  }
};

export function getClassification(data) {
  switch (true) {
    // closed
    case data.Status === risksConstants.statusClosed:
      return 'closed';

    // transferred
    case data.Status === risksConstants.statusTransferred:
      return 'transferred ';
    // high
    case getImpactString(parseInt(data.Impact, 10)) === classifications.MEDIUM &&
      getProbabiltyString(parseFloat(data.Probability)) === classifications.HIGH:
    case getImpactString(parseInt(data.Impact, 10)) === classifications.HIGH &&
      getProbabiltyString(parseFloat(data.Probability)) === classifications.MEDIUM:
    case getImpactString(parseInt(data.Impact, 10)) === classifications.HIGH &&
      getProbabiltyString(parseFloat(data.Probability)) === classifications.HIGH:
      return classifications.HIGH;

    // medium
    case getImpactString(parseInt(data.Impact, 10)) === classifications.HIGH &&
      getProbabiltyString(parseFloat(data.Probability)) === classifications.LOW:
    case getImpactString(parseInt(data.Impact, 10)) === classifications.MEDIUM &&
      getProbabiltyString(parseFloat(data.Probability)) === classifications.MEDIUM:
    case getImpactString(parseInt(data.Impact, 10)) === classifications.LOW &&
      getProbabiltyString(parseFloat(data.Probability)) === classifications.HIGH:
      return classifications.MEDIUM;
    default:
      return classifications.LOW;
  }
}

export function getClassificationColor(list, classifcation) {
  const statusItem = list.find(item => item.Value.toLowerCase() === classifcation.toLowerCase());
  if (statusItem) return statusItem.Style;
  return '#00b3c6'; // theme's primary color
}

export function getClassificationName(list, classifcation, locale) {
  const statusItem = list.find(item => item.Value.toLowerCase() === classifcation.toLowerCase());
  if (statusItem) return locale === 'ar' ? statusItem.Arabic : statusItem.English;
  return classifcation;
}

export const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
];

export function reduceUsersList(list) {
  const newList = [];
  list.map(item => {
    const obj = {
      value: item.Username,
      label: item.DisplayName,
    };
    return newList.push(obj);
  });
  return newList;
}

export function taskClassificationColor(taskList, classifcation) {
  return taskList.find(item => item.Value.toLowerCase() === classifcation.toLowerCase()).Style;
}

export function taskClassificationName(taskList, classifcation, locale) {
  return taskList
    .filter(item => item.Value === classifcation)
    .map(item => (locale === 'ar' ? item.Arabic : item.English));
}

export function getTaskClassification(data) {
  const finishDate = moment(data.FinishDate);
  switch (true) {
    case data.PercentComplete === 100:
      return tasksConstants.completed;
    case data.PercentComplete !== 100 && moment().diff(finishDate, 'days') > 0:
      return tasksConstants.delayed;
    default:
      return tasksConstants.active;
  }
}

export function getMilestoneClassification(data) {
  const actualFinishDate = moment(data.ActualFinishDate);
  const plannedFinishDate = moment(data.PlannedFinishDate);
  switch (true) {
    case data.CompletedWorkPercentage === 100:
      return milestoneConstants.completed;
    case data.CompletedWorkPercentage !== 100 && actualFinishDate.diff(plannedFinishDate, 'days') > 0:
      return milestoneConstants.delayed;
    default:
      return milestoneConstants.active;
  }
}

export const getTaskStatus = (statusesList, status, locale) => {
  let match = statusesList.find(item => parseInt(item.Value, 10) === status);

  if (!match) match = { ...statusesList[0] };

  return {
    name: locale === 'ar' ? match.Arabic : match.English,
    style: match.Style,
  };
};

export function ProjectTaskList(list) {
  const newList = [];
  list.map(item => {
    const obj = {
      ...item,
      IsCompleted: item.PercentComplete === 100,
      isUpdated: false,
    };
    return newList.push(obj);
  });
  return newList;
}

export function reduceClassificationList(list, locale) {
  const newList = [];
  list.map(item => {
    const obj = {
      value: item.Value,
      label: locale === 'ar' ? item.Arabic : item.English,
    };
    return newList.push(obj);
  });
  return newList;
}

export function priorityClassificationColor(prioList, classifcation) {
  const statusItem = prioList.find(item => item.Value.toLowerCase() === classifcation.toLowerCase());
  if (statusItem) return statusItem.Style;
  return prioList[prioList.length - 1].Style;
}

export function tasksListSave(taskList) {
  return taskList.filter(item => item.isUpdated);
}

export function findUser(userList, value, byKey) {
  return userList.find(item => item[byKey] === value);
}

export function findPriority(priorityList, value) {
  return priorityList.filter(item => item.value === value);
}

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const encodeAttachments = attachments => {
  if (!attachments.length) return [];

  return Promise.all(
    attachments.map(async attachment => {
      const base64Attachment = await toBase64(attachment);
      return base64Attachment;
    }),
  );
};

export const getAttachmentFileType = attachment => {
  const ext = attachment.AttachmentDownloadURL
    ? attachment.AttachmentDownloadURL.split('.')[attachment.AttachmentDownloadURL.split('.').length - 1]
    : attachment.Name.split('.')[attachment.Name.split('.').length - 1];
  return ext;
};

export const getChatFileType = attachment => attachment.split('.').pop();

export const convertParseObjectsToMessages = (parseObjects, myID) => {
  const messages = [];
  parseObjects.forEach(parseObject => {
    const senderID = parseObject.attributes.sender_id;
    const author = myID === senderID ? 'me' : 'them';
    const type = parseObject.attributes.type.toLowerCase();
    const { creationDate } = parseObject.attributes;
    if (parseObject.attributes.type === 'TEXT') {
      messages.push({
        author,
        senderID,
        type,
        creationDate,
        data: { text: parseObject.attributes.body_text },
      });
    } else {
      messages.push({
        author,
        senderID,
        type,
        creationDate,
        data: {
          url: parseObject.attributes.downloadUrl,
          fileName: `${parseObject.attributes.file_name}`,
        },
      });
    }
  });
  return messages;
};

export const convertParseObjectsToMessagesFB = (parseObjects, myID) => {
  const messages = [];
  parseObjects.forEach(parseObject => {
    const senderID = parseObject.senderId;
    const author = myID === senderID ? 'me' : 'them';
    const type = parseObject.type.toLowerCase();
    const { creationDate } = parseObject;
    if (parseObject.type === 'TEXT') {
      messages.push({
        author,
        senderID,
        type,
        creationDate,
        data: { text: parseObject.text },
      });
    } else {
      messages.push({
        author,
        senderID,
        type,
        creationDate,
        data: {
          url: parseObject.downloadUrl,
          fileName: `${parseObject.name}`,
        },
      });
    }
  });
  return messages;
};

export const hexToRgbA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const renderMultiple = (renderer, times) => {
  const items = [];
  for (let i = 0; i < times; i += 1) {
    const key = `multi-render-${i + 1}`;
    items.push(renderer(key));
  }
  return items;
};

export const mergeAttachments = async attachments => {
  let _attachments = attachments.reduce(
    (accum, attachment) => {
      if (attachment instanceof File) {
        accum.newFiles.push(attachment);
      } else {
        accum.oldFiles.push({
          ...attachment,
          UID: new Date().getTime(),
        });
      }
      return accum;
    },
    { newFiles: [], oldFiles: [] },
  );

  let newFiles = await encodeAttachments(_attachments.newFiles);
  newFiles = newFiles.map((base64Attachment, index) => {
    return {
      AttachmentBase64: base64Attachment.replace(/^data:(.*,)?/, ''),
      AttachmentDownloadURL: '',
      Base64Data: base64Attachment,
      FileType: 'text/plain',
      ID: 0,
      Name: _attachments.newFiles[index].name,
      Type: 'text/plain',
      URL: base64Attachment,
      isDeleted: false,
    };
  });

  _attachments = [...newFiles, ..._attachments.oldFiles];

  return _attachments;
};

export const chunkArray = (myArray, chunkSize) => {
  const arrayLength = myArray.length;
  const tempArray = [];

  for (let index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const objectHasProperty = (object, property) => Object.prototype.hasOwnProperty.call(object, property);

export const groupByProjectID = (items, projectsList, propName) => {
  if (!items) return [];
  const _projectsList = { ...projectsList };
  items.forEach(item => {
    if (objectHasProperty(_projectsList, item.ProjectUID)) {
      if (objectHasProperty(_projectsList[item.ProjectUID], propName)) {
        if (!_projectsList[item.ProjectUID][propName].find(_item => _item.ID === item.ID)) {
          _projectsList[item.ProjectUID][propName].push(item);
        }
      } else {
        _projectsList[item.ProjectUID][propName] = [item];
      }
    } else {
      _projectsList[item.ProjectUID] = {
        ..._projectsList[item.ProjectUID],
        [propName]: [item],
      };
    }

    if (!objectHasProperty(_projectsList[item.ProjectUID], 'project') && objectHasProperty(item, 'Project')) {
      _projectsList[item.ProjectUID].project = item.Project || item.Program || item.Portfolio;
    }
  });

  return _projectsList;
};

export const formattedDate = date => {
  let _moment = moment(date);

  if (!_moment.isValid()) _moment = moment();

  return _moment.locale('en').format('DD-MM-YYYY');
};

export const isValidDate = date => {
  return moment(date).isValid();
};

export const addToast = {
  success: (msg, options) => toast.success(<Toast messageId={msg} type="success" />, options),
  warning: (msg, options) => toast.warning(<Toast messageId={msg} type="warning" />, options),
  error: (msg, options) => toast.error(<Toast messageId={msg} type="error" />, options),
};

export const toastMsgOptions = {
  position: 'top-right',
  autoClose: 3500,
  toastId: 'save-task',
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};
export const downloadInternalURl = attachment => {
  const a = document.createElement('a');
  const objectURL = URL.createObjectURL(attachment);
  a.href = objectURL;
  a.download = attachment.name;
  document.body.appendChild(a);
  a.click();
};
export const downloadAttachment = async attachment => {
  if (attachment.name) return downloadInternalURl(attachment);
  const name = attachment.Name || attachment.FileName;
  const attachmentURL = await new AttachmentsAPIController().getAttachment(
    `${baseURL}${attachment.AttachmentDownloadURL}`,
    name,
  );
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = attachmentURL;
  a.download = name;
  document.body.appendChild(a);
  return a.click();
};

export const checkOwnerUser = (list, currentUser, CurrentStep) => {
  const stepObject = list
    .find(item => item.SortOrder === CurrentStep)
    .StepOwners.find(itemOwner => itemOwner.Username === currentUser);
  return !!stepObject;
};

export const sortSteps = list => {
  return list
    .sort(function(a, b) {
      return a.SortOrder - b.SortOrder;
    })
    .reverse();
};

export const getEscalationStatus = status => {
  if (status === 3) return 'status-closed';
  if (status !== 3) return 'status-Open';
  return 'status-not-started';
};

export const getHandshakeLocalizedName = (item, locale) => {
  if (locale === 'en') return item.English || item.Arabic;
  return item.Arabic || item.English;
};

export function getRequestTypeLocalized(items, locale, data) {
  const isAr = locale === 'ar';
  const itemsMap = items.reduce((acc, curr) => {
    acc[curr.Value] = isAr ? curr.Arabic : curr.English;

    return acc;
  }, {});

  return data.map(item => ({
    ...item,
    label: itemsMap[item.RequestTypeText],
  }));
}

export const downloadFile = async (url, name) => {
  const attachmentURL = await new AttachmentsAPIController().getAttachment(url, name);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = attachmentURL;
  a.download = name;
  document.body.appendChild(a);
  return a.click();
};

export const getDeliverableStatusName = (items = [], id, locale = 'en') => {
  const found = items.find(el => el.Value === id);

  if (found) {
    return {
      name: locale === 'ar' ? found.Arabic : found.English,
      color: found.Style,
    };
  }

  return {
    name: id,
  };
};

export function getLevelStatusColor(theme, statusValue) {
  const listStatuses = theme.Level_Statuses.item.Items;
  const status = listStatuses.find(_status => _status.Value === statusValue);
  if (status) return status.Style;
  return theme.ThemeColor.item.Text;
}

export const getPhaseName = (phases, value, locale) => {
  const phase = phases.find(ph => ph.Value === value);

  return locale === 'ar' ? phase?.Arabic : phase?.English;
};
