/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';

import './loader.scss';

// eslint-disable-next-line
class PanelLoader extends React.Component {
  render() {
    const {
      props: { render, theme },
    } = this;
    const themeColor = theme.ThemeColor.item.Text;

    return (
      <div className="panelLoader__container" style={{ borderTopColor: themeColor }}>
        <div className="panelLoader__header">
          <div className="panelLoader__header-leading">
            <div className="panelLoader__header-title animated-background" />
            <div className="panelLoader__header-count animated-background" style={{ backgroundColor: themeColor }} />
          </div>
        </div>
        <div className="panelLoader__content">{render()}</div>
      </div>
    );
  }
}

PanelLoader.defaultProps = {
  isLoading: false,
};

const mapStateToProps = ({ theme }) => ({
  theme,
});

export default connect(mapStateToProps)(PanelLoader);
