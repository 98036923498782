import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Table from '../../../Table';
import Panel from '../../../Panel';
import Controllers from '../Controllers';
import NonProjectTaskModal from '../../../NonProjectTaskModal';

import { getNonProjectsTasksApprovals, postTaskStatus } from '../../../../actions/tasksCenter';

import './styles.scss';

class NonProjectsTasks extends React.Component {
  state = {
    taskModalOpen: false,
    taskModalInfo: null,
    controllersLoading: false,
  };

  componentDidMount() {
    this.getNonProjectsTasksApprovals();
  }

  getNonProjectsTasksApprovals = () => {
    const { _getNonProjectsTasksApprovals, nonProjectsTasks } = this.props;
    if (!nonProjectsTasks.length) {
      _getNonProjectsTasksApprovals();
    }
  };

  getColumns = () => {
    const { intl, _postTaskStatus, theme } = this.props;
    const themeColor = theme.ThemeColor.item.Text;

    return [
      {
        accessor: 'Title', // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.title' }),
        width: 200,
      },
      {
        accessor: data => moment(data.StartDate).format('DD-MMM-YYYY'),
        Header: intl.formatMessage({ id: 'common.startDate' }),
        id: 'startDate',
      },
      {
        accessor: data => moment(data.FinishDate).format('DD-MMM-YYYY'),
        Header: intl.formatMessage({ id: 'common.finishDate' }),
        id: 'finishDate',
      },
      {
        accessor: data => (data.AssignTo && data.AssignTo.DisplayName) || '---',
        Header: intl.formatMessage({ id: 'common.assignedTo' }),
        id: 'assignedTo',
      },
      {
        accessor: data => `${data.DraftPercentage}%`,
        Header: `${intl.formatMessage({ id: 'common.completion' })}`,
        id: 'completion',
      },
      {
        accessor: '',
        Header: '',
        Cell: data => (
          <Controllers
            _postTaskStatus={_postTaskStatus}
            data={data.value}
            themeColor={themeColor}
            isEventModal={false}
            type="nonProjectsTasks"
          />
        ),
      },
    ];
  };

  handleRowClick = rowInfo => {
    const { original: task } = rowInfo;

    this.setState({
      taskModalInfo: task,
      taskModalOpen: true,
    });
  };

  handleTaskModalClose = () => {
    this.setState({
      taskModalOpen: false,
      controllersLoading: false,
    });
  };

  _postTask = (body, type, closeModal, removeControllersLoading) => {
    const { _postTaskStatus } = this.props;
    this.setState({ controllersLoading: true }, () => {
      _postTaskStatus(body, type, closeModal, removeControllersLoading);
    });
  };

  render() {
    const {
      props: { theme, nonProjectsTasks, loaders, intl, locale, tasksClassifications, taskApprovalStatuses },
      state: { taskModalOpen, taskModalInfo, controllersLoading },
      handleTaskModalClose,
    } = this;

    return (
      <div className="nonProjectsTasks__container">
        <Panel
          title={intl.formatMessage({ id: 'taskCenter.tasks-approvals' })}
          count={nonProjectsTasks.length}
          isCollapsable
          render={() => (
            <Table
              onRowClick={this.handleRowClick}
              columns={this.getColumns()}
              data={nonProjectsTasks}
              showPagination={false}
              sortable={false}
              isLoading={loaders.nonProjectsTasks}
              numOfLoadingRows={3}
            />
          )}
        />
        <NonProjectTaskModal
          _postTaskStatus={(body, type, closeModal, removeControllersLoading) =>
            this._postTask(body, type, closeModal, removeControllersLoading)
          }
          isOpen={taskModalOpen}
          task={taskModalInfo}
          onClose={handleTaskModalClose}
          locale={locale}
          theme={theme}
          tasksClassifications={tasksClassifications}
          taskApprovalStatuses={taskApprovalStatuses}
          controllersLoading={controllersLoading}
          removeControllersLoading={() => this.setState({ controllersLoading: false })}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  _getNonProjectsTasksApprovals: getNonProjectsTasksApprovals,
  _postTaskStatus: postTaskStatus,
};

const mapStateToProps = ({ tasksCenter, theme }) => ({
  nonProjectsTasks: tasksCenter.approvals.nonProjectsTasks,
  loaders: tasksCenter.loaders,
  tasksClassifications: theme.TasksClassifications.item.Items,
  taskApprovalStatuses: theme.non_project_task_approval_statuses.item.Items,
  theme,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NonProjectsTasks));
