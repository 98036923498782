import React, { Component } from 'react';
import Message from './Messages';
import BouncyBalls from '../../BouncyBalls';

class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      page: 0,
    };
  }

  componentDidMount() {
    const { getMoreMessages, innerRef } = this.props;

    innerRef.current.addEventListener('scroll', () => {
      if (innerRef.current.scrollTop === 0) {
        this.setState(
          prevState => ({
            isLoading: true,
            page: prevState.page + 1,
          }),
          () => {
            const { page } = this.state;
            getMoreMessages(page);
          },
        );
        this.setState({ isLoading: false });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { innerRef, messages } = this.props;
    if (prevProps.messages.length !== messages.length && prevProps.messages.length !== 0) {
      innerRef.current.scrollTop =
        innerRef.current.children[messages.length - 1].offsetTop -
        innerRef.current.children[prevProps.messages.length - 1].offsetTop;
    }
  }

  componentWillUnmount() {
    const { innerRef } = this.props;
    innerRef.current.removeEventListener('scroll', null);
  }

  render() {
    const { theme, language, messages, innerRef } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="sc-message-list" ref={innerRef}>
        {isLoading && <BouncyBalls color={theme.ThemeColor.item.Text} loading={isLoading} />}
        {messages.map((message, i) => {
          return <Message message={message} key={i} theme={theme} language={language} />;
        })}
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <MessageList forwardedRef={ref} {...props} />);
