import React from 'react';
import { trimFileName, downloadAttachment } from '../../Helpers';
import { ReactComponent as PdfFile } from '../../assets/images/pdf-file.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-button.svg';

import Button from '../Button';

import './style.scss';

const AttachmentsList = props => {
  const { Attachments, isDelete, onDeleteAttatchment } = props;
  return (
    <div className="attachment-container">
      {Attachments.length > 0 &&
        Attachments.map((attacment, index) => (
          <div title={attacment.Name || attacment.name} className="attachment" key={index}>
            <Button
              backgroundColor="#eeeeee"
              color="black"
              Icon={() => <PdfFile />}
              onClick={() => (isDelete ? null : downloadAttachment(attacment))}
            >
              {trimFileName(attacment.Name || attacment.name)}
            </Button>
            {isDelete ? (
              <div className="delete-item" onClick={() => onDeleteAttatchment(index)}>
                <DeleteIcon style={{ width: 15, height: 15 }} />
              </div>
            ) : null}
          </div>
        ))}
    </div>
  );
};

export default AttachmentsList;
