import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import DashboardSummaryCards from '../DashboardSummaryCards';
import SummaryInfoCard from '../SummaryInfoCard';
import DashboardProgramHeader from '../DashboardProgramHeader';
import DashboardProjectInfo from '../DashboardProjectInfo';
import ProjectFinancialInfo from '../ProjectFinancialInfo';
import DashboardTabsSection from '../DashboardTabsSection';

import { ReactComponent as BagIcon } from '../../assets/images/bag.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/warning.svg';
import { ReactComponent as FireIcon } from '../../assets/images/fire.svg';
import { ReactComponent as SpeedIcon } from '../../assets/images/speed.svg';

import { getStatusName, getStatusColor, reduceCustomFields, filterRisks, filterIssues } from '../../Helpers';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';

import './styles.scss';

function DashboardProjectDetails({ dashboard = {}, match, theme, locale }) {
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);

  const { summary } = dashboard;
  const { projectCode } = match.params;

  const isAr = locale === 'ar';

  const filterrisks = filterRisks(dashboard.risks.filter(item => item.ProjectUID === projectCode));

  const filterissues = filterIssues(dashboard.issues.filter(item => item.ProjectUID === projectCode));

  const summaryData = [
    {
      id: 'common.programs',
      value: summary.entities,
      Icon: BagIcon,
      badgeColor: '#191f6c',
      Component: SummaryInfoCard,
      compProps: { user: project.ProjectManager || {} },
    },
    {
      id: 'dashboard.project-actual-progress',
      value: project.ActualCompletedPercentage,
      badgeColor: getStatusColor(theme, project.Status),
      isProgress: true,
      progressTitle: 'dashboard.actual',
    },
    {
      id: 'dashboard.project-planned-progress',
      value: project.CustomFields?.PlannedProgress,
      badgeColor: theme.ThemeColor.item.Text,
      isProgress: true,
      progressTitle: 'dashboard.planned',
    },
    {
      id: 'dashboard.project-status',
      value: getStatusName(theme.Statuses.item.Items, project.Status, locale),
      Icon: SpeedIcon,
      badgeColor: '#0AB11A',
      statusStyle: true,
    },
    {
      id: 'common.risks',
      value: filterrisks.length,
      Icon: WarningIcon,
      badgeColor: '#FE9F00',
    },
    {
      id: 'common.issues',
      value: filterissues.length,
      Icon: FireIcon,
      badgeColor: '#CE1818',
    },
  ];

  async function getProjectDetails() {
    try {
      const res = await new ProjectAPIController().getProjectByCode(projectCode);

      setProject(reduceCustomFields(res.Data));
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProjectDetails();
  }, [projectCode]);

  return (
    <div className="dashboard-project-details">
      <div className="dashboard-project-details__header">
        <DashboardProgramHeader
          data={project}
          customTitle={isAr ? project.ProjectNameArabic : project.ProjectName}
          customPhase={project.CustomFields?.ProjectPhase}
          backgroundColor="#3e98c7"
          loading={loading}
          showPhase
        />
      </div>

      <DashboardSummaryCards data={summaryData} loading={loading} />
      <div className="dashboard__info">
        <div className="details card">
          <DashboardProjectInfo project={project} loading={loading} />
        </div>
        <div className="financial-info card">
          <ProjectFinancialInfo project={project} />
        </div>
      </div>
      <div className="dashboard__info">
        <DashboardTabsSection projectCode={projectCode} />
      </div>
    </div>
  );
}

const mapStateToProps = ({ dashboard, theme, language }) => ({
  dashboard,
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(DashboardProjectDetails);
