import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';

import Tabs from '../DashboardTabs';
import InitiativeInfo from './InitiativeInfo';
import Projects from './Projects';
import IssuesRisks from './IssuesRisks';
import Milestones from './Milestones';

import ProgramsAPIController from '../../providers/controllers/ProgramsAPIController';

import { reduceCustomFields } from '../../Helpers';

import './styles.scss';

function InitiativeDetails({ programCode, intl, stats }) {
  const [program, setProgram] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  let content = null;

  async function getProgramByCode() {
    try {
      const res = await new ProgramsAPIController().getProgramByCode(programCode);
      setProgram(reduceCustomFields(res.Data));
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProgramByCode();
  }, [programCode]);

  function onTabChange(newTab) {
    setActiveTab(newTab);
  }

  const tabs = [
    {
      id: 0,
      label: intl.formatMessage({ id: 'dashboard.initiative-info' }),
    },
    {
      id: 1,
      label: intl.formatMessage({ id: 'common.projects' }),
    },
    {
      id: 2,
      label: intl.formatMessage({ id: 'dashboard.issues-risks' }),
    },
    {
      id: 3,
      label: intl.formatMessage({ id: 'program.milestones' }),
    },
  ];

  switch (activeTab) {
    case 0:
      content = <InitiativeInfo program={program} loading={loading} />;
      break;
    case 1:
      content = <Projects projects={stats.projects} />;
      break;
    case 2:
      content = <IssuesRisks programCode={programCode} />;
      break;
    case 3:
      content = <Milestones programCode={programCode} />;
      break;
    default:
      content = null;
  }

  return (
    <div className="initiative-details">
      <Tabs activeTab={activeTab} onTabChange={onTabChange} tabs={tabs} />
      {activeTab === 3 || activeTab === 2 || activeTab === 4 ? (
        content
      ) : (
        <div className="initiative-details-content">
          <div className="card">{content}</div>
        </div>
      )}
    </div>
  );
}

export default injectIntl(InitiativeDetails);
