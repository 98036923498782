import React from 'react';
import { connect } from 'react-redux';

import Checkbox from '../Field/Control/CheckBox';

import { formattedDate, getDeliverableStatusName } from '../../Helpers';

function Deliverable({ item, theme, locale, save, readOnly, showCheckbox }) {
  const status = getDeliverableStatusName(theme.DeliverableStatuses.item.Items, item.StatusID.toString(), locale);

  function handleChange(id, checked) {
    save(id, !checked);
  }

  const disabled = readOnly || item.Progress !== 100 || item.StatusID === 3 || item.StatusID === 5;

  return (
    <div className="deliverable-item">
      <div className="field title-field">
        {showCheckbox ? (
          <Checkbox
            id={item.ID}
            checked={item.IsSelected}
            onChangeCheckBox={handleChange}
            disabled={disabled}
            vibrant
          />
        ) : null}
        {item.DeliverableName || item.Title}
      </div>

      <div className="field">{item.StartDate && formattedDate(item.StartDate)}</div>
      <div className="field">{item.FinishDate && formattedDate(item.FinishDate)}</div>
      <div className="field">{`${item.Progress}%`}</div>
      <div className="field">
        <span style={{ color: status.color }}>{status.name}</span>
      </div>
      <div className="field">{item.AssignedTo}</div>
    </div>
  );
}

const mapStateToProps = ({ theme, language }) => ({
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(Deliverable);
