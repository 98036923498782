import React, { useState } from 'react';

import Field from '../../Field';
import CheckBox from '../../Field/Control/CheckBox';

function Item({ title, data = {}, onSave, dataKey, readOnly }) {
  const [isRequired, setIsRequired] = useState(Boolean(data.length));
  function toggleCheck() {
    setIsRequired(!isRequired);
    onSave(`${dataKey}Required`, !isRequired);
  }

  function onChange(e) {
    onSave(dataKey, e.currentTarget.value);
  }

  return (
    <div className="change-form-request-impacts__item">
      <span className="checkbox-container">
        <CheckBox checked={isRequired} onChangeCheckBox={toggleCheck} disabled={readOnly} vibrant />
      </span>

      <span className="title-container">{title}</span>
      <span className="text-container">
        <Field
          control={{
            type: 'textarea',
            value: data,
            required: isRequired,
            onChangeAction: onChange,
            disabled: readOnly || !isRequired,
            error: isRequired && !data,
          }}
        />
      </span>
    </div>
  );
}

export default Item;
