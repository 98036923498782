import React from 'react';
import ImpulseBalls from './ImpulseBalls';
import './style.scss';

const BouncyBalls = ({ loading, color, fullPage }) => {
  return (
    <div className="loading-container" style={{ height: `${fullPage ? 'calc(100vh - 241px)' : 'auto'}` }}>
      <ImpulseBalls size={40} frontColor={color} loading={loading} />
    </div>
  );
};

export default BouncyBalls;
