import UrlAPIControllers from '../config/urlServiceConfig';
import HttpServiceConfig from '../config/httpServiceConfig';

export default class UsersAPIController {
  login = data => {
    const FETCH_URL = new UrlAPIControllers().loginUrl();
    return new HttpServiceConfig().post2(FETCH_URL, data);
  };

  validateCode = data => {
    const FETCH_URL = new UrlAPIControllers().validateCodeUrl();
    return new HttpServiceConfig().post2(FETCH_URL, data);
  };

  getUserSecurityInfo = () => {
    const FETCH_URL = new UrlAPIControllers().getUserSecurityInfo();
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getHandshake = () => {
    const FETCH_URL = new UrlAPIControllers().handshakeUrl();
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getNotifications = () => {
    const FETCH_URL = new UrlAPIControllers().notificationsUrl();
    return new HttpServiceConfig().get(FETCH_URL);
  };

  pushNotification = data => {
    const POST_URL = new UrlAPIControllers().pushNotificationURL();
    return new HttpServiceConfig().post(POST_URL, data);
  };

  markNotificationRead = id => {
    const POST_URL = new UrlAPIControllers().markNotificationsReadByIDUrl(id);
    return new HttpServiceConfig().post(POST_URL);
  };

  postDeviceID = data => {
    const POST_URL = new UrlAPIControllers().postDeviceID();
    return new HttpServiceConfig().post(POST_URL, data);
  };

  removeDeviceId = DeviceID => {
    const POST_URL = new UrlAPIControllers().removeDeviceId(DeviceID);
    return new HttpServiceConfig().post(POST_URL);
  };
}
