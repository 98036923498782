import React from 'react';

import './styles.scss';

export default ({ color, label }) => {
  return (
    <div className="vChip">
      <div className="vChip__chip" style={{ backgroundColor: color }} />
      <p className="vChip__label">{label}</p>
    </div>
  );
};
