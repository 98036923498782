import React from 'react';
import './style.scss';

const ProgressBar = props => {
  const {
    firstLineValue = '10',
    firstLineColor = 'red',
    firstLineTitle = 'firstLineTitle',
    secondValue = '10',
    bigNumber,
    asideNumber,
  } = props;

  return (
    <div className="progress-component-container">
      <div className={`${asideNumber ? 'asideNumber' : ''}`}>
        {asideNumber ? (
          <div className="number">{asideNumber}</div>
        ) : (
          <div className="first-line">
            <div className="first-line-title">{firstLineTitle}</div>
            <div
              className="first-line-title-number"
              style={{ fontSize: bigNumber && 18, fontWeight: bigNumber && 'unset' }}
            >
              {secondValue}
            </div>
          </div>
        )}

        <div className="progress-component">
          <div
            className="first-progress-line"
            style={{ width: `${firstLineValue}%`, backgroundColor: firstLineColor }}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
