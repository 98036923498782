export const calculateUrgency = (pos, scale, rtl) => {
  return rtl ? Math.round(pos / scale) : 100 - Math.round(pos / scale);
};

export const calculateImpact = (pos, scale) => {
  return 100 - Math.round(pos / scale);
};

export const getPointX = (urgency, scale, rtl) => {
  return rtl ? Math.round(urgency * scale) : Math.round((100 - urgency) * scale);
};

export const getPointY = (impact, scale) => {
  return Math.round((100 - impact) * scale);
};

export const calculateTotal = (impact, urgency) => (impact + urgency) / 2;

const insidePoly = (point, vs) => {
  const x = point[0];
  const y = point[1];

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0];
    const yi = vs[i][1];
    const xj = vs[j][0];
    const yj = vs[j][1];

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

export const calculateBudgets = projects => {
  const areas = {
    must: [
      [100, 0],
      [90, 0],
      [50, 100],
      [100, 100],
    ],
    should: [
      [90, 0],
      [50, 100],
      [10, 100],
      [50, 0],
    ],
    wont: [
      [50, 0],
      [0, 0],
      [0, 100],
      [10, 100],
    ],
  };
  const budgets = {
    must: 0,
    should: 0,
    wont: 0,
  };
  projects.forEach(project => {
    if (!project.Urgency || !project.Impact) return;
    const point = [project.Urgency, project.Impact];
    if (insidePoly(point, areas.must)) {
      budgets.must += project.Budget;
    } else if (insidePoly(point, areas.should)) {
      budgets.should += project.Budget;
    } else if (insidePoly(point, areas.wont)) {
      budgets.wont += project.Budget;
    }
  });
  return budgets;
};

export const generateMustShape = (context, shape, grid, rtl) => {
  if (rtl) {
    context.beginPath();
    context.moveTo(grid.width, grid.height);
    context.lineTo(grid.width, 0);
    context.lineTo(grid.width * 0.5, 0);
    context.lineTo(grid.width * 0.9, grid.height);
    context.closePath();
    // (!) Konva specific method, it is very important
    context.fillStrokeShape(shape);
  } else {
    context.beginPath();
    context.moveTo(0, 0);
    context.lineTo(0, grid.height);
    context.lineTo(grid.width * 0.1, grid.height);
    context.lineTo(grid.width * 0.5, 0);
    context.closePath();
    // (!) Konva specific method, it is very important
    context.fillStrokeShape(shape);
  }
};

export const generateShouldShape = (context, shape, grid, rtl) => {
  if (rtl) {
    context.beginPath();
    context.moveTo(grid.width * 0.5, grid.height);
    context.lineTo(grid.width * 0.9, grid.height);
    context.lineTo(grid.width * 0.5, 0);
    context.lineTo(grid.width * 0.1, 0);
    context.closePath();
    // (!) Konva specific method, it is very important
    context.fillStrokeShape(shape);
  } else {
    context.beginPath();
    context.moveTo(grid.width * 0.5, 0);
    context.lineTo(grid.width * 0.1, grid.height);
    context.lineTo(grid.width * 0.5, grid.height);
    context.lineTo(grid.width * 0.9, 0);
    context.closePath();
    // (!) Konva specific method, it is very important
    context.fillStrokeShape(shape);
  }
};
