import React, { useEffect, useState } from 'react';
import PriorityDB from '../PriorityDB';
import RiskIssueLoader from '../RiskIssueLoader';
import ProgramsAPIController from '../../providers/controllers/ProgramsAPIController';
import { addToast } from '../../Helpers';

const PriorityDBTab = props => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  useEffect(() => {
    new ProgramsAPIController()
      .getProgramByCode(props.match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          setProjects(res.Data.Projects || []);
          setLoading(false);
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-project-toast',
        });
      });
  });

  return loading ? <RiskIssueLoader /> : <PriorityDB {...props} projects={projects} />;
};

export default PriorityDBTab;
