import React from 'react';
import Button from '../../../../components/Button';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

export default function SliderArrow({ className, to, onClick, id, text, themeColor }) {
  return (
    <Button
      type="button"
      onClick={onClick}
      className={`button button--text button--icon ${className}`}
      aria-label={to}
      backgroundColor={themeColor}
    >
      <FormattedMessage id={id} defaultMessage={text} />
    </Button>
  );
}

SliderArrow.propTypes = {
  className: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
};
