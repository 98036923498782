import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import DashboardSummaryCards from '../DashboardSummaryCards';
import DashboardBarChart from '../DashboardBarChart';
import DashboardInitiativesList from '../DashboardInitiativesList';
import DashboardFilters from '../DashboardFiltersPopUp';
import ProjectsPopUp from '../ProjectsPopUp';
import InitiativePopUp from '../InitiativePopUp';
import RiskIssuesPopUp from '../RiskIssuesPopUp';
import { ReactComponent as BagIcon } from '../../assets/images/bag.svg';
import { ReactComponent as DocumentIcon } from '../../assets/images/document.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/warning.svg';
import { ReactComponent as FireIcon } from '../../assets/images/fire.svg';
import { ReactComponent as LightBulbIcon } from '../../assets/images/light-bulb.svg';

import { getDashboardInitiativesStats } from '../../Helpers';

function DashboardInitiatives({ dashboard = {}, intl, filteredProjects, locale, theme, history }) {
  const initialStats = {};
  const initialStatePopUps = {
    projects: { isOpen: false, type: '', index: '' },
    initiative: { isOpen: false },
    riskissues: { isOpen: false, type: '' },
  };

  const [stats, setStats] = useState(initialStats);

  const [popUpsState, setpopUpsState] = useState(initialStatePopUps);

  const { loaders } = dashboard;

  const openPopUp = (mainType, type, index) => {
    setpopUpsState({
      ...popUpsState,
      [mainType]: { isOpen: true, type, index },
    });
  };

  const closePopUp = mainType => {
    setpopUpsState({
      ...popUpsState,
      [mainType]: { isOpen: false },
    });
  };

  const summaryData = [
    {
      id: 'Portfolios.Portfolios',
      value: stats.entitiesCount,
      Icon: BagIcon,
      badgeColor: '#152836',
    },
    {
      id: 'common.programs',
      value: stats.programsCount,
      Icon: LightBulbIcon,
      badgeColor: '#642045',
      onClick: () => openPopUp('initiative'),
    },
    {
      id: 'dashboard.projects',
      value: stats.projectsCount,
      Icon: DocumentIcon,
      badgeColor: '#81BDBB',
      onClick: () => openPopUp('projects'),
    },
    {
      id: 'common.risks',
      value: stats.risksCount,
      Icon: WarningIcon,
      badgeColor: '#FE9F00',
      onClick: () => openPopUp('riskissues', 'risks'),
    },
    {
      id: 'common.issues',
      value: stats.issuesCount,
      Icon: FireIcon,
      badgeColor: '#CE1818',
      onClick: () => openPopUp('riskissues', 'issues'),
    },
  ];

  function onApplyFilters(filters) {
    const newStats = getDashboardInitiativesStats(dashboard, filteredProjects, filters);
    setStats(newStats);
  }

  useEffect(() => {
    const newStats = getDashboardInitiativesStats(dashboard, filteredProjects);
    setStats(newStats);
  }, [dashboard, filteredProjects]);

  const financialInfoLabels = [
    {
      name: intl.formatMessage({ id: 'datacard.budget' }),
      color: '#642045',
    },
    {
      name: intl.formatMessage({ id: 'datacard.allocated' }),
      color: '#152836',
    },
    {
      name: intl.formatMessage({ id: 'dashboard.spent' }),
      color: '#F1993F',
    },
    {
      name: intl.formatMessage({ id: 'common.remaining-budget' }),
      color: '#6D2177',
    },
  ];

  return (
    <>
      <div className="dashboard-initiatives">
        <DashboardSummaryCards data={summaryData} loading={loaders.allProjects} />
        <div className="dashboard__info">
          <DashboardBarChart
            type="byStatus"
            projects={stats.programs}
            title={intl.formatMessage({ id: 'dashboard.initiatives-count-by-status' })}
            loading={loaders.allProjects}
            onClick={(data, values) => {
              if (data) {
                openPopUp('initiative', 'Status', values[data._index]);
              }
            }}
            isProgram
          />
          <DashboardBarChart
            type="byPhase"
            projects={stats.projects}
            title={intl.formatMessage({ id: 'dashboard.project-count-by-phase' })}
            loading={loaders.allProjects}
            onClick={(data, values) => {
              if (data) {
                openPopUp('projects', 'Phase', values[data._index]);
              }
            }}
          />
          <DashboardBarChart
            type="financial"
            barData={stats.financialInfo}
            customLabels={financialInfoLabels}
            title={intl.formatMessage({ id: 'dashboard.financial-info' })}
            projects={stats.projects}
            loading={loaders.allProjects}
          />
        </div>
        <div className="dashboard-initiatives__list-container">
          <DashboardInitiativesList entities={stats.entities} />
        </div>
      </div>

      {popUpsState.projects.isOpen && (
        <ProjectsPopUp
          projects={
            popUpsState.projects.index
              ? stats.groupedByData[popUpsState.projects.type][popUpsState.projects.index]
              : stats.projects
          }
          intl={intl}
          locale={locale}
          theme={theme}
          isOpen={popUpsState.projects.isOpen}
          history={history}
          onClose={() => {
            closePopUp('projects');
          }}
        />
      )}

      {popUpsState.initiative.isOpen && (
        <InitiativePopUp
          projects={
            popUpsState.initiative.index
              ? stats.groupedByData[popUpsState.initiative.type][popUpsState.initiative.index]
              : stats.programs
          }
          intl={intl}
          locale={locale}
          theme={theme}
          isOpen={popUpsState.initiative.isOpen}
          onClose={() => {
            closePopUp('initiative');
          }}
        />
      )}
      {popUpsState.riskissues.isOpen && (
        <RiskIssuesPopUp
          intl={intl}
          theme={theme}
          locale={locale}
          onClose={() => closePopUp('riskissues')}
          data={popUpsState.riskissues.type === 'risks' ? stats.risks : stats.issues}
          type={popUpsState.riskissues.type}
        />
      )}

      <DashboardFilters onApplyFilters={onApplyFilters} />
    </>
  );
}

const mapStateToProps = ({ dashboard, language, theme }) => ({
  dashboard,
  theme,
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps)(DashboardInitiatives));
