import React from 'react';
import { FormattedMessage } from 'react-intl';
import './style.scss';

function Flag(props) {
  const { data, locale } = props;
  return (
    <div className="text-info-item">
      <div className="text-info-item__title bold-text">{locale === 'ar' ? data.Arabic : data.English}</div>
      <span className="text-info-item__value text-gray">
        <FormattedMessage id={data.itemData === 'True' ? 'common.yes' : 'common.no'} />
      </span>
    </div>
  );
}

export default Flag;
