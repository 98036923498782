import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BackIcon } from '../../assets/images/back-arrow.svg';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import RiskIssueModalLoading from '../EscalationsLoader';
import { checkOwnerUser, sortSteps, addToast } from '../../Helpers';
import ActionsLog from '../ActionsLog';
import './style.scss';

class Escalations extends Component {
  state = {
    laoding: true,
    escalatedData: {},
  };

  componentDidMount() {
    this.getEscalation();
  }

  getEscalation = () => {
    const { match } = this.props;
    this.setState({ laoding: true }, () => {
      new ProjectAPIController()
        .getEscalation(match.params.id)
        .then(res => {
          this.setState({
            laoding: false,
            escalatedData: {
              ...res.Data,
              Steps: sortSteps(res.Data.Steps),
            },
          });
        })
        .catch(() => {
          addToast.error('toast.error', {
            toastId: 'get-escalation-error',
          });
        });
    });
  };

  render() {
    const {
      changeView,
      wrapperClassname = '',
      modalView,
      theme,
      match,
      user: { user },
    } = this.props;
    const { laoding, escalatedData } = this.state;

    if (laoding) return <RiskIssueModalLoading />;
    const isOwner = checkOwnerUser(escalatedData.Steps, user.Username, escalatedData.CurrentStep);

    return (
      <section className={`project-escalations-container ${wrapperClassname}`}>
        {!modalView ? (
          <div className="sec-header-container tab-header">
            <div
              className="back-button colorBlue cursor"
              onClick={() => changeView('escalations', match.params.id, match.params.code)}
            >
              <BackIcon width="15px" height="15px" fill={theme.ThemeColor.item.Text} />
            </div>
            <div className="title bold-text">
              <FormattedMessage id="project-details.action-log" />
            </div>
          </div>
        ) : null}
        <div className="main-content">
          <div className="esc-items-container full-width">
            <div className="main-line" />

            <div className="esc-actions m-top">
              <ActionsLog
                comments={escalatedData.Log}
                escalatedData={escalatedData}
                escalationID={escalatedData.ID}
                allowComment={isOwner}
                actionLogPage
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Escalations;
