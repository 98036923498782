import React, { Component } from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import Button from '../../Button';
import { ReactComponent as BackIcon } from '../../../assets/images/back-arrow.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';

import { postRisk } from '../../../actions/project';
import { postNotification } from '../../../actions/userActions';

import {
  findUser,
  getClassification,
  getClassificationColor,
  getClassificationName,
  getClassificationSelect,
  reduceClassificationList,
  reduceUsersList,
  risksConstants,
  mergeAttachments,
  getImpactNumber,
  getProbabilityNumber,
  getImpactString,
  getProbabiltyString,
  getProjectLocalizedName,
  addToast,
} from '../../../Helpers';

import './style.scss';
import Field from '../../Field';

class ProjectRisksAdd extends Component {
  constructor(props) {
    super(props);
    const {
      match,
      risksList,
      user: {
        user: { Username },
      },
    } = props;
    let isEdit = props.mode;
    let riskDetails = null;
    if (isEdit) {
      riskDetails = risksList.find(item => item.ID.toString() === match.params.id);
      if (!riskDetails) {
        props.changeView('base');
      }
      isEdit = isEdit && riskDetails;
    }
    const attachments = isEdit ? riskDetails.Attachments || [] : [];
    const Impacts = reduceClassificationList(props.theme.RisksImpacts.item.Items, props.locale);
    const Probabilities = reduceClassificationList(props.theme.RisksProbabilities.item.Items, props.locale);
    const Categories = reduceClassificationList(props.theme.RiskCategories.item.Items, props.locale);
    const selectedImpact = isEdit
      ? Impacts.find(impact => impact.value === getImpactString(parseInt(riskDetails.Impact, 10)))
      : Impacts[0];
    const selectedProbabilty = isEdit
      ? Probabilities.find(probabilty => probabilty.value === getProbabiltyString(parseFloat(riskDetails.Probability)))
      : Probabilities[0];
    const selectedCategory = isEdit
      ? Categories.find(category => category.value === riskDetails.Category)
      : Categories[0];

    this.state = {
      riskDetails,
      ID: isEdit ? riskDetails.ID : 0,
      Title: isEdit ? riskDetails.Title : '',
      Description: isEdit ? riskDetails.Description : '',
      AssignedTo: null,
      MitigationPlan: isEdit ? riskDetails.MitigationPlan : '',
      DueDate: isEdit
        ? moment(riskDetails.DueDate)
            .locale('en')
            .valueOf()
        : new Date(),
      Owner: isEdit ? riskDetails.OwnerModel.Username : Username,
      selectedCategory,
      attachments,
      selectedImpact,
      selectedProbabilty,
      users: [],
      isLoadingData: true,
    };
  }

  componentDidMount() {
    this.findAllUsers();
  }

  throwError = (backendError = false) => {
    addToast.error(backendError ? 'common.errorSavingChanges' : 'common.fillRequiredFields', {
      toastId: 'risk-add-error',
    });
  };

  validateFields = () => {
    const { Title, Description, MitigationPlan, DueDate } = this.state;

    if (!Title.trim() || !Description.trim() || !MitigationPlan.trim() || !DueDate) {
      return false;
    }

    return true;
  };

  handleSave = async () => {
    const {
      props: { match, postRiskAction, onRiskAddition, changeView },
      state: {
        Title,
        Description,
        selectedCategory,
        MitigationPlan,
        Owner,
        attachments,
        AssignedTo,
        DueDate,
        selectedImpact,
        selectedProbabilty,
        ID,
      },
      validateFields,
      throwError,
      pushNotification,
    } = this;
    const isValid = validateFields();

    if (!isValid) throwError();
    else {
      const _attachments = await mergeAttachments(attachments);
      const data = {
        projectUID: match.params.code,
        risk: {
          AssignedTo: AssignedTo.value,
          Attachments: _attachments,
          Category: selectedCategory.value,
          Classification: selectedImpact.value,
          Description,
          DueDate: moment(DueDate)
            .locale('en')
            .format('DD-MM-YYYY'),
          HasAttachments: _attachments.length > 0,
          ID,
          Impact: getImpactNumber(selectedImpact.value),
          ImpactValue: selectedImpact.value,
          ImpactString: selectedImpact.value,
          MitigationPlan,
          Owner,
          Probability: getProbabilityNumber(selectedProbabilty.value),
          ProbabilityValue: selectedProbabilty.value,
          ProbabilityString: selectedProbabilty.value,
          ProjectUID: match.params.code,
          Status: risksConstants.statusActive,
          Title,
          itemType: 'risk',
        },
      };
      postRiskAction(
        data,
        () => {
          onRiskAddition();
          changeView('base');
          pushNotification(Title);
          addToast.success('common.changesHaveBeenSaved');
        },
        () => {
          throwError(true);
        },
      );
    }
  };

  pushNotification = title => {
    const {
      match,
      postNotificationAction,
      mode,
      projectDetails,
      program,
      locale,
      user: { user: loggedUser },
    } = this.props;
    const action = mode ? 'modified a' : 'added a new';

    const notification = {
      notification: {
        Body: `${loggedUser.DisplayName} ${action} risk "${title}" on project ${
          program ? getProjectLocalizedName(program, locale) : projectDetails.ProjectName
        }`,
        ProjectUID: match.params.code,
        ReceiverUserName: null,
        Title: loggedUser.DisplayName,
        Type: 1,
      },
    };

    postNotificationAction(notification);
  };

  findAllUsers() {
    const { match, mode } = this.props;
    const { riskDetails } = this.state;
    const isEdit = mode && riskDetails;

    new ProjectAPIController()
      .getUsers(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({
            users: reduceUsersList(res.Data),
            AssignedTo: isEdit
              ? findUser(reduceUsersList(res.Data), riskDetails.AssignedTo.Username, 'value')
              : reduceUsersList(res.Data)[0],
            isLoadingData: false,
          });
        }
      })
      .catch(error => {
        addToast.error(error.message);
      });
  }

  render() {
    const { theme, locale, intl, mode, postingRisk, changeView } = this.props;
    const {
      ID,
      Title,
      Description,
      selectedImpact,
      MitigationPlan,
      selectedProbabilty,
      DueDate,
      users,
      AssignedTo,
      selectedCategory,
      attachments,
      isLoadingData,
    } = this.state;

    const titleFieldPlaceholder = intl.formatMessage({ id: 'projectrisk.title-field-placeholder' });
    const detailFieldPlaceholder = intl.formatMessage({
      id: 'projectrisk.detail-field-placeholder',
    });
    const mitigationPlanPlaceholder = intl.formatMessage({
      id: 'project-issues-add.mitigation-plan-placeholder',
    });

    return (
      <section className={`project-risks-add-container ${postingRisk ? 'loading' : ''}`}>
        <div className="sec-header-container tab-header">
          <div
            className="back-button cursor"
            style={{ color: theme.ThemeColor.item.Text }}
            onClick={() => (ID ? changeView('details', ID) : changeView('base'))}
          >
            <BackIcon width="15px" height="15px" fill={theme.ThemeColor.item.Text} />
          </div>
          <div className="title bold-text">
            <FormattedMessage id="projectrisk.add" />
          </div>
          <div className="section-actions">
            <Button
              backgroundColor={theme.ThemeColor.item.Text}
              onClick={this.handleSave}
              disabled={postingRisk || isLoadingData}
              loading={postingRisk}
              Icon={() => <CheckIcon fill="#fff" />}
            >
              {mode ? (
                <FormattedMessage id="project-issues-add.update-btn-label" />
              ) : (
                <FormattedMessage id="project-issues-add.save-btn-label" />
              )}
            </Button>
          </div>
        </div>
        <div className="main-content tTab-content-container">
          <div className="form">
            <div className="form-label">
              <span style={{ background: theme.ThemeColor.item.Text }}>1</span>
              <FormattedMessage id="projectrisk.risk-details" />
            </div>
            <form>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="projectrisk.title" />
                </span>
                <Field
                  control={{
                    type: 'input',
                    onChangeAction: e => this.setState({ Title: e.target.value }),
                    placeholder: titleFieldPlaceholder,
                    value: Title,
                    validateOnExite: true,
                    error: !Title.trim().length,
                  }}
                />
              </div>
              <div className="control">
                <span className="label">
                  <FormattedMessage id="common.details" />
                </span>
                <Field
                  control={{
                    type: 'textarea',
                    onChangeAction: e => this.setState({ Description: e.target.value }),
                    placeholder: detailFieldPlaceholder,
                    value: Description,
                    validateOnExite: true,
                    error: !Description.trim().length,
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="projectrisk.category" />
                </span>
                <Field
                  control={{
                    type: 'select',
                    onChangeAction: e => this.setState({ selectedCategory: e }),
                    value: selectedCategory,
                    className: 'users-select-container',
                    classNamePrefix: 'users-select',
                    options: reduceClassificationList(theme.RiskCategories.item.Items, locale),
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="projectrisk.impact-probability" />
                </span>
                <Field
                  control={{
                    type: 'select',
                    onChangeAction: e => this.setState({ selectedImpact: e }),
                    value: selectedImpact,
                    options: reduceClassificationList(theme.RisksImpacts.item.Items, locale),
                    className: 'impact-select-container',
                    classNamePrefix: 'impact-select',
                  }}
                />
                <Field
                  control={{
                    type: 'select',
                    onChangeAction: e => this.setState({ selectedProbabilty: e }),
                    value: selectedProbabilty,
                    options: reduceClassificationList(theme.RisksProbabilities.item.Items, locale),
                    className: 'probabilty-select-container',
                    classNamePrefix: 'probabilty-select',
                  }}
                />
              </div>
              <div className="control">
                <span className="label" />
                <div
                  className="control-info"
                  style={{
                    background: getClassificationColor(
                      theme.RisksClassifications.item.Items,
                      getClassificationSelect(selectedImpact.value, selectedProbabilty.value),
                    ),
                  }}
                >
                  <span>
                    {getClassificationName(
                      theme.RisksClassifications.item.Items,
                      getClassification({
                        Impact: getImpactNumber(selectedImpact.value),
                        Probability: getProbabilityNumber(selectedProbabilty.value),
                      }),
                      locale,
                    )}{' '}
                    <FormattedMessage id="projectrisk.risk" />
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className="form">
            <div className="form-label">
              <span style={{ background: theme.ThemeColor.item.Text }}>2</span>
              <FormattedMessage id="projectrisk.risk-solution" />
            </div>
            <form>
              <div className="control">
                <span className="label">
                  <FormattedMessage id="projectrisk.mitigation-plan" />
                </span>
                <Field
                  control={{
                    type: 'textarea',
                    onChangeAction: e => this.setState({ MitigationPlan: e.target.value }),
                    placeholder: mitigationPlanPlaceholder,
                    value: MitigationPlan,
                    validateOnExite: true,
                    error: !MitigationPlan.trim().length,
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="projectrisk.due-date" />
                </span>
                <Field
                  control={{
                    type: 'date',
                    onChangeAction: e => this.setState({ DueDate: e }),
                    locale: locale === 'ar' ? 'ar' : 'en',
                    value: DueDate,
                    validateOnExite: true,
                    error: !DueDate,
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="project-issues-add.assigned-to" />
                </span>
                <Field
                  control={{
                    type: 'select',
                    onChangeAction: e => this.setState({ AssignedTo: e }),
                    value: AssignedTo,
                    options: users,
                    className: 'users-select-container',
                    classNamePrefix: 'users-select',
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="projectrisk.attachments" />
                </span>
                <Field
                  control={{
                    type: 'attachment',
                    onChangeAction: e => {
                      this.setState({ attachments: [...e] });
                    },
                    value: attachments,
                    // validation: this.inputValidation,
                    validateOnExite: false,
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ user, project }) => ({
  user,
  postingRisk: project.loaders.postRisk,
});

const mapDispatchToProps = {
  postRiskAction: postRisk,
  postNotificationAction: postNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProjectRisksAdd));
