import React from 'react';
import './style.scss';

function Description(props) {
  const { data, locale } = props;
  return (
    <div className="project-description-container">
      <div className="bold-text project-description-header">{locale === 'ar' ? data.Arabic : data.English}</div>
      <span className="project-description-value">{data.itemData ? data.itemData : '---'}</span>
    </div>
  );
}

export default Description;
