import React from 'react';
import { injectIntl } from 'react-intl';
import { ProcessesTextField } from '../ProcessesTextArea';
import ProcessesProjectInfo from '../ProcessesProjectInfo';
import Deliverables from '../DeliverablesAcceptanceList';

function DeliverablesAcceptanceForm({ processType, project, data, saveData, readOnly, intl }) {
  return (
    <div>
      <ProcessesProjectInfo request={data} project={project} processType={processType} />
      <div className="processes-project-info">
        <ProcessesTextField
          title={intl.formatMessage({ id: 'change-request.arabic-title' })}
          classes="half rtl"
          name="TitleArabic"
          data={data.TitleArabic}
          onSave={saveData}
          readOnly={readOnly}
          required
        />
        <ProcessesTextField
          title={intl.formatMessage({ id: 'change-request.english-title' })}
          classes="half ltr"
          name="TitleEnglish"
          data={data.TitleEnglish}
          onSave={saveData}
          readOnly={readOnly}
          required
        />
      </div>
      <Deliverables data={data.Deliverables} saveData={saveData} readOnly={readOnly} showCheckbox />
    </div>
  );
}

export default injectIntl(DeliverablesAcceptanceForm);
