import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';

import Breadcrumbs from '../../../components/Breadcrumb';
import Field from '../../../components/Field';
import DownloadButton from '../../../components/DownloadButton';
import { ReportsListLoader, ReportsContentLoader } from './loader';

import { addToast } from '../../../Helpers';
import PortfolioaAPIController from '../../../providers/controllers/PortfolioAPIController';
import ProgramsAPIController from '../../../providers/controllers/ProgramsAPIController';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';

import './style.scss';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfoliosList: [],
      programsList: [],
      usersList: [],
      programs: null,
      portfolio: null,
      program: null,
      project: null,
      category: null,
      status: null,
      classification: null,
      priority: null,
      startDate: null,
      finishDate: null,
      user: null,
      selectedReport: null,
      programsLoading: true,
      initiativesLoading: true,
      usersLoading: true,
    };

    this.reportsList = [
      {
        type: 'project',
        filters: ['Portfolio', 'Program'],
      },
      {
        type: 'program',
        filters: ['Portfolio'],
      },
      {
        type: 'risk',
        filters: ['Portfolio', 'Program', 'Project', 'Category', 'Status', 'Classification'],
      },
      {
        type: 'issue',
        filters: ['Portfolio', 'Program', 'Project', 'Category', 'Status', 'Priority'],
      },
      {
        type: 'financial',
        filters: ['Portfolio'],
      },
      {
        type: 'Audit_Trail',
        filters: ['StartDate', 'FinishDate', 'User'],
      },
    ];

    this.getAllPortfolios();
    this.getAllPrograms();
    this.getAllUsers();
  }

  componentDidMount() {
    const { selectedReport } = this.state;
    this.setState({ selectedReport: !selectedReport ? this.reportsList[0] : selectedReport });
  }

  getAllPortfolios() {
    new PortfolioaAPIController()
      .getAllPortfolio()
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({ portfoliosList: res.Data, programsLoading: false });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-portfolios-error',
        });
      });
  }

  getAllPrograms() {
    new ProgramsAPIController()
      .getAllPrograms()
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({ programsList: res.Data, initiativesLoading: false });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-programs-error',
        });
      });
  }

  getAllUsers = () => {
    new ProjectAPIController()
      .getAllUsers()
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({ usersList: res.Data, usersLoading: false });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-users-error',
        });
      });
  };

  getListOptions(list) {
    const { locale } = this.props;

    const _list = [];
    list.forEach(item => {
      _list.push({
        value: item.Code || item.Value || item.Username || item.ProjectUID,
        label:
          locale === 'ar'
            ? item.NameArabic || item.Arabic || item.DisplayName
            : item.NameEnglish || item.English || item.DisplayName,
      });
    });
    return _list;
  }

  getDownloadURL(isPDF) {
    const { selectedReport } = this.state;
    const typeParam = selectedReport.type === 'financial' ? 'financial' : `${selectedReport.type}s`;
    let _params = `${typeParam}?`;
    selectedReport.filters.forEach((item, index) => {
      const stateKey = `${item.charAt(0).toLowerCase()}${item.slice(1)}`;
      const { [stateKey]: stateVal } = this.state;
      let paramValue = stateVal;
      const prefix = index > 0 ? '&' : '';
      const appendix = stateVal ? '=' : '';
      const paramKey = `${prefix}${stateKey}${appendix}`;
      let _param = '';

      if (stateVal?.value) {
        paramValue = stateVal.value;
      } else if (moment(stateVal).isValid()) {
        paramValue = moment(stateVal).toJSON();
      }

      _param = `${paramKey}${paramValue || ''}`;
      _params += _param;
    });
    _params += `${selectedReport.filters.length ? '&' : ''}isPDF=${isPDF}`;

    return `${window.env.PP_REPORTS_URL}${_params}`;
  }

  getDownloadableFileName(type) {
    const {
      props: { intl },
      state: { selectedReport },
    } = this;
    const name = intl.formatMessage({ id: `reports.${selectedReport.type}` });
    const dateAndTime = moment().format('MM-DD-YYYY hh:mm');

    return `${name} - ${dateAndTime}.${type}`;
  }

  getIsDownloadDisabled = () => {
    const { selectedReport } = this.state;

    switch (selectedReport?.type) {
      default: {
        return false;
      }
    }
  };

  selectReport(report) {
    this.setState({
      selectedReport: report,
      programs: null,
      portfolio: null,
      program: null,
      project: null,
      category: null,
      status: null,
      classification: null,
      priority: null,
      startDate: null,
      finishDate: null,
      user: null,
    });
  }

  render() {
    const {
      programs,
      portfolio,
      program,
      project,
      category,
      status,
      classification,
      priority,
      startDate,
      finishDate,
      user,
      selectedReport,
      portfoliosList,
      programsList,
      usersList,
      usersLoading,
      programsLoading,
      initiativesLoading,
    } = this.state;

    const { intl, theme } = this.props;
    const { reportsList, getIsDownloadDisabled } = this;
    const statusesList = `${selectedReport?.type.charAt(0).toUpperCase()}${selectedReport?.type.slice(1)}Statuses`;
    const categoriesList = `${selectedReport?.type.charAt(0).toUpperCase()}${selectedReport?.type
      .slice(1)
      .replace(/status/gi, '')}Categories`;
    const isExcelDownloadBtn = selectedReport?.type === 'financial' || selectedReport?.type === 'Audit_Trail';

    reportsList.map(item => {
      const _item = item;
      _item.title = intl.formatMessage({ id: `reports.${item.type}` });
      return _item;
    });

    return (
      <div className="container">
        <div className="bread">
          <Breadcrumbs
            title="common.reports"
            routes={[{ title: intl.formatMessage({ id: 'common.reports' }), location: 'reports' }]}
          />
        </div>
        <div className="reports-page-container">
          {usersLoading || programsLoading || initiativesLoading ? (
            <>
              <div className="left-col" style={{ borderTopColor: theme.ThemeColor.item.Text }}>
                <ReportsListLoader />
              </div>
              <div className="reports-filters" style={{ borderTopColor: theme.ThemeColor.item.Text }}>
                <ReportsContentLoader />
              </div>
            </>
          ) : (
            <>
              <div className="left-col" style={{ borderTopColor: theme.ThemeColor.item.Text }}>
                <div className="title">
                  <FormattedMessage id="reports.list" />
                </div>
                <div className="reports-list">
                  {reportsList.map(item => (
                    <div
                      key={item.type}
                      onClick={() => this.selectReport({ title: item.title, filters: item.filters, type: item.type })}
                      style={{
                        borderInlineStartColor:
                          selectedReport.type === item.type ? theme.ThemeColor.item.Text : 'transparent',
                        fontWeight: selectedReport.title === item.title ? 'bold' : 'normal',
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
                </div>
              </div>
              <div className="reports-filters" style={{ borderTopColor: theme.ThemeColor.item.Text }}>
                <div className="report-header">
                  <div className="title">
                    <FormattedMessage id="reports.details" />
                  </div>
                  <div className="actions">
                    {!isExcelDownloadBtn ? (
                      <>
                        <DownloadButton
                          url={this.getDownloadURL(true)}
                          filename={this.getDownloadableFileName('pdf')}
                          backgroundColor={theme.ThemeColor.item.Text}
                          color="white"
                          disabled={getIsDownloadDisabled()}
                        >
                          <FormattedMessage id="reports.download-pdf" />
                        </DownloadButton>
                        <DownloadButton
                          url={this.getDownloadURL(false)}
                          filename={this.getDownloadableFileName('docx')}
                          backgroundColor={theme.ThemeColor.item.Text}
                          color="white"
                          disabled={getIsDownloadDisabled()}
                        >
                          <FormattedMessage id="reports.download-word" />
                        </DownloadButton>
                      </>
                    ) : (
                      <DownloadButton
                        url={this.getDownloadURL(false)}
                        filename={this.getDownloadableFileName('xls')}
                        backgroundColor={theme.ThemeColor.item.Text}
                        color="white"
                        disabled={getIsDownloadDisabled()}
                      >
                        <FormattedMessage id="reports.download-excel" />
                      </DownloadButton>
                    )}
                  </div>
                </div>
                <div className="form">
                  {/* Portfolio ddl */}
                  {selectedReport?.filters.find(item => item === 'Portfolio') ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.portfolio" />
                      </span>
                      <Field
                        control={{
                          type: 'select',
                          onChangeAction: e =>
                            this.setState({ portfolio: e, programs: null, program: null, project: null }),
                          value: portfolio,
                          className: 'users-select-container',
                          classNamePrefix: 'users-select',
                          options: this.getListOptions(portfoliosList),
                          isClearable: true,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* Program ddl */}
                  {selectedReport?.filters.find(item => item.includes('Program')) ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.program" />
                      </span>
                      <Field
                        control={{
                          type: 'select',
                          onChangeAction: e => this.setState({ programs: e, program: e, project: null }),
                          value: programs || program,
                          className: 'users-select-container',
                          classNamePrefix: 'users-select',
                          options: this.getListOptions(
                            !portfolio
                              ? []
                              : [...programsList].filter(
                                  _initiative => _initiative?.Portfolio?.Code === portfolio?.value,
                                ),
                          ),
                          isClearable: true,
                          disabled: !portfolio,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* Project ddl */}
                  {selectedReport?.filters.find(item => item.includes('Project')) ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.project" />
                      </span>
                      <Field
                        control={{
                          type: 'select',
                          onChangeAction: e => this.setState({ project: e }),
                          value: project,
                          className: 'users-select-container',
                          classNamePrefix: 'users-select',
                          options: this.getListOptions(
                            program ? programsList.find(prog => prog.Code === program.value)?.Projects : [],
                          ),
                          isClearable: true,
                          disabled: !program,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* Category ddl */}
                  {selectedReport?.filters.find(item => item === 'Category') ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.category" />
                      </span>
                      <Field
                        control={{
                          type: 'select',
                          onChangeAction: e => this.setState({ category: e }),
                          value: category,
                          className: 'users-select-container',
                          classNamePrefix: 'users-select',
                          options: this.getListOptions(theme[categoriesList].item.Items),
                          isClearable: true,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* Status ddl */}
                  {selectedReport?.filters.find(item => item === 'Status') ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.status" />
                      </span>
                      <Field
                        control={{
                          type: 'select',
                          onChangeAction: e => this.setState({ status: e }),
                          value: status,
                          className: 'users-select-container',
                          classNamePrefix: 'users-select',
                          options: this.getListOptions(theme[statusesList].item.Items),
                          isClearable: true,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* Classification ddl */}
                  {selectedReport?.filters.find(item => item === 'Classification') ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.classification" />
                      </span>
                      <Field
                        control={{
                          type: 'select',
                          onChangeAction: e => this.setState({ classification: e }),
                          value: classification,
                          className: 'users-select-container',
                          classNamePrefix: 'users-select',
                          options: this.getListOptions(theme.RisksClassifications.item.Items),
                          isClearable: true,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* Priority ddl */}
                  {selectedReport?.filters.find(item => item === 'Priority') ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.priority" />
                      </span>
                      <Field
                        control={{
                          type: 'select',
                          onChangeAction: e => this.setState({ priority: e }),
                          value: priority,
                          className: 'users-select-container',
                          classNamePrefix: 'users-select',
                          options: this.getListOptions(theme.IssuePriorities.item.Items),
                          isClearable: true,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* StartDate ddl */}
                  {selectedReport?.filters.find(item => item === 'StartDate') ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.startDate" />
                      </span>
                      <Field
                        control={{
                          type: 'date',
                          onChangeAction: e => this.setState({ startDate: e }),
                          value: startDate,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* FinishDate ddl */}
                  {selectedReport?.filters.find(item => item === 'FinishDate') ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.finishDate" />
                      </span>
                      <Field
                        control={{
                          type: 'date',
                          onChangeAction: e => this.setState({ finishDate: e }),
                          value: finishDate,
                        }}
                      />
                    </div>
                  ) : null}

                  {/* User ddl */}
                  {selectedReport?.filters.find(item => item === 'User') ? (
                    <div className="control">
                      <span className="label flex-v-center">
                        <FormattedMessage id="common.user" />
                      </span>
                      <Field
                        control={{
                          type: 'select',
                          onChangeAction: e => this.setState({ user: e }),
                          value: user,
                          className: 'users-select-container',
                          classNamePrefix: 'users-select',
                          options: this.getListOptions(usersList),
                          isClearable: true,
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme, language }) => ({
  theme,
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps)(Reports));
