import moment from 'moment';

export default function calculateMilestonesWeight(milestones) {
  const milestonesWeight = milestones.reduce((total, milestone) => {
    return total + (parseFloat(milestone.CompletedWorkWeight, 10) || 0);
  }, 0);
  return milestonesWeight;
}

export function formatDateString(string, char = '-') {
  const dateArray = string.split(char);

  let date = moment(new Date(dateArray[2], dateArray[1] - 1, dateArray[0])).format('M/DD/YYYY hh:mm:ss A');
  if (date === 'Invalid date' || dateArray.length !== 3) {
    date = string;
  }
  return date;
}
