import React from 'react';
// eslint-disable-next-line
import { default as ReactModal } from 'react-modal';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';

import './styles.scss';

ReactModal.setAppElement(document.getElementById('root'));
// eslint-disable-next-line
class Modal extends React.Component {
  render() {
    const {
      props: { children, onClose, dashBoardPopUp, isOpen, width, title, ...rest },
    } = this;

    const customStyles = {
      content: {
        width: width || '100%',
      },
    };

    return (
      <div>
        <ReactModal isOpen={isOpen} onRequestClose={onClose} {...rest} style={dashBoardPopUp && customStyles}>
          <div className="modal__topbar">
            <div className="modal__topbar-title">{title ? <h1>{title}</h1> : null}</div>
            <CloseIcon onClick={onClose} />
          </div>
          <div className="modal__content">{children}</div>
        </ReactModal>
      </div>
    );
  }
}

export default Modal;
