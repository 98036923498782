import React from 'react';

import { FormattedMessage } from 'react-intl';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/email.svg';
import { ReactComponent as ExtentionIcon } from '../../assets/images/extention.svg';
import { getProjectCardField } from '../../Helpers';
import ProjectDataType from '../ProjectDataType';
import ProjectPreviewLoader from './ProjectPreviewLoader';
import Avatar from '../Avatar';

import './style.scss';
import { ReportsUrl } from '../../providers/config/urlServiceConfig';

function ProjectPreview(props) {
  const { projectDetails, theme, locale, loading, match, user } = props;
  const PPlusProjectDetailsReport = locale === 'en' ? 'PPlusProjectDetails-EN' : 'PPlusProjectDetails-AR';

  if (loading) return <ProjectPreviewLoader />;

  const ProjectCard = getProjectCardField(theme.Project_Card_Fields.item.Items, projectDetails.CustomFields);

  return (
    <section className="project-preview-container">
      <div className="title bold-text">
        <FormattedMessage id="project.details-preview" />
        <div className="section-actions">
          {ReportsUrl ? (
            <a
              download
              target="_blank"
              rel="noopener noreferrer"
              href={`${ReportsUrl}${PPlusProjectDetailsReport}&rs:Command=Render&projectUID=${match.params.code}&LoginUserName=${user.user.Username}&rs:Format=PDF`}
              className="action report-link"
              style={{ background: theme.ThemeColor.item.Text }}
            >
              <FormattedMessage id="project-preview.project-details-report" />
            </a>
          ) : null}
        </div>
      </div>
      <div className="main-content">
        <div className="user-avatar-container">
          <Avatar user={projectDetails.ProjectManager} width={100} />
        </div>
        <div className="user-info">
          <div className="user-name bold-text">{projectDetails.ProjectManager.DisplayName}</div>
          <div className="user-secondary-info">
            <FormattedMessage id="project.details-manager" />
          </div>
          <div className="user-contact">
            <div className="contact-item">
              <PhoneIcon className="contact-icon" width="17px" height="17px" stroke={theme.ThemeColor.item.Text} />
              <div>
                <FormattedMessage id="project.details-phone" /> : &nbsp;
                <span className="contact-item__value">
                  {projectDetails.ProjectManager.Phone ? projectDetails.ProjectManager.Phone : '---'}
                </span>
              </div>
            </div>
            <div className="contact-item">
              <ExtentionIcon className="contact-icon" width="17px" height="17px" stroke={theme.ThemeColor.item.Text} />
              <div>
                <FormattedMessage id="project.details-extention" /> : &nbsp;
                <span className="contact-item__value">
                  {projectDetails.ProjectManager.Ext ? projectDetails.ProjectManager.Ext : '---'}
                </span>
              </div>
            </div>
          </div>
          <div className="user-contact">
            <div className="contact-item">
              <EmailIcon className="contact-icon" width="17px" height="17px" stroke={theme.ThemeColor.item.Text} />
              <div>
                <FormattedMessage id="project.details-email" /> : &nbsp;
                <span className="contact-item__value">
                  {projectDetails.ProjectManager.Email ? projectDetails.ProjectManager.Email : '---'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="secondary-content tTab-content-container">
        {ProjectCard.length > 0 ? (
          <div>
            {ProjectCard.map((item, i) => (
              <ProjectDataType type={item.Style} key={i} data={item} locale={locale} />
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default ProjectPreview;
