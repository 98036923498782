export default {
  'projectdata.financial': 'المالية',
  'documentsLibrary.attachFile': 'أرفق ملف',
  'attachment.only10FilesAtOnce': 'الحد الأقصى للملفات المسموح برفعها 10 ملفات لكل مرة',
  'attachment.fileTypeError': 'لم يتم رفع ملف أو أكثر بسبب نوع الملف',
  'portfolio.total-project': 'عدد المشاريع',
  'portfolio.progress': 'تقدم',
  'portfolio.total-budget': 'الميزانية الإجمالية',
  'portfolio.risks': 'المخاطر',
  'portfolio.issues': 'المشاكل',
  'header.notification': 'التنبيهات',
  'header.notification-see-more': 'شاهد المزيد',
  'header.view-profile': 'عرض الصفحة الشخصية',
  'header.view-my-profile': 'الملف الشخصي',
  'header.view-activity': 'الآنشطة',
  'header.view-chat': 'دردشة',
  'header.view-faq': 'FAQ',
  'header.view-support': 'مساعده',
  'header.view-logout': 'تسجيل خروج',
  'header.searchHere': 'ابحث هنا...',
  'header.language': 'اللغة',
  'header.tasksCenter': 'مركز المهام',
  deliverables: 'التسلميات',
  phasesGateReview: 'بوابه المراحل',
  'deliverables.add-deliverable': 'إضافة مخرج',
  'deliverables.deliverable-name': 'اسم المخرج',
  'deliverables.deliverable-details': 'تفاصيل المخرج',
  'deliverables.planned-finish-date': 'تاريخ الاكتمال المخطط',
  'deliverables.success-measure': 'مقياس نجاح المخرج',
  'deliverables.target': 'الهدف',
  'deliverables.completion-percentage': 'نسبة الإكتمال',
  'deliverables.actual-finish-date': 'تاريخ الاكتمال الفعلي',
  'deliverables.add-new-deliverable': 'إضافة مخرج جديد',
  'milestones.name': 'اسم المعلم',
  'milestones.new-name': 'اسم المعلم الجديد',
  'milestones.planned-finish-date': 'تاريخ اكتمال المخطط',
  'milestones.weighted-completed-work': 'الوزن',
  'milestones.completed-work': 'الإنجاز',
  'milestones.milestones-details': 'تفاصيل المخطط',
  'milestones.toggle-mode': 'تشغيل خاصية التعديل',
  'milestones.total': 'المجموع',
  'dashboard.filter-by-entity': 'تصفية حسب المحافظ',
  'dashboard.filter-by-program': 'تصفية حسب البرنامج',
  'dashboard.project-count-by-status': 'عدد المشاريع حسب تصنيف الحالات',
  'dashboard.project-count-by-phase': 'عدد المشاريع حسب مراحل المشروع',
  'programs.total-project': 'عدد المشاريع',
  'programs.portfolio-owner': 'مدير المحفظة',
  'programs.program-owner': 'مدير البرنامج',
  'programs.completion': 'تقدم',
  'programs.risks': 'المخاطر',
  'programs.total-budget': 'الميزانية الإجمالية',
  'programs.issues': 'المشاكل',
  'programs.risk-and-issues-report': 'تقرير المشاكل و المخاطر',
  'programs.program-details-report': 'تقرير تفاصيل البرنامج',
  'programs.portfolio-details-report': 'تقرير تفاصيل المحفظة',
  'project-preview.project-details-report': 'تقرير تفاصيل المشروع',
  'project.tab.available': 'المشاريع المتاحة',
  'project.tap.critical': 'مشاريع حرجة',
  'project.tap.critical.pms': 'مشاريع PMs',
  'program.tap.start-date': 'تاريخ البدء',
  'program.tap.finished-date': 'تاريخ الانتهاء',
  'program.tap.manager': 'مدير',
  'datacard.startdate': 'تاريخ البدء',
  'processes.name': 'الاسم',
  'datacard.finished-date': 'تاريخ الانتهاء',
  'datacard.last-published': 'آخر نشر',
  'datacard.budget': 'الميزانية الإجمالية',
  'datacard.allocated': 'الميزانية المخصصة',
  'datacard.completion': 'إتمام',
  'datacard.actual-percentage': 'النسبة المئوية الفعلية',
  'datacard.planned-percentage': 'النسبة المئوية المخططة',
  'datacard.baseline-percentage': 'نسبة خط الأساس',
  'dashboard.resources-shortage': 'نقص الموارد',
  'project.details-preview': 'عرض',
  'project.details-manager': 'مدير',
  'project.details-phone': 'رقم الهاتف',
  'project.details-extention': 'إمتداد',
  'project.details-email': 'البريد الآلكتروني',
  'project.details-project-description': 'تفاصيل المشروع',
  'project.details-sponsor': 'راعي',
  'project.details-owner': 'مالك',
  'project.details-project-phase': 'مرحلة المشروع',
  'progressmain.update-progress': 'تقدم التحديث',
  'progressmain.edit-label': 'تعديل',
  'progressmain.history-label': 'سجل',
  'progressmain.see-more-label': 'مشاهدة المزيد',
  'progressmain.progress-label': 'التقدم / الأنشطة المنجزة من الأسبوع السابق',
  'progressmain.planned-label': 'الأنشطة المخططة للأسبوع التالي',
  'progressmain.edit-view-label': 'تحديث مشروع التقدم / تحرير',
  'progressmain.edit-view-save-label': 'حفظ',
  'project-tasks.title': 'المهام',
  'project-tasks.save-button-label': 'حفظ',
  'project-tasks.complete-label': 'اكتمال',
  'project-tasks.to-label': 'الى',
  'project-issues.issue': 'مشكلة',
  'project-issues.title': 'المشاكل',
  'project-issues.add-label': 'اضافة مشكلة جديدة',
  'project-issues-details.title': 'تفاصيل المشكلة',
  'project-issues-details.edit-label': 'تعديل',
  'project-issues-details.close-issue-label': 'اغلاق المشكلة',
  'project-issues-details.description-label': 'وصف المشكلة',
  'project-issues-details.category-label': 'الفئة',
  'project-issues-details.added-by-label': 'أضيفت بواسطة',
  'project-issues.issue-log-report': 'تقرير سجل المشاكل',
  'project-issues.issue-info-report': 'تقرير معلومات المعوق',
  'project-issues-details.impact-label': 'التأثير',
  'project-issues-details.probability-label': 'الاحتمالية',
  'project-issues-details.response-plan-label': 'خطة الاستجابة',
  'project-issues-details.resolution-plan-label': 'خطة تقليل الأثر',
  'project-issues-details.due-date-label': 'تاريخ الاستحقاق',
  'project-issues-details.responsible-label': 'المسؤول',
  'project-issues-add.title': 'اضافة مشكلة جديدة',
  'project-issues-add.issue-details-label': 'تفاصيل المشكلة',
  'project-issues-add.title-field-label': 'العنوان',
  'project-issues-add.detail-field-label': 'التفاصيل',
  'project-issues-add.category-field-label': 'الفئة',
  'project-issues-add.inp-field-label': 'الاحتمالية والتأثير',
  'project-issues-add.issue-solution-label': 'حل المشكلة',
  'project-issues-add.attachments-label': 'المرفقات',
  'project-issues-add.mitigation-plan-label': 'خطة التخفيف',
  'project-issues-add.due-date-field-label': 'تاريخ الاستحقاق',
  'project-issues-add.assigned-to': 'المدير',
  'project-issues-add.admin-field-label': 'المدير',
  'project-issues-add.save-btn-label': 'حفظ',
  'project-issues-add.update-btn-label': 'تعديل',
  'project-issues-add.high-risk-label': 'مخاطرة عالية',
  'project-issues-add.title-field-placeholder': 'الرجاء إضافة عنوان المشكلة هنا',
  'project-issues-add.detail-field-placeholder': 'الرجاء إضافة تفاصيل المشكلة هنا',
  'project-issues-add.mitigation-plan-placeholder': 'الرجاء وصف خطة التخفيف هنا',
  'project-issues-add.resolution-plan-placeholder': 'الرجاء وصف خطة التخفيف هنا',
  'project-issues-add.resolution-plan-label': 'خطة تقليل الأثر',
  'project-issues.priority': 'الأفضلية',
  'project-issues.impact': 'التأثير',
  'projectdata.preview': 'ملخص',
  'projectdata.progress': 'آكتمال',
  'projectdata.risks': 'المخاطر',
  'projectdata.issues': 'المشاكل',
  'projectdata.tasks': 'المهام',
  'projectdata.docsLibrary': 'مكتبة المستندات',
  'projectdata.milestones': 'المعالم',
  'projectrisk.add': 'إضافة مخاطر جديدة',
  'projectrisk.save': 'إضافة',
  'projectrisk.risk-details': 'تفاصيل المخاطر',
  'projectrisk.risk-log-report': 'تقرير سجل المخاطر',
  'projectrisk.risk-info-report': 'تقرير معلومات الخطر',
  'projectrisk.title': 'عنوان',
  'common.description': 'تفاصيل',
  'common.percentage': 'النسبة',
  'projectrisk.category': 'الفئة',
  'projectrisk.impact-probability': 'التأثير والاحتمالية',
  'projectrisk.risk-solution': 'حل المخاطر',
  'projectrisk.mitigation-plan': 'خطة التخفيف',
  'projectrisk.due-date': 'تاريخ الاستحقاق',
  'projectrisk.administrator': 'المالك',
  'attachment-field.drop-files': 'إسقاط الملفات هنا أو انقر لتحميل',
  'attachment-field.limit-files': 'تحميل 10 ملفات كحد اقصى',
  'projectrisk.title-field-placeholder': 'الرجاء إضافة عنوان الخطر هنا',
  'projectrisk.detail-field-placeholder': 'الرجاء إضافة تفاصيل الخطر هنا',
  'projectrisk.mitigation-plan-placeholder': 'الرجاء وصف خطة التخفيف هنا',
  'projectrisk.assign-to': 'يسند إلى',
  'projectrisk.risk': 'الخطورة',
  'common.more': 'المزيد',
  'projectrisks-main.risks': 'المخاطر',
  'projectrisks-main.add-new': 'إضافة مخاطر جديدة',
  'projectrisks-details.risk-details': 'تفاصيل المخاطر',
  'projectrisks-details.close-risks': 'أغلق هذا الخطر',
  'projectrisks-details.convert-issue': 'تحويل إلى عائق',
  'projectrisks-details.edit': 'تعديل',
  'projectrisks-details.description': 'التفاصيل',
  'projectrisks-details.category': 'الفئة',
  'projectrisks-details.added-by': 'أضيفت بواسطة',
  'projectrisks-details.impact': 'التأثير',
  'projectrisks-details.probability': 'الاحتمالية',
  'projectrisks-details.mitigation-plan': 'خطة التخفيف',
  'projectrisks-details.due-date': 'تاريخ الاستحقاق',
  'projectrisks-details.responsible': 'مسؤول',
  'projectrisk.attachments': 'المرفقات',
  'project-details-bread-title': 'تفاصيل المشروع',
  'program-details-bread-title': 'تفاصيل البرنامج',
  'portfolio-details-bread-title': 'تفاصيل المحفظة',
  'common.administrator': 'المدير',
  'common.export': 'تصدير',
  'common.details': 'التفاصيل',
  'common.home': 'الرئيسية',
  'common.SAR': 'ريال سعودي',
  'common.SARShort': 'ريال',
  'common.next': 'التالي',
  'common.previous': 'السابق',
  'common.Entity': 'الجهة',
  'common.fillRequiredFields': 'الرجاء تعبئة جميع الحقول المطلوبة',
  'common.changesHaveBeenSaved': 'تم حفظ التعديلات بنجاح!',
  'common.itemHasBeenDeleted': 'تم حذف العنصر بنجاح!',
  'common.errorSavingChanges': 'تعذر حفظ التعديلات الأخيرة لخطأ ما!',
  'common.errorFetchingData': 'حدث خطأ أثناء جلب البيانات!',
  'common.wrongCredentials': 'لقد قمت بإدخال بيانات خاطئة',
  'common.new': 'جديد',
  'common.title': 'العنوان',
  'common.projectTitle': 'اسم المشروع',
  'common.projectTitleAr': 'اسم المشروع بالعربي',
  'common.startDate': 'تاريخ البداية',
  'common.finishDate': 'تاريخ الإنتهاء',
  'common.expectedDate': 'الوقت المتوقع للإنجاز',
  'common.assignedTo': 'المنفذ',
  'common.completion': 'نسبة الإنجاز',
  'common.request-type': 'نوع الطلب',
  'common.creation-date': 'تاريخ الإنشاء',
  'common.created-by': 'المُنشىء',
  'common.status': 'الحالة',
  'common.attachments': 'المرفقات',
  'common.dueDate': 'تاريخ الاستحقاق',
  'common.save': 'حفظ',
  'common.projectManager': 'مدير المشروع',
  'common.statusManager': 'مدير الحالة',
  'common.cancel': 'إلغاء',
  'common.view-risk': 'عرض المخاطرة',
  'common.view-issue': 'عرض المشكلة',
  'common.looksLikeThereIsNoDataHere': 'يبدو أنه لا يوجد بيانات متاحة هنا',
  'common.consumed': 'تم صرف',
  'common.outOf': 'من',
  'common.liquidity': 'السيولة',
  'common.back': 'رجوع',
  'common.edit': 'تعديل',
  'common.delete': 'حذف',
  'common.support-documents': 'الوثائق الداعمة',
  'common.all': 'الكل',
  'common.entities': 'الجهات',
  'common.programs': 'البرامج',
  'common.projects': 'المشاريع',
  'common.project': 'المشروع',
  'common.totalBudget': 'مجموع الميزانية',
  'common.total': 'مجموع',
  'common.risks': 'المخاطر',
  'common.issues': 'المشاكل',
  'common.summary-info': 'ملخص المعلومات',
  'common.type-a-message': 'اكتب رسالتك',
  'common.select-project': 'اختر المشروع',
  'common.actual': 'الحقيقي',
  'common.baseline': 'خط الأساس',
  'common.planned': 'المخطط له',
  'common.other': 'أخرى',
  'common.creation-time': 'وقت الإنشاء',
  'common.fetch-failed-error': 'فشل في جلب البيانات',
  'common.approve-success': 'تم القبول بنجاح',
  'common.reject-success': 'تم الرفض بنجاح',
  'common.approve-error': 'فشل في القبول',
  'common.reject-error': 'فشل في الرفض',
  'common.notes': 'ملاحظات',
  'common.reports': 'التقارير',
  'common.user': 'المستخدم',
  'dashboard.program-manager': 'مدير البرنامج',
  'common.program-leader': 'قائد البرنامج',
  'common.classification': 'التصنيف',
  'common.priority': 'الأهمية',
  'common.current-month-utilization': 'استخدام الموارد للشهر الحالي ',
  'common.capacity-planner': 'مخطط السعة',
  'common.projectcoordinator': 'منسق المشروع',
  'common.capacity-planner-dashboard': 'لوجة قيادة مخطط السعه - التاريخ الحالي',
  'common.No-of-Active-Resourses': 'عدد الموارد النشطة',
  'common.ResourcesRequried': 'الموارد المطلوبة',
  'common.AVGEndMonth': 'متوسط الاستخدام (حتى الشهر الحالي)',
  'common.AVGEndYear': 'متوسط الاستخدام (نهاية السنه)',
  'common.UtilizedMostRole': 'الدور الأكثر استخدامًا',
  'common.UtilizedLeastRole': 'الدور الأقل استخدامًا',
  'common.UtilizedMostResource': 'أكثر الموارد المستخدمة',
  'common.UtilizedLeastResource': 'الموارد الأقل استخدامًا',
  'common.UtilizationPerRole': 'الاستخدام لكل دور',
  'common.ResourcesUtilization': 'استخدام الموارد',
  'common.ResourcesUtilizationDate': 'استغلال الفريق خلال شهر',
  'common.team-members': 'أعضاء الفريق',
  'common.utilization': 'نسبة الاستغلال',
  'common.contact-details': 'معلومات الاتصال',
  'common.projects-tasks-count': 'عدد مهام المشاريع',
  'common.other-tasks-count': 'عدد المهام الاخرى',
  'common.task': 'المهمه',
  'common.other-tasks': 'مشاريع اخرى',
  'common.current-month': 'الشهر الحالي',
  'common.man-power-capacity': 'قدرة القوى العاملة',
  'common.not-utilized': 'غير مستغله',
  'common.over-utilized': 'فوق المستغل',
  'common.utilized': 'المستغل',
  'common.under-utilized': 'تحت المستغل',
  'common.No.of-FTE-Needed': 'No. of FTE Needed',
  'common.booked-mandays': 'حجزت عدة أيام',
  'common.available-mandays': 'أيام العمل المتاحة',
  'common.current': 'الحالي',
  'task.taskActualCompletion': 'الإنجاز الحقيقي للمهمة',
  'task.lastChangeStatus': 'حالة آخر تغيير',
  'task.delayDuration': 'مقدار التأخير',
  'statuses.low': 'منخفض',
  'statuses.medium': 'متوسط',
  'statuses.high': 'عالي',
  'Portfolios.Portfolios': 'المحافظ',
  'portfolio.workspace-permission': 'لا توجد لديك صلاحية للوصول الى مساحة العمل',
  'Programs.Programs': 'البرامج',
  'tasksCenter.approvals': 'الموافقات',
  'tasksCenter.project-tasks': 'مهام المشاريع',
  'tasksCenter.my-tasks': 'مهامي',
  'tasksCenter.my-requests': 'طلباتي',
  'taskCenter.projects-tasks-approval': 'موافقات مهام المشاريع',
  'taskCenter.tasks-approvals': 'موافقات المهام',
  'taskCenter.PMO-processes': 'PMO عمليات',
  'taskCenter.my-pmo-processes': 'عمليات PMO الخاصة بي',
  'taskCenter.my-requests': 'طلباتي',
  'taskCenter.requested-tasks': 'المهام المطلوبة',
  'taskCenter.add-new-task': 'إضافة مهمة جديدة',
  'taskCenter.title-in-english': 'العنوان بالانجليزي',
  'taskCenter.title-in-arabic': 'العنوان بالعربي',
  'program.deliverables': 'التسليمات',
  'program.milestones': 'المعالم',
  'program.adaa': 'أداء',
  'program.risksAndIssues': 'المخاطر والمشاكل',
  'program.allocated': 'مخصصة',
  'program.allocatedToProjects': 'مخصصة للمشاريع',
  'projectdata.deliverables': 'التسليمات',
  'projectdata.liquidity': 'السيولة',
  'projectdata.processes': 'العمليات',
  'projectliquidity-main.liquidity': 'السيولة',
  'projectliquidity-main.add-new': 'إضافة سيولة جديدة',
  'projectliquidity.year': 'السنة',
  'projectliquidity.amount': 'القيمة',
  'projectliquidity.spent': 'المنصرف',
  'projectliquidity.add': 'إضافة سيولة جديدة',
  'projectliquidity.year-field-placeholder': 'الرجاء إضافة السنة',
  'projectliquidity.amount-field-placeholder': 'الرجاء إضافة القيمة',
  'projectliquidity.spent-field-placeholder': 'الرجاء إضافة المنصرف',
  'milestones.must-upload-an-attachment': 'يجرب إرفاق مستند واحد على الأقل!',
  'liquidity.please-enter-valid-amounts-for-liquidity': 'الرجاء إدخال قيم صالحة للسيولة',
  'common.phases': 'المراحل',
  'common.goToWorkSpace': 'ذهاب لمنصة العمل',
  'progressmain.no-planned': 'لا يوجد أنشطة مخططة',
  'progressmain.no-progress': 'لا يوجد أنشطة منجزة',
  'emptyComponent.title': 'لا يوجد لديك {title}',
  'emptyComponent.program': 'اي برنامج في هذه المحفظة',
  'common.toDate': 'الى',
  'dashboard.noProjects': 'لا توجد مشاريع',
  priority: 'لوحة الأولوية',
  'pdb.high': 'عالي',
  'pdb.low': 'منخفض',
  'pdb.sooner': 'عاجل',
  'pdb.later': 'لاحق',
  'pdb.impact': 'التأثير',
  'pdb.urgency': 'الاستعجال',
  'pdb.projects': 'المشاريع',
  'pdb.manager': 'المدير',
  'pdb.budget': 'الميزانية',
  'pdb.progress': 'التقدم',
  'common.comment': 'تعليق',
  'common.done': 'حفظ',
  'common.Add-comment': 'إضافة تعليق',
  'common.next-phase': 'المرحلة القادمة',
  'common.check-list': 'القائمه',
  'common.add': 'إضافة',
  'Phasesgatereview.title': 'بوابه المراحل',
  'createProject.title': 'إضافة مشروع جديد',
  'common.project-description': 'تفاصيل المشروع',
  'common.departments': 'الاقسام',
  'common.submit': 'إرسال',
  'common.resubmit': 'إعادة التقديم',
  'common.currentProjectPhase': 'المرحلة الحالية للمشروع',
  'common.projectSponser': 'راعي المشروع',
  'common.projectOwner': 'مالك المشروع',
  'common.projectBudget': 'ميزانية المشروع',
  'common.portfolio': 'المحفظة',
  'common.program': 'البرنامج',
  'common.pending': 'قيد الانتظار',
  'common.approve': 'قبول',
  'common.reject': 'رفض',
  'taskCenter.phase-approvals': 'موافقات البوابات المرحلية',
  'common.not-available': 'غير متاح',
  'taskCenter.new-projects': 'مشاريع جديدة',
  'common.projectPreview': 'عرض المشروع',
  'common.language': 'English',
  'common.dashboard': 'لوحة القيادة',
  'common.saveAsDraft': 'حفظ كمسودة',
  'common.rejectModal': 'هل انت متأكد ؟',
  'toast.success': 'نجاح',
  'toast.error': 'خطأ',
  'common.programTitle': 'اسم البرنامج',
  'common.approveStatus': 'تمت الموافقه بنجاح!',
  'common.rejectStatus': 'تم الرفض بنجاح!',
  'project-details.escalation': 'التصعيد',
  'project-details.escalation-up': 'التصعيد لأعلى',
  'project-details.escalation-date': 'الوقت',
  'project-details.escalated-by': 'تصعيد بواسطة',
  'project-details.escalated.status': 'الحالة',
  'project-details.escalated.spport': 'الدعم المطلوب / التفاصيل',
  'project-details.escalated.spport-placeholder': 'يرجى وصف الدعم المطلوب / التفاصيل ',
  'common.type-a-comment': 'اكتب التعليق ...',
  'project-details.close.escaltion': 'إغلاق التصعيد',
  'project-details.escalated.actions': 'سجل العمل',
  'status-closed': 'مغلق',
  'status-Open': 'قيد التنفيذ',
  'status-not-started': 'لم تبدء',
  'common.read-more': 'اقرأ المزيد',
  'project-details.action-log': 'التصعيد / سجل العمل',
  'common.escalated-by': 'تصعيد بواسطة',
  'common.select': 'إختر...',
  'common.interdependencies': 'الإرتباطات',
  'common.create-new': 'إضافة جديد',
  'common.owner': 'المالك',
  'common.due-date': 'تاريخ الاستحقاق',
  'id.affected-project': 'المشروع المتأثر',
  'id.impact-project': 'المشروع المؤثر',
  'id.affecting-project': 'المشروع المؤثر',
  'common.number': 'رقم',
  'id.affecting-task': 'المهمة المؤثرة',
  'id.affected-task': 'المهمة المتأثرة',
  'id.details': 'تفاصيل الإرتباط',
  'common.category': 'الفئة',
  'id.log': 'سجل',
  'id.relationship': 'العلاقة',
  'id.close-dependency': 'إغلاق الإرتباط',
  'common.earn': 'القيمة المكتسبة',
  'project-details.transfer-to-issue': 'تحويل إلى معيقة',
  'processes.change-request': 'طلب تغيير',
  'processes.project-charter': 'ميثاق المشروع',
  'processes.change-details': 'تفاصيل التغيير',
  'processes.change-details-placeholder':
    'يشرح هذا القسم الآلية المستخدمة لتحديد أي أطراف ثالثة قد تحدث أثناء المشروع، مثل جميع التغييرات والأسباب والاحتياجات يجب أن تكون معروفة',
  'processes.change-request-impact': 'التأثير',
  'processes.change-request-scope': 'النطاق',
  'processes.change-request-cost': 'التكلفة',
  'processes.change-request-duration': 'المدة',
  'processes.change-request-factor': 'العامل',
  'processes.change-request-description': 'الوصف',
  'processes.change-request-description-placeholder': 'كتابة الوصف',
  'processes.change-reasons': 'أسباب التغيير',
  'processes.change-effect': 'وصف الأثر',
  'processes.change-reasons-placeholder':
    'في حالة حدوث أي تغيير في المشروع، يجب معرفة خذا التغيير وتحديد تأثيره على جميع مجالات المشروع، سواء كانت متأثرة بالمشروع. من خلال فحص كل هذه الآثار وتوثيقها أو طلب التغيير، يجب تحديد ما إذا كان هذا التغيير سيؤثر على الحط الأساسي أم لا',
  'processes.save-success': 'تم حفظ المسودة بنجاح',
  'processes.submit-success': 'تم ارسال الطلب بنجاح',
  'processes.save-error': 'فشل في الحفظ',
  'processes.send-success': 'تم الإرسال بنجاح',
  'processes.send-failed': 'فشل في الإرسال',
  'process.history': 'سجل العمليات',
  'processes.deliverables-fetch-failed': 'فشل في جلب بيانات المخرجات',
  'project-processes.project-number': 'رقم المشروع',
  'project-processes.project-name': 'اسم المشروع',
  'project-processes.project-owner': 'مالك المشروع',
  'project-processes.start-date': 'تاريخ البداية',
  'project-processes.finish-date': 'تاريخ الانتهاء',
  'project-processes.request-number': 'رقم الطلب',
  'project-processes.request-date': 'تاريخ الطلب',
  'project-processes.applicant': 'مقدم الطلب',
  'change-request.english-title': 'العنوان انجليزي',
  'change-request.arabic-title': 'العنوان بالعربي',
  // Reports
  'reports.list': 'قائمة التقارير',
  'reports.project': 'Summary Project Report',
  'reports.program': 'Summary Program Report',
  'reports.risk': 'Risk Status Report',
  'reports.issue': 'Issue Status Report',
  'reports.financial': 'التقرير المالي',
  'reports.Audit_Trail': 'Audit & Trails Report',
  'reports.details': 'تفاصيل التقرير',
  'reports.download-pdf': 'تحميل بصيغة PDF',
  'reports.download-word': 'تحميل بصيغة Word',
  'reports.download-excel': 'تحميل بصيغة Excel',
  'processes.project-closure': 'إغلاق المشروع',
  'common.lessons-learned': 'الدروس المستفادة',
  'common.end-project': 'إنهاء المشروع',
  'common.evaluation': 'التقييم',
  'common.vendor': 'المقاول',
  'common.team-performance': 'أداء فريق العمل',
  'process.deliverable-name': 'اسم المخرج',
  'common.deliveryDate': 'تاريخ التسليم',
  'process.deliverable-reviwer': 'اصحاب العلاقه المشاركون في مراجعة المخرج',
  'processes.deliverables-acceptance': 'قبول المخرجات',
  'processes.deliverables': 'المخرجات',
  'processes.deliverables-acceptance-deliverable': 'المخرج',
  'processes.create-process': 'إنشاء عملية',
  'common.progress': 'نسبة الإنجاز الفعلي',
  'common.responsible': 'المسؤول',
  'dashboard.projects': 'المشاريع',
  'dashboard.initiatives-count-by-status': 'عدد المشاريع حسب تصنيف الحالات',
  'common.programs-dashboard': 'لوحة القيادة',
  'dashboard.financial-info': 'المعلومات المالية',
  'dashboard.project-name': 'اسم المشروع',
  'common.phase': 'المرحلة',
  'dashboard.announced': 'معلنة ؟',
  'common.yes': 'نعم',
  'common.no': 'لا',
  'dashboard.spent': 'المنصرف',
  'dashboard.progress': 'نسبة التقدم',
  'common.type': 'النوع',
  'common.level-name': 'اسم المستوى',
  'dashboard.torchbearer': 'حامل المشعل',
  'common.apply': 'تطبيق',
  'dashboard.data-categories': 'تصنيف البيانات',
  'common.remaining-budget': 'الميزانية المتبقية',
  'common.dashboards': 'لوحات القيادة',
  'dashboard.projects-by-phase': 'توزيع المشاريع حسب المرحلة',
  'dashboard.initiative-progress': 'نسبة إنجاز البرنامج',
  'dashboard.actual': 'الفعلي',
  'dashboard.initiative-status': 'حالة البرنامج',
  'dashboard.initiative-info': 'معلومات البرنامج',
  'dashboard.issues-risks': 'المخاطر والمشاكل',
  'dashboard.initiative-details': 'تفاصيل البرنامج',
  'dashboard.progress-summary': 'ملخص الإنجازات',
  'dashboard.projects-by-status': 'توزيع المشاريع حسب الحالة',
  'dashboard.projects-list': 'قائمة المشاريع',
  'dashboard.program-name': 'اسم المحفظه',
  'dashboard.project-info': 'معلومات المشروع',
  'dashboard.project-actual-progress': 'نسبة إنجاز المشروع الفعلي',
  'dashboard.initiative-owner': 'اسم قائد البرنامج',
  'dashboard.launch-date': 'تاريخ الانطلاق',
  'dashboard.project-planned-progress': 'نسبة إنجاز المشروع المخطط له',
  'dashboard.planned': 'المخطط',
  'dashboard.project-status': 'حالة المشروع',
  'dashboard.contracts-value': 'قيمة العقود',
  'dashboard.remaining': 'المتبقي',
  'dashboard.achievement-report': 'تقرير الإنجاز',
  'dashboard.achieved-works': 'الأعمال المنجزة في الفترة السابقة',
  'dashboard.expected-achieved-works': 'الأعمال المتوقع إنجازها في الفترة القادمة',
  'dashboard.project-details': 'تفاصيل المشروع',
  'project-closure.deliverables-hint': 'جميع المخرجات يجب أن تكون نسبة الإنجاز الفعلي لها %100 وان تكون مكتملة',
  'stage-gate-dashboard.project-by-phase-gate': 'توزيع المشاريع حسب بوابة المراحل',
  'stage-gate-dashboard.project-by-checklist': 'توزيع المشاريع المعلقة حسب قائمة المراجعة',
  'stage-gate-dashboard.holded-phases-title': '{projectName} - قائمة المراجعة المعلقة - مرحلة {phaseTitle}',
  'common.project-stage-gate-dashboard': 'لوحة قيادة مراحل المشاريع',
  'common.day': '{count, plural, =0 {{count} يوم} one {{count} يوم} other{{count} أيام}}',
  'common.project-name': 'اسم المشروع',
  'project-financial.spentAmount': 'المنصرف',
  'project-financial.spentDate': 'التاريخ',
  'project-financial.add-new': 'اضافة'

};
