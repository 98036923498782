import React from 'react';

function Loader() {
  return (
    <div className="dashboard-program-header animated-background">
      <span className="title shadow-background" />
      <span className="title phase shadow-background" />
    </div>
  );
}

export default Loader;
