/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import ReduxThunk from 'redux-thunk';
import { connect, Provider as ProviderRedux } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { createStore, applyMiddleware, compose } from 'redux';
// import { ThemeProvider } from 'emotion-theming';
// import { theme } from 'mt-design/lib';

import reducers from './reducers';
import Layout from './Layout';
import ReduxIntlProvider from './components/ReduxProviders';
import ToastContainer from './components/ToastContainer';
import Login from './containers/Views/LoginPage';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import ScrollToTop from './components/ScrollToTop';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'theme', 'language'],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const composeSetup =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(persistedReducer, undefined, composeSetup(applyMiddleware(ReduxThunk)));
const persistor = persistStore(store);

let PrivateRoute = () => {
  return (
    <Switch>
      render=
      {localStorage.getItem('token') ? (
        <Switch>
          <Route path="/" name="Home" component={Layout} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/login" name="Login Page" render={() => <Login />} />
          <Redirect to="/login" />
        </Switch>
      )}
    </Switch>
  );
};
const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
PrivateRoute = connect(mapStateToProps, null)(PrivateRoute);

ReactDOM.render(
  <ProviderRedux store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ReduxIntlProvider>
        <HashRouter>
          <ScrollToTop />
          <PrivateRoute />
          <ToastContainer />
        </HashRouter>
      </ReduxIntlProvider>
    </PersistGate>
  </ProviderRedux>,
  document.getElementById('root'),
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
