import React from 'react';

import './loader.scss';

function Loader() {
  return (
    <div className="initiative-details__info initiative-details__info-loader animated-background">
      <div className="row">
        <div className="details-section">
          <span className="title text shadow-background title--loading" />
          <span className="content text shadow-background content--loading" />
        </div>
        <div className="details-section">
          <span className="title text shadow-background title--loading" />
          <span className="content text shadow-background content--loading" />
        </div>
      </div>
      <div className="row footer">
        <div className="footer-item shadow-background">
          <span className="label shadow-background" />
          <span className="value shadow-background" />
        </div>
        <div className="footer-item shadow-background">
          <span className="label shadow-background" />
          <span className="value shadow-background" />
        </div>
        <div className="footer-item shadow-background">
          <span className="label shadow-background" />
          <span className="value shadow-background" />
        </div>
        <div className="footer-item shadow-background">
          <span className="label shadow-background" />
          <span className="value shadow-background" />
        </div>
        <div className="footer-item shadow-background">
          <span className="label shadow-background" />
          <span className="value shadow-background" />
        </div>
      </div>
    </div>
  );
}

export default Loader;
