import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AppsStoreIcon from '../../../assets/images/appstore1x.png';
import GooglePlayIcon from '../../../assets/images/googlepaly1.png';
import UsersAPIController from '../../../providers/controllers/UsersAPIController';
import { appStoreURL, playStoreURL, logoURL } from '../../../providers/config/urlServiceConfig';
import { addToast } from '../../../Helpers';
import './style.scss';

const randomID = Math.random()
  .toString(36)
  .substr(2, 9);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      invalidusername: false,
      invalidPassword: false,
      isSecondStep: false,
      validationCode: '',
    };
  }

  componentDidMount() {
    this.getHandShake();
    const { dispatch, user } = this.props;
    dispatch({ type: 'TOGGLE_LANGUAGE_SETTINGS', payload: {} });

    if (!user.DeviceID) {
      dispatch({ type: 'DEVICE_ID', payload: randomID });
    }
  }

  getHandShake() {
    new UsersAPIController().getHandshake().then(res => {
      if (res) {
        if (res.StatusCode === 'Success') {
          const { dispatch } = this.props;
          dispatch(getHandshake => {
            getHandshake({
              type: 'HANDSHAKE_ASYNC',
              payload: res.Data,
            });
          });
        }
      }
    });
  }

  handleLogin = e => {
    e.preventDefault();
    if (this.handleValidation()) {
      const { dispatch } = this.props;

      const { username, password } = this.state;
      const user = {
        data: {
          Username: username,
          Password: password,
          DeviceID: '',
          DeviceName: '',
          DeviceType: 'Web',
        },
      };
      new UsersAPIController()
        .login(user)
        .then(res => {
          if (res.StatusCode.toLowerCase() === 'success') {
            const { UserToken, User, IsTwoFactorEnabled } = res.Data;

            if (IsTwoFactorEnabled) {
              this.setState({ isSecondStep: true });
            } else {
              dispatch(loginUser => {
                localStorage.setItem('token', UserToken);
                localStorage.setItem('user', JSON.stringify(User));
                const { user: userData } = this.props;
                new UsersAPIController()
                  .postDeviceID({
                    userDevice: {
                      Token: '',
                      Value: userData.DeviceID,
                      User: {
                        Username: User.Username,
                      },
                    },
                  })
                  .then(response => {
                    if (response.StatusCode === 'Success') {
                      loginUser({
                        type: 'LOGIN',
                        payload: {
                          user: User,
                        },
                      });
                    } else {
                      addToast.error(response.StatusMessage || 'error', { toastId: 'login-toast' });
                    }
                  });
              });
            }
          } else {
            addToast.error(res.StatusMessage || 'error', { toastId: 'login-toast' });
          }
        })
        .catch(() => {
          addToast.error('common.wrongCredentials', { toastId: 'login-toast' });
        });
    }
  };

  handleCode = e => {
    e.preventDefault();
    const { validationCode } = this.state;
    if (validationCode) {
      const { dispatch } = this.props;

      const { username } = this.state;
      const body = { data: { Username: username, SMSAuthCode: validationCode } };
      new UsersAPIController()
        .validateCode(body)
        .then(res => {
          if (res.StatusCode.toLowerCase() === 'success') {
            const { UserToken, User } = res.Data;

            dispatch(loginUser => {
              localStorage.setItem('token', UserToken);
              localStorage.setItem('user', JSON.stringify(User));
              const { user: userData } = this.props;
              new UsersAPIController()
                .postDeviceID({
                  userDevice: {
                    Token: '',
                    Value: userData.DeviceID,
                    User: {
                      Username: User.Username,
                    },
                  },
                })
                .then(response => {
                  if (response.StatusCode === 'Success') {
                    loginUser({
                      type: 'LOGIN',
                      payload: {
                        user: User,
                      },
                    });
                  } else {
                    addToast.error(response.StatusMessage || 'error', { toastId: 'login-toast' });
                  }
                });
            });
          }
          if (res.StatusMessage === 'Code Is Expired') {
            this.setState({ isSecondStep: false });
            addToast.error(res.StatusMessage || 'error', { toastId: res.StatusMessage });
          } else if (res.StatusMessage === 'Invalid Code') {
            addToast.error(res.StatusMessage || 'error', { toastId: res.StatusMessage });
          }
        })
        .catch(() => {
          addToast.error('common.wrongCredentials', { toastId: 'login-toast' });
        });
    }
  };

  handleValidation() {
    this.validation();
    const { username, password } = this.state;
    if (username === '') {
      return false;
    }
    return password !== '';
  }

  validation() {
    const { username, password } = this.state;
    username === ''
      ? this.setState({
          invalidusername: true,
        })
      : this.setState({ invalidusername: false });
    password === ''
      ? this.setState({
          invalidPassword: true,
        })
      : this.setState({ invalidPassword: false });
  }

  render() {
    const { theme } = this.props;
    const { invalidPassword, invalidusername, isSecondStep } = this.state;
    return (
      <div>
        <div className="login-container">
          <div className="right-side">
            <div className="form">
              {isSecondStep ? (
                <form className="login-form validation-form" onSubmit={this.handleCode}>
                  <div className="text-center login-header">
                    <img alt="logo" src={logoURL} style={{ maxWidth: window.env.BIG_LOGO ? '50%' : '100px' }} />
                    <h5 className="content-group-lg text-black">
                      <div style={{ color: theme.ThemeColor.item.Text }} className="txt_sign">
                        Login Account
                      </div>
                      <p className="display-block sec-text">Please login to your account</p>
                    </h5>
                  </div>
                  <input
                    type="text"
                    placeholder="Validation Code"
                    id="code"
                    key="code"
                    className={invalidusername ? 'invalid' : ''}
                    title={invalidusername ? 'Username required' : ''}
                    onChange={event => {
                      this.setState({
                        validationCode: event.target.value,
                      });
                      event.target.value === ''
                        ? this.setState({
                            invalidusername: true,
                          })
                        : this.setState({
                            invalidusername: false,
                          });
                    }}
                  />
                  <button style={{ backgroundColor: theme.ThemeColor.item.Text }} type="submit">
                    Validate
                  </button>
                </form>
              ) : (
                <form className="login-form" onSubmit={this.handleLogin}>
                  <div className="text-center login-header">
                    <img alt="logo" src={logoURL} style={{ maxWidth: window.env.BIG_LOGO ? '50%' : '100px' }} />
                    <h5 className="content-group-lg text-black">
                      <div style={{ color: theme.ThemeColor.item.Text }} className="txt_sign">
                        Login Account
                      </div>
                      <p className="display-block sec-text">Please login to your account</p>
                    </h5>
                  </div>
                  <input
                    type="text"
                    placeholder="Username"
                    // value={username}
                    className={invalidusername ? 'invalid' : ''}
                    title={invalidusername ? 'Username required' : ''}
                    onChange={event => {
                      this.setState({
                        username: event.target.value,
                      });
                      event.target.value === ''
                        ? this.setState({
                            invalidusername: true,
                          })
                        : this.setState({
                            invalidusername: false,
                          });
                    }}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    // value={password}
                    className={invalidPassword ? 'invalid' : ''}
                    title={invalidPassword ? 'Passwprd required' : ''}
                    onChange={event => {
                      this.setState({
                        password: event.target.value,
                      });
                      event.target.value === ''
                        ? this.setState({
                            invalidPassword: true,
                          })
                        : this.setState({
                            invalidPassword: false,
                          });
                    }}
                  />
                  <button style={{ backgroundColor: theme.ThemeColor.item.Text }} type="submit">
                    Login
                  </button>
                </form>
              )}
            </div>
            <div className="mobile-app-container">
              <a href={playStoreURL} target="_blank" rel="noopener noreferrer" className="google-play">
                <img srcSet={GooglePlayIcon} alt="app store" height="100" />
              </a>
              <a href={appStoreURL} target="_blank" rel="noopener noreferrer" className="app-store">
                <img srcSet={AppsStoreIcon} alt="app store" />
              </a>
            </div>
          </div>
          <div className="left-side">
            <div className="left-side-overlay" style={{ background: theme.ThemeColor.item.Text }} />
            <div className="left-side-overlay lines" />
            <div className="p_main_div">
              <div className="txt_title_login">{window.env.TITLE}</div>
              <div className="txt_sup_login">{window.env.SLOGAN}</div>
              <div className="txt_copy">
                © {new Date().getFullYear()} {window.env.COPYRIGHT || 'PPlus All Rights Reservered'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state /* , ownProps */) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Login);
