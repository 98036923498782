import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import Tabs from '../DashboardTabs';
import AchievmentReport from './AchievementReport';
import Risks from './Risks';
import Issues from './Issues';
import Deliverables from './Deliverables';

import './styles.scss';

function DashboardTabsSection({ intl, projectCode }) {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      label: intl.formatMessage({ id: 'dashboard.achievement-report' }),
    },
    {
      id: 1,
      label: intl.formatMessage({ id: 'common.risks' }),
    },
    {
      id: 2,
      label: intl.formatMessage({ id: 'common.issues' }),
    },
    // {
    //   id: 4,
    //   label: intl.formatMessage({ id: 'dashboard.deliverables' }),
    // },
  ];

  function onTabChange(newTab) {
    setActiveTab(newTab);
  }

  let content;

  switch (activeTab) {
    case 0:
      content = <AchievmentReport projectCode={projectCode} />;
      break;
    case 1:
      content = <Risks projectCode={projectCode} />;
      break;
    case 2:
      content = <Issues projectCode={projectCode} />;
      break;
    case 4:
      content = <Deliverables />;
      break;
    default:
      content = null;
  }

  return (
    <div className="dashboard-tabs-section">
      <Tabs activeTab={activeTab} onTabChange={onTabChange} tabs={tabs} />
      <div className="dashboard-tabs-section__content">{content}</div>
    </div>
  );
}

export default injectIntl(DashboardTabsSection);
