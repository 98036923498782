import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import groupBy from 'lodash-es/groupBy';
import DashboardAPIController from '../../../providers/controllers/DashboardAPIController';
import PhaseChart from '../PhasesChart';
import ReviewsChart from '../ReviewsChart';
import ProjectsList from './ProjectsList';
import { addToast } from '../../../Helpers';
import { PhaseChartLoader, BarChartLoader, ProjectsListLoader } from '../loader';
import '../style.scss';

class ProjectStageGate extends Component {
  state = {
    allInitiatives: [],
    allProjects: [],
    projectsByPhase: [],
    phasesSchema: [],
    projectsLoading: true,
    schemaLoading: true,
  };

  componentDidMount() {
    this.getProjectPhaseGateInfo();
  }

  getProjectPhaseGateInfo() {
    const { theme } = this.props;

    new DashboardAPIController()
      .getProjectPhaseGateInfo()
      .then(res => {
        const { StatusCode, Data } = res;
        if (StatusCode === 'Success') {
          const _data = Data.filter(item => item.Phases.length);
          _data.forEach(_element => {
            const element = _element;
            const currentPhase = [...element.Phases].reverse().find(phase => phase.StatusID !== 1);
            element.PhaseID = currentPhase.PhaseID;

            const _phase = theme.ProjectPhases.item.Items.find(item => item.Value === element.PhaseID.toString());
            element.PhaseName = _phase.English;
            element.PhaseNameAr = _phase.Arabic;
          });

          const projectsPhasesCount = groupBy(_data, 'PhaseID');

          const _projects = [..._data];
          const initiativesList = [];
          _projects.forEach(element => {
            const existInitiative = [...initiativesList].find(
              item => element.Program && item.Code === element.Program?.Code,
            );
            if (!existInitiative && element.Program) {
              initiativesList.push({ ...element.Program });
            }
          });

          initiativesList.map(initiative => {
            const _initiative = initiative;
            return (_initiative.Projects = _projects.filter(project => project.Program?.Code === initiative.Code));
          });

          this.setState(
            {
              allProjects: _data,
              allInitiatives: initiativesList,
              projectsLoading: false,
              projectsByPhase: projectsPhasesCount,
            },
            () => {
              this.getProjectPhaseGateSchema();
            },
          );
        }
      })
      .catch(error => {
        addToast.error(error.message, { toastId: 'get-project-phase-gate-info' });
      });
  }

  getSchemaCounts() {
    const { phasesSchema, allProjects } = this.state;
    const _projectsList = [...allProjects];
    const _phasesSchema = [...phasesSchema];

    _phasesSchema.forEach(_schemaItem => {
      const schemaItem = _schemaItem;
      _projectsList.forEach(project => {
        project.Phases.forEach(phase => {
          phase.CheckListItems.forEach(_checkItem => {
            const checkItem = _checkItem;
            if (
              !checkItem.IsChecked &&
              schemaItem.ItemID === checkItem.ItemID &&
              phase.PhaseID === schemaItem.PhaseID
            ) {
              schemaItem.Count++;
            }
          });
        });
      });
    });

    this.setState({ phasesSchema: _phasesSchema });
  }

  getProjectPhaseGateSchema() {
    new DashboardAPIController()
      .getProjectPhaseGateSchema()
      .then(res => {
        const { StatusCode, Data } = res;
        if (StatusCode === 'Success') {
          const _checkLists = [];
          Data.forEach(phase => {
            phase.CheckListItems.forEach(_checkItem => {
              const checkItem = _checkItem;
              checkItem.Count = 0;
              checkItem.PhaseID = phase.PhaseID;
              checkItem.PhaseTitle = phase.Title;
              checkItem.PhaseTitleAr = phase.TitleArabic;
              _checkLists.push(checkItem);
            });
          });
          this.setState({ phasesSchema: _checkLists, schemaLoading: false }, () => {
            this.getSchemaCounts();
          });
        }
      })
      .catch(error => {
        addToast.error(error.message, { toastId: 'get-project-phase-gate-schema-info' });
      });
  }

  render() {
    const { allInitiatives, allProjects, phasesSchema, projectsByPhase, projectsLoading, schemaLoading } = this.state;
    return (
      <div className="initiative-stage-gate-container">
        <div className="dashboard-row">
          <div className="dashboard-col" style={{ width: '420px' }}>
            {projectsLoading ? (
              <PhaseChartLoader />
            ) : (
              <>
                <div className="dashboard-col-title text-center">
                  <FormattedMessage id="stage-gate-dashboard.project-by-phase-gate" />
                </div>
                <PhaseChart data={projectsByPhase} ProjectsCount={allProjects.length} />
              </>
            )}
          </div>
          <div className="dashboard-col" style={{ flexGrow: 1, paddingInlineEnd: '8px' }}>
            {projectsLoading || schemaLoading ? (
              <BarChartLoader />
            ) : (
              <>
                <div className="dashboard-col-title">
                  <FormattedMessage id="stage-gate-dashboard.project-by-checklist" />
                </div>
                <ReviewsChart reviewsData={phasesSchema} />
              </>
            )}
          </div>
        </div>
        <div className="dashboard-row">
          <div className="dashboard-col" style={{ flexGrow: 1 }}>
            {projectsLoading ? <ProjectsListLoader /> : <ProjectsList initiativesList={allInitiatives} />}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ theme, language }) => {
  return {
    theme,
    language,
  };
};

export default connect(mapStateToProps)(ProjectStageGate);
