import React from 'react';
import { FormattedMessage } from 'react-intl';

import EntityCard from '../EntityCard';

import { reduceCustomFields, getProjectsByProgram } from '../../Helpers';

import './styles.scss';

function DashboardProgramsList({ programs = [], projects = [], code, theme, language, history }) {
  return (
    <div className="dashboard-programs-list">
      <span className="dashboard-programs-list__title">
        <FormattedMessage id="common.programs" />
      </span>
      <div className="dashboard-programs-list__programs">
        {programs.map(program => (
          <EntityCard
            key={program.Code}
            code={code}
            data={reduceCustomFields(program)}
            projects={getProjectsByProgram(projects, program)}
            theme={theme}
            language={language}
            item={program}
            history={history}
            inDashboard
            isProgram
          />
        ))}
      </div>
    </div>
  );
}

export default DashboardProgramsList;
