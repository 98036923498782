import React from 'react';

import './style.scss';

export default () => (
  <div className="taskLoader__container animated-background">
    <div className="taskLoader__status">
      <p className="shadow-background" />
    </div>
    <div className="taskLoader__content">
      <div className="taskLoader__content-section">
        <p className="taskLoader__content-title shadow-background" />
        <p className="taskLoader__content-subtitle shadow-background" />
      </div>
      <div className="taskLoader__content-section">
        <p className="taskLoader__content-title shadow-background" />
        <p className="taskLoader__content-subtitle shadow-background" />
      </div>
    </div>
    <p className="taskLoader__circle shadow-background" />
  </div>
);
