import React from 'react';
import { connect } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/email.svg';
import { ReactComponent as ExtentionIcon } from '../../assets/images/extention.svg';
import Avatar from '../Avatar';

import './styles.scss';

const UserCard = ({ user, theme }) => (
  <div className="userCard">
    <div className="user-avatar-container">
      <Avatar user={user} width={100} />
    </div>
    <div className="user-info">
      <div className="user-name bold-text">{user.DisplayName}</div>
      <div className="user-secondary-info">
        <FormattedMessage id="project.details-manager" />
      </div>
      <div className="user-contact">
        <div className="contact-item">
          <PhoneIcon className="contact-icon" width="17px" height="17px" stroke={theme.ThemeColor.item.Text} />
          <div>
            <FormattedMessage id="project.details-phone" /> : &nbsp;
            <span className="contact-item__value">{user.Phone ? user.Phone : '---'}</span>
          </div>
        </div>
        <div className="contact-item">
          <ExtentionIcon className="contact-icon" width="17px" height="17px" stroke={theme.ThemeColor.item.Text} />
          <div>
            <FormattedMessage id="project.details-extention" /> : &nbsp;
            <span className="contact-item__value">{user.Ext ? user.Ext : '---'}</span>
          </div>
        </div>
      </div>
      <div className="user-contact">
        <div className="contact-item">
          <EmailIcon className="contact-icon" width="17px" height="17px" stroke={theme.ThemeColor.item.Text} />
          <div>
            <FormattedMessage id="project.details-email" /> : &nbsp;
            <span className="contact-item__value">{user.Email ? user.Email : '---'}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = ({ theme }) => ({
  theme,
});

export default connect(mapStateToProps)(UserCard);
