/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import BouncyBalls from '../BouncyBalls';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-dwon.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/arrow-up.svg';

import './styles.scss';

class Panel extends React.Component {
  state = {
    collapsed: false,
  };

  handleCollapseChange = () => {
    this.setState(prevState => ({
      collapsed: !prevState.collapsed,
    }));
  };

  handleAction = (e, action) => {
    e.stopPropagation();
    action(e);
  };

  render() {
    const {
      props: { title, render, count, isCollapsable, isLoading, theme, action, actionText, actionDisabled },
      state: { collapsed },
    } = this;
    const themeColor = theme.ThemeColor.item.Text;
    const titleIsString = typeof title === 'string';
    let headerProps = {};

    if (isCollapsable) {
      headerProps = {
        onClick: this.handleCollapseChange,
      };
    }

    return (
      <div className="panel__container" style={{ borderTopColor: themeColor }}>
        <div className="panel__header" {...headerProps} style={{ cursor: isCollapsable ? 'pointer' : 'auto' }}>
          <div className="panel__header-leading">
            <div className="panel__header-title">{titleIsString ? <h3>{title}</h3> : title()}</div>
            {typeof count !== 'object' && count >= 0 ? (
              <div className="panel__header-count" style={{ backgroundColor: themeColor }}>
                <p>{count}</p>
              </div>
            ) : null}
          </div>
          <div className="panel__header-trailing">
            {action ? (
              <Button
                type="button"
                disabled={actionDisabled}
                onClick={e => this.handleAction(e, action)}
                backgroundColor={themeColor}
              >
                {actionText}
              </Button>
            ) : null}
            {isCollapsable ? collapsed ? <ArrowDownIcon fill={themeColor} /> : <ArrowUpIcon fill={themeColor} /> : null}
          </div>
        </div>
        {!collapsed ? (
          <div className="panel__content">
            {!isLoading ? render() : <BouncyBalls color={themeColor} loading={isLoading} />}
          </div>
        ) : null}
      </div>
    );
  }
}

Panel.defaultProps = {
  count: 0,
  isCollapsable: false,
  collapsed: false,
  isLoading: false,
};

const mapStateToProps = ({ theme }) => ({
  theme,
});

export default connect(mapStateToProps)(Panel);
