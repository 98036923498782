import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import ProcessesLoader from '../../../components/ProcessesLoader';
import ProjectEdit from '../AddProject/ProjectEdit';
import { reduceCustomFields } from '../../../Helpers';
import { compsMap } from '../../../Helpers/processes';

function ProcessEditPreview(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [project, setProject] = useState({});
  const { history, match } = props;

  const { id, process } = match.params;

  async function getRequestData() {
    setLoading(true);
    try {
      const res = await new ProjectAPIController().getProcessRequest(process, id);
      const projectRes = await new ProjectAPIController().getProjectByCode(res.Data.ProjectUID);
      res.Data.Data.TitleEnglish = res.Data.NameEnglish;
      res.Data.Data.TitleArabic = res.Data.NameArabic;
      setData(res.Data);
      setProject(reduceCustomFields(projectRes.Data));
    } catch {
      // TODO add a toast here
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRequestData();
  }, [history]);

  const ProcessComponent = process !== 'newproject' ? compsMap[process] : ProjectEdit;

  if (!ProcessComponent || (!loading && !data)) return <Redirect from={history.location.pathname} to="/" />;

  const readOnly = !!(data && data.Steps.length);

  return loading ? (
    <ProcessesLoader />
  ) : (
    <ProcessComponent
      {...props}
      readOnly={readOnly}
      editData={data}
      project={project}
      processType={process}
      refetchData={getRequestData}
    />
  );
}

export default ProcessEditPreview;
