import React from 'react';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as StartDateIcon } from '../../assets/images/calendar.svg';
import { ReactComponent as EndDateIcon } from '../../assets/images/calendar-end.svg';
import { ReactComponent as CashIcon } from '../../assets/images/cash.svg';
import ProjectInfoLoader from './ProjectInfoLoader';
import LineChart from '../LineChart';
import ProgressBar from '../ProgressBar';
import HChip from '../HChip';
import Badge from '../Badge';

import { getStatusColor, nFormatter } from '../../Helpers';

import './style.scss';

function DataCard(props) {
  const {
    theme,
    Status,
    ProjectName,
    locale,
    ProjectNameArabic,
    Budget,
    LastCheckoutDate,
    ActualCompletedPercentage,
    customFields,
    loading,
    intl,
    monthlyAssignment,
    ProjectStartDate,
    earning,
  } = props;
  const themeColor = theme.ThemeColor.item.Text;
  const projectName = () => {
    if (locale === 'en') {
      return ProjectName || ProjectNameArabic;
    }
    return ProjectNameArabic || ProjectName;
  };

  if (loading) return <ProjectInfoLoader themeColor={themeColor} />;
  const monthlyAssignmentAvailable = Object.keys(monthlyAssignment).length;
  const earningAvailable = Object.keys(earning).length;
  return (
    <div className="projectInfo">
      <aside className="projectInfo__main" style={{ borderTopColor: getStatusColor(theme, Status) }}>
        <div className="title">{projectName()}</div>
        <div>
          <Badge theme={theme} Status={Status} locale={locale} />
        </div>

        <div className="info-container">
          <div className="info start-date">
            <StartDateIcon className="info-icon" width="15px" height="15px" fill={themeColor} />
            <div className="info-details">
              <FormattedMessage id="datacard.startdate" />
              <span className="data">{moment(ProjectStartDate).format('DD-MMM-YYYY')}</span>
            </div>
          </div>
          <div className="info end-date">
            <EndDateIcon className="info-icon" width="15px" height="15px" fill={themeColor} />
            <div className="info-details">
              <FormattedMessage id="datacard.finished-date" />
              <span className="data">{moment(new Date(customFields.FinishDate.item.Value)).format('DD-MMM-YYYY')}</span>
            </div>
          </div>
          <div className="info last-published">
            <StartDateIcon className="info-icon" width="15px" height="15px" fill={themeColor} />
            <div className="info-details">
              <FormattedMessage id="datacard.last-published" />
              <span className="data">{moment(LastCheckoutDate).fromNow()}</span>
            </div>
          </div>
          <div className="info budget">
            <CashIcon className="info-icon" width="15px" height="15px" fill={themeColor} />
            <div className="info-details">
              <FormattedMessage id="datacard.budget" />
              <span className="data">{nFormatter(Budget, 1, locale)}</span>
            </div>
          </div>
        </div>

        <div className="project-progress">
          <ProgressBar
            firstLineValue={ActualCompletedPercentage}
            firstLineColor={getStatusColor(theme, Status)}
            firstLineTitle={intl.formatMessage({ id: 'datacard.actual-percentage' })}
            secondValue={`${ActualCompletedPercentage} %`}
            bigNumber
          />
        </div>
        <div className="project-progress">
          <ProgressBar
            firstLineValue={customFields.PlannedProgress.item.Value}
            firstLineColor={getStatusColor(theme, Status)}
            firstLineTitle={intl.formatMessage({ id: 'datacard.planned-percentage' })}
            secondValue={`${customFields.PlannedProgress.item.Value} %`}
            bigNumber
          />
        </div>
        <div className="project-progress">
          <ProgressBar
            firstLineValue={customFields.BaselineProgress.item.Value}
            firstLineColor={getStatusColor(theme, Status)}
            firstLineTitle={intl.formatMessage({ id: 'datacard.baseline-percentage' })}
            secondValue={`${customFields.BaselineProgress.item.Value} %`}
            bigNumber
          />
        </div>
      </aside>
      {earningAvailable ? (
        <div className="projectInfo__chart" style={{ borderTopColor: getStatusColor(theme, Status) }}>
          <div className="projectInfo__chart-canvas">
            <LineChart data={earning} />
          </div>
          <div className="projectInfo__chart-datalabels">
            {earning.datasets.map(item => (
              <HChip key={item.id} label={intl.formatMessage({ id: `common.${item.id}` })} color={item.strokeColor} />
            ))}
          </div>
        </div>
      ) : null}
      {monthlyAssignmentAvailable ? (
        <div className="projectInfo__chart" style={{ borderTopColor: getStatusColor(theme, Status) }}>
          <div className="projectInfo__chart-canvas">
            <LineChart data={monthlyAssignment} />
          </div>
          <div className="projectInfo__chart-datalabels">
            {monthlyAssignment.datasets.map(item => (
              <HChip key={item.id} label={intl.formatMessage({ id: `common.${item.id}` })} color={item.strokeColor} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default injectIntl(DataCard);
