import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Breadcrumbs from '../../../components/Breadcrumb';
import DataCard from '../../../components/ProjectInfo';
import ProjectData from '../../../components/ProjectData';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import routes from '../../../routes';

import { getMonthlyAssignment, getEarning } from '../../../actions/project';
import { cleanCustomFields, getMonthlyAssignmentInitials, addToast } from '../../../Helpers';
import { getEarningInitials } from '../../../Helpers/Project';

import './style.scss';

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projectDetails: {},
      customFields: {},
    };
  }

  componentDidMount() {
    this.getProjectByCode();
    this.getMonthlyAssignment();
    this.getEarning();
  }

  getProjectByCode() {
    const { match } = this.props;

    new ProjectAPIController()
      .getProjectByCode(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({
            projectDetails: res.Data,
            loading: false,
            customFields: cleanCustomFields(res.Data.CustomFields),
          });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-project-toast',
        });
      });
  }

  getEarning = () => {
    const { getEarningAction, match } = this.props;
    getEarningAction(match.params.code);
  };

  getMonthlyAssignment = () => {
    const { getMonthlyAssignmentAction, match } = this.props;
    getMonthlyAssignmentAction(match.params.code);
  };

  getLineChartData = () => {
    const { monthlyAssignment, intl } = this.props;
    const months = moment.monthsShort(); // ['jan.', 'feb.', 'mrt.', ...]

    return monthlyAssignment.reduce((accum, month, iteration) => {
      const _accum = { ...accum };

      if (!Object.keys(_accum).length) {
        _accum.datasets = getMonthlyAssignmentInitials();
        _accum.labels = [];
      }

      const abbriviatedLocalizedMonth = months[month.Month - 1];
      _accum.labels = [..._accum.labels, abbriviatedLocalizedMonth];
      _accum.datasets.actual.data.push(month.WorkActualDays);
      _accum.datasets.baseline.data.push(month.WorkBaselineDays);
      _accum.datasets.planned.data.push(month.WorkPlannedDays);

      if (iteration + 1 === monthlyAssignment.length) {
        _accum.datasets.actual.label = intl.formatMessage({ id: 'common.actual' });
        _accum.datasets.baseline.label = intl.formatMessage({ id: 'common.baseline' });
        _accum.datasets.planned.label = intl.formatMessage({ id: 'common.planned' });
        _accum.datasets = Object.values(_accum.datasets);
        // eslint-disable-next-line
        _accum.datasets.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
      }

      return _accum;
    }, {});
  };

  getLineChartEarningData = () => {
    const { earning, intl } = this.props;
    const months = moment.monthsShort(); // ['jan.', 'feb.', 'mrt.', ...]

    return earning.reduce((accum, month, iteration) => {
      const _accum = { ...accum };

      if (!Object.keys(_accum).length) {
        _accum.datasets = getEarningInitials();
        _accum.labels = [];
      }

      const abbriviatedLocalizedMonth = months[month.Month - 1];
      _accum.labels = [..._accum.labels, abbriviatedLocalizedMonth];
      _accum.datasets.consumed.data.push(month.ConsumedBudget);
      _accum.datasets.earn.data.push(month.EarnValue);

      if (iteration + 1 === earning.length) {
        _accum.datasets.consumed.label = intl.formatMessage({ id: 'projectliquidity.spent' });
        _accum.datasets.earn.label = intl.formatMessage({ id: 'common.earn' });

        _accum.datasets = Object.values(_accum.datasets);
        // eslint-disable-next-line
        _accum.datasets.sort((a, b) => (a.id < b.id ? -1 : b.id < a.id ? 1 : 0));
      }

      return _accum;
    }, {});
  };

  render() {
    const { projectDetails, loading, customFields } = this.state;
    const { theme, language, match, user, history } = this.props;
    let breadcrumbData = [];

    if (Object.keys(projectDetails).length) {
      const projectDetailsRoute = {
        title: language.locale === 'ar' ? projectDetails.ProjectNameArabic : projectDetails.ProjectName,
      };
      const programRoute = {
        title: language.locale === 'ar' ? projectDetails.Program.NameArabic : projectDetails.Program.NameEnglish,
        location: `/programs/${projectDetails.PortfolioUID}/?program=${projectDetails.Program.ProjectUID}`,
      };
      breadcrumbData = [programRoute, projectDetailsRoute];
    }

    return (
      <div className="content-container">
        <Route exact path={match.path}>
          <Redirect to={routes.preview(match.url)} />
        </Route>
        <Breadcrumbs title="project-details-bread-title" routes={breadcrumbData} />
        <div className="content-body">
          <DataCard
            {...projectDetails}
            customFields={customFields}
            theme={theme}
            locale={language.locale}
            loading={loading}
            monthlyAssignment={this.getLineChartData()}
            earning={this.getLineChartEarningData()}
          />
          <ProjectData
            loading={loading}
            projectDetails={projectDetails}
            theme={theme}
            locale={language.locale}
            match={match}
            user={user}
            history={history}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ project, ...state }) => {
  return {
    ...state,
    monthlyAssignment: project.monthlyAssignment,
    loadingMonthlyAssignment: project.loaders.getMonthlyAssignment,
    earning: project.earning,
  };
};

const mapDispatchToProps = {
  getMonthlyAssignmentAction: getMonthlyAssignment,
  getEarningAction: getEarning,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProjectDetails));
