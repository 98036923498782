import React from 'react';
import { injectIntl } from 'react-intl';

import CountCard from '../CountCard';
import Loader from './Loader';

import { renderMultiple } from '../../Helpers';

import './styles.scss';

function DashboardSummaryCards({ intl, data, loading }) {
  return loading ? (
    <div className="animated-background" style={{ display: 'flex', justifyContent: 'space-between' }}>
      {renderMultiple(
        key => (
          <Loader key={key} />
        ),
        6,
      )}
    </div>
  ) : (
    <div className="summary-cards">
      {data.map(({ Component, compProps, ...el }) => {
        const props = {
          key: el.id,
          title: intl.formatMessage({ id: el.id }),
          ...el,
        };

        if (Component) {
          return <Component {...props} {...compProps} />;
        }

        return <CountCard {...props} />;
      })}
    </div>
  );
}

export default injectIntl(DashboardSummaryCards);
