import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ImpulseBalls from '../BouncyBalls/ImpulseBalls';
import defaultAttachment from '../../assets/images/attachment.svg';
import AttachmentsAPIController from '../../providers/controllers/AttachmentsAPIController';

const AsyncImg = ({ src, name, themeColor, ...props }) => {
  const [img, setImg] = useState(defaultAttachment);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    new AttachmentsAPIController().getAttachment(src, name).then(imgUrl => {
      setImg(imgUrl);
      setIsLoading(false);
    });
  }, [src, name]);

  if (isLoading) {
    return <ImpulseBalls size={20} frontColor={themeColor} loading />;
  }

  return (
    <img
      src={img}
      onError={e => {
        e.target.onerror = null;
        setImg(defaultAttachment);
      }}
      alt={name}
      {...props}
    />
  );
};

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(AsyncImg);
