import React from 'react';
import { injectIntl } from 'react-intl';

import ProgressBar from '../ProgressBar';

import './style.scss';

function SummaryCard({ title, value, Icon, badgeColor, statusStyle, isProgress, intl, onClick, progressTitle }) {
  const iconStyle = {
    height: 35,
    width: 35,
    fill: '#FFF',
  };
  return (
    <div
      className="summary-card"
      onClick={onClick}
      style={{ borderTopColor: badgeColor || 'transparent', cursor: onClick ? 'pointer' : 'normal' }}
    >
      <div className="summary-card__content">
        <p className="summary-card__title">{title}</p>
        {isProgress ? (
          <div className="summary-card__progress-container">
            <ProgressBar
              firstLineValue={value}
              secondValue={`${value} %`}
              firstLineColor={badgeColor}
              firstLineTitle={intl.formatMessage({
                id: progressTitle || 'common.actual',
              })}
            />
          </div>
        ) : (
          <p className={`summary-card__value ${statusStyle ? 'summary-card__value--status' : ''}`}>
            <span>{typeof value === 'number' || !!value ? value : '----'}</span>
          </p>
        )}
      </div>
      {Icon && (
        <div className="summary-card__icon" style={{ backgroundColor: badgeColor }}>
          <Icon style={iconStyle} />
        </div>
      )}
    </div>
  );
}

export default injectIntl(SummaryCard);
