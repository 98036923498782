import React from 'react';

import CollapseContent from './CollapseContent';

import { withRouter } from 'react-router-dom';
import { ReactComponent as ArrowUp } from '../../assets/images/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../../assets/images/arrow-dwon.svg';
import './style.scss';

const CollapseContainer = props => {
  const { theme, locale, CustomCoponent, CustomCollapseContent, isExpanded, isCurrentExpanded, onClick } = props;

  return (
    <div className="collapse-main-outside-container">
      <div className="details-body">
        <div className="project-details">
          <div className="content cursor">
            <div className="content-container">
              <div className="content-starting">
                <CustomCoponent />
              </div>
              <div className="content-trailing">
                <button className={`${locale === 'ar' ? 'rtl ' : ''} btn-collapse cursor`} onClick={() => onClick()}>
                  {isExpanded && isCurrentExpanded ? (
                    <ArrowUp width="30px" height="30px" fill="#BFBFBF" />
                  ) : (
                    <ArrowDown width="30px" height="30px" fill="#BFBFBF" />
                  )}
                </button>
              </div>
            </div>
          </div>

          {isExpanded && isCurrentExpanded && (
            <div className="col-content">
              <CollapseContent
                className="cursor"
                locale={locale}
                theme={theme}
                CustomCollapseContent={CustomCollapseContent}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(CollapseContainer);
