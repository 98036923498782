import React from 'react';

import './loader.scss';

export default () => (
  <div className="pTabLoader__container animated-background">
    <div className="pTabLoader__head">
      <div className="pTabLoader__head-title shadow-background" />
    </div>
    <div className="pTabLoader__body">
      <div className="pTabLoader__item shadow-background" />
      <div className="pTabLoader__item shadow-background" />
      <div className="pTabLoader__item shadow-background" />
      <div className="pTabLoader__item shadow-background" />
      <div className="pTabLoader__item shadow-background" />
    </div>
  </div>
);
