import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { ReactComponent as SendIcon } from '../../assets/images/send.svg';
import AttachmentsList from '../CommentAttachments';

import Button from '../Button';

import Field from '../Field';
import './style.scss';

class CommentInput extends React.Component {
  state = {
    attachments: [],
    comment: '',
  };

  onDeleteAttatchment(index) {
    const { attachments } = this.state;
    const array = [...attachments];
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ attachments: array });
    }
  }

  render() {
    const { attachments, comment } = this.state;
    const { themeColor, intl, onSubmit, loading } = this.props;
    return (
      <div className="comment-input-container">
        <Field
          control={{
            type: 'attachment',
            onChangeAction: e => this.setState({ attachments: [...e] }),
            value: attachments,
            // validation: this.inputValidation,
            validateOnExite: false,
            style: {
              width: 40,
              minWidth: 40,
              height: 40,
              borderRadius: '50%',
              color: '#eeeeee',
            },
            disabled: loading,
            isCommentAttatch: true,
          }}
        />
        <div className="comment-input">
          <AttachmentsList Attachments={attachments} isDelete onDeleteAttatchment={e => this.onDeleteAttatchment(e)} />
          <Field
            className="modifyTaskModal__dataSpan"
            control={{
              type: 'text',
              placeholder: intl.formatMessage({ id: 'common.type-a-comment' }),
              onChangeAction: e => this.setState({ comment: e.target.value }),
              value: comment,
              //   error: !comment.length,
              validateOnExite: false,
              disabled: loading,
            }}
          />
        </div>
        <div className="btn-send-container">
          <Button
            onClick={() => {
              onSubmit(comment, attachments);
              this.setState({ attachments: [], comment: '' });
            }}
            color="white"
            style={{
              width: 40,
              minWidth: 40,
              height: 40,
              borderRadius: '50%',
              color: '#eeeeee',
            }}
            loading={loading}
            backgroundColor={themeColor}
            Icon={() => <SendIcon fill="white" />}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps, null)(CommentInput));
