import React from 'react';
import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';

import { ATTACHMENTS_URL } from '../../providers/config/urlServiceConfig';

import { ReactComponent as EmailIcon } from '../../assets/images/email.svg';
import { ReactComponent as UserIcon } from '../../assets/images/user.svg';

import './style.scss';

function SummaryInfoCard({
  user: { Username, Email, DisplayName },
  // title,
  themeColor,
}) {
  return (
    <div className="summary-card">
      <div className="summary-card-info">
        {Username ? (
          <img className="summary-card-info__image" src={`${ATTACHMENTS_URL}user/${Username}`} alt="user" />
        ) : (
          <UserIcon style={{ height: 66, width: 66 }} />
        )}
        <div className="summary-card-info__content">
          <h4 className="summary-card-info__content-name">{DisplayName || '-----'}</h4>
          {/*
          <p className="summary-card-info__content-title">
            {title ? <span>{title}</span> : <FormattedMessage id="dashboard.portfolio-sponsor" />}
          </p>
        */}
          <p className="summary-card-info__content-email-container">
            <EmailIcon style={{ height: 11, width: 15, fill: themeColor }} />
            <span className="summary-card-info__content-email">{Email || '-----'}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ theme }) => ({ themeColor: theme.ThemeColor.item.Text });

export default connect(mapStateToProps)(SummaryInfoCard);
