import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../components/Button';
import ProjectAPIController from '../../../../providers/controllers/ProjectAPIController';
import ProjectSection from './ProjectSection';
import DetailsSection from './DetailsSection';
import IDTable from '../IDTable';
import routes from '../../../../routes';
import { addToast } from '../../../../Helpers';

const IDDetails = ({ locale, theme, match, history }) => {
  const [dependency, setDependency] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const themeColor = theme.ThemeColor.item.Text;

  useEffect(() => {
    new ProjectAPIController()
      .getInterdependency(match.params.id)
      .then(res => {
        if (res.StatusCode === 'Success') {
          setDependency(res?.Data || []);
          setIsLoading(false);
        } else {
          throw new Error('service error');
        }
      })
      .catch(() => {
        addToast.error('errorFetchingData', {
          toastId: 'dependency-fetchError-error',
        });
      });
  }, []);

  const closeDependency = () => {
    setIsClosing(true);
    new ProjectAPIController()
      .closeInterdependency(match.params.id)
      .then(res => {
        if (res.StatusCode === 'Success') {
          addToast.success('common.changesHaveBeenSaved');
          history.replace(routes.interdependencies);
        } else {
          throw new Error('service error');
        }
      })
      .catch(() => {
        addToast.error('common.errorSavingChanges', {
          toastId: 'dependency-close-error',
        });
      })
      .finally(() => {
        setIsClosing(false);
      });
  };

  if (isLoading) return null;
  return (
    <div>
      <div className="id-header">
        <h4>
          <FormattedMessage id="common.interdependencies" />
        </h4>
        {dependency.Active ? (
          <div className="header-actions">
            <Button onClick={closeDependency} disabled={isLoading} loading={isClosing}>
              <FormattedMessage id="id.close-dependency" />
            </Button>
          </div>
        ) : null}
      </div>
      <div className="id-form">
        <ProjectSection formData={dependency} locale={locale} themeColor={themeColor} type="affecting" />
        <ProjectSection formData={dependency} locale={locale} themeColor={themeColor} type="affected" />
        {/* {dependency.AffectedProject?.ProjectUID ? ( */}
        <div className="id-section">
          <div className="id-section-content">
            <div className="id-section-row align-start">
              <IDTable type="Risks" list={dependency.Risks} preview />
              <IDTable type="Issues" list={dependency.Issues} preview />
            </div>
          </div>
        </div>
        {/* ) : null} */}
        <DetailsSection formData={dependency} locale={locale} themeColor={themeColor} theme={theme} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ theme, language }) => ({
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(IDDetails);
