import React from 'react';
import './Loader.scss';

const ReportsListLoader = () => {
  return (
    <div className="reports-graph-container">
      <div className="reports-list-shadow">
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
      </div>
    </div>
  );
};

const ReportsContentLoader = () => {
  return (
    <div className="reports-graph-container">
      <div className="reports-list-shadow">
        <div className="animated-background" style={{ padding: '16px' }} />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
      </div>
    </div>
  );
};
export { ReportsListLoader, ReportsContentLoader };
