import React from 'react';
import './style.scss';
import moment from 'moment';
import { ReactComponent as DeleteButton } from '../../assets/images/delete-button.svg';
import AttachmentsList from '../CommentAttachments';
import Avatar from '../Avatar';

const PhaseGateCollapseContent = props => {
  const { data, deleteComment, createDeleteLoading } = props;

  return (
    <div className="phase-gate-collapse-content">
      <div className="first-row">
        <div className="avatar-with-text">
          <Avatar user={data.Created} width={30} />
          <div className="text-container">
            <div>{data.Created.DisplayName}</div>
            <div className="small-font-size-text">{moment(data.Timestamp).format('MMMM DD, YYYY h:mm a')}</div>
            <div className="description">{data.Text}</div>
            <AttachmentsList Attachments={data.Attachments} />
          </div>
        </div>

        <button className="delete-icon" onClick={() => deleteComment(data.ID)} disabled={createDeleteLoading}>
          {createDeleteLoading ? <div className="loading-indicator" /> : <DeleteButton />}
        </button>
      </div>
    </div>
  );
};

export default PhaseGateCollapseContent;
