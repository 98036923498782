import React from 'react';
import { connect } from 'react-redux';

import Navigator from '../Navigator';
import Main from './Main';
import Add from './Add';
import Details from './Details';
import routes from '../../routes';
import { getAllDeliverables, getAllDeliverableByID } from '../../actions/deliverables';

import './styles.scss';

class Deliverables extends React.Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.getDeliverableById = this.getDeliverableById.bind(this);
  }

  componentDidMount() {
    this.getAllDeliverables();
  }

  getDeliverableById = (programUID, ID) => {
    const { getDeliverableByIDAction } = this.props;
    getDeliverableByIDAction(programUID, ID);
  };

  getAllDeliverables = () => {
    const {
      match: {
        params: { code },
      },
      getAllDeliverablesAction,
    } = this.props;
    getAllDeliverablesAction(code);
  };

  changeView(view, id) {
    const { history, match } = this.props;
    history.push(routes[view](match.url, id));
  }

  render() {
    const {
      props: {
        match,
        loaders: { loadingDeliverables, loadingDeliverable },
        deliverables,
        deliverable,
        themeColor,
      },
    } = this;

    const componentsList = [
      {
        id: 'add',
        name: 'add',
        component: Add,
        props: {
          changeView: this.changeView,
          deliverables,
          loading: loadingDeliverables,
        },
      },
      {
        id: 'details',
        name: 'view',
        component: Details,
        props: {
          changeView: this.changeView,
          deliverable,
          code: match.params.code,
          loading: loadingDeliverable,
          getDeliverableById: this.getDeliverableById,
        },
      },
      {
        id: 'edit',
        name: 'edit',
        component: Add,
        props: {
          changeView: this.changeView,
          deliverable,
          editMode: true,
          loading: loadingDeliverable,
          getDeliverableById: this.getDeliverableById,
        },
      },
      {
        id: 'base',
        name: 'main',
        component: Main,
        props: { changeView: this.changeView, themeColor, deliverables, loading: loadingDeliverables },
      },
    ];

    return <Navigator match={match} componentsList={componentsList} />;
  }
}

const mapStateToProps = ({ deliverables, theme }) => ({
  ...deliverables,
  themeColor: theme.ThemeColor.item.Text,
});

const mapDispatchToProps = {
  getAllDeliverablesAction: getAllDeliverables,
  getDeliverableByIDAction: getAllDeliverableByID,
};

export default connect(mapStateToProps, mapDispatchToProps)(Deliverables);
