import React from 'react';
import { connect } from 'react-redux';

import AsyncImg from '../AsyncImg';
import ImpulseBalls from '../BouncyBalls/ImpulseBalls';
import { ReactComponent as AttachmentIcon } from '../../assets/images/attachment.svg';
import { ReactComponent as PDFIcon } from '../../assets/images/pdf-icon.svg';
import { ReactComponent as DOCIcon } from '../../assets/images/doc-icon.svg';
import { ReactComponent as TextDocIcon } from '../../assets/images/text-document.svg';
import { ReactComponent as ExcelIcon } from '../../assets/images/excel-icon.svg';
import { ReactComponent as PowerPointIcon } from '../../assets/images/powerpoint-icon.svg';
import { baseURL } from '../../providers/config/urlServiceConfig';
import { getAttachmentFileType, trimFileName, downloadAttachment } from '../../Helpers';

import './styles.scss';

const imageTypes = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg'];

class Attachment extends React.Component {
  state = {
    isLoading: false,
  };

  renderIcon = attachment => {
    const type = getAttachmentFileType(attachment);
    switch (true) {
      case type === 'pdf':
        return <PDFIcon />;
      case type === 'doc' || type === 'docx':
        return <DOCIcon />;
      case type === 'txt':
        return <TextDocIcon />;
      case type === 'xls' || type === 'xlsx':
        return <ExcelIcon />;
      case type === 'ppt' || type === 'pptx':
        return <PowerPointIcon style={{ width: '40px' }} />;
      case imageTypes.indexOf(type) >= 0:
        return <AsyncImg src={`${baseURL}${attachment.AttachmentDownloadURL}`} name={attachment.Name} />;
      default:
        return <AttachmentIcon />;
    }
  };

  handleDownload = attachment => {
    this.setState({ isLoading: true }, () => {
      downloadAttachment(attachment).then(() => this.setState({ isLoading: false }));
    });
  };

  render() {
    const { attachment, key, themeColor } = this.props;
    const { isLoading } = this.state;

    return (
      <div onClick={() => this.handleDownload(attachment)} className="mb-15 attachment-item" key={key}>
        <div className="attachment__container">
          <div className="attachment__icon text-blue" style={{ color: themeColor }}>
            {isLoading ? (
              <ImpulseBalls size={20} frontColor={themeColor} loading={isLoading} />
            ) : (
              this.renderIcon(attachment)
            )}
          </div>
        </div>
        <p title={attachment.Name} className="attachmetn-name">
          {trimFileName(attachment.Name)}
        </p>
      </div>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  return {
    themeColor: theme.ThemeColor.item.Text,
  };
};

export default connect(mapStateToProps, null)(Attachment);
