import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Field from '../Field';
import AttachmentsList from '../AttachmentsList';

import './style.scss';

function ProcessesAttachments({
  readOnly,
  attachments = [],
  intl,
  onChange,
  title = 'common.attachments',
  required,
  theme,
}) {
  return (
    <div className="processes-attachments-wrapper">
      {readOnly ? (
        <div>
          {attachments && Boolean(attachments.length) && (
            <>
              <span className="attachments-title" style={{ color: theme.ThemeColor.item.Text }}>
                <FormattedMessage id={title} />
              </span>
              <AttachmentsList attachments={attachments} />
            </>
          )}
        </div>
      ) : (
        <Field
          label={{
            label: intl.formatMessage({ id: title }),
            style: { color: theme.ThemeColor.item.Text, fontWeight: 'bold' },
          }}
          control={{
            type: 'attachment',
            value: attachments || [],
            onChangeAction: onChange,
            required,
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = ({ theme }) => {
  return {
    theme,
  };
};

export default injectIntl(connect(mapStateToProps)(ProcessesAttachments));
