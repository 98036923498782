import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Panel from '../Panel';
import PanelLoader from '../Panel/PanelLoader';
import TaskRow from '../TaskRow';
import EmptyComponent from '../EmptyComponent';
import TaskRowLoader from '../TaskRow/TaskRowLoader';

import { renderMultiple, addToast } from '../../Helpers';
import { getNonProjectsTasks, postMyUpdatedTasks, postMyUpdatedTasksStartAction } from '../../actions/tasksCenter';

import './styles.scss';

class MyTasks extends React.Component {
  state = {};

  componentDidMount() {
    const { getNonProjectsTasksAction } = this.props;
    getNonProjectsTasksAction();
  }

  renderTasks = (tasks = []) => {
    if (!tasks.length) return <EmptyComponent small />;
    return tasks.map((task, i) => <TaskRow task={task} key={`task#${i + 1}`} type="nonProjectTask" />);
  };

  handleSave = () => {
    const { postMyUpdatedTasksAction, myUpdatedTasks, postMyUpdatedTasksStart } = this.props;
    const body = {
      data: [
        {
          ...myUpdatedTasks[0],
          StatusID: 3, // pending
        },
      ],
    };
    postMyUpdatedTasksStart();
    postMyUpdatedTasksAction(body, () => {
      addToast.success('common.changesHaveBeenSaved');
    });
  };

  render() {
    const {
      props: { intl, isLoading, myTasks, isSubmitting, myUpdatedTasks },
    } = this;

    return (
      <div className={`myTasks__container${isSubmitting ? ' loading' : ''}`}>
        {isLoading ? (
          <PanelLoader render={() => renderMultiple(_key => <TaskRowLoader key={_key} />, 6)} />
        ) : (
          <Panel
            title="Non Projects Tasks"
            count={myTasks.length}
            render={() => this.renderTasks(myTasks)}
            isLoading={isLoading}
            action={this.handleSave}
            actionText={intl.formatMessage({ id: 'common.save' })}
            actionDisabled={isSubmitting || !myUpdatedTasks.length}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ tasksCenter: { myTasks, myUpdatedTasks, loaders }, language }) => ({
  loaders,
  language,
  myTasks,
  isLoading: loaders.myTasks,
  isSubmitting: loaders.postingMyUpdatedTasks,
  myUpdatedTasks,
});

const mapDispatchToProps = {
  getNonProjectsTasksAction: getNonProjectsTasks,
  postMyUpdatedTasksAction: postMyUpdatedTasks,
  postMyUpdatedTasksStart: postMyUpdatedTasksStartAction,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MyTasks));
