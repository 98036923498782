import React from 'react';

import './styles.scss';

const HorizontalChip = ({ countText, color, text }) => {
  return (
    <div className="horizontalChip__container">
      <span className="horizontalChip__countText">{countText}</span>
      <span className="horizontalChip__color" style={{ backgroundColor: color }} />
      <span className="horizontalChip__text">{text}</span>
    </div>
  );
};

export default HorizontalChip;
