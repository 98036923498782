import {
  POST_RISK_START,
  POST_RISK,
  POST_ISSUE_START,
  POST_ISSUE,
  POST_LIQUIDITY_START,
  POST_LIQUIDITY,
  GET_MONTHLY_ASSIGNMENT,
  GET_EARNING,
} from '../actions/project';

const initialState = {
  loaders: {
    postRisk: false,
    postIssue: false,
    postLiquidity: false,
    getMonthlyAssignment: true,
    getEarning: true,
  },
  monthlyAssignment: [],
  earning: [],
};

function projectReducer(state = initialState, { type, payload }) {
  switch (type) {
    case 'LOGOUT': {
      return {
        ...initialState,
      };
    }
    case POST_RISK_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postRisk: true,
        },
      };
    }
    case POST_ISSUE_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postIssue: true,
        },
      };
    }
    case POST_LIQUIDITY_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postLiquidity: true,
        },
      };
    }
    case POST_RISK: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postRisk: false,
        },
      };
    }
    case POST_ISSUE: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postIssue: false,
        },
      };
    }
    case POST_LIQUIDITY: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postLiquidity: false,
        },
      };
    }
    case GET_MONTHLY_ASSIGNMENT: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getMonthlyAssignment: false,
        },
        monthlyAssignment: payload,
      };
    }
    case GET_EARNING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          getEarning: false,
        },
        earning: payload,
      };
    }
    default:
      return state;
  }
}

export default projectReducer;
