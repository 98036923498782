import React from 'react';
import { connect } from 'react-redux';

import './styles.scss';

const BotOptions = ({ text, options, onOptionSelect, themeColor }) => {
  return (
    <div className="taskOptions__container">
      <div className="taskOptions__description">
        <p>{text}</p>
      </div>
      {options.map((option, i) => (
        <div className="taskOptions__item" key={`taskOptions__item-#${i + 1}`}>
          <button type="submit" onClick={() => onOptionSelect(option)} style={{ backgroundColor: themeColor }}>
            {option.title}
          </button>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(BotOptions);
