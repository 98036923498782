import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import Field from '../Field';

function Evaluation({ saveData, request, readOnly, intl, themeColor }) {
  return (
    <div className="evaluation">
      <div className="title">
        <span style={{ color: themeColor }}>
          <FormattedMessage id="common.evaluation" />
        </span>
      </div>
      <div className="content">
        <div className="vendor">
          <Field
            label={{
              label: intl.formatMessage({ id: 'common.vendor' }),
            }}
            control={{
              type: 'input',
              name: 'Vendor',
              value: request.Data.Vendor || '',
              onChangeAction: e => saveData({ Vendor: e.currentTarget.value }),
              error: !request.Data.Vendor,
              disabled: readOnly,
            }}
          />
          <div className="flex-width-text-field">
            <Field
              control={{
                type: 'inputwithformula',
                name: 'VendorReview',
                onChangeAction: e => saveData({ VendorReview: parseInt(e.currentTarget.value, 10) }),
                value: request.Data.VendorReview || '',
                validateOnExite: true,
                formula: '10/',
                error: request.Data.VendorReview > 10 || request.Data.VendorReview < 0,
                disabled: readOnly,
                required: true,
              }}
            />
          </div>
        </div>
        <div className="team-performance">
          <Field
            label={{
              label: intl.formatMessage({ id: 'common.team-performance' }),
            }}
            control={{
              type: 'inputwithformula',
              name: 'teamPerformance',
              onChangeAction: e => saveData({ TeamReview: parseInt(e.currentTarget.value, 10) }),
              value: request.Data.TeamReview || '',
              validateOnExite: true,
              formula: '10/',
              error: request.Data.TeamReview > 10 || request.Data.TeamReview < 0,
              disabled: readOnly,
              required: true,
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps)(Evaluation));
