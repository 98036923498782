import React, { useState } from 'react';
import Button from '../Button';
import { downloadFile } from '../../Helpers';

const DownloadButton = ({ children, url, filename, ...otherProps }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = () => {
    setIsLoading(true);
    downloadFile(url, filename).finally(() => setIsLoading(false));
  };

  return (
    <Button
      style={{ cursor: otherProps.disabled ? 'unset' : 'pointer' }}
      loading={isLoading}
      {...otherProps}
      onClick={handleDownload}
    >
      {children}
    </Button>
  );
};

export default DownloadButton;
