import DashboardAPIController from '../providers/controllers/DashboardAPIController';

export const GET_ALL_ENTITIES = 'GET_ALL_ENTITIES';
export const GET_ALL_PROGRAMS = 'GET_ALL_PROGRAMS';
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const GET_ALL_RISKS = 'GET_ALL_RISKS';
export const GET_ALL_ISSUES = 'GET_ALL_ISSUES';

const getAllEntitiesAction = payload => {
  return {
    type: GET_ALL_ENTITIES,
    payload,
  };
};

const getAllProgramsAction = payload => {
  return {
    type: GET_ALL_PROGRAMS,
    payload,
  };
};

const getAllProjectsAction = payload => {
  return {
    type: GET_ALL_PROJECTS,
    payload,
  };
};

const getAllRisksAction = payload => ({
  type: GET_ALL_RISKS,
  payload,
});

const getAllIssuesAction = payload => ({
  type: GET_ALL_ISSUES,
  payload,
});

export const getAllEntities = () => {
  return dispatch => {
    new DashboardAPIController().getAllEntities().then(res => {
      dispatch(getAllEntitiesAction(res.Data));
    });
  };
};

export const getAllPrograms = () => {
  return dispatch => {
    new DashboardAPIController().getAllPrograms().then(res => {
      dispatch(getAllProgramsAction(res.Data));
    });
  };
};

export const getAllProjects = () => {
  return dispatch => {
    new DashboardAPIController().getAllProjects().then(res => {
      dispatch(getAllProjectsAction(res.Data));
    });
  };
};

export const getAllRisks = () => async dispatch => {
  const res = await new DashboardAPIController().getAllRisks();

  dispatch(getAllRisksAction(res.Data));
};

export const getAllIssues = () => async dispatch => {
  const res = await new DashboardAPIController().getAllIssues();

  dispatch(getAllIssuesAction(res.Data));
};
