import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { filterRisks } from '../../Helpers';
import ProjectRisksMain from '../ProjectRisks/ProjectRisksMain';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';

function Risks({ theme, locale, projectCode }) {
  const [loading, setLoading] = useState(true);
  const [risks, setRisks] = useState([]);

  async function getRisks() {
    try {
      const res = await new ProjectAPIController().getProjectRisks(projectCode);
      setRisks(res.Data);
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRisks();
  }, [projectCode]);

  return (
    <div className="dashboard-tabs-section__risks">
      <ProjectRisksMain risksList={filterRisks(risks)} theme={theme} locale={locale} loading={loading} readOnly />
    </div>
  );
}

const mapStateToProps = ({ theme, language }) => ({
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(Risks);
