import React, { Component } from 'react';

import ProjectPreview from '../ProjectPreview';
import ProjectUpdateProgress from '../ProjectUpdateProgress';
import ProjectRisks from '../ProjectRisks';
import ProjectIssues from '../ProjectIssues';
import ProjectTasks from '../ProjectTasks';
import DocsLibrary from '../DocsLibrary';
import PhasesGateReview from '../PhasesGateReview';
import ProcessesTab from '../ProcessesTab';

import { ReactComponent as ChartIcon } from '../../assets/images/column-chart.svg';
import { ReactComponent as Phases } from '../../assets/images/phases.svg';
import { ReactComponent as Processes } from '../../assets/images/process.svg';
import { ReactComponent as ProgressIcon } from '../../assets/images/progress-up.svg';
import { ReactComponent as RiskIcon } from '../../assets/images/risk.svg';
import { ReactComponent as IssueIcon } from '../../assets/images/issue.svg';
import { ReactComponent as TaskIcon } from '../../assets/images/task.svg';
import { ReactComponent as FilesIcon } from '../../assets/images/files.svg';
import { ReactComponent as FinancialIcon } from '../../assets/images/money-bag.svg';

import './style.scss';
import TabsContainer from '../TabsContainer';
import routes from '../../routes';
import Launcher from '../P+Chat';
import ProjectFinancial from '../ProjectFinancial';

class ProjectData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponentName: 'projectdata.preview',
      index: 0,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const {
      location: { search },
    } = history;

    if (search && search.includes('?to=')) {
      const to = search.replace('?to=', '');
      if (to === 'risk') this.handleSelectedCompChange('projectdata.risks', 2);
      if (to === 'issue') this.handleSelectedCompChange('projectdata.issues', 3);
    }
  }

  handleSelectedCompChange = (item, id) => {
    const { match, history } = this.props;
    this.setState(
      {
        selectedComponentName: item,
        index: id,
      },
      () => {
        history.replace(routes[id](match.url));
      },
    );
  };

  render() {
    const { selectedComponentName, index } = this.state;
    const { theme, projectDetails, locale, match, loading, user } = this.props;
    const program = projectDetails;
    const componentsList = [
      {
        id: 'preview',
        name: 'projectdata.preview',
        icon: <ChartIcon width="15px" height="15px" stroke={theme.ThemeColor.item.Text} />,
        component: ProjectPreview,
        props: { loading, user, projectDetails, theme, locale },
      },
      {
        id: 'phasesGateReview',
        name: 'phasesGateReview',
        icon: <Phases width="15px" height="15px" />,
        component: PhasesGateReview,
        props: { theme, locale, program },
      },
      {
        id: 'processes',
        name: 'projectdata.processes',
        icon: <Processes width="15px" height="15px" />,
        component: ProcessesTab,
        props: { theme, user, locale, program },
      },
      {
        id: 'progress',
        name: 'projectdata.progress',
        icon: <ProgressIcon width="15px" height="15px" />,
        component: ProjectUpdateProgress,
        props: { theme, locale },
      },
      {
        id: 'financial',
        name: 'projectdata.financial',
        icon: <FinancialIcon width="15px" height="15px" />,
        component: ProjectFinancial,
        props: { theme, locale, projectDetails },
      },
      {
        id: 'risks',
        name: 'projectdata.risks',
        icon: <RiskIcon width="15px" height="15px" />,
        component: ProjectRisks,
        props: {
          theme,
          locale,
          user,
          projectDetails,
          onConvertToIssue: () => this.handleSelectedCompChange('projectdata.issues', 3),
        },
      },
      {
        id: 'issues',
        name: 'projectdata.issues',
        icon: <IssueIcon width="15px" height="15px" />,
        component: ProjectIssues,
        props: { projectDetails, user, theme, locale },
      },
      {
        id: 'tasks',
        name: 'projectdata.tasks',
        icon: <TaskIcon width="15px" height="15px" />,
        component: ProjectTasks,
        props: { projectDetails, theme, locale },
      },
      {
        id: 'documents',
        name: 'projectdata.docsLibrary',
        icon: <FilesIcon width="15px" height="15px" />,
        component: DocsLibrary,
        props: { theme, locale },
      },
    ];

    return (
      <>
        <TabsContainer
          activeTab={index}
          activeTabName={selectedComponentName}
          tabs={componentsList}
          onTabClick={this.handleSelectedCompChange}
          theme={theme}
          locale={locale}
          match={match}
        />
        <div className="chat-contanier">
          <Launcher
            theme={theme}
            locale={locale}
            code={match.params.code}
            agentProfile={{
              teamName: locale === 'ar' ? 'نقاشات المشروع' : 'Chat',
              imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
            }}
            showEmoji={false}
          />
        </div>
      </>
    );
  }
}

export default ProjectData;
