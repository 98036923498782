import React from 'react';

class StateNavigator extends React.Component {
  render() {
    const { componentsList, selectedComponentName } = this.props;
    return componentsList.find(item => item.name === selectedComponentName).component;
  }
}

export default StateNavigator;
