import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumb';
import ProgramData from '../../../components/ProgramData';
import ProgramsAPIController from '../../../providers/controllers/ProgramsAPIController';
import PortfolioaAPIController from '../../../providers/controllers/PortfolioAPIController';
import { getProjectLocalizedName, addToast } from '../../../Helpers';
import './style.scss';
import InitiativeCard from '../Programs/InitiativeCard';
import ProgramCardLoader from '../Programs/ProgramCardLoader';
import routes from '../../../routes';

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      program: {},
      // customFields: {},
    };
  }

  componentDidMount() {
    this.getProjectByCode();
  }

  getProjectByCode() {
    const { match } = this.props;
    if (match.url.includes('portfolio')) {
      new PortfolioaAPIController()
        .getPortfoiloByCode(match.params.code)
        .then(res => {
          this.setState({
            program: res.Data,
            loading: false,
          });
        })
        .catch(error => {
          addToast.error(error.message, {
            toastId: 'get-project-toast',
          });
        });
    } else {
      new ProgramsAPIController()
        .getProgramByCode(match.params.code)
        .then(res => {
          if (res.StatusCode === 'Success') {
            this.setState({
              program: res.Data,
              loading: false,
              // customFields: cleanCustomFields(res.Data.CustomFields),
            });
          }
        })
        .catch(error => {
          addToast.error(error.message, {
            toastId: 'get-project-toast',
          });
        });
    }
  }

  render() {
    const { program, loading } = this.state;
    const { theme, language, match, user, history } = this.props;

    let breadcrumbData = [];
    const localazationData = { NameArabic: program.NameArabic, NameEnglish: program.NameEnglish };
    if (Object.keys(program).length) {
      const programRoute = {
        title: getProjectLocalizedName(localazationData, language.locale),
        location: `/programs-details/${program.Code}`,
      };
      breadcrumbData = [programRoute];
    }
    const ProjectsPhases = theme.Project_Phases.item.Items;
    return (
      <div className="content-container program-details">
        <Route exact path={match.path}>
          <Redirect to={routes.preview(match.url)} />
        </Route>
        <Breadcrumbs
          title={match.url.includes('portfolio') ? 'portfolio-details-bread-title' : 'program-details-bread-title'}
          routes={breadcrumbData}
        />
        <div className="content-body">
          {loading ? (
            <ProgramCardLoader themeColor={theme.ThemeColor.item.text} />
          ) : (
            <InitiativeCard
              key={program.Code}
              program={program}
              onStatusFilterChange={this.handleFilterByStatus}
              theme={theme}
              ProjectsPhases={ProjectsPhases}
              language={language}
              getProjectsNumForStatus={this.getProjectsNumForStatus}
              details
            />
          )}
          <ProgramData
            loading={loading}
            program={program}
            theme={theme}
            locale={language.locale}
            match={match}
            user={user}
            history={history}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /* , ownProps */) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ProjectDetails);
