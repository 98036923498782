import React, { Component } from 'react';
import Navigator from '../Navigator';
import ProjectIssues from './ProjectIssuesMain';
import ProjectIssuesDetails from './ProjectIssuesDetails';
import ProjectIssuesAdd from './ProjectIssuesAdd';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import Escalation from '../Escalations';
import ActionLogPage from '../Escalations/ActionLogPage';
import routes from '../../routes';
import { addToast } from '../../Helpers';

class ProjectIssuesNavigator extends Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.state = {
      selectedComponentName: 'main',
      issuesList: [],
      issuesDetails: null,
      loading: true,
      isActive: false,
    };
  }

  componentDidMount() {
    this.getProjectIssus();
  }

  getProjectIssus() {
    const { match } = this.props;
    new ProjectAPIController()
      .getProjectIssues(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({ issuesList: res.Data, loading: false });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-project-error',
        });
      });
  }

  handleIssueUpdation = () => {
    this.setState({ loading: true, selectedComponentName: 'main', issuesList: [] }, () => {
      this.getProjectIssus();
    });
  };

  changeView(view, id, code) {
    const { history, match } = this.props;
    history.push(routes[view](match.url, id, code));
  }

  render() {
    const { selectedComponentName, loading, issuesList, issuesDetails, isActive } = this.state;
    const { theme, locale, match, projectDetails, user, program, history } = this.props;
    const issueDetails = issuesList.filter(item => item.ID === parseInt(window.location.href.split('/').pop(), 10));

    const componentsList = [
      {
        id: 'details',
        name: 'details',
        component: ProjectIssuesDetails,
        props: {
          issuesDetails,
          issuesList,
          theme,
          user,
          locale,
          history,
          changeView: this.changeView,
          onCloseIssue: this.handleIssueUpdation,
          projectDetails,
        },
      },
      {
        id: 'escalations',
        name: 'escalations',
        component: Escalation,
        props: {
          theme,
          locale,
          issuesList,
          user,
          isActive,
          riskIssueData: issueDetails ? issueDetails[0] : {},
          escalationType: 'Issues',
          getAllData: this.handleIssueUpdation,
          changeView: this.changeView,
        },
      },
      {
        id: 'actionLog',
        name: 'actionLog',
        component: ActionLogPage,
        props: {
          theme,
          locale,
          issuesList,
          user,
          isActive,
          riskIssueData: issueDetails ? issueDetails[0] : {},
          escalationType: 'Issues',
          getAllData: this.handleIssueUpdation,
          changeView: this.changeView,
        },
      },
      {
        id: 'add',
        name: 'add',
        component: ProjectIssuesAdd,
        props: {
          mode: false,
          theme,
          locale,
          changeView: this.changeView,
          onAddIssue: this.handleIssueUpdation,
          projectDetails,
          program,
        },
      },
      {
        id: 'edit',
        name: 'edit',
        component: ProjectIssuesAdd,
        props: {
          mode: true,
          theme,
          issuesDetails,
          issuesList,
          locale,
          changeView: this.changeView,
          onAddIssue: this.handleIssueUpdation,
          projectDetails,
          program,
        },
      },
      {
        id: 'base',
        name: 'main',
        component: ProjectIssues,
        props: {
          loading,
          theme,
          locale,
          user,
          changeView: this.changeView,
          issuesList,
        },
      },
    ];
    if (loading)
      return (
        <ProjectIssues
          changeView={this.changeView}
          issuesList={issuesList}
          loading={loading}
          theme={theme}
          locale={locale}
          user={user}
          match={match}
        />
      );
    return (
      <>
        <Navigator match={match} componentsList={componentsList} selectedComponentName={selectedComponentName} />
      </>
    );
  }
}

export default ProjectIssuesNavigator;
