// import React from 'react';
// import InitiativeStageGate from '../components/StageGateDashboards/Initiatives';
// import FundingSourcesDashboard from '../components/FundingSourcesDashboard';
// import ProjectStageGate from '../components/StageGateDashboards/Projects';
// import FundingProcurementDashboard from '../containers/Views/FundingProcurementDashboard';
// import InitiativeRoadmap from '../components/InitiativeRoadmap';
// import DashboardCapexopex from '../containers/Views/DashboardCapexopex';
import DashboardInitiative from '../components/DashboardInitiative';
import ProjectStageGate from '../components/StageGateDashboards/Projects';
import CapacityPlannerDashboard from '../containers/Views/CapacityPlannerDashboard';

// import CapacityPlannerDashboard from '../containers/Views/CapacityPlannerDashboard';
import { ReactComponent as DashboardIcon } from '../assets/images/dashboard_icon.svg';
import { ReactComponent as StageGateDashboardIcon } from '../assets/images/stage_gate_dashboard_icon.svg';
// import { ReactComponent as RoadIcon } from '../assets/images/road.svg';
// import { ReactComponent as StageGateDashboardIcon } from '../assets/images/stage_gate_dashboard_icon.svg';

// export const dashboardsRoutes = [
//   {
//     to: 'fundingDashboard',
//     IconComponent: DashboardIcon,
//     localeId: 'common.fundingDashboard',
//     Component: FundingProcurementDashboard,
//   },
//   {
//     to: 'procurementDashboard',
//     IconComponent: DashboardIcon,
//     localeId: 'common.procurementDashboard',
//     Component: FundingProcurementDashboard,
//   },
//   {
//     to: 'initiatives-stage-gate-dashboard',
//     IconComponent: StageGateDashboardIcon,
//     localeId: 'common.initiatives-stage-gate-dashboard',
//     Component: InitiativeStageGate,
//   },
//   {
//     to: 'project-stage-gate-dashboard',
//     IconComponent: StageGateDashboardIcon,
//     localeId: 'common.project-stage-gate-dashboard',
//     Component: ProjectStageGate,
//   },
//   {
//     to: 'initiatives-roadMap',
//     IconComponent: RoadIcon,
//     localeId: 'common.initiatives-roadMap',
//     Component: InitiativeRoadmap,
//     noBreadcrumbs: true,
//   },
//   {
//     to: 'dashboard-CO',
//     IconComponent: DashboardIcon,
//     localeId: 'common.dashboard-CO',
//     Component: DashboardCapexopex,
//   },
//   {
//     to: 'capacity-planner',
//     IconComponent: DashboardIcon,
//     localeId: 'common.capacity-planner',
//     Component: CapacityPlannerDashboard,
//   },
//   {
//     to: 'funding-sources-dashboard',
//     IconComponent: StageGateDashboardIcon,
//     localeId: 'common.funding-sources-dashboard',
//     Component: FundingSourcesDashboard,
//   },
//   {
//     to: 'initiatives',
//     IconComponent: DashboardIcon,
//     localeId: 'common.initiatives',
//     Component: DashboardInitiative,
//   },
// ];
export const dashboardsRoutes = [
  {
    to: 'initiatives',
    IconComponent: DashboardIcon,
    localeId: 'common.programs-dashboard',
    Component: DashboardInitiative,
  },
  {
    to: 'project-stage-gate-dashboard',
    IconComponent: StageGateDashboardIcon,
    localeId: 'common.project-stage-gate-dashboard',
    Component: ProjectStageGate,
  },
  {
    to: 'capacity-planner',
    IconComponent: DashboardIcon,
    localeId: 'common.capacity-planner',
    Component: CapacityPlannerDashboard,
  },
];

const routes = {
  '/': 'Home',
  '/project-details': 'project-details',
  '/portfolio-details': 'portfolio',
};
export default routes;
