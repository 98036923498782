import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
// eslint-disable-next-line import/no-cycle
import File from '../File';
import ImpulseBalls from '../BouncyBalls/ImpulseBalls';
import { ReactComponent as FolderOpen } from '../../assets/images/folder-open.svg';
import { ReactComponent as FolderClosed } from '../../assets/images/folder-closed.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/upload.svg';

import { getLibraryFilesListAction, uploadLibraryFileAction } from '../../actions/docsLibrary';
import { toBase64, addToast } from '../../Helpers';

import './styles.scss';
import attachmentsWhitelist from '../Field/Control/Attachment/filesWhitelist';

class Folder extends React.Component {
  state = {
    opened: false,
  };

  componentDidMount() {
    const { opened } = this.state;
    const { render, defaultOpen } = this.props;

    if (defaultOpen) {
      this.setState({
        opened: true,
      });
    }
    if ((opened || defaultOpen) && !render) {
      this.getLibrary();
    }
  }

  handleFolderClick = () => {
    const { opened } = this.state;
    if (!opened) this.getLibrary();

    this.setState(prevState => ({
      opened: !prevState.opened,
    }));
  };

  handleUpload = async e => {
    const { projectUID, uploadLibraryFileAction: uploadLibraryFile, listType, subFolder, folder } = this.props;
    const file = Array.from(e.target.files)[0];
    if (attachmentsWhitelist.includes(file.type)) {
      const fileEncodedTo64 = await toBase64(file);
      const reqData = {
        projectUID,
        request: {
          FileName: file.name,
          DocumentBytesString: fileEncodedTo64.replace(/^data:(.*,)?/, ''),
          SubFolder: subFolder ? folder : undefined,
        },
      };
      uploadLibraryFile(reqData, listType);
    } else {
      addToast.error('attachment.fileTypeError', {
        toastId: 'upload-limit-error',
      });
    }
  };

  getLibrary = () => {
    const {
      projectUID,
      getLibraryFilesListAction: getLibraryFilesList,
      listType: ListType,
      parentType,
      folder,
      subFolder,
    } = this.props;
    const data = {
      projectUID,
      request: {
        ListType: subFolder ? parentType : ListType,
        Folder: folder,
      },
    };

    getLibraryFilesList(data, ListType);
  };

  render() {
    const { opened } = this.state;
    const { docsLibrary, listType, parentType, title, upload = false, theme, render } = this.props;
    const files = docsLibrary.items[listType] || [];
    const isLoading = docsLibrary.loading[listType] || false;

    return (
      <div className="folder__container">
        <div className="folder__header" onClick={this.handleFolderClick} role="button" tabIndex={0}>
          {opened ? (
            <FolderOpen fill={theme.ThemeColor.item.Text} />
          ) : (
            <FolderClosed fill={theme.ThemeColor.item.Text} />
          )}
          <p>{title}</p>
          <div className="folder__loader">
            <ImpulseBalls size={20} frontColor={theme.ThemeColor.item.Text} loading={isLoading} />
          </div>
        </div>
        {opened && (render || files.length || upload) ? (
          <div className="folder__content">
            {upload ? (
              <div className="folder__upload-attachment">
                <label htmlFor={`${listType}-upload`}>
                  <UploadIcon fill={theme.ThemeColor.item.Text} />
                  <FormattedMessage id="documentsLibrary.attachFile" />
                  <input type="file" id={`${listType}-upload`} onChange={this.handleUpload} />
                </label>
              </div>
            ) : null}
            {render ? render() : null}
            {files.length
              ? files.map((file, i) => (
                  <File
                    key={`${listType}-file-#${i + 1}`}
                    file={file}
                    theme={theme}
                    parentType={parentType || listType}
                  />
                ))
              : null}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ docsLibrary }) => ({
  docsLibrary,
});

const mapDispatchToProps = {
  getLibraryFilesListAction,
  uploadLibraryFileAction,
};

Folder.propTypes = {
  defaultOpen: PropTypes.bool,
  docsLibrary: PropTypes.object.isRequired,
  getLibraryFilesListAction: PropTypes.func.isRequired,
  listType: PropTypes.string,
  projectUID: PropTypes.string.isRequired,
  render: PropTypes.func,
  title: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  upload: PropTypes.bool,
  uploadLibraryFileAction: PropTypes.func.isRequired,
  folder: PropTypes.string,
  subFolder: PropTypes.bool,
  parentType: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
