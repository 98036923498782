import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Field from '../Field';
import Button from '../Button';
import { ReactComponent as CheckIcon } from '../../assets/images/CheckIcon.svg';
import './style.scss';

class AddModal extends React.Component {
  state = {
    attachments: [],
    comment: '',
  };

  render() {
    const { attachments, comment } = this.state;
    const { themeColor, intl, handleSave, phaseID, loading } = this.props;

    return (
      <div className="add-public-modal">
        <div className="text-container">
          <span className="label flex-v-center ">
            <FormattedMessage id="common.comment" />
          </span>
        </div>
        <Field
          control={{
            type: 'textarea',
            onChangeAction: e => this.setState({ comment: e.target.value }),
            placeholder: intl.formatMessage({
              id: 'common.comment',
            }),
            value: comment,
            validateOnExite: true,
            error: !comment.length,
          }}
        />

        <span className="label flex-v-center ">
          <FormattedMessage id="project-issues-add.attachments-label" />
        </span>

        <Field
          control={{
            type: 'attachment',
            onChangeAction: e => {
              this.setState({ attachments: [...e] });
            },
            value: attachments,
            // validation: this.inputValidation,
            validateOnExite: false,
          }}
        />
        <div className="button-container">
          <Button
            color="white"
            backgroundColor={themeColor}
            Icon={() => <CheckIcon fill="white" width="10" height="10" />}
            onClick={() => {
              if (comment.length)
                handleSave({
                  Attachments: attachments,
                  ProjectPhaseItemID: phaseID,
                  Text: comment,
                });
            }}
            loading={loading}
          >
            <FormattedMessage id="common.done" />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps, null)(AddModal));
