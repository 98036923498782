import React from 'react';

import './style.scss';

export default () => (
  <div className="contentLoader__container animated-background">
    <div className="contentLoader__circle shadow-background" />
    <div className="contentLoader__textcontainer">
      <div className="contentLoader__item shadow-background" />
      <div className="contentLoader__item shadow-background" />
      <div className="contentLoader__item shadow-background" />
    </div>
  </div>
);
