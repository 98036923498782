import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';

import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';

import './styles.scss';

const Timeago = ({ date, withIcon = false }) => {
  return (
    <div className="Timeago__container">
      {withIcon ? <ClockIcon /> : null}
      {moment(date).fromNow()}
    </div>
  );
};

Timeago.propTypes = {
  date: PropTypes.string.isRequired,
  withIcon: PropTypes.bool,
};

export default Timeago;
