import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../Button';
import { ReactComponent as BackIcon } from '../../../assets/images/back-arrow.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/info.svg';
import Field from '../../Field';

import { postIssue } from '../../../actions/project';
import { postNotification } from '../../../actions/userActions';

import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import {
  findUser,
  issuesConstants,
  priorityClassificationColor,
  reduceClassificationList,
  reduceUsersList,
  mergeAttachments,
  getProjectLocalizedName,
  addToast,
} from '../../../Helpers';

import './style.scss';

class ProjectIssuesAdd extends Component {
  constructor(props) {
    super(props);
    const {
      match,
      issuesList,
      user: {
        user: { Username },
      },
    } = props;
    let isEdit = props.mode;
    let issuesDetails = null;
    if (isEdit) {
      issuesDetails = issuesList.find(item => item.ID.toString() === match.params.id);
      if (!issuesDetails) {
        props.changeView('base');
      }
      isEdit = isEdit && issuesDetails;
    }
    const Probabilities = reduceClassificationList(props.theme.IssuePriorities.item.Items, props.locale);
    const Categories = reduceClassificationList(props.theme.IssueCategories.item.Items, props.locale);
    const attachments = isEdit ? issuesDetails.Attachments || [] : [];
    const selectedProbabilty = isEdit
      ? Probabilities.find(probabilty => probabilty.value === issuesDetails.Priority)
      : Probabilities[0];
    const selectedCategory = isEdit
      ? Categories.find(category => category.value === issuesDetails.Category)
      : Categories[0];
    this.state = {
      issuesDetails,
      ID: isEdit ? issuesDetails.ID : 0,
      Title: isEdit ? issuesDetails.Title : '',
      Discussion: isEdit ? issuesDetails.Discussion : '',
      Resolution: isEdit ? issuesDetails.Resolution : '',
      DueDate: isEdit
        ? moment(issuesDetails.DueDate)
            .locale('en')
            .valueOf()
        : new Date(),
      AssignedTo: null,
      Owner: isEdit ? issuesDetails.OwnerModel.Username : Username,
      attachments,
      selectedCategory,
      selectedProbabilty,
      users: [],
      mode: props.mode,
      isLoadingData: true,
    };
  }

  componentDidMount() {
    this.findAllUsers();
  }

  throwError = (backendError = false) => {
    addToast.error(backendError ? 'common.errorSavingChanges' : 'common.fillRequiredFields', {
      toastId: 'submit-issue-error',
    });
  };

  validateFields = () => {
    const { Title, Discussion, selectedCategory, selectedProbabilty, Resolution, DueDate } = this.state;

    if (
      !Title.trim() ||
      !Discussion.trim() ||
      !selectedCategory.value ||
      !selectedProbabilty.value ||
      !Resolution.trim() ||
      !DueDate
    ) {
      return false;
    }

    return true;
  };

  save = async () => {
    const {
      props: { match, postIssueAction, onAddIssue, changeView },
      state: {
        Title,
        Discussion,
        selectedCategory,
        Resolution,
        Owner,
        attachments,
        AssignedTo,
        DueDate,
        selectedProbabilty,
        ID,
        mode,
      },
      validateFields,
      throwError,
      pushNotification,
    } = this;

    const isValid = validateFields();

    if (!isValid) throwError();
    else {
      const _attachments = await mergeAttachments(attachments);
      const data = {
        projectUID: match.params.code,
        issue: {
          ID,
          Title,
          ProjectUID: match.params.code,
          Discussion,
          Category: selectedCategory.value,
          Classification: selectedProbabilty.label,
          Resolution,
          Owner,
          Priority: selectedProbabilty.value,
          Status: issuesConstants.statusActive,
          DueDate: moment(DueDate)
            .locale('en')
            .format('DD-MM-YYYY'),
          AssignedTo: AssignedTo.value,
          CategoryText: selectedCategory.value,
          PriorityText: selectedProbabilty.label,
          Attachments: _attachments,
          HasAttachments: _attachments.length > 0,
          editAllow: mode,
          editMode: mode,
          itemType: 'issue',
        },
      };

      postIssueAction(
        data,
        () => {
          onAddIssue();
          changeView('base');
          pushNotification(Title);
          addToast.success('common.changesHaveBeenSaved');
        },
        () => {
          throwError(true);
        },
      );
    }
  };

  pushNotification = title => {
    const {
      match,
      postNotificationAction,
      mode,
      projectDetails,
      program,
      locale,
      user: { user: loggedUser },
    } = this.props;
    const action = mode ? 'modified an' : 'added a new';
    const notification = {
      notification: {
        Body: `${loggedUser.DisplayName} ${action} issue "${title}" on project ${
          program ? getProjectLocalizedName(program, locale) : projectDetails.ProjectName
        }`,
        ProjectUID: match.params.code,
        ReceiverUserName: null,
        Title: loggedUser.DisplayName,
        Type: 1,
      },
    };

    postNotificationAction(notification);
  };

  inputValidation = value => {
    const re = new RegExp(/^[a-zA-Z0-9 ]*$/);
    return !re.test(String(value).toLowerCase()) || !value;
  };

  findAllUsers() {
    const { match, mode } = this.props;
    const { issuesDetails } = this.state;
    const isEdit = mode && issuesDetails;

    new ProjectAPIController()
      .getUsers(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({
            users: reduceUsersList(res.Data),
            AssignedTo: isEdit
              ? findUser(reduceUsersList(res.Data), issuesDetails.AssignedTo.Username, 'value')
              : reduceUsersList(res.Data)[0],
            isLoadingData: false,
          });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-users-error',
        });
      });
  }

  render() {
    const { theme, intl, locale, postingIssue, changeView } = this.props;
    const {
      ID,
      Title,
      Discussion,
      mode,
      selectedProbabilty,
      DueDate,
      AssignedTo,
      isLoadingData,
      users,
      selectedCategory,
      Resolution,
      attachments,
    } = this.state;

    const titleFieldPlaceholder = intl.formatMessage({
      id: 'project-issues-add.title-field-placeholder',
    });
    const detailFieldPlaceholder = intl.formatMessage({
      id: 'project-issues-add.detail-field-placeholder',
    });
    const mitigationPlanPlaceholder = intl.formatMessage({
      id: 'project-issues-add.resolution-plan-placeholder',
    });

    return (
      <section className={`project-issues-add-container ${postingIssue ? 'loading' : ''}`}>
        <div className="sec-header-container tab-header">
          <div
            className="back-button colorBlue cursor"
            onClick={() => (ID ? changeView('details', ID) : changeView('base'))}
          >
            <BackIcon width="15px" height="15px" style={{ fill: theme.ThemeColor.item.Text }} />
          </div>
          <div className="title bold-text">
            <FormattedMessage id="project-issues-add.title" />
          </div>
          <div className="section-actions">
            <Button
              disabled={postingIssue || isLoadingData}
              loading={postingIssue}
              backgroundColor={theme.ThemeColor.item.Text}
              onClick={this.save}
              Icon={() => <CheckIcon fill="#fff" />}
            >
              {mode ? (
                <FormattedMessage id="project-issues-add.update-btn-label" />
              ) : (
                <FormattedMessage id="project-issues-add.save-btn-label" />
              )}
            </Button>
          </div>
        </div>
        <div className="main-content tTab-content-container">
          <div className="form">
            <div className="form-label">
              <span style={{ background: theme.ThemeColor.item.Text }}>1</span>
              <FormattedMessage id="common.details" />
            </div>
            <form>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="project-issues-add.title-field-label" />
                </span>
                <Field
                  control={{
                    type: 'input',
                    onChangeAction: e => this.setState({ Title: e.target.value }),
                    placeholder: titleFieldPlaceholder,
                    value: Title,
                    validation: this.inputValidation,
                    validateOnExite: true,
                    error: !Title.trim(),
                  }}
                />
              </div>
              <div className="control">
                <span className="label">
                  <FormattedMessage id="project-issues-add.detail-field-label" />
                </span>
                <Field
                  control={{
                    type: 'textarea',
                    onChangeAction: e => this.setState({ Discussion: e.target.value }),
                    placeholder: detailFieldPlaceholder,
                    value: Discussion,
                    validation: this.inputValidation,
                    validateOnExite: true,
                    error: !Discussion.trim(),
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="project-issues-add.category-field-label" />
                </span>
                <Field
                  control={{
                    type: 'select',
                    onChangeAction: e => this.setState({ selectedCategory: e }),
                    value: selectedCategory,
                    className: 'users-select-container',
                    classNamePrefix: 'users-select',
                    options: reduceClassificationList(theme.IssueCategories.item.Items, locale),
                    error: !selectedCategory.value,
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="project-issues.impact" />
                </span>
                <Field
                  control={{
                    type: 'select',
                    menuIsOpen: true,
                    onChangeAction: e => this.setState({ selectedProbabilty: e }),
                    value: selectedProbabilty,
                    options: reduceClassificationList(theme.IssuePriorities.item.Items, locale),
                    className: 'probabilty-select-container',
                    classNamePrefix: 'probabilty-select',
                    error: !selectedProbabilty.value,
                  }}
                />
              </div>
              <div className="control">
                <span className="label" />

                <div
                  className="control-info"
                  style={{
                    background: priorityClassificationColor(theme.IssuePriorities.item.Items, selectedProbabilty.value),
                  }}
                >
                  <span>{selectedProbabilty.label}</span>
                  <InfoIcon />
                </div>
              </div>
            </form>
          </div>
          <div className="form">
            <div className="form-label">
              <span style={{ background: theme.ThemeColor.item.Text }}>2</span>
              <FormattedMessage id="project-issues-add.issue-solution-label" />
            </div>
            <form>
              <div className="control">
                <span className="label">
                  <FormattedMessage id="project-issues-add.resolution-plan-label" />
                </span>
                <Field
                  control={{
                    type: 'textarea',
                    onChangeAction: e => this.setState({ Resolution: e.target.value }),
                    placeholder: mitigationPlanPlaceholder,
                    value: Resolution,
                    validateOnExite: true,
                    error: !Resolution.trim(),
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="project-issues-add.due-date-field-label" />
                </span>
                <Field
                  control={{
                    type: 'date',
                    onChangeAction: e => this.setState({ DueDate: e }),
                    locale: locale === 'ar' ? 'ar' : 'en',
                    value: DueDate,
                    validateOnExite: true,
                    error: !DueDate,
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="common.administrator" />
                </span>
                <Field
                  control={{
                    type: 'select',
                    onChangeAction: e => this.setState({ AssignedTo: e }),
                    value: AssignedTo,
                    options: users,
                    className: 'users-select-container',
                    classNamePrefix: 'users-select',
                  }}
                />
              </div>
              <div className="control">
                <span className="label flex-v-center">
                  <FormattedMessage id="project-issues-add.attachments-label" />
                </span>
                <Field
                  control={{
                    type: 'attachment',
                    onChangeAction: e => this.setState({ attachments: [...e] }),
                    value: attachments,
                    // validation: this.inputValidation,
                    validateOnExite: false,
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ user, project }) => ({
  user,
  postingIssue: project.loaders.postIssue,
});

const mapDispatchToProps = {
  postIssueAction: postIssue,
  postNotificationAction: postNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ProjectIssuesAdd));
