import React from 'react';
import socket from 'socket.io-client';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Widget, addResponseMessage, renderCustomComponent, dropMessages } from 'react-chat-widget';
import { FarisUrl } from '../../providers/config/urlServiceConfig';

import Message from './Message';
import ProjectsCarousel from './ProjectsCarousel';
import BotOptions from './BotOptions';

import 'react-chat-widget/lib/styles.css';
import './styles.scss';

const options = {
  transports: ['polling'],
  forceBase64: true,
  path: '/socket.io/',
  upgrade: true,
  timestampRequests: false,
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax: 500,
};

class ChatBot extends React.Component {
  constructor(props) {
    super(props);
    if (FarisUrl) {
      this.socket = socket(FarisUrl, options);
    }
  }

  componentDidMount() {
    if (this.socket) {
      this.socket.on('connect', () => {
        this.setUserUtteredConnection();
      });

      this.socket.on('reconnect', () => {
        this.setUserUtteredConnection();
        dropMessages();
      });

      this.socket.on('bot_uttered', message => {
        this.handleNewBotMessage(message);
      });
    }
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  }

  getMessageStructure = (message, firstMessage) => {
    const { user } = this.props;
    const token = localStorage.getItem('token');
    let data = {
      message,
      session_id: this.socket.id,
    };

    if (firstMessage) {
      data = {
        ...data,
        token,
        display_name: user.user.DisplayName,
        username: user.user.Username,
      };
    }

    return data;
  };

  getIsMessageParsable = newMessage => {
    return /^[\],:{}\s]*$/.test(
      newMessage.text
        .replace(/\\["\\/bfnrtu]/g, '@')
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']')
        .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
    );
  };

  getParsedMessage = newMessage => {
    return JSON.parse(newMessage.text);
  };

  getUserUttered = () => {
    return localStorage.getItem('userUttered');
  };

  setUserUttered = () => {
    localStorage.setItem('userUttered', true);
  };

  setUserUtteredConnection = () => {
    const dictData = this.getMessageStructure('hi', true);
    this.socket.emit('user_uttered', dictData);
  };

  handleNewBotMessage = newMessage => {
    const { user } = this.props;
    const userUttered = this.getUserUttered();
    if (newMessage.text.startsWith(`Hi **${user.user.DisplayName}**`) && userUttered) {
      return;
    }
    this.setUserUttered();

    const optionsProvided = Object.prototype.hasOwnProperty.call(newMessage, 'quick_replies');

    if (optionsProvided) {
      renderCustomComponent(() => (
        <BotOptions
          text={newMessage.text}
          options={newMessage.quick_replies}
          onOptionSelect={this.handleOptionSelect}
        />
      ));
    } else if (this.getIsMessageParsable(newMessage)) {
      const { text, type, data } = this.getParsedMessage(newMessage);
      if (type === 'slider') {
        const projects = (data || []).filter(item => item && item.title);
        if (!projects.length) {
          renderCustomComponent(() => (
            <Message message={{ text: 'Sorry, un-able to find any projects, try again later!' }} />
          ));
        }
        addResponseMessage(text);
        renderCustomComponent(() => <ProjectsCarousel projects={projects} onSelect={this.handleProjectSelect} />);
      }
    } else {
      renderCustomComponent(() => <Message message={newMessage} />);
    }
  };

  handleNewUserMessage = newMessage => {
    this.socket.emit('user_uttered', this.getMessageStructure(newMessage));
  };

  handleProjectSelect = project => {
    this.handleNewUserMessage(project.payload);
  };

  handleOptionSelect = task => {
    this.handleNewUserMessage(task.payload);
  };

  render() {
    const {
      intl: { formatMessage },
      themeColor,
      language,
    } = this.props;
    const senderPlaceHolder = `${formatMessage({ id: 'common.type-a-message' })}...`;

    if (!FarisUrl) return null;

    return (
      <div
        className={css`
          .rcw-launcher {
            background-color: ${themeColor};
          }
          .rcw-send-icon {
            transform: ${language.locale === 'en' ? 'unset' : 'rotate(180deg)'};
          }
          .rcw-header {
            background-color: ${themeColor};
          }
          .rcw-client {
            margin-left: ${language.locale === 'en' ? 'auto' : 'unset'};
            background-color: ${themeColor}55;
            direction: ltr;
          }
          .message__container {
            margin-right: ${language.locale === 'ar' ? 'auto' : 'unset'};

            p {
              direction: ltr;
            }
          }

          .rcw-widget-container {
            right: ${language.locale === 'en' ? '0' : 'unset'};
            left: ${language.locale === 'en' ? 'unset' : '10px'};
          }
        `}
      >
        <Widget
          senderPlaceHolder={senderPlaceHolder}
          title=""
          subtitle="FARIS"
          handleNewUserMessage={this.handleNewUserMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user, theme, language }) => ({
  user,
  language,
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps)(ChatBot));
