import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { filterIssues } from '../../Helpers';
import ProjectIssuesMain from '../ProjectIssues/ProjectIssuesMain';

function Issues({ theme, locale, projectCode }) {
  const [loading, setLoading] = useState(true);
  const [issues, setIssues] = useState([]);

  async function getIssues() {
    try {
      const res = await new ProjectAPIController().getProjectIssues(projectCode);
      setIssues(res.Data);
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getIssues();
  }, [projectCode]);

  return (
    <div className="dashboard-tabs-section__issues">
      <ProjectIssuesMain issuesList={filterIssues(issues)} theme={theme} locale={locale} loading={loading} readOnly />
    </div>
  );
}

const mapStateToProps = ({ theme, language }) => ({
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(Issues);
