import React from 'react';

import { FormattedMessage } from 'react-intl';
import UserCard from '../UserCard';
import ProjectDataType from '../ProjectDataType';
import ProjectPreviewLoader from './ProjectPreviewLoader';

import { getProjectCardField } from '../../Helpers';

import './style.scss';

function ProgramPreview(props) {
  const { program, theme, locale, loading, match } = props;

  if (loading) return <ProjectPreviewLoader />;

  const ProjectCard = getProjectCardField(
    match.url.includes('portfolio') ? theme.Portfolio_Card_Fields.item.Items : theme.Program_Card_Fields.item.Items,
    program.CustomFields,
  );

  return (
    <section className="project-preview-container">
      <div className="title bold-text">
        <FormattedMessage id="project.details-preview" />
      </div>
      <UserCard user={program.Manager} />
      <div className="secondary-content tTab-content-container">
        {ProjectCard.length > 0 ? (
          <div>
            {ProjectCard.map((item, i) => (
              <ProjectDataType type={item.Style} key={i} data={item} locale={locale} />
            ))}
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default ProgramPreview;
