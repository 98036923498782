import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';

const PhasesChart = props => {
  const { data, ProjectsCount, language, theme } = props;
  const centerDataStyle = {
    position: 'absolute',
    top: '50%',
    right: '50%',
    maxWidth: '60%',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translate(50%, -50%)',
  };

  const countStyle = {
    textAlign: 'center',
  };

  const phasesItems =
    language.locale === 'ar' ? [...theme.ProjectPhases.item.Items].reverse() : [...theme.ProjectPhases.item.Items];

  return (
    <div className="phases-chart-container">
      <div className="dashboard__doughnut-charts" style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ margin: 'auto 15px', position: 'relative' }}>
          <Doughnut
            data={{
              labels: phasesItems.map(item => (language.locale === 'ar' ? item.Arabic : item.English)),
              datasets: [
                {
                  label: 'Initiative by phases',
                  fill: true,
                  backgroundColor: phasesItems.map(item => item.Style),
                  borderColor: '#fff',
                  data: phasesItems.map(element => {
                    return data[element.Value]?.length || 0;
                  }),
                },
              ],
            }}
            width={350}
            height={320}
            options={{
              legend: {
                display: false,
              },
              plugins: {
                datalabels: {
                  align: language.locale === 'ar' ? 'right' : 'center',

                  color: '#FFFFFF',
                  padding: {
                    right: -2,
                  },
                  font: {
                    size: 16,
                    weight: 600,
                  },
                },
              },

              maintainAspectRatio: false,
              responsive: true,
              cutoutPercentage: 70,
            }}
          />
          <div style={centerDataStyle}>
            <div style={countStyle}>
              <div className="center-count">{ProjectsCount}</div>
            </div>
          </div>
        </div>
        <div className={`legends-container${language.locale === 'ar' ? ' row-reverse' : ''}`}>
          {phasesItems.map(item => (
            <span key={item.Id} className="legend">
              <span className="legend-square" style={{ backgroundColor: item.Style.trim() }} />
              {language.locale === 'ar' ? item.Arabic : item.English}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ theme, language }) => {
  return {
    theme,
    language,
  };
};

export default connect(mapStateToProps)(PhasesChart);
