import UrlAPIControllers from '../config/urlServiceConfig';
import HttpServiceConfig from '../config/httpServiceConfig';

export default class DashboardAPIController {
  getAllEntities = () => {
    const FETCH_URL = new UrlAPIControllers().allEntitiesURL();
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getAllPrograms = () => {
    const FETCH_URL = new UrlAPIControllers().allProgramsURL();
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getAllProjects = () => {
    const FETCH_URL = new UrlAPIControllers().allProjectsURL();
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getAllRisks = () => {
    const FETCH_URL = new UrlAPIControllers().getAllRisks();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getAllIssues = () => {
    const FETCH_URL = new UrlAPIControllers().getAllIssues();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getDashboardPrograms = () => {
    const FETCH_URL = new UrlAPIControllers().dashboardsPrograms();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getIntiativePhaseGateInfo = () => {
    const FETCH_URL = new UrlAPIControllers().getIntiativePhaseGateInfoURL();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectPhaseGateInfo = () => {
    const FETCH_URL = new UrlAPIControllers().getProjectPhaseGateInfoURL();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getIntiativePhaseGateSchema = () => {
    const FETCH_URL = new UrlAPIControllers().getIntiativePhaseGateSchemaURL();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectPhaseGateSchema = () => {
    const FETCH_URL = new UrlAPIControllers().getProjectPhaseGateSchemaURL();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getFundingSourcesInitiatives = () => {
    const FETCH_URL = new UrlAPIControllers().getFundingSourcesInitiativesURL();

    return new HttpServiceConfig().get(FETCH_URL);
  };
}
