import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Modal from '../Modal';
import './Modal.scss';

const PhaseCheckListModal = props => {
  const { data, theme, language, isOpen, onClose, intl } = props;
  const currentPhase = data.Phases.find(item => item.PhaseID === data.PhaseID);

  return (
    <Modal
      title={intl.formatMessage(
        { id: 'stage-gate-dashboard.holded-phases-title' },
        {
          projectName: language.locale === 'ar' ? data.NameArabic : data.NameEnglish,
          phaseTitle: language.locale === 'ar' ? data.PhaseNameAr : data.PhaseName,
        },
      )}
      isOpen={isOpen}
      onClose={() => onClose()}
      withoutPadding
    >
      <div className="phase-check-list-modal">
        {currentPhase.CheckListItems.map(item => (
          <div className="phase-check-list-item" key={`${currentPhase.PhaseID}_${item.ItemID}`}>
            <span
              className={`review-check-item${item.IsChecked ? ' checked' : ''}`}
              style={item.IsChecked ? { backgroundColor: theme.ThemeColor.item.Text, border: 'none' } : {}}
            />
            {language.locale === 'ar' ? item.TitleArabic : item.Title}
          </div>
        ))}
      </div>
    </Modal>
  );
};
const mapStateToProps = ({ theme, language }) => {
  return {
    theme,
    language,
  };
};
export default connect(mapStateToProps)(injectIntl(PhaseCheckListModal));
