import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { ReactComponent as BackIcon } from '../../assets/images/back-arrow.svg';
import { ReactComponent as EscalationIcon } from '../../assets/images/esc.svg';
import { ReactComponent as PowerIcon } from '../../assets/images/power.svg';
import Button from '../Button';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import Field from '../Field';
import RiskIssueModalLoading from '../EscalationsLoader';
import { checkOwnerUser, sortSteps, addToast, getEscalationStatus } from '../../Helpers';
import VerticalSteps from '../VerticalSteps';
import ActionsLog from '../ActionsLog';
import './style.scss';

class Escalations extends Component {
  state = {
    Description: '',
    laoding: true,
    escalatedData: {},
    Steps: [],
    submitLoading: false,
    closeLoading: false,
  };

  componentDidMount() {
    const { match } = this.props;
    if (!match.params.id) {
      this.getEscalationSteps();
    } else {
      this.getEscalation();
    }
  }

  onSubmitEscalation() {
    const { match } = this.props;
    this.setState({ submitLoading: true });
    if (match.params.id) {
      this.onEscalatedUp();
    } else {
      this.onAddEscalation();
    }
  }

  onEscalatedUp() {
    const { changeView, match } = this.props;
    const { escalatedData } = this.state;
    new ProjectAPIController()
      .escalationSubmit(escalatedData.ID)
      .then(res => {
        if (res) {
          this.setState({ submitLoading: false });
          addToast.success('common.changesHaveBeenSaved');
          changeView('details', match.params.code);
        }
      })
      .catch(() => {
        addToast.error('toast.error', {
          toastId: 'on-escalated-up-error',
        });
      });
  }

  onAddEscalation() {
    const {
      changeView,
      escalationType,
      user: { user },
      match,
    } = this.props;
    const projectUID = match.url.split('/')[2];

    const { Description } = this.state;
    const data = {
      escalation: {
        Description,
        EscalatedBy: user.Username,
        OpenDateString: null,
        ProjectUID: projectUID,
        RelatedItemID: 1,
        Status: '1',
        Title: Description,
      },
    };
    new ProjectAPIController()
      .addEscalation(projectUID, escalationType, match.params.code, data)
      .then(res => {
        if (res) {
          this.setState({ submitLoading: false });
          addToast.success('common.changesHaveBeenSaved');
          changeView('details', match.params.code);
        }
      })
      .catch(() => {
        addToast.error('toast.error', {
          toastId: 'add-escalation-error',
        });
      });
  }

  onCloseEscalation(id) {
    const { changeView, match } = this.props;
    this.setState({ closeLoading: true });
    new ProjectAPIController()
      .closeEscalation(id)
      .then(res => {
        if (res) {
          addToast.success('common.changesHaveBeenSaved');
          changeView('details', match.params.code);
        }
      })
      .catch(() => {
        addToast.error('toast.error', {
          toastId: 'add-escalation-error',
        });
      })
      .finally(() => {
        this.setState({ closeLoading: false });
      });
  }

  getEscalation = () => {
    const { match } = this.props;

    this.setState({ laoding: true }, () => {
      new ProjectAPIController()
        .getEscalation(match.params.id)
        .then(res => {
          this.setState({
            laoding: false,
            escalatedData: {
              ...res.Data,
              Steps: sortSteps(res.Data.Steps),
            },
          });
        })
        .catch(() => {
          addToast.error('toast.error', {
            toastId: 'get-escalation-error',
          });
        });
    });
  };

  getEscalationSteps() {
    const { escalationType } = this.props;
    this.setState({ laoding: true }, () => {
      new ProjectAPIController()
        .getEscalationStep(escalationType)
        .then(res => {
          this.setState({ Steps: sortSteps(res.Data), laoding: false });
        })
        .catch(() => {
          addToast.error('toast.error', {
            toastId: 'get-escalation-steps-error',
          });
        });
    });
  }

  render() {
    const {
      changeView,
      wrapperClassname = '',
      modalView,
      theme,
      locale,
      intl,
      match,
      user: { user },
    } = this.props;
    const { Description, laoding, escalatedData, Steps, submitLoading, closeLoading } = this.state;
    const detailFieldPlaceholder = intl.formatMessage({
      id: 'project-details.escalated.spport-placeholder',
    });
    if (laoding) return <RiskIssueModalLoading />;
    const isOwner = match.params.id
      ? checkOwnerUser(escalatedData.Steps, user.Username, escalatedData.CurrentStep)
      : true;
    const isClosed = escalatedData.Status === 3;
    const showEscalateUp =
      !match.params.id || (!isClosed && escalatedData.Steps && escalatedData.CurrentStep < escalatedData.Steps.length);
    return (
      <section className={`project-escalations-container ${wrapperClassname}`}>
        {!modalView ? (
          <div className="sec-header-container tab-header">
            <div className="back-button colorBlue cursor" onClick={() => changeView('details', match.params.code)}>
              <BackIcon width="15px" height="15px" fill={theme.ThemeColor.item.Text} />
            </div>
            <div className="title bold-text">
              <FormattedMessage id="project-details.escalation" />
            </div>
            {isOwner ? (
              <div className="section-actions">
                {match.params.id && !isClosed ? (
                  <Button
                    type="button"
                    style={{ borderColor: theme.ThemeColor.item.Text, color: theme.ThemeColor.item.Text }}
                    className="secondary-action action"
                    onClick={() => this.onCloseEscalation(escalatedData.ID)}
                    loading={closeLoading}
                    border
                  >
                    <PowerIcon className="action-icon" width="11px" height="11px" />{' '}
                    <FormattedMessage id="project-details.close.escaltion" />
                  </Button>
                ) : null}
                {showEscalateUp ? (
                  <Button
                    backgroundColor={theme.ThemeColor.item.Text}
                    className="action"
                    disabled={!match.params.id && !Description}
                    loading={submitLoading}
                    onClick={() => this.onSubmitEscalation()}
                  >
                    <EscalationIcon className="action-icon" width="11px" height="11px" />
                    <FormattedMessage id="project-details.escalation-up" />
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="main-content">
          <div className="esc-items-container">
            <div className="esc-items">
              <div className="item">
                <div className="label">
                  <FormattedMessage id="project-details.escalation-date" />
                </div>
                <div className="data">
                  {moment(escalatedData.CreatedDate ? escalatedData.CreatedDate : new Date()).format('DD MMMM YYYY')}
                </div>
              </div>
              <div className="item">
                <div className="label">
                  <FormattedMessage id="project-details.escalated-by" />
                </div>
                <div className="data">
                  {escalatedData.EscalatedByUserModel
                    ? escalatedData.EscalatedByUserModel.DisplayName
                    : user.DisplayName}
                </div>
              </div>
              <div className="item">
                <div className="label">
                  <FormattedMessage id="project-details.escalated.status" />
                </div>
                <div className="data">
                  {escalatedData.Status ? (
                    <FormattedMessage id={getEscalationStatus(escalatedData.Status)} />
                  ) : (
                    <FormattedMessage id="status-Open" />
                  )}
                </div>
              </div>
            </div>
            <div className="main-line" />
            <div className="esc-items">
              <div className="item full-size">
                <div className="label">
                  <FormattedMessage id="project-details.escalated.spport" />
                </div>
                {match.params.id ? (
                  <div className="description">{escalatedData.Description ? escalatedData.Description : 'N/A'} </div>
                ) : (
                  <Field
                    control={{
                      type: 'textarea',
                      onChangeAction: e => this.setState({ Description: e.target.value }),
                      placeholder: detailFieldPlaceholder,
                      value: Description,
                      validateOnExite: false,
                      disabled: match.params.id,
                      // error: !Description.trim().length,
                    }}
                  />
                )}
              </div>
            </div>
            {match.params.id ? (
              <div className="esc-actions">
                <ActionsLog
                  comments={escalatedData.Log}
                  escalatedData={escalatedData}
                  escalationID={escalatedData.ID}
                  allowComment={isOwner}
                  changeView={changeView}
                  riskIssueId={match.params.code}
                  id={match.params.id}
                />
              </div>
            ) : null}
          </div>
          <div className="steps-container">
            <VerticalSteps
              steps={match.params.id ? escalatedData.Steps : Steps}
              currentStep={escalatedData.CurrentStep}
              locale={locale}
              isClosed={isClosed}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default injectIntl(Escalations);
