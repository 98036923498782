import React from 'react';

import BouncyBalls from '../../../BouncyBalls';
import { ReactComponent as ThumbsUpIcon } from '../../../../assets/images/thumbs-up-icon.svg';

import './styles.scss';

class Controllers extends React.Component {
  state = {
    loading: false,
  };

  handleAction = (e, actionType) => {
    const { removeControllersLoading } = this.props;
    e.stopPropagation();

    const {
      _postTaskStatus,
      data: { ID, PercentComplete, DraftPercentage },
      type,
      onCloseModal,
      isEventModal,
    } = this.props;

    this.setState({ loading: true }, () => {
      let actionID = actionType === 'approve' ? 3 : 4;
      let body = {
        task: {
          ID,
          PercentComplete,
        },
        actionID,
      };
      if (type === 'nonProjectsTasks') {
        actionID = actionType === 'approve' ? 4 : 5;
        body = {
          data: {
            DraftPercentage,
            ID,
            actionID,
          },
        };
      }

      if (isEventModal) {
        _postTaskStatus(body, type, removeControllersLoading, onCloseModal);
      } else {
        _postTaskStatus(body, type, removeControllersLoading);
      }
    });
  };

  handlePhaseAction = (e, actionType) => {
    const { data } = this.props;
    e.stopPropagation();
    this.setState(
      {
        loading: true,
      },
      () => {
        const {
          _postTaskStatus,
          data: { ID },
        } = this.props;
        const statusID = actionType === 'approve' ? 4 : 5;
        const body = {
          id: ID,
          statusID,
        };

        _postTaskStatus(body, data.ProjectUID);
      },
    );
  };

  render() {
    const {
      state: { loading },
      props: { themeColor, phase, controllersLoading },
    } = this;

    const handler = phase ? this.handlePhaseAction : this.handleAction;

    return (
      <div className="controllers__container">
        {controllersLoading || loading ? (
          <div>
            <BouncyBalls color={themeColor} loading />
          </div>
        ) : (
          <>
            <ThumbsUpIcon fill="green" onClick={e => handler(e, 'approve')} />
            |
            <ThumbsUpIcon className="controllers__thumbs-down" fill="red" onClick={e => handler(e, 'decline')} />
          </>
        )}
      </div>
    );
  }
}

export default Controllers;
