import React, { Component } from 'react';

import './style.scss';

class TextArea extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromProps(props, state) {
    const { error } = state;
    const { settings } = props;
    if (settings.error !== error) {
      return {
        ...state,
        error: settings.error,
      };
    }
    return state;
  }

  defaultValidation = value => {
    return !value;
  };

  showError(show) {
    this.setState({ error: show });
  }

  render() {
    const {
      props: {
        settings: { value, onChangeAction, placeholder, validation, validateOnExite, disabled },
      },
      state: { error },
    } = this;
    const _validation = validation || this.defaultValidation;

    return (
      <div className="textarea-container">
        <textarea
          className={`input-control ${error ? 'error' : ''}`}
          value={value}
          onChange={e => onChangeAction(e)}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          onBlur={validateOnExite ? () => this.showError(_validation(value)) : null}
        />
      </div>
    );
  }
}

export default TextArea;
