import React from 'react';

import './style.scss';

export default () => (
  <div className="riskIssueLoader__container animated-background">
    <div className="riskIssueLoader__status">
      <p className="shadow-background" />
    </div>
    <p className="riskIssueLoader__title shadow-background" />
  </div>
);
