import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../../routes';

function Navigator({ componentsList, match = {} }) {
  return (
    <div>
      <Switch>
        {componentsList.map(item => {
          return (
            <Route
              key={item.id}
              name={item.name}
              path={routes[item.id](match.path)}
              render={props => <item.component {...props} {...item.props} />}
            />
          );
        })}
      </Switch>
    </div>
  );
}

export default Navigator;
