import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ImpulseBalls from '../BouncyBalls/ImpulseBalls';
import UserIconURL from '../../assets/images/user.svg';
import { ATTACHMENTS_URL } from '../../providers/config/urlServiceConfig';
import AttachmentsAPIController from '../../providers/controllers/AttachmentsAPIController';
import './style.scss';

const Avatar = ({ username, user, width = 30, themeColor }) => {
  const [img, setImg] = useState(UserIconURL);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    new AttachmentsAPIController()
      .getAttachment(`${ATTACHMENTS_URL}user/${username || user.Username}`, username || user.Username)
      .then(imgUrl => {
        setImg(imgUrl);
        setIsLoading(false);
      });
  }, [username, user]);

  return (
    <div className="avatar-container" style={{ width, height: width }}>
      {isLoading ? (
        <ImpulseBalls size={width / 2} frontColor={themeColor} loading />
      ) : (
        <img src={img} onError={() => setImg(UserIconURL)} alt="img" style={{ width, height: width }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(Avatar);
