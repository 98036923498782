import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import Field from '../../../components/Field';
import Button from '../../../components/Button';
import ProjectPreviewLoader from './ProjectPreviewLoader';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import ProgramsAPIController from '../../../providers/controllers/ProgramsAPIController';
import PortfolioaAPIController from '../../../providers/controllers/PortfolioAPIController';
import { reduceUsersList, getProjectLocalizedName, addToast } from '../../../Helpers';

import './style.scss';

const nameRegex = /[!@#$%^&*(),.?":{}|<>=]/g;

class AddProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      Name: '',
      NameArabic: '',
      Description: '',
      Portfolio: '',
      Program: '',
      StartDateValue: new Date(),
      Budget: '',
      Manager: '',
      Owner: '',
      Sponsor: '',
      users: [],
      portfolios: [],
      programs: [],
      data: null,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.getGeneralData().then(() => {
      if (match.params.id) {
        this.getNewProjectData(match.params.id);
      }
    });
  }

  getGeneralData = async () => {
    return Promise.all([this.findAllUsers(), this.getAllPortfolios()]);
  };

  getNewProjectData = id => {
    const { history, intl } = this.props;
    new ProjectAPIController().getNewProjectURL(id).then(res => {
      const item = res.Data;
      const { portfolios, users } = this.state;

      if (res.Data.StatusID !== 0) {
        history.replace(`/processes/newproject/${item.ID}`);
      }

      this.getAllPrograms(item.Data.Portfolio);

      this.setState({
        data: item,
        Name: getProjectLocalizedName(item, intl?.locale),
        Description: item.Data.Description,
        Portfolio: portfolios.find(data => {
          return data.Code === item.Data.Portfolio;
        }),

        StartDateValue: moment(item.Data.StartDate),
        NameArabic: item.Data.NameArabic,

        Manager: users.find(user => {
          return user.value === item.Data.Manager;
        }),
        Owner: users.find(user => {
          return user.value === item.Data.Owner;
        }),
        Sponsor: users.find(user => {
          return user.value === item.Data.Sponsor;
        }),
        Budget: JSON.stringify(item.Data.Budget),
      });
    });
  };

  validateName = name => {
    return !name?.trim().length || Boolean(name.match(nameRegex));
  };

  validateFields = () => {
    const {
      Name,
      NameArabic,
      Sponsor,
      Description,
      Portfolio,
      Program,
      Budget,
      Manager,
      Owner,
      data,
      programs,
    } = this.state;
    const isProgramValid =
      data &&
      programs.length > 0 &&
      programs.find(program => {
        return program.Code === data.Data.Program;
      });

    const programValidate = isProgramValid ? !isProgramValid : !Program;
    if (
      !Name?.trim() ||
      Name.match(nameRegex) ||
      !NameArabic?.trim() ||
      NameArabic.match(nameRegex) ||
      !Description?.trim() ||
      !Portfolio ||
      programValidate ||
      !Budget?.trim() ||
      !Manager ||
      !Owner ||
      !Sponsor
    ) {
      return false;
    }

    return true;
  };

  handleChange = e => {
    const { name, value } = e.target;
    if (!value.match(nameRegex)) {
      this.setState({ [name]: value });
    }
  };

  findAllUsers = async () => {
    try {
      const user = await new ProjectAPIController().getAllUsers();
      if (user.StatusCode === 'Success') {
        this.setState({
          users: reduceUsersList(user.Data),
        });
      }
    } catch (error) {
      addToast.error(error.message, {
        toastId: 'get-users-error',
      });
    }
  };

  getAllPortfolios = async () => {
    try {
      const portfolio = await new PortfolioaAPIController().getAllPortfolio();
      if (portfolio.StatusCode === 'Success') {
        this.setState({ portfolios: portfolio.Data });
      }
    } catch (error) {
      addToast.error(error.message, {
        toastId: 'get-entities',
      });
    }
  };

  getAllPrograms = portfolioID => {
    new ProgramsAPIController()
      .getAllPrograms(portfolioID)
      .then(res => {
        if (res.StatusCode === 'Success') {
          const programs = res.Data || [];
          this.setState({ programs });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-projects-toast',
        });
      });
  };

  getApiData = () => {
    const {
      Name,
      NameArabic,
      Description,
      Portfolio,
      Program,
      StartDateValue,
      Budget,
      Manager,
      Owner,
      Sponsor,
      data,
      programs,
    } = this.state;
    const { match } = this.props;
    const isProgramValid =
      data &&
      programs.length > 0 &&
      programs.find(program => {
        return program.Code === data.Data.Program;
      });

    const apiData = {
      Name,
      NameArabic,
      Description,
      StartDateValue: moment(StartDateValue)
        .locale('en')
        .format('DD-MM-YYYY'),
      Portfolio: Portfolio.ProjectUID,
      Program: Program.ProjectUID || (isProgramValid && isProgramValid.Code),
      Budget: parseInt(Budget, 10),
      Manager: Manager.value,
      Owner: Owner.value,
      Sponsor: Sponsor.value,
      ProjectLifeCycle: 'Initiation',
    };
    if (match.params.id) {
      apiData.RequestID = parseInt(match.params.id, 10);
    }
    return apiData;
  };

  submit = ID => {
    const { history } = this.props;

    this.setState({ isSubmitting: true }, () => {
      new ProjectAPIController()
        .submitNewProject(ID)
        .then(res => {
          if (res.StatusCode === 'Success') {
            addToast.success('common.changesHaveBeenSaved');
            history.replace('/');
          }
        })
        .catch(error => {
          addToast.error(error.message, {
            toastId: 'get-users-error',
          });
          this.setState({ isSubmitting: false });
        });
    });
  };

  save = submit => {
    if (this.validateFields()) {
      const { history } = this.props;
      this.setState({ isSubmitting: true }, () => {
        new ProjectAPIController()
          .saveNewProject({
            formData: this.getApiData(),
          })
          .then(res => {
            if (res.StatusCode === 'Success') {
              if (submit) {
                this.submit(res.Data);
              } else {
                history.replace('/');
              }
              addToast.success('common.changesHaveBeenSaved');
            }
          })
          .catch(error => {
            addToast.error(error.message, {
              toastId: 'get-users-error',
            });
            this.setState({ isSubmitting: false });
          });
      });
    }
  };

  render() {
    const {
      Name,
      NameArabic,
      Description,
      Portfolio,
      Program,
      StartDateValue,
      Budget,
      Manager,
      Owner,
      Sponsor,

      users,
      portfolios,
      programs,
      isSubmitting,

      data,
    } = this.state;
    const { intl, theme, history, match } = this.props;
    const isValid = this.validateFields();

    const isProgramValid =
      data &&
      programs.length > 0 &&
      programs.find(program => {
        return program.Code === data.Data.Program;
      });

    if ((!data || !programs.length || !portfolios.length) && match.params.id) return <ProjectPreviewLoader />;

    return (
      <div className="add-project-main-container">
        <div className="add-project-content-container" style={{ borderColor: theme.ThemeColor.item.Text }}>
          <div className="add-project-container">
            <div className="add-project-content">
              <div className="main-header">
                <div className="add-project-title">
                  <FormattedMessage id="createProject.title" />
                </div>
                <div className="add-project-buttons-container">
                  <Button color="white" backgroundColor={theme.ThemeColor.item.Text} onClick={() => history.push('/')}>
                    <FormattedMessage id="common.cancel" />
                  </Button>
                  <Button
                    color="white"
                    backgroundColor={theme.ThemeColor.item.Text}
                    onClick={() => this.save(false)}
                    disabled={isSubmitting || !isValid}
                  >
                    <FormattedMessage id="common.saveAsDraft" />
                  </Button>
                  <Button
                    color={theme.ThemeColor.item.Text}
                    backgroundColor="transparent"
                    onClick={() => this.save(true)}
                    disabled={isSubmitting || !isValid}
                    border
                  >
                    <FormattedMessage id="common.submit" />
                  </Button>
                </div>
              </div>
            </div>
            <form className="sections-padding">
              <div className="sections">
                <div className="sections-column">
                  <div className="section section-padding">
                    <div className="control">
                      <span className="label">
                        <FormattedMessage id="common.projectTitle" />
                      </span>
                      <Field
                        control={{
                          type: 'input',
                          name: 'Name',
                          onChangeAction: this.handleChange,
                          placeholder: intl.formatMessage({
                            id: 'common.projectTitle',
                          }),
                          value: Name,
                          validateOnExite: true,
                          error: this.validateName(Name),
                        }}
                      />
                    </div>
                  </div>

                  <div className="section section-padding">
                    <div className="control">
                      <span className="label">
                        <FormattedMessage id="common.projectTitleAr" />
                      </span>
                      <Field
                        control={{
                          type: 'input',
                          name: 'NameArabic',
                          onChangeAction: this.handleChange,
                          placeholder: intl.formatMessage({
                            id: 'common.projectTitleAr',
                          }),
                          value: NameArabic,
                          validateOnExite: true,
                          error: this.validateName(NameArabic),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sections">
                <div className="section section-padding">
                  <div className="section ">
                    <div className="control">
                      <span className="label">
                        <FormattedMessage id="common.project-description" />
                      </span>
                      <Field
                        control={{
                          type: 'textarea',
                          onChangeAction: e => this.setState({ Description: e.target.value }),
                          placeholder: intl.formatMessage({
                            id: 'common.project-description',
                          }),
                          value: Description,
                          // validateOnExite: true,
                          error: !Description?.trim().length,
                        }}
                      />
                    </div>
                  </div>
                  <div className="sections-column">
                    <div className="section">
                      <div className="control">
                        <span className="label">
                          <FormattedMessage id="common.portfolio" />
                        </span>
                        <Field
                          control={{
                            type: 'select',
                            onChangeAction: e => {
                              this.getAllPrograms(e.ProjectUID);
                              this.setState({ Portfolio: e, Program: '', programs: [] });
                            },
                            placeholder: intl.formatMessage({
                              id: 'common.portfolio',
                            }),
                            value: Portfolio,
                            options: portfolios,
                            getOptionValue: item => item.ProjectUID,
                            getOptionLabel: item => getProjectLocalizedName(item, intl.locale),
                            className: 'users-select-container',
                            classNamePrefix: 'users-select',
                            validateOnExite: true,
                            error: !Portfolio,
                          }}
                        />
                      </div>
                      <div className="control">
                        <span className="label">
                          <FormattedMessage id="common.program" />
                        </span>
                        <Field
                          control={{
                            type: 'select',
                            onChangeAction: e => this.setState({ Program: e }),
                            placeholder: intl.formatMessage({
                              id: 'common.program',
                            }),
                            value: isProgramValid || Program,
                            disabled: programs.length === 0,
                            options: programs,
                            getOptionValue: item => item.ProjectUID,
                            getOptionLabel: item => getProjectLocalizedName(item, intl.locale),
                            className: 'users-select-container',
                            classNamePrefix: 'users-select',
                            validateOnExite: true,
                            error: !Program && !data,
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sections-column">
                    <div className="section">
                      <div className="control">
                        <span className="label">
                          <FormattedMessage id="common.startDate" />
                        </span>
                        <Field
                          control={{
                            type: 'date',
                            onChangeAction: e => this.setState({ StartDateValue: e }),
                            locale: intl.locale === 'ar' ? 'ar' : 'en',
                            value: StartDateValue,
                            validateOnExite: true,
                            error: !StartDateValue,
                            noIcons: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className="section second">
                      <div className="control">
                        <span className="label">
                          <FormattedMessage id="common.projectBudget" />
                        </span>
                        <Field
                          control={{
                            type: 'number',
                            onChangeAction: e => this.setState({ Budget: e.target.value }),
                            placeholder: intl.formatMessage({
                              id: 'common.projectBudget',
                            }),
                            value: Budget,
                            validateOnExite: true,
                            error: !Budget?.trim().length,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section section-padding section-right">
                  <div className="sections-column">
                    <div className="section">
                      <div className="control">
                        <span className="label">
                          <FormattedMessage id="common.projectManager" />
                        </span>
                        <Field
                          control={{
                            type: 'select',
                            onChangeAction: e => this.setState({ Manager: e }),
                            value: Manager,
                            placeholder: intl.formatMessage({
                              id: 'common.projectManager',
                            }),
                            options: users,
                            className: 'users-select-container',
                            classNamePrefix: 'users-select',
                            validateOnExite: true,
                            error: !Manager,
                          }}
                        />
                      </div>
                      <div className="control">
                        <span className="label">
                          <FormattedMessage id="common.projectOwner" />
                        </span>
                        <Field
                          control={{
                            type: 'select',
                            onChangeAction: e => this.setState({ Owner: e }),
                            value: Owner,
                            placeholder: intl.formatMessage({
                              id: 'common.projectOwner',
                            }),
                            options: users,
                            className: 'users-select-container',
                            classNamePrefix: 'users-select',
                            validateOnExite: true,
                            error: !Owner,
                          }}
                        />
                      </div>
                      <div className="control">
                        <span className="label">
                          <FormattedMessage id="common.projectSponser" />
                        </span>
                        <Field
                          control={{
                            type: 'select',
                            onChangeAction: e => this.setState({ Sponsor: e }),
                            value: Sponsor,
                            placeholder: intl.formatMessage({
                              id: 'common.projectSponser',
                            }),
                            options: users,
                            className: 'users-select-container',
                            classNamePrefix: 'users-select',
                            validateOnExite: true,
                            error: !Sponsor,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard, theme, language }) => ({
  dashboard,
  theme,
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps, null)(AddProject));
