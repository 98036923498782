import React from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import 'react-table/react-table.css';
import './styles.scss';

const mapStateToProps = ({ theme }) => ({
  theme,
});

export const Th = ({ children, style }) => {
  const minWidth = style.flex.split(' ')[0];
  return (
    <div className="table__head" style={{ minWidth, ...style }}>
      {children}
    </div>
  );
};

export const Td = ({ children, style }) => {
  const minWidth = style.flex.split(' ')[0];
  return (
    <div className="table__cell" style={{ minWidth, ...style }}>
      {typeof children === 'string' ? <p>{children}</p> : children}
    </div>
  );
};

export const NoDataComponent = () => {
  return (
    <div className="table__noDateComponent">
      <FormattedMessage id="common.looksLikeThereIsNoDataHere" />
    </div>
  );
};

export const TdLoader = () => {
  return (
    <div className="table__cell">
      <div className="table__cell-loader animated-background" />
    </div>
  );
};

class Table extends React.Component {
  state = {};

  render() {
    const {
      columns,
      data,
      rowBorderColor = '#f44436',
      isLoading,
      numOfLoadingRows = 3,
      renderNoDataComponent = true,
      onRowClick,
      customRowStyle = () => {},
      ...rest
    } = this.props;
    return (
      <ReactTable
        {...rest}
        columns={columns}
        data={data}
        ThComponent={Th}
        TdComponent={isLoading ? TdLoader : Td}
        pageSize={data.length}
        showPagination={false}
        ResizerComponent={() => null}
        minRows={isLoading ? numOfLoadingRows : 0}
        NoDataComponent={() => (!isLoading && renderNoDataComponent ? <NoDataComponent /> : null)}
        getTrGroupProps={(state, rowInfo) => ({
          style: {
            borderInlineStartColor: rowBorderColor,
            // zIndex: 100,
            cursor: onRowClick && !isLoading ? 'pointer' : 'auto',
            ...customRowStyle(rowInfo),
          },
          onClick: onRowClick && !isLoading ? () => onRowClick(rowInfo) : () => null,
        })}
      />
    );
  }
}

export default connect(mapStateToProps)(Table);
