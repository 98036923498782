import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { css } from 'emotion';
import Slider from 'react-slick';
import { FormattedMessage, injectIntl } from 'react-intl';
import queryString from 'query-string';
import Breadcrumbs from '../../../components/Breadcrumb';
import SliderArrow from './SliderArrow';
import InitiativeFilterBar from './InitiativeFilterBar';
import EmptyComponent from '../../../components/EmptyComponent';
import InitiativeCard from './InitiativeCard';
import ProgramCardLoader from './ProgramCardLoader';
import ProgramsAPIController from '../../../providers/controllers/ProgramsAPIController';
import PTab from '../../../components/PTab';
import routes from '../../../routes';

import { devreduceStatus, getStatusColor, addToast } from '../../../Helpers';
import { ReportsUrl } from '../../../providers/config/urlServiceConfig';

import './style.scss';
import './slick.scss';
import './slick-theme.scss';
import ChatBot from '../../../components/ChatBot';

class Programs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programsList: [],
      loading: true,
      parentProgram: {},
      availableProject: [],
      keepAvilableProject: [],
      statusList: devreduceStatus(props.theme.Statuses.item.Items),
      appliedFilters: [],
      programOnView: {},
    };
  }

  componentDidMount() {
    this.getAllProjects();
  }

  getAllProjects() {
    const { match, location, history, language } = this.props;
    new ProgramsAPIController()
      .getAllPrograms(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          const programsList = res.Data || [];
          let newState = {
            programsList,
            loading: false,
          };
          if (programsList.length !== 0) {
            const query = queryString.parse(location.search);
            let index = programsList.findIndex(item => item.Code === query.program);
            if (index < 0) index = language.locale === 'ar' ? programsList.length - 1 : 0;
            newState = {
              ...newState,
              parentProgram: programsList[index].Portfolio || {},
              availableProject: programsList[index].Projects,
              keepAvilableProject: programsList[index].Projects,
              programOnView: programsList[index],
            };
            if (this.slider) {
              this.slider.slickGoTo(index);
              history.replace({
                pathname: location.pathname,
                search: queryString.stringify({ program: programsList[index].Code }),
              });
            }
          }
          this.setState({ ...newState });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-projects-toast',
        });
      });
  }

  getProjectsNumForStatus = status => {
    const { keepAvilableProject } = this.state;
    return keepAvilableProject.filter(project => project.Status === status).length;
  };

  getProjectsToFiltersCount = () => {
    const { statusList, availableProject } = this.state;
    return statusList.reduce((accum, status) => {
      const _accum = { ...accum };
      const numOfProjects = availableProject.filter(project => project.Status === status.Value).length;

      _accum[status.Value] = numOfProjects;
      return _accum;
    }, {});
  };

  renderPrograms = () => {
    const {
      props: { theme, language },
      state: { programsList, appliedFilters, statusList },
    } = this;

    const ProjectsPhases = theme.Project_Phases.item.Items;

    return programsList.map(program => (
      <InitiativeCard
        onClick={() => this.handleInitiativeClick(program)}
        key={program.Code}
        program={program}
        onStatusFilterChange={this.handleFilterByStatus}
        theme={theme}
        language={language}
        appliedFilters={appliedFilters}
        statusList={statusList}
        ProjectsPhases={ProjectsPhases}
        getProjectsNumForStatus={this.getProjectsNumForStatus}
      />
    ));
  };

  handleInitiativeClick = ({ Code, DirectAccess }) => {
    const { history } = this.props;
    if (DirectAccess) {
      history.push(routes.program(Code));
    } else {
      addToast.error('portfolio.workspace-permission', {
        toastId: 'direct-access-error',
      });
    }
  };

  handleFilterByStatus = data => {
    const { appliedFilters, keepAvilableProject, statusList } = this.state;
    let _appliedFilters = [...appliedFilters];

    if (appliedFilters.length + 1 === statusList.length) {
      _appliedFilters = [];
    } else if (appliedFilters.includes(data.Value)) {
      _appliedFilters = _appliedFilters.filter(filter => filter !== data.Value);
    } else {
      _appliedFilters.push(data.Value);
    }

    let _availableProjects = [...keepAvilableProject];

    if (_appliedFilters.length) {
      _availableProjects = _availableProjects.filter(project => _appliedFilters.includes(project.Status));
    }

    this.setState({
      appliedFilters: _appliedFilters,
      availableProject: _availableProjects,
    });
  };

  handleOnSw(e) {
    const { theme, history, location } = this.props;
    const { programsList } = this.state;
    const list = programsList[e].Projects;

    history.replace({
      pathname: location.pathname,
      search: queryString.stringify({ program: programsList[e].Code }),
    });
    this.setState({
      availableProject: list,
      keepAvilableProject: list,
      statusList: devreduceStatus(theme.Statuses.item.Items),
      appliedFilters: [],
      programOnView: programsList[e],
    });
  }

  render() {
    const { theme, language, history, user, intl } = this.props;
    const {
      loading,
      availableProject,
      appliedFilters,
      statusList,
      parentProgram: { NameArabic: ParentArName = '', NameEnglish: ParentEnName = '', Code },
      programOnView,
      programsList,
    } = this.state;

    const themeColor = theme.ThemeColor.item.Text;
    const settings = {
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,

      prevArrow: (
        <SliderArrow
          to="prev"
          className="slick-arrow"
          id={language.locale === 'ar' ? 'common.next' : 'common.previous'}
          text="Previous"
          themeColor={themeColor}
        />
      ),
      nextArrow: (
        <SliderArrow
          to="next"
          className="slick-arrow"
          id={language.locale === 'ar' ? 'common.previous' : 'common.next'}
          text="Next"
          themeColor={themeColor}
        />
      ),
    };
    const breadcrumbData = [
      { title: language.locale === 'ar' ? ParentArName : ParentEnName, location: `/programs/${Code}` },
    ];

    const BreadcrumbRender = () => {
      return <Breadcrumbs title="Programs.Programs" routes={breadcrumbData} />;
    };
    if (programsList.length === 0 && !loading) {
      return (
        <>
          <div className="bread-container">
            <BreadcrumbRender />
          </div>
          <EmptyComponent title={intl.formatMessage({ id: 'emptyComponent.program', defaultMessage: 'programs' })} />
        </>
      );
    }

    const _availableProjects = [...availableProject].sort(
      (a, b) => moment(b.StartDate).valueOf() - moment(a.StartDate).valueOf(),
    );
    const PPlusRisksAndIssues = 'PPlusRisksAndIssues';
    const PPlusPortfolioInfo = 'PPlusPortfolioInfo';

    const size = (190 / programsList.length - 2) * 2.8;
    const fontSize = size > 40 ? 40 : size;

    return (
      <div className="programs-main-container">
        <div className="bread-container">
          <BreadcrumbRender />
          <div className="reports-container">
            {ReportsUrl ? (
              <div className="section-actions">
                <a
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${ReportsUrl}${PPlusRisksAndIssues}&rs:Command=Render&PortfolioUID=${Code}&LoginUserName=${user.user.Username}&rs:Format=PDF`}
                  className="action report-link"
                  style={{ background: themeColor }}
                >
                  <FormattedMessage id="programs.risk-and-issues-report" />
                </a>
                <a
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${ReportsUrl}${PPlusPortfolioInfo}&rs:Command=Render&PortfolioUID=${Code}&LoginUserName=${user.user.Username}&rs:Format=PDF`}
                  className="action report-link"
                  style={{ background: themeColor }}
                >
                  <FormattedMessage id="programs.portfolio-details-report" />
                </a>
              </div>
            ) : null}
          </div>
        </div>
        <InitiativeFilterBar
          onChange={this.handleFilterByStatus}
          locale={language.locale}
          appliedFilters={appliedFilters}
          filtersList={statusList}
          themeColor={themeColor}
          counts={this.getProjectsToFiltersCount()}
        />
        <div className={`${language.locale === 'en' ? '' : 'rtl'} program-container  `}>
          <div
            className={css`
              .slick-next,
              .slick-prev {
                background: ${theme.ThemeColor.item.Text};
              }
              .slick-dots li button:before {
                font-size: ${fontSize}px;
              }
            `}
          >
            <Slider
              {...settings}
              ref={c => (this.slider = c)}
              afterChange={e => this.handleOnSw(e)}
              theme={theme}
              locale={language.locale}
            >
              {loading ? <ProgramCardLoader themeColor={themeColor} /> : this.renderPrograms()}
            </Slider>
          </div>
          <PTab
            theme={theme}
            locale={language.locale}
            projects={_availableProjects}
            history={history}
            loading={loading}
            statusColor={getStatusColor(theme, programOnView.Status)}
          />
        </div>
        <ChatBot />
      </div>
    );
  }
}

Programs.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
};

const mapStateToProps = (state /* , ownProps */) => {
  return {
    ...state,
  };
};

export default injectIntl(connect(mapStateToProps)(Programs));
