import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import routes from '../../routes';
import { getRequestTypeLocalized, getStatusName } from '../../Helpers';

import Panel from '../Panel';
import Table from '../Table';

function ProcessesTable({ data, intl, locale, statuses, themeColor, loading, title, history, theme }) {
  const columns = [
    {
      id: 'Title',
      accessor: rowData =>
        locale === 'ar' ? rowData.NameArabic || rowData.TitleArabic : rowData.NameEnglish || rowData.TitleEnglish,
      Header: intl.formatMessage({ id: 'common.title' }),
      width: 200,
    },
    {
      accessor: rowData => (locale === 'ar' ? rowData.WorkflowItem?.NameArabic : rowData.WorkflowItem?.Name),
      Header: intl.formatMessage({ id: 'common.request-type' }),
      width: 200,
      id: 'request-type',
    },
    {
      accessor: rowData => moment(rowData.RequestBy).format('DD-MMM-YYYY'),
      Header: intl.formatMessage({ id: 'common.creation-date' }),
      id: 'creation-date',
    },
    {
      accessor: rowData => {
        const creator = rowData.RequestBy;
        return creator?.DisplayName || '--';
      },
      Header: intl.formatMessage({ id: 'common.created-by' }),
      id: 'created-by',
    },
    {
      accessor: ({ StatusID = 0 }) => {
        return getStatusName(statuses, StatusID.toString(), locale);
      },
      Header: intl.formatMessage({ id: 'common.status' }),
      id: 'status',
    },
  ];

  function handleRowClick(rowData) {
    const {
      original: { WorkflowItem },
    } = rowData;
    history.push(routes.processEditPreview(WorkflowItem.Code.toLowerCase(), rowData.original.ID));
  }

  const localizedData = getRequestTypeLocalized(theme.Processes.item.Items, locale, data);

  return (
    <Panel
      title={title}
      count={localizedData.length}
      isCollapsable
      render={() => (
        <Table
          columns={columns}
          data={localizedData}
          showPagination={false}
          sortable={false}
          rowBorderColor={themeColor}
          isLoading={loading}
          numOfLoadingRows={3}
          pageSize={localizedData.length}
          onRowClick={handleRowClick}
        />
      )}
    />
  );
}

const mapStateToProps = ({ theme, language }) => ({
  themeColor: theme.ThemeColor.item.Text,
  statuses: theme.Request_statuses.item.Items,
  locale: language.locale,
  theme,
});

export default injectIntl(connect(mapStateToProps)(withRouter(ProcessesTable)));
