/*
 * action types
 */

export const TOGGLE_LANGUAGE = 'TOGGLE_LANGUAGE';
export const TOGGLE_LANGUAGE_SETTINGS = 'TOGGLE_LANGUAGE_SETTINGS';

export const HANDSHAKE = 'HANDSHAKE';
export const HANDSHAKE_ERROR = 'HANDSHAKE_ERROR';

/*
 * action creators
 */

export function toggleLanguage(key) {
  return { type: TOGGLE_LANGUAGE, payload: key };
}

export function handshake() {
  return { type: HANDSHAKE };
}

export function handshakeFailed(error) {
  return { type: HANDSHAKE_ERROR, error };
}
