import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ProcessesProjectInfo from '../ProcessesProjectInfo';
import { ProcessesTextArea, ProcessesTextField } from '../ProcessesTextArea';
import ProcessesAttachments from '../ProcessesAttachments';
import Impacts from './Impacts';

function ChangeRequestForm({ project, processType, themeColor, saveData, request, readOnly, intl }) {
  function handleAttachments(files) {
    saveData({ Attachments: files });
  }

  return (
    <div>
      <ProcessesProjectInfo request={request} project={project} processType={processType} />
      <div className="processes-project-info">
        <ProcessesTextField
          title={intl.formatMessage({ id: 'change-request.arabic-title' })}
          classes="half rtl"
          name="TitleArabic"
          data={request.Data.TitleArabic}
          onSave={saveData}
          readOnly={readOnly}
          required
        />

        <ProcessesTextField
          title={intl.formatMessage({ id: 'change-request.english-title' })}
          classes="half ltr"
          name="TitleEnglish"
          data={request.Data.TitleEnglish}
          onSave={saveData}
          readOnly={readOnly}
          required
        />
      </div>

      <ProcessesTextArea
        title="processes.change-details"
        name="Details"
        data={request.Data.Details}
        titleColor={themeColor}
        onSave={saveData}
        readOnly={readOnly}
        required
      />

      <Impacts data={request.Data} saveData={saveData} readOnly={readOnly} />

      <ProcessesTextArea
        title="processes.change-reasons"
        name="Reasons"
        data={request.Data.Reasons}
        titleColor={themeColor}
        onSave={saveData}
        readOnly={readOnly}
        required
      />
      <ProcessesTextArea
        title="processes.change-effect"
        name="Description"
        data={request.Data.Description}
        titleColor={themeColor}
        onSave={saveData}
        readOnly={readOnly}
        required
      />

      <ProcessesAttachments attachments={request.Data.Attachments} onChange={handleAttachments} readOnly={readOnly} />
    </div>
  );
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps)(ChangeRequestForm));
