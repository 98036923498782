import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const ProjectListItem = props => {
  const { theme, language, initiativeItem, selectProjecteHandler } = props;
  return (
    <>
      <tr className="initiative-row" style={{ backgroundColor: '#F5F5F5' }}>
        <td>{language.locale === 'ar' ? initiativeItem.NameArabic : initiativeItem.NameEnglish}</td>
        <td>{initiativeItem.Manager ? initiativeItem.Manager.DisplayName : ''}</td>
        <td colSpan={theme.ProjectPhases.item.Items.length} style={{ paddingInlineEnd: '0px' }} />
      </tr>
      {initiativeItem.Projects.map(project => (
        <tr onClick={() => selectProjecteHandler(initiativeItem, project.ProjectUID)} key={project.ProjectUID}>
          <td style={{ paddingInlineStart: '24px' }}>
            {language.locale === 'ar' ? project.NameArabic : project.NameEnglish}
          </td>
          <td>{project.Manager ? project.Manager.DisplayName : ''}</td>
          {theme.ProjectPhases.item.Items.map((_, index) => {
            let lastItemClass = '';
            if (!project.Phases[index + 1] || (project.Phases[index + 1] && project.Phases[index + 1].StatusID === 1)) {
              lastItemClass = language.locale === 'ar' ? ' last-ar' : ' last';
            }
            return project.Phases[index] && project.Phases[index].StatusID !== 1 ? (
              <td key={index}>
                <div
                  className={`grid-phase-days${lastItemClass}`}
                  style={{
                    backgroundColor: `${project.Phases[index].ColorCode.trim()}4D`,
                    borderColor: project.Phases[index].ColorCode.trim(),
                  }}
                >
                  <FormattedMessage id="common.day" values={{ count: project.Phases[index].Duration }} />
                </div>
              </td>
            ) : (
              <td key={index} />
            );
          })}
        </tr>
      ))}
    </>
  );
};

const mapStateToProps = ({ theme, language }) => {
  return {
    theme,
    language,
  };
};

export default connect(mapStateToProps)(ProjectListItem);
