import React from 'react';

export default () => (
  <div className="deliverable__details animated-background">
    <div className="deliverable__details-body shadow-background">
      <div className="deliverable__row shadow-background" />
      <div className="deliverable__row shadow-background" />
    </div>
    <div className="deliverable__row shadow-background" />
  </div>
);
