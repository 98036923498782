import React from 'react';

import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './styles.scss';

class CircularProgress extends React.Component {
  state = {
    hovered: false,
  };

  handleHover = () => {
    const { onHover } = this.props;

    this.setState(
      prevState => ({
        hovered: !prevState.hovered,
      }),
      () => {
        if (onHover) onHover();
      },
    );
  };

  render() {
    const {
      state: { hovered },
      props: { value, color, displayText = true, tipText, children },
    } = this;

    const progressProps = {
      ...this.props,
      value,
      text: displayText ? `${value}%` : '',
      styles: buildStyles({
        // How long animation takes to go from one value to another, in seconds
        pathTransitionDuration: 1,
        // Colors
        pathColor: color,
        textColor: color,
        opacity: hovered ? '50%' : '100%',
      }),
    };

    return (
      <div className="circularProgress__container" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
        {children ? (
          <CircularProgressbarWithChildren {...progressProps}>{children}</CircularProgressbarWithChildren>
        ) : (
          <CircularProgressbar {...progressProps} />
        )}
        {hovered && Boolean(tipText) ? <span className="circularProgress__tooltip">{tipText}</span> : null}
      </div>
    );
  }
}

export default CircularProgress;
