import TasksCenterController from '../providers/controllers/TasksCenterController';
import ProjectAPIController from '../providers/controllers/ProjectAPIController';
import { addToast } from '../Helpers';

export const GET_TASKS_CENTER_COUNT = 'GET_TASKS_CENTER_COUNT';
export const GET_PROJECTS_TASKS_APPROVALS = 'GET_PROJECTS_TASKS_APPROVALS';
export const GET_NON_PROJECTS_TASKS_APPROVALS = 'GET_NON_PROJECTS_TASKS_APPROVALS';
export const GET_MY_REQUESTS = 'GET_MY_REQUESTS';
export const POST_UPDATED_TASKS_START = 'POST_UPDATED_TASKS_START';
export const POST_NEW_TASK = 'POST_NEW_TASK';
export const GET_PMO_PROCESSORS = 'GET_PMO_PROCESSORS';
export const GET_PHASE_APPROVALS = 'GET_PHASE_APPROVALS';
export const GET_PROJECTS_TASKS = 'GET_PROJECTS_TASKS';
export const GET_PROJECTS_RISKS = 'GET_PROJECTS_RISKS';
export const GET_PROJECTS_ISSUES = 'GET_PROJECTS_ISSUES';
export const GET_NON_PROJECTS_TASKS = 'GET_NON_PROJECTS_TASKS';
export const POST_TASK_STATUS = 'POST_TASK_STATUS';
export const POST_PHASE_STATUS = 'POST_PHASE_STATUS';
export const POST_UPDATED_TASKS = 'POST_UPDATED_TASKS';
export const UPDATE_TASK_PERCENTAGE = 'UPDATE_TASK_PERCENTAGE';
export const UPDATE_NON_PROJECT_TASK_PERCENTAGE = 'UPDATE_NON_PROJECT_TASK_PERCENTAGE';
export const UPDATE_PMO_PROCESSES = 'UPDATE_PMO_PROCESSES';
export const POST_MY_UPDATED_TASKS_START = 'POST_MY_UPDATED_TASKS_START';
export const POST_MY_UPDATED_TASKS = 'POST_MY_UPDATED_TASKS';
export const GET_NEW_PROJECTS_APPROVALS = 'GET_NEW_PROJECTS_APPROVALS';
export const GET_NEW_PROJECTS_REQUESTS = 'GET_NEW_PROJECTS_REQUESTS';

const getTasksCenterCountAction = payload => {
  return {
    type: GET_TASKS_CENTER_COUNT,
    payload,
  };
};

const getProjectsTasksApprovalsAction = payload => {
  return {
    type: GET_PROJECTS_TASKS_APPROVALS,
    payload,
  };
};

const getNonProjectsTasksApprovalsAction = payload => {
  return {
    type: GET_NON_PROJECTS_TASKS_APPROVALS,
    payload,
  };
};

const getMyRequestsAction = payload => {
  return {
    type: GET_MY_REQUESTS,
    payload,
  };
};

const getNewProjectsRequestsAction = payload => {
  return {
    type: GET_NEW_PROJECTS_REQUESTS,
    payload,
  };
};

const getPMOProcessorsAction = payload => {
  return {
    type: GET_PMO_PROCESSORS,
    payload,
  };
};

const getPhaseApprovalsAction = payload => {
  return {
    type: GET_PHASE_APPROVALS,
    payload,
  };
};

const getNewProjectsApprovalsAction = payload => {
  return {
    type: GET_NEW_PROJECTS_APPROVALS,
    payload,
  };
};

const getProjectsTasksAction = payload => {
  return {
    type: GET_PROJECTS_TASKS,
    payload,
  };
};

const getProjectsRisksAction = payload => {
  return {
    type: GET_PROJECTS_RISKS,
    payload,
  };
};

const getProjectsIssuesAction = payload => {
  return {
    type: GET_PROJECTS_ISSUES,
    payload,
  };
};

const getNonProjectsTasksAction = payload => {
  return {
    type: GET_NON_PROJECTS_TASKS,
    payload,
  };
};

const postTaskStatusAction = payload => {
  return {
    type: POST_TASK_STATUS,
    payload,
  };
};

const postPhaseStatusAction = payload => {
  return {
    type: POST_PHASE_STATUS,
    payload,
  };
};

const postUpdatedTasksAction = payload => {
  return {
    type: POST_UPDATED_TASKS,
    payload,
  };
};

export const postMyUpdatedTasksStartAction = () => {
  return {
    type: POST_MY_UPDATED_TASKS_START,
  };
};

const postNewTask = data => {
  return {
    type: POST_NEW_TASK,
    data,
  };
};
const postMyUpdatedTasksAction = payload => {
  return {
    type: POST_MY_UPDATED_TASKS,
    payload,
  };
};

const updateTaskPercentageAction = payload => {
  return {
    type: UPDATE_TASK_PERCENTAGE,
    payload,
  };
};

const updateNonProjectTaskPercentageAction = payload => {
  return {
    type: UPDATE_NON_PROJECT_TASK_PERCENTAGE,
    payload,
  };
};

export const updatePmoProcesses = payload => ({
  type: UPDATE_PMO_PROCESSES,
  payload,
});

export const getTasksCenterCount = () => {
  return dispatch => {
    new TasksCenterController().getTaskCenterCount().then(res => {
      dispatch(getTasksCenterCountAction(res.Data || {}));
    });
  };
};

export const getProjectsTasksApprovals = () => {
  return dispatch => {
    new TasksCenterController().getProjectsTasksApprovals().then(res => {
      dispatch(getProjectsTasksApprovalsAction(res.Data));
    });
  };
};

export const getNonProjectsTasksApprovals = () => {
  return dispatch => {
    new TasksCenterController().getNonProjectsTasksApprovals().then(res => {
      dispatch(getNonProjectsTasksApprovalsAction(res.Data));
    });
  };
};

export const getMyRequests = () => {
  return dispatch => {
    new TasksCenterController().getMyRequests().then(res => {
      dispatch(getMyRequestsAction(res.Data));
    });
  };
};

export const getPMOProcessors = () => {
  return dispatch => {
    new TasksCenterController().getPMOProcesses().then(res => {
      dispatch(getPMOProcessorsAction(res.Data));
    });
  };
};

export const getPhaseApprovals = () => {
  return dispatch => {
    new ProjectAPIController().getPhaseApprovals().then(res => {
      dispatch(getPhaseApprovalsAction(res.Data));
    });
  };
};

export const getNewProjectsApprovals = () => {
  return dispatch => {
    new ProjectAPIController().getNewProjectsApprovals().then(res => {
      dispatch(getNewProjectsApprovalsAction(res.Data));
    });
  };
};

export const getNewProjectsRequests = () => {
  return dispatch => {
    new ProjectAPIController().getNewProjectsRequests().then(res => {
      dispatch(getNewProjectsRequestsAction(res.Data));
    });
  };
};

export const getProjectsTasks = () => {
  return dispatch => {
    new TasksCenterController().getProjectsTasks().then(res => {
      dispatch(getProjectsTasksAction(res.Data));
    });
  };
};

export const getProjectsRisks = () => {
  return dispatch => {
    new TasksCenterController().getProjectsRisks().then(res => {
      dispatch(getProjectsRisksAction(res.Data));
    });
  };
};

export const getProjectsIssues = () => {
  return dispatch => {
    new TasksCenterController().getProjectsIssues().then(res => {
      dispatch(getProjectsIssuesAction(res.Data));
    });
  };
};

export const getNonProjectsTasks = () => {
  return dispatch => {
    new TasksCenterController().getNonProjectsTasks().then(res => {
      dispatch(getNonProjectsTasksAction(res.Data));
    });
  };
};

export const postTaskStatus = (body, type, removeControllersLoading, closeModal) => {
  return dispatch => {
    let req;
    const payload = {
      type,
    };

    let approve = false;

    if (type === 'nonProjectsTasks') {
      req = new TasksCenterController().postNonProjectsTasksStatus;
      payload.taskID = body.data.ID;
      approve = body.data.actionID === 4;
    } else {
      req = new TasksCenterController().postProjectsTasksStatus;
      payload.taskID = body.task.ID;
      approve = body.actionID === 3;
    }

    req(body).then(() => {
      addToast.success(approve ? 'common.approveStatus' : 'common.rejectStatus');
      closeModal && closeModal();
      // removeControllersLoading && removeControllersLoading();
      dispatch(postTaskStatusAction(payload));
    });
  };
};

export const postPhaseStatus = (body, id) => {
  return dispatch => {
    const req = new ProjectAPIController().postPhaseStatus(body);
    const payload = {
      type: 'phase',
      phaseID: id,
    };

    req.then(() => {
      addToast.success('common.changesHaveBeenSaved');
      dispatch(postPhaseStatusAction(payload));
    });
  };
};

export const postUpdatedTasks = (body, cb) => {
  return dispatch => {
    new ProjectAPIController().updateProjectsTasks(body).then(() => {
      dispatch(postUpdatedTasksAction(body));
      cb && cb();
    });
  };
};

export const addNewTask = (body, callback) => {
  return dispatch => {
    new ProjectAPIController().addNewTask(body).then(() => {
      dispatch(postNewTask(body));
      callback && callback();
    });
  };
};

export const postMyUpdatedTasks = (body, cb) => {
  return (dispatch, getState) => {
    if (!body) {
      dispatch(postMyUpdatedTasksStartAction());
      const { myUpdatedTasks: data } = getState().tasksCenter;
      // eslint-disable-next-line
      body = {
        data,
      };
    }
    new TasksCenterController().postNonProjectsTasks(body).then(() => {
      dispatch(postMyUpdatedTasksAction(body));
      cb && cb();
    });
  };
};

export const updateTaskPercentage = (task, percentage) => {
  return dispatch => {
    dispatch(updateTaskPercentageAction({ task, percentage }));
  };
};

export const updateNonProjectTaskPercentage = (task, percentage) => {
  return dispatch => {
    dispatch(updateNonProjectTaskPercentageAction({ task, percentage }));
  };
};
