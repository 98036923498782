import React from 'react';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Carousel = ({ render, ...carouselProps }) => {
  return <ReactCarousel {...carouselProps}>{render()}</ReactCarousel>;
};

export default Carousel;
