import ProjectAPIController from '../providers/controllers/ProjectAPIController';

export const POST_RISK_START = 'POST_RISK_START';
export const POST_RISK = 'POST_RISK';
export const POST_ISSUE_START = 'POST_ISSUE_START';
export const POST_ISSUE = 'POST_ISSUE';
export const POST_LIQUIDITY_START = 'POST_LIQUIDITY_START';
export const POST_LIQUIDITY = 'POST_LIQUIDITY';
export const GET_MONTHLY_ASSIGNMENT = 'GET_MONTHLY_ASSIGNMENT';
export const GET_EARNING = 'GET_EARNING';

const postRiskActionStart = () => {
  return {
    type: POST_RISK_START,
  };
};

const postRiskAction = payload => {
  return {
    type: POST_RISK,
    payload,
  };
};

const getEarningAction = payload => {
  return {
    type: GET_EARNING,
    payload,
  };
};

const postIssueActionStart = () => {
  return {
    type: POST_ISSUE_START,
  };
};

const postIssueAction = payload => {
  return {
    type: POST_ISSUE,
    payload,
  };
};

const postLiquidityActionStart = () => {
  return {
    type: POST_LIQUIDITY_START,
  };
};

const postLiquidityAction = payload => {
  return {
    type: POST_LIQUIDITY,
    payload,
  };
};

const getMonthlyAssignmentAction = payload => {
  return {
    type: GET_MONTHLY_ASSIGNMENT,
    payload,
  };
};

export const postRisk = (body, cbSuccess, cbFail) => {
  return dispatch => {
    dispatch(postRiskActionStart());
    const request = new ProjectAPIController().addRisk(body);

    return request.then(
      res => {
        const { StatusCode } = res;

        if (StatusCode === 'Success') {
          cbSuccess && cbSuccess();
        } else {
          cbFail && cbFail();
        }
        dispatch(postRiskAction());
      },
      err => {
        dispatch(postRiskAction());
        console.log(err);
        cbFail && cbFail();
      },
    );
  };
};

export const postIssue = (body, cbSuccess, cbFail) => {
  return dispatch => {
    dispatch(postIssueActionStart());
    const request = new ProjectAPIController().addIssue(body);
    return request.then(
      res => {
        const { StatusCode } = res;

        if (StatusCode === 'Success') {
          cbSuccess && cbSuccess();
        } else {
          cbFail && cbFail();
        }
        dispatch(postIssueAction());
      },
      err => {
        dispatch(postIssueAction());
        console.log(err);
        cbFail && cbFail();
      },
    );
  };
};

export const postLiquidity = (body, cb) => {
  return dispatch => {
    dispatch(postLiquidityActionStart());
    new ProjectAPIController()
      .addLiquidity(body)
      .then(res => {
        const { StatusCode } = res;

        if (StatusCode === 'Success') {
          cb && cb();
        }

        dispatch(postLiquidityAction());
      })
      .catch(() => {
        dispatch(postLiquidityAction());
      });
  };
};

export const getMonthlyAssignment = code => {
  return dispatch => {
    new ProjectAPIController().getMonthlyAssignment(code).then(res => {
      if (res.StatusCode === 'Success') {
        dispatch(getMonthlyAssignmentAction(res.Data));
      }
    });
  };
};
export const getEarning = code => {
  return dispatch => {
    new ProjectAPIController().getEarning(code).then(res => {
      if (res.StatusCode === 'Success') {
        dispatch(getEarningAction(res.Data));
      }
    });
  };
};
