import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Panel from '../Panel';
import PanelLoader from '../Panel/PanelLoader';
import ProjectItems from './children/ProjectItems';
import TaskRowLoader from '../TaskRow/TaskRowLoader';

import { getProjectLocalizedName, renderMultiple, addToast } from '../../Helpers';
import { getProjectsTasks, getProjectsRisks, getProjectsIssues, postUpdatedTasks } from '../../actions/tasksCenter';

import './styles.scss';

class ProjectsTasks extends React.Component {
  state = {
    postingProjectWithID: null,
  };

  componentDidMount() {
    const { getProjectsTasksAction, getProjectsRisksAction, getProjectsIssuesAction } = this.props;
    getProjectsTasksAction();
    getProjectsRisksAction();
    getProjectsIssuesAction();
  }

  getProjectTitle = project => {
    const {
      language: { locale },
    } = this.props;
    if (project) return getProjectLocalizedName(project, locale);
    return '';
  };

  getProjectItemsCount = project => {
    let sum = 0;
    Object.keys(project).forEach(key => {
      if (key !== 'project') sum += project[key].length;
    });
    return sum;
  };

  getProjectHasUpdatedTasks = project => {
    const { updatedTasks } = this.props;
    if (!project.tasks) return false;

    let has = false;

    project.tasks.forEach(task => {
      updatedTasks.forEach(_task => {
        if (task.ID === _task.ID) has = true;
      });
    });

    return has;
  };

  handleSave = projectID => {
    const { postUpdatedTasksAction, updatedTasks } = this.props;
    const projectTasks = updatedTasks.filter(task => task.ProjectUID === projectID);
    this.setState({
      postingProjectWithID: projectID,
    });

    const modifyStatus = projectTasks.map(item => {
      return {
        ...item,
        StatusID: 2,
      };
    });

    const body = {
      data: modifyStatus,
    };
    postUpdatedTasksAction(body, () => {
      this.setState(
        {
          postingProjectWithID: null,
        },
        () => {
          addToast.success('common.changesHaveBeenSaved');
        },
      );
    });
  };

  render() {
    const {
      props: {
        intl,
        history,
        projectsTasks,
        loaders: { projectsTasksTab: isLoading },
      },
      state: { postingProjectWithID },
    } = this;
    const projects = Object.keys(projectsTasks);

    return (
      <div className="projectsTasks__container">
        {isLoading
          ? renderMultiple(
              key => <PanelLoader key={key} render={() => renderMultiple(_key => <TaskRowLoader key={_key} />, 4)} />,
              3,
            )
          : projects.map((project, i) => (
              <div
                key={`projectPanel#${i + 1}`}
                className={`projectsTasks__section${postingProjectWithID === project ? ' loading' : ''}`}
              >
                <Panel
                  title={this.getProjectTitle(projectsTasks[project].project)}
                  count={this.getProjectItemsCount(projectsTasks[project])}
                  render={() => <ProjectItems project={projectsTasks[project]} history={history} />}
                  action={() => this.handleSave(project)}
                  actionText={intl.formatMessage({ id: 'common.save' })}
                  actionDisabled={
                    postingProjectWithID === project || !this.getProjectHasUpdatedTasks(projectsTasks[project])
                  }
                />
              </div>
            ))}
      </div>
    );
  }
}

const mapStateToProps = ({ tasksCenter: { projectsTasks, updatedTasks, loaders }, language }) => ({
  projectsTasks,
  loaders,
  language,
  updatedTasks,
});

const mapDispatchToProps = {
  getProjectsTasksAction: getProjectsTasks,
  getProjectsRisksAction: getProjectsRisks,
  getProjectsIssuesAction: getProjectsIssues,
  postUpdatedTasksAction: postUpdatedTasks,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProjectsTasks));
