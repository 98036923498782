import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Tabs from '../../../components/Tabs';
import Approvals from '../../../components/Approvals';
import ProjectsTasks from '../../../components/ProjectsTasks';
import MyTasks from '../../../components/MyTasks';

import { getTasksCenterCount } from '../../../actions/tasksCenter';

import './styles.scss';
import MyRequests from '../../../components/MyRequests';

const tabs = ['approvals', 'projects-tasks', 'requests', 'my-tasks'];

class TasksCenter extends React.Component {
  constructor(props) {
    super(props);
    const currentTab = tabs.find(tab => props.location.pathname.includes(tab));
    this.state = {
      activeTab: currentTab || 'approvals',
    };
  }

  componentDidMount() {
    const { getTasksCenterCount: _getTasksCenterCount } = this.props;
    _getTasksCenterCount();
  }

  getTabsDetails = () => {
    const {
      tasksCenter: { counters },
      intl,
    } = this.props;
    const {
      ApprovalsCount_Total: approvalsCount = 0,
      ProjectTasksCount = 0,
      MyTasksCount_Total: myTasksCount = 0,
      MyRequestsCount: myRequestsCount = 0,
    } = counters || {};

    return [
      { id: 'approvals', title: intl.formatMessage({ id: 'tasksCenter.approvals' }), count: approvalsCount },
      {
        id: 'projects-tasks',
        title: intl.formatMessage({ id: 'tasksCenter.project-tasks' }),
        count: ProjectTasksCount,
      },
      { id: 'requests', title: intl.formatMessage({ id: 'tasksCenter.my-requests' }), count: myRequestsCount },
      { id: 'my-tasks', title: intl.formatMessage({ id: 'tasksCenter.my-tasks' }), count: myTasksCount },
    ];
  };

  renderContent = () => {
    return (
      <Switch>
        <Route path="/tasks/approvals" name="Tasks Center Approvals" component={Approvals} />
        <Route path="/tasks/projects-tasks" name="Tasks Center Projects Tasks" component={ProjectsTasks} />
        <Route path="/tasks/requests" name="Tasks Center Requests" component={MyRequests} />
        <Route path="/tasks/my-tasks" name="Tasks Center Non Projects Tasks" component={MyTasks} />
        <Redirect from="/tasks/" to="/tasks/approvals" />
      </Switch>
    );
  };

  handleTabChange = tabId => {
    const {
      props: { history },
    } = this;

    this.setState(
      {
        activeTab: tabId,
      },
      () => {
        history.replace(`/tasks/${tabId}`);
      },
    );
  };

  render() {
    const {
      props: { theme },
      state: { activeTab },
    } = this;

    return (
      <div className="tasksCenter__container">
        <div className="tasksCenter__tabs-container">
          <Tabs
            activeTab={activeTab}
            tabs={this.getTabsDetails()}
            onChange={this.handleTabChange}
            themeColor={theme.ThemeColor.item.Text}
          />
        </div>
        <div className="tasksCenter__content-wrapper">{this.renderContent()}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getTasksCenterCount,
};

const mapStateToProps = ({ tasksCenter, theme }) => ({
  tasksCenter,
  theme,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TasksCenter));
