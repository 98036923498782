export const filterProjectsByStatus = (projects, selectedStatus) => {
  if (!selectedStatus) return projects;
  return projects.filter(project => project.Status === selectedStatus.code);
};

export const filterProjectsByEntity = (projects, selectedEntity) => {
  if (!selectedEntity.Code || selectedEntity.Code === 'all') return projects;
  return projects.filter(project => (project.Portfolio || {}).Code === selectedEntity.Code);
};

export const filterProjectsByProgram = (projects, selectedProgram) => {
  if (!selectedProgram.Code || selectedProgram.Code === 'all') return projects;
  return projects.filter(project => (project.Program || {}).Code === selectedProgram.Code);
};

export const getMonthlyAssignmentInitials = () => ({
  planned: {
    id: 'planned',
    backgroundColor: 'rgba(103, 183, 220, .1)',
    strokeColor: 'rgb(103, 183, 220)',
    borderColor: 'rgb(103, 183, 220)',
    borderCapStyle: 'butt',
    pointHitRadius: 0,
    pointColor: 'rgb(103, 183, 220)',
    tooltipColor: 'rgb(103, 183, 220)',
    pointStrokeColor: 'rgb(103, 183, 220)',
    pointHighlightFill: 'rgb(103, 183, 220)',
    pointBackgroundColor: 'rgb(103, 183, 220)',
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#fff',
    pointHighlightBackgroundColor: '#fff',
    borderWidth: 2,
    pointHighlightStroke: '#fff',
    pointHoverFill: '#fff',
    data: [],
  },
  baseline: {
    id: 'baseline',
    strokeColor: 'rgb(103, 113, 220)',
    backgroundColor: 'rgba(103, 183, 220, .1)',
    borderColor: 'rgb(103, 113, 220)',
    pointBackgroundColor: 'rgb(103, 113, 220)',
    borderCapStyle: 'butt',
    pointColor: '#fff',
    pointHitRadius: 0,
    pointStrokeColor: '#fff',
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    borderWidth: 2,
    tooltipColor: 'rgb(103, 113, 220)',
    pointHighlightFill: '#fff',
    hoverBackgroundColor: 'fff',
    pointHoverBackgroundColor: '#fff',
    pointHighlightBackgroundColor: '#fff',
    pointHoverFill: '#fff',
    pointHighlightStroke: '#fff',
    data: [],
  },
  actual: {
    id: 'actual',
    backgroundColor: 'rgba(103, 183, 220, .1)',
    pointBackgroundColor: 'rgb(163, 103, 220)',
    borderColor: 'rgb(163, 103, 220)',
    strokeColor: 'rgb(163, 103, 220)',
    borderCapStyle: 'butt',
    pointHitRadius: 0,
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#fff',
    pointHighlightBackgroundColor: '#fff',
    borderWidth: 2,
    pointColor: 'rgb(163, 103, 220)',
    pointStrokeColor: 'rgb(163, 103, 220)',
    tooltipColor: 'rgb(163, 103, 220)',
    pointHoverFill: '#fff',
    pointHighlightStroke: '#fff',
    data: [],
  },
});
export const getEarningInitials = () => ({
  consumed: {
    id: 'consumed',
    strokeColor: 'rgb(103, 113, 220)',
    backgroundColor: 'rgba(103, 183, 220, .1)',
    borderColor: 'rgb(103, 113, 220)',
    pointBackgroundColor: 'rgb(103, 113, 220)',
    borderCapStyle: 'butt',
    pointColor: '#fff',
    pointHitRadius: 0,
    pointStrokeColor: '#fff',
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    borderWidth: 2,
    tooltipColor: 'rgb(103, 113, 220)',
    pointHighlightFill: '#fff',
    hoverBackgroundColor: 'fff',
    pointHoverBackgroundColor: '#fff',
    pointHighlightBackgroundColor: '#fff',
    pointHoverFill: '#fff',
    pointHighlightStroke: '#fff',
    data: [],
  },
  earn: {
    id: 'earn',
    backgroundColor: 'rgba(103, 183, 220, .1)',
    pointBackgroundColor: 'rgb(163, 103, 220)',
    borderColor: 'rgb(163, 103, 220)',
    strokeColor: 'rgb(163, 103, 220)',
    borderCapStyle: 'butt',
    pointHitRadius: 0,
    pointBorderWidth: 2,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#fff',
    pointHighlightBackgroundColor: '#fff',
    borderWidth: 2,
    pointColor: 'rgb(163, 103, 220)',
    pointStrokeColor: 'rgb(163, 103, 220)',
    tooltipColor: 'rgb(163, 103, 220)',
    pointHoverFill: '#fff',
    pointHighlightStroke: '#fff',
    data: [],
  },
});
