import UrlAPIControllers, { projectService } from '../config/urlServiceConfig';
import HttpServiceConfig from '../config/httpServiceConfig';

export default class ProjectAPIController {
  getProjectByCode = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getUtilizationCapacity = () => {
    const FETCH_URL = new UrlAPIControllers().getUtilizationCapacity();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getResourcesCapacity = () => {
    const FETCH_URL = new UrlAPIControllers().getResourcesCapacity();

    return new HttpServiceConfig().get(FETCH_URL);
  };

  transferRisk = (code, id) => {
    const FETCH_URL = `${projectService}risk/toissue/${code}/${id}`;
    return new HttpServiceConfig().post(FETCH_URL);
  };

  getProjectRisks = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/risks`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectIssues = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/issues`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectLiquidity = code => {
    const FETCH_URL = `${projectService}${code}/liquidities`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProgressUpdate = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/ProgressUpdate`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getEarning = code => {
    const FETCH_URL = `${projectService}/Project/${code}/Earning`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectTasks = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/tasks`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getAllProjectTasks = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/alltasks`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getPhaseApprovals = () => {
    const FETCH_URL = `${projectService}PhaseGate/Approvals`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getUsers = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/users`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getMonthlyAssignment = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/MonthlyAssignment`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getAllUsers = () => {
    const FETCH_URL = `${projectService}/users`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getLibraryStructure = () => {
    const FETCH_URL = `${new UrlAPIControllers().projectLibraryURL()}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getLibraryFilesList = body => {
    const FETCH_URL = `${new UrlAPIControllers().projectLibraryURL()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  getProjectRequests = projectUID => {
    const FETCH_URL = `${new UrlAPIControllers().projectRequestsURL(projectUID)}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  addIssue = body => {
    const FETCH_URL = new UrlAPIControllers().addIssueUrl();
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  addRisk = body => {
    const FETCH_URL = new UrlAPIControllers().addRiskUrl();
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  addLiquidity = body => {
    const FETCH_URL = new UrlAPIControllers().addLiquidityUrl();
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  progressUpdate = body => {
    const FETCH_URL = new UrlAPIControllers().progressUpdateURL();
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  updateProjectsTasks = body => {
    const FETCH_URL = `${new UrlAPIControllers().updateTasksURL()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  addNewTask = body => {
    const FETCH_URL = `${new UrlAPIControllers().addNewTaskURL()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  postPhaseStatus = body => {
    const FETCH_URL = `${projectService}PhaseGate/doaction`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  uploadLibraryFile = body => {
    const FETCH_URL = `${new UrlAPIControllers().uploadLibraryFileURL()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  updateProjectPriority = body => {
    const FETCH_URL = `${new UrlAPIControllers().updateProjectPriority()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  saveNewProject = body => {
    const FETCH_URL = `${new UrlAPIControllers().saveNewProjectURL()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  submitNewProject = ID => {
    const FETCH_URL = `${new UrlAPIControllers().submitNewProjectURL(ID)}`;
    return new HttpServiceConfig().post(FETCH_URL);
  };

  getNewProject = ID => {
    const FETCH_URL = `${new UrlAPIControllers().getNewProject(ID)}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getNewProjectsApprovals = () => {
    const FETCH_URL = `${new UrlAPIControllers().getNewProjectsApprovalsURL()}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getNewProjectsRequests = () => {
    const FETCH_URL = `${new UrlAPIControllers().getNewProjectsRequestsURL()}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getNewProjectsSteps = () => {
    const FETCH_URL = `${new UrlAPIControllers().getNewProjectStepsURL()}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getPhaseGate = code => {
    const FETCH_URL = `${new UrlAPIControllers().getPhaseGate(code)}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getPhaseGateComments = code => {
    const FETCH_URL = `${new UrlAPIControllers().getPhaseGateComments(code)}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  savePhaseGateComment = body => {
    const FETCH_URL = `${new UrlAPIControllers().savePhaseGateComment()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  deleteComment = code => {
    const FETCH_URL = `${new UrlAPIControllers().deleteComment(code)}`;
    return new HttpServiceConfig().post(FETCH_URL);
  };

  phaseGateCheck = body => {
    const FETCH_URL = `${new UrlAPIControllers().phaseGateCheck()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  phaseGateSubmit = code => {
    const FETCH_URL = `${new UrlAPIControllers().phaseGateSubmit(code)}`;
    return new HttpServiceConfig().post(FETCH_URL);
  };

  getRiskByID = (code, riskid) => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/Risks/${riskid}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getIssueByID = (code, issueid) => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/Issues/${issueid}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getNewProjectURL = ID => {
    const FETCH_URL = `${new UrlAPIControllers().getNewProjectURL(ID)}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  newProjectsDoAction = body => {
    const FETCH_URL = `${new UrlAPIControllers().newProjectsDoAction()}`;
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  saveProcess = (process, body) => {
    const FETCH_URL = `${new UrlAPIControllers().wfServiceURL()}/${process}`;

    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  submitProcess = (process, ID) => {
    const FETCH_URL = `${new UrlAPIControllers().wfServiceURL()}/submit/${ID}`;

    return new HttpServiceConfig().post(FETCH_URL);
  };

  getProcessRequest = (process, ID) => {
    const FETCH_URL = `${new UrlAPIControllers().wfServiceURL()}/${process}/${ID}`;

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProcessesPreviewSteps = stepType => {
    const FETCH_URL = `${new UrlAPIControllers().wfServiceURL()}/steps/${stepType}`;

    return new HttpServiceConfig().get(FETCH_URL);
  };

  processesFormAction = (action, payload) => {
    const FETCH_URL = `${new UrlAPIControllers().wfServiceURL()}/DoAction`;

    return new HttpServiceConfig().post(FETCH_URL, payload);
  };

  resubmitProcessRequest = ID => {
    const FETCH_URL = `${new UrlAPIControllers().wfServiceURL()}/return/${ID}`;

    return new HttpServiceConfig().post(FETCH_URL);
  };

  getEscalation = code => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/Escalations/${code}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getAllEscalation = (code, type, id) => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/Escalations/${code}/${type}/${id}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getEscalationStep = type => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/Escalations/Steps/${type}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  escalationSubmit = id => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/Escalations/${id}/EscalateUP`;
    return new HttpServiceConfig().post(FETCH_URL);
  };

  escalationSubmit = id => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/Escalations/${id}/EscalateUP`;
    return new HttpServiceConfig().post(FETCH_URL);
  };

  closeEscalation = id => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/Escalations/${id}/Close`;
    return new HttpServiceConfig().post(FETCH_URL);
  };

  addEEscalationLog = (id, data) => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/Escalations/${id}/EscalationLog`;
    return new HttpServiceConfig().post(FETCH_URL, data);
  };

  addEscalation = (code, type, id, data) => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${code}/${type}/${id}/AddEscalation`;
    return new HttpServiceConfig().post(FETCH_URL, data);
  };

  getInterdependencies = () => {
    const FETCH_URL = `${projectService}Dependency/AllDependencies`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getInterdependency = id => {
    const FETCH_URL = `${projectService}Dependency/${id}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  closeInterdependency = id => {
    const FETCH_URL = `${projectService}Dependency/close/${id}`;
    return new HttpServiceConfig().post(FETCH_URL);
  };

  submitInterdependency = data => {
    const FETCH_URL = `${projectService}Dependency`;
    return new HttpServiceConfig().post(FETCH_URL, data);
  };

  getProjectDeliverables = code => {
    const FETCH_URL = `${projectService}project/${code}/Deliverables`;

    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProjectFinancial = projectUID => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${projectUID}/ProjectFinancial`;

    return new HttpServiceConfig().get(FETCH_URL);
  };

  saveProjectFinancial = (projectUID, data) => {
    const FETCH_URL = `${new UrlAPIControllers().projectDetailsURL()}/${projectUID}/ProjectFinancial`;

    return new HttpServiceConfig().post(FETCH_URL, data);
  };
}
