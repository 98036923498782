import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import FieldsList from '../FieldsList';
import Deliverable from './Deliverable';

import './style.scss';

function Deliverables({ data, intl, saveData, themeColor, readOnly, showCheckbox, showDeliverablesHint }) {
  const items = [
    {
      name: intl.formatMessage({ id: 'processes.deliverables-acceptance-deliverable' }),
      width: '16.6%',
    },
    {
      name: intl.formatMessage({ id: 'common.startDate' }),
      width: '16.6%',
    },
    {
      name: intl.formatMessage({ id: 'common.finishDate' }),
      width: '16.6%',
    },
    {
      name: intl.formatMessage({ id: 'common.progress' }),
      width: '16.6%',
    },
    {
      name: intl.formatMessage({ id: 'common.status' }),
      width: '16.6%',
    },
    {
      name: intl.formatMessage({ id: 'common.responsible' }),
      width: '16.6%',
    },
  ];

  function save(id, newValue) {
    const newDeliverables = data?.map(item => {
      if (item.ID === id) {
        return {
          ...item,
          IsSelected: newValue,
        };
      }
      return item;
    });
    saveData({ Deliverables: newDeliverables });
  }

  function formatDeliverables() {
    return data?.map(deliverable => ({
      CustomComponent: () => (
        <Deliverable
          key={deliverable.ID}
          item={deliverable}
          save={save}
          readOnly={readOnly}
          showCheckbox={showCheckbox}
        />
      ),
    }));
  }

  return (
    <div className="deliverables-acceptance__fields-list">
      <FieldsList
        titleStyles={{ color: themeColor }}
        title={intl.formatMessage({ id: 'processes.deliverables' })}
        titles={items}
        lists={formatDeliverables()}
        showDeliverablesHint={showDeliverablesHint}
      />
    </div>
  );
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps)(Deliverables));
