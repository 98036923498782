import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Panel from '../Panel';
import Table from '../Table';
import { getProjectLocalizedName, getStatusName } from '../../Helpers';
import ProjectTaskModal from '../ProjectTaskModal';
import routes from '../../routes';

class NewProjectsPanel extends React.Component {
  state = {
    modalOpen: false,
  };

  getColumns = () => {
    const { intl, statuses } = this.props;
    return [
      {
        accessor: data => getProjectLocalizedName(data, intl?.locale),
        // accessor: `Title${suffix}`, // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.title' }),
        width: 200,
        id: 'Title',
      },
      {
        accessor: rowData => (intl?.locale === 'ar' ? rowData.WorkflowItem.NameArabic : rowData.WorkflowItem.Name),
        Header: intl.formatMessage({ id: 'common.request-type' }),
        width: 200,
        id: 'request-type',
      },
      {
        accessor: data => moment(data.Created).format('DD-MMM-YYYY'),
        Header: intl.formatMessage({ id: 'common.creation-date' }),
        id: 'createdOn',
      },
      {
        accessor: data => data.RequestBy.DisplayName,
        Header: intl.formatMessage({ id: 'common.created-by' }),
        id: 'createdBy',
      },
      {
        accessor: ({ StatusID = 0 }) => {
          return getStatusName(statuses, StatusID.toString(), intl?.locale);
        },
        Header: intl.formatMessage({ id: 'common.status' }),
        id: 'status',
      },
    ];
  };

  handleRowClick = data => {
    const { history, requests } = this.props;
    if (requests) {
      history.push(routes.processEditPreview(data.original.WorkflowItem.Code.toLowerCase(), data.original.ID));
    }
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { intl, isLoading, projects, tasksClassifications, taskApprovalStatuses } = this.props;
    const { modalOpen } = this.state;
    return (
      <>
        <Panel
          title={intl.formatMessage({ id: 'taskCenter.my-pmo-processes' })}
          count={projects.length}
          isCollapsable
          render={() => (
            <Table
              columns={this.getColumns()}
              data={projects}
              showPagination={false}
              sortable={false}
              onRowClick={data => this.handleRowClick(data)}
              isLoading={isLoading}
              numOfLoadingRows={4}
              pageSize={1000}
            />
          )}
        />
        <ProjectTaskModal
          isOpen={modalOpen}
          task={{}}
          onClose={this.handleModalClose}
          locale={intl.locale}
          tasksClassifications={tasksClassifications}
          taskApprovalStatuses={taskApprovalStatuses}
        />
      </>
    );
  }
}
const mapStateToProps = ({ theme }) => ({
  statuses: theme.Request_statuses.item.Items,
});

export default withRouter(injectIntl(connect(mapStateToProps)(NewProjectsPanel)));
