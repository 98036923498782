import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Timeago from '../../Timeago';
import Avatar from '../../Avatar';
import { hexToRgbA } from '../../../Helpers';
import routes from '../../../routes';
import UsersAPIController from '../../../providers/controllers/UsersAPIController';
import { getNotificationsAction } from '../../../actions/userActions';

const NotificationItem = ({ notification, theme, locale }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handeClick = () => {
    new UsersAPIController().markNotificationRead(notification.ID).then(() => {
      dispatch(getNotificationsAction());
    });
    if (!notification.Params) {
      history.push(routes.tasks('', 'approvals'));
      return;
    }
    const params = JSON.parse(notification.Params);
    switch (params.TYPE) {
      case 'RISK':
        history.push(
          routes.details(routes.risks(routes[params.ENTITYTYPE.toLowerCase()](params.PROJECTUID)), params.ID),
        );
        break;
      case 'ISSUE':
        history.push(
          routes.details(routes.issues(routes[params.ENTITYTYPE.toLowerCase()](params.PROJECTUID)), params.ID),
        );
        break;
      // case 'NEWPROJECT':
      //   history.push(routes.details('/newproject', params.ID));
      //   break;
      case 'Phase':
        history.push(`/project/${routes.phasesGateReview(params.PROJECTUID)}`);
        break;
      case 'ESCALATION': {
        const parentRoute = params.RELATEDITEMTYPENAME === 'RISK' ? routes.risks : routes.issues;
        history.push(
          routes.details(parentRoute(routes[params.ENTITYTYPE.toLowerCase()](params.PROJECTUID)), params.RELATEDITEMID),
        );
        break;
      }

      default:
        history.push(routes.tasks('', 'approvals'));
    }
  };
  return (
    <div
      key={notification.ID}
      className="body-content"
      onClick={handeClick}
      style={{ backgroundColor: notification.IsRead ? '#FFFFFF' : hexToRgbA(theme.ThemeColor.item.Text, 0.15) }}
    >
      <div className="icon">
        <Avatar username={notification.SenderUsername} width={50} />
      </div>
      <div className="notification-details">
        <div className="notification__body">
          <b>{locale === 'ar' ? notification.TextAr : notification.Text}</b>
        </div>
        <Timeago date={notification.TimeStamp} withIcon />
      </div>
    </div>
  );
};

export default NotificationItem;
