import React from 'react';
import { FormattedMessage } from 'react-intl';

import Field from '../Field';

import './style.scss';

function ProcessesTextArea({ title, onSave, data, readOnly, name, titleColor, required }) {
  function handleChange(e) {
    onSave({ [name]: e.currentTarget.value });
  }

  return (
    <div className="textarea-section">
      <span className="title" style={{ color: titleColor }}>
        <FormattedMessage id={title} />
      </span>
      <div className="processes-textarea-content">
        {readOnly ? (
          <p>{data}</p>
        ) : (
          <Field
            control={{
              type: 'textarea',
              name,
              value: data,
              onChangeAction: handleChange,
              disabled: readOnly,
              required,
              error: !data,
            }}
          />
        )}
      </div>
    </div>
  );
}

function ProcessesTextField({ title, onSave, data, readOnly, name, required, classes }) {
  function handleChange(e) {
    onSave({ [name]: e.currentTarget.value });
  }

  return (
    <div className={`info-field ${classes}`}>
      <Field
        label={{
          label: title,
        }}
        control={{
          type: 'input',
          name,
          value: data,
          onChangeAction: handleChange,
          disabled: readOnly,
          required,
          error: !data,
        }}
      />
    </div>
  );
}
export { ProcessesTextArea, ProcessesTextField };
