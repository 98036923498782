import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formattedDate } from '../../../../Helpers';

const typeMap = {
  affecting: ['AffectingProject', 'AffectingTask', 'affectingTasks'],
  affected: ['AffectedProject', 'AffectedTask', 'affectedTasks'],
};

const ProjectSection = ({ formData = {}, themeColor, locale, type }) => {
  const suffix = locale === 'ar' ? 'Ar' : '';

  return (
    <div className="id-section">
      <div className="id-subTitle">
        <h5>
          <FormattedMessage id={`id.${type}-project`} />
        </h5>
      </div>
      <div className="id-section-content">
        <div className="id-section-row">
          <div className="control preview">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.projectTitle" />
            </span>
            <div>{formData[`${typeMap[type][0]}Name${suffix}`]}</div>
          </div>
          <div className="control">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id={`id.${type}-task`} />
            </span>
            <div>{formData[`${typeMap[type][1]}Name`]}</div>
          </div>
          <div className="control preview date">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.startDate" />
            </span>
            <div>{formattedDate(formData[`${typeMap[type][1]}StartDate`])}</div>
          </div>
          <div className="control preview date">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.finishDate" />
            </span>
            <div>{formattedDate(formData[`${typeMap[type][1]}FinishDate`])}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSection;
