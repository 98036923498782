import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import Table from '../Table';

import { formattedDate } from '../../Helpers';

import './style.scss';

const ProcessHistory = ({ history, intl }) => {
  const themeColor = useSelector(state => state.theme.ThemeColor.item.Text);
  const processesStatuses = useSelector(state => state.theme.ProcessesStatuses.item.Items);

  const getColumns = () => {
    return [
      {
        accessor: data => {
          const findItem = processesStatuses.find(item => {
            return item.Value === data.Action;
          });
          return intl.locale === 'ar' ? findItem?.Arabic : findItem?.English;
        }, // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.status' }),
        id: 'status',
      },
      {
        accessor: data => data.ActionBy.DisplayName,
        Header: intl.formatMessage({ id: 'common.created-by' }),
        id: 'ActionBy',
      },
      {
        accessor: data => formattedDate(data.ActionDate),
        Header: intl.formatMessage({ id: 'common.creation-date' }),
        id: 'creationDate',
      },
      {
        accessor: data =>
          moment(data.ActionDate)
            .locale('en')
            .format('HH:mm'),
        Header: intl.formatMessage({ id: 'common.creation-time' }),
        id: 'creationTime',
      },
      {
        accessor: data => <span>{data.Comment}</span>,
        Header: intl.formatMessage({ id: 'common.comment' }),
        id: 'comment',
      },
    ];
  };
  return (
    <div className="process-history">
      <div className="table-with-label-title">
        <span style={{ color: themeColor }} className="title">
          <FormattedMessage id="process.history" />
        </span>
      </div>
      <div className="table-with-label-table">
        <Table
          columns={getColumns()}
          data={history}
          showPagination={false}
          sortable={false}
          // isLoading={isLoading}
          numOfLoadingRows={4}
        />
      </div>
    </div>
  );
};

export default injectIntl(ProcessHistory);
