import React from 'react';
import { connect } from 'react-redux';

import TitledContent from '../TitledContent';

import { updateTaskPercentage, updateNonProjectTaskPercentage } from '../../actions/tasksCenter';

import './loader.scss';

// eslint-disable-next-line
class TaskRow extends React.Component {
  render() {
    return (
      <div className="taskRowLoader__container">
        <div className="taskRowLoader__starting">
          <div className="taskRowLoader__status animated-background" />
          <TitledContent title={<div className="taskRowLoader__title animated-background" />} fontSize="12px">
            <div className="taskRowLoader__details">
              <span className="taskRowLoader__details-item">
                <div className="animated-background" />
                <div className="animated-background" />
              </span>
              <span className="taskRowLoader__details-item">
                <div className="animated-background" />
                <div className="animated-background" />
              </span>
              <span className="taskRowLoader__details-item">
                <div className="animated-background" />
                <div className="animated-background" />
              </span>
              <span className="taskRowLoader__details-item">
                <div className="animated-background" />
                <div className="animated-background" />
              </span>
              <span className="taskRowLoader__details-item">
                <div className="animated-background" />
                <div className="animated-background" />
              </span>
            </div>
          </TitledContent>
        </div>
        <div className="taskRowLoader__trailing">
          <div className="animated-background" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme, language }) => ({
  locale: language.locale,
  tasksClassifications: theme.TasksClassifications.item.Items,
  taskApprovalStatuses: theme.task_approval_statuses.item.Items,
  theme,
});

const mapDispatchToProps = {
  updateTaskPercentageAction: updateTaskPercentage,
  updateNonProjectTaskPercentageAction: updateNonProjectTaskPercentage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskRow);
