import React from 'react';
import { getStatusColor, getStatusName } from '../../Helpers';

import './style.scss';

const Badge = props => {
  const { theme, Status, locale } = props;

  return (
    <div className="recoverable">
      <div
        className="recover"
        style={{
          backgroundColor: `${getStatusColor(theme, Status).trim()}22`,
          color: getStatusColor(theme, Status),
          borderColor: getStatusColor(theme, Status),
        }}
      >
        <p>{getStatusName(theme.Statuses.item.Items, Status, locale)}</p>
      </div>
    </div>
  );
};

export default Badge;
