import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import DashboardInitiatives from '../../../components/DashboardInitiatives';
import DashboardInitiative from '../../../components/DashboardInitiative';
import DashboardInitiativeDetails from '../../../components/DashboardInitiativeDetails';
import DashboardProjectDetails from '../../../components/DashboardProjectDetails';
import DashboardBreadcrumbs from '../../../components/DashboardBreadcrumbs';

import { filterProjectsByEntity, filterProjectsByProgram } from '../../../Helpers';
import { getAllEntities, getAllPrograms, getAllProjects, getAllRisks, getAllIssues } from '../../../actions/dashboard';

import './styles.scss';

import routes from '../../../routes';
import { dashboardsRoutes } from '../../../routes/routes';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredProjects: [],
      selectedEntity: '',
      selectedProgram: '',
    };
  }

  componentDidMount() {
    const {
      getAllEntitiesAction,
      getAllProgramsAction,
      getAllProjectsAction,
      getAllRisksAction,
      getAllIssuesAction,
    } = this.props;
    getAllEntitiesAction();
    getAllProgramsAction();
    getAllProjectsAction();
    getAllRisksAction();
    getAllIssuesAction();
  }

  componentDidUpdate(prevProps) {
    const {
      dashboard: { projects },
    } = this.props;
    if (prevProps.dashboard.projects !== projects) {
      this.setFilteredProjects();
    }
  }

  getTotalItems = (list, type) => {
    return list.reduce((accum, item) => {
      const _accum = accum + item[type];

      return _accum;
    }, 0);
  };

  getAllFilterItem = () => {
    const { intl } = this.props;
    const name = intl.formatMessage({ id: 'common.all' });
    return { NameArabic: name, NameEnglish: name, Code: null };
  };

  getOptions = type => {
    const {
      props: { dashboard },
      state: { selectedEntity },
    } = this;
    const isProgram = type === 'programs';
    const targetArray = dashboard[type];
    const options = [this.getAllFilterItem()];

    targetArray.forEach(item => {
      if (isProgram && selectedEntity) {
        if (item.Portfolio && item.Portfolio.Code === selectedEntity.Code) {
          options.push(item);
        }
      } else {
        options.push(item);
      }
    });

    return options;
  };

  setFilteredProjects = () => {
    const {
      props: {
        dashboard: { projects },
      },
      state: { selectedEntity, selectedProgram },
    } = this;
    let _projects = filterProjectsByEntity(projects, selectedEntity);
    _projects = filterProjectsByProgram(_projects, selectedProgram);

    this.setState({
      filteredProjects: _projects,
    });
  };

  handleFilterChange = (value, type) => {
    const { selectedProgram } = this.state;
    let _selectedProgram = selectedProgram;

    if (type === 'selectedEntity') _selectedProgram = '';
    if (type === 'selectedProgram') _selectedProgram = value.Code ? value : '';

    this.setState(
      {
        [type]: value.Code ? value : '',
        selectedProgram: _selectedProgram,
      },
      () => {
        this.setFilteredProjects();
      },
    );
  };

  renderDashboardRoutes = () => {
    return dashboardsRoutes.map(({ to, Component }) => (
      <Route path={routes.dashboards(to)} key={to} name={to} render={props => <Component {...props} />} exact />
    ));
  };

  render() {
    const { filteredProjects } = this.state;

    return (
      <div className="dashboard__container">
        <DashboardBreadcrumbs />
        <div className="dashboard-content">
          <Switch>
            <Route
              exact
              path="/dashboard/initiatives"
              render={props => <DashboardInitiatives {...props} filteredProjects={filteredProjects} />}
            />

            <Route
              exact
              path={routes.dashboardInitiative()}
              render={props => <DashboardInitiative {...props} filteredProjects={filteredProjects} />}
            />

            <Route
              exact
              path="/dashboard/initiatives/:initiativeCode/:programCode"
              render={props => <DashboardInitiativeDetails {...props} filteredProjects={filteredProjects} />}
            />
            <Route
              exact
              path="/dashboard/initiatives/:initiativeCode/:programCode/:projectCode"
              component={DashboardProjectDetails}
              // render={props => <DashboardProjectDetails {...props} filteredProjects={filteredProjects} />}
            />
            {this.renderDashboardRoutes()}
            <Redirect from="/dashboard" to="/dashboard/initiatives" />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard, theme, language }) => ({
  dashboard,
  theme,
  locale: language.locale,
});

const mapDispatchToProps = {
  getAllEntitiesAction: getAllEntities,
  getAllProgramsAction: getAllPrograms,
  getAllProjectsAction: getAllProjects,
  getAllRisksAction: getAllRisks,
  getAllIssuesAction: getAllIssues,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
