import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as Businesswoman } from '../../assets/images/businesswoman.svg';
import { ReactComponent as Man } from '../../assets/images/man.svg';
import { ReactComponent as Stand } from '../../assets/images/stand.svg';
import { ReactComponent as Teamwork } from '../../assets/images/teamwork.svg';
import DashboardBarChart from '../DashboardBarChart';
import DoubleCircularProgress from '../DoubleCircularProgress';
import AsideCards from './asideCards';
import RolesPopUp from './RolesPopUp';
import ManPowerChart from './ManPowerChart';
import Panel from '../Panel';
import moment from 'moment';
// import routes from '../../routes';

import { getClassificationColor, getStatusName } from '../../Helpers';

import './style.scss';

function CapacityPlannerComponent({ history, theme, utilizationCapacity, resourcesRoles, intl, locale }) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');

  function getResourcesUtilizationColor() {
    if (utilizationCapacity?.ResourcesUtilization > 70) {
      return '#499732';
    }
    if (utilizationCapacity?.ResourcesUtilization < 50) {
      return '#E2463A';
    }
    return '#FFBF2B';
  }

  function getCustomRoleTooltipValue({ index }) {
    return `${utilizationCapacity?.Roles[index]?.Shortage * 100}%`;
  }

  function customRoleLabel(value) {
    return `${value}%`;
  }

  const barRolesData = utilizationCapacity?.Roles.map(item => {
    return item.Utilization;
  });

  const barRolesLabels = utilizationCapacity?.Roles.map(item => {
    return { name: getStatusName(theme?.ResourceRoles?.item?.Items || [], item.Role, locale) };
  });

  const barRolesColors = utilizationCapacity?.Roles.map(item => {
    return getClassificationColor(theme?.UtilizationStatuses?.item?.Items || [], item.Status);
  });

  const customTooltipTitle = intl.formatMessage({ id: 'dashboard.resources-shortage' });

  return (
    <div className="capacity-planner-dashboard">
      <div className="capacity-planner-header-card" style={{ borderColor: theme.ThemeColor.item.Text }}>
        <FormattedMessage id="common.capacity-planner-dashboard" /> ({moment().format('MMMM')} {moment().format('YYYY')}
        )
      </div>
      <div className="colored-card-container">
        <div
          className="colored-card"
          onClick={() => {
            setTitle({ title: 'Program Manager', data: resourcesRoles.programManagers });
            setIsOpen(!isOpen);
          }}
        >
          <div>
            <div className="title">
              <FormattedMessage id="dashboard.program-manager" />
            </div>
            <div className="number">{resourcesRoles?.programManagers?.length}</div>
          </div>
          <div className="icon">
            <Man />
          </div>
        </div>

        <div
          className="colored-card"
          onClick={() => {
            setTitle({ title: 'Program Leader', data: resourcesRoles?.ProgramLeaders });
            setIsOpen(!isOpen);
          }}
          style={{ backgroundColor: '#B41931' }}
        >
          <div>
            <div className="title">
              <FormattedMessage id="common.program-leader" />
            </div>
            <div className="number">{resourcesRoles?.ProgramLeaders?.length}</div>
          </div>
          <div className="icon">
            <Stand />
          </div>
        </div>
        <div
          className="colored-card"
          onClick={() => {
            setTitle({ title: 'Project Manager', data: resourcesRoles?.projectMangers });

            setIsOpen(!isOpen);
          }}
          style={{ backgroundColor: '#EB5A3D' }}
        >
          <div>
            <div className="title">
              <FormattedMessage id="common.projectManager" />
            </div>
            <div className="number">{resourcesRoles?.projectMangers?.length}</div>
          </div>
          <div className="icon">
            <Businesswoman />
          </div>
        </div>
        <div
          className="colored-card"
          onClick={() => {
            setTitle({ title: 'Project Coordinator', data: resourcesRoles?.projectCoordinators });

            setIsOpen(!isOpen);
          }}
          style={{ backgroundColor: '#FF9619' }}
        >
          <div>
            <div className="title">
              <FormattedMessage id="common.projectcoordinator" />
            </div>
            <div className="number">{resourcesRoles?.projectCoordinators?.length}</div>
          </div>
          <div className="icon">
            <Teamwork />
          </div>
        </div>
      </div>
      <div className="second-section">
        <div className="cards-section">
          <div className="cards-first-section">
            <div className="aside-cards-container">
              <AsideCards
                title={intl.formatMessage({ id: 'common.No-of-Active-Resourses' })}
                text={utilizationCapacity?.Resources.length}
                borderColor={theme.ThemeColor.item.Text}
                textColor={theme.ThemeColor.item.Text}
              />
            </div>
            <div className="aside-cards-container">
              <AsideCards
                title={intl.formatMessage({ id: 'common.ResourcesRequried' })}
                text={utilizationCapacity?.CountRequriedResources}
                textColor={theme.ThemeColor.item.Text}
                borderColor={theme.ThemeColor.item.Text}
              />
            </div>
            <div className="aside-cards-container">
              <AsideCards
                title={intl.formatMessage({ id: 'common.AVGEndMonth' })}
                text={utilizationCapacity?.AVGEndMonth}
                textColor={theme.ThemeColor.item.Text}
                borderColor={theme.ThemeColor.item.Text}
              />
            </div>
            <div className="aside-cards-container">
              <AsideCards
                title={intl.formatMessage({ id: 'common.AVGEndYear' })}
                text={utilizationCapacity?.AVGEndYear}
                textColor={theme.ThemeColor.item.Text}
                borderColor={theme.ThemeColor.item.Text}
              />
            </div>
          </div>
          <div className="cards-first-section">
            <div className="aside-cards-container">
              <AsideCards
                title={intl.formatMessage({ id: 'common.UtilizedMostRole' })}
                text={() => (
                  <span className="aside-cards-container__content">
                    <p>
                      {getStatusName(
                        theme?.ResourceRoles?.item?.Items || [],
                        utilizationCapacity?.UtilizedMostRole,
                        locale,
                      )}
                    </p>
                    <p>{utilizationCapacity?.UtilizedMostRoleUtilization}%</p>
                  </span>
                )}
                textColor={theme.ThemeColor.item.Text}
                borderColor="#E2463A"
              />
            </div>
            <div className="aside-cards-container">
              <AsideCards
                title={intl.formatMessage({ id: 'common.UtilizedLeastRole' })}
                text={() => (
                  <span className="aside-cards-container__content">
                    <p>
                      {getStatusName(
                        theme?.ResourceRoles?.item?.Items || [],
                        utilizationCapacity?.UtilizedLeastRole,
                        locale,
                      )}
                    </p>
                    <p>{utilizationCapacity?.UtilizedLeastRoleUtilization}%</p>
                  </span>
                )}
                textColor={theme.ThemeColor.item.Text}
                borderColor="#499732"
              />
            </div>
            <div className="aside-cards-container">
              <AsideCards
                title={intl.formatMessage({ id: 'common.UtilizedMostResource' })}
                text={() => (
                  <span className="aside-cards-container__content">
                    <p>{utilizationCapacity?.UtilizedMostResource?.DisplayName}</p>
                    <p>{utilizationCapacity?.UtilizedMostResourceUtilization}%</p>
                  </span>
                )}
                textColor={theme.ThemeColor.item.Text}
                borderColor="#E2463A"
              />
            </div>
            <div className="aside-cards-container">
              <AsideCards
                title={intl.formatMessage({ id: 'common.UtilizedLeastResource' })}
                text={() => (
                  <span className="aside-cards-container__content">
                    <p>{utilizationCapacity?.UtilizedLeastResource?.DisplayName}</p>
                    <p>{utilizationCapacity?.UtilizedLeastResourceUtilization}%</p>
                  </span>
                )}
                textColor={theme.ThemeColor.item.Text}
                borderColor="#499732"
              />
            </div>
          </div>
        </div>
        <div className="capacity-planner-charts">
          <div className="capacity-planner-BarChart">
            <DashboardBarChart
              projects={utilizationCapacity?.Roles}
              barData={barRolesData}
              customLabels={barRolesLabels}
              backgroundColors={barRolesColors}
              title={intl.formatMessage({ id: 'common.UtilizationPerRole' })}
              customLabel={customRoleLabel}
              customTooltipTitle={customTooltipTitle}
              getCustomTooltipValue={getCustomRoleTooltipValue}
            />
          </div>
          <div
            className="capacity-planner-double-circle"
            onClick={() => history.push('/dashboard/capacity-planner/resources-utilization')}
          >
            <Panel
              count={null}
              title={intl.formatMessage({ id: 'common.current-month-utilization' })}
              render={() => (
                <>
                  <DoubleCircularProgress
                    outerData={{
                      value: utilizationCapacity?.ResourcesUtilization,
                      color: getResourcesUtilizationColor(),
                      displayText: true,
                      text: `${utilizationCapacity?.ResourcesUtilization}%`,
                      tipText: `${utilizationCapacity?.ResourcesUtilization}%`,
                    }}
                    strokeWidth={10}
                  />
                  <div className="footer">
                    <div className="average-sign">
                      <div>{'>70%'}</div>
                      <div className="colored-mark" style={{ backgroundColor: '#499732' }} />
                    </div>
                    <div className="average-sign">
                      <div> {'>50% v <70%'} </div>
                      <div className="colored-mark" style={{ backgroundColor: '#FFBF2B' }} />
                    </div>
                    <div className="average-sign">
                      <div> {'<50%'} </div>
                      <div className="colored-mark" style={{ backgroundColor: '#E2463A' }} />
                    </div>
                  </div>
                </>
              )}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <RolesPopUp
          title={title.title}
          onClose={() => setIsOpen(!isOpen)}
          data={title.data}
          theme={theme}
          locale={locale}
        />
      )}
      <ManPowerChart utilizationCapacity={utilizationCapacity} intl={intl} />
    </div>
  );
}
export default injectIntl(CapacityPlannerComponent);
