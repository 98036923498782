import React, { Component } from 'react';

import './style.scss';

class InputWithFormula extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromProps(props, state) {
    const { error } = state;
    const { settings } = props;
    if (settings.error !== error) {
      return {
        ...state,
        error: settings.error,
      };
    }
    return state;
  }

  defaultValidation = value => {
    return !value;
  };

  checkRequired = () => {
    const {
      settings: { required, value },
    } = this.props;

    return required && !value;
  };

  showError(show) {
    this.setState({ error: show });
  }

  render() {
    const {
      props: {
        settings: {
          keyRender,
          value,
          onChangeAction,
          placeholder,
          validation,
          validateOnExite,
          disabled = false,
          name,
          formula,
        },
      },
      state: { error },
    } = this;
    const _validation = validation || this.defaultValidation;

    return (
      <div className={`input-with-formula ${error || this.checkRequired() ? 'error' : ''}`}>
        <div className={`formula-container ${disabled ? 'formula-container--disabled' : ''}`}>
          <div>{formula}</div>
        </div>
        <input
          name={name}
          className="input-control"
          value={value || ''}
          key={keyRender}
          disabled={disabled}
          onChange={e => onChangeAction(e)}
          type="number"
          placeholder={placeholder}
          onBlur={validateOnExite ? () => this.showError(_validation(value)) : null}
        />
      </div>
    );
  }
}

export default InputWithFormula;
