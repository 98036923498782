import React, { PureComponent } from 'react';
import moment from 'moment';
import AttachmentsList from '../../CommentAttachments';
import './style.scss';

class ActionItem extends PureComponent {
  render() {
    const { data, usernameColor, locale } = this.props;
    const DetailsAR = data.DetailsAR ? data.DetailsAR : data.Details;
    return (
      <div className="action-item-container">
        <div className="action-item-header">
          <div style={{ color: usernameColor }}>
            {(data.CreatedByUserModel && data.CreatedByUserModel.DisplayName) || data.CreaatedBy}
          </div>
          <div>{moment(data.CreatedDate).format('DD MMMM YYYY')}</div>
        </div>
        <div className="action-item-details">{locale === 'ar' ? DetailsAR : data.Details}</div>
        <AttachmentsList Attachments={data.Attachments || []} isDelete={false} />
      </div>
    );
  }
}

export default ActionItem;
