import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as CheckIcon } from '../../assets/images/CheckIcon.svg';
import './style.scss';

const StepIcon = props => {
  const { step } = props;
  const defaultIcon = () => {
    return <div className="node in-active" />;
  };
  const getIcon = () => {
    const { theme } = props;
    const ApprovedColor = '#4caf50';
    switch (step.Status) {
      case 3:
        return (
          <div
            className="node"
            style={{
              backgroundColor: `${ApprovedColor.trim()}22`,
              borderColor: '#fff',
              boxShadow: ` 0 0 0 2px ${'#4caf50'}`,
            }}
          >
            <CheckIcon fill={ApprovedColor} />
          </div>
        );
      case 2:
      case 1:
        return (
          <div
            className="node"
            style={{
              backgroundColor: `${theme.ThemeColor.item.Text.trim()}22`,
              borderColor: '#fff',
              boxShadow: ` 0 0 0 2px ${theme.ThemeColor.item.Text}`,
            }}
          />
        );

      default:
        return defaultIcon();
    }
  };

  return getIcon();
};

const mapStateToProps = state => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps, null)(StepIcon);
