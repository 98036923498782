import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import DashboardSummaryCards from '../DashboardSummaryCards';
import DashboardBarChart from '../DashboardBarChart';
import DashboardProgramsList from '../DashboardProgramsList';
import SummaryInfoCard from '../SummaryInfoCard';
import DashboardProgramHeader from '../DashboardProgramHeader';
import DashboardFilters from '../DashboardFiltersPopUp';

import ProjectsPopUp from '../ProjectsPopUp';
import RiskIssuesPopUp from '../RiskIssuesPopUp';

import { ReactComponent as BagIcon } from '../../assets/images/bag.svg';
import { ReactComponent as DocumentIcon } from '../../assets/images/document.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/warning.svg';
import { ReactComponent as FireIcon } from '../../assets/images/fire.svg';
import { ReactComponent as LightBulbIcon } from '../../assets/images/light-bulb.svg';

import { getDashboardInitiativeStats } from '../../Helpers';

import './style.scss';

function DashboardInitiative({ dashboard = {}, intl, filteredProjects, match, locale, theme, history, language }) {
  const initialStats = {
    projects: [],
    programs: [],
    entity: {
      Manager: {},
    },
  };

  const initialStatePopUps = {
    projects: { isOpen: false, type: '', index: '' },
    initiative: { isOpen: false },
    riskissues: { isOpen: false, type: '' },
  };
  const [popUpsState, setpopUpsState] = useState(initialStatePopUps);

  const [stats, setStats] = useState(initialStats);
  const { summary, loaders } = dashboard;
  const { code } = match.params;

  const openPopUp = (mainType, type, index) => {
    setpopUpsState({
      ...popUpsState,
      [mainType]: { isOpen: true, type, index },
    });
  };

  const closePopUp = mainType => {
    setpopUpsState({
      ...popUpsState,
      [mainType]: { isOpen: false },
    });
  };

  const summaryData = [
    {
      id: 'Portfolios.Portfolios',
      value: summary.entities,
      Icon: BagIcon,
      badgeColor: '#152836',
      Component: SummaryInfoCard,
      compProps: { user: stats.entity.Manager },
    },
    {
      id: 'common.programs',
      value: stats.programsCount,
      Icon: LightBulbIcon,
      badgeColor: '#642045',
    },
    {
      id: 'dashboard.projects',
      value: stats.projectsCount,
      Icon: DocumentIcon,
      badgeColor: '#81BDBB',
      onClick: () => openPopUp('projects'),
    },
    {
      id: 'common.risks',
      value: stats.risksCount,
      Icon: WarningIcon,
      badgeColor: '#FE9F00',
      onClick: () => openPopUp('riskissues', 'risks'),
    },
    {
      id: 'common.issues',
      value: stats.issuesCount,
      Icon: FireIcon,
      badgeColor: '#CE1818',
      onClick: () => openPopUp('riskissues', 'issues'),
    },
  ];

  function onApplyFilters(filters) {
    const newStats = getDashboardInitiativeStats(dashboard, filteredProjects, code, filters);
    setStats(newStats);
  }

  useEffect(() => {
    const newStats = getDashboardInitiativeStats(dashboard, filteredProjects, code);
    setStats(newStats);
  }, [dashboard, filteredProjects, code]);

  const financialInfoLabels = [
    {
      name: intl.formatMessage({ id: 'datacard.budget' }),
      color: '#642045',
    },
    {
      name: intl.formatMessage({ id: 'datacard.allocated' }),
      color: '#152836',
    },
    {
      name: intl.formatMessage({ id: 'dashboard.spent' }),
      color: '#F1993F',
    },
    {
      name: intl.formatMessage({ id: 'common.remaining-budget' }),
      color: '#6D2177',
    },
  ];

  return (
    <>
      <div className="dashboard-initiative">
        <div className="dashboard-initiative__header">
          <DashboardProgramHeader data={stats.entity} />
        </div>
        <DashboardSummaryCards data={summaryData} loading={loaders.allProjects} />
        <div className="dashboard__info">
          <DashboardBarChart
            type="byStatus"
            projects={stats.projects}
            title={intl.formatMessage({ id: 'dashboard.project-count-by-status' })}
            loading={loaders.allProjects}
            onClick={(data, values) => {
              if (data) {
                openPopUp('projects', 'Status', values[data._index]);
              }
            }}
          />
          <DashboardBarChart
            type="byPhase"
            projects={stats.projects}
            title={intl.formatMessage({ id: 'dashboard.project-count-by-phase' })}
            loading={loaders.allProjects}
            onClick={(data, values) => {
              if (data) {
                openPopUp('projects', 'Phase', values[data._index]);
              }
            }}
          />
          <DashboardBarChart
            type="financial"
            barData={stats.financialInfo}
            customLabels={financialInfoLabels}
            title={intl.formatMessage({ id: 'dashboard.financial-info' })}
            projects={stats.projects}
            loading={loaders.allProjects}
          />
        </div>

        {popUpsState.projects.isOpen && (
          <ProjectsPopUp
            projects={
              popUpsState.projects.index
                ? stats.groupedByData[popUpsState.projects.type][popUpsState.projects.index]
                : stats.projects
            }
            intl={intl}
            locale={locale}
            theme={theme}
            isOpen={popUpsState.projects.isOpen}
            onClose={() => {
              closePopUp('projects');
            }}
            history={history}
          />
        )}

        {popUpsState.riskissues.isOpen && (
          <RiskIssuesPopUp
            intl={intl}
            theme={theme}
            locale={locale}
            onClose={() => closePopUp('riskissues')}
            data={popUpsState.riskissues.type === 'risks' ? stats.risks : stats.issues}
            type={popUpsState.riskissues.type}
          />
        )}

        <div className="dashboard-initiatives__list-container">
          <DashboardProgramsList
            programs={stats.programs}
            projects={stats.projects}
            code={code}
            theme={theme}
            language={language}
            history={history}
          />
        </div>
      </div>
      <DashboardFilters onApplyFilters={onApplyFilters} project />
    </>
  );
}

const mapStateToProps = ({ dashboard, theme, language }) => ({
  dashboard,
  theme,
  locale: language.locale,
  language,
});

export default injectIntl(connect(mapStateToProps)(DashboardInitiative));
