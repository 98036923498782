import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Loader from './Loader';

import { getProjectLocalizedName } from '../../Helpers';

import './style.scss';

function DashboardProgramHeader({ locale, data = {}, backgroundColor, showPhase, loading, customTitle, customPhase }) {
  const isAr = locale === 'ar';

  return loading ? (
    <Loader />
  ) : (
    <div className="dashboard-program-header" style={{ backgroundColor }}>
      <span className="title">{customTitle || getProjectLocalizedName(data, locale)}</span>
      {showPhase && (
        <span className="title phase">
          <FormattedMessage id="common.phase" /> :{' '}
          {customPhase || (isAr ? data.CustomFields?.PhaseArabic : data.CustomFields?.PhaseEnglish)}
        </span>
      )}
    </div>
  );
}

const mapStateToProps = ({ language }) => ({
  locale: language.locale,
});

export default connect(mapStateToProps)(DashboardProgramHeader);
