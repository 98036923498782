import {
  GET_ALL_ENTITIES,
  GET_ALL_PROGRAMS,
  GET_ALL_PROJECTS,
  GET_ALL_RISKS,
  GET_ALL_ISSUES,
} from '../actions/dashboard';
import { getClassification, getIssueClassification } from '../Helpers';

const initialState = {
  loaders: {
    allEntities: true,
    allPrograms: true,
    allProjects: true,
    allRisks: true,
    allIssues: true,
  },
  summary: {
    entities: 0,
    programs: 0,
    projects: 0,
    totalBudget: 0,
    risks: 0,
    issues: 0,
  },
  entities: [],
  programs: [],
  projects: [],
  risks: [],
  issues: [],
};

const getTotalItems = (list, type) => {
  return list.reduce((accum, item) => {
    const _accum = accum + item[type];

    return _accum;
  }, 0);
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LOGOUT': {
      return {
        ...initialState,
      };
    }
    case GET_ALL_ENTITIES: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          allEntities: false,
        },
        summary: {
          ...state.summary,
          entities: payload.length,
        },
        entities: payload,
      };
    }
    case GET_ALL_PROGRAMS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          allPrograms: false,
        },
        summary: {
          ...state.summary,
          programs: payload.length,
        },
        programs: payload,
      };
    }
    case GET_ALL_PROJECTS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          allProjects: false,
        },
        summary: {
          ...state.summary,
          projects: payload.length,
          totalBudget: getTotalItems(payload, 'Budget'),
          risks: getTotalItems(payload, 'Risks'),
          issues: getTotalItems(payload, 'Issues'),
        },
        projects: payload,
      };
    }
    case GET_ALL_RISKS:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          allRisks: false,
        },
        risks: payload.map(item => ({ ...item, Classification: getClassification(item) })),
      };

    case GET_ALL_ISSUES:
      return {
        ...state,
        loaders: {
          ...state.loaders,
          allIssues: false,
        },
        issues: payload.map(item => ({ ...item, Classification: getIssueClassification(item) })),
      };

    default:
      return state;
  }
};

export default dashboardReducer;
