import UrlAPIControllers, { projectService } from '../config/urlServiceConfig';
import HttpServiceConfig from '../config/httpServiceConfig';

export default class PortfolioaAPIController {
  getAllPortfolio = () => {
    const FETCH_URL = new UrlAPIControllers().allEntitiesURL();
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getPortfoiloByCode = code => {
    const FETCH_URL = `${new UrlAPIControllers().portfoiloByCode(code)}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getPortfoiloLiquidity = code => {
    const FETCH_URL = `${projectService}/portfolio/${code}/Financial`;
    return new HttpServiceConfig().get(FETCH_URL);
  };
}
