import React from 'react';
// import { injectIntl, FormattedMessage } from 'react-intl';

import './style.scss';

function asideCards({ title = 'title', text = 'text', textColor = 'red', borderColor = 'red' }) {
  let textTorRender = text;

  if (typeof text === 'function') {
    textTorRender = text();
  } else if (typeof text === 'number') {
    textTorRender = parseFloat(text)?.toFixed(2);
  }

  return (
    <div className="aside-cards-component" style={{ borderColor }}>
      <div className="title">{title}</div>
      <div className="number" style={{ color: textColor }}>
        {textTorRender}
      </div>
    </div>
  );
}
export default asideCards;
