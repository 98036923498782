const layoutRoutes = {
  base: (path = '') => path,
  portfolios: '/portfolios',
  dashboard: '/dashboard',
  interdependencies: '/interdependencies',
  resourcesUtilization: '/dashboard/capacity-planner/resources-utilization',
  tasks: (path = '', id = '') => `${path}/tasks/${id}`,
  programs: (code = ':code') => `/programs/${code}`,
  projects: (code = ':code') => `/projects/${code}`,
  portfolio: (code = ':code') => `/portfolio/${code}`,
  program: (code = ':code') => `/program/${code}`,
  project: (code = ':code') => `/project/${code}`,
  details: (path = '', id = ':id') => `${path}/details/${id}`,
  escalations: (path = '', id = ':id?', code = ':code') => `${path}/${code}/escalations/${id}`,
  actionLog: (path = '', id = ':id', code = ':code') => `${path}/${code}/action-log/${id}`,
  preview: (path = '') => `${path}/preview`,
  processes: (path = '') => `${path}/processes`,
  risks: (path = '') => `${path}/risks`,
  issues: (path = '') => `${path}/issues`,
  documents: (path = '') => `${path}/documents`,
  milestones: (path = '') => `${path}/milestones`,
  priority: (path = '') => `${path}/priority`,
  financial: (path = '') => `${path}/financial`,
  progress: (path = '') => `${path}/progress`,
  deliverables: (path = '') => `${path}/deliverables`,
  add: (path = '') => `${path}/add`,
  edit: (path = '', id = ':id') => `${path}/edit/${id}`,
  phasesGateReview: (path = '') => `${path}/phase-gate`,
  createProcess: (process = ':process') => `/processes/${process}`,
  processEditPreview: (process = ':process', id = ':id') => `/processes/${process}/${id}`,
  workflow: (path = '') => `${path}/workflow`,
  dashboards: (dashboardName = '') => `/dashboard/${dashboardName}`,
  dashboardInitiative: (code = ':code') => `/dashboard/initiatives/${code}`,
  reports: '/reports',
};

export default layoutRoutes;
