import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import groupBy from 'lodash-es/groupBy';
import { ReactComponent as DownArrowIcon } from '../../../assets/images/down-arrow.svg';
import DoubleCircularProgress from '../../../components/DoubleCircularProgress';
import VerticalChip from '../../../components/VChip';
import ProgressBar from '../../../components/ProgressBar';
import BarChart from '../../../components/BarChart';
import { ReactComponent as PortfolioOwner2 } from '../../../assets/images/portfolio_owner_icon.svg';
import { ReactComponent as PortfolioIcon } from '../../../assets/images/portfolio.svg';
import { getStatusColor, getStatusName, nFormatter } from '../../../Helpers';

import './initiativeCard.scss';

class InitiativeCard extends Component {
  constructor() {
    super();
    this.state = {
      expandable: false,
      animation: false,
    };
  }

  render() {
    const { program, onClick = () => null, theme, language, intl, ProjectsPhases, details, match } = this.props;

    const isPortfolio = match.url.toLowerCase().includes('portfolio');
    const themeColor = theme.ThemeColor.item.Text;
    const deliverables = intl.formatMessage({ id: 'program.deliverables' });
    const milestones = intl.formatMessage({ id: 'program.milestones' });
    const adaa = intl.formatMessage({ id: 'program.adaa' });
    const risksAndIssues = intl.formatMessage({ id: 'program.risksAndIssues' });
    const budget = program.Budget;
    const projectsBudget = program.ProjectsBudget;
    const liquidityAmount = program.LiquidityAmount;
    const liquiditySpent = program.LiquiditySpent;
    const allocated = isPortfolio ? liquidityAmount : projectsBudget;

    const getProjectsPerPhase = () => {
      const phasesCopy = [...ProjectsPhases];
      const dataToUse = language.locale === 'ar' ? phasesCopy.reverse() : phasesCopy;

      const labels = dataToUse.map(item => (language.locale === 'ar' ? item.Arabic : item.English));
      const phases = dataToUse.map(item => item.Value);
      const datasets = [
        {
          data: phases.map(val => (program.Projects || []).filter(_project => _project.ProjectPhase === val).length),
          backgroundColor: dataToUse.map(phase => phase.Style),
          barThickness: 25,
        },
      ];

      return {
        labels,
        datasets,
      };
    };

    const group = groupBy(program.Projects, 'ProjectPhase');

    const { labels, datasets } = getProjectsPerPhase();
    const { expandable, animation } = this.state;

    const options = {
      plugins: { datalabels: { display: false } },
      // Elements options apply to all of the options unless overridden in a dataset
      responsive: true,
      scales: {
        yAxes: [
          {
            position: language.locale === 'ar' ? 'right' : 'left',
            ticks: {
              display: true,
              autoSkip: true,
              maxTicksLimit: 4,
              minTicksLimit: 4,
              beginAtZero: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    };
    return (
      <div className="initiativeCard">
        <div
          className={`initiativeCard__main slider ${!details && 'cursor'}`}
          onClick={onClick}
          style={{
            borderTopColor: theme.ThemeColor.item.Text,
          }}
        >
          <div className="program-content">
            <div className="title">
              {language.locale === 'en' && program.NameEnglish ? program.NameEnglish : program.NameArabic}
            </div>
            <div className="projects-with-status">
              <div className="program-status">
                <div
                  className="status-line"
                  style={{
                    backgroundColor: getStatusColor(theme, program.Status),
                  }}
                />
                <span
                  className="text"
                  style={{
                    color: getStatusColor(theme, program.Status),
                  }}
                >
                  {getStatusName(theme.Statuses.item.Items, program.Status, language.locale)}
                </span>
              </div>
              <div className={`${language.locale === 'en' ? '' : 'rtl'} projects`}>
                <div className={`${language.locale === 'en' ? '' : 'rtl'} icon`}>
                  <PortfolioIcon
                    style={{
                      width: '15px',
                      height: '15px',
                      fill: themeColor,
                    }}
                  />
                </div>
                <div className={`${language.locale === 'en' ? '' : 'rtl'} text initiativeCard__main-totalProjects`}>
                  <FormattedMessage id="programs.total-project" />: <div className="value">{program.ProjectsCount}</div>
                </div>
              </div>
            </div>
            <div className={`${language.locale === 'en' ? '' : 'rtl'} portfolio-owner`}>
              <div className={`${language.locale === 'en' ? '' : 'rtl'} icon`}>
                <PortfolioOwner2 fill="none" stroke={themeColor} />
              </div>
              <div className={`${language.locale === 'en' ? '' : 'rtl'} text`}>
                <FormattedMessage id={isPortfolio ? 'programs.portfolio-owner' : 'programs.program-owner'} /> :
              </div>
              <div className="owner-name">{program.Manager.DisplayName}</div>
            </div>
          </div>
          <div className="initiativeCard__main-stats">
            <div className="initiativeCard__main-stats-left">
              <div className="initiativeCard__main-stat">
                <VerticalChip countText={program.Deliverables} color="#65B2D3" text={deliverables} />
              </div>
              <div className="initiativeCard__main-stat">
                <VerticalChip countText={program.Milestones} color="#474B57" text={milestones} />
              </div>
            </div>
            <div className="initiativeCard__main-stats-body">
              <DoubleCircularProgress
                outerData={{
                  value: program.Progress,
                  color: themeColor,
                  displayText: true,
                  text: `${program.Progress}%`,
                  tipText: `${
                    language.locale === 'en' && program.NameEnglish ? program.NameEnglish : program.NameArabic
                  } - ${program.Progress}%`,
                }}
                innerData={{
                  value: program.BaselineProgress,
                  color: '#485A77',
                  displayText: false,
                  tipText: `ADA'A - ${program.BaselineProgress}%`,
                }}
              />
            </div>
            <div className="initiativeCard__main-stats-right">
              <div className="initiativeCard__main-stat">
                <VerticalChip countText={`${program.BaselineProgress}%`} color="#485A77" text={adaa} />
              </div>
              <div className="initiativeCard__main-stat">
                <VerticalChip countText={program.Risks + program.Issues} color="#CE0000" text={risksAndIssues} />
              </div>
            </div>
          </div>
          <div className="initiativeCard__main-footer">
            <div className="initiativeCard__main-footer-item">
              <ProgressBar
                firstLineValue={budget > 0 ? 100 : 0}
                firstLineColor="#474b57"
                firstLineTitle={intl.formatMessage({ id: 'datacard.budget' })}
                secondValue={`${nFormatter(budget, 1, language.locale)} `}
              />
            </div>
            <div className="initiativeCard__main-footer-item">
              <ProgressBar
                firstLineValue={budget !== 0 ? (allocated / budget) * 100 : 0}
                firstLineColor="#1b8ebf"
                firstLineTitle={intl.formatMessage({ id: 'program.allocated' })}
                secondValue={`${nFormatter(allocated, 1, language.locale)} `}
              />
            </div>
            <div className="initiativeCard__main-footer-item">
              <ProgressBar
                firstLineValue={allocated !== 0 ? (liquiditySpent / allocated) * 100 : 0}
                firstLineColor="rgb(90, 90, 164)"
                firstLineTitle={intl.formatMessage({ id: 'projectliquidity.spent' })}
                noMargin
                secondValue={` ${nFormatter(liquiditySpent, 1, language.locale)} `}
              />
            </div>
          </div>
          {program.Projects ? (
            <>
              <div
                className="expandable-container"
                onClick={e => {
                  e.stopPropagation();
                  this.setState({ expandable: !expandable, animation: true });
                }}
              >
                <div>
                  <FormattedMessage id="common.phases" defaultMessage="Phases" />
                </div>
                <div className={expandable ? 'rotate-icon rotate' : 'rotate-icon '}>
                  <DownArrowIcon
                    style={{
                      height: '10px',
                      width: '13px',
                    }}
                  />
                </div>
              </div>
              {expandable ? (
                <div className="initiativeCard__barChart">
                  <div className="initiativeCard__barChart-canvas">
                    <BarChart data={{ labels, datasets }} options={options} />
                  </div>
                </div>
              ) : (
                <div className={`small-bar-chart-outside-container ${animation && 'animation'}`}>
                  <div className="small-bar-chart-container">
                    {ProjectsPhases.map((data, index) => (
                      <div
                        className={language.locale === 'en' ? 'small-bar-chart-colors' : 'small-bar-chart-colors ltr'}
                        key={index}
                      >
                        <div>{group[data.Value] ? group[data.Value].length : 0} </div>
                        <div className="line" style={{ backgroundColor: data.Style }} />
                        <div className="small-bar-chart-titles">
                          {' '}
                          {language.locale === 'en' ? data.English : data.Arabic}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(withRouter(InitiativeCard));
