import { css } from '@emotion/core';

const sideText = rtl => (rtl ? '90deg' : '-90deg');

export const container = () => css`
  position: relative;
  display: flex;
  height: calc(100vh - 304px);
  margin-top: 20px;
  box-sizing: border-box;
`;
export const chartContainer = () => css`
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 8px 0px #eeeeee;
  /* padding-top: 15px; */
  padding-inline-end: 15px;
  margin-inline-start: 50px;
  box-sizing: border-box;
`;
export const row = () => css`
  display: flex;
  width: 100%;
  height: calc(100% - 80px);
`;
export const arrow = (isCollapsed, rtl) => {
  const Collapsed = css`
    bottom: 10px;
    margin-inline-start: 8px;
    width: 15px;
    transform: rotate(${rtl ? '90deg' : '-90deg'});
  `;
  return css`
    position: absolute;
    transform: rotate(${rtl ? '-90deg' : '90deg'});
    transition: all 500ms ease;
    ${isCollapsed ? Collapsed : ''}
  `;
};
export const listSection = (isCollapsed, rtl) => css`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgray;
  padding: ${isCollapsed ? '0px' : '0px 10px'};
  height: ${isCollapsed ? '100%' : '40px'};
  transition: all 500ms ease;
  box-sizing: border-box;
  cursor: pointer;
  p {
    ${isCollapsed ? 'margin-inline-start: -15px;' : ''}
    transform: rotate(${isCollapsed ? sideText(rtl) : 0});
    transition: all 500ms ease;
  }
`;
export const chart = () => css`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f4fbf5;
  /* overflow: hidden; */
`;
export const yaxis = rtl => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  width: 40px;
  p,
  h4 {
    transform: rotate(${sideText(rtl)});
    margin: 0;
  }
  p {
    font-size: 14px;
    color: gray;
  }
`;
export const xaxis = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-inline-start: 40px;
  padding: 0 10px;
  p,
  h4 {
    margin: 0;
  }
  h4 {
    span {
      font-size: 16px;
    }
  }
  p {
    font-size: 14px;
    color: gray;
  }
`;
export const app = () => css`
  position: relative;
  height: calc(100%);
  width: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;
export const curveMust = () => css`
  position: absolute;
  width: 108%;
  height: 500%;
  top: -250%;
  left: -67%;
  transform: rotate(30deg);
  z-index: -1;
  border-right: 2px dashed #ff6347;
  background-color: #fff6f4;
`;
export const curveShould = () => css`
  position: absolute;
  width: 108%;
  height: 500%;
  top: -250%;
  left: -29%;
  z-index: -2;
  transform: rotate(30deg);
  border: 2px dashed #ffa400;
  background-color: #fffbf0;
`;
export const curveWont = () => css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -3;
  background-color: #f4fbf5;
`;
export const circle = color => css`
  height: 20px;
  width: 20px;
  position: absolute;
  border-radius: 50%;
  margin-inline-start: -20px;
  background-color: ${color || 'green'};
  border: 3px solid white;
  box-sizing: border-box;
`;
export const projectsList = isCollapsed => css`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: ${isCollapsed ? '0' : '100'};
  width: ${isCollapsed ? '30px' : '250px'};
  height: 100%;
  background-color: ${isCollapsed ? 'lightgray' : 'white'};
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);
  margin-inline-end: 20px;
  overflow: hidden;
  transition: all 500ms ease;
`;
export const list = css`
  height: calc(100% - 40px);
  background-color: 'white';
  overflow-y: auto;
`;
export const listItem = (inChart, primaryColor) => css`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  padding-inline-start: 30px;
  box-sizing: border-box;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid lightgray;
  overflow: hidden;
  * {
    pointer-events: none;
  }
  p {
    padding: 0 5px;
    font-size: 12px;
    margin: 0;
  }
  &:hover {
    background-color: ${primaryColor}0f;
  }
  ${inChart
    ? `
  &::before {
  content: "";
  background-color: ${primaryColor};
  transform: rotate(45deg);
  position: absolute;
  top: -10px;
  left: -10px;
  height: 20px;
  width: 20px;
  }
  `
    : ''}
`;
export const section = () => css`
  position: relative;
  width: 50%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid black;
  box-sizing: border-box;
  h3 {
    position: absolute;
    right: 0;
  }
`;
export const sub = () => css`
  text-align: center;
  width: 2%;
  height: 2%;
  p {
    margin: 5px 0;
  }
`;

export const badge = () => css`
  position: absolute;
  background-color: red;
  color: white;
  border: 1px solid white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const tooltip = drag => css`
  position: absolute;
  background-color: white;
  width: 200px;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  transition: box-shadow 100ms;
  box-shadow: ${drag ? '15px 15px 10px 0px rgba(0, 0, 0, 0.3)' : '5px 5px 10px 0px rgba(0, 0, 0, 0.6)'};
  * {
    margin: 0;
  }
  h5,
  h6 {
    text-align: center;
    font-size: 12px;
  }
  h5 {
    margin-bottom: 10px;
    /* color: gray; */
  }
  p {
    font-size: 12px;
  }
  &::after {
    content: '';
    width: 30px;
    height: 15px;
    background-color: white;
    position: absolute;
    bottom: 0px;
    left: 85px;
  }
  &::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 5px;
    transform: rotate(45deg);
    background-color: white;
    position: absolute;
    transition: box-shadow 100ms;
    bottom: -5px;
    left: 92.5px;
    box-shadow: ${drag ? '15px 15px 10px 0px rgba(0, 0, 0, 0.3)' : '5px 5px 10px 0px rgba(0, 0, 0, 0.6)'};
  }
`;

export const tooltipRow = () => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding-bottom: 5px;
`;

export const progress = rtl => css`
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: whitesmoke;
  div {
    position: absolute;
    background: red;
    ${rtl ? 'right: 0;' : 'left: 0;'}
    top: 0;
    height: 8px;
    border-radius: 4px;
  }
`;
