import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ProgramsAPIController from '../../providers/controllers/ProgramsAPIController';

import Main from '../ProgramMilestones/Main';

function Milestones({ programCode, theme }) {
  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getProgramMilestones() {
    try {
      const res = await new ProgramsAPIController().getProgramMilestones(programCode);
      setMilestones(res.Data);
    } catch (err) {
      console.log({ err });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProgramMilestones();
  }, [programCode]);

  return (
    <div className="initiative-details__milestones">
      <Main milestones={milestones} theme={theme} loading={loading} readOnly />
    </div>
  );
}

const mapStateToProps = ({ theme, language }) => ({
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(Milestones);
