import React from 'react';

import StateNavigator from '../StateNavigator';
import Main from './Main';
import Details from './Details';

import './styles.scss';

class Financial extends React.Component {
  state = {
    selectedComponent: 'main',
    selectedProject: null,
  };

  handleSelectedComponentChange = selectedComponent => {
    this.setState({
      selectedComponent,
    });
  };

  // eslint-disable-next-line
  handleProjectClick = project => {
    this.setState({
      selectedComponent: 'view',
      selectedProject: project,
    });
  };

  render() {
    const {
      state: { selectedComponent, selectedProject },
      props: { match, theme, program },
    } = this;
    const componentsList = [
      {
        name: 'main',
        component: (
          <Main
            match={match}
            theme={theme}
            onNavigate={this.handleSelectedComponentChange}
            onProjectClick={this.handleProjectClick}
            data={program}
          />
        ),
      },
      {
        name: 'view',
        component: <Details match={match} onNavigate={this.handleSelectedComponentChange} project={selectedProject} />,
      },
    ];

    return <StateNavigator componentsList={componentsList} selectedComponentName={selectedComponent} />;
  }
}

export default Financial;
