import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

// eslint-disable-next-line import/no-cycle
import Folder from '../Folder';
import ImpulseBalls from '../BouncyBalls/ImpulseBalls';
import { ReactComponent as PDFIcon } from '../../assets/images/pdf-icon-2.svg';
import { ReactComponent as DOCIcon } from '../../assets/images/doc-icon-2.svg';
import { ReactComponent as TextDocIcon } from '../../assets/images/text-document.svg';
import { ReactComponent as ImgIcon } from '../../assets/images/img-icon.svg';
import { ReactComponent as BlankFileIcon } from '../../assets/images/attached.svg';
import { ReactComponent as ExcelIcon } from '../../assets/images/excel-icon.svg';
import { ReactComponent as PowerPointIcon } from '../../assets/images/powerpoint-icon.svg';
import { getAttachmentFileType, trimFileName, downloadAttachment } from '../../Helpers';

import { addNewSection as addNewSectionAction } from '../../actions/docsLibrary';

import './styles.scss';

const imageTypes = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg'];
const renderIcon = file => {
  const type = getAttachmentFileType(file).toLowerCase();
  switch (true) {
    case type === 'pdf':
      return <PDFIcon />;
    case type === 'doc' || type === 'docx':
      return <DOCIcon />;
    case type === 'txt':
      return <TextDocIcon />;
    case type === 'xls' || type === 'xlsx':
      return <ExcelIcon />;
    case type === 'ppt' || type === 'pptx':
      return <PowerPointIcon />;
    case imageTypes.indexOf(type) >= 0:
      return <ImgIcon />;
    default:
      return <BlankFileIcon />;
  }
};

const File = ({ file, match, theme, addNewSection, parentType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const isFolder = file.Type === 'Folder';
  useEffect(() => {
    if (isFolder) {
      addNewSection(file.FileName);
    }
  }, [file]);

  if (isFolder) {
    // call Folder Component
    return (
      <Folder
        theme={theme}
        projectUID={match.params.code}
        listType={file.FileName}
        parentType={parentType}
        title={file.FileName}
        upload={parentType === 'library'}
        folder={file.SPURL}
        subFolder
      />
    );
  }

  const handleDownload = () => {
    setIsLoading(true);
    downloadAttachment(file).then(() => setIsLoading(false));
  };

  return (
    <div className="file__container">
      <div onClick={handleDownload} className="file__anchor">
        {renderIcon(file)}
        <div className="file__name-container">
          <p title={file.FileName} className="file__file-name">
            {trimFileName(file.FileName, 80)}
          </p>
          {file.isNew ? (
            <div className="file__new-file">
              <FormattedMessage id="common.new" />
            </div>
          ) : null}
        </div>
        <div className="folder__loader">
          <ImpulseBalls size={20} frontColor={theme.ThemeColor.item.Text} loading={isLoading} />
        </div>
      </div>
    </div>
  );
};

File.propTypes = {
  file: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  theme: PropTypes.object,
  addNewSection: PropTypes.func.isRequired,
  parentType: PropTypes.string,
};

const mapDispatchToProps = {
  addNewSection: addNewSectionAction,
};

export default connect(null, mapDispatchToProps)(withRouter(injectIntl(File)));
