import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '../../Button';
import Field from '../../Field';

import ProgramsAPIController from '../../../providers/controllers/ProgramsAPIController';
import { formattedDate, isValidDate, mergeAttachments, addToast } from '../../../Helpers';

import './styles.scss';

const initialValues = {
  ActualFinishDate: moment(),
  Attachments: [],
  CompletedWorkPercentage: 0,
  CompletedWorkWeight: 0,
  PlannedFinishDate: moment(),
  Title: '',
};

class Add extends React.Component {
  constructor(props) {
    super(props);

    if (props.editMode) {
      const milestone = props.milestones.find(item => item.ID.toString() === props.match.params.id);
      let _ActualFinishDate = moment();
      let _PlannedFinishDate = moment();
      if (!milestone) {
        props.changeView('base');
      } else {
        const { ActualFinishDate, PlannedFinishDate } = milestone;
        _ActualFinishDate = isValidDate(ActualFinishDate) ? ActualFinishDate : _ActualFinishDate;
        _PlannedFinishDate = isValidDate(PlannedFinishDate) ? PlannedFinishDate : _PlannedFinishDate;
      }

      this.state = {
        ...initialValues,
        ...milestone,
        ActualFinishDate: _ActualFinishDate,
        PlannedFinishDate: _PlannedFinishDate,
        isPosting: false,
        deleteLoading: false,
      };
    } else {
      this.state = {
        loading: false,
        isPosting: false,
        ...initialValues,
      };
    }
  }

  throwError = (messageId = 'common.fillRequiredFields') => {
    addToast.error(messageId, {
      toastId: 'submit-deliverable-error',
    });
  };

  validateForm = () => {
    const {
      state: { Title, PlannedFinishDate, CompletedWorkPercentage },
    } = this;
    let formIsValid = true;

    if (!Title.length || !PlannedFinishDate || !CompletedWorkPercentage.toString().length) {
      formIsValid = false;
    }

    return formIsValid;
  };

  handleSubmit = async () => {
    const {
      state,
      state: { Attachments },
      props: { match, changeView, getAllMilestones },
      throwError,
    } = this;
    const _attachments = await mergeAttachments(Attachments || []);
    const body = {
      formData: {
        ...state,
        Attachments: _attachments,
        ActualFinishDate: formattedDate(state.ActualFinishDate),
        PlannedFinishDate: formattedDate(state.PlannedFinishDate),
        CompletedWorkPercentage: parseInt(state.CompletedWorkPercentage, 10),
        CompletedWorkWeight: parseInt(state.CompletedWorkWeight, 10),
      },
      programUID: match.params.code,
    };

    if (!this.validateForm()) throwError();
    else if (parseInt(state.CompletedWorkPercentage, 10) === 100 && (Attachments || []).length === 0) {
      throwError('milestones.must-upload-an-attachment');
    } else {
      this.setState({
        isPosting: true,
      });
      new ProgramsAPIController()
        .addMilestone(body)
        .then(() => {
          addToast.success('common.changesHaveBeenSaved');
          getAllMilestones();
          changeView('base');
          this.setState({
            isPosting: false,
          });
        })
        .catch(() => {
          addToast.error('common.errorSavingChanges', {
            toastId: 'submit-deliverable-error',
          });
        });
    }
  };

  handleDeleteMilestone = () => {
    const {
      props: { match, changeView, getAllMilestones },
    } = this;
    this.setState({ deleteLoading: true });
    new ProgramsAPIController().deleteMilestone(match.params.code, match.params.id).then(() => {
      this.setState({ deleteLoading: false });
      addToast.success('common.itemHasBeenDeleted');
      getAllMilestones();
      changeView('base');
    });
  };

  render() {
    const {
      state: {
        ID,
        Title,
        PlannedFinishDate,
        CompletedWorkPercentage,
        CompletedWorkWeight,
        ActualFinishDate,
        Attachments,
        loading,
        isPosting,
        deleteLoading,
      },
      props: { editMode, changeView, themeColor, locale, intl },
    } = this;

    return (
      <div className="milestone__container">
        <div className="milestone__content">
          <div className="milestone__header">
            <div className="milestone__title">
              <FormattedMessage id="projectdata.milestones" />
            </div>
            <div className="milestone__actions">
              <Button
                className="action secondary-action"
                type="submit"
                onClick={() => (ID ? changeView('details', ID) : changeView('base'))}
                color={themeColor}
                border
              >
                <FormattedMessage id="common.back" />
              </Button>
              {editMode ? (
                <Button
                  disabled={loading || deleteLoading}
                  className="action"
                  type="submit"
                  loading={deleteLoading}
                  onClick={this.handleDeleteMilestone}
                  backgroundColor={themeColor}
                >
                  <FormattedMessage id="common.delete" />
                </Button>
              ) : null}
              <Button
                disabled={
                  loading ||
                  isPosting ||
                  (CompletedWorkPercentage > 100 && Attachments === null) ||
                  (Attachments && Attachments.length === 0)
                }
                loading={isPosting}
                className="action"
                type="submit"
                onClick={this.handleSubmit}
                backgroundColor={themeColor}
              >
                <FormattedMessage id="common.save" />
              </Button>
            </div>
          </div>
          <form className={`milestone__form ${loading || isPosting ? 'loading' : ''}`}>
            <div className="milestone__form-col">
              <div className="milestone__form-control">
                <span className="label flex-v-center">
                  <FormattedMessage id="common.title" />
                </span>
                <Field
                  control={{
                    type: 'input',
                    onChangeAction: e => this.setState({ Title: e.target.value }),
                    placeholder: intl.formatMessage({ id: 'milestones.name' }),
                    value: Title,
                    disabled: true,
                    validateOnExite: true,
                    error: !Title.length,
                  }}
                />
              </div>
              <div className="milestone__form-control">
                <span className="label flex-v-center">
                  <FormattedMessage id="milestones.weighted-completed-work" />
                </span>
                <Field
                  control={{
                    type: 'number',
                    onChangeAction: e => this.setState({ CompletedWorkWeight: e.target.value }),
                    placeholder: intl.formatMessage({ id: 'milestones.weighted-completed-work' }),
                    value: CompletedWorkWeight,
                    disabled: true,
                  }}
                />
              </div>

              <div className="milestone__form-control">
                <span className="label flex-v-center">
                  <FormattedMessage id="deliverables.planned-finish-date" />
                </span>
                <Field
                  control={{
                    type: 'date',
                    disabled: true,
                    onChangeAction: date => this.setState({ PlannedFinishDate: date }),
                    locale,
                    placeholder: intl.formatMessage({ id: 'milestones.planned-finish-date' }),
                    value: PlannedFinishDate,
                    validateOnExite: true,
                    error: !PlannedFinishDate,
                  }}
                />
              </div>
            </div>
            <div className="milestone__form-col">
              <div className="milestone__form-control">
                <span className="label flex-v-center">
                  <FormattedMessage id="milestones.completed-work" />
                </span>
                <Field
                  control={{
                    type: 'number',
                    onChangeAction: e => this.setState({ CompletedWorkPercentage: e.target.value }),
                    placeholder: intl.formatMessage({ id: 'milestones.completed-work' }),
                    value: CompletedWorkPercentage,
                    error: !CompletedWorkPercentage.toString().length,
                  }}
                />
              </div>
              <div className="milestone__form-control">
                <span className="label flex-v-center">
                  <FormattedMessage id="deliverables.actual-finish-date" />
                </span>
                <Field
                  control={{
                    type: 'date',
                    locale,
                    onChangeAction: date => this.setState({ ActualFinishDate: date }),
                    placeholder: intl.formatMessage({ id: 'deliverables.actual-finish-date' }),
                    value: ActualFinishDate,
                  }}
                />
              </div>
            </div>
          </form>
          <div className="milestone__form-control milestone__form-control-standalone">
            <span className="label flex-v-center">
              <FormattedMessage id="projectrisk.attachments" />
            </span>
            <Field
              control={{
                type: 'attachment',
                onChangeAction: e => {
                  this.setState({ Attachments: [...e] });
                },
                value: Attachments || [],
                validateOnExite: false,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme, language }) => ({
  themeColor: theme.ThemeColor.item.Text,
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps, null)(Add));
