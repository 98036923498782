import React from 'react';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import CircularProgress from '../CircularProgress';

import './styles.scss';

class DoubleCircularProgress extends React.Component {
  state = {
    hovered: false,
  };

  handleHover = () => {
    this.setState(prevState => ({
      hovered: !prevState.hovered,
    }));
  };

  render() {
    const {
      state: { hovered },
      props: { outerData, innerData, strokeWidth },
    } = this;

    return (
      <div className="doubleCircularProgress" onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
        <CircularProgressbarWithChildren
          value={outerData.value}
          strokeWidth={strokeWidth || 6}
          styles={buildStyles({ pathColor: outerData.color })}
        >
          {outerData.displayText ? (
            <p className="outerData-text" style={{ color: outerData.color }}>
              {outerData.text}
            </p>
          ) : null}
          {innerData && <CircularProgress {...innerData} strokeWidth={5} onHover={this.handleHover} />}
        </CircularProgressbarWithChildren>
        {hovered ? <span className="doubleCircularProgress__tooltip">{outerData.tipText}</span> : null}
      </div>
    );
  }
}

export default DoubleCircularProgress;
