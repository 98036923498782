import React from 'react';

const PhaseChartLoader = () => (
  <div className="skeleton phase-gate-donut-shadow animated-background">
    <div className="shadow-background">
      <div className="dashboard-col-title shadow-background" />
    </div>
    <div className="donut-shadow-container">
      <div className="donut-shadow shadow-background" />
    </div>
  </div>
);

const BarChartLoader = () => {
  return (
    <div className="graph-container">
      <table className="graph">
        <tbody>
          <tr className="animated-background" style={{ height: '40%' }} />
          <tr className="animated-background" style={{ height: '40%' }} />
          <tr className="animated-background" style={{ height: '50%' }} />
          <tr className="animated-background" style={{ height: '50%' }} />
          <tr className="animated-background" style={{ height: '60%' }} />
          <tr className="animated-background" style={{ height: '60%' }} />
          <tr className="animated-background" style={{ height: '55%' }} />
          <tr className="animated-background" style={{ height: '65%' }} />
          <tr className="animated-background" style={{ height: '70%' }} />
          <tr className="animated-background" style={{ height: '70%' }} />
          <tr className="animated-background" style={{ height: '70%' }} />
          <tr className="animated-background" style={{ height: '80%' }} />
          <tr className="animated-background" style={{ height: '80%' }} />
        </tbody>
      </table>
    </div>
  );
};

const ProjectsListLoader = () => {
  return (
    <div className="graph-container">
      <div className="projects-list-shadow">
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
        <div className="animated-background" />
      </div>
    </div>
  );
};
export { PhaseChartLoader, BarChartLoader, ProjectsListLoader };
