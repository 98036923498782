/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { nFormatter } from '../../Helpers';
import * as styles from './styles';

const Tooltip = ({ tooltip, rtl, locale }) => {
  return (
    <React.Fragment>
      <div css={styles.tooltip(tooltip.drag)} style={{ bottom: tooltip.y, left: tooltip.x - 100 }}>
        <h5>{tooltip.Title}</h5>
        {/* <h6>{tooltip.subTitle}</h6> */}
        <div css={styles.tooltipRow}>
          <p>
            <FormattedMessage id="pdb.manager" defaultMessage="Manager" />
          </p>
          <p>{tooltip.SubTitle}</p>
        </div>
        <div css={styles.tooltipRow}>
          <p>
            <FormattedMessage id="pdb.budget" defaultMessage="Budget" />
          </p>
          <p>
            {nFormatter(tooltip.Budget, 1, locale)} <FormattedMessage id="common.SARShort" defaultMessage="SAR" />
          </p>
        </div>
        <div css={styles.tooltipRow} style={{ paddingTop: 5 }}>
          <p>
            <FormattedMessage id="pdb.progress" defaultMessage="Progress" />
          </p>
          <p>{tooltip.Progress}%</p>
        </div>
        <div css={styles.progress(rtl)}>
          <div style={{ width: `${tooltip.Progress}%`, backgroundColor: tooltip.Color }} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tooltip;
