import React from 'react';

const ImageMessage = props => {
  const { data } = props;
  return (
    <a target="_blank" href={data.url} rel="noreferrer noopener" className="sc-message--image">
      <img alt={data.fileName} src={data.url} />
    </a>
  );
};

export default ImageMessage;
