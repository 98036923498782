import React, { useState, useEffect } from 'react';
import ProcessesFormLayout from '../../../components/ProcessesFormLayout';
import ProjectClosureForm from '../../../components/ProjectClosureForm';
import ProcessesLoader from '../../../components/ProcessesLoader';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import { addToast } from '../../../Helpers';

function ProjectClosure({ readOnly, editData = {}, project = {}, refetchData, processType }) {
  const initialState = {
    Data: {
      ID: 0,
      RequestID: 0,
      Type: 'ProjectClosure',
      ProjectUID: project.CustomFields.ProjectUID,
      ProjectName: project.ProjectName,
      TitleEnglish: '',
      TitleArabic: '',
      LessonsLearned: '',
      IsClose: false,
      Notes: '',
      Vendor: '',
      VendorReview: 0,
      TeamReview: 0,
      Deliverables: [],
      Attachments: [],
    },
  };

  const [state, setState] = useState({ ...initialState, ...editData });
  const [loading, setLoading] = useState(false);

  function saveData(data) {
    setState({ ...state, Data: { ...state.Data, ...data } });
  }

  async function getProjectDeliverables() {
    try {
      const res = await new ProjectAPIController().getProjectDeliverables(project.CustomFields.ProjectUID);
      setLoading(false);
      if (res.Data) {
        saveData({
          Deliverables: res.Data,
        });
      }
    } catch (err) {
      addToast.error('processes.deliverables-fetch-failed');
    }
  }

  function inRange(val, min, max) {
    return val >= min && val <= max;
  }

  function checkDeliverablesCompletion() {
    const { Deliverables } = state.Data;
    return !!Deliverables.find(deliverable => deliverable.Progress !== 100 || deliverable.StatusID !== 3);
  }
  function validate() {
    const {
      LessonsLearned,
      Deliverables,
      Vendor,
      VendorReview,
      TeamReview,
      IsClose,
      TitleEnglish,
      TitleArabic,
    } = state.Data;

    let deliverables = IsClose;

    if (!IsClose && Deliverables.length) {
      deliverables = Deliverables.reduce(
        (acc, curr) => (acc && curr.CompletionPercentage !== 100) || curr.StatusID === 3 || curr.StatusID === 5,
        true,
      );
    }

    return !(
      Boolean(TitleEnglish) &&
      Boolean(TitleArabic) &&
      Boolean(LessonsLearned.length) &&
      Boolean(Vendor.length) &&
      inRange(VendorReview, 0, 10) &&
      inRange(TeamReview, 0, 10) &&
      deliverables &&
      !checkDeliverablesCompletion()
    );
  }

  useEffect(() => {
    if (state.Data.ID === 0) {
      getProjectDeliverables();
    } else {
      saveData({
        Deliverables: editData.Data.Deliverables,
      });
    }
  }, [project.CustomFields.ProjectUID]);

  return loading ? (
    <ProcessesLoader />
  ) : (
    <ProcessesFormLayout
      title="processes.project-closure"
      data={state}
      processType={processType}
      readOnly={readOnly}
      refetchData={refetchData}
      disabled={validate()}
    >
      <ProjectClosureForm
        project={project}
        processType={processType}
        request={state}
        saveData={saveData}
        readOnly={readOnly}
        showDeliverablesHint={checkDeliverablesCompletion()}
      />
    </ProcessesFormLayout>
  );
}

export default ProjectClosure;
