/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import { cloneDeep, groupBy } from 'lodash-es';

export const reduceCustomFields = project => {
  const customKeysObj = project.CustomFields
    ? project.CustomFields.reduce((acc, curr) => {
        acc[curr.Key.replace(/ /g, '')] = curr.Value;

        return acc;
      }, {})
    : project.CustomFields;

  return {
    ...project,
    CustomFields: customKeysObj,
  };
};

export const filterRisks = risks => {
  return risks.filter(risk => risk.Status === '(1) Active');
};

export const filterIssues = issues => {
  return issues.filter(issue => issue.Status === '(1) Active');
};

export const getAllocatedSum = programs => {
  let sum = 0;
  programs.forEach(program => {
    if (Array.isArray(program.Projects)) {
      sum += program.Projects.reduce((acc, curr) => acc + curr.Budget, 0);
    }
  });

  return sum;
};

export const getFinancialInfo = arr => {
  return arr.reduce(
    (acc, item) => {
      return [
        acc[0] + (item.Budget || 0),
        acc[1] + (parseInt(item?.ProjectsBudget, 10) || 0),
        acc[2] + (parseInt(item?.LiquiditySpent, 10) || 0),
        acc[3] + (parseInt(item?.ProjectsBudget - item?.LiquiditySpent, 10) || 0),
      ];
    },
    [0, 0, 0, 0],
  );
};

export const getDashboardInitiativesStats = (dashboard, allProjects, filters = {}) => {
  const { summary } = dashboard;

  const dashboardClone = cloneDeep(dashboard);
  let { entities, programs, risks, issues } = dashboardClone;
  risks = filterRisks(risks);
  issues = filterIssues(issues);
  let projects = allProjects.map(item => ({
    ...reduceCustomFields(item),
    Program: reduceCustomFields(item.Program || {}),
  }));
  programs = programs.map(item => reduceCustomFields(item));
  entities = entities.map(item => ({
    ...reduceCustomFields(item),
    Programs: item.Programs ? item.Programs.map(program => reduceCustomFields(program)) : [],
  }));

  // if (filters.vrp) {
  //   entities = entities
  //     .map(entity => {
  //       if (Array.isArray(entity.Programs)) {
  //         entity.Programs = entity.Programs.filter(program => {
  //           return (
  //             program.CustomFields?.VRP === filters.vrp.Value ||
  //             program.CustomFields?.VRP === filters.vrp.Arabic ||
  //             program.CustomFields?.VRP === filters.vrp.English
  //           );
  //         });
  //       }
  //
  //       return entity;
  //     })
  //     .filter(el => el.Programs?.length);
  //
  //   programs = programs.filter(program => {
  //     return (
  //       program.CustomFields?.VRP === filters.vrp.Value ||
  //       program.CustomFields?.VRP === filters.vrp.Arabic ||
  //       program.CustomFields?.VRP === filters.vrp.English
  //     );
  //   });
  //
  //   projects = projects.filter(project => {
  //     return (
  //       project.Program?.CustomFields?.VRP === filters.vrp.Value ||
  //       project.Program?.CustomFields?.VRP === filters.vrp.Arabic ||
  //       project.Program?.CustomFields?.VRP === filters.vrp.English
  //     );
  //   });
  // }

  if (filters.phase) {
    // entities = entities
    //   .map(entity => {
    //     if (Array.isArray(entity.Programs)) {
    //       entity.Programs = entity.Programs.filter(program => {
    //         return program.PhaseID.toString() === filters.phase.Value;
    //       });
    //     }
    //     return entity;
    //   })
    //   .filter(el => el.Programs?.length);
    //
    // programs = programs.filter(program => {
    //   return program.PhaseID.toString() === filters.phase.Value;
    // });

    projects = projects.filter(project => {
      return project.ProjectPhase.toString() === filters.phase.Value;
    });
  }

  if (filters.status) {
    entities = entities
      .map(entity => {
        if (Array.isArray(entity.Programs)) {
          entity.Programs = entity.Programs.filter(program => {
            return program.Status === filters.status.Value;
          });
        }
        return entity;
      })
      .filter(el => el.Programs?.length);

    programs = programs.filter(program => {
      return program.Status === filters.status.Value;
    });

    projects = projects.filter(project => {
      return project.Program?.Status === filters.status.Value;
    });
  }

  if (filters.fundStatus) {
    entities = entities
      .map(entity => {
        if (Array.isArray(entity.Programs)) {
          entity.Programs = entity.Programs.filter(program => {
            return program.CustomFields?.FundStatus === filters.fundStatus.Value;
          });
        }
        return entity;
      })
      .filter(el => el.Programs?.length);

    programs = programs.filter(program => {
      return program.CustomFields?.FundStatus === filters.fundStatus.Value;
    });

    projects = projects.filter(project => {
      return project.Program?.CustomFields?.FundStatus === filters.fundStatus.Value;
    });
  }

  if (filters.initiativeLeader) {
    entities = entities
      .map(entity => {
        if (Array.isArray(entity.Programs)) {
          entity.Programs = entity.Programs.filter(program => {
            return program.Manager?.DisplayName === filters.initiativeLeader.label;
          });
        }
        return entity;
      })
      .filter(el => el.Programs?.length);

    programs = programs.filter(program => {
      return program.Manager?.DisplayName === filters.initiativeLeader.label;
    });

    projects = projects.filter(project => {
      return project.Program?.Manager?.DisplayName === filters.initiativeLeader.label;
    });
  }

  if (filters.programManager) {
    projects = projects.filter(project => {
      return project.Program.Manager?.Username === filters.programManager.value;
    });
  }

  const uids = [...programs.map(el => el.ProjectUID), ...projects.map(el => el.ProjectUID)];

  risks = risks.filter(item => uids.includes(item.ProjectUID));
  issues = issues.filter(item => uids.includes(item.ProjectUID));

  // let projectsCount = 0;
  // programs.forEach(program => {
  //   projectsCount += program.ProjectsCount;
  // });

  return {
    entitiesCount: summary.entities,
    programsCount: programs.length,
    projectsCount: projects.length,
    risksCount: risks.length,
    issuesCount: issues.length,
    entities,
    projects,
    programs,
    risks,
    issues,
    financialInfo: getFinancialInfo(programs),
    groupedByData: {
      Status: groupBy(programs, 'Status'),
      Phase: groupBy(projects, 'ProjectPhase'),
    },
  };
};

export const getDashboardInitiativeStats = (dashboard, allProjects, code, filters = {}) => {
  const dashboardClone = cloneDeep(dashboard);
  let { risks, issues } = dashboardClone;

  risks = filterRisks(risks);
  issues = filterIssues(issues);

  const entity = dashboardClone.entities.find(el => el.Code === code) || { Manager: {} };
  let programs = dashboardClone.entities
    .filter(el => el.Code === code)
    .reduce((acc, curr) => {
      return acc.concat(curr.Programs);
    }, []);

  const ids = programs.map(el => el.Code);
  let projects = allProjects.filter(project => (project.Program ? ids.includes(project.Program.Code) : false));
  projects = projects.map(item => ({
    ...reduceCustomFields(item),
    Program: reduceCustomFields(item.Program || {}),
  }));
  programs = programs.map(item => reduceCustomFields(item));

  // if (filters.vrp) {
  //   programs = programs.filter(program => {
  //     return (
  //       program.CustomFields?.VRP === filters.vrp.Value ||
  //       program.CustomFields?.VRP === filters.vrp.Arabic ||
  //       program.CustomFields?.VRP === filters.vrp.English
  //     );
  //   });
  //
  //   projects = projects.filter(project => {
  //     return (
  //       project.Program?.CustomFields?.VRP === filters.vrp.Value ||
  //       project.Program?.CustomFields?.VRP === filters.vrp.Arabic ||
  //       project.Program?.CustomFields?.VRP === filters.vrp.English
  //     );
  //   });
  // }

  if (filters.phase) {
    // programs = programs.filter(program => {
    //   return (
    //     program.PhaseID.toString() === filters.phase.Value
    //     // program.CustomFields?.PhaseArabic === filters.phase.Arabic ||
    //     // program.CustomFields?.PhaseEnglish === filters.phase.English
    //   );
    // });

    projects = projects.filter(project => {
      return project.ProjectPhase === filters.phase.Value;
      // project.Program?.CustomFields?.PhaseArabic === filters.phase.Arabic ||
      // project.Program?.CustomFields?.PhaseEnglish === filters.phase.English
    });
  }

  if (filters.status) {
    // programs = programs.filter(program => {
    //   return program.Status === filters.status.Value;
    // });

    projects = projects.filter(project => {
      return project.Status === filters.status.Value;
    });
  }

  if (filters.fundStatus) {
    programs = programs.filter(program => {
      return program.CustomFields?.FundStatus === filters.fundStatus.Value;
    });

    projects = projects.filter(project => {
      return project.Program?.CustomFields?.FundStatus === filters.fundStatus.Value;
    });
  }

  if (filters.initiativeLeader) {
    programs = programs.filter(program => {
      return program.Manager?.DisplayName === filters.initiativeLeader.label;
    });

    projects = projects.filter(project => {
      return project.Program?.Manager?.DisplayName === filters.initiativeLeader.label;
    });
  }

  if (filters.programManager) {
    programs = programs.filter(program => {
      return program.Manager?.Username === filters.programManager.value;
    });

    projects = projects.filter(project => {
      return project.Program.Manager?.Username === filters.programManager.value;
    });
  }

  const uids = [...programs.map(el => el.ProjectUID), ...projects.map(el => el.ProjectUID)];

  risks = risks.filter(item => uids.includes(item.ProjectUID));
  issues = issues.filter(item => uids.includes(item.ProjectUID));

  return {
    entity,
    projects,
    programs,
    programsCount: programs.length,
    projectsCount: projects.length,
    risksCount: risks.length,
    issuesCount: issues.length,
    risks,
    issues,
    // financialInfo: [10, 20, 30, 40, 50],
    financialInfo: getFinancialInfo(programs),
    groupedByData: {
      Status: groupBy(projects, 'Status'),
      Phase: groupBy(projects, 'ProjectPhase'),
    },
    // financialInfo: [],
  };
};

export const getProjectsByProgram = (projects = [], program) => {
  return projects.filter(project => project.Program?.Code === program.Code);
};

export const getDashboardInitiativeDetailsStats = (dashboard, allProjects, entityCode, programCode, filters = {}) => {
  const dashboardClone = cloneDeep(dashboard);
  let { risks, issues } = dashboardClone;

  risks = filterRisks(risks);
  issues = filterIssues(issues);

  const entity = dashboardClone.entities.find(el => el.Code === entityCode);

  const initiative = entity ? entity.Programs.find(el => el.Code === programCode) : { Manager: {} };
  let projects = allProjects.filter(project => project.Program?.Code === programCode);

  if (filters.phase) {
    projects = projects.filter(project => {
      return project.ProjectPhase === filters.phase.Value;
    });
  }

  if (filters.status) {
    projects = projects.filter(project => {
      return project.Status === filters.status.Value;
    });
  }

  if (filters.manager) {
    projects = projects.filter(project => {
      return project.Manager?.Username === filters.manager.value;
    });
  }

  const cleanInitiative = reduceCustomFields(initiative);

  const uids = [initiative.ProjectUID];

  risks = risks.filter(item => uids.includes(item.ProjectUID));
  issues = issues.filter(item => uids.includes(item.ProjectUID));

  return {
    initiative: cleanInitiative,
    projects,
    projectsCount: projects.length,
    risksCount: risks.length,
    issuesCount: issues.length,
    financialInfo: [
      cleanInitiative.Budget || 0,
      parseInt(cleanInitiative?.CustomFields?.ContractsValue, 10) || 0,
      parseInt(cleanInitiative?.CustomFields?.Liquidity, 10) || 0,
      parseInt(cleanInitiative?.CustomFields?.ConsumedBudget, 10) || 0,
      cleanInitiative.RemainingBudget || 0,
    ],
    risks,
    issues,
    groupedByData: {
      Status: groupBy(projects, 'Status'),
      Phase: groupBy(projects, 'ProjectPhase'),
    },

    // financialInfo: getFinancialInfo(dashboard.projects),
  };
};
