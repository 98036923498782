import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Chip from '../Chip';
import TitledContent from '../TitledContent';
import RiskIssueModal from '../RiskIssueModal';

import {
  getClassificationColor,
  getClassification,
  getIssueClassification,
  getClassificationName,
} from '../../Helpers';

import './styles.scss';

class RiskIssueRow extends React.Component {
  state = {
    modalOpen: false,
  };

  handleModalOpen = e => {
    e.stopPropagation();

    this.setState(prevState => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  render() {
    const {
      state: { modalOpen },
      props: { item, type, risksClassifications, issuesClassifications, themeColor, locale },
      handleModalOpen,
    } = this;
    const statusText =
      type === 'risk'
        ? getClassificationName(risksClassifications, getClassification(item), locale)
        : getClassificationName(issuesClassifications, getIssueClassification(item), locale);
    const statusColor =
      type === 'risk'
        ? getClassificationColor(risksClassifications, getClassification(item))
        : getClassificationColor(issuesClassifications, getIssueClassification(item));
    const linkTextLocale = `common.view-${type}`;

    return (
      <div className="riskIssueRow__container" onClick={this.handleModalOpen} role="link" tabIndex={0}>
        <div className="riskIssueRow__starting">
          <div className="riskIssueRow__status">
            <Chip text={statusText} color={statusColor} />
          </div>
          <TitledContent title={item.Title} fontSize="12px">
            <div className="riskIssueRow__details">
              <span className="riskIssueRow__details-item">
                <FormattedMessage id="common.dueDate" />
                :&ensp;
                {moment(item.DueDate).format('DD-MMM-YYYY')}
              </span>
            </div>
          </TitledContent>
        </div>
        <div className="riskIssueRow__trailing" style={{ color: themeColor }}>
          <FormattedMessage id={linkTextLocale} />
        </div>
        <RiskIssueModal isOpen={modalOpen} onClose={handleModalOpen} item={item} type={type} />
      </div>
    );
  }
}

const mapStateToProps = ({ theme, language }) => ({
  locale: language.locale,
  risksClassifications: theme.RisksClassifications.item.Items,
  issuesClassifications: theme.IssuesClassifications.item.Items,
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(RiskIssueRow);
