import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Entity from '../containers/Views/Portfolio';
import Programs from '../containers/Views/Programs';
import ProjectDetails from '../containers/Views/ProjectDetails';
import ProgramDetails from '../containers/Views/ProgramDetails';
import TasksCenter from '../containers/Views/TasksCenter';
import Dashboard from '../containers/Views/Dashboard';
import AddProject from '../containers/Views/AddProject';
import Interdependencies from '../containers/Views/Interdependencies';
import ProjectPreview from '../containers/Views/AddProject/ProjectPreview';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Reports from '../containers/Views/Reports';
import CreateProcess from '../containers/Views/CreateProcess';
import ProcessesEditPreview from '../containers/Views/ProcessEditPreview';
import ResourcesUtilization from '../containers/Views/ResourcesUtilization';

import { getHandshakeAction, getNotificationsAction, getUserSecurityInfoAction } from '../actions/userActions';
import routes from '../routes';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyWord: '',
    };
  }

  componentDidMount() {
    this.getHandshake();
    this.fetchNotifications();
    this.getUserSecurityInfoAction();
    localStorage.removeItem('userUttered');
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.fetchNotifications();
    }
  }

  getHandshake = () => {
    const { getHandshakeAction: getHandshake } = this.props;
    getHandshake();
  };

  getUserSecurityInfoAction = () => {
    const { getUserSecurityInfoAction: getUserSecurityInfo } = this.props;
    getUserSecurityInfo();
  };

  fetchNotifications = () => {
    const { getNotificationsAction: getNotifications } = this.props;
    getNotifications();
  };

  filterEntities = event => {
    this.setState({
      keyWord: event.target.value,
    });
  };

  renderThemeRoutes = showPortfolioLevel => {
    const { keyWord } = this.state;

    if (showPortfolioLevel) {
      return [
        <Route
          path={routes.portfolios}
          name="Portfolios"
          render={() => <Entity keyWord={keyWord} {...this.props} />}
          key="portfolios"
        />,
        <Route path={routes.programs()} name="Programs" component={Programs} key="programs" />,
      ];
    }
    return <Route path={routes.programs('')} name="Programs" component={Programs} />;
  };

  render() {
    const {
      theme: { showPortfolioLevel },
    } = this.props;
    const redirectPath = showPortfolioLevel ? routes.portfolios : routes.programs('');
    return (
      <div className="app">
        <Header filterEntities={this.filterEntities} {...this.props} />
        <main className="main-container">
          <Switch>
            {this.renderThemeRoutes(showPortfolioLevel)}
            <Route path={routes.program()} name="ProgramDetails" component={ProgramDetails} />
            <Route path={routes.project()} name="ProjectDetails" component={ProjectDetails} />
            <Route
              path={routes.resourcesUtilization}
              exact
              name="Resources Utilization"
              component={ResourcesUtilization}
            />
            <Route path={routes.portfolio()} name="ProgramDetails" component={ProgramDetails} />
            <Route path={routes.tasks()} name="ProjectDetails" component={TasksCenter} />
            <Route path={routes.dashboard} name="Dashboard" component={Dashboard} />
            <Route path={routes.createProcess()} exact name="Create Process" component={CreateProcess} />
            <Route
              path={routes.processEditPreview()}
              exact
              name="Process Edit/Preview"
              component={ProcessesEditPreview}
            />
            {window.env.INTERDEPENDENCIES ? (
              <Route path={routes.interdependencies} name="Interdependencies" component={Interdependencies} />
            ) : null}
            <Route
              path={[routes.add('/newproject'), routes.edit('/newproject')]}
              name="AddProject"
              component={AddProject}
            />
            <Route path={routes.details('/newproject')} name="ProjectPreview" component={ProjectPreview} />
            <Route path={routes.reports} name="Reports" component={Reports} />
            <Redirect from="/" to={redirectPath} />
          </Switch>
        </main>
        <Footer {...this.props} />
      </div>
    );
  }
}

Layout.propTypes = {
  getHandshakeAction: PropTypes.func.isRequired,
  getNotificationsAction: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired,
};

const mapStateToProps = (state /* , ownProps */) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = {
  getNotificationsAction,
  getHandshakeAction,
  getUserSecurityInfoAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
