import React from 'react';

import { FormattedMessage } from 'react-intl';
import { ReactComponent as AttachmentIcon } from '../../../assets/images/attachment.svg';
import { ReactComponent as EscalationIcon } from '../../../assets/images/esc.svg';

import { ReactComponent as PlusIcon } from '../../../assets/images/add.svg';
import RiskLoader from '../../RiskIssueLoader';
import EmptyComponent from '../../EmptyComponent';
import Button from '../../Button';
import { getClassification, getClassificationColor, getClassificationName, renderMultiple } from '../../../Helpers';

import './style.scss';
import { ReportsUrl } from '../../../providers/config/urlServiceConfig';

const getClassName = (theme, item, locale) => {
  return getClassificationName(theme.RisksClassifications.item.Items, getClassification(item), locale);
};

function ProjectRisks(props) {
  const { risksList, theme, locale, loading, match, user, changeView, readOnly } = props;

  const PPlusRiskLog = locale === 'en' ? 'PPlusRiskLog-EN' : 'PPlusRiskLog-AR';

  return (
    <section className="project-risks-main-container">
      <div className="sec-header-container tab-header">
        <div className="title bold-text">
          <FormattedMessage id="projectrisks-main.risks" />
        </div>
        {!readOnly && (
          <div className="section-actions">
            {risksList.length !== 0 && !loading && (
              <>
                {ReportsUrl ? (
                  <a
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${ReportsUrl}${PPlusRiskLog}&rs:Command=Render&ProjectUID=${match.params.code}&LoginUserName=${user.user.Username}&rs:Format=PDF`}
                    className="action report-link"
                    style={{ background: theme.ThemeColor.item.Text }}
                  >
                    <FormattedMessage id="projectrisk.risk-log-report" />
                  </a>
                ) : null}
              </>
            )}

            <Button
              type="submit"
              backgroundColor={theme.ThemeColor.item.Text}
              onClick={() => changeView('add')}
              Icon={() => <PlusIcon className="action-icon" width="11px" height="11px" fill="#fff" />}
            >
              <FormattedMessage id="projectrisks-main.add-new" />
            </Button>
          </div>
        )}
      </div>
      <div className="main-risks-content tTab-content-container">
        <div className="list-container">
          {!loading && risksList.length === 0 && <EmptyComponent small />}
          {loading ? renderMultiple(key => <RiskLoader key={key} />, 5) : null}
          {risksList.map((item, i) => (
            <div className="list-item cursor" onClick={() => !readOnly && changeView('details', item.ID)} key={i}>
              <span
                className="label"
                style={{
                  background: getClassificationColor(theme.RisksClassifications.item.Items, getClassification(item)),
                }}
              >
                <p>{getClassName(theme, item, locale)}</p>
              </span>
              <div className="item-title">
                <p>{item.Title}</p>
                {(item.Attachments || []).length ? (
                  <span className="attachment text-blue">
                    <AttachmentIcon width="13px" height="13px" fill={theme.ThemeColor.item.Text} />
                  </span>
                ) : null}
                {item.IsEscalated ? (
                  <span className="attachment text-blue">
                    <EscalationIcon width="13px" height="13px" fill={theme.ThemeColor.item.Text} />
                  </span>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProjectRisks;
