import ChangeRequest from '../containers/Views/ChangeRequest';
import ProjectCharter from '../containers/Views/ProjectCharter';
import ProjectClosure from '../containers/Views/ProjectClosure';
import DeliverablesAcceptance from '../containers/Views/DeliverablesAcceptance';
// import DeliverablesAcceptance from '../containers/Views/DeliverablesAcceptance';
// import RiskIssueProcess from '../containers/Views/RiskIssueProcess';

// eslint-disable-next-line
export const compsMap = {
  changerequest: ChangeRequest,
  charter: ProjectCharter,
  projectclosure: ProjectClosure,
  // deliverablesacceptance: DeliverablesAcceptance,
  deliverableacceptance: DeliverablesAcceptance,
  // projectclosure: InitiativeClosure,
  // risk: RiskIssueProcess,
  // issue: RiskIssueProcess,
};
