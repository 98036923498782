import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from '../../Table';
import PortfolioAPIController from '../../../providers/controllers/PortfolioAPIController';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import { getProjectLocalizedName, numberWithCommas, addToast } from '../../../Helpers';
import SpentCell from '../SpentCell';

class Main extends React.Component {
  state = {
    projects: [],
    loadingFinancials: true,
  };

  componentDidMount() {
    const { data } = this.props;
    if (data.ProjectUID) {
      this.getFinancials();
    }
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (!prevProps.data.ProjectUID && data.ProjectUID) {
      this.getFinancials();
    }
  }

  getFinancials() {
    const { match, data } = this.props;

    if (match.url.includes('portfolio')) {
      new PortfolioAPIController()
        .getPortfoiloLiquidity(match.params.code)
        .then(res => {
          if (res.StatusCode === 'Success') {
            this.setState({
              projects: res.Data,
              loadingFinancials: false,
            });
          }
        })
        .catch(error => {
          addToast.error(error.message, {
            toastId: 'get-risks-error',
          });
        });
    } else if (data.Projects) {
      new ProjectAPIController()
        .getProjectLiquidity(match.params.code)
        .then(res => {
          if (res.StatusCode === 'Success') {
            const newList = data.Projects.map(project => {
              const financial = res.Data
                ? res.Data.find(fi => fi.ProjectUID === project.ProjectUID) || { Spent: 0, ID: 0 }
                : { Spent: 0, ID: 0 };
              return {
                ...project,
                ...financial,
              };
            });
            this.setState({
              projects: newList,
              loadingFinancials: false,
            });
          }
        })
        .catch(error => {
          addToast.error(error.message, {
            toastId: 'get-risks-error',
          });
        });
    } else {
      this.setState({
        projects: [],
        loadingFinancials: false,
      });
    }
  }

  handleSpentUpdate = (value, index) => {
    const { projects } = this.state;
    const newProjects = [...projects];
    newProjects[index] = { ...newProjects[index], Spent: parseInt(value, 10) };
    this.setState({
      projects: newProjects,
    });
  };

  getColumns = () => {
    const { intl, theme, match } = this.props;
    const portfolioPage = match.url.includes('portfolio');
    return [
      {
        accessor: data => getProjectLocalizedName(data, intl.locale),
        Header: intl.formatMessage({ id: portfolioPage ? 'common.programTitle' : 'common.projectTitle' }),
        width: '40%',
        id: 'ProjectTitle',
      },
      {
        accessor: data =>
          `${numberWithCommas(portfolioPage ? data.Allocated : data.Budget)} ${intl.formatMessage({
            id: 'common.SAR',
          })}`,
        Header: intl.formatMessage({ id: portfolioPage ? 'datacard.allocated' : 'datacard.budget' }),
        id: 'Budget',
        width: '25%',
      },
      {
        accessor: data => `${numberWithCommas(data.Spent)} ${intl.formatMessage({ id: 'common.SAR' })}`,
        Header: intl.formatMessage({ id: 'projectliquidity.spent' }),
        id: 'Spent',
        width: '35%',
        Cell: ({ original, index }) => (
          <SpentCell
            project={original}
            key={original.ID}
            theme={theme}
            index={index}
            intl={intl}
            handleSpentUpdate={this.handleSpentUpdate}
            match={match}
          />
        ),
      },
    ];
  };

  handleRowClick = ({ original }) => {
    const { onProjectClick } = this.props;
    onProjectClick(original);
  };

  render() {
    const {
      state: { projects, isSaving, loadingFinancials },
    } = this;

    return (
      <div className="financial__container">
        <div className="financial__content">
          <div className="financial__header">
            <div className="financial__title">
              <FormattedMessage id="projectdata.financial" />
            </div>
          </div>
          <div className={projects.length > 0 ? 'financial__body' : ''}>
            <Table
              rowBorderColor="transparent"
              onRowClick={this.handleRowClick}
              columns={this.getColumns()}
              data={projects}
              showPagination={false}
              sortable={false}
              isLoading={loadingFinancials || isSaving}
              numOfLoadingRows={5}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Main);
