import React from 'react';
import Description from './Description';
import HTML from './HTML';
import Text from './Text';
import Flag from './Flag';
import Lookup from './Lookup';

const ProjectDataType = props => {
  const { type, data, locale } = props;
  switch (type.toLowerCase()) {
    case 'html':
      return <HTML data={data} locale={locale} />;
    case 'textarea':
      return <Description data={data} locale={locale} />;
    case 'text':
      return <Text data={data} locale={locale} />;
    case 'flag':
      return <Flag data={data} locale={locale} />;
    case 'date':
      return <Text data={data} locale={locale} isDate />;
    case 'currency':
      return <Text data={data} locale={locale} isCurrency />;
    default:
      return type.toLowerCase().includes('lookup') ? (
        <Lookup data={data} locale={locale} />
      ) : (
        <Description data={data} locale={locale} />
      );
  }
};

export default ProjectDataType;
