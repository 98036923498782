import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import MessageList from './MessageList';
import UserInput from './UserInput';
import Header from './Header';

class ChatWindow extends Component {
  onUserInputSubmit = message => {
    const { handleUserInputSubmit } = this.props;
    handleUserInputSubmit(message);
  };

  handleFilesSelected = filesList => {
    const { onFilesSelected: onFilesSelected1 } = this.props;
    onFilesSelected1(filesList);
  };

  render() {
    const {
      onClose,
      agentProfile,
      showEmoji,
      messageList: messageList1,
      chatRef,
      isOpen,
      language,
      getMoreMessages,
    } = this.props;
    const messageList = messageList1 || [];
    const classList = ['sc-chat-window', isOpen ? 'opened' : 'closed'];
    const { theme, locale } = this.props;
    const { teamName, imageUrl } = agentProfile;
    return (
      <div
        className={classList.join(' ')}
        style={{
          right: `${language.locale === 'en' ? '0' : 'unset'}`,
          left: `${language.locale === 'ar' ? '0' : 'unset'}`,
        }}
      >
        <Header theme={theme} teamName={teamName} onClose={onClose} />
        <MessageList
          innerRef={chatRef}
          getMoreMessages={getMoreMessages}
          theme={theme}
          messages={messageList}
          imageUrl={imageUrl}
          language={language}
        />
        <UserInput
          theme={theme}
          locale={locale}
          onSubmit={this.onUserInputSubmit}
          onFilesSelected={this.handleFilesSelected}
          showEmoji={showEmoji}
        />
      </div>
    );
  }
}

ChatWindow.propTypes = {
  agentProfile: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilesSelected: PropTypes.func,
  handleUserInputSubmit: PropTypes.func.isRequired,
  showEmoji: PropTypes.bool,
};

const mapStateToProps = ({ language }) => ({
  language,
});

export default connect(mapStateToProps)(ChatWindow);
