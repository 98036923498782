import moment from 'moment';
import {
  GET_ALL_DELIVERABLES,
  POST_DELIVERABLE_START,
  POST_DELIVERABLE,
  DELETE_DELIVERABLE_START,
  DELETE_DELIVERABLE,
  START_DELIVERABLE_LOADING,
  DELETE_DELIVERABLE_DATA,
  GET_DELIVERABLE_BY_ID,
  START_DELIVERABLE_BY_ID_LOADING,
} from '../actions/deliverables';

const initialState = {
  loaders: {
    postingDeliverable: false,
    loadingDeliverables: true,
    loadingDeliverable: true,
    deletingDeliverable: false,
  },
  deliverables: [],
  deliverable: {},
};

function deliverablesReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGOUT': {
      return {
        ...initialState,
      };
    }
    case GET_DELIVERABLE_BY_ID: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          loadingDeliverable: !action.payload,
        },
        deliverable: action.payload || {},
      };
    }
    case GET_ALL_DELIVERABLES: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          loadingDeliverables: false,
        },
        deliverables: action.payload || [],
      };
    }
    case POST_DELIVERABLE_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postingDeliverable: true,
        },
      };
    }
    case START_DELIVERABLE_BY_ID_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          loadingDeliverable: true,
        },
      };
    }
    case POST_DELIVERABLE: {
      let deliverables = [...state.deliverables];
      const deliverableInPayload = action.payload.formData;

      if (deliverableInPayload.ID === 0) {
        deliverables.push({
          ...deliverableInPayload,
          ID: action.id,
          PlannedFinishDate: moment(deliverableInPayload.PlannedFinishDate, 'DD-MM-YYYY'),
          ActualFinishDate: moment(deliverableInPayload.ActualFinishDate, 'DD-MM-YYYY'),
        });
      } else {
        deliverables = deliverables.map(item => {
          if (item.ID === deliverableInPayload.ID) {
            return deliverableInPayload;
          }
          return item;
        });
      }

      return {
        ...state,
        loaders: {
          ...state.loaders,
          postingDeliverable: false,
        },
        deliverables,
      };
    }
    case DELETE_DELIVERABLE_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          deletingDeliverable: true,
        },
      };
    }
    case DELETE_DELIVERABLE: {
      const deliverables = [...state.deliverables].filter(item => item.ID !== action.payload.id);

      return {
        ...state,
        loaders: {
          ...state.loaders,
          deletingDeliverable: false,
        },
        deliverables,
      };
    }
    case START_DELIVERABLE_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          loadingDeliverables: true,
        },
      };
    }

    case DELETE_DELIVERABLE_DATA: {
      const data = state.deliverables.filter(item => {
        return JSON.stringify(item.ID) !== action.payload;
      });
      return {
        ...state,
        deliverables: data,
      };
    }
    default:
      return state;
  }
}

export default deliverablesReducer;
