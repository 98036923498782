import React from 'react';
import './styles.scss';

const BarChartLoader = () => {
  return (
    <div className="graph-container">
      <table className="graph">
        <tbody>
          <tr className="animated-background" style={{ height: '34%' }} />
          <tr className="animated-background" style={{ height: '65%' }} />
          <tr className="animated-background" style={{ height: '21%' }} />
          <tr className="animated-background" style={{ height: '22%' }} />
          <tr className="animated-background" style={{ height: '5%' }} />
          <tr className="animated-background" style={{ height: '90%' }} />
        </tbody>
      </table>
    </div>
  );
};
export default BarChartLoader;
