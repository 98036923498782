import React from 'react';

import './programCardLoader.scss';

// programCardLoader__

export default ({ themeColor }) => (
  <div className="programCardLoader__container animated-background" style={{ borderTopColor: themeColor }}>
    <div className="programCardLoader__title shadow-background" />
    <div className="programCardLoader__totalProjects shadow-background" />
    <div className="programCardLoader__status shadow-background" />
    <div className="programCardLoader__ownerPic shadow-background" />
    <div className="programCardLoader__ownerName shadow-background" />
    <div className="programCardLoader__progress shadow-background" />
    <div className="programCardLoader__statuses shadow-background" />
    <div className="programCardLoader__info">
      <div className="programCardLoader__info-item shadow-background" />
      <div className="programCardLoader__info-item shadow-background" />
      <div className="programCardLoader__info-item shadow-background" />
    </div>
  </div>
);
