import React from 'react';
import { injectIntl } from 'react-intl';
import ProcessesProjectInfo from '../ProcessesProjectInfo';
import ProcessesAttachments from '../ProcessesAttachments';
import { ProcessesTextArea, ProcessesTextField } from '../ProcessesTextArea';

import './style.scss';
import { connect } from 'react-redux';

function ProjectCharterForm({ saveData, request, project, readOnly, processType, intl, themeColor }) {
  function handleAttachments(files) {
    saveData({ Attachments: files });
  }

  return (
    <div className="project-charter-form">
      <ProcessesProjectInfo request={request} project={project} processType={processType} />
      <div className="processes-project-info">
        <ProcessesTextField
          title={intl.formatMessage({ id: 'change-request.arabic-title' })}
          classes="half rtl"
          name="TitleArabic"
          data={request.Data.TitleArabic}
          onSave={saveData}
          readOnly={readOnly}
          required
        />
        <ProcessesTextField
          title={intl.formatMessage({ id: 'change-request.english-title' })}
          classes="half ltr"
          name="TitleEnglish"
          data={request.Data.TitleEnglish}
          onSave={saveData}
          readOnly={readOnly}
          required
        />
      </div>
      <div className="project-charter-form__content">
        <ProcessesAttachments
          attachments={request.Data.Attachments}
          onChange={handleAttachments}
          readOnly={readOnly}
          required
        />
        <ProcessesTextArea
          title="common.notes"
          titleColor={themeColor}
          data={request.Data.Notes}
          name="Notes"
          onSave={saveData}
          readOnly={readOnly}
        />
      </div>
      <span />
    </div>
  );
}

const mapStateToProps = ({ theme }) => {
  return {
    themeColor: theme.ThemeColor.item.Text,
  };
};

export default injectIntl(connect(mapStateToProps)(ProjectCharterForm));
