export default {
  'projectdata.financial': 'Financial',
  'documentsLibrary.attachFile': 'Attach file',
  'attachment.only10FilesAtOnce': 'You can only upload up to 10 files at once.',
  'attachment.fileTypeError': 'A file or more were not uploaded. Invalid File Type',
  'portfolio.total-project': 'Total Projects',
  'portfolio.progress': 'Progress',
  'portfolio.total-budget': 'Total Budget',
  'portfolio.risks': 'Risks',
  'portfolio.issues': 'Issues',
  'header.notification': 'Notification',
  'header.notification-see-more': 'See More',
  'header.view-profile': 'View profile',
  'header.view-my-profile': 'My Profile',
  'header.view-activity': 'Activity',
  'header.view-chat': 'chat',
  'header.view-faq': 'FAQ',
  'header.view-support': 'Support',
  'header.view-logout': 'logout',
  'header.searchHere': 'Search here...',
  'header.language': 'Language',
  'processes.name': 'Name',
  'header.tasksCenter': 'Tasks Center',
  deliverables: 'Deliverables',
  phasesGateReview: 'Phase gate review',
  'deliverables.add-deliverable': 'Add Deliverable',
  'deliverables.deliverable-name': 'Deliverable Name',
  'deliverables.deliverable-details': 'Deliverable Details',
  'deliverables.planned-finish-date': 'Planned Finish Date',
  'deliverables.success-measure': 'Success Measure',
  'deliverables.target': 'Target',
  'deliverables.completion-percentage': 'Completion Percentage',
  'deliverables.actual-finish-date': 'Actual Finish Date',
  'deliverables.add-new-deliverable': 'Add New Deliverable',
  'milestones.name': 'Milestone Name',
  'milestones.new-name': 'New Milestone Name',
  'milestones.planned-finish-date': 'Planned Finish Date',
  'milestones.weighted-completed-work': 'Weight',
  'milestones.completed-work': 'Progress',
  'milestones.milestones-details': 'Milestone Details',
  'milestones.toggle-mode': 'Toggle Edit Mode',
  'milestones.total': 'Total',
  'dashboard.filter-by-entity': 'Filter by Entity',
  'dashboard.filter-by-program': 'Filter by Program',
  'dashboard.project-count-by-status': 'Project Count by Status',
  'dashboard.project-count-by-phase': 'Project Count by Phase',
  'programs.total-project': 'Total Projects',
  'programs.portfolio-owner': 'Portfolio Owner',
  'programs.program-owner': 'Program Owner',
  'programs.completion': 'Completion',
  'programs.risks': 'Risks',
  'programs.total-budget': 'Total Budget',
  'programs.issues': 'Issues',
  'programs.risk-and-issues-report': 'Risk and Issues Report',
  'programs.program-details-report': 'Program Details Report',
  'programs.portfolio-details-report': 'Portfolio Details Report',
  'project-preview.project-details-report': 'Project Details Report',
  'project.tab.available': 'Available Projects',
  'project.tap.critical': 'Critical Projects',
  'project.tap.critical.pms': 'Critical PMs',
  'program.tap.start-date': 'Start Date',
  'program.tap.finished-date': 'Finished Date',
  'program.tap.manager': 'Manager',
  'datacard.startdate': 'Start Date',
  'datacard.finished-date': 'Finished Date',
  'datacard.last-published': 'Last Published',
  'datacard.budget': 'Budget',
  'datacard.allocated': 'Allocated',
  'datacard.completion': 'Completion',
  'datacard.actual-percentage': 'Actual Percentage',
  'datacard.planned-percentage': 'Planned Percentage',
  'datacard.baseline-percentage': 'Baseline Percentage',
  'dashboard.resources-shortage': 'Resources Shortage',
  'project.details-preview': 'Preview',
  'project.details-manager': 'Manager',
  'project.details-phone': 'Phone',
  'project.details-extention': 'Extension',
  'project.details-email': 'Email',
  'project.details-project-description': 'Project Description',
  'project.details-sponsor': 'Sponsor',
  'project.details-owner': 'Owner',
  'project.details-project-phase': 'Project Phase',
  'progressmain.update-progress': 'Update Progress',
  'progressmain.edit-label': 'Edit',
  'progressmain.history-label': 'History',
  'progressmain.see-more-label': 'See More',
  'progressmain.progress-label': 'Progress/ completed activities from previous week',
  'progressmain.planned-label': 'Planned Activities for Following Week',
  'progressmain.edit-view-label': 'Project Update Progress / Edit',
  'progressmain.edit-view-save-label': 'Save',
  'project-tasks.title': 'Tasks',
  'project-tasks.save-button-label': 'Save',
  'project-tasks.complete-label': 'Complete',
  'project-tasks.to-label': 'TO',
  'project-issues.issue': 'Issue',
  'project-issues.title': 'Issues',
  'project-issues.add-label': 'Add New Issue',
  'project-issues-details.title': 'Issue Details',
  'project-issues-details.edit-label': 'Edit',
  'project-issues-details.close-issue-label': 'Close This Issue',
  'project-issues-details.description-label': 'Description',
  'project-issues-details.category-label': 'Category',
  'project-issues-details.added-by-label': 'Added By',
  'project-issues-details.impact-label': 'Impact',
  'project-issues.issue-log-report': 'Issues Log Report',
  'project-issues.issue-info-report': 'Issue Info Report',
  'project-issues-details.probability-label': 'Probability',
  'project-issues-details.response-plan-label': 'Response Plan',
  'project-issues-details.resolution-plan-label': 'Resolution Plan',
  'project-issues-details.due-date-label': 'Due Date',
  'project-issues-details.responsible-label': 'Responsible',
  'project-issues-add.title': 'Add New Issue',
  'project-issues-add.issue-details-label': 'Issue Details',
  'project-issues-add.title-field-label': 'Title',
  'project-issues-add.detail-field-label': 'Detail',
  'project-issues-add.category-field-label': 'Category',
  'project-issues-add.inp-field-label': 'Impact & Probability',
  'project-issues-add.issue-solution-label': 'Issue Solution',
  'project-issues-add.mitigation-plan-label': 'Mitigation Plan',
  'project-issues-add.due-date-field-label': 'Due Date',
  'project-issues-add.assigned-to': 'Assigned To',
  'project-issues-add.admin-field-label': 'Owner',
  'project-issues-add.save-btn-label': 'Save',
  'project-issues-add.update-btn-label': 'Update',
  'project-issues-add.high-risk-label': 'High Risk',
  'project-issues-add.attachments-label': 'Attachments',
  'project-issues-add.title-field-placeholder': 'Please add issue title here',
  'project-issues-add.detail-field-placeholder': 'Please describe the issue in details',
  'project-issues-add.mitigation-plan-placeholder': 'Please describe the mitigation plan in details',
  'project-issues-add.resolution-plan-placeholder': 'Please describe in the resolution plan in details',
  'project-issues-add.resolution-plan-label': 'Resolution Plan',
  'project-issues.priority': 'Priority',
  'project-issues.impact': 'Impact',
  'projectdata.preview': 'Preview',
  'projectdata.progress': 'Progress',
  'projectdata.risks': 'Risks',
  'projectdata.issues': 'Issues',
  'projectdata.tasks': 'Tasks',
  'projectdata.docsLibrary': 'Documents Library',
  'projectdata.milestones': 'Milestones',
  'projectrisk.add': 'Add New Risk',
  'projectrisk.save': 'Save',
  'projectrisk.risk-details': 'Risk Details',
  'projectrisk.risk-log-report': 'Risks Log Report',
  'projectrisk.risk-info-report': 'Risk Info Report',
  'projectrisk.title': 'Title',
  'common.description': 'Description',
  'projectrisk.category': 'Category',
  'projectrisk.impact-probability': 'Impact &  Probability',
  'projectrisk.risk-solution': 'Risk Solution',
  'projectrisk.mitigation-plan': 'Mitigation Plan',
  'projectrisk.due-date': 'Due Date',
  'projectrisk.administrator': 'Owner',
  'projectrisk.risk': 'Risk',
  'attachment-field.drop-files': 'Drop files here or click to upload',
  'attachment-field.limit-files': 'Upload up to 10 files',
  'projectrisks-main.risks': 'Risks',
  'projectrisks-main.add-new': 'Add New Risks',
  'projectrisks-details.risk-details': 'Risk Details',
  'projectrisks-details.close-risks': 'Close This Risk',
  'projectrisks-details.convert-issue': 'Convert To Issue',
  'projectrisks-details.edit': 'Edit',
  'projectrisks-details.description': 'Description',
  'projectrisks-details.category': 'Category',
  'projectrisks-details.added-by': 'Added By',
  'projectrisks-details.impact': 'Impact',
  'projectrisks-details.probability': 'Probability',
  'projectrisks-details.mitigation-plan': 'Mitigation Plan',
  'projectrisks-details.due-date': 'Due Date',
  'projectrisks-details.responsible': 'Responsible',
  'projectrisk.title-field-placeholder': 'Please add risk title here',
  'projectrisk.detail-field-placeholder': 'Please describe the risk in details',
  'projectrisk.mitigation-plan-placeholder': 'Please add the mitigation plan',
  'projectrisk.attachments': 'Attachments',
  'projectrisk.assign-to': 'Assign to',
  'project-details-bread-title': 'Project Detail',
  'program-details-bread-title': 'Program Detail',
  'portfolio-details-bread-title': 'Portfolio details',
  'common.administrator': 'Administrator',
  'common.export': 'Export',
  'common.details': 'Details',
  'common.more': 'More',
  'common.home': 'Home',
  'common.SAR': 'SAR',
  'common.SARShort': 'SAR',
  'common.next': 'Next',
  'common.previous': 'Previous',
  'common.percentage': 'Percentage',
  'common.Entity': 'Entity',
  'common.fillRequiredFields': 'Please fill all the required fields',
  'common.wrongCredentials': 'You have entered non-valid credentials',
  'common.changesHaveBeenSaved': 'Changes have been saved successfully!',
  'common.itemHasBeenDeleted': 'Item has been deleted successfully!',
  'common.errorSavingChanges': 'Failed, An Error occured while saving your changes!',
  'common.errorFetchingData': 'An Error occured while fetching the data!',
  'common.new': 'New',
  'common.title': 'Title',
  'common.projectTitle': 'Project Name',
  'common.projectTitleAr': 'Project Name Arabic',
  'common.startDate': 'Start Date',
  'common.finishDate': 'Finish Date',
  'common.expectedDate': 'Expected Date',
  'common.assignedTo': 'Assigned To',
  'common.completion': 'Completion',
  'common.request-type': 'Request Type',
  'common.creation-date': 'Creation Date',
  'common.created-by': 'Created by',
  'common.status': 'Status',
  'common.attachments': 'Attachments',
  'common.projectManager': 'Project Manager',
  'common.statusManager': 'Status Manager',
  'common.cancel': 'Cancel',
  'common.view-risk': 'View Risk',
  'common.view-issue': 'View Issue',
  'common.looksLikeThereIsNoDataHere': "Looks like there's no data available here",
  'common.dueDate': 'Due Date',
  'common.save': 'Save',
  'common.consumed': 'Consumed',
  'common.outOf': 'Out of',
  'common.liquidity': 'Liquidity',
  'common.back': 'Back',
  'common.edit': 'Edit',
  'common.support-documents': 'Support Documents',
  'common.delete': 'Delete',
  'common.all': 'All',
  'common.entities': 'Entities',
  'common.programs': 'Programs',
  'common.projects': 'Projects',
  'common.current-month-utilization': 'Current Month Utilization ',
  'common.project': 'Project',
  'common.totalBudget': 'Total Budget',
  'common.total': 'Total',
  'common.risks': 'Risks',
  'common.issues': 'Issues',
  'common.summary-info': 'Summary Information',
  'common.type-a-message': 'Type your message',
  'common.select-project': 'Select Project',
  'common.actual': 'Actual',
  'common.baseline': 'Baseline',
  'common.planned': 'Planned',
  'common.other': 'Other',
  'common.creation-time': 'Creation time',
  'common.fetch-failed-error': 'Failed to Fetch Data',
  'common.approve-success': 'Sucessfully Approved',
  'common.reject-success': 'Sucessfully Rejected',
  'common.approve-error': 'Failed to Approve',
  'common.reject-error': 'Failed to Reject',
  'common.notes': 'Notes',
  'common.reports': 'Reports',
  'common.user': 'User',
  'common.program-leader': 'Program Leader',
  'common.classification': 'Classification',
  'common.priority': 'Priority',
  'common.capacity-planner': 'Capacity Planner',
  'common.projectcoordinator': 'Project Coordinator',
  'common.capacity-planner-dashboard': 'Capacity Planner Dashboard - Current Month',
  'common.No-of-Active-Resourses': 'No. of Active Resourses',
  'common.ResourcesRequried': 'Resources Requried',
  'common.AVGEndMonth': 'Avg. Utilization(Untill Current Month)',
  'common.AVGEndYear': 'Avg. Utilization(EOY)',
  'common.UtilizedMostRole': 'Most Utilized Role',
  'common.UtilizedLeastRole': 'Least Utilized Role',
  'common.UtilizedMostResource': 'Most Utilized Resource',
  'common.UtilizedLeastResource': 'Least Utilized Resource',
  'common.UtilizationPerRole': 'Utilization Per Role',
  'common.ResourcesUtilization': 'Resources Utilization',
  'common.ResourcesUtilizationDate': 'Resources Utilization during month ',
  'common.team-members': 'Team Members',
  'common.utilization': 'Utilization',
  'common.contact-details': 'Contact Details',
  'common.projects-tasks-count': 'Projects Tasks Count',
  'common.other-tasks-count': 'Other Tasks Count',
  'common.task': 'task',
  'common.other-tasks': 'Other Tasks',
  'common.current-month': 'Current Month',
  'common.man-power-capacity': 'Manpower Capacity',
  'common.not-utilized': 'Not Utilized',
  'common.under-utilized': 'Under Utilized',
  'common.over-utilized': 'Over Utilized',
  'common.utilized': 'Utilized',
  'common.No.of-FTE-Needed': 'No. of FTE Needed',
  'common.booked-mandays': 'Booked Mandays',
  'common.available-mandays': 'Available Mandays',
  'common.current': 'Current',
  'task.taskActualCompletion': 'Task Actual Completion',
  'task.lastChangeStatus': 'Last Change Status',
  'task.delayDuration': 'Delay Duration',
  'statuses.low': 'Low',
  'statuses.medium': 'Medium',
  'statuses.high': 'High',
  'Portfolios.Portfolios': 'Portfolios',
  'portfolio.workspace-permission': "You don't have permission to access the workspace",
  'Programs.Programs': 'Programs',
  'tasksCenter.approvals': 'Approvals',
  'tasksCenter.project-tasks': 'Projects Tasks',
  'tasksCenter.my-tasks': 'My Tasks',
  'tasksCenter.my-requests': 'My Requests',
  'taskCenter.projects-tasks-approval': 'Projects Tasks Approval',
  'taskCenter.tasks-approvals': 'Tasks Approvals',
  'taskCenter.PMO-processes': 'PMO Processes',
  'taskCenter.my-pmo-processes': 'My PMO Processes',
  'taskCenter.my-requests': 'My Requests',
  'taskCenter.requested-tasks': 'Required Tasks',
  'taskCenter.add-new-task': 'Add New Task',
  'taskCenter.title-in-english': 'Title in English',
  'taskCenter.title-in-arabic': 'Title in Arabic',
  'program.deliverables': 'Deliverables',
  'program.milestones': 'Milestones',
  'program.adaa': "ADA'A",
  'program.risksAndIssues': 'Risks & Issues',
  'program.allocated': 'Allocated',
  'program.allocatedToProjects': 'Allocated to projects',
  'projectdata.deliverables': 'Deliverables',
  'projectdata.liquidity': 'Liquidity',
  'projectdata.processes': 'Processes',
  'projectliquidity-main.liquidity': 'Liquidity',
  'projectliquidity-main.add-new': 'Add New Liquidity',
  'projectliquidity.year': 'Year',
  'projectliquidity.amount': 'Amount',
  'projectliquidity.spent': 'Spent',
  'projectliquidity.add': 'Add New Liquidity',
  'projectliquidity.year-field-placeholder': 'Please add the year',
  'projectliquidity.amount-field-placeholder': 'Please add the amount',
  'projectliquidity.spent-field-placeholder': 'Please add the spent',
  'liquidity.please-enter-valid-amounts-for-liquidity': 'Please enter valid amounts for liquidity',
  'milestones.must-upload-an-attachment': 'You must upload at least one attachment!',
  'common.phases': 'Projects by phases',
  'common.goToWorkSpace': 'Go to workspace',
  'progressmain.no-planned': 'No Planned Activity',
  'progressmain.no-progress': 'No Progress Activity',
  'emptyComponent.title': "You don't have {title}",
  'emptyComponent.program': 'any programs in this portfolio',
  'common.toDate': 'to',
  'dashboard.noProjects': 'No Projects Available',
  priority: 'Priority Dashboard',
  'pdb.high': 'High',
  'pdb.low': 'Low',
  'pdb.sooner': 'Sooner',
  'pdb.later': 'Later',
  'pdb.impact': 'Impact',
  'pdb.urgency': 'Urgency',
  'pdb.projects': 'Projects',
  'pdb.manager': 'Manager',
  'pdb.budget': 'Budget',
  'pdb.progress': 'Progress',
  'common.comment': 'Comment',
  'common.done': 'Done',
  'common.Add-comment': 'Add comment',
  'common.next-phase': 'Next phase',
  'common.check-list': 'Checklist',
  'common.add': 'Add',
  'createProject.title': 'Create new project',
  'common.project-description': 'Project Description',
  'common.departments': 'Departments',
  'common.submit': 'Submit',
  'common.resubmit': 'Resubmit',
  'common.currentProjectPhase': 'Current Project Phase',
  'common.projectSponser': 'Project Sponsor',
  'common.projectOwner': 'Project Owner',
  'common.projectBudget': 'Project Budget',
  'common.portfolio': 'Portfolio',
  'common.program': 'Program',
  'Phasesgatereview.title': 'Phase gate review',
  'common.pending': 'Pending',
  'common.approve': 'Approve',
  'common.reject': 'Reject',
  'taskCenter.phase-approvals': 'Phase Approvals',
  'common.not-available': 'NA',
  'taskCenter.new-projects': 'New Projects',
  'common.projectPreview': 'Project Preview',
  'common.language': 'العربية',
  'common.dashboard': 'Dashboard',
  'common.saveAsDraft': 'Save As Draft',
  'common.rejectModal': 'Are you sure ?',
  'toast.success': 'Success',
  'toast.error': 'Error',
  'common.programTitle': 'Program Name',
  'common.approveStatus': 'Item has been approved successfully!',
  'common.rejectStatus': 'Item has been rejected successfully!',
  'project-details.escalation': 'Escalation',
  'project-details.escalation-up': 'Escalation Up',
  'project-details.escalation-date': 'Date',
  'project-details.escalated-by': 'Escalated By',
  'project-details.escalated.status': 'Status',
  'project-details.escalated.spport': 'Support Needed / Details',
  'project-details.escalated.spport-placeholder': 'Please describe the  Support Needed / Details',
  'common.type-a-comment': 'Type your comment here ...',
  'project-details.close.escaltion': 'Close Escalation',
  'project-details.escalated.actions': 'Action Log',
  'status-closed': 'Closed',
  'status-Open': 'Open',
  'status-not-started': 'Not Started',
  'common.read-more': 'Read More',
  'project-details.action-log': 'Escalation / Action Log',
  'common.escalated-by': 'Escalated By',
  'common.select': 'Select...',
  'common.interdependencies': 'Interdependencies',
  'common.create-new': 'Create New',
  'common.owner': 'Owner',
  'common.due-date': 'Due Date',
  'id.affected-project': 'Affected Project',
  'id.impact-project': 'Impact Project',
  'id.affecting-project': 'Affecting Project',
  'common.number': 'Number',
  'id.affecting-task': 'Affecting Task',
  'id.affected-task': 'Affected Task',
  'id.details': 'Interdependence Details',
  'common.category': 'Category',
  'id.log': 'Log',
  'id.relationship': 'Relationship',
  'id.close-dependency': 'Close Interdependency',
  'common.earn': 'Earn Value',
  'project-details.transfer-to-issue': 'Transfer To Issue',
  'processes.change-request': 'Change Request',
  'processes.project-charter': 'Project Charter',
  'processes.change-details': 'Change Details',
  'processes.change-details-placeholder': 'Enter Change Details',
  'processes.change-request-impact': 'Impact',
  'processes.change-request-scope': 'Scope',
  'processes.change-request-cost': 'Cost',
  'processes.change-request-duration': 'Duration',
  'processes.change-request-factor': 'Factor',
  'processes.change-request-description': 'Description',
  'processes.change-request-description-placeholder': 'Enter Description',
  'processes.change-reasons': 'Change Reasons',
  'processes.change-effect': 'Effect Description',
  'processes.change-reasons-placeholder': 'Enter description here',
  'processes.save-success': 'Draft has been saved successfully',
  'processes.submit-success': 'Request has been sent successfully',
  'processes.save-error': 'Save Failed',
  'processes.send-success': 'Sent Successfully',
  'processes.send-failed': 'Send Failed',
  'process.history': 'History',
  'processes.deliverables-fetch-failed': 'Failed to fetch the deliverables',
  'processes.create-process': 'Create Process',
  'project-processes.project-number': 'Project Number',
  'project-processes.project-name': 'Project Name',
  'project-processes.project-owner': 'Project Owner',
  'project-processes.start-date': 'Start Date',
  'project-processes.finish-date': 'Finish Date',
  'project-processes.request-number': 'Request Number',
  'project-processes.request-date': 'Request Date',
  'project-processes.applicant': 'Applicant',
  'change-request.english-title': 'English Title',
  'change-request.arabic-title': 'Arabic Title',
  // Reports
  'reports.list': 'Reports List',
  'reports.project': 'Summary Project Report',
  'reports.program': 'Summary Program Report',
  'reports.risk': 'Risk Status Report',
  'reports.issue': 'Issue Status Report',
  'reports.financial': 'Financial Report',
  'reports.Audit_Trail': 'Audit & Trails Report',
  'reports.details': 'Report Details',
  'reports.download-pdf': 'Download as PDF',
  'reports.download-word': 'Download as Word',
  'reports.download-excel': 'Download as Excel',
  'processes.project-closure': 'Project Closure',
  'common.lessons-learned': 'Lessons Learned',
  'common.end-project': 'Close Project',
  'common.evaluation': 'Evaluation',
  'common.vendor': 'Vendor',
  'common.team-performance': 'Team Performance',
  'process.deliverable-name': 'Deliverable Name',
  'common.deliveryDate': 'Delivery Date',
  'process.deliverable-reviwer': 'Deliverable Stakeholders',
  'processes.deliverables-acceptance': 'Deliverables Acceptance',
  'processes.deliverables': 'Deliverables',
  'processes.deliverables-acceptance-deliverable': 'Deliverable',
  'common.progress': 'Progress',
  'common.responsible': 'Responsible',
  'dashboard.projects': 'Projects',
  'dashboard.initiatives-count-by-status': 'Programs Count By Status',
  'common.programs-dashboard': 'Dashboard',
  'dashboard.financial-info': 'Financial Info',
  'dashboard.project-name': 'Project Name',
  'common.phase': 'Phase',
  'dashboard.announced': 'Announced ?',
  'common.yes': 'Yes',
  'common.no': 'No',
  'dashboard.spent': 'Spent',
  'dashboard.progress': 'Progress',
  'common.type': 'Type',
  'common.level-name': 'Level Name',
  'dashboard.torchbearer': 'Torch Bearer',
  'common.apply': 'Apply',
  'dashboard.data-categories': 'Data Categories',
  'common.remaining-budget': 'Remaining Budget',
  'common.dashboards': 'Dashboards',
  'dashboard.projects-by-phase': 'Projects By Phase',
  'dashboard.initiative-progress': 'Program Progress Percentage',
  'dashboard.actual': 'Actual',
  'dashboard.initiative-status': 'Program Status',
  'dashboard.initiative-info': 'Program Info',
  'dashboard.issues-risks': 'Issues/Risks',
  'dashboard.initiative-details': 'Program Details',
  'dashboard.progress-summary': 'Progress Summary',
  'dashboard.projects-by-status': 'Projects By Status',
  'dashboard.projects-list': 'Projects List',
  'dashboard.program-name': 'Portfolio Name',
  'dashboard.program-manager': 'Program Manager',
  'dashboard.project-info': 'Project Info',
  'dashboard.project-actual-progress': 'Actual Progress',
  'dashboard.initiative-owner': 'Program Leader',
  'dashboard.launch-date': 'Launch Date',
  'dashboard.project-planned-progress': 'Planned Progress',
  'dashboard.planned': 'Planned',
  'dashboard.project-status': 'Project Status',
  'dashboard.contracts-value': 'Contracts Value',
  'dashboard.remaining': 'Remaining',
  'dashboard.achievement-report': 'Achievement Report',
  'dashboard.achieved-works': 'Achieved Works',
  'dashboard.expected-achieved-works': 'Expected Achieved Works',
  'dashboard.project-details': 'Project Details',
  'project-closure.deliverables-hint': 'All deliverables must be 100% Progress and Completed',
  'stage-gate-dashboard.project-by-phase-gate': 'Projects distribution by phase gate',
  'stage-gate-dashboard.project-by-checklist': 'Projects by pending checklist',
  'stage-gate-dashboard.holded-phases-title': '{projectName} - Pending Checklist - {phaseTitle} phase',
  'common.project-stage-gate-dashboard': 'Projects Stage Gates Dashboard',
  'common.day': '{count, plural, =0 {{count} Day} one {{count} Day} other{{count} Days}}',
  'common.project-name': 'Project Name',
  'project-financial.spentAmount': 'Spent',
  'project-financial.spentDate': 'Date',
  'project-financial.add-new': 'Add New'
};
