import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SuccessIcon } from '../../assets/images/success.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg';

function Toast({ messageId, type = 'error' }) {
  const typeText = <FormattedMessage id={`toast.${type}`} />;
  return (
    <>
      <div className="toast-color" />
      {type === 'success' ? <SuccessIcon /> : <ErrorIcon />}
      <div className="toast-text">
        <h4>{typeText}</h4>
        <p>
          <FormattedMessage id={messageId} />
        </p>
      </div>
    </>
  );
}

Toast.propTypes = {
  messageId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default Toast;
