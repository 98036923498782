import React from 'react';
import * as PropTypes from 'prop-types';
import TabsNav from '../TabsNav';
import Navigator from '../Navigator';

function TabsContainer({ activeTab, activeTabName, tabs, onTabClick, match }) {
  return (
    <div className="project-data-container">
      <TabsNav activeTab={activeTab} activeTabName={activeTabName} tabs={tabs} onTabClick={onTabClick} />
      <Navigator match={match} componentsList={tabs} selectedComponentName={activeTabName} />
      <div className="new-msg">
        <i className="far fa-comments" />
      </div>
    </div>
  );
}

TabsContainer.propTypes = {
  activeTab: PropTypes.any,
  activeTabName: PropTypes.any,
  tabs: PropTypes.arrayOf(PropTypes.any),
  onTabClick: PropTypes.func,
  match: PropTypes.any,
};

export default TabsContainer;
