import React from 'react';
import { connect } from 'react-redux';

function Tab({ data, isActive, onChange, themeColor }) {
  function handleChange() {
    if (onChange) {
      onChange(data.id);
    }
  }

  return (
    <div className="dashboard-tabs__tab" onClick={handleChange}>
      <span className="title" style={{ color: isActive ? '#434041' : '#a2a2a2' }}>
        {data.label}
      </span>
      {isActive && <div className="tab--active" style={{ backgroundColor: themeColor }} />}
    </div>
  );
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(Tab);
