import React from 'react';

function Loader() {
  return (
    <div className="summary-card shadow-background">
      <div className="summary-card__content shadow-background">
        <p className="summary-card__title shadow-background" />
        <p className="summary-card__value shadow-background" />
      </div>
      <div className="summary-card__icon shadow-background" />
    </div>
  );
}

export default Loader;
