import React from 'react';
import './style.scss';

const Button = props => {
  const { children, style, onClick, type, disabled, loading, color, backgroundColor, Icon, border, className } = props;

  return (
    <button
      className={`main-button-container ${className || ''}`}
      type={type || 'button'}
      disabled={disabled || loading}
      style={{
        color,
        backgroundColor: disabled ? '#D1D1D1' : backgroundColor,
        border: border ? '1px solid' : 'none',
        borderColor: color,
        ...style,
      }}
      onClick={onClick}
    >
      {Icon && !loading && (
        <div className="icon">
          <Icon />
        </div>
      )}
      <div>
        {loading ? (
          <div className="loading-indicator" style={{ borderColor: color, borderTopColor: 'transparent' }} />
        ) : (
          children
        )}
      </div>
    </button>
  );
};

export default Button;
