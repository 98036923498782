import { HANDSHAKE_ERROR } from '../actions';
import { cleanHandshakeData } from '../Helpers';
import { HANDSHAKE_ASYNC } from '../actions/userActions';

const initialState = {
  Loading: true,
  ThemeColor: { item: { Text: window.env.THEME_COLOR } },
};

function theme(state = initialState, action) {
  switch (action.type) {
    case HANDSHAKE_ASYNC:
      // eslint-disable-next-line no-case-declarations
      const data = cleanHandshakeData(action.payload);

      return {
        ...state,
        Loading: false,
        Error: '',
        showPortfolioLevel: data.ShowPortfolioLevel.item.Text === 'true',
        ...data,
        ThemeColor: { item: { Text: window.env.THEME_COLOR } },
      };
    case HANDSHAKE_ERROR:
      return {
        Loading: false,
        Error: action.error,
      };

    default:
      return state;
  }
}

export default theme;
