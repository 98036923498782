import React from 'react';
import { FormattedMessage } from 'react-intl';

import './ProjectPreviewLoader.scss';

export default () => (
  <div className="projectPreviewLoader__container animated-background">
    <div className="projectPreviewLoader__header flex-h-v-center">
      <FormattedMessage id="project.details-preview" />
    </div>
    <div className="projectPreviewLoader__content">
      <div className="projectPreviewLoader__content-ownerPic flex-h-v-center">
        <div className="shadow-background" />
      </div>
      <div className="projectPreviewLoader__content-details">
        <p className="projectPreviewLoader__content-details-name shadow-background" />
        <p className="projectPreviewLoader__content-details-position shadow-background" />
        <div className="projectPreviewLoader__content-details-contact">
          <p className="shadow-background" />
          <p className="shadow-background" />
        </div>
        <div className="projectPreviewLoader__content-details-contact">
          <p className="shadow-background" />
        </div>
      </div>
    </div>
    <div className="projectPreviewLoader__footer">
      <div className="projectPreviewLoader__footer-row">
        <div className="projectPreviewLoader__footer-item">
          <p className="projectPreviewLoader__footer-item-title shadow-background" />
          <p className="projectPreviewLoader__footer-item-desc shadow-background" />
        </div>
      </div>
      <div className="projectPreviewLoader__footer-row">
        <div className="projectPreviewLoader__footer-item">
          <p className="projectPreviewLoader__footer-item-title shadow-background" />
          <p className="projectPreviewLoader__footer-item-desc shadow-background" />
        </div>
        <div className="projectPreviewLoader__footer-item">
          <p className="projectPreviewLoader__footer-item-title shadow-background" />
          <p className="projectPreviewLoader__footer-item-desc shadow-background" />
        </div>
        <div className="projectPreviewLoader__footer-item">
          <p className="projectPreviewLoader__footer-item-title shadow-background" />
          <p className="projectPreviewLoader__footer-item-desc shadow-background" />
        </div>
      </div>
      <div className="projectPreviewLoader__footer-row">
        <div className="projectPreviewLoader__footer-item">
          <p className="projectPreviewLoader__footer-item-title shadow-background" />
          <p className="projectPreviewLoader__footer-item-desc shadow-background" />
        </div>
      </div>
    </div>
  </div>
);
