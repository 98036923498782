import React from 'react';
import { ToastContainer } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import 'react-toastify/dist/ReactToastify.css';
import './style.scss';

const ToastContainers = ({ intl }) => {
  const transitions = {
    bounce: 'Bounce',
    slide: 'Slide',
    zoom: 'Zoom',
    flip: 'Flip',
  };
  const rtl = intl.locale !== 'en';
  return (
    <ToastContainer
      transition={transitions.Bounce}
      autoClose={4000}
      position={rtl ? 'top-left' : 'top-right'}
      closeButton={<CloseIcon width={25} />}
      hideProgressBar
      closeOnClick
      pauseOnHover
      draggable
      rtl={rtl}
    />
  );
};

export default injectIntl(ToastContainers);
