import React from 'react';
import './style.scss';

export default () => (
  <div className="EscalationLoader__container animated-background">
    <div className="EscalationLoader__content">
      <div className="EscalationLoader__content-status flex-h-v-center">
        <div className="shadow-background" />
      </div>
    </div>

    <div className="EscalationLoader__details">
      <div className="EscalationLoader__details-row">
        <div className="EscalationLoader__details-item">
          <p className="EscalationLoader__details-item-title shadow-background" />
          <p className="EscalationLoader__details-item-title shadow-background" />
        </div>
      </div>

      <div className="EscalationLoader__details-row">
        <div className="EscalationLoader__details-item">
          <p className="EscalationLoader__details-item-title shadow-background" />
          <p className="EscalationLoader__details-item-title shadow-background" />
        </div>
        <div className="EscalationLoader__details-item">
          <p className="EscalationLoader__details-item-title shadow-background" />
          <p className="EscalationLoader__details-item-title shadow-background" />
        </div>
        <div className="EscalationLoader__details-item">
          <p className="EscalationLoader__details-item-title shadow-background" />
          <p className="EscalationLoader__details-item-title shadow-background" />
        </div>
        <div className="EscalationLoader__details-item">
          <p className="EscalationLoader__details-item-title shadow-background" />
          <p className="EscalationLoader__details-item-title shadow-background" />
        </div>
      </div>
      <div className="EscalationLoader__details-row">
        <div className="EscalationLoader__details-item">
          <p className="EscalationLoader__details-item-desc shadow-background" />
        </div>
      </div>
      <div className="EscalationLoader__details-row">
        <div className="EscalationLoader__details-item">
          <p className="EscalationLoader__details-item-title shadow-background" />
          <p className="EscalationLoader__details-item-title shadow-background" />
        </div>
        <div className="EscalationLoader__details-item">
          <p className="EscalationLoader__details-item-title shadow-background" />
          <p className="EscalationLoader__details-item-title shadow-background" />
        </div>
      </div>
    </div>
  </div>
);
