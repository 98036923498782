import {
  GET_TASKS_CENTER_COUNT,
  GET_PROJECTS_TASKS_APPROVALS,
  GET_NON_PROJECTS_TASKS_APPROVALS,
  GET_PMO_PROCESSORS,
  GET_PHASE_APPROVALS,
  GET_PROJECTS_TASKS,
  GET_PROJECTS_RISKS,
  GET_PROJECTS_ISSUES,
  GET_NON_PROJECTS_TASKS,
  POST_TASK_STATUS,
  POST_UPDATED_TASKS,
  POST_NEW_TASK,
  POST_UPDATED_TASKS_START,
  POST_MY_UPDATED_TASKS_START,
  POST_MY_UPDATED_TASKS,
  UPDATE_TASK_PERCENTAGE,
  UPDATE_NON_PROJECT_TASK_PERCENTAGE,
  GET_MY_REQUESTS,
  POST_PHASE_STATUS,
  GET_NEW_PROJECTS_APPROVALS,
  GET_NEW_PROJECTS_REQUESTS,
} from '../actions/tasksCenter';
import { groupByProjectID } from '../Helpers';

const initialState = {
  loaders: {
    counters: true,
    projectsTasks: true,
    nonProjectsTasks: true,
    pmoProcessors: true,
    phaseApprovals: true,
    newProjectsApprovals: true,
    projectsTasksTab: true,
    postingUpdatedTasks: false,
    myTasks: true,
    myRequests: true,
    newProjectsRequests: true,
    postingMyUpdatedTasks: false,
  },
  counters: {},
  approvals: {
    projectsTasks: [],
    nonProjectsTasks: [],
    pmoProcessors: [],
    phaseApprovals: [],
    newProjectsApprovals: [],
  },
  myTasks: [],
  myRequests: [],
  newProjectsRequests: [],
  projectsTasks: {},
  updatedTasks: [],
  myUpdatedTasks: [],
};

const tasksCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGOUT': {
      return {
        ...initialState,
      };
    }
    case GET_TASKS_CENTER_COUNT: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          counters: false,
        },
        counters: action.payload,
      };
    }
    case GET_PROJECTS_TASKS_APPROVALS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          projectsTasks: false,
        },
        approvals: {
          ...state.approvals,
          projectsTasks: action.payload,
        },
      };
    }
    case GET_NON_PROJECTS_TASKS_APPROVALS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          nonProjectsTasks: false,
        },
        approvals: {
          ...state.approvals,
          nonProjectsTasks: action.payload,
        },
      };
    }
    case GET_PMO_PROCESSORS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          pmoProcessors: false,
        },
        approvals: {
          ...state.approvals,
          pmoProcessors: action.payload,
        },
      };
    }
    case GET_PHASE_APPROVALS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          phaseApprovals: false,
        },
        approvals: {
          ...state.approvals,
          phaseApprovals: action.payload,
        },
      };
    }
    case GET_NEW_PROJECTS_APPROVALS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          newProjectsApprovals: false,
        },
        approvals: {
          ...state.approvals,
          newProjectsApprovals: action.payload,
        },
      };
    }
    case GET_NEW_PROJECTS_REQUESTS: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          newProjectsRequests: false,
        },
        newProjectsRequests: action.payload,
      };
    }
    case GET_PROJECTS_TASKS: {
      const projectsTasks = groupByProjectID(action.payload, state.projectsTasks, 'tasks');

      return {
        ...state,
        projectsTasks,
        loaders: {
          ...state.loaders,
          projectsTasksTab: false,
        },
      };
    }
    case GET_PROJECTS_RISKS: {
      const projectsTasks = groupByProjectID(action.payload, state.projectsTasks, 'risks');

      return {
        ...state,
        projectsTasks,
      };
    }
    case GET_PROJECTS_ISSUES: {
      const projectsTasks = groupByProjectID(action.payload, state.projectsTasks, 'issues');

      return {
        ...state,
        projectsTasks,
      };
    }
    case GET_NON_PROJECTS_TASKS: {
      return {
        ...state,
        myTasks: action.payload,
        loaders: {
          ...state.loaders,
          myTasks: false,
        },
      };
    }
    case POST_UPDATED_TASKS_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postingUpdatedTasks: true,
        },
      };
    }

    case POST_NEW_TASK: {
      return {
        ...state,
        counters: {
          ...state.counters,
          MyRequestsCount: state.counters.MyRequestsCount + 1,
        },
      };
    }
    case GET_MY_REQUESTS: {
      return {
        ...state,
        myRequests: action.payload,
        loaders: {
          ...state.loaders,
          myRequests: false,
        },
      };
    }
    case POST_TASK_STATUS: {
      const targetArr = [...state.approvals[action.payload.type]];
      const filteredTargetArr = targetArr.filter(task => task.ID !== action.payload.taskID);
      const modifiedApprovals = {
        ...state.approvals,
        [action.payload.type]: filteredTargetArr,
      };
      const modifiedCounters = { ...state.counters };
      if (state.counters) {
        modifiedCounters[action.payload.type] -= 1;
      }

      return {
        ...state,
        approvals: modifiedApprovals,
        counters: modifiedCounters,
      };
    }
    case POST_UPDATED_TASKS: {
      const {
        payload: { data },
      } = action;
      const { projectsTasks, updatedTasks } = state;
      const projects = Object.keys(projectsTasks);

      const _projects = projects.reduce((accum, item) => {
        const _projectTasks = (projectsTasks[item].tasks || []).map(task => {
          let newTask = task;

          data.forEach(_task => {
            const isTargetTask = task.ID === _task.ID;
            if (isTargetTask) newTask = _task;
          });

          return newTask;
        });

        // eslint-disable-next-line
        accum[item] = {
          ...projectsTasks[item],
          tasks: _projectTasks,
        };

        return accum;
      }, {});

      const _updatedTasks = updatedTasks.filter(task => !data.find(_task => task.ID === _task.ID));

      return {
        ...state,
        loaders: {
          ...state.loaders,
          postingUpdatedTasks: false,
        },
        projectsTasks: _projects,
        updatedTasks: _updatedTasks,
      };
    }
    case UPDATE_TASK_PERCENTAGE: {
      const { task, percentage } = action.payload;
      const _task = { ...task, PercentComplete: percentage };
      const taskExists = state.updatedTasks.find(item => item.ID === task.ID);
      let _updatedTasks = [...state.updatedTasks];

      if (!taskExists) {
        _updatedTasks.push(_task);
      } else {
        _updatedTasks = _updatedTasks.reduce((accum, item) => {
          if (item.ID === _task.ID) accum.push(_task);
          else accum.push(item);
          return accum;
        }, []);
      }

      const { projectsTasks } = state;
      const projects = Object.keys(projectsTasks);

      const _projects = projects.reduce((accum, item) => {
        const _projectTasks = (projectsTasks[item].tasks || []).map(__task => {
          let newTask = __task;
          const isTargetTask = __task.ID === _task.ID;
          if (isTargetTask) newTask = _task;

          return newTask;
        });

        // eslint-disable-next-line
        accum[item] = {
          ...projectsTasks[item],
          tasks: _projectTasks,
        };

        return accum;
      }, {});

      return {
        ...state,
        updatedTasks: _updatedTasks,
        projectsTasks: _projects,
      };
    }
    case UPDATE_NON_PROJECT_TASK_PERCENTAGE: {
      const { task, percentage } = action.payload;
      const _task = { ...task, DraftPercentage: percentage };
      let _myUpdatedTasks = [...state.myUpdatedTasks];
      const taskExists = state.myUpdatedTasks.find(item => item.ID === task.ID);

      const _myTasks = [...state.myTasks].map(item => {
        if (item.ID === action.payload.task.ID) {
          return {
            ...item,
            DraftPercentage: action.payload.percentage,
          };
        }
        return item;
      });

      if (!taskExists) {
        _myUpdatedTasks.push(_task);
      } else {
        _myUpdatedTasks = _myUpdatedTasks.reduce((accum, item) => {
          if (item.ID === _task.ID) accum.push(_task);
          else accum.push(item);
          return accum;
        }, []);
      }

      return {
        ...state,
        myTasks: _myTasks,
        myUpdatedTasks: _myUpdatedTasks,
      };
    }
    case POST_MY_UPDATED_TASKS_START: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          postingMyUpdatedTasks: true,
        },
      };
    }
    case POST_MY_UPDATED_TASKS: {
      const {
        payload: { data },
      } = action;
      const _myTasks = [...state.myTasks].map(item => {
        let _item = { ...item };
        data.forEach(task => {
          if (task.ID === _item.ID) {
            _item = task;
          }
        });
        return _item;
      });

      return {
        ...state,
        myUpdatedTasks: [],
        loaders: {
          ...state.loaders,
          postingMyUpdatedTasks: false,
        },
        myTasks: _myTasks,
      };
    }

    case POST_PHASE_STATUS: {
      const newList = state.approvals.phaseApprovals.filter(data => {
        return data.ProjectUID !== action.payload.phaseID;
      });
      return {
        ...state,
        approvals: {
          ...state.approvals,

          phaseApprovals: newList,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default tasksCenterReducer;
