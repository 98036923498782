import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import moment from 'moment';
import Table from '../Table';
import Panel from '../Panel';
import TableItem from './TableItem';
import { getClassificationColor } from '../../Helpers';

import './style.scss';

function ResourcesUtilization({
  // history,
  theme,
  resources,
  intl,
  // intl,
  locale,
}) {
  const columns = () => {
    return [
      {
        accessor: dataItem => dataItem.DisplayName,
        Header: intl.formatMessage({ id: 'common.team-members' }),
        id: 'team-members',
      },

      {
        accessor: dataItem => `${dataItem.Utilization} %`,
        Header: intl.formatMessage({ id: 'common.utilization' }),
        id: 'utilization',
      },
    ];
  };

  const daysColumns = () => {
    const days = resources[0].Days;
    return days.map((item, index) => {
      return {
        accessor: '',
        Cell: dataItem => (
          <>
            <TableItem
              color={
                dataItem.value.Days[index].IsWeekendDay
                  ? '#E1E1E1'
                  : getClassificationColor(
                      theme?.ResourceUtilizationStatuses?.item?.Items || [],
                      dataItem.value.Days[index].Status,
                    )
              }
              tasks={dataItem.value.Days[index].Tasks}
              rowindex={dataItem.index}
              index={index}
              lastIndex={days.length - 1}
              themeColor={theme.ThemeColor.item.Text}
              locale={locale}
              theItem={dataItem.value}
            />
          </>
        ),

        Header: item.Day,
        id: Math.random()
          .toString(36)
          .substr(2, 9),
        maxWidth: 100,
        minWidth: 30,
      };
    });
  };

  return (
    <div className="ResourcesUtilization-component">
      <Panel
        count={null}
        title={() => (
          <div className="header-title">
            <div className="first-section">
              {intl.formatMessage({ id: 'common.ResourcesUtilizationDate' })}
              {moment().format('MMMM')}
            </div>
            <div className="sec-section">
              {theme?.ResourceUtilizationStatuses?.item?.Items?.map(item => (
                <div className="legend">
                  <div className="colored-mark" style={{ backgroundColor: item.Style }} />
                  <div className="text">{locale === 'ar' ? item.Arabic : item.English}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        render={() => (
          <>
            <div className="ResourcesUtilization-table">
              <div className="info-table">
                <div className="header-resources-utilization" />
                <Table
                  columns={columns()}
                  data={resources || []}
                  showPagination={false}
                  sortable={false}
                  numOfLoadingRows={3}
                  pageSize={resources?.length}
                  headerBackgroundColor={theme.ThemeColor.item.Text}
                  rowBorderColor="transparent"
                />
              </div>
              <div className="days-table">
                <div className="header-resources-utilization" style={{ color: theme.ThemeColor.item.Text }}>
                  <FormattedMessage id="common.current-month" /> -{moment().format('MMMM YYYY')}
                </div>
                <Table
                  columns={daysColumns()}
                  data={resources || []}
                  showPagination={false}
                  sortable={false}
                  numOfLoadingRows={3}
                  pageSize={resources?.length}
                  headerBackgroundColor={theme.ThemeColor.item.Text}
                  rowBorderColor="transparent"
                />
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
}
export default injectIntl(ResourcesUtilization);
