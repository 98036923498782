import React, { Component } from 'react';
import FieldLabel from '../FieldLabel';
import Control from './Control/Control';

import './style.scss';

class Field extends Component {
  render() {
    const { control, label } = this.props;
    return (
      <div className={`field-container ${label ? label.labelPosition : 'left'}-labeled`}>
        {label ? <FieldLabel settings={label} /> : null}
        <Control settings={control} />
      </div>
    );
  }
}

export default Field;
