import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Truncate from 'react-truncate';

import Carousel from '../../Carousel';

import './styles.scss';

class ProjectsCarousel extends React.Component {
  renderProjects = () => {
    const { projects } = this.props;
    const items = [];

    for (let i = 0; i <= projects.length; i += 2) {
      const first = projects[i] ? this.renderSingleProject(projects[i]) : null;
      const second = projects[i + 1] ? this.renderSingleProject(projects[i + 1]) : null;
      if (first) {
        items.push(
          <div className="projectsCarousel__items" key={`projectsCarousel__item-#${i + 1}`}>
            {first}
            {second}
          </div>,
        );
      }
    }

    return items;
  };

  renderSingleProject = project => {
    const { onSelect, themeColor } = this.props;
    return (
      <div className="projectsCarousel__item">
        <Truncate lines={3} width={241}>
          <h2>{project.title}</h2>
        </Truncate>
        <button type="submit" onClick={() => onSelect(project)} style={{ backgroundColor: themeColor }}>
          <FormattedMessage id="common.select-project" />
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="projectsCarousel__container">
        <Carousel render={this.renderProjects} showThumbs={false} width="340px" showIndicators={false} />
      </div>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(ProjectsCarousel);
