import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import TitledContent from '../../TitledContent';
import Button from '../../Button';
import { getProjectLocalizedName, nFormatter } from '../../../Helpers';

import './styles.scss';

class Details extends React.Component {
  state = {};

  render() {
    const {
      props: { themeColor, onNavigate, project, locale, intl },
    } = this;
    const title = getProjectLocalizedName(project, intl.locale);
    const budget = nFormatter(project.Budget || project.Allocated, 1, locale);
    const spent = nFormatter(project.Spent, 1, locale);

    return (
      <div className="financial__container">
        <div className="financial__content">
          <div className="financial__header">
            <div className="financial__title">{title}</div>
            <div className="financial__actions">
              <Button
                className="action secondary-action"
                type="submit"
                onClick={() => onNavigate('main')}
                color={themeColor}
                border
              >
                <FormattedMessage id="common.back" />
              </Button>
            </div>
          </div>
          <div className="financial__details">
            <div className="financial__details__body">
              <div className="financial__details__row">
                <TitledContent fontSize="13px" title={<FormattedMessage id="common.projectTitle" />}>
                  {title}
                </TitledContent>
              </div>
              <div className="financial__details__row">
                <TitledContent
                  fontSize="13px"
                  title={<FormattedMessage id={project.Allocated ? 'datacard.allocated' : 'datacard.budget'} />}
                >
                  {budget}
                </TitledContent>
              </div>
              <div className="financial__details__row">
                <TitledContent fontSize="13px" title={<FormattedMessage id="projectliquidity.spent" />}>
                  {spent}
                </TitledContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps)(Details));
