import React, { useState } from 'react';

import ProcessesFormLayout from '../../../components/ProcessesFormLayout';
import ChangeRequestForm from '../../../components/ChangeRequestForm';

function ChangeRequest({ readOnly, editData = {}, project = {}, processType, refetchData }) {
  const initialState = {
    Data: {
      RequestID: 0,
      Type: 'ChangeRequest',
      ProjectUID: project.ProjectUID,
      ProjectName: project.ProjectName,
      TitleEnglish: '',
      TitleArabic: '',
      Description: '',
      Details: '',
      Reasons: '',
      ScopeImpactRequired: false,
      ScopeImpact: '',
      DurationImpactRequired: false,
      DurationImpact: '',
      CostImpactRequired: false,
      CostImpact: '',
      OtherImpactRequired: false,
      OtherImpact: '',
      Attachments: [],
    },
  };

  const _editData = editData;
  if (_editData.Data) {
    _editData.Data.ScopeImpactRequired = !!_editData.Data?.ScopeImpact?.length || false;
    _editData.Data.DurationImpactRequired = !!_editData.Data?.DurationImpact.length || false;
    _editData.Data.CostImpactRequired = !!_editData.Data?.CostImpact?.length || false;
    _editData.Data.OtherImpactRequired = !!_editData.Data?.OtherImpact?.length || false;
  }

  const [state, setState] = useState({ ...initialState, ..._editData });

  function saveData(data) {
    const _data = data;
    const field = Object.keys(data)[0];
    if (field.toLowerCase().includes('impactrequired')) {
      _data[`${field.split('Required')[0]}`] = '';
    }
    setState({ ...state, Data: { ...state.Data, ..._data } });
  }

  function validateImpacts(impact, impactRequired) {
    return !!((impactRequired && impact.length) || !impactRequired);
  }

  function validate() {
    const {
      Details,
      Reasons,
      Description,
      ScopeImpact,
      DurationImpact,
      CostImpact,
      OtherImpact,
      TitleEnglish,
      TitleArabic,
      ScopeImpactRequired,
      DurationImpactRequired,
      CostImpactRequired,
      OtherImpactRequired,
    } = state.Data;

    const isScopeImpactValid = validateImpacts(ScopeImpact, ScopeImpactRequired);
    const isDurationImpactValid = validateImpacts(DurationImpact, DurationImpactRequired);
    const isCostImpactValid = validateImpacts(CostImpact, CostImpactRequired);
    const isOtherImpactValid = validateImpacts(OtherImpact, OtherImpactRequired);

    const impacts = isScopeImpactValid && isDurationImpactValid && isCostImpactValid && isOtherImpactValid;

    const anyImpactIsSelected =
      ScopeImpactRequired || DurationImpactRequired || CostImpactRequired || OtherImpactRequired;

    return !(
      Boolean(Details.length) &&
      Boolean(Reasons.length) &&
      Boolean(Description.length) &&
      Boolean(TitleEnglish?.length) &&
      Boolean(TitleArabic?.length) &&
      impacts &&
      anyImpactIsSelected
    );
  }

  return (
    <ProcessesFormLayout
      title="processes.change-request"
      data={state}
      processType={processType}
      readOnly={readOnly}
      refetchData={refetchData}
      disabled={validate()}
    >
      <ChangeRequestForm
        project={project}
        processType={processType}
        request={state}
        saveData={saveData}
        readOnly={readOnly}
      />
    </ProcessesFormLayout>
  );
}

export default ChangeRequest;
