import React from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';

import Modal from '../Modal';
import TitledContent from '../TitledContent';
import AttachmentsList from '../AttachmentsList';
import Controllers from '../Approvals/children/Controllers';

import { getTaskClassification, taskClassificationName, taskClassificationColor, getTaskStatus } from '../../Helpers';

import './styles.scss';

// eslint-disable-next-line
class ProjectTaskModal extends React.Component {
  state = {
    controllersLoading: false,
  };

  _postTask = (body, type, closeModal, removeControllersLoading) => {
    const { _postTaskStatus } = this.props;
    this.setState({ controllersLoading: true }, () => {
      _postTaskStatus(body, type, closeModal, removeControllersLoading);
    });
  };

  render() {
    const {
      isOpen,
      onClose,
      task,
      intl,
      tasksClassifications,
      taskApprovalStatuses,
      locale,
      _postTaskStatus,
      themeColor,
    } = this.props;
    const { controllersLoading } = this.state;
    if (!isOpen) return null;

    const statusText = taskClassificationName(tasksClassifications, getTaskClassification(task), locale);
    const statusColor = taskClassificationColor(tasksClassifications, getTaskClassification(task));
    const lastChangeStatus = getTaskStatus(taskApprovalStatuses, task.StatusID, locale);

    return (
      <div>
        <Modal title={intl.formatMessage({ id: 'common.details' })} isOpen={isOpen} onClose={onClose}>
          <div className="projectTaskModal__content">
            <div className="projectTaskModal__row">
              <div className="projectTaskModal__startingCol projectTaskModal__col">
                <TitledContent title={intl.formatMessage({ id: 'common.title' })}>
                  <span className="projectTaskModal__dataSpan">{task.Title}</span>
                </TitledContent>
                <div className="projectTaskModal__midCol">
                  <TitledContent title={intl.formatMessage({ id: 'common.startDate' })}>
                    <span className="projectTaskModal__dataSpan">{moment(task.StartDate).format('DD-MMM-YYYY')}</span>
                  </TitledContent>
                  <TitledContent title={intl.formatMessage({ id: 'common.finishDate' })}>
                    <span className="projectTaskModal__dataSpan">{moment(task.FinishDate).format('DD-MMM-YYYY')}</span>
                  </TitledContent>
                </div>
                <div className="projectTaskModal__midCol">
                  <TitledContent title={intl.formatMessage({ id: 'common.expectedDate' })}>
                    <span className="projectTaskModal__dataSpan">
                      {task.ExpectedDate
                        ? moment(task.ExpectedDate).format('DD-MMM-YYYY')
                        : intl.formatMessage({ id: 'common.not-available' })}
                    </span>
                  </TitledContent>
                  <TitledContent title={intl.formatMessage({ id: 'task.taskActualCompletion' })}>
                    <span className="projectTaskModal__dataSpan">{task.OriginalPercentComplete}%</span>
                  </TitledContent>
                </div>
              </div>
              <div className="projectTaskModal__trailingCol projectTaskModal__col">
                <TitledContent title={intl.formatMessage({ id: 'common.assignedTo' })}>
                  <span className="projectTaskModal__dataSpan">{task.AssignedTo[0].DisplayName}</span>
                </TitledContent>
                <TitledContent title={intl.formatMessage({ id: 'common.status' })}>
                  <span className="modifyTaskModal__dataSpan" style={{ color: statusColor, fontWeight: 600 }}>
                    {statusText}
                  </span>
                </TitledContent>
                <TitledContent title={intl.formatMessage({ id: 'task.lastChangeStatus' })}>
                  <span className="projectTaskModal__dataSpan">
                    {task.PercentComplete}%
                    <span style={{ color: lastChangeStatus.style, fontWeight: 600 }}>
                      {` ${lastChangeStatus.name}`}
                    </span>
                  </span>
                </TitledContent>
              </div>
            </div>
            {(task.Attachments || []).length ? (
              <div className="projectTaskModal__row">
                <TitledContent title={intl.formatMessage({ id: 'common.attachments' })}>
                  <AttachmentsList attachments={task.Attachments} />
                </TitledContent>
              </div>
            ) : null}
            <div className=" custom_control_approval">
              {_postTaskStatus ? (
                <Controllers
                  isEventModal
                  _postTaskStatus={(body, type, closeModal, removeControllersLoading) =>
                    this._postTask(body, type, closeModal, removeControllersLoading)
                  }
                  data={task}
                  themeColor={themeColor}
                  onCloseModal={onClose}
                  type="projectsTasks"
                  controllersLoading={controllersLoading}
                  removeControllersLoading={() => this.setState({ controllersLoading: false })}
                />
              ) : null}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default injectIntl(ProjectTaskModal);
