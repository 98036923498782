import React, { Component } from 'react';
import { css } from 'emotion';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Chip from '../Chip';
import TitledContent from '../TitledContent';
import Slider from '../Slider';
import ModifyTaskModal from '../ModifyTaskModal';

import { taskClassificationColor, taskClassificationName, getTaskClassification, getTaskStatus } from '../../Helpers';

import './style.scss';

class ProjectTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskModalOpen: false,
    };
  }

  handleTaskModalOpen = () => {
    this.setState(prevState => ({
      taskModalOpen: !prevState.taskModalOpen,
    }));
  };

  render() {
    const {
      theme,
      locale,
      tasksClassifications,
      taskApprovalStatuses,
      item,
      index,
      handleUpdateSlider,
      handleCheck,
      updateData,
      updateStatus,
    } = this.props;
    const { taskModalOpen } = this.state;

    return (
      <>
        <div className="taskRow__container">
          <div className="taskRow__starting" role="button" onClick={() => this.handleTaskModalOpen()} tabIndex={0}>
            <div className="taskRow__status">
              <Chip
                text={taskClassificationName(tasksClassifications, getTaskClassification(item), locale)}
                color={taskClassificationColor(tasksClassifications, getTaskClassification(item))}
              />
            </div>
            <TitledContent title={item.Title} fontSize="12px">
              <div className="taskRow__details">
                <span className="taskRow__details-item">
                  <FormattedMessage id="common.startDate" />
                  :&ensp;
                  {moment(item.StartDate).format('DD-MMM-YYYY')}
                </span>
                <span className="taskRow__details-item">
                  <FormattedMessage id="common.finishDate" />
                  :&ensp;
                  {moment(item.FinishDate).format('DD-MMM-YYYY')}
                </span>

                <span className="taskRow__details-item">
                  <FormattedMessage id="task.lastChangeStatus" />
                  :&ensp;
                  <span
                    className="taskRow__details-item-lastChangeStatus"
                    style={{ color: getTaskStatus(taskApprovalStatuses, item.StatusID, locale).style }}
                  >
                    {getTaskStatus(taskApprovalStatuses, item.StatusID, locale).name}
                  </span>
                </span>
                {moment().diff(item.FinishDate, 'days') > 0 ? (
                  <span className="taskRow__details-item">
                    <FormattedMessage id="task.delayDuration" />
                    :&ensp;
                    {moment().diff(item.FinishDate, 'days')} Days
                  </span>
                ) : null}
              </div>
            </TitledContent>
          </div>
          <div className="taskRow__trailing">
            <div className="d-flex pc-cont">
              {item.IsCompleted ? null : (
                <Slider
                  value={item.PercentComplete}
                  onChangePercent={e => handleUpdateSlider(e, index)}
                  theme={theme}
                  item={item}
                  miniPercentage
                  showPercentage={false}
                  width="100%"
                />
              )}
              <div
                className={`check-container ${css`
                                  .round input[type="checkbox"]:checked + label{
                                    background-color: ${theme.ThemeColor.item.Text};
                                    border-color: ${theme.ThemeColor.item.Text};
                                }
                                                        }
                                  `}`}
              >
                <div className="round">
                  <input
                    type="checkbox"
                    id={`${index}checkbox`}
                    checked={item.IsCompleted}
                    onChange={() => handleCheck(index)}
                  />
                  <label htmlFor={`${index}checkbox`} />
                </div>
              </div>
            </div>
          </div>
          {taskModalOpen ? (
            <ModifyTaskModal
              isOpen={taskModalOpen}
              task={item}
              index={index}
              onClose={() => this.handleTaskModalOpen()}
              theme={theme}
              locale={locale}
              taskApprovalStatuses={taskApprovalStatuses}
              tasksClassifications={tasksClassifications}
              type="project"
              updateData={updateData}
              updateStatus={updateStatus}
            />
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ theme, language }) => ({
  locale: language.locale,
  tasksClassifications: theme.TasksClassifications.item.Items,
  taskApprovalStatuses: theme.task_approval_statuses.item.Items,
  nonProjectTaskApprovalStatuses: theme.non_project_task_approval_statuses.item.Items,
  theme,
});

export default connect(mapStateToProps, null)(ProjectTasks);
