import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Panel from '../Panel';
import CircularProgress from '../CircularProgress';
import { numberWithCommas } from '../../Helpers';

import './styles.scss';

function ProjectFinancialInfo({ intl, project }) {
  const contracts = project.Budget || 0;
  const remaining = project.RemainingBudget || 0;
  const consumed = contracts - remaining;
  return (
    <Panel
      count={null}
      title={intl.formatMessage({ id: 'dashboard.financial-info' })}
      render={() => (
        <div className="dashboard-project-financial-info">
          <div className="dashboard-project-financial-info-container">
            <div className="financial-info__details">
              <div className="item">
                <span className="item-title">
                  <FormattedMessage id="datacard.budget" />
                </span>
                <span className="item-value">{numberWithCommas(contracts)}</span>
              </div>
              <div className="item">
                <span className="item-title">
                  <FormattedMessage id="dashboard.remaining" />
                </span>
                <span className="item-value">{numberWithCommas(consumed)}</span>
              </div>
            </div>
            <div className="chart">
              <CircularProgress value={contracts ? (consumed / contracts) * 100 : 0} displayText={false}>
                <div className="chart-spent">
                  <span className="chart-spent__title">
                    <FormattedMessage id="dashboard.spent" />
                  </span>
                  <span className="chart-spent__value">{numberWithCommas(consumed)}</span>
                </div>
              </CircularProgress>
            </div>
          </div>
        </div>
      )}
    />
  );
}

export default injectIntl(ProjectFinancialInfo);
