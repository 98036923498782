import UsersAPIController from '../providers/controllers/UsersAPIController';

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';

export const HANDSHAKE_ASYNC = 'HANDSHAKE_ASYNC';

export const GET_USER_SECURITY_INFO = 'GET_USER_SECURITY_INFO';

export const DEVICE_ID = 'DEVICE_ID';

export function getNotifications(payload) {
  return { type: GET_NOTIFICATIONS, payload };
}

export function pushNotification() {
  return { type: PUSH_NOTIFICATION };
}

export function getHandshake(payload) {
  return { type: HANDSHAKE_ASYNC, payload };
}

export function getUserSecurityInfo(payload) {
  return { type: GET_USER_SECURITY_INFO, payload };
}

export function getNotificationsAction() {
  return dispatch => {
    new UsersAPIController().getNotifications().then(res => {
      dispatch(getNotifications(res.Data));
    });
  };
}

export function getHandshakeAction() {
  return dispatch => {
    new UsersAPIController().getHandshake().then(res => {
      dispatch(getHandshake(res.Data));
    });
  };
}

export function getUserSecurityInfoAction() {
  return dispatch => {
    new UsersAPIController().getUserSecurityInfo().then(res => {
      dispatch(getUserSecurityInfo(res.Data));
    });
  };
}

export const postNotification = body => {
  return dispatch => {
    new UsersAPIController().pushNotification(body).then(() => {
      dispatch(pushNotification());
    });
  };
};
