import React from 'react';

import './style.scss';

export default () => (
  <div className="ProcessesTabLoader__container animated-background">
    <div className="ProcessesTabLoader__item shadow-background" />
    <div className="ProcessesTabLoader__item shadow-background" />
    <div className="ProcessesTabLoader__item shadow-background" />
  </div>
);
