import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Panel from '../../Panel';
import PanelLoader from '../../Panel/PanelLoader';
import TaskRow from '../../TaskRow';
import TaskRowLoader from '../../TaskRow/TaskRowLoader';
import AddNewTaskModal from '../../AddNewTaskModal';

import { renderMultiple } from '../../../Helpers';
import { getMyRequests } from '../../../actions/tasksCenter';

class MyRequests extends React.Component {
  state = {
    taskModalOpen: false,
  };

  componentDidMount() {
    this.getMyRequests();
  }

  getMyRequests = () => {
    const { _getMyRequests } = this.props;
    _getMyRequests();
  };

  toggleModal = () => {
    this.setState(prevState => ({
      taskModalOpen: !prevState.taskModalOpen,
    }));
  };

  render() {
    const { intl, myRequests, loader, theme } = this.props;
    const { taskModalOpen } = this.state;
    if (loader) return <PanelLoader render={() => renderMultiple(_key => <TaskRowLoader key={_key} />, 6)} />;

    return (
      <>
        <Panel
          title={intl.formatMessage({ id: 'taskCenter.requested-tasks' })}
          count={myRequests.length}
          actionText={intl.formatMessage({ id: 'taskCenter.add-new-task' })}
          action={this.toggleModal}
          render={() => {
            return myRequests.map((task, i) => (
              <TaskRow editable={false} task={task} key={`task#${i + 1}`} type="nonProjectTask" />
            ));
          }}
          isLoading={loader}
          isCollapsable
        />
        <AddNewTaskModal theme={theme} isOpen={taskModalOpen} closeModal toggleModal={this.toggleModal} />
      </>
    );
  }
}

const mapDispatchToProps = {
  _getMyRequests: getMyRequests,
};

const mapStateToProps = ({ tasksCenter, theme, language }) => ({
  myRequests: tasksCenter.myRequests,
  loader: tasksCenter.loaders.myRequests,
  tasksClassifications: theme.TasksClassifications.item.Items,
  taskApprovalStatuses: theme.task_approval_statuses.item.Items,
  locale: language.locale,
  theme,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MyRequests));
