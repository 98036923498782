import React from 'react';
import Modal from '../Modal';
import Table from '../Table';
// import ProgressBar from '../ProgressBar';
import {
  // getStatusColor,
  getProjectLocalizedName,
  formattedDate,
} from '../../Helpers';

function InitiativePopUp({ projects = [], intl, locale, theme, isOpen, onClose, onProjectClick }) {
  const getColumns = () => {
    return [
      {
        accessor: dataItem => (
          <div className="project-title-container">
            <div>{getProjectLocalizedName(dataItem, locale)}</div>
          </div>
        ),
        Header: intl.formatMessage({ id: 'common.programTitle' }),
        id: 'projectName',
        width: 300,
      },

      {
        accessor: dataItem => formattedDate(dataItem.StartDate),
        Header: intl.formatMessage({ id: 'common.startDate' }),
        id: 'startDate',
      },
      {
        accessor: dataItem => formattedDate(dataItem.FinishDate),
        Header: intl.formatMessage({ id: 'common.finishDate' }),
        id: 'finishDate',
      },

      {
        accessor: dataItem => dataItem.Manager.DisplayName,
        Header: intl.formatMessage({ id: 'project.details-manager' }),
        id: 'projectManager',
      },

      // {
      //   accessor: dataItem =>
      //     intl.formatMessage({ id: dataItem.CustomFields?.IsAnnounced === 'True' ? 'common.yes' : 'common.no' }),
      //   Header: intl.formatMessage({ id: 'dashboard.announced' }),
      //   id: 'Announced',
      // },

      // {
      //   accessor: 'Progress',
      //   Header: intl.formatMessage({ id: 'common.completion' }),
      //   id: 'progress',
      //
      //   Cell: dataItem => (
      //     <ProgressBar
      //       firstLineValue={dataItem.original.Progress}
      //       firstLineColor={getStatusColor(theme, dataItem.original.Status)}
      //       asideNumber={`${dataItem.original.Progress}%`}
      //     />
      //   ),
      // },
    ];
  };
  return (
    <Modal
      title={intl.formatMessage({ id: 'common.programs' })}
      isOpen={isOpen}
      onClose={() => onClose()}
      withoutPadding
      dashBoardPopUp
    >
      <div className="projects-table">
        <Table
          columns={getColumns()}
          data={projects}
          showPagination={false}
          sortable={false}
          numOfLoadingRows={3}
          pageSize={projects.length}
          onRowClick={onProjectClick}
          headerBackgroundColor={theme.ThemeColor.item.Text}
          withoutBorder
        />
      </div>
    </Modal>
  );
}

export default InitiativePopUp;
