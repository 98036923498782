import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as CheckIcon } from '../../../../assets/images/CheckIcon.svg';
import './style.scss';

class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  render() {
    const { themeColor, label, disabled, onChangeCheckBox, id } = this.props;
    const { checked } = this.state;
    return (
      <div className="check-box-main-container">
        <label className="container">
          <input
            type="checkbox"
            checked={checked}
            onChange={() => {
              onChangeCheckBox(id, checked);
              this.setState({ checked: !checked });
            }}
            disabled={disabled}
          />
          <span className="checkmark" style={{ backgroundColor: !checked || disabled ? '#eeeeee' : themeColor }}>
            {checked && <CheckIcon fill="white" />}
          </span>
        </label>
        <div className="checkbox-label">{label}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps, null)(CheckBox);
