import React from 'react';
import { FormattedMessage } from 'react-intl';
import './style.scss';

const EmptyComponent = props => {
  const { title, small } = props;
  return (
    <div className={`empty-component ${small ? 'small' : ''}`}>
      <FormattedMessage
        id={title ? 'emptyComponent.title' : 'common.looksLikeThereIsNoDataHere'}
        defaultMessage={`there is no data in ${title}`}
        values={{ title }}
        className="text"
      />
    </div>
  );
};

export default EmptyComponent;
