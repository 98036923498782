import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import groupBy from 'lodash-es/groupBy';
import Button from '../../../components/Button';
import Steps from '../../../components/Steps';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import ProgramsAPIController from '../../../providers/controllers/ProgramsAPIController';
import PortfolioaAPIController from '../../../providers/controllers/PortfolioAPIController';
import Table from '../../../components/Table';
import { getProjectLocalizedName, addToast } from '../../../Helpers';
import ProjectPreviewLoader from './ProjectPreviewLoader';
import RejectModal from './RejectModal';
import './style.scss';

class ProjectPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Portfolio: '',
      isSubmitting: false,
      portfolios: [],
      programs: [],
      data: null,
      ActionValue: '',
      isOpen: false,
      users: {},
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.getGeneralData().then(() => {
      this.getNewProjectData(match.params.id);
    });
  }

  getGeneralData = async () => {
    return Promise.all([this.findAllUsers(), this.getAllPortfolios()]);
  };

  findAllUsers = async () => {
    try {
      const user = await new ProjectAPIController().getAllUsers();
      if (user.StatusCode === 'Success') {
        this.setState({
          users: groupBy(user.Data, 'Username'),
        });
      }
    } catch (error) {
      addToast.error(error.message, {
        toastId: 'get-users-error',
      });
    }
  };

  getNewProjectData = id => {
    new ProjectAPIController().getNewProjectURL(id).then(res => {
      const item = res.Data;
      const { portfolios } = this.state;

      this.getAllPrograms(item.Data.Portfolio);
      this.setState({
        data: res.Data,
        Portfolio: portfolios.find(portfolio => {
          return portfolio.Code === item.Data.Portfolio;
        }),
      });
    });
  };

  getAllPortfolios = async () => {
    try {
      const portfolio = await new PortfolioaAPIController().getAllPortfolio();
      if (portfolio.StatusCode === 'Success') {
        this.setState({ portfolios: portfolio.Data });
      }
    } catch (error) {
      addToast.error(error.message, {
        toastId: 'get-entities',
      });
    }
  };

  getAllPrograms = portfolioID => {
    new ProgramsAPIController()
      .getAllPrograms(portfolioID)
      .then(res => {
        if (res.StatusCode === 'Success') {
          const programs = res.Data || [];
          this.setState({ programs });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-projects-toast',
        });
      });
  };

  getprogramName = () => {
    const { programs, data } = this.state;
    if (data && programs.length > 0) {
      return programs.find(program => {
        return program.Code === data.Data.Program;
      });
    }
    return {};
  };

  toggleModal = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  getColumns = () => {
    const { intl } = this.props;
    return [
      {
        accessor: data => data.Action, // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.status' }),
        id: 'status',
      },
      {
        accessor: data => data.ActionBy.DisplayName,
        Header: intl.formatMessage({ id: 'common.created-by' }),
        id: 'ActionBy',
      },
      {
        accessor: data => moment(data.Created).format('DD-MMM-YYYY'),
        Header: intl.formatMessage({ id: 'common.creation-date' }),
        id: 'creationDate',
      },
    ];
  };

  handleApproveReject = (ActionValue, Comment) => {
    const { data } = this.state;
    const { history } = this.props;
    const body = {
      action: {
        RequestID: data.ID,
        ActionValue,
        Comment,
      },
    };
    this.setState({ isSubmitting: true, ActionValue }, () => {
      new ProjectAPIController()
        .newProjectsDoAction(body)
        .then(res => {
          if (res.StatusCode === 'Success') {
            this.setState({ isSubmitting: false, isOpen: false });
            history.replace('/');
            addToast.success(ActionValue === 1 ? 'common.approveStatus' : 'common.rejectStatus');
          }
        })
        .catch(error => {
          addToast.error(error.message, {
            toastId: 'get-projects-toast',
          });
        });
    });
  };

  getUsersPermission = () => {
    const { data } = this.state;
    const { currentUser } = this.props;

    const currentStep = data.Steps.find(step => step.StatusID === data.StatusID);

    return currentStep?.Approvers?.find(group => {
      return group.Approver.Username === currentUser;
    });
  };

  getSteps = () => {
    const { data } = this.state;
    return data.Steps.sort((a, b) => a.Order - b.order).map((step, i) => {
      let Status = '';
      if (i !== 0) {
        // If it's not the first step (Order !== 1)
        const prevStep = data.Steps[i - 1];
        if (prevStep.StatusID === 1) {
          Status = 'Started';
        }
      }

      if (i === 0 || Status !== 'Started') {
        if (step.StatusID === 3 || step.StatusID === 5) {
          Status = 'Approved';
        } else if (step.StatusID === 1) {
          Status = 'Rejected';
        } else if (step.StatusID === 2) {
          Status = 'Pending';
        } else {
          Status = 'Started';
        }
      }

      return {
        ...step,
        Order: step.Order,
        TitleArabic: step.NameArabic,
        Title: step.Name,
        Status,
      };
    });
  };

  render() {
    const { Portfolio, portfolios, programs, isSubmitting, data, ActionValue, isOpen, users } = this.state;
    const { theme, locale } = this.props;
    const dots = '- - -';

    let showButtons = false;

    if (!data || !portfolios.length || !programs.length || !Object.keys(users).length) return <ProjectPreviewLoader />;
    if (data) showButtons = data.StatusID === 2 && this.getUsersPermission();

    return (
      <div className="add-project-main-container project-preview">
        <div className="add-project-content-container" style={{ borderColor: theme.ThemeColor.item.Text }}>
          <div className="add-project-container">
            <div className="add-project-content">
              <div className="main-header">
                <div className="add-project-title">
                  <FormattedMessage id="common.projectPreview" />
                </div>
                {showButtons ? (
                  <div className="add-project-buttons-container">
                    <Button
                      color="white"
                      onClick={() => this.handleApproveReject(1, '')}
                      disabled={isSubmitting}
                      loading={ActionValue === 1 && isSubmitting}
                      backgroundColor="#28a745"
                    >
                      <FormattedMessage id="common.approve" />
                    </Button>
                    <Button
                      color="white"
                      onClick={() => this.toggleModal()}
                      disabled={isSubmitting}
                      loading={ActionValue === 2 && isSubmitting}
                      backgroundColor="#c62828"
                    >
                      <FormattedMessage id="common.reject" />
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
            {data.Steps.length ? (
              <div className="project-steps">
                <Steps steps={this.getSteps(data.Steps)} locale={locale} />
              </div>
            ) : null}
            <form className="sections-padding">
              <div className="sections">
                <div className="sections-column">
                  <div className="section section-padding">
                    <div className="control">
                      <span className="label withoutWidth">
                        <FormattedMessage id="common.projectTitle" />
                      </span>
                      <div className="display-text">{data.Data.Name || dots}</div>
                    </div>
                  </div>

                  <div className="section section-padding">
                    <div className="control">
                      <span className="label withoutWidth">
                        <FormattedMessage id="common.projectTitleAr" />
                      </span>
                      <div className="display-text">{data.Data.NameArabic || dots}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sections">
                <div className="section section-padding">
                  <div className="section ">
                    <div className="sections-column">
                      <div className="control">
                        <span className="label withoutWidth">
                          <FormattedMessage id="common.project-description" />
                        </span>
                        <div className="display-text">{data.Data.Description || dots}</div>
                      </div>
                    </div>
                  </div>

                  <div className="section ">
                    <div className="sections-column">
                      <div className="control">
                        <span className="label withoutWidth">
                          <FormattedMessage id="common.portfolio" />
                        </span>
                        <div className="display-text">
                          {Portfolio ? getProjectLocalizedName(Portfolio, locale) : dots}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="section ">
                    <div className="sections-column">
                      <div className="control">
                        <span className="label withoutWidth">
                          <FormattedMessage id="common.program" />
                        </span>
                        <div className="display-text">
                          {this.getprogramName() ? getProjectLocalizedName(this.getprogramName(), locale) : dots}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sections-column">
                    <div className="section">
                      <div className="sections-column">
                        <div className="control">
                          <span className="label withoutWidth">
                            <FormattedMessage id="common.startDate" />
                          </span>
                          <div className="display-text">
                            {moment(data.Data.StartDate).format('DD-MMM-YYYY') || dots}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section second">
                      <div className="sections-column">
                        <div className="control">
                          <span className="label withoutWidth">
                            <FormattedMessage id="common.projectBudget" />
                          </span>
                          <div className="display-text">{data.Data.Budget || dots}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="section section-padding section-right">
                  <div className="sections-column">
                    <div className="control">
                      <span className="label withoutWidth">
                        <FormattedMessage id="common.projectManager" />
                      </span>
                      <div className="display-text">{users[data.Data.Manager][0].DisplayName || dots}</div>
                    </div>
                  </div>

                  <div className="section">
                    <div className="sections-column">
                      <div className="control">
                        <span className="label withoutWidth">
                          <FormattedMessage id="common.projectOwner" />
                        </span>

                        <div className="display-text">{users[data.Data.Owner][0].DisplayName || dots}</div>
                      </div>
                    </div>
                  </div>
                  <div className="section">
                    <div className="sections-column">
                      <div className="control">
                        <span className="label withoutWidth">
                          <FormattedMessage id="common.projectSponser" />
                        </span>
                        <div className="display-text">{users[data.Data.Sponsor][0].DisplayName || dots}</div>
                      </div>
                    </div>
                  </div>
                  <div className="section">
                    <div className="sections-column" />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Table
            columns={this.getColumns()}
            data={data.ActionHistory}
            showPagination={false}
            sortable={false}
            // isLoading={isLoading}
            numOfLoadingRows={4}
          />
          {isOpen && (
            <RejectModal
              theme={theme}
              isOpen
              closeModal
              toggleModal={this.toggleModal}
              loading={isSubmitting}
              handleApproveReject={(action, comment) => this.handleApproveReject(action, comment)}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ dashboard, theme, language, user }) => ({
  dashboard,
  theme,
  locale: language.locale,
  currentUser: user.user.Username,
});

export default injectIntl(connect(mapStateToProps, null)(ProjectPreview));
