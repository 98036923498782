import React from 'react';

function Deliverables() {
  return (
    <div className="dashboard-tabs__achievement-report">
      <div className="dashboard-tabs-card">
        <div className="content-container">
          <span />
          deliverables
        </div>
      </div>
    </div>
  );
}

export default Deliverables;
