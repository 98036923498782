import React, { Component } from 'react';
import Navigator from '../Navigator';
import ProjectRisks from './ProjectRisksMain';
import ProjectRisksDetails from './ProjectRisksDetails';
import Escalation from '../Escalations';
import ActionLogPage from '../Escalations/ActionLogPage';
import ProjectRisksAdd from './ProjectRisksAdd';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { addToast } from '../../Helpers';
import routes from '../../routes';

class ProjectRisksNavigator extends Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.state = {
      selectedComponentName: 'main',
      risksList: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getAllRisks();
  }

  getAllRisks() {
    const { match } = this.props;
    new ProjectAPIController()
      .getProjectRisks(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({ risksList: res.Data, loading: false });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-risks-error',
        });
      });
  }

  handleRiskUpdation = () => {
    this.setState({ loading: true, selectedComponentName: 'main', risksList: [] }, () => {
      this.getAllRisks();
    });
  };

  changeView(view, id, code) {
    const { history, match } = this.props;
    history.push(routes[view](match.url, id, code));
  }

  render() {
    const { loading, selectedComponentName, risksList } = this.state;
    const { locale, theme, match, onConvertToIssue, projectDetails, user, program } = this.props;
    const riskDetails = risksList.filter(item => item.ID === parseInt(window.location.href.split('/').pop(), 10));
    const componentsList = [
      {
        id: 'details',
        name: 'details',
        component: ProjectRisksDetails,
        props: {
          theme,
          locale,
          risksList,
          user,
          onCloseRisk: this.handleRiskUpdation,
          onConvertToIssue,
          changeView: this.changeView,
          projectDetails,
        },
      },
      {
        id: 'escalations',
        name: 'escalations',
        component: Escalation,
        props: {
          theme,
          locale,
          risksList,
          user,
          projectDetails,
          riskIssueData: riskDetails ? riskDetails[0] : {},
          getAllData: this.handleRiskUpdation,
          escalationType: 'risks',
          changeView: this.changeView,
        },
      },
      {
        id: 'actionLog',
        name: 'actionLog',
        component: ActionLogPage,
        props: {
          theme,
          locale,
          risksList,
          user,
          projectDetails,
          riskIssueData: riskDetails ? riskDetails[0] : {},
          getAllData: this.handleRiskUpdation,
          escalationType: 'risks',
          changeView: this.changeView,
        },
      },
      {
        id: 'add',
        name: 'add',
        component: ProjectRisksAdd,
        props: {
          mode: false,
          theme,
          locale,
          onRiskAddition: this.handleRiskUpdation,
          risksList,
          projectDetails,
          changeView: this.changeView,
          program,
        },
      },
      {
        id: 'edit',
        name: 'edit',
        component: ProjectRisksAdd,
        props: {
          mode: true,
          theme,
          locale,
          onRiskAddition: this.handleRiskUpdation,
          risksList,
          projectDetails,
          changeView: this.changeView,
          program,
        },
      },
      {
        id: 'base',
        name: 'main',
        component: ProjectRisks,
        props: { loading, theme, user, locale, risksList, changeView: this.changeView },
      },
    ];
    if (loading)
      return (
        <ProjectRisks
          changeView={this.changeView}
          risksList={risksList}
          loading={loading}
          theme={theme}
          locale={locale}
          user={user}
          match={match}
        />
      );
    return (
      <>
        <Navigator match={match} componentsList={componentsList} selectedComponentName={selectedComponentName} />
      </>
    );
  }
}

export default ProjectRisksNavigator;
