import React from 'react';
import { FormattedMessage } from 'react-intl';
import Field from '../../../../components/Field';
import { getHandshakeLocalizedName } from '../../../../Helpers';

const DetailsFormSection = ({ formData, setFormData, themeColor, locale, theme, currentUser, users, errors }) => {
  const categories = theme.InterdependenceCategory.item.Items;
  const relationships = theme.InterdependenceRelationship.item.Items;
  // const statuses = theme.InterdependenceStatus.item.Items;
  return (
    <div className="id-section">
      <div className="id-subTitle">
        <h5>
          <FormattedMessage id="id.details" />
        </h5>
      </div>
      <div className="id-section-content">
        <div className="id-section-row">
          <div className="control preview">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.created-by" />
            </span>
            <div>{currentUser.DisplayName}</div>
          </div>
          <div className="control">
            <span className="label flex-v-center">
              <FormattedMessage id="common.category" />
            </span>
            <Field
              control={{
                type: 'select',
                onChangeAction: e => {
                  setFormData({ ...formData, Category: e });
                },
                value: formData.Category,
                options: categories,
                getOptionLabel: option => getHandshakeLocalizedName(option, locale),
                getOptionValue: option => option.Value,
                className: 'users-select-container',
                classNamePrefix: 'users-select',
                error: errors.Category,
              }}
            />
          </div>
          <div className="control">
            <span className="label flex-v-center">
              <FormattedMessage id="common.owner" />
            </span>
            <Field
              control={{
                type: 'select',
                onChangeAction: e => {
                  setFormData({ ...formData, Owner: e });
                },
                value: formData.Owner,
                options: users,
                getOptionLabel: option => option.DisplayName,
                getOptionValue: option => option.Username,
                className: 'users-select-container',
                classNamePrefix: 'users-select',
                isLoading: !users.length,
                error: errors.Owner,
              }}
            />
          </div>
          <div className="control">
            <span className="label flex-v-center">
              <FormattedMessage id="id.relationship" />
            </span>
            <Field
              control={{
                type: 'select',
                onChangeAction: e => {
                  setFormData({ ...formData, Relationship: e });
                },
                value: formData.Relationship,
                options: relationships,
                getOptionLabel: option => getHandshakeLocalizedName(option, locale),
                getOptionValue: option => option.Value,
                className: 'users-select-container',
                classNamePrefix: 'users-select',
                error: errors.Relationship,
              }}
            />
          </div>
          {/* <div className="control"> */}
          {/*   <span className="label flex-v-center"> */}
          {/*     <FormattedMessage id="common.status" /> */}
          {/*   </span> */}
          {/*   <Field */}
          {/*     control={{ */}
          {/*       type: 'select', */}
          {/*       onChangeAction: e => { */}
          {/*         setFormData({ ...formData, Status: e }); */}
          {/*       }, */}
          {/*       value: formData.Status, */}
          {/*       options: statuses, */}
          {/*       getOptionLabel: option => getHandshakeLocalizedName(option, locale), */}
          {/*       getOptionValue: option => option.Value, */}
          {/*       className: 'users-select-container', */}
          {/*       classNamePrefix: 'users-select', */}
          {/*     }} */}
          {/*   /> */}
          {/* </div> */}
        </div>
        <div className="id-section-row quarter">
          <div className="control">
            <span className="label flex-v-center">
              <FormattedMessage id="id.log" />
            </span>
            <Field
              control={{
                type: 'number',
                onChangeAction: e => {
                  setFormData({ ...formData, LogNumber: e.target.value < 0 ? 0 : e.target.value });
                },
                value: formData.LogNumber,
                className: 'users-select-container',
                classNamePrefix: 'users-select',
                error: errors.LogNumber,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsFormSection;
