import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Field from '../Field';
import { formattedDate } from '../../Helpers';
import './style.scss';

function ProcessesProjectInfo({ intl, project, request, currentUser, locale, processType }) {
  const isAr = locale === 'ar';

  function getCustomizedFields() {
    const datesFields = [
      {
        label: 'project-processes.start-date',
        value: project.CustomFields && formattedDate(project.CustomFields.StartDate),
      },
      {
        label: 'project-processes.finish-date',
        value: project.CustomFields && formattedDate(project.CustomFields.FinishDate),
      },
    ];

    const requestFields = [
      {
        label: 'project-processes.request-number',
        value: request.ID,
      },
      {
        label: 'project-processes.request-date',
        value: request.Created
          ? moment(request.Created)
              .locale('en')
              .format('DD-MM-YYYY HH:mm')
          : moment(new Date())
              .locale('en')
              .format('DD-MM-YYYY HH:mm'),
      },
    ];

    switch (processType) {
      case 'initiativeplan':
      case 'projectclosure':
        return [...datesFields, ...requestFields];

      case 'changerequest':
        return [
          ...requestFields,
          {
            label: 'project-processes.applicant',
            value: request.RequestBy ? request.RequestBy.DisplayName : currentUser,
          },
        ];

      case 'deliverableacceptance':
        return requestFields.reverse();

      default:
        return [];
    }
  }

  const fields = [
    // {
    //   label: 'project-processes.project-number',
    //   value: project.CustomFields && project.CustomFields.ProjectID,
    // },
    {
      label: 'project-processes.project-name',
      value: isAr ? project.ProjectNameArabic : project.ProjectName,
    },
    {
      label: 'project-processes.project-owner',
      value: project.ProjectOwner && project.ProjectOwner.DisplayName,
    },
    ...getCustomizedFields(),
  ];

  return (
    <div className="processes-project-info">
      {fields.map(item => (
        <div key={item.label} className="info-field">
          <Field
            label={{
              label: intl.formatMessage({ id: item.label }),
            }}
            control={{
              type: 'input',
              disabled: true,
              value: item.value,
              validateOnExite: true,
            }}
          />
        </div>
      ))}
    </div>
  );
}

const mapStateToProps = ({ language, user }) => ({
  locale: language.locale,
  currentUser: user.user.DisplayName,
});

export default injectIntl(connect(mapStateToProps)(ProcessesProjectInfo));
