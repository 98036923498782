import React from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import groupBy from 'lodash-es/groupBy';

const ReviewsChart = props => {
  const { reviewsData, language, theme } = props;

  const options = {
    plugins: {
      legend: false,
      datalabels: {
        align: 'end',
        anchor: 'end',
        font: {
          weight: 'bold',
        },
        textAlign: 'center',
      },
    },
    scaleLineColor: 'none',
    gridLines: {
      drawBorder: false,
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            fontSize: window.screen.width > 1024 ? 9 : 7,
            display: false,
            fontFamily: language.locale === 'ar' ? 'Cairo' : 'Roboto',
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            fontStyle: 'bold',
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          angleLines: {
            display: false,
          },

          gridLines: {
            display: true,
            drawBorder: false,
          },

          ticks: {
            suggestedMax:
              Math.max(...reviewsData.map(item => item.Count)) + Math.max(...reviewsData.map(item => item.Count)) * 0.2,
            beginAtZero: true,
            display: false,
            autoSkip: false,
            maxTicksLimit: 6,
          },
        },
      ],
    },
  };

  const data = language.locale === 'ar' ? [...reviewsData].reverse() : [...reviewsData];

  const colorsList = [
    '#6E1946',
    '#B41932',
    '#91B9B4',
    '#FDBF6F',
    '#FF7F00',
    '#7F7F7F',
    '#EB5A3C',
    '#86B548',
    '#992F2F',
    '#E89374',
    '#437D85',
    '#2FA543',
    '#9E586F',
  ];

  const barsColors = data.map((_, index) =>
    colorsList[index] ? colorsList[index] : colorsList[index % colorsList.length],
  );

  const barLabels = data.map(item => (language.locale === 'ar' ? item.TitleArabic : item.Title));

  const phasesList = [...theme.ProjectPhases.item.Items];

  const phasesLabelsGrouped = groupBy(data, 'PhaseID');

  const keysList = Object.keys(phasesLabelsGrouped);
  const phasesLabels = keysList.length
    ? keysList.map(item => {
        return {
          Length: phasesLabelsGrouped[item].length,
          Title: phasesList.find(phase => phase.Value === item)[language.locale === 'ar' ? 'Arabic' : 'English'],
          Color: phasesList.find(phase => phase.Value === item.toString()).Style,
        };
      })
    : [];

  return (
    <div className="reviews-chart-container">
      <div className="dashboard__bar-charts">
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Bar
            data={{
              labels: barLabels,
              datasets: [
                {
                  data: data.map(element => {
                    return element.Count;
                  }),
                  backgroundColor: language.locale === 'ar' ? barsColors.reverse() : barsColors,
                  barThickness: 30,
                },
              ],
            }}
            options={options}
            height={300}
          />
        </div>
        <div className={`labels-container-bar${language.locale === 'ar' ? ' row-reverse' : ''}`}>
          {barLabels.map((item, index) => (
            <div className="bar-label" key={index}>
              {item}
            </div>
          ))}
        </div>
        <div className="bar-phases-container">
          {phasesLabels
            ? phasesLabels.map((item, index) => (
                <div className="bar-phase-label" key={index} style={{ flex: item.Length * 0.9 }}>
                  <span className="label-top-border" style={{ backgroundColor: item.Color }} />
                  <span className="label-title">{item.Title}</span>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ theme, language }) => {
  return {
    theme,
    language,
  };
};

export default connect(mapStateToProps)(ReviewsChart);
