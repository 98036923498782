export const baseURL = window.env.API_URL;
export const playStoreURL = window.env.PLAY_STORE_URL;
export const appStoreURL = window.env.APP_STORE_URL;
export const logoURL = `${process.env.PUBLIC_URL}${window.env.LOGO_PATH}`;
export const headerLogoURL = `${process.env.PUBLIC_URL}${window.env.HEADER_LOGO_PATH || window.env.LOGO_PATH}`;
export const ATTACHMENTS_URL = `${baseURL}attachments.svc/`;
export const projectService = `${baseURL}projectservice.svc/`;
export const ReportsUrl = window.env.REPORTS_URL;
export const FarisUrl = window.env.FARIS_URL;

export class urlServiceConfig {
  loginUrl = () => `${projectService}login`;

  validateCodeUrl = () => `${projectService}Login/Validate`;

  handshakeUrl = () => `${projectService}handshake`;

  getUtilizationCapacity = () => `${projectService}DashBoards/Utilization/capacity`;

  getResourcesCapacity = () => `${projectService}DashBoards/Utilization/Resources`;

  getUserSecurityInfo = () => `${projectService}GetUserSecurityInfo`;

  notificationsUrl = () => `${projectService}Notification`;

  MarkNotificationsReadUrl = () => `${projectService}Notification/MarkAllAsRead`;

  markNotificationsReadByIDUrl = id => `${projectService}Notification/MarkAsReadByID/${id}`;

  allEntitiesURL = () => `${projectService}AllPortfolios`;

  allProgramsURL = () => `${projectService}AllPrograms`;

  allProjectsURL = () => `${projectService}allprojectsbasic`;

  programDetailsURL = () => `${projectService}Program`;

  programsURL = () => `${projectService}Programs`;

  projectDetailsURL = () => `${projectService}Project`;

  addIssueUrl = () => `${projectService}issue`;

  addRiskUrl = () => `${projectService}risk`;

  addLiquidityUrl = () => `${projectService}liquidity`;

  progressUpdateURL = () => `${projectService}project/progressupdate`;

  updateTasksURL = () => `${projectService}task`;

  addNewTaskURL = () => `${projectService}TaskCenter/NonProjectTasks/Task`;

  projectLibraryURL = () => `${projectService}ProjectLibrary`;

  uploadLibraryFileURL = () => `${projectService}UploadProjectLibraryFile`;

  pushNotificationURL = () => `${projectService}notification/push`;

  tasksCenterURL = () => `${projectService}TaskCenter`;

  addMilestoneURL = () => `${projectService}milestone`;

  deleteMilestoneUrl = (code, id) => `${projectService}${code}/DeleteMilestone/${id}`;

  deliverablesURL = code => `${projectService}${code}/deliverables`;

  deliverableByIdURL = (code, id) => `${projectService}${code}/Deliverable/${id}`;

  postDeliverableURL = () => `${projectService}deliverable`;

  deleteDeliverableURL = (code, id) => `${projectService}${code}/DeleteDeliverable/${id}`;

  wfServiceURL = () => `${baseURL}wfservice.svc`;

  portfoiloByCode = code => `${projectService}portfolio/${code}`;

  updateProjectPriority = () => `${projectService}ProjectPriority`;

  saveNewProjectURL = () => `${this.wfServiceURL()}/NewProject`;

  submitNewProjectURL = ID => `${this.wfServiceURL()}/submit/${ID}`;

  getNewProjectURL = ID => `${this.wfServiceURL()}/NewProject/${ID}`;

  getNewProjectStepsURL = () => `${this.wfServiceURL()}/steps/newproject`;

  getNewProjectsApprovalsURL = () => `${this.wfServiceURL()}/mytasks`;

  getNewProjectsRequestsURL = () => `${this.wfServiceURL()}/myrequests`;

  newProjectsDoAction = () => `${this.wfServiceURL()}/DoAction`;

  getPhaseGate = code => `${projectService}/PhaseGate/Phases/${code}`;

  getPhaseGateComments = ID => `${projectService}/PhaseGate/comments/${ID}`;

  savePhaseGateComment = () => `${projectService}/PhaseGate/Comment`;

  deleteComment = code => `${projectService}/PhaseGate/Comment/delete/${code}`;

  phaseGateCheck = () => `${projectService}/PhaseGate/CheckList/Check`;

  phaseGateSubmit = code => `${projectService}PhaseGate/submit/${code}`;

  postDeviceID = () => `${projectService}userdevice`;

  removeDeviceId = DeviceID => `${projectService}logout/${DeviceID}`;

  getAllRisks = () => `${projectService}Dashboards/GetAllRisks`;

  getAllIssues = () => `${projectService}Dashboards/GetAllIssues`;

  projectRequestsURL = projectUID => `${this.wfServiceURL()}/Requests/${projectUID}`;

  getProjectPhaseGateInfoURL = () => `${projectService}dashboards/projects?phasegates=true`;

  getProjectPhaseGateSchemaURL = () => `${projectService}PhaseGate/Phases/schema`;
}

export default urlServiceConfig;
