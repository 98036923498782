import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addToast } from '../../../Helpers';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import CapacityPlannerComponent from '../../../components/CapacityPlannerComponent';
import Loader from './Loading';

function CapacityPlannerDashboard({ theme, history, locale }) {
  const [utilizationCapacity, setutilizationCapacity] = useState(null);
  const [resourcesRoles, setResourcesRoles] = useState(null);
  const [loading, setLoading] = useState(true);

  function getUtilizationCapacity() {
    new ProjectAPIController()
      .getUtilizationCapacity()
      .then(res => {
        setutilizationCapacity(res.Data);

        const projectMangers = res.Data.Resources.filter(item => {
          return item.Role === 'Project Manager';
        });
        const ProgramLeaders = res.Data.Resources.filter(item => {
          return item.Role === 'Program Leader';
        });
        const programManagers = res.Data.Resources.filter(item => {
          return item.Role === 'Program Manager';
        });
        const projectCoordinators = res.Data.Resources.filter(item => {
          return item.Role === 'Project Coordinator';
        });
        setResourcesRoles({
          projectMangers,
          ProgramLeaders,
          programManagers,
          projectCoordinators,
        });
        setLoading(false);
      })
      .catch(() => {
        addToast.error('error-get-data');
      });
  }

  useEffect(() => {
    getUtilizationCapacity();
  }, [history.location]);

  if (loading) return <Loader />;

  return (
    <div>
      <CapacityPlannerComponent
        utilizationCapacity={utilizationCapacity}
        resourcesRoles={resourcesRoles}
        theme={theme}
        locale={locale}
        history={history}
      />
    </div>
  );
}

const mapStateToProps = ({ language, theme }) => ({
  theme,
  locale: language.locale,
});

export default connect(mapStateToProps)(CapacityPlannerDashboard);
