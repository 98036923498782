import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import DashboardSummaryCards from '../DashboardSummaryCards';
import DashboardBarChart from '../DashboardBarChart';
import SummaryInfoCard from '../SummaryInfoCard';
import DashboardProgramHeader from '../DashboardProgramHeader';
import InitiativeDetails from '../InitiativeDetails';
import DashboardFilters from '../DashboardFiltersPopUp';
import RiskIssuesPopUp from '../RiskIssuesPopUp';
import { ReactComponent as BagIcon } from '../../assets/images/bag.svg';
import { ReactComponent as DocumentIcon } from '../../assets/images/document.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/warning.svg';
import { ReactComponent as FireIcon } from '../../assets/images/fire.svg';
import { ReactComponent as SpeedIcon } from '../../assets/images/speed.svg';

import { getDashboardInitiativeDetailsStats, getStatusName, getLevelStatusColor } from '../../Helpers';

import './styles.scss';

function DashboardInitiativeDetails({ dashboard = {}, intl, filteredProjects, match, themeColor, theme, locale }) {
  const initialStats = {
    projects: [],
    programs: [],
    entity: {
      Manager: {},
    },
  };

  const initialStatePopUps = {
    projects: { isOpen: false, type: '', index: '' },
    initiative: { isOpen: false },
    riskissues: { isOpen: false, type: '' },
    contracts: { isOpen: false },
  };

  const [stats, setStats] = useState(initialStats);
  const [popUpsState, setpopUpsState] = useState(initialStatePopUps);
  const { summary, loaders } = dashboard;
  const { initiativeCode, programCode } = match.params;

  const openPopUp = (mainType, type, index) => {
    setpopUpsState({
      ...popUpsState,
      [mainType]: { isOpen: true, type, index },
    });
  };

  const closePopUp = mainType => {
    setpopUpsState({
      ...popUpsState,
      [mainType]: { isOpen: false },
    });
  };

  const summaryData = [
    {
      id: 'common.programs',
      value: summary.entities,
      Icon: BagIcon,
      badgeColor: '#191f6c',
      Component: SummaryInfoCard,
      compProps: { user: stats.initiative?.Manager || {} },
    },
    {
      id: 'dashboard.initiative-progress',
      value: 20,
      badgeColor: getLevelStatusColor(theme, stats.initiative?.Status),
      isProgress: true,
      progressTitle: 'dashboard.actual',
    },
    {
      id: 'dashboard.initiative-status',
      value: getStatusName(theme.Level_Statuses.item.Items, stats.initiative?.Status, locale),
      Icon: SpeedIcon,
      badgeColor: '#0AB11A',
      statusStyle: true,
    },
    {
      id: 'dashboard.projects',
      value: stats.projectsCount,
      Icon: DocumentIcon,
      badgeColor: '#81BDBB',
    },
    {
      id: 'common.risks',
      value: stats.risksCount,
      Icon: WarningIcon,
      badgeColor: '#FE9F00',
      onClick: () => openPopUp('riskissues', 'risks'),
    },
    {
      id: 'common.issues',
      value: stats.issuesCount,
      Icon: FireIcon,
      badgeColor: '#CE1818',
      onClick: () => openPopUp('riskissues', 'issues'),
    },
  ];

  function onApplyFilters(filters) {
    const newStats = getDashboardInitiativeDetailsStats(
      dashboard,
      filteredProjects,
      initiativeCode,
      programCode,
      filters,
    );
    setStats(newStats);
  }

  useEffect(() => {
    const newStats = getDashboardInitiativeDetailsStats(dashboard, filteredProjects, initiativeCode, programCode);
    setStats(newStats);
  }, [dashboard, filteredProjects, initiativeCode, programCode]);

  const financialInfoLabels = [
    {
      name: intl.formatMessage({ id: 'datacard.budget' }),
      color: '#642045',
    },
    {
      name: intl.formatMessage({ id: 'datacard.allocated' }),
      color: '#152836',
    },
    {
      name: intl.formatMessage({ id: 'dashboard.spent' }),
      color: '#F1993F',
    },
    {
      name: intl.formatMessage({ id: 'common.remaining-budget' }),
      color: '#6D2177',
    },
  ];

  return (
    <>
      <div className="dashboard-initiative">
        <div className="dashboard-initiative__header">
          <DashboardProgramHeader data={stats.initiative} backgroundColor={themeColor} loading={loaders.allProjects} />
        </div>
        <DashboardSummaryCards data={summaryData} loading={loaders.allProjects} />
        <div className="dashboard__info">
          <DashboardBarChart
            type="byPhase"
            projects={stats.projects}
            title={intl.formatMessage({ id: 'dashboard.projects-by-phase' })}
            loading={loaders.allProjects}
          />
          <DashboardBarChart
            type="byStatus"
            projects={stats.projects}
            title={intl.formatMessage({ id: 'dashboard.projects-by-status' })}
            loading={loaders.allProjects}
          />
          <DashboardBarChart
            type="financial"
            barData={stats.financialInfo}
            customLabels={financialInfoLabels}
            title={intl.formatMessage({ id: 'dashboard.financial-info' })}
            projects={stats.projects}
            loading={loaders.allProjects}
          />
        </div>

        {popUpsState.riskissues.isOpen && (
          <RiskIssuesPopUp
            intl={intl}
            theme={theme}
            locale={locale}
            onClose={() => closePopUp('riskissues')}
            data={popUpsState.riskissues.type === 'risks' ? stats.risks : stats.issues}
            type={popUpsState.riskissues.type}
          />
        )}

        <div className="dashboard-initiative__details">
          <InitiativeDetails programCode={programCode} stats={stats} />
        </div>
      </div>
      <DashboardFilters onApplyFilters={onApplyFilters} level3Only project />
    </>
  );
}

const mapStateToProps = ({ dashboard, theme, language }) => ({
  dashboard,
  themeColor: theme.ThemeColor.item.Text,
  theme,
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps)(DashboardInitiativeDetails));
