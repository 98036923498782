/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import Konva from 'konva';
import { Stage, Layer, Circle, Line, Shape } from 'react-konva';
import { FormattedMessage } from 'react-intl';
import ProjectsList from './ProjectsList';
import Tooltip from './Tooltip';
import { getStatusColor, numberWithCommas, getProjectLocalizedName, addToast } from '../../Helpers';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import {
  calculateImpact,
  calculateUrgency,
  getPointX,
  getPointY,
  generateMustShape,
  generateShouldShape,
  calculateTotal,
  calculateBudgets,
} from './helpers';
import * as styles from './styles';

const initialTooltip = {
  visible: false,
  drag: false,
  x: 0,
  y: 0,
  Progress: 0,
  Impact: '',
  Urgency: '',
  Budget: 0,
  Total: '',
  Title: '',
  Color: 'red',
  SubTitle: '',
};

const gridArray = [0, 0.1, 0.2, 0.3, 0.4, 0.6, 0.7, 0.8, 0.9, 1];

const initialGrid = {
  scaleX: window.innerWidth / 100,
  scaleY: window.innerHeight / 100,
  width: 0,
  height: 0,
};

function PriorityDB({ projects, theme, locale }) {
  const stageCont = useRef();
  const stage = useRef();
  const layer = useRef();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [tooltip, setTooltip] = useState(initialTooltip);
  const [budgets, setBudgets] = useState(calculateBudgets(projects));
  const [items, setItems] = useState(
    projects.map(item => {
      return {
        ...item,
        Color: getStatusColor(theme, item.Status),
        group: `${item.Urgency}x${item.Impact}`,
      };
    }),
  );
  const [badges] = useState([]);
  const [grid, setGrid] = useState(initialGrid);
  const [draggedEl, setDraggedEl] = useState();
  const setSize = () => {
    if (stageCont.current) {
      const { offsetHeight, offsetWidth } = stageCont.current;
      setGrid({
        scaleX: offsetWidth / 100,
        scaleY: offsetHeight / 100,
        width: offsetWidth,
        height: offsetHeight,
      });
    }
  };
  const rtl = locale === 'ar';
  useEffect(() => {
    window.addEventListener('resize', setSize);
    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, []);
  useLayoutEffect(() => {
    // if (layer.current) {
    //   layer.current.children.forEach(currentItem => {
    //     layer.current.children.forEach(item => {
    //       if (currentItem._id === item._id) return;
    //       if (
    //         currentItem.attrs.x === item.attrs.x &&
    //         currentItem.attrs.y === item.attrs.y
    //       ) {
    //         console.log("overlap");
    //         setBadges([
    //           ...badges,
    //           { x: currentItem.attrs.x, y: currentItem.attrs.y, count: 3 }
    //         ]);
    //       }
    //     });
    //   });
    // }
  });
  useEffect(() => {
    if (stageCont.current) {
      setSize();
    }
  }, [stageCont, isCollapsed]);
  const handleDragStart = e => {
    e.target.setAttrs({
      shadowOffset: {
        x: 15,
        y: 15,
      },
      scaleX: 1.1,
      scaleY: 1.1,
      opacity: 0.5,
    });
  };
  const drawMustShape = (context, shape) => {
    generateMustShape(context, shape, grid, rtl);
  };
  const drawShouldShape = (context, shape) => {
    generateShouldShape(context, shape, grid, rtl);
  };
  const handleMouseEnter = e => {
    const Impact = calculateImpact(e.target.attrs.y, grid.scaleY);
    const Urgency = calculateUrgency(e.target.attrs.x, grid.scaleX, rtl);
    const currentItem = items[e.target.attrs.index];
    const { Manager, Budget, Color, Progress } = currentItem;
    e.target.setAttrs({
      zIndex: 100,
    });
    setTooltip({
      visible: true,
      drag: false,
      x: getPointX(Urgency, grid.scaleX, rtl),
      y: Impact * grid.scaleY + 25,
      Title: getProjectLocalizedName(currentItem, locale),
      SubTitle: Manager.DisplayName,
      Budget,
      Impact,
      Urgency,
      Color,
      Total: calculateTotal(Impact, Urgency),
      Progress,
    });
  };
  const handleMouseLeave = () => {
    setTooltip(initialTooltip);
  };
  const listEnter = e => {
    e.persist();
    if (e.target) {
      const currentItem = items[parseInt(e.target.attributes.index.value, 10)];
      const { Impact, Urgency, Budget, Progress, Color, Manager } = currentItem;
      setTooltip({
        visible: true,
        drag: false,
        x: getPointX(Urgency, grid.scaleX, rtl),
        y: Impact * grid.scaleY + 25,
        Title: getProjectLocalizedName(currentItem, locale),
        SubTitle: Manager.DisplayName,
        Budget,
        Color,
        Progress,
        Total: calculateTotal(Impact, Urgency),
      });
    }
  };
  const handleListDragStart = e => {
    setDraggedEl(parseInt(e.target.attributes.index.value, 10));
  };
  const handleDrag = e => {
    const Impact = calculateImpact(e.target.attrs.y, grid.scaleY);
    const Urgency = calculateUrgency(e.target.attrs.x, grid.scaleX, rtl);
    const currentItem = items[e.target.attrs.index];
    const { Manager, Budget, Color, Progress } = currentItem;
    setTooltip({
      visible: true,
      drag: true,
      x: getPointX(Urgency, grid.scaleX, rtl),
      y: Impact * grid.scaleY + 25,
      Title: getProjectLocalizedName(currentItem, locale),
      SubTitle: Manager.DisplayName,
      Budget,
      Impact,
      Urgency,
      Color,
      Total: calculateTotal(Impact, Urgency),
      Progress,
    });
    //   e.target.to({
    //     duration: 0.5,
    //     easing: Konva.Easings.ElasticEaseOut,
    //     scaleX: 1,
    //     scaleY: 1,
    //     shadowOffsetX: 5,
    //     shadowOffsetY: 5
    //   });
  };
  const handleDragEnd = e => {
    const newItems = [...items];
    const Impact = calculateImpact(e.target.attrs.y, grid.scaleY);
    const Urgency = calculateUrgency(e.target.attrs.x, grid.scaleX, rtl);
    newItems.splice(e.target.attrs.index, 1, {
      ...items[e.target.attrs.index],
      Impact,
      Urgency,
      group: `${Urgency}x${Impact}`,
    });
    setItems(newItems);
    setBudgets(calculateBudgets(newItems));
    setIsUpdated(true);
    // e.target.position({
    //   x: Math.round(e.target.attrs.x),
    //   y: Math.round(e.target.attrs.y)
    // });
    e.target.to({
      duration: 0.5,
      opacity: 1,
      easing: Konva.Easings.ElasticEaseOut,
      scaleX: 1,
      scaleY: 1,
      shadowOffsetX: 5,
      shadowOffsetY: 5,
    });
    setTooltip({ ...tooltip, drag: false });
  };
  // console.log(budgets);

  // const checkOverlap = e => {
  //   let group = null;
  //   layer.current.children.forEach(item => {
  //     if (e.target._id === item._id) return;
  //     if (
  //       e.target.attrs.x === item.attrs.x &&
  //       e.target.attrs.y === item.attrs.y
  //     ) {
  //       const Impact = 100 - Math.round(e.target.attrs.y / grid.scaleY);
  //       const Urgency = 100 - Math.round(e.target.attrs.x / grid.scaleX);
  //       group = `${Urgency}x${Impact}`;
  //     }
  //   });
  //   return group;
  // };

  const handleStageDrop = e => {
    e.persist();
    e.preventDefault();
    stage.current.setPointersPositions(e);
    const pos = stage.current.getPointerPosition();
    const newItems = [...items];
    const Impact = calculateImpact(pos.y, grid.scaleY);
    const Urgency = calculateUrgency(pos.x, grid.scaleX, rtl);
    newItems.splice(draggedEl, 1, {
      ...items[draggedEl],
      Impact,
      Urgency,
      group: `${Urgency}x${Impact}`,
    });
    setItems(newItems);
    setBudgets(calculateBudgets(newItems));
    setIsUpdated(true);
  };
  const handleBound = pos => {
    const bound = { x: pos.x < 0 ? 0 : pos.x, y: pos.y < 0 ? 0 : pos.y };
    if (pos.x > grid.width) {
      bound.x = grid.width;
    }
    if (pos.y > grid.height) {
      bound.y = grid.height;
    }
    return bound;
  };
  const showError = () => {
    addToast.error('common.errorSavingChanges', {
      toastId: 'priorities-save-error',
    });
  };
  const showSuccess = () => {
    addToast.success('common.changesHaveBeenSaved');
  };
  const handleSave = () => {
    setIsLoading(true);
    const filteredItems = items.filter(item => item.Impact && item.Urgency);
    new ProjectAPIController()
      .updateProjectPriority({
        priorities: filteredItems.map(item => ({
          ProjectUID: item.ProjectUID,
          Impact: item.Impact,
          Urgency: item.Urgency,
        })),
      })
      .then(({ Data }) => {
        if (!Data) {
          showError();
        } else {
          showSuccess();
          setIsUpdated(false);
        }
        setIsLoading(false);
      })
      .catch(showError);
  };
  return (
    <div css={styles.container} style={{}}>
      <ProjectsList
        items={items}
        handleListDragStart={handleListDragStart}
        listEnter={listEnter}
        handleMouseLeave={handleMouseLeave}
        locale={locale}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        setSize={setSize}
        rtl={rtl}
        primaryColor={theme.ThemeColor.item.Text}
      />
      <div css={styles.chartContainer} onDragOver={e => e.preventDefault()} onDrop={handleStageDrop}>
        <div css={styles.xaxis}>
          <button
            type="submit"
            className="action"
            style={{ background: theme.ThemeColor.item.Text }}
            onClick={handleSave}
            disabled={!isUpdated || isLoading}
          >
            <CheckIcon className="action-icon" width="11px" height="11px" fill="#fff" />
            <FormattedMessage id="common.save" />
          </button>
          <h4 style={{ color: '#FF6347' }}>
            {numberWithCommas(budgets.must)} <FormattedMessage id="common.SARShort" defaultMessage="SAR" />
          </h4>
          <h4 style={{ color: '#FFA400' }}>
            {numberWithCommas(budgets.should)} <FormattedMessage id="common.SARShort" defaultMessage="SAR" />
          </h4>
          <h4 style={{ color: '#19CF63' }}>
            {numberWithCommas(budgets.wont)} <FormattedMessage id="common.SARShort" defaultMessage="SAR" />
          </h4>
        </div>
        <div css={styles.row}>
          <div css={styles.yaxis(rtl)}>
            <p>
              <FormattedMessage id="pdb.high" defaultMessage="High" />
            </p>
            <h4>
              <FormattedMessage id="pdb.impact" defaultMessage="Impact" />
            </h4>
            <p>
              <FormattedMessage id="pdb.low" defaultMessage="Low" />
            </p>
          </div>
          <div ref={stageCont} css={styles.chart}>
            <Stage ref={stage} width={grid.width} height={grid.height}>
              <Layer>
                <Shape fill="#FFF6F4" sceneFunc={drawMustShape} />
                <Shape fill="#FFFBF0" sceneFunc={drawShouldShape} />
              </Layer>
              <Layer>
                {gridArray.map((value, i) => (
                  <React.Fragment key={i}>
                    <Line points={[0, 0, 0, grid.height]} tension={0} stroke="lightgray" x={grid.width * value} y={0} />
                    <Line points={[0, 0, grid.width, 0]} tension={0} stroke="lightgray" x={0} y={grid.height * value} />
                  </React.Fragment>
                ))}
                <Line points={[0, 0, 0, grid.height]} tension={0} stroke="black" x={grid.width * 0.5} y={0} />
                <Line points={[0, 0, grid.width, 0]} tension={0} stroke="black" x={0} y={grid.height * 0.5} />
                <Line
                  points={
                    rtl
                      ? [grid.width * 0.9, grid.height, grid.width / 2, 0]
                      : [grid.width * 0.1, grid.height, grid.width / 2, 0]
                  }
                  tension={0}
                  stroke="#FF6347"
                  dash={[10, 10]}
                />
                <Line
                  points={
                    rtl
                      ? [grid.width * 0.5, grid.height, grid.width * 0.1, 0]
                      : [grid.width * 0.5, grid.height, grid.width * 0.9, 0]
                  }
                  tension={0}
                  stroke="#FFA400"
                  dash={[10, 10]}
                />
              </Layer>
              <Layer ref={layer}>
                {items.map((item, i) =>
                  typeof item.Impact === 'number' && typeof item.Urgency === 'number' ? (
                    <Circle
                      key={i}
                      x={getPointX(item.Urgency, grid.scaleX, rtl)}
                      y={getPointY(item.Impact, grid.scaleY)}
                      numPoints={5}
                      radius={10}
                      innerRadius={20}
                      outerRadius={40}
                      fill={item.Color || 'green'}
                      opacity={1}
                      index={i}
                      draggable
                      dragBoundFunc={handleBound}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      rotation={Math.random() * 180}
                      shadowColor="black"
                      shadowBlur={10}
                      shadowOpacity={0.6}
                      onDragStart={handleDragStart}
                      onDragMove={handleDrag}
                      onDragEnd={handleDragEnd}
                      strokeWidth={3}
                      stroke="white"
                    />
                  ) : null,
                )}
              </Layer>
              {/* <Layer> */}
              {/*   {tooltip.visible ? ( */}
              {/*     <Group */}
              {/*       x={tooltip.x} */}
              {/*       y={tooltip.y - 25} */}
              {/*       offsetY={90} */}
              {/*       offsetX={100} */}
              {/*     > */}
              {/*       <Rect */}
              {/*         width={200} */}
              {/*         height={100} */}
              {/*         fill="white" */}
              {/*         cornerRadius={5} */}
              {/*         shadowBlur={10} */}
              {/*         shadowOpacity={0.6} */}
              {/*         shadowOffset={{ x: 5, y: 5 }} */}
              {/*       /> */}
              {/*       <Text */}
              {/*         width={190} */}
              {/*         align="center" */}
              {/*         offsetX={-5} */}
              {/*         offsetY={-5} */}
              {/*         text={tooltip.title} */}
              {/*       /> */}
              {/*       <Group x={10} y={30}> */}
              {/*         <Text offsetY={-5} text={tooltip.Impact} /> */}
              {/*         <Text offsetY={-20} text={tooltip.Urgency} /> */}
              {/*         <Text offsetY={-35} text={tooltip.total} /> */}
              {/*       </Group> */}
              {/*     </Group> */}
              {/*   ) : null} */}
              {/* </Layer> */}
            </Stage>
            {tooltip.visible ? <Tooltip tooltip={tooltip} rtl={rtl} locale={locale} /> : null}
            {badges.map((badge, index) => (
              <div key={`badge-${index}`} css={styles.badge} style={{ bottom: badge.y, left: badge.x }}>
                {badge.count}
              </div>
            ))}
          </div>
        </div>
        <div css={styles.xaxis}>
          <p>
            <FormattedMessage id="pdb.sooner" defaultMessage="Sooner" />
          </p>
          <h4>
            <FormattedMessage id="pdb.urgency" defaultMessage="Urgency" />
          </h4>
          <p>
            <FormattedMessage id="pdb.later" defaultMessage="Later" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default PriorityDB;
