import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import PTabLoader from './Loader';
import TabItem from '../TabItem';
import routes from '../../routes';

import './styles.scss';
import 'react-tabs/style/react-tabs.css';

class PTab extends Component {
  handleRoute = code => {
    const { history } = this.props;
    history.push(routes.project(code));
  };

  render() {
    const { locale, theme, projects, loading } = this.props;
    const className = locale === 'ar' ? 'rtl tab-name' : ' tab-name';

    return (
      <div className="slider-details" style={{ borderTopColor: theme.ThemeColor.item.Text }}>
        <div className="details-head">
          <div className="tabs">
            {loading ? (
              <PTabLoader />
            ) : (
              <Tabs theme={theme} locale={locale} projects={projects}>
                <TabList>
                  <Tab>
                    <div className={className}>
                      <FormattedMessage id="project.tab.available" />{' '}
                      <span className="tab-name__number" style={{ backgroundColor: theme.ThemeColor.item.Text }}>
                        {projects.length}
                      </span>
                    </div>
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className="tab-details">
                    {projects.length === 0 ? (
                      <div className="dashboard-no-projects">
                        <FormattedMessage id="dashboard.noProjects" defaultMessage="No Projects Available" />
                      </div>
                    ) : (
                      projects.map((item, i) => (
                        <TabItem
                          key={`tab-item-${i}`}
                          theme={theme}
                          locale={locale}
                          item={item}
                          handleRoute={this.handleRoute}
                        />
                      ))
                    )}
                  </div>
                </TabPanel>
              </Tabs>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PTab;
