import React from 'react';

import './styles.scss';

const TitledContent = ({ title, children, fontSize = '14px' }) => {
  return (
    <div className="titledContent__container">
      <label className="titledContent__title" style={{ fontSize }}>
        {title}
      </label>
      <div className="titledContent__content">{children}</div>
    </div>
  );
};

export default TitledContent;
