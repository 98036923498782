import React from 'react';
import ProjectsTasks from './children/ProjectsTasks';
import NonProjectsTasks from './children/NonProjectsTasks';
import PhaseApprovals from './children/PhaseApprovals';
import './styles.scss';
import PMOProcesses from './children/PMOProcesses';

class Approvals extends React.Component {
  state = {};

  render() {
    return (
      <div className="approvals__container">
        <div className="approvals__section">
          <ProjectsTasks />
        </div>
        <div className="approvals__section">
          <NonProjectsTasks />
        </div>
        <div className="approvals__section">
          <PMOProcesses />
        </div>
        <div className="approvals__section">
          <PhaseApprovals />
        </div>
      </div>
    );
  }
}

export default Approvals;
