import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Link } from 'react-router-dom';
import Table from '../Table';
import Field from '../Field';
import ProcessesTabLoader from './loading';
import { ReactComponent as DownArrowIcon } from '../../assets/images/down-arrow.svg';

import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { addToast, formattedDate, getProjectLocalizedName } from '../../Helpers';
import routes from '../../routes';

import './style.scss';

function ProcessesTab({ program, user, theme, match, intl, locale, history }) {
  const [loading, setLoading] = useState(true);
  const [processes, setProcesses] = useState(null);
  const [workflows, setWorkflows] = useState(null);
  const [processesDdOpen, setProcessesDdOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const ddRef = useRef(null);
  const themeColor = theme.ThemeColor.item.Text;

  function getProjectRequests() {
    new ProjectAPIController()
      .getProjectRequests(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          setProcesses(res.Data.Requests);
          setWorkflows(res.Data.Workflows);
          setLoading(false);
        } else {
          addToast.error('toast.error', {
            toastId: 'error-get-processes',
          });
        }
      })
      .catch(() => {
        addToast.error('toast.error', {
          toastId: 'error-get-processes',
        });
      });
  }

  function getColumns() {
    return [
      {
        id: 'Title',
        Header: intl.formatMessage({ id: 'common.title' }),
        width: 300,
        accessor: request => {
          return <p>{getProjectLocalizedName(request, locale)}</p>;
        },
      },
      {
        id: 'RequestTypeText',
        Header: intl.formatMessage({ id: 'common.request-type' }),
        accessor: request => {
          const requestType = theme.Processes.item.Items.find(sysProcess => {
            return sysProcess.Value === request.WorkflowItem.Code;
          });
          return locale === 'ar' ? requestType.Arabic : requestType.English;
        },
      },
      {
        id: 'creation-date',
        Header: intl.formatMessage({ id: 'common.creation-date' }),

        accessor: request => formattedDate(request.CreatedOnDate),
      },
      {
        id: 'created-by',
        Header: intl.formatMessage({ id: 'common.created-by' }),

        accessor: request => request.RequestBy?.DisplayName,
      },
    ];
  }

  function handleRowClick({ original: request }) {
    history.push(routes.processEditPreview(request.WorkflowItem.Code.toLowerCase(), request.ID));
  }

  function renderWorkflowsLinks() {
    if (!processesDdOpen) {
      return null;
    }

    function renderWorkflowLink({ Code, NameArabic, Name, CanDo }) {
      const processesPathname = `${Code.toLowerCase()}?ProjectUID=${match.params.code}`;
      if (CanDo) {
        return (
          <Link key={Code} className="processesTab__dropdown-list-item" to={routes.createProcess(processesPathname)}>
            {locale === 'ar' ? NameArabic : Name}
          </Link>
        );
      }
      return null;
    }

    return workflows.map(renderWorkflowLink);
  }

  function handleClickOutside(event) {
    if (!ddRef.current?.contains(event.target)) {
      setProcessesDdOpen(false);
    }
  }

  useEffect(() => {
    getProjectRequests();
  }, [match.params.code]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) {
    return (
      <div className="processesTab">
        <div className="processesTab__content">
          <div className="processesTab__header">
            <FormattedMessage id="projectdata.processes" />
          </div>

          <div className="processesTab__body">
            <ProcessesTabLoader />
          </div>
        </div>
      </div>
    );
  }

  const requests = processes.filter(item => {
    if (filter?.Value) {
      return filter.Value === item.WorkflowItem?.Code;
    }
    return item;
  });

  const processesList = theme.Processes.item.Items.filter(sysProcess => sysProcess.Value !== 'NewProject');
  const listDirection = intl.locale === 'ar' ? 'rtl' : 'ltr';
  const canCreateProcess = user?.user?.Username === program.ProjectManager?.Username;
  const isThereList = renderWorkflowsLinks()?.filter(item => {
    return item;
  })?.length;

  return (
    <div className="processesTab">
      <div className="processesTab__content">
        <div className="processesTab__header">
          <div className="processesTab__header-title">
            <FormattedMessage id="projectdata.processes" />
          </div>

          {canCreateProcess && (
            <div className="processesTab__dropdown" ref={ddRef}>
              <div
                className="processesTab__dropdown-handler"
                role="button"
                onKeyDown={() => {}}
                tabIndex={0}
                onClick={() => setProcessesDdOpen(!processesDdOpen)}
              >
                <span className="processesTab__dropdown-handler-title">
                  <FormattedMessage id="processes.create-process" />
                  <DownArrowIcon />
                </span>
              </div>
              {processesDdOpen && isThereList ? (
                <div className={`processesTab__dropdown-list ${listDirection}`}>{renderWorkflowsLinks()}</div>
              ) : null}
            </div>
          )}

          <div className="processesTab__header-select-filter">
            <Field
              control={{
                type: 'select',
                onChangeAction: e => {
                  setFilter(e);
                },
                placeholder: intl.formatMessage({
                  id: 'common.request-type',
                }),
                value: filter,
                options: processesList,
                getOptionValue: item => item.Value,
                getOptionLabel: item => (locale === 'ar' ? item.Arabic : item.English),
                className: 'users-select-container',
                classNamePrefix: 'users-select',
                isClearable: true,
              }}
            />
          </div>
        </div>

        <div className="processesTab__body">
          <div className="processesTab__body-title" style={{ color: themeColor }}>
            <FormattedMessage id="taskCenter.PMO-processes" />
          </div>
          <Table
            columns={getColumns()}
            data={requests}
            showPagination={false}
            sortable={false}
            onRowClick={handleRowClick}
            isLoading={loading}
            numOfLoadingRows={4}
            rowBorderColor="transparent"
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ language }) => ({
  locale: language.locale,
});

export default connect(mapStateToProps, null)(injectIntl(ProcessesTab));
