import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';

const ImpulseBalls = ({ size, frontColor, backColor, loading, sizeUnit }) => {
  const countBalls = 3;
  return (
    loading && (
      <Wrapper size={size} sizeUnit={sizeUnit} countBalls={countBalls} frontColor={frontColor} backColor={backColor} />
    )
  );
};

ImpulseBalls.defaultProps = {
  loading: true,
  size: 40,
  frontColor: '#00ff89',
  backColor: '#4b4c56',
  sizeUnit: 'px',
};

ImpulseBalls.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.number,
  frontColor: PropTypes.string,
  backColor: PropTypes.string,
  sizeUnit: PropTypes.string,
};
export default ImpulseBalls;
