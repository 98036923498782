import React from 'react';
import { Line } from 'react-chartjs-2';

const options = {
  scaleShowGridLines: true,
  scaleGridLineColor: 'rgba(0,0,0,.05)',
  scaleGridLineWidth: 1,
  scaleShowHorizontalLines: true,
  scaleShowVerticalLines: true,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  hover: { mode: null },
  bezierCurve: true,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          display: true,
          maxTicksLimit: 4,
          minTicksLimit: 4,
        },
      },
    ],
  },
  tooltips: {
    mode: 'index',
    caretSize: false,
    intersect: false,
    position: 'average',
    titleFontSize: 14,
    bodyFontSize: 13,
    bodySpacing: 3,
    callbacks: {
      labelColor(tooltipItem, chart) {
        return {
          borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].tooltipColor,
          backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].tooltipColor,
        };
      },
    },
    // custom: tooltipModel => {
    //   const newValue = tooltipModel;
    //   if (newValue.y > 35) {
    //     newValue.y = 35;
    //   }
    // },
  },
  bezierCurveTension: 0.4,
  pointDot: true,
  pointDotRadius: 4,
  pointDotStrokeWidth: 1,
  pointHitDetectionRadius: 20,
  datasetStroke: true,
  datasetStrokeWidth: 2,
  datasetFill: true,
  responsive: true,
};

const LineChart = ({ data }) => <Line data={data} options={options} />;

export default LineChart;
