import React from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../Modal';
import Table from '../Table';
import { getStatusName } from '../../Helpers';

function RolesPopUp({ intl, title = 'title', onClose, data, theme, locale }) {
  const columns = [
    {
      id: 'Name',
      accessor: dataItem => dataItem.User.DisplayName,
      Header: intl.formatMessage({ id: 'processes.name' }),
    },
    {
      id: 'Email',
      accessor: dataItem =>
        `${intl.formatMessage({ id: 'project.details-email' })} : ${dataItem.User.Email} ,
        ${intl.formatMessage({ id: 'project.details-phone' })} :  
        ${dataItem.User.Phone}`,
      Header: intl.formatMessage({ id: 'common.contact-details' }),
    },
  ];

  return (
    <Modal
      title={getStatusName(theme?.ResourceRoles?.item?.Items || [], title, locale)}
      isOpen
      onClose={() => onClose()}
      withoutPadding
      dashBoardPopUp
    >
      <Table
        columns={columns}
        data={data}
        showPagination={false}
        sortable={false}
        numOfLoadingRows={3}
        pageSize={data.length}
        withoutBorder
      />
    </Modal>
  );
}
export default injectIntl(RolesPopUp);
