import React from 'react';

import Navigator from '../Navigator';
import Main from './Main';
import Add from './Add';
import Details from './Details';
import routes from '../../routes';
import ProgramsAPIController from '../../providers/controllers/ProgramsAPIController';
import { formattedDate, addToast } from '../../Helpers';

import './style.scss';

class Milestones extends React.Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.updateMilestones = this.updateMilestones.bind(this);
    this.getAllMilestones = this.getAllMilestones.bind(this);
    this.state = {
      milestones: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getAllMilestones();
  }

  getAllMilestones = () => {
    const { match } = this.props;

    this.setState({ loading: true });
    new ProgramsAPIController().getProgramMilestones(match.params.code).then(data => {
      const milestones = data.Data || [];
      this.setState({
        milestones,
        loading: false,
      });
    });
  };

  updateMilestones = async (editMilestones, toDelete) => {
    const { match } = this.props;
    const programUID = match.params.code;
    const programsApiController = new ProgramsAPIController();
    this.setState({ loading: true });
    try {
      const res = await Promise.all([
        ...editMilestones.map(milestone => {
          const data = {
            programUID,
            formData: {
              ...milestone,
              PlannedFinishDate: formattedDate(milestone.PlannedFinishDate),
              ActualFinishDate: formattedDate(milestone.ActualFinishDate),
            },
          };
          return programsApiController.addMilestone(data);
        }),
        ...toDelete.map(deleteItem => {
          return programsApiController.deleteMilestone(programUID, deleteItem);
        }),
      ]);
      if (res.reduce((acc, item) => acc && item.StatusCode === 'Success', true)) {
        addToast.success('common.changesHaveBeenSaved');
      }
    } catch (e) {
      addToast.error('common.errorSavingChanges', {
        toastId: 'milestones-save-error',
      });
    }

    this.getAllMilestones();
  };

  changeView(view, id) {
    const { history, match } = this.props;
    history.push(routes[view](match.url, id));
  }

  render() {
    const {
      state: { selectedComponent, milestones, loading },
      props: { match, theme, user, locale, program },
    } = this;

    const componentsList = [
      {
        id: 'add',
        name: 'add',
        component: Add,
        props: { changeView: this.changeView, getAllMilestones: this.getAllMilestones },
      },
      {
        id: 'details',
        name: 'view',
        component: Details,
        props: { changeView: this.changeView, milestones },
      },
      {
        id: 'edit',
        name: 'edit',
        component: Add,
        props: {
          changeView: this.changeView,
          editMode: true,
          milestones,
          getAllMilestones: this.getAllMilestones,
        },
      },
      {
        id: 'base',
        name: 'main',
        component: Main,
        props: {
          program,
          theme,
          locale,
          user,
          loading,
          milestones,
          changeView: this.changeView,
          updateMilestones: this.updateMilestones,
        },
      },
    ];
    if (loading)
      return (
        <Main
          milestones={milestones}
          key="loading"
          program={program}
          theme={theme}
          locale={locale}
          user={user}
          updateMilestones={this.updateMilestones}
          changeView={this.changeView}
          loading={loading}
        />
      );

    return (
      <>
        <Navigator match={match} componentsList={componentsList} selectedComponentName={selectedComponent} />
      </>
    );
  }
}

export default Milestones;
