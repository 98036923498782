import React from 'react';
import { createMarkup } from '../../../Helpers';
import './style.scss';

function HTML(props) {
  const { data, locale } = props;
  return (
    <div className="project-description-container">
      <div className="bold-text project-description-header">{locale === 'ar' ? data.Arabic : data.English}</div>
      <span
        className="project-description-value"
        dangerouslySetInnerHTML={data.itemData ? createMarkup(data.itemData) : { __html: '---' }}
      />
    </div>
  );
}

export default HTML;
