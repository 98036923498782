import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Table from '../../../Table';
import Panel from '../../../Panel';
import Controllers from '../Controllers';
import ProjectTaskModal from '../../../ProjectTaskModal';

import { getProjectsTasksApprovals, postTaskStatus } from '../../../../actions/tasksCenter';
import { getProjectLocalizedName } from '../../../../Helpers';

import './styles.scss';

class ProjectsTasks extends React.Component {
  state = {
    taskModalOpen: false,
    taskModalInfo: null,
  };

  componentDidMount() {
    this.getProjectsTasksApprovals();
  }

  getProjectsTasksApprovals = () => {
    const { _getProjectsTasksApprovals, projectsTasks } = this.props;
    if (!projectsTasks.length) {
      _getProjectsTasksApprovals();
    }
  };

  getColumns = () => {
    const { intl, locale, _postTaskStatus, theme } = this.props;
    const themeColor = theme.ThemeColor.item.Text;

    return [
      {
        accessor: 'Title', // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.title' }),
        width: 200,
      },
      {
        accessor: data => getProjectLocalizedName(data.Project, locale), // Custom value accessors!
        Header: intl.formatMessage({ id: 'common.projectTitle' }),
        id: 'projectTitle', // Required because our accessor is not a string
      },
      {
        accessor: data => moment(data.StartDate).format('DD-MMM-YYYY'),
        Header: intl.formatMessage({ id: 'common.startDate' }),
        id: 'startDate',
      },
      {
        accessor: data => moment(data.FinishDate).format('DD-MMM-YYYY'),
        Header: intl.formatMessage({ id: 'common.finishDate' }),
        id: 'finishDate',
      },
      {
        accessor: data =>
          data.ExpectedDate
            ? moment(data.ExpectedDate).format('DD-MMM-YYYY')
            : intl.formatMessage({ id: 'common.not-available' }),
        Header: intl.formatMessage({ id: 'common.expectedDate' }),
        id: 'expectedDate',
      },
      {
        accessor: data => data.AssignedTo[data.AssignedTo.length - 1].DisplayName,
        Header: intl.formatMessage({ id: 'common.assignedTo' }),
        id: 'assignedTo',
      },
      {
        accessor: data => `${data.PercentComplete}%`,
        Header: `${intl.formatMessage({ id: 'common.completion' })}`,
        id: 'completion',
      },
      {
        accessor: '',
        Header: '',
        Cell: data => (
          <Controllers
            _postTaskStatus={_postTaskStatus}
            data={data.value}
            themeColor={themeColor}
            type="projectsTasks"
          />
        ),
      },
    ];
  };

  handleRowClick = rowInfo => {
    const { original: task } = rowInfo;

    this.setState({
      taskModalInfo: task,
      taskModalOpen: true,
    });
  };

  handleTaskModalClose = () => {
    this.setState({
      taskModalOpen: false,
    });
  };

  render() {
    const {
      props: {
        projectsTasks,
        loaders,
        intl,
        tasksClassifications,
        taskApprovalStatuses,
        locale,
        _postTaskStatus,
        theme,
      },
      state: { taskModalOpen, taskModalInfo },
      handleTaskModalClose,
    } = this;
    return (
      <div className="projectsTasks__container">
        <Panel
          title={intl.formatMessage({ id: 'taskCenter.projects-tasks-approval' })}
          count={projectsTasks.length}
          isCollapsable
          render={() => (
            <Table
              columns={this.getColumns()}
              data={projectsTasks}
              showPagination={false}
              sortable={false}
              onRowClick={this.handleRowClick}
              isLoading={loaders.projectsTasks}
              numOfLoadingRows={4}
            />
          )}
        />
        <ProjectTaskModal
          themeColor={theme.ThemeColor.item.Text}
          _postTaskStatus={_postTaskStatus}
          isOpen={taskModalOpen}
          task={taskModalInfo}
          onClose={handleTaskModalClose}
          locale={locale}
          tasksClassifications={tasksClassifications}
          taskApprovalStatuses={taskApprovalStatuses}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  _getProjectsTasksApprovals: getProjectsTasksApprovals,
  _postTaskStatus: postTaskStatus,
};

const mapStateToProps = ({ tasksCenter, theme, language }) => ({
  projectsTasks: tasksCenter.approvals.projectsTasks,
  loaders: tasksCenter.loaders,
  tasksClassifications: theme.TasksClassifications.item.Items,
  taskApprovalStatuses: theme.task_approval_statuses.item.Items,
  locale: language.locale,
  theme,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProjectsTasks));
