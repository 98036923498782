import React from 'react';

import { FormattedMessage } from 'react-intl';
import { ReactComponent as AttachmentIcon } from '../../../assets/images/attachment.svg';
import { ReactComponent as EscalationIcon } from '../../../assets/images/esc.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/add.svg';
import IssueLoader from '../../RiskIssueLoader';
import EmptyComponent from '../../EmptyComponent';
import Button from '../../Button';
import {
  getIssueClassification,
  getClassificationColor,
  getClassificationName,
  renderMultiple,
} from '../../../Helpers';

import './style.scss';
import { ReportsUrl } from '../../../providers/config/urlServiceConfig';

function ProjectIssues(props) {
  const { issuesList, theme, locale, loading, match, user, changeView, readOnly } = props;

  const IssueLog = locale === 'en' ? 'PPlusIssueLog-EN' : 'PPlusIssueLog-AR';

  return (
    <section className="project-issues-main-container">
      <div className="sec-header-container tab-header">
        <div className="title bold-text">
          <FormattedMessage id="project-issues.title" />
        </div>
        {!readOnly && (
          <div className="section-actions">
            {issuesList.length !== 0 && !loading && (
              <>
                {ReportsUrl ? (
                  <a
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${ReportsUrl}${IssueLog}&rs:Command=Render&ProjectUID=${match.params.code}&LoginUserName=${user.user.Username}&rs:Format=PDF`}
                    className="action report-link"
                    style={{ background: theme.ThemeColor.item.Text }}
                  >
                    <FormattedMessage id="project-issues.issue-log-report" />
                  </a>
                ) : null}
              </>
            )}

            <Button
              type="submit"
              backgroundColor={theme.ThemeColor.item.Text}
              onClick={() => changeView('add')}
              Icon={() => <PlusIcon width="11px" height="11px" fill="#fff" />}
            >
              <FormattedMessage id="project-issues.add-label" />
            </Button>
          </div>
        )}
      </div>
      <div className="main-issues-content tTab-content-container">
        <div className="list-container">
          {!loading && issuesList.length === 0 && <EmptyComponent small />}
          {loading ? renderMultiple(key => <IssueLoader key={key} />, 5) : null}
          {issuesList.map((item, i) => {
            const classificationName = getClassificationName(
              theme.RisksClassifications.item.Items,
              getIssueClassification(item),
              locale,
            );
            return (
              <div className="list-item cursor" onClick={() => !readOnly && changeView('details', item.ID)} key={i}>
                <span
                  className="label"
                  style={{
                    background: getClassificationColor(
                      theme.IssuesClassifications.item.Items,
                      getIssueClassification(item),
                    ),
                  }}
                >
                  <p>{classificationName}</p>
                </span>
                <div className="item-title">
                  <p>{item.Title}</p>
                  {(item.Attachments || []).length ? (
                    <span className="attachment text-blue">
                      <AttachmentIcon width="13px" height="13px" fill={theme.ThemeColor.item.Text} />
                    </span>
                  ) : null}
                  {item.IsEscalated ? (
                    <span className="attachment text-blue">
                      <EscalationIcon width="13px" height="13px" fill={theme.ThemeColor.item.Text} />
                    </span>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default ProjectIssues;
