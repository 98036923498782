const initialState = {
  ID: 0,
  AffectingProject: '',
  AffectingTask: '',
  AffectedProject: '',
  AffectedTask: '',
  AffectingProjectUID: '',
  AffectingProjectName: '',
  AffectingTaskID: '',
  AffectingTaskName: '',
  AffectingTaskStartDate: '',
  AffectingTaskFinishDate: '',
  AffectedProjectUID: '',
  AffectedProjectName: '',
  AffectedTaskID: '',
  AffectedTaskName: '',
  AffectedTaskStartDate: '',
  AffectedTaskFinishDate: '',
  RequestDate: '',
  Owner: '',
  OwnerUsername: '',
  Category: 1,
  Status: '',
  LogNumber: '',
  Relationship: 1,
  Risks: [],
  Issues: [],
};

export default initialState;
