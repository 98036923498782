import React, { useState } from 'react';

import ProcessesFormLayout from '../../../components/ProcessesFormLayout';
import ProjectCharterForm from '../../../components/ProjectCharterForm';

function ProjectCharter({ readOnly, editData = {}, project = {}, processType, refetchData }) {
  const initialState = {
    Data: {
      RequestID: 0,
      ID: 0,
      Type: 'ProjectCharter',
      ProjectUID: project.ProjectUID,
      ProjectName: project.ProjectName,
      Attachments: [],
      Notes: '',
    },
  };
  const [state, setState] = useState({ ...initialState, ...editData });

  function saveData(data) {
    setState({ ...state, Data: { ...state.Data, ...data } });
  }

  function validate() {
    return !state.Data.Attachments.length;
  }

  return (
    <ProcessesFormLayout
      title="processes.project-charter"
      data={state}
      processType={processType}
      readOnly={readOnly}
      refetchData={refetchData}
      disabled={validate()}
    >
      <ProjectCharterForm
        saveData={saveData}
        request={state}
        project={project}
        readOnly={readOnly}
        processType={processType}
      />
    </ProcessesFormLayout>
  );
}

export default ProjectCharter;
