import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Breadcrumbs from '../Breadcrumb';

import { dashboardsRoutes } from '../../routes/routes';

function DashboardBreadcrumbs({ history, dashboard, language }) {
  const { pathname } = history.location;
  const urlArr = pathname.split('/');
  const bcRoutes = [];
  const isAr = language.locale === 'ar';

  const entity = dashboard.entities.find(el => el.Code === urlArr[3]);
  const program = dashboard.programs.find(el => el.Code === urlArr[5]);

  if (entity) {
    bcRoutes.push({
      title: isAr ? entity.NameArabic : entity.NameEnglish,
      location: `/dashboard/entities/${entity.Code}`,
    });
  }

  if (entity && program) {
    bcRoutes.push({
      title: isAr ? program.NameArabic : program.NameEnglish,
      location: `/dashboard/entities/${entity.Code}/${program.Code}`,
    });
  }

  const generalDashboards = dashboardsRoutes;
  const generalDashInView = generalDashboards.find(route => pathname.indexOf(route.to) > -1);
  const isGeneralDashboard = Boolean(generalDashInView);

  if (isGeneralDashboard) {
    if (generalDashInView.noBreadcrumbs) {
      return null;
    }
  }

  function createBreadcrumbsTitle() {
    let title = 'common.dashboard';

    if (isGeneralDashboard) {
      title = generalDashInView.localeId;
    }

    return title;
  }

  return <Breadcrumbs title={createBreadcrumbsTitle()} routes={bcRoutes} hideCreateButton dashboard />;
}

const mapStateToProps = ({ dashboard, language }) => ({ dashboard, language });

export default connect(mapStateToProps)(withRouter(DashboardBreadcrumbs));
