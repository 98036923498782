import React from 'react';

import './style.scss';

function CollapseContent(props) {
  const { CustomCollapseContent } = props;
  return (
    <section className="collaps-section">
      <div className="collaps-content cursor">
        <div>
          <CustomCollapseContent />
        </div>
      </div>
    </section>
  );
}

export default CollapseContent;
