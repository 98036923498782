import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Field from '../../../components/Field';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import './style.scss';

class RejectModal extends React.Component {
  state = {
    comment: '',
  };

  render() {
    const { comment } = this.state;
    const { intl, loading, toggleModal, handleApproveReject } = this.props;

    return (
      <Modal title={intl.formatMessage({ id: 'common.rejectModal' })} isOpen onClose={() => toggleModal()}>
        <div className="add-public-modal">
          <div className="text-container">
            <span className="label flex-v-center ">
              <FormattedMessage id="common.comment" />
            </span>
          </div>
          <Field
            control={{
              type: 'textarea',
              onChangeAction: e => this.setState({ comment: e.target.value }),
              placeholder: intl.formatMessage({
                id: 'common.comment',
              }),
              value: comment,
              validateOnExite: true,
              // error: !comment.length,
            }}
          />

          <div className="button-container">
            <Button color="#8a8a8b" border backgroundColor="white" onClick={() => toggleModal()}>
              <FormattedMessage id="common.cancel" />
            </Button>

            <div className="cancel-reject-modal">
              <Button
                color="white"
                backgroundColor="#c62828"
                onClick={() => handleApproveReject(2, comment)}
                loading={loading}
              >
                <FormattedMessage id="common.reject" />
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps, null)(RejectModal));
