import React, { Component } from 'react';
import { connect } from 'react-redux';
import FileDrop from 'react-file-drop';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as TimesIcon } from '../../../../assets/images/times.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/images/attachment.svg';
import attachmentsWhitelist, { extraTypesMap } from './filesWhitelist';
import Button from '../../../Button';
import { addToast } from '../../../../Helpers';
import './style.scss';

const FILES_LIMIT = 10;

class Attachment extends Component {
  constructor(props) {
    super(props);
    const { settings } = props;
    this.state = {
      value: settings.value,
      onChangeAction: settings.onChangeAction,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { settings } = props;
    return {
      ...state,
      value: settings.value,
      onChangeAction: settings.onChangeAction,
    };
  }

  handleChange = newValue => {
    const {
      props: {
        settings: { onChangeAction },
      },
      state: { value: currentValue },
    } = this;
    const actualFiles = currentValue.filter(file => !file.isDeleted);
    const reachedMaxLimit = actualFiles.length > FILES_LIMIT || newValue.length + actualFiles.length > FILES_LIMIT;

    if (reachedMaxLimit) {
      addToast.error(`attachment.only${FILES_LIMIT}FilesAtOnce`, {
        toastId: 'upload-limit-error',
      });
      // Error
    } else {
      const acceptedList = newValue.filter(file => {
        let { type } = file;
        if (!type) {
          const nameArr = file.name.split('.');
          const ext = nameArr[nameArr.length - 1];

          const extMime = extraTypesMap[ext];
          if (extMime) {
            type = extMime;
          }
        }
        return attachmentsWhitelist.includes(type);
      });
      onChangeAction([...currentValue, ...acceptedList]);

      if (acceptedList.length !== newValue.length) {
        addToast.error('attachment.fileTypeError', {
          toastId: 'upload-limit-error',
        });
      }
    }
  };

  removeFile(index) {
    const { value, onChangeAction } = this.state;

    const _value = [...value].reduce((accum, file, i) => {
      if (!(file instanceof File)) {
        // already uploaded attachments
        const _file = { ...file };
        if (i === index) {
          _file.isDeleted = true;
        }
        accum.push(_file);
      } else if (index !== i) {
        // new files yet not uploaded
        accum.push(file);
      }
      return accum;
    }, []);

    this.setState({
      value: _value,
    });
    onChangeAction(_value);
  }

  render() {
    const {
      props: {
        settings: { disabled = false, isCommentAttatch, style, required },
        themeColor,
      },
      state: { value },
    } = this;
    return isCommentAttatch ? (
      <div className="upload-wrapper">
        <Button color="white" backgroundColor="#eeeeee" style={style} Icon={() => <LinkIcon fill={themeColor} />} />
        <input
          type="file"
          name="myfile"
          disabled={disabled}
          onChange={e => this.handleChange(Array.from(e.target.files))}
        />
      </div>
    ) : (
      <div className="attachments-container">
        <FileDrop onDrop={files => this.handleChange(Array.from(files))}>
          <div className={`control-input attachment-container${required && !value.length ? ' error' : ''}`}>
            <label htmlFor="attachments-input">
              <div>
                <FormattedMessage id="attachment-field.drop-files" />
              </div>
              <div className="text-gray">
                <FormattedMessage id="attachment-field.limit-files" />
              </div>
              <input
                onChange={e => this.handleChange(Array.from(e.target.files))}
                className="attachments-input"
                type="file"
                id="attachments-input"
                multiple
              />
            </label>
          </div>
          <div className="uploaded-files">
            {value.map((file, i) =>
              !file.isDeleted ? (
                <div className="file" key={i}>
                  <span className="file-name">{file.name || file.Name}</span>
                  <TimesIcon onClick={() => this.removeFile(i)} className="cursor" width="11px" height="11px" />
                </div>
              ) : null,
            )}
          </div>
        </FileDrop>
      </div>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(injectIntl(Attachment));
