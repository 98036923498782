import React from 'react';
import './style.scss';

function ProcessesLoader() {
  return (
    <div className="processes-loader__container animated-background">
      <div className="processes-loader__content">
        <div className="section-one">
          <div className="processes-loader__content-status flex-h-v-center">
            <p className="processes-loader__details-item-title shadow-background" />
          </div>
        </div>
        <div className="section-two">
          <div className="processes-loader__content-status flex-h-v-center">
            <div className="shadow-background" />
          </div>
          <div className="processes-loader__content-status flex-h-v-center">
            <div className="shadow-background" />
          </div>

          <div className="processes-loader__content-status flex-h-v-center">
            <div className="shadow-background" />
          </div>
        </div>
      </div>

      <div className="processes-loader">
        <div className="processes-loader__details-row">
          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>

          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>

          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>
          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>
          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>
        </div>
        <div className="table-title-container">
          <p className="table-title shadow-background" />
        </div>
        <p className="long-title shadow-background" />

        <div className="processes-loader__details-row">
          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>

          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>

          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>
          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>
          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>
          <div className="processes-loader__details-item">
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
            <p className="details-item-title shadow-background" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessesLoader;
