import React from 'react';

import Input from './Input';
import NumberInput from './NumberInput';
import InputWithFormula from './InputWithFormula';
import TextArea from './TextAera';
import DateField from './Date';
import SelectControl from './Select';
import Attachment from './Attachment';

function Control(props) {
  const { settings } = props;

  switch (settings.type) {
    case 'input':
      return <Input settings={settings} />;
    case 'number':
      return <NumberInput settings={settings} />;
    case 'inputwithformula':
      return <InputWithFormula settings={settings} />;
    case 'textarea':
      return <TextArea settings={settings} />;
    case 'select':
      return <SelectControl settings={settings} />;
    case 'date':
      return <DateField settings={settings} />;
    case 'attachment':
      return <Attachment settings={settings} />;
    default:
      return <Input settings={settings} />;
  }
}

export default Control;
