import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import PropTypes from 'prop-types';
import Folder from '../Folder';
import FolderLoader from './FolderLoader';
import { ReactComponent as FilesIcon } from '../../assets/images/files.svg';

import { getLibraryStructureAction } from '../../actions/docsLibrary';
import { renderMultiple } from '../../Helpers';

import './style.scss';

class DocsLibrary extends Component {
  componentDidMount() {
    const { getLibraryStructureAction: getLibraryStructure } = this.props;
    getLibraryStructure();
  }

  renderDefaultFolders = () => {
    const {
      docsLibrary: {
        structure,
        loading: { structure: loadingStructure },
      },
      match,
      theme,
      locale,
    } = this.props;
    if (loadingStructure) {
      return renderMultiple(key => <FolderLoader key={key} themeColor={theme.ThemeColor.item.Text} />, 4);
    }

    if (!structure.length) return null;

    return structure.map((folder, i) => (
      <Folder
        projectUID={match.params.code}
        key={`folder#${i + 1}`}
        theme={theme}
        listType={folder.ListType}
        title={locale === 'ar' ? folder.TitleArabic : folder.TitleEnglish}
        upload={folder.ListType === 'library'}
      />
    ));
  };

  render() {
    const { theme, match, intl } = this.props;

    return (
      <div className="docs-library">
        <div className="docs-library__content">
          <div className="docs-library__title">
            <FilesIcon />
            <FormattedMessage id="projectdata.docsLibrary" />
          </div>
          <div className="docs-library__body">
            <Folder
              theme={theme}
              projectUID={match.params.code}
              defaultOpen
              title={intl.formatMessage({ id: 'projectdata.docsLibrary' })}
              upload={false}
              render={this.renderDefaultFolders}
            />
          </div>
        </div>
      </div>
    );
  }
}

DocsLibrary.propTypes = {
  getLibraryStructureAction: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  docsLibrary: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ docsLibrary }) => ({
  docsLibrary,
});

const mapDispatchToProps = {
  getLibraryStructureAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocsLibrary));
