import React from 'react';
import { FormattedMessage } from 'react-intl';

import { numberWithCommas } from '../../../Helpers';

const SpentCell = ({ project }) => {
  return (
    <div className="table-spent-field">
      <div className="table-spent-field__input">
        <span>{numberWithCommas(project.Spent || 0)}&nbsp;</span>
        <FormattedMessage id="common.SAR" />
      </div>
    </div>
  );
};

export default SpentCell;
