import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import './style.scss';
import { Link, withRouter } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/add-plus-button.svg';
import Button from '../Button';
import sysRoutes from '../../routes';
/**
 * [Breadcrumb description]
 * @param {[type]} routes [route{}[]]
 * @param routes: // is an array of route objects {}
 * each @route object contains a title and a location
 */

const Breadcrumbs = ({ title, routes = [], theme, history, securityInfo, hideCreateButton }) => {
  return (
    <div className="bread-crumb-main-container">
      <div className="bread-crumbs__container">
        <div className="bread-crumbs__title">
          <FormattedMessage id={title} />
        </div>
        <div className="bread-crumbs__icon-holder">
          <HomeIcon className="home" width="20px" height="20px" />
        </div>
        <div className="bread-crumbs__routes">
          <Link className="bread-crumbs__route-link" to="/">
            <FormattedMessage id="common.home" defaultMessage="common.home" />
            &ensp;/
          </Link>
          {routes.map((route, index) => (
            <Link key={index} className="bread-crumbs__route-link cursor" to={route.location}>
              {` ${route.title} ${index + 1 !== routes.length ? ' /' : ''}`}
            </Link>
          ))}
        </div>
      </div>
      {!hideCreateButton && Array.isArray(securityInfo) && securityInfo.includes(window.env.ADD_PROJECT_GROUP) ? (
        <Button
          backgroundColor={theme.ThemeColor.item.Text}
          Icon={() => <PlusIcon fill="white" width="10" height="10" />}
          onClick={() => history.push(sysRoutes.createProcess('newproject'))}
        >
          <FormattedMessage id="createProject.title" />
        </Button>
      ) : null}
    </div>
  );
};

const mapStateToProps = ({ theme, user }) => {
  return {
    theme,
    securityInfo: user.securityInfo,
  };
};

export default withRouter(connect(mapStateToProps, null)(Breadcrumbs));
