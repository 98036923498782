import React from 'react';
import { connect } from 'react-redux';
import { cleanHandshakeDataWithKey } from '../../Helpers';
import { ReactComponent as CheckIcon } from '../../assets/images/CheckIcon.svg';
import './style.scss';

const StepIcon = props => {
  const { step } = props;

  const defaultIcon = () => {
    return (
      <div
        className="step-icon-container"
        style={{
          backgroundColor: '#D1D1D1',
        }}
      />
    );
  };

  const getIcon = () => {
    const { theme } = props;

    switch (cleanHandshakeDataWithKey(theme.ProjectPhasesStatuses.item.Items, 'Value')[step.StatusID].item.English) {
      case 'Approved':
        return (
          <div
            className="step-icon-container"
            style={{
              backgroundColor: theme.ThemeColor.item.Text,
            }}
          >
            <CheckIcon fill="white" />
          </div>
        );

      case 'Started':
      case 'Pending':
        return (
          <div
            className="step-icon-container"
            style={{
              backgroundColor: theme.ThemeColor.item.Text,
            }}
          />
        );

      case 'Rejected':
        return (
          <div
            className="step-icon-container"
            style={{
              backgroundColor: '#c62828',
            }}
          />
        );

      default:
        return defaultIcon();
    }
  };

  return <div className="phases-circle-step-container">{getIcon()}</div>;
};

const mapStateToProps = state => {
  return {
    theme: state.theme,
  };
};

export default connect(mapStateToProps, null)(StepIcon);
