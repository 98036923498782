import React from 'react';
import Attachment from '../Attachment';
import './style.scss';

const AttachmentsList = ({ attachments = [] }) => {
  return (
    <div className="attachments-list">
      {attachments.map((attachment, index) => (
        <Attachment attachment={attachment} key={`attach-${index + 1}`} />
      ))}
    </div>
  );
};

export default AttachmentsList;
