import React from 'react';

import './projectInfoLoader.scss';

export default ({ themeColor }) => (
  <div className="projectInfoLoader__container animated-background" style={{ borderTopColor: themeColor }}>
    <div className="projectInfoLoader__title flex-h-v-center">
      <p className="shadow-background" />
    </div>
    <div className="projectInfoLoader__status flex-h-v-center">
      <p className="shadow-background" />
    </div>
    <div className="projectInfoLoader__infoContainer flex-h-v-center flex-wrap">
      <div className="projectInfoLoader__infoContainer-item shadow-background" />
      <div className="projectInfoLoader__infoContainer-item shadow-background" />
      <div className="projectInfoLoader__infoContainer-item shadow-background" />
      <div className="projectInfoLoader__infoContainer-item shadow-background" />
    </div>
    <div className="projectInfoLoader__progress flex-h-v-center">
      <div className="projectInfoLoader__progress-item flex-h-v-center">
        <p className="shadow-background" />
      </div>
      <div className="projectInfoLoader__progress-item flex-h-v-center">
        <p className="shadow-background" />
      </div>
      <div className="projectInfoLoader__progress-item flex-h-v-center">
        <p className="shadow-background" />
      </div>
    </div>
  </div>
);
