import React, { useLayoutEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import DataVizTheme from '@amcharts/amcharts4/themes/dataviz';
import Animatedheme from '@amcharts/amcharts4/themes/animated';
import Moment from 'moment';
import { ReactComponent as Point } from '../../assets/images/point-with-line.svg';
import Panel from '../Panel';

function ManPowerChart({ utilizationCapacity, intl }) {
  const legandlabels = {
    booked: {
      name: intl.formatMessage({ id: 'common.booked-mandays' }),
      color: '#3893E4',
      fill: 'rgba(209, 239, 211,0.5)',
    },
    available: {
      name: intl.formatMessage({ id: 'common.available-mandays' }),
      color: '#E9963E',
      fill: 'rgba(249,211,211,0.5)',
    },
  };

  useLayoutEffect(() => {
    am4core.useTheme(DataVizTheme);
    am4core.useTheme(Animatedheme);
    const chart = am4core.create('chartID', am4charts.XYChart);
    chart.logo.height = -10000;
    chart.rtl = intl?.locale === 'ar';
    const _data = utilizationCapacity?.Months?.map(el => {
      const _date = `${el.Month?.toString()}/01/${new Date().getFullYear()}`;
      return {
        date: new Date(_date),
        booked: el.Booked,
        available: el.Available,
        category: Moment(new Date(_date)).format('MMM'),
      };
    });

    chart.data = intl?.locale === 'ar' ? _data.reverse() : _data;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.hidden = true;
    dateAxis.cursorTooltipEnabled = false;

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.openValueY = 'booked';
    series.dataFields.valueY = 'available';
    series.sequencedInterpolation = true;
    series.strokeWidth = 2;
    series.stroke = legandlabels.booked.color;
    series.fill = legandlabels.booked.fill;
    series.fillOpacity = 0.8;

    series.tooltipHTML = `${legandlabels.available.name}: {valueY.value} <br> ${legandlabels.booked.name}: {openValueY.value}`;
    series.tooltip.autoTextColor = false;
    series.tooltip.getFillFromObject = false;
    series.tooltip.label.fill = am4core.color('#000');
    series.tooltip.background.fill = am4core.color('#ffff');

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.fill = legandlabels.booked.color;
    bullet.fillOpacity = 1;
    bullet.strokeWidth = 2;
    bullet.circle.radius = 4;

    const series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.dateX = 'date';
    series2.dataFields.valueY = 'booked';
    series2.sequencedInterpolation = true;
    series2.strokeWidth = 2;
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.getStrokeFromObject = true;
    series2.tooltip.label.fill = am4core.color('#000');
    series2.stroke = legandlabels.available.color;
    series2.fill = legandlabels.available.fill;

    const bullet2 = series2.bullets.push(new am4charts.CircleBullet());
    bullet2.fill = legandlabels.available.color;
    bullet2.fillOpacity = 1;
    bullet2.strokeWidth = 2;
    bullet2.circle.radius = 4;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;

    let negativeRange;
    chart.events.on('datavalidated', () => {
      series.dataItems.each(s1DataItem => {
        let s1PreviousDataItem;
        let s2PreviousDataItem;

        const s2DataItem = series2.dataItems.getIndex(s1DataItem.index);

        if (s1DataItem.index > 0) {
          s1PreviousDataItem = series.dataItems.getIndex(s1DataItem.index - 1);
          s2PreviousDataItem = series2.dataItems.getIndex(s1DataItem.index - 1);
        }

        let startTime = am4core.time
          .round(new Date(s1DataItem.dateX.getTime()), dateAxis.baseInterval.timeUnit, dateAxis.baseInterval.count)
          .getTime();

        if (s1PreviousDataItem && s2PreviousDataItem) {
          const x0 =
            am4core.time
              .round(
                new Date(s1PreviousDataItem.dateX.getTime()),
                dateAxis.baseInterval.timeUnit,
                dateAxis.baseInterval.count,
              )
              .getTime() +
            dateAxis.baseDuration / 2;
          const y01 = s1PreviousDataItem.valueY;
          const y02 = s2PreviousDataItem.valueY;

          const x1 = startTime + dateAxis.baseDuration / 2;
          const y11 = s1DataItem.valueY;
          const y12 = s2DataItem.valueY;

          const intersection = am4core.math.getLineIntersection(
            { x: x0, y: y01 },
            { x: x1, y: y11 },
            { x: x0, y: y02 },
            { x: x1, y: y12 },
          );

          startTime = Math.round(intersection.x);
        }

        if (s2DataItem.valueY > s1DataItem.valueY) {
          if (!negativeRange) {
            negativeRange = dateAxis.createSeriesRange(series);
            negativeRange.date = new Date(startTime);
            negativeRange.contents.fill = series2.fill;
            negativeRange.contents.fillOpacity = 0.8;
          }
        } else {
          if (negativeRange) {
            negativeRange.endDate = new Date(startTime);
          }
          negativeRange = undefined;
        }

        if (s1DataItem.index === series.dataItems.length - 1) {
          if (negativeRange) {
            negativeRange.endDate = new Date(s1DataItem.dateX.getTime() + dateAxis.baseDuration / 2);
            negativeRange = undefined;
          }
        }
      });
    });
  }, [intl?.locale]);

  return (
    <div className="man-power-legend-main-container">
      <Panel
        count={null}
        title={() => (
          <div className="man-power-legend-container">
            <div className="title">
              <FormattedMessage id="common.man-power-capacity" />
            </div>
            <div className="man-power-legend">
              <span className="legend">
                <span className="legend-square" style={{ backgroundColor: legandlabels.booked.fill }} />
                <FormattedMessage id="common.utilized" />
              </span>
              <span className="legend">
                <span className="legend-square" style={{ backgroundColor: legandlabels.available.fill }} />
                <FormattedMessage id="common.under-utilized" />
              </span>
              <span className="legend">
                <div className="point">
                  <Point stroke={legandlabels.available.color} />
                </div>
                <FormattedMessage id="common.booked-mandays" />
              </span>

              <span className="legend">
                <div className="point">
                  <Point stroke={legandlabels.booked.color} />
                </div>
                <FormattedMessage id="common.available-mandays" />
              </span>
            </div>
          </div>
        )}
        render={() => {
          return utilizationCapacity?.Months?.length ? (
            <>
              <div className="manPower">
                <div id="chartID" className="chart" />
              </div>
            </>
          ) : (
            <div className="dashboard-no-data">
              <FormattedMessage id="common.looksLikeThereIsNoDataHere" />
            </div>
          );
        }}
      />
    </div>
  );
}

export default injectIntl(ManPowerChart);
