import React from 'react';
import FileIcon from '../icons/FileIcon';

const FileMessage = props => {
  const { data } = props;
  return (
    <a className="sc-message--file" rel="noreferrer noopener" href={data.url} target="_blank" download={data.fileName}>
      <FileIcon />
      <p>{data.fileName}</p>
    </a>
  );
};

export default FileMessage;
