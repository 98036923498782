import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ProgramPreview from '../ProgramPreview';
import ProjectRisks from '../ProjectRisks';
import ProjectIssues from '../ProjectIssues';
import DocsLibrary from '../DocsLibrary';
import Deliverables from '../Deliverables';
import Milestones from '../ProgramMilestones';
import Financial from '../Financial';
import PriorityDBTab from '../PriorityDBTab';
import { ReactComponent as ChartIcon } from '../../assets/images/column-chart.svg';
import { ReactComponent as RiskIcon } from '../../assets/images/risk.svg';
import { ReactComponent as IssueIcon } from '../../assets/images/issue.svg';
import { ReactComponent as FilesIcon } from '../../assets/images/files.svg';
import { ReactComponent as FlagIcon } from '../../assets/images/flag.svg';
import { ReactComponent as Clipboard } from '../../assets/images/clipboard.svg';
import { ReactComponent as CoinsIcon } from '../../assets/images/money-bag.svg';
import { ReactComponent as PriorityIcon } from '../../assets/images/priority.svg';

import './style.scss';
import TabsContainer from '../TabsContainer';
import Launcher from '../P+Chat';
import routes from '../../routes';

class ProgramData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponentName: 'projectdata.preview',
      tabID: 'preview',
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const {
      location: { search },
    } = history;

    if (search && search.includes('?to=')) {
      const to = search.replace('?to=', '');
      if (to === 'risk') this.handleSelectedCompChange('projectdata.risks', 'risks');
      if (to === 'issue') this.handleSelectedCompChange('projectdata.issues', 'issues');
    }
  }

  handleSelectedCompChange = (item, id) => {
    const { match, history } = this.props;
    this.setState(
      {
        selectedComponentName: item,
        tabID: id,
      },
      () => {
        history.replace(routes[id](match.url));
      },
    );
  };

  render() {
    const { selectedComponentName, tabID } = this.state;
    const { theme, program, locale, match, loading, user } = this.props;
    const isProgram = match.url.toLowerCase().includes('program');
    let componentsList = [
      {
        id: 'preview',
        name: 'projectdata.preview',
        icon: <ChartIcon width="15px" height="15px" stroke={theme.ThemeColor.item.Text} />,
        component: ProgramPreview,
        props: { loading, program, theme, locale },
      },

      {
        id: 'risks',
        name: 'projectdata.risks',
        icon: <RiskIcon width="15px" height="15px" />,
        component: ProjectRisks,
        props: {
          theme,
          locale,
          user,
          program,
          onConvertToIssue: () => this.handleSelectedCompChange('projectdata.issues', 'risks'),
        },
      },
      {
        id: 'issues',
        name: 'projectdata.issues',
        icon: <IssueIcon width="15px" height="15px" />,
        component: ProjectIssues,
        props: { program, theme, locale, user },
      },
      {
        id: 'documents',
        name: 'projectdata.docsLibrary',
        icon: <FilesIcon width="15px" height="15px" />,
        component: DocsLibrary,
        props: { theme, locale },
      },
      {
        id: 'milestones',
        name: 'projectdata.milestones',
        icon: <FlagIcon width="15px" height="15px" />,
        component: Milestones,
        props: { program, theme, locale, user },
      },
      {
        id: 'financial',
        name: 'projectdata.financial',
        icon: <CoinsIcon width="15px" height="15px" />,
        component: Financial,
        props: { theme, locale, user, program },
      },
      {
        id: 'deliverables',
        name: 'deliverables',
        icon: <Clipboard width="15px" height="15px" />,
        component: Deliverables,
        props: { theme, locale },
      },
    ];
    if (isProgram) {
      componentsList = [
        ...componentsList,
        {
          id: 'priority',
          name: 'priority',
          icon: <PriorityIcon width="15px" height="15px" />,
          component: PriorityDBTab,
          props: { theme, locale },
        },
      ];
    }
    if (!program) return null;
    return (
      <>
        <TabsContainer
          activeTab={tabID}
          activeTabName={selectedComponentName}
          tabs={componentsList}
          onTabClick={this.handleSelectedCompChange}
          theme={theme}
          locale={locale}
          match={match}
        />
        <div className="chat-contanier">
          <Launcher
            theme={theme}
            locale={locale}
            code={match.params.code}
            agentProfile={{
              teamName: locale === 'ar' ? 'نقاشات المشروع' : 'Chat',
              imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
            }}
            showEmoji={false}
          />
        </div>
      </>
    );
  }
}

export default withRouter(ProgramData);
