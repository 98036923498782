import React from 'react';
import './style.scss';

const FieldLabel = props => {
  const { settings } = props;
  return (
    <div className="label-container" style={settings.style}>
      {' '}
      {settings.label}
    </div>
  );
};
export default FieldLabel;
