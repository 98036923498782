import React from 'react';
import NewProjectsRequests from './NewProjectsRequests';
import MyRequestsPanel from './MyRequestsPanel';
import './style.scss';

const MyRequests = () => {
  return (
    <div className="myrequests__container">
      <div className="myrequests__section">
        <MyRequestsPanel />
      </div>
      <div className="myrequests__section">
        <NewProjectsRequests />
      </div>
    </div>
  );
};

export default MyRequests;
