import React, { Component } from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';

import { FormattedMessage } from 'react-intl';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-dwon.svg';
import routes from '../../routes';

import './styles.scss';
import * as PropTypes from 'prop-types';

class TabsNav extends Component {
  state = {
    dimensions: null,
    dropDownMoreOpen: false,
  };

  componentDidMount() {
    this.setState({
      dimensions: {
        width: this.container.offsetWidth - 150,
      },
    });
    window.addEventListener('resize', this.calculateContainer);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateContainer);
  }

  calculateContainer = () => {
    if (this.container) {
      this.setState({
        dimensions: {
          width: this.container.offsetWidth - 150,
        },
      });
    }
  };

  render() {
    const { tabs, activeTabName, onTabClick, themeColor, location, match } = this.props;
    let topTabs = tabs;
    let moreTabs = [];
    const { dimensions, dropDownMoreOpen } = this.state;

    if (dimensions && tabs.length * 150 > dimensions.width) {
      const allowedTabs = parseInt((dimensions.width / (tabs.length * 150)).toString()[2], 10);

      if (tabs.findIndex(tab => matchPath(location.pathname, { path: routes[tab.id]() })) < allowedTabs) {
        topTabs = tabs.slice(0, allowedTabs);
        moreTabs = tabs.slice(allowedTabs);
      } else {
        topTabs = tabs.slice(0, allowedTabs - 1);
        topTabs.push(tabs.find(tab => activeTabName === tab.name));
        moreTabs = tabs.slice(allowedTabs - 1);
        const removeIndex = moreTabs.findIndex(tab => tab.name === activeTabName);
        moreTabs.splice(removeIndex, 1);
      }
    }

    return (
      <div className="tabsNav" style={{ borderTopColor: themeColor }}>
        <nav className="tabsNav__wrapper">
          <div className="tabsNav__container" ref={el => (this.container = el)}>
            {topTabs.map((item, i) => {
              const isActive = matchPath(location.pathname, { path: routes[item.id](match.path) });
              return (
                <div
                  role="button"
                  key={`tab#${i + 1}`}
                  tabIndex={0}
                  style={{ borderBottomColor: themeColor }}
                  className={`${isActive ? 'active' : ''} nav-item`}
                  onClick={() => onTabClick(item.name, item.id)}
                >
                  <div
                    className={css`
                      svg {
                        fill: ${isActive ? themeColor : '#8B8B8C'} !important;
                        stroke: ${isActive ? themeColor : '#8B8B8C'} !important;
                      }
                    `}
                  >
                    {item.icon}
                  </div>
                  <div>
                    <FormattedMessage id={item.name} />
                  </div>
                </div>
              );
            })}
          </div>
          {moreTabs.length !== 0 && (
            <div
              className="tabsNav__container-more-menu active"
              onClick={() =>
                this.setState({
                  dropDownMoreOpen: !dropDownMoreOpen,
                })
              }
            >
              <div>
                <FormattedMessage id="common.more" />
              </div>
              <ArrowDownIcon fill="white" className="tabsNav__more-svg" style={{ background: themeColor }} />
              <div className={`tabsNav__dropdown ${dropDownMoreOpen ? '' : 'display-none '}`}>
                {moreTabs.map((item, i) => {
                  const isActive = matchPath(location.pathname, { path: routes[item.id](match.path) });
                  return (
                    <div
                      role="button"
                      key={`tab#${i + 1}`}
                      tabIndex={0}
                      style={{ borderBottomColor: themeColor }}
                      className={`${isActive ? 'active' : ''} nav-item tabsNav__dropdown-item`}
                      onClick={() => onTabClick(item.name, item.id)}
                    >
                      <div
                        className={css`
                          svg {
                            fill: ${themeColor} !important;
                            stroke: ${themeColor} !important;
                            margin-inline-end: 12px;
                          }
                        `}
                      >
                        {item.icon}
                      </div>
                      <div>
                        <FormattedMessage id={item.name} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </nav>
      </div>
    );
  }
}

TabsNav.propTypes = {
  tabs: PropTypes.any,
  onTabClick: PropTypes.any,
  themeColor: PropTypes.any,
};

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(withRouter(TabsNav));
