import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { FormattedMessage } from 'react-intl';
import Button from '../../Button';
import { ReactComponent as BackIcon } from '../../../assets/images/back-arrow.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/check.svg';

import { modules, formats, addToast } from '../../../Helpers';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import 'react-quill/dist/quill.snow.css';
import './style.scss';

class ProjectUpdateProgressEditMode extends Component {
  constructor(props) {
    super(props);
    const { updateProgressList } = props;
    this.state = {
      PlannedActivity: updateProgressList.length ? updateProgressList[0].PlannedActivity : '',
      ProgressActivity: updateProgressList.length ? updateProgressList[0].ProgressActivity : '',
      isSubmitting: false,
    };
  }

  handleSave() {
    const { match } = this.props;
    const { PlannedActivity, ProgressActivity } = this.state;
    const data = {
      progress: {
        ProjectUID: match.params.code,
        PlannedActivity,
        ProgressActivity,
      },
    };
    this.setState({ isSubmitting: true });
    new ProjectAPIController()
      .progressUpdate(data)
      .then(res => {
        const { StatusCode } = res;
        if (StatusCode === 'Success') {
          const { onProgressSave } = this.props;
          addToast.success('common.changesHaveBeenSaved');
          onProgressSave(this.state);
        }
        this.setState({ isSubmitting: false });
      })
      .catch(err => {
        addToast.error('common.errorSavingChanges', { toastId: 'edit-progress' });

        this.setState({ isSubmitting: false });
        return err;
      });
  }

  render() {
    const { changeView, theme } = this.props;
    const { ProgressActivity, PlannedActivity, isSubmitting } = this.state;
    return (
      <div className={`project-update-progress-edit-container ${isSubmitting ? 'loading' : ''}`}>
        <div className="sec-header-container tab-header">
          <div className="back-button  cursor" onClick={() => changeView('base')}>
            <BackIcon width="15px" height="15px" style={{ fill: theme.ThemeColor.item.Text }} />
          </div>
          <div className="title bold-text">
            <FormattedMessage id="progressmain.edit-view-label" />
          </div>
          <div className="div-actions section-actions">
            <Button
              type="button"
              Icon={() => <CheckIcon className="action-icon" width="15px" height="15px" fill="#fff" />}
              backgroundColor={theme.ThemeColor.item.Text}
              onClick={() => this.handleSave()}
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              <FormattedMessage id="progressmain.edit-view-save-label" />
            </Button>
          </div>
        </div>
        <div className="main-content">
          <div className="edit-div progress">
            <div className="title bold-text">
              <FormattedMessage id="progressmain.progress-label" />
            </div>
            <div className="editor-container">
              <ReactQuill
                style={{ height: '100px' }}
                value={ProgressActivity}
                onChange={e => this.setState({ ProgressActivity: e })}
                modules={modules}
                formats={formats}
              />
            </div>
          </div>

          <div className="edit-div planned">
            <div className="title bold-text">
              <FormattedMessage id="progressmain.planned-label" />
            </div>
            <div className="editor-container">
              <ReactQuill
                style={{ height: '100px' }}
                value={PlannedActivity}
                theme="snow"
                onChange={e => this.setState({ PlannedActivity: e })}
                modules={modules}
                formats={formats}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectUpdateProgressEditMode;
