import React, { useState, useEffect } from 'react';
//  eslint-disable-next-line
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { connect } from 'react-redux';
import './style.scss';
import { FormattedMessage } from 'react-intl';
import { getClassificationColor, getTaskClassification } from '../../Helpers';

// eslint-disable-next-line
function SliderItem(props) {
  const {
    value,
    onChangePercent,
    theme,
    item,
    showPercentage = true,
    miniPercentage = false,
    width = '250px',
    disabled = false,
    color,
    type = 'task',
    locale,
  } = props;
  let statusColor = color;
  const [theValue, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  if (type === 'task') {
    statusColor = getClassificationColor(theme.TasksClassifications.item.Items, getTaskClassification(item));
  }

  return (
    <div className={`slider-container ${disabled ? 'disabled' : ''}`}>
      {showPercentage ? (
        <div className="complete-label">
          <FormattedMessage id="project-tasks.complete-label" /> {theValue}%
        </div>
      ) : null}
      <div className="slider" style={{ width }}>
        {miniPercentage ? <div className="mini-percentage-label">{theValue}%</div> : null}

        <Slider
          disabled={disabled}
          min={0}
          max={100}
          defaultValue={theValue}
          value={theValue}
          railStyle={{ backgroundColor: '#e6e6e6', height: '10px' }}
          trackStyle={{ backgroundColor: statusColor, height: '10px' }}
          onChange={event => {
            setValue(event);
          }}
          onAfterChange={() => onChangePercent(theValue)}
          reverse={locale === 'ar'}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ language }) => ({
  locale: language.locale,
});

export default connect(mapStateToProps, null)(SliderItem);
