import { dropMessages } from 'react-chat-widget';
import { GET_USER_SECURITY_INFO } from '../actions/userActions';

const INITIAL_STATE = { user: null, loggedin: false, notifications: [], DeviceID: null, securityInfo: [] };
export default (state = INITIAL_STATE, { type, payload = {} }) => {
  switch (type) {
    case 'LOGIN': {
      return {
        ...state,
        ...payload,
        loggedin: true,
      };
    }
    case 'LOGOUT': {
      dropMessages();
      localStorage.removeItem('userUttered');
      return {
        ...state,
        ...payload,
        user: null,
        loggedin: false,
        notifications: [],
        DeviceID: null,
      };
    }
    case 'GET_NOTIFICATIONS':
      return {
        ...state,
        notifications: payload,
      };

    case 'DEVICE_ID':
      return {
        ...state,
        DeviceID: payload,
      };

    case GET_USER_SECURITY_INFO:
      return {
        ...state,
        Loading: false,
        securityInfo: payload.Groups,
      };
    default:
      return state;
  }
};
