import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * [Tabs description]
 * @extends React
 * @param tabs[] is an Array of tab{}
 * tab {
 *   id: string (required),
 *   title: string (required),
 *   count: number (nullable)
 * }
 * @param activeTab string > refers to tab.id (required)
 * @param onChange () => tab.id
 * @param themeColor refers to theme.ThemeColor.item.Text
 */

const Tabs = ({ activeTab, tabs, onChange, themeColor }) => {
  return (
    <div className="tabs__container">
      {tabs.map(tab => (
        <div
          role="button"
          tabIndex={0}
          className={`tabs__item ${activeTab === tab.id ? 'tabs__item-active' : ''}`}
          style={{ borderBottomColor: themeColor }}
          key={tab.id}
          onClick={() => onChange(tab.id)}
        >
          <p className="tabs__item-title">{tab.title}</p>
          {tab.count ? (
            <div
              className="tabs__item-count"
              style={{
                backgroundColor: themeColor,
              }}
            >
              <p>{tab.count}</p>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Tabs;
