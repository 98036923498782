import React from 'react';
import Table from '../Table';
import ProgressBar from '../ProgressBar';
import { getStatusColor, getProjectLocalizedName, formattedDate, nFormatter, getPhaseName } from '../../Helpers';

import './style.scss';

function ProjectTable({ data, intl, locale, theme, history }) {
  const columns = [
    {
      id: 'projectName',
      accessor: dataItem => getProjectLocalizedName(dataItem, locale),
      Header: intl.formatMessage({ id: 'dashboard.project-name' }),
    },
    {
      id: 'startDate',
      accessor: dataItem => formattedDate(dataItem.StartDate),
      Header: intl.formatMessage({ id: 'common.startDate' }),
    },
    {
      id: 'finishDate',
      accessor: dataItem => formattedDate(dataItem.FinishDate),
      Header: intl.formatMessage({ id: 'common.finishDate' }),
    },
    {
      id: 'projectPhase',
      accessor: dataItem => getPhaseName(theme.Project_Phases.item.Items, dataItem.ProjectPhase, locale),
      Header: intl.formatMessage({ id: 'project.details-project-phase' }),
    },
    {
      id: 'budget',
      accessor: dataItem => nFormatter(dataItem.Budget, 1, locale),
      Header: intl.formatMessage({ id: 'pdb.budget' }),
    },
    {
      id: 'spent',
      accessor: dataItem => nFormatter(dataItem.Consumed, 1, locale),
      Header: intl.formatMessage({ id: 'dashboard.spent' }),
    },
    {
      id: 'projectManager',
      accessor: dataItem => dataItem.Manager?.DisplayName,
      Header: intl.formatMessage({ id: 'common.projectManager' }),
    },
    {
      accessor: 'Progress',
      Header: intl.formatMessage({ id: 'dashboard.progress' }),
      Cell: dataItem => (
        <ProgressBar
          asideNumber={`${dataItem.original.Progress}%`}
          firstLineValue={dataItem.original.Progress}
          firstLineColor={getStatusColor(theme, dataItem.original.Status)}
        />
      ),
    },
  ];
  return (
    <div className="projects-table">
      <Table
        columns={columns}
        data={data}
        onRowClick={row => {
          history.push(`/project/${row.original.ProjectUID}`);
        }}
        numOfLoadingRows={3}
        showPagination={false}
        sortable={false}
      />
    </div>
  );
}

export default ProjectTable;
