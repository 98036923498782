import React from 'react';
import { css } from 'emotion';
import Ball from './Ball';

function Wrapper({ size, countBalls, frontColor, backColor, sizeUnit }) {
  const getBalls = () => {
    const balls = [];
    for (let i = 0; i < countBalls; ) {
      balls.push(
        <Ball
          frontColor={frontColor}
          backColor={backColor}
          size={size}
          x={i * (size / 5 + size / 5)}
          y={0}
          key={i.toString()}
          index={i}
          sizeUnit={sizeUnit}
        />,
      );
      i += 1;
    }
    return balls;
  };

  return (
    <div
      className={css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${size}px;
        height: ${size / 5}px;
      `}
    >
      {getBalls()}
    </div>
  );
}

export default Wrapper;
