import React from 'react';

export default () => (
  <div className="entity-card skeleton animated-background">
    <div className="entity-head shadow-background">
      <div className="entity-title shadow-background" />
      <div className="total-project shadow-background" />
      <div className="recoverable shadow-background" />
    </div>
    <div className="entity-body shadow-background">
      <div className="progress-content shadow-background" />
    </div>
    <div className="entity-footer shadow-background">
      <div className="budget entity-footer__box shadow-background">
        <div className="text normal-text shadow-background" />
        <span className="entity-footer__value shadow-background" />
      </div>
      <div className="budget entity-footer__box shadow-background">
        <div className="text normal-text shadow-background" />
        <span className="entity-footer__value shadow-background" />
      </div>
      <div className="budget entity-footer__box shadow-background">
        <div className="text normal-text shadow-background" />
        <span className="entity-footer__value shadow-background" />
      </div>
    </div>
  </div>
);
