import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function Badge({ count, themeColor }) {
  return !count ? null : (
    <span className="notifications-badge" style={{ backgroundColor: themeColor }}>
      {count > 100 ? '+99' : count}
    </span>
  );
}

Badge.propTypes = {
  count: PropTypes.number.isRequired,
  themeColor: PropTypes.string.isRequired,
};

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(Badge);
