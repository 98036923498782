import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getStatusName } from '../../../../Helpers';

const DetailsSection = ({ formData = {}, themeColor, locale, theme }) => {
  const categories = theme.InterdependenceCategory.item.Items;
  const relationships = theme.InterdependenceRelationship.item.Items;
  const statuses = theme.InterdependenceStatus.item.Items;
  return (
    <div className="id-section">
      <div className="id-subTitle">
        <h5>
          <FormattedMessage id="id.details" />
        </h5>
      </div>
      <div className="id-section-content">
        <div className="id-section-row">
          <div className="control preview">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.created-by" />
            </span>
            <div>{formData?.RequestBy?.DisplayName}</div>
          </div>
          <div className="control preview">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.category" />
            </span>
            <div>{getStatusName(categories, formData?.Category?.toString(), locale)}</div>
          </div>
          <div className="control preview">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.owner" />
            </span>
            <div>{formData?.Owner?.DisplayName}</div>
          </div>
          <div className="control preview">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="common.status" />
            </span>
            <div>{getStatusName(statuses, formData?.Active ? 'Active' : 'Closed', locale)}</div>
          </div>
        </div>
        <div className="id-section-row half">
          <div className="control">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="id.log" />
            </span>
            <div>{formData?.LogNumber}</div>
          </div>
          <div className="control">
            <span className="label flex-v-center" style={{ color: themeColor }}>
              <FormattedMessage id="id.relationship" />
            </span>
            <div>{getStatusName(relationships, formData?.Relationship?.toString(), locale)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsSection;
