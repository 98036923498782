import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import ProgramCard from '../EntityCard';

import './styles.scss';

function DashboardInitiativesList({ entities = [], theme, language, history }) {
  return (
    <div className="dashboard-initiatives-list">
      <div className="dashboard-initiatives-list__title">
        <FormattedMessage id="Portfolios.Portfolios" />
      </div>
      <div className="dashboard-initiatives-list__programs">
        {entities.map(item => (
          <ProgramCard key={item.Code} theme={theme} language={language} item={item} history={history} inDashboard />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ theme, language }) => ({
  theme,
  language,
});

export default connect(mapStateToProps)(withRouter(DashboardInitiativesList));
