import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ProcessActionModal from '../ProcessActionModal';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { addToast, mergeAttachments, formattedDate } from '../../Helpers';
import { updatePmoProcesses } from '../../actions/tasksCenter';
import Button from '../Button';
import './style.scss';

const actions = {
  approve: {
    value: 1,
    success: 'common.approve-success',
    error: 'common.approve-error',
  },
  reject: {
    value: 2,
    success: 'common.reject-success',
    error: 'common.reject-error',
  },
};

const initialModal = {
  isOpen: false,
  action: '',
};

function ProcessesPanelHeader({
  theme,
  themeColor,
  title,
  readOnly,
  history,
  currentUser,
  data,
  processType,
  refetchData,
  removeAttachments,
  allDisabled,
  _updatePmoProcesses,
}) {
  const [approveLoader, setApproveLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [sendLoader, setSendLoader] = useState(false);
  const [resubmitLoader, setResubmitLoader] = useState(false);
  const [modal, setModal] = useState(initialModal);
  const disabled = approveLoader || rejectLoader || saveLoader || sendLoader || resubmitLoader;

  function handleAction(value, comment) {
    if (data.ID) {
      const payload = {
        action: {
          RequestID: data.ID,
          ActionValue: value,
          Comment: comment,
        },
      };
      return new ProjectAPIController().processesFormAction(processType.toLowerCase(), payload);
    }
    return Promise.reject(new Error('id is not defined'));
  }

  async function onAction(action, comment) {
    const loader = action === 'approve' ? setApproveLoader : setRejectLoader;
    try {
      loader(true);
      const res = await handleAction(actions[action].value, comment || '');
      if (res.StatusCode.toLowerCase() === 'success') {
        addToast.success(actions[action].success);
        _updatePmoProcesses({ ID: data.ID });
        history.push('/');
      } else {
        throw new Error(res.StatusMessage);
      }
    } catch {
      addToast.error(actions[action].error);
    } finally {
      loader(false);
    }
  }

  function onCancel() {
    history.push('/');
  }

  async function saveDraft() {
    try {
      const _attachments = removeAttachments ? undefined : await mergeAttachments(data.Data.Attachments || []);
      const _data = { ...data.Data };

      _data.ProjectUID = _data.ProjectUID || data.ProjectUID;

      if (_data.Type === 'ProjectClosure' || data.WorkflowItem?.Code === 'ProjectClosure') {
        let _deliverables = [];
        _deliverables = [..._data.Deliverables].map(item => {
          return {
            ID: _data.RequestID ? item.ID : 0,
            FormID: _data.ID || 0,
            DeliverableID: _data.RequestID ? item.DeliverableID : item.ID || 0,
            DeliverableName: item.Title,
            StartDateValue: formattedDate(item.StartDate),
            FinishDateValue: formattedDate(item.FinishDate),
            Progress: item.Progress,
            AssignedTo: item.AssignedTo,
          };
        });

        _data.Deliverables = _deliverables;
      }
      const payload = {
        formData: {
          ..._data,
          Attachments: _attachments,
          RequestID: _data.RequestID || 0,
        },
      };
      return new ProjectAPIController().saveProcess(processType, payload);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async function onSave() {
    try {
      setSaveLoader(true);
      const res = await saveDraft();
      if (res.StatusCode === 'Success') {
        addToast.success('processes.save-success');
        history.push('/');
      } else {
        throw new Error('Save Failed');
      }
    } catch {
      addToast.error('processes.save-error');
    } finally {
      setSaveLoader(false);
    }
  }
  async function onSubmit() {
    try {
      setSendLoader(true);
      const saveRes = await saveDraft();
      if (saveRes.StatusCode === 'Success') {
        const res = await new ProjectAPIController().submitProcess(processType, saveRes.Data);
        if (res.Data) {
          history.push('/');
          addToast.success('processes.submit-success');
        } else {
          throw new Error('Send Failed');
        }
      }
    } catch {
      addToast.error('processes.send-failed');
    } finally {
      setSendLoader(false);
    }
  }

  async function onResubmit() {
    try {
      setResubmitLoader(true);
      const res = await new ProjectAPIController().resubmitProcessRequest(data.ID);
      if (res.StatusCode.toLowerCase() === 'success') {
        refetchData();
        // window.location.reload();
      } else {
        throw new Error(res.StatusMessage);
      }
    } catch (e) {
      addToast.error(e.message);
    } finally {
      setResubmitLoader(false);
    }
  }

  function getUserPermission() {
    const currentStep = data.Steps.find(item => item.StatusID === 2);
    const approvers = currentStep?.Approvers.map(user => user.Approver.Username);
    // const users = data[`Step${data.StepID}`].split(',');

    return approvers.find(item => {
      return item === currentUser;
    });
  }

  const openModal = action => {
    setModal({
      isOpen: true,
      action,
    });
  };

  const closeModal = () => {
    setModal(initialModal);
  };

  const showAcceptRejectButtons = readOnly ? data.StatusID === 2 && getUserPermission() : false;
  const showResubmit = readOnly ? data.StatusID === 1 && currentUser === data.RequestBy.Username : false;

  return (
    <div className="processes-panel-header__title-container">
      <div className="title" style={{ color: themeColor }}>
        <FormattedMessage id={title} />
      </div>
      <div className="buttons-wrapper">
        {readOnly ? (
          <>
            {showAcceptRejectButtons && (
              <>
                <Button
                  className="button"
                  color="white"
                  backgroundColor="#28a745"
                  onClick={() => openModal('approve')}
                  loading={approveLoader}
                  disabled={disabled}
                >
                  <FormattedMessage id="common.approve" />
                </Button>
                <Button
                  className="button"
                  color="white"
                  backgroundColor="#c62828"
                  onClick={() => openModal('reject')}
                  loading={rejectLoader}
                  disabled={disabled}
                >
                  <FormattedMessage id="common.reject" />
                </Button>
              </>
            )}
            {showResubmit ? (
              <Button
                className="button"
                color="white"
                backgroundColor={themeColor}
                onClick={onResubmit}
                loading={resubmitLoader}
                disabled={disabled}
              >
                <FormattedMessage id="common.resubmit" />
              </Button>
            ) : null}
          </>
        ) : (
          <>
            <Button
              className="button"
              color="white"
              backgroundColor={themeColor}
              onClick={onCancel}
              disabled={disabled}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            {processType !== 'risk' && processType !== 'issue' && (
              <Button
                className="button"
                color="white"
                backgroundColor={themeColor}
                onClick={() => onSave()}
                disabled={disabled || allDisabled}
                loading={saveLoader}
              >
                <FormattedMessage id="common.saveAsDraft" />
              </Button>
            )}

            <Button
              className="button"
              color="white"
              backgroundColor={themeColor}
              onClick={onSubmit}
              loading={sendLoader}
              disabled={disabled || allDisabled}
            >
              <FormattedMessage id="common.submit" />
            </Button>
          </>
        )}
        {modal.isOpen ? (
          <ProcessActionModal
            theme={theme}
            isOpen
            closeModal
            toggleModal={closeModal}
            action={modal.action}
            loading={approveLoader || rejectLoader}
            handleApproveReject={(action, comment) => onAction(action, comment)}
          />
        ) : null}
      </div>
    </div>
  );
}

const mapStateToProps = ({ theme, user }) => ({
  theme,
  themeColor: theme.ThemeColor.item.Text,
  currentUser: user.user.Username,
});

const mapDispatchToProps = {
  _updatePmoProcesses: updatePmoProcesses,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withRouter(ProcessesPanelHeader)));
