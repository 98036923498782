import React from 'react';

import './initiativeFilterBar.scss';

// eslint-disable-next-line
class InitiativeFilterBar extends React.Component {
  getStatusIsSelected = value => {
    const { appliedFilters } = this.props;
    return appliedFilters.includes(value);
  };

  getStatusColor = status => {
    const { themeColor, appliedFilters } = this.props;
    if (appliedFilters.length && !appliedFilters.includes(status.Value)) return '#ddd';
    if (!status.Style.startsWith('#')) return themeColor;
    return status.Style;
  };

  render() {
    const { filtersList, onChange, locale, themeColor, counts } = this.props;

    return (
      <div className="initiativeFilterBar__container" style={{ borderLeftColor: themeColor }}>
        {filtersList.map((status, i) => (
          <div
            className="initiativeFilterBar__filter"
            key={`filter${i + 1}`}
            onClick={() => onChange(status)}
            style={{
              borderBottomWidth: '0',
            }}
            tabIndex={0}
            role="button"
          >
            <div
              style={{ backgroundColor: this.getStatusColor(status) }}
              className="initiativeFilterBar__filter-color-prev"
            />
            <span>{locale === 'en' ? status.English : status.Arabic}</span>
            <span className="initiativeFilterBar__filter-count" style={{ color: this.getStatusColor(status) }}>
              {counts[status.Value]}
            </span>
          </div>
        ))}
      </div>
    );
  }
}

export default InitiativeFilterBar;
