import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ProcessesFormLayout from '../../../components/ProcessesFormLayout';
import DeliverablesAcceptanceForm from '../../../components/DeliverablesAcceptanceForm';
import ProcessesLoader from '../../../components/ProcessesLoader';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import { addToast } from '../../../Helpers';

function DeliverablesAcceptance({ readOnly, editData = {}, project = {}, processType, refetchData }) {
  const initialState = {
    Data: {
      ID: 0,
      Type: 'DeliverablesAcceptance',
      Deliverables: [],
      ProjectUID: project.ProjectUID,
      TitleArabic: '',
      TitleEnglish: '',
    },
  };

  const [state, setState] = useState({ ...initialState, ...editData });
  const [loading, setLoading] = useState(true);

  function saveData(data) {
    setState({ ...state, Data: { ...state.Data, ...data } });
  }

  async function getProjectDeliverables() {
    try {
      const res = await new ProjectAPIController().getProjectDeliverables(project.CustomFields.ProjectUID);

      if (res.Data) {
        setLoading(false);
        if (editData?.Data) {
          const filterData = res.Data.map(item => {
            const findData = editData.Data.Deliverables.find(data => {
              return item.ID === data.DeliverableID;
            });
            return {
              ...findData,
            };
          });
          saveData({ Deliverables: filterData });
        } else {
          const arrangeData = res.Data.map(item => {
            return {
              ...item,
              DeliverableID: item?.ID,
              IsSelected: item?.IsSelected,
              FinishDateValue: moment(item.FinishDate)
                .locale('en')
                .format('DD-MM-YYYY'),
              StartDateValue: moment(item.StartDate)
                .locale('en')
                .format('DD-MM-YYYY'),
              DeliverableName: item.Title,
            };
          });

          saveData({ Deliverables: arrangeData });
        }
      }
    } catch (err) {
      addToast.error('processes.deliverables-fetch-failed');
    }
  }

  useEffect(() => {
    getProjectDeliverables();
  }, [project.ProjectUID]);

  function validate() {
    const { TitleEnglish, TitleArabic, Deliverables } = state.Data;

    return !(
      Boolean(TitleEnglish?.length) &&
      Boolean(TitleArabic?.length) &&
      Deliverables.find(item => item.IsSelected)
    );
  }

  return loading ? (
    <ProcessesLoader />
  ) : (
    <ProcessesFormLayout
      title="processes.deliverables-acceptance"
      data={state}
      processType={processType}
      readOnly={readOnly}
      refetchData={refetchData}
      disabled={validate()}
      // To Remove Attachments from request payload when save/submit
      removeAttachments
    >
      <DeliverablesAcceptanceForm
        project={project}
        processType={processType}
        data={state.Data}
        saveData={saveData}
        readOnly={readOnly}
      />
    </ProcessesFormLayout>
  );
}

export default DeliverablesAcceptance;
