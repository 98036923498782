import React, { Component } from 'react';
import Button from '../Button';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';
import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import TaskLoader from './TaskLoader';
import ProjectTaskRow from './ProjectTaskRow';

import './style.scss';

import { ProjectTaskList, renderMultiple, addToast } from '../../Helpers';

class ProjectTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasksList: [],
      loading: true,
      updated: false,
      isSubmitting: false,
      index: '',
    };
  }

  componentDidMount() {
    this.getAllTasks();
  }

  getAllTasks() {
    const { match } = this.props;

    new ProjectAPIController()
      .getProjectTasks(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({ tasksList: ProjectTaskList(res.Data), loading: false });
        }
      })
      .catch(error => {
        addToast.error(error.message, { toastId: 'get-project-tasks' });
      });
  }

  handleSave = () => {
    const { tasksList, index } = this.state;
    const filtertasksList = tasksList.filter(task => task.isUpdated);
    tasksList[index].StatusID = 2;
    this.setState({
      tasksList,
      isSubmitting: true,
    });
    new ProjectAPIController()
      .updateProjectsTasks({ data: filtertasksList })
      .then(res => {
        if (res.StatusCode === 'Success') {
          addToast.success('common.changesHaveBeenSaved');
          this.setState({
            updated: false,
            isSubmitting: false,
          });
        }
      })
      .catch(error => {
        addToast.error(error.message, { toastId: 'add-project-task' });
      });
  };

  updateStatus = i => {
    const { tasksList } = this.state;
    tasksList[i].StatusID = 2;
    this.setState({
      tasksList,
    });
  };

  handleUpdateSlider(PercentComplete, index, attachments) {
    const { tasksList } = this.state;
    tasksList[index].PercentComplete = PercentComplete;
    tasksList[index].isUpdated = true;

    if (attachments) {
      tasksList[index].Attachments = attachments;
    }

    tasksList[index].IsCompleted = tasksList[index].PercentComplete === 100;
    this.setState({
      tasksList,
      index,
      updated: true,
    });
  }

  handleCheck(index) {
    const { tasksList } = this.state;
    tasksList[index].IsCompleted = !tasksList[index].IsCompleted;
    tasksList[index].PercentComplete = tasksList[index].IsCompleted ? 100 : tasksList[index].PercentComplete;
    tasksList[index].isUpdated = true;
    this.setState({
      tasksList,
      updated: true,
    });
  }

  render() {
    const { theme, match } = this.props;
    const { tasksList, loading, updated, isSubmitting } = this.state;

    return (
      <section className={`project-tasks-container ${isSubmitting ? 'loading' : ''}`}>
        <div className="sec-header-container tab-header">
          <div className="title bold-text">
            <FormattedMessage id="project-tasks.title" />
          </div>
          <div className="section-actions">
            <Button
              type="submit"
              disabled={!updated || isSubmitting || loading}
              loading={isSubmitting}
              backgroundColor={theme.ThemeColor.item.Text}
              onClick={() => this.handleSave()}
              Icon={() => <CheckIcon className="action-icon" width="11px" height="11px" fill="#fff" />}
            >
              <FormattedMessage id="project-tasks.save-button-label" />
            </Button>
          </div>
        </div>
        <div className="main-content tTab-content-container">
          <div className="list-container">
            {loading ? renderMultiple(key => <TaskLoader key={key} />, 4) : null}
            {tasksList.map((item, index) => (
              <ProjectTaskRow
                key={index}
                item={item}
                index={index}
                match={match}
                handleUpdateSlider={(e, i) => this.handleUpdateSlider(e, i)}
                handleCheck={i => this.handleCheck(i)}
                updateData={(percentage, attachment, i) => this.handleUpdateSlider(percentage, i, attachment)}
                updateStatus={() => this.updateStatus(index)}
              />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ theme, language }) => ({
  locale: language.locale,
  tasksClassifications: theme.TasksClassifications.item.Items,
  taskApprovalStatuses: theme.task_approval_statuses.item.Items,
  nonProjectTaskApprovalStatuses: theme.non_project_task_approval_statuses.item.Items,
  theme,
});

export default connect(mapStateToProps, null)(ProjectTasks);
