import UrlAPIControllers, { projectService } from '../config/urlServiceConfig';
import HttpServiceConfig from '../config/httpServiceConfig';

export default class ProgramsAPIController {
  getAllPrograms = code => {
    const FETCH_URL = `${new UrlAPIControllers().programsURL()}${code ? `?code=${code}` : ''}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProgramByCode = code => {
    const FETCH_URL = `${new UrlAPIControllers().programDetailsURL()}/${code}`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getProgramMilestones = code => {
    const FETCH_URL = `${projectService}/${code}/Milestones`;
    return new HttpServiceConfig().get(FETCH_URL);
  };

  addMilestone = body => {
    const FETCH_URL = new UrlAPIControllers().addMilestoneURL();
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  deleteMilestone = (code, id) => {
    const FETCH_URL = new UrlAPIControllers().deleteMilestoneUrl(code, id);
    return new HttpServiceConfig().post(FETCH_URL);
  };

  getAllDeliverables = code => {
    const FETCH_URL = new UrlAPIControllers().deliverablesURL(code);
    return new HttpServiceConfig().get(FETCH_URL);
  };

  getDeliverableById = (code, id) => {
    const FETCH_URL = new UrlAPIControllers().deliverableByIdURL(code, id);
    return new HttpServiceConfig().get(FETCH_URL);
  };

  postDeliverable = body => {
    const FETCH_URL = new UrlAPIControllers().postDeliverableURL();
    return new HttpServiceConfig().post(FETCH_URL, body);
  };

  deleteDeliverable = (code, id) => {
    const FETCH_URL = new UrlAPIControllers().deleteDeliverableURL(code, id);
    return new HttpServiceConfig().post(FETCH_URL);
  };
}
