import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import Header from './Header';
import Item from './Item';

import './style.scss';

function Impacts({ intl, themeColor, saveData, data, readOnly }) {
  const impacts = [
    {
      id: 1,
      title: intl.formatMessage({ id: 'processes.change-request-scope' }),
      data: data.ScopeImpact,
      dataKey: 'ScopeImpact',
    },
    {
      id: 2,
      title: intl.formatMessage({ id: 'processes.change-request-duration' }),
      data: data.DurationImpact,
      dataKey: 'DurationImpact',
    },
    {
      id: 3,
      title: intl.formatMessage({ id: 'processes.change-request-cost' }),
      data: data.CostImpact,
      dataKey: 'CostImpact',
    },
    {
      id: 4,
      title: intl.formatMessage({ id: 'common.other' }),
      data: data.OtherImpact,
      dataKey: 'OtherImpact',
    },
  ];

  function onSave(key, value) {
    saveData({ [key]: value });
  }

  return (
    <div className="change-form-request-impacts">
      <span className="title" style={{ color: themeColor }}>
        <FormattedMessage id="processes.change-request-impact" />
      </span>
      <Header />
      <div className="">
        {impacts.map(impact => (
          <Item key={impact.id} {...impact} onSave={onSave} readOnly={readOnly} />
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps)(Impacts));
