import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { getPMOProcessors, postTaskStatus } from '../../../../actions/tasksCenter';
import './styles.scss';
import ProcessesTable from '../../../ProcessesTable';

class PMOProcesses extends React.Component {
  componentDidMount() {
    this.getPMOProcessors();
  }

  getPMOProcessors = () => {
    const { _getPMOProcessors, pmoProcessors } = this.props;
    if (!pmoProcessors.length) {
      _getPMOProcessors();
    }
  };

  getColumns = () => {
    const { intl } = this.props;
    return [
      {
        accessor: 'RequestTitle', // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.title' }),
        width: 200,
      },
      {
        accessor: 'RequestTypeText', // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.request-type' }),
        width: 200,
      },
      {
        accessor: data => moment(data.CreatedOn).format('DD-MMM-YYYY'),
        Header: intl.formatMessage({ id: 'common.creation-date' }),
        id: 'creation-date',
      },
      {
        accessor: data => data.CreatedByModel.DisplayName,
        Header: intl.formatMessage({ id: 'common.created-by' }),
        id: 'created-by',
      },
    ];
  };

  render() {
    const { pmoProcessors, loaders, intl } = this.props;

    return (
      <div className="projectsTasks__container">
        <ProcessesTable
          title={intl.formatMessage({ id: 'taskCenter.PMO-processes' })}
          loading={loaders.pmoProcessors}
          data={pmoProcessors}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  _getPMOProcessors: getPMOProcessors,
  _postTaskStatus: postTaskStatus,
};

const mapStateToProps = ({ tasksCenter, theme }) => ({
  pmoProcessors: tasksCenter.approvals.pmoProcessors,
  loaders: tasksCenter.loaders,
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PMOProcesses));
