import React from 'react';
import { css, keyframes } from 'emotion';

function Ball({ size, x, y, frontColor, index, backColor }) {
  const impulse = keyframes`
    0% {
        background: ${backColor};
        transform: scale(1);
        animation-timing-function: cubic-bezier(1,0,0.7,1);
    }
    40% {
        background: ${frontColor};
        transform: scale(1.5);
        animation-timing-function: cubic-bezier(0.3,0,0,1);
    }
    72.5% {
        background:${backColor};
        transform: scale(1);
        animation-timing-function: linear;
    }
    100% {
        background: ${backColor};
        transform: scale(1);
    }
`;

  return (
    <div
      className={css`
        position: absolute;
        top: ${y}px;
        left: ${x}px;
        width: ${size / 5}px;
        height: ${size / 5}px;
        border-radius: 50%;
        animation: ${impulse} 1.25s linear infinite;
        animation-delay: ${index * 0.125}s;
      `}
    />
  );
}

export default Ball;
