import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Field from '../Field';
import Modal from '../Modal';
import Button from '../Button';
import './style.scss';

class RejectModal extends React.Component {
  state = {
    comment: '',
  };

  render() {
    const { comment } = this.state;
    const { intl, loading, toggleModal, handleApproveReject, action } = this.props;

    return (
      <Modal title={intl.formatMessage({ id: 'common.rejectModal' })} isOpen onClose={() => toggleModal()}>
        <div className="add-public-modal">
          <div className="text-container">
            <span className="label flex-v-center ">
              <FormattedMessage id="common.comment" />
            </span>
          </div>
          <Field
            control={{
              type: 'textarea',
              onChangeAction: e => this.setState({ comment: e.target.value }),
              placeholder: intl.formatMessage({
                id: 'common.comment',
              }),
              value: comment,
              validateOnExite: true,
              error: !comment.length && action === 'reject',
            }}
          />

          <div className="button-container">
            <Button color="#8a8a8b" border backgroundColor="white" onClick={() => toggleModal()}>
              <FormattedMessage id="common.cancel" />
            </Button>

            <div className="cancel-reject-modal">
              <Button
                color="white"
                backgroundColor={action === 'reject' ? '#c62828' : '#28a745'}
                onClick={() => handleApproveReject(action, comment)}
                loading={loading}
                disabled={!comment.length && action === 'reject'}
              >
                <FormattedMessage id={action === 'reject' ? 'common.reject' : 'common.approve'} />
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps, null)(RejectModal));
