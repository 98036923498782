import React from 'react';
import Linkify from 'react-linkify';

const TextMessage = props => {
  const { theme, data, author } = props;
  return (
    <div
      className="sc-message--text"
      style={{
        backgroundColor: `${author === 'me' ? theme.ThemeColor.item.Text : '#f4f7f9'}`,
        borderColor: theme.ThemeColor.item.Text,
      }}
    >
      <Linkify properties={{ target: '_blank' }}>{data.text}</Linkify>
    </div>
  );
};

export default TextMessage;
