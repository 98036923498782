import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Modal from '../Modal';
import RiskDetails from '../ProjectRisks/ProjectRisksDetails';
import IssueDetails from '../ProjectIssues/ProjectIssuesDetails';

import './styles.scss';

// eslint-disable-next-line
const RiskIssueModal = props => {
  const { isOpen, onClose, type, item, theme, locale, intl } = props;
  const isRisk = type === 'risk';

  if (!isOpen) return null;

  const stopProp = e => {
    e.stopPropagation();
  };

  return (
    <div onClick={stopProp} className="riskIssueModal">
      <Modal title={intl.formatMessage({ id: 'common.details' })} isOpen={isOpen} onClose={onClose}>
        {isRisk ? (
          <RiskDetails theme={theme} locale={locale} details={item} modalView wrapperClassname="riskIssueModal" />
        ) : (
          <IssueDetails theme={theme} locale={locale} details={item} modalView wrapperClassname="riskIssueModal" />
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ theme, language }) => ({
  theme,
  locale: language.locale,
});

export default injectIntl(connect(mapStateToProps)(RiskIssueModal));
