import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import StepIcon from './StepIcon';
import './style.scss';

const Steps = ({ steps, locale, currentStep, isClosed }) => {
  return (
    <div className="vertical-steps">
      <ul>
        {steps.map(step => (
          <>
            <li className="d-flex" key={`Step-${step.ID}`}>
              <StepIcon step={step} />
              <div className="title">
                <div>{locale === 'ar' ? step.StepNameLang : step.StepName}</div>
                {currentStep === step.SortOrder && step.DueDate && !isClosed ? (
                  <div className="date">{moment(step.DueDate).fromNow()}</div>
                ) : null}
              </div>
            </li>
            <li>
              <div className="node-line grey" />
            </li>
          </>
        ))}
      </ul>
    </div>
  );
};

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  locale: PropTypes.string.isRequired,
};

export default Steps;
