import React from 'react';
import './style.scss';

function Footer(props) {
  const { theme } = props;
  return (
    <div className="footer-container">
      <div className="details">
        {new Date().getFullYear()} ©{' '}
        {window.env.COPYRIGHT || (
          <>
            <span style={{ color: theme.ThemeColor.item.Text }}>
              <b>Project Plus</b>
            </span>{' '}
            by Master works
          </>
        )}
      </div>
    </div>
  );
}

export default Footer;
