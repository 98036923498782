import React from 'react';
import { numberWithCommas, formattedDate } from '../../../Helpers';
import './style.scss';

function Text(props) {
  const { data, locale, isCurrency, isDate } = props;
  if (isCurrency) {
    data.itemData = numberWithCommas(data.itemData);
  } else if (isDate) {
    data.itemData = formattedDate(data.itemData);
  }

  return (
    <div className="text-info-item">
      <div className="text-info-item__title bold-text">{locale === 'ar' ? data.Arabic : data.English}</div>
      <span className="text-info-item__value text-gray">{data.itemData ? data.itemData : '---'}</span>
    </div>
  );
}

export default Text;
