import React from 'react';

import './style.scss';
import StepIcon from './StepIcon';
import { getTitleName } from '../../Helpers';

const PhasesSteps = props => {
  const { phases, locale } = props;
  return (
    <div
      className="phases-steps-container"
      style={{
        borderColor: 'gray',
      }}
    >
      <div className="phases-steps">
        {phases.map((step, index) => (
          <div className="icon-with-line-container" key={index}>
            <div className="icon-with-line">
              {step.Order !== 1 && <div className="phases-line" />}
              <div className="text-with-icon-container">
                <div className="text-with-icon">
                  <StepIcon step={step} />
                </div>
                <div className="status-container">
                  <p className="status">{getTitleName(step, locale)}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhasesSteps;
