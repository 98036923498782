import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '../../Button';
import TitledContent from '../../TitledContent';
import AttachmentsList from '../../AttachmentsList';

import { formattedDate } from '../../../Helpers';

import './styles.scss';

class Details extends React.Component {
  state = {};

  render() {
    const {
      props: { themeColor, changeView, milestones, match },
    } = this;
    const milestone = milestones.find(item => item.ID.toString() === match.params.id);
    if (!milestone) {
      changeView('base');
      return null;
    }
    const {
      ID,
      Title,
      PlannedFinishDate,
      ActualFinishDate,
      Attachments,
      CompletedWorkPercentage,
      CompletedWorkWeight,
    } = milestone;

    return (
      <div className="milestone__container">
        <div className="milestone__content">
          <div className="milestone__header">
            <div className="milestone__title">
              <FormattedMessage id="projectdata.milestones" />
            </div>
            <div className="milestone__actions">
              <Button
                className="action secondary-action"
                type="submit"
                onClick={() => changeView('base')}
                color={themeColor}
                border
              >
                <FormattedMessage id="common.back" />
              </Button>
              <Button
                className="action"
                type="submit"
                onClick={() => changeView('edit', ID)}
                backgroundColor={themeColor}
              >
                <FormattedMessage id="common.edit" />
              </Button>
            </div>
          </div>
          <div className="milestone__details">
            <div className="milestone__row milestone__details-title">
              <h2>{Title}</h2>
            </div>
            <div className="deliverable__details-body">
              <div className="milestone__row">
                <TitledContent title={<FormattedMessage id="deliverables.planned-finish-date" />}>
                  {formattedDate(PlannedFinishDate)}
                </TitledContent>
                <TitledContent title={<FormattedMessage id="milestones.completed-work" />}>
                  {CompletedWorkPercentage || '---'}
                </TitledContent>
                <TitledContent title={<FormattedMessage id="milestones.weighted-completed-work" />}>
                  {CompletedWorkWeight}
                </TitledContent>
              </div>
              <div className="milestone__row">
                <TitledContent title={<FormattedMessage id="deliverables.actual-finish-date" />}>
                  {formattedDate(ActualFinishDate)}
                </TitledContent>
              </div>
            </div>
            <div className="milestone__row">
              <TitledContent title={<FormattedMessage id="common.support-documents" />}>
                {Attachments && Attachments.length ? <AttachmentsList attachments={Attachments} /> : null}
              </TitledContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default connect(mapStateToProps)(Details);
