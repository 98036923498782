import React, { Component } from 'react';
import moment from 'moment';
import TextMessage from './TextMessage';
import EmojiMessage from './EmojiMessage';
import FileMessage from './FileMessage';
import ImageMessage from './ImageMessage';
import VoiceMessage from './VoiceMessage';
import Avatar from '../../../Avatar';

class Message extends Component {
  renderMessageOfType = type => {
    const { theme } = this.props;
    const { message } = this.props;
    switch (type) {
      case 'text':
        return <TextMessage {...message} theme={theme} />;
      case 'emoji':
        return <EmojiMessage {...message} theme={theme} />;
      case 'file':
        return <FileMessage {...message} theme={theme} />;
      case 'voice':
        return <VoiceMessage {...message} theme={theme} />;
      case 'image':
        return <ImageMessage {...message} theme={theme} />;
      default:
        return <TextMessage theme={theme} data={{ text: 'Unsupported file' }} />;
    }
  };

  render() {
    const { message, language } = this.props;
    const contentClassList = ['sc-message--content', message.author === 'me' ? 'sent' : 'received'];
    return (
      <div className="sc-message">
        <div className="sc-message--before">
          {message.author === 'them' && (
            <div className="sc-message--avatar">
              <Avatar username={message.senderID} />
            </div>
          )}
          <div
            className={contentClassList.join(' ')}
            style={{
              flexDirection: `${language.locale === 'en' ? 'row' : 'row-reverse'}`,
            }}
          >
            {this.renderMessageOfType(message.type)}
          </div>
        </div>

        <span
          style={{
            justifyContent: `${language.locale === 'en' ? 'flex-end' : 'flex-start'}`,
          }}
          className={`sc-message--time ${contentClassList.join(' ')}`}
        >
          {' '}
          {moment(message.creationDate).fromNow()}
        </span>
      </div>
    );
  }
}

export default Message;
