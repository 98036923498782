import React from 'react';
import Loader from '../../../components/InitiativeDetails/InitiativeInfo/Loader';
import BarChartLoader from '../../../components/BarChartLoader/BarChartLoader';

function Loading() {
  return (
    <>
      <div className="FundingProcurementDashboard-loading">
        <BarChartLoader />
        <BarChartLoader />
      </div>
      <div className="mt-top">
        <Loader />
      </div>
    </>
  );
}

export default Loading;
