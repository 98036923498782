import ServerConfig from './ServerConfig';
import messages from '../../Messaging';

export default class HttpServiceConfig {
  get = apiUrl =>
    new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        headers: new ServerConfig().getHeader(),
      })
        .then(response => {
          if (response.status === 401) {
            localStorage.clear();
            localStorage.removeItem('persist:root');
            window.location.reload();
          }
          return response.json();
        })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });

  put = (apiUrl, body) =>
    new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'PUT',
        headers: new ServerConfig().getHeader(),
        body: JSON.stringify(body),
      })
        .then(response => response.json())
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });

  post = (apiUrl, body) =>
    new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'POST',
        headers: new ServerConfig().getHeader(),
        body: JSON.stringify(body),
      })
        .then(response => response.json())
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });

  post2 = (apiUrl, body) => {
    return new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'POST',
        headers: new ServerConfig().getHeader(),
        body: JSON.stringify(body),
      })
        .then(response => {
          if (response.status === 200) {
            resolve(response.json());
          } else if (response.status === 401) {
            reject({ message: messages.ErrorMessage });
          }
        })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  delete = apiUrl =>
    new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'DELETE',
        headers: new ServerConfig().getHeader(),
      })
        .then(response => response.json())
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });

  attach = (apiUrl, name) =>
    new Promise((resolve, reject) => {
      fetch(apiUrl, {
        method: 'GET',
        headers: new ServerConfig().getHeader(),
        cache: 'force-cache',
      })
        .then(response => response.blob())
        .then(result => {
          resolve(URL.createObjectURL(new File([result], name)));
        })
        .catch(err => {
          reject(err);
        });
    });
}
