import React, { Component } from 'react';

import Navigator from '../Navigator';
import ProjectUpdateProgressDetails from './ProjectUpdateProgressDetails';
import ProjectUpdateProgressHistory from './ProjectUpdateProgressHistory';
import ProjectUpdateProgressEditMode from './ProjectUpdateProgressEdit';

import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { addToast } from '../../Helpers';
import routes from '../../routes';

class ProjectUpdateProgressNavigator extends Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
    this.state = {
      selectedComponentName: 'main',
      updateProgressList: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getAllProgress();
  }

  getAllProgress() {
    const { match } = this.props;
    this.setState({ loading: true });
    new ProjectAPIController()
      .getProgressUpdate(match.params.code)
      .then(res => {
        if (res.StatusCode === 'Success') {
          this.setState({ updateProgressList: res.Data.reverse(), loading: false });
        }
      })
      .catch(error => {
        addToast.error(error.message, {
          toastId: 'get-progress-error',
        });
      });
  }

  handleProgressSave = () => {
    this.getAllProgress();
    this.changeView('base');
  };

  changeView(view, id) {
    const { history, match } = this.props;
    history.push(routes[view](match.url, id));
  }

  render() {
    const { theme, locale, match } = this.props;
    const { selectedComponentName, loading, updateProgressList } = this.state;

    const componentsList = [
      {
        id: 'details',
        name: 'details',
        component: ProjectUpdateProgressDetails,
        props: { theme, locale, update: updateProgressList[0], changeView: this.changeView },
      },

      {
        id: 'add',
        name: 'edit',
        component: ProjectUpdateProgressEditMode,
        props: {
          theme,
          locale,
          onProgressSave: this.handleProgressSave,
          changeView: this.changeView,
          updateProgressList,
        },
      },
      {
        id: 'base',
        name: 'main',
        component: ProjectUpdateProgressHistory,
        props: { theme, locale, updateProgressList, changeView: this.changeView },
      },
    ];
    if (loading) {
      return (
        <ProjectUpdateProgressHistory
          loading={loading}
          update={{}}
          updateProgressList={updateProgressList}
          changeView={this.changeView}
          theme={theme}
          locale={locale}
        />
      );
    }
    return (
      <>
        <Navigator match={match} componentsList={componentsList} selectedComponentName={selectedComponentName} />
      </>
    );
  }
}

export default ProjectUpdateProgressNavigator;
