import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import ProjectAPIController from '../../providers/controllers/ProjectAPIController';
import { createMarkup } from '../../Helpers';

function AchievementReport({ projectCode }) {
  const [progress, setProgress] = useState({});

  async function getProgress() {
    try {
      const res = await new ProjectAPIController().getProgressUpdate(projectCode);

      if (Array.isArray(res.Data) && res.Data[0]) {
        setProgress(res.Data[0]);
      }
    } catch (err) {
      console.log({ err });
    }
  }

  useEffect(() => {
    getProgress();
  }, [projectCode]);

  return (
    <div className="dashboard-tabs-section__achievement-report">
      <div className="dashboard-tabs-card">
        <div className="content-container achievement-content">
          <div className="section">
            <span className="title">
              <FormattedMessage id="dashboard.achieved-works" />
            </span>
            <span className="content" dangerouslySetInnerHTML={createMarkup(progress.ProgressActivity)} />
          </div>
          <div className="section">
            <span className="title">
              <FormattedMessage id="dashboard.expected-achieved-works" />
            </span>
            <span className="content" dangerouslySetInnerHTML={createMarkup(progress.PlannedActivity)} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AchievementReport;
