import React, { useRef } from 'react';
import { ReactComponent as CloseIcon } from '../assets/close-icon.svg';
import { ReactComponent as ChatIcon } from '../assets/chat-icon.svg';

function Header(props) {
  const { teamName, onClose, theme } = props;
  const svg = useRef(null);
  return (
    <div className="sc-header">
      <div className="sc-header--team-name">
        <ChatIcon style={{ backgroundColor: theme.ThemeColor.item.Text }} ref={svg} className="sc-header--img" />{' '}
        {teamName}
        <button className="sc-header--close-button" onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

export default Header;
