import { combineReducers } from 'redux';

import user from './user';
import language from './language';
import theme from './theme';
import docsLibrary from './docsLibrary';
import tasksCenter from './tasksCenter';
import project from './project';
import dashboard from './dashboard';
import deliverables from './deliverables';

export default combineReducers({
  user,
  theme,
  language,
  docsLibrary,
  tasksCenter,
  dashboard,
  project,
  deliverables,
});
