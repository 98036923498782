import React from 'react';
import { connect } from 'react-redux';
import NewProjectsPanel from '../../NewProjectsPanel';

import { getNewProjectsRequests, postTaskStatus } from '../../../actions/tasksCenter';

import './styles.scss';

class NewProjectsRequests extends React.Component {
  componentDidMount() {
    this.getNewProjectsRequests();
  }

  getNewProjectsRequests = () => {
    const { _getNewProjectsRequests } = this.props;
    _getNewProjectsRequests();
  };

  render() {
    const { projectsTasks, loaders, tasksClassifications, taskApprovalStatuses } = this.props;

    return (
      <div className="projectsTasks__container">
        <NewProjectsPanel
          projects={projectsTasks}
          isLoading={loaders.newProjectsRequests}
          tasksClassifications={tasksClassifications}
          taskApprovalStatuses={taskApprovalStatuses}
          requests
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  _getNewProjectsRequests: getNewProjectsRequests,
  _postTaskStatus: postTaskStatus,
};

const mapStateToProps = ({ tasksCenter, theme }) => ({
  projectsTasks: tasksCenter.newProjectsRequests,
  loaders: tasksCenter.loaders,
  tasksClassifications: theme.TasksClassifications.item.Items,
  taskApprovalStatuses: theme.task_approval_statuses.item.Items,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectsRequests);
