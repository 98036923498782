import React, { Component } from 'react';

import './style.scss';
import DatePicker from 'react-date-picker';

class DateField extends Component {
  state = {
    error: null,
  };

  static getDerivedStateFromProps(props, state) {
    const { error } = state;
    const { settings } = props;
    if (settings.error !== error) {
      return {
        ...state,
        error: settings.error,
      };
    }
    return state;
  }

  defaultValidation = value => {
    return !value;
  };

  showError(show) {
    this.setState({ error: show });
  }

  render() {
    const {
      props: {
        settings: { value, onChangeAction, locale, validation, validateOnExite, disabled, noIcons },
      },
      state: { error },
    } = this;
    const _value = (value && new Date(value)) || null;
    const _validation = validation || this.defaultValidation;
    const _icons = noIcons ? { calendarIcon: null, clearIcon: null } : {};

    return (
      <div className="date-container">
        <DatePicker
          locale={locale === 'ar' ? 'ar' : 'en'}
          className={`input-control ${error ? 'error' : ''} ${noIcons ? 'no-icons' : ''} ${
            locale === 'ar' ? 'dir-ltr' : ''
          }`}
          onChange={e => onChangeAction(e)}
          value={_value}
          format="dd-MM-yyyy"
          disabled={disabled}
          onBlur={validateOnExite ? () => this.showError(_validation(value)) : null}
          {..._icons}
        />
      </div>
    );
  }
}

export default DateField;
