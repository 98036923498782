import React from 'react';

const ImageMessage = props => {
  const { data } = props;
  return (
    <div className="sc-message--audio">
      <audio controls>
        <source src={data.url} />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default ImageMessage;
