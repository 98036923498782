import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import ProjectListItem from './ProjectListItem';
import PhaseCheckListModal from '../PhaseCheckListModal';

class ProjectsList extends Component {
  state = {
    selectedProject: null,
    showSelectedProjectModal: false,
  };

  selectProjecteHandler(_initiative, projectUID) {
    this.setState({
      selectedProject: _initiative.Projects.find(item => item.ProjectUID === projectUID),
      showSelectedProjectModal: true,
    });
  }

  closeProjectModalHandler() {
    this.setState({ selectedProject: null, showSelectedProjectModal: false });
  }

  render() {
    const { theme, language, initiativesList } = this.props;
    const { showSelectedProjectModal, selectedProject } = this.state;
    return (
      <div className="initiatives-list-container">
        <table className="initiatives-list-table">
          <thead>
            <tr>
              <th style={{ borderBottomColor: theme.ThemeColor.item.Text, width: '25%' }}>
                <FormattedMessage id="common.project-name" />
              </th>
              <th style={{ borderBottomColor: theme.ThemeColor.item.Text }}>
                <FormattedMessage id="common.projectManager" />
              </th>
              {theme.ProjectPhases.item.Items.map(element => (
                <th style={{ borderBottomColor: theme.ThemeColor.item.Text }} key={element.Id}>
                  {language.locale === 'ar' ? element.Arabic : element.English}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {initiativesList
              ? initiativesList.map(item => (
                  <ProjectListItem
                    key={item.ProjectUID}
                    initiativeItem={item}
                    selectProjecteHandler={(initiative, projectUID) =>
                      this.selectProjecteHandler(initiative, projectUID)
                    }
                  />
                ))
              : null}
          </tbody>
        </table>
        {showSelectedProjectModal ? (
          <PhaseCheckListModal
            isOpen={showSelectedProjectModal}
            data={selectedProject}
            onClose={() => {
              this.closeProjectModalHandler();
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ theme, language }) => {
  return {
    theme,
    language,
  };
};

export default connect(mapStateToProps)(ProjectsList);
