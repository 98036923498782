import React from 'react';
import { FormattedMessage } from 'react-intl';

import './PhasesGateReviewLoader.scss';

export default () => (
  <div className="PhasesGateReviewLoader__container animated-background">
    <div className="PhasesGateReviewLoader__header flex-h-v-center">
      <FormattedMessage id="project.details-preview" />
    </div>

    <div className="PhasesGateReviewLoader__content">
      <div className="PhasesGateReviewLoader__content-ownerPic flex-h-v-center">
        <div className="shadow-background" />
      </div>
      <div className="PhasesGateReviewLoader__content-details">
        <div className="PhasesGateReviewLoader__content-ownerPic flex-h-v-center">
          <div className="shadow-background" />
        </div>
      </div>
      <div className="PhasesGateReviewLoader__content-details">
        <div className="PhasesGateReviewLoader__content-ownerPic flex-h-v-center">
          <div className="shadow-background" />
        </div>
      </div>
      <div className="PhasesGateReviewLoader__content-details">
        <div className="PhasesGateReviewLoader__content-ownerPic flex-h-v-center">
          <div className="shadow-background" />
        </div>
      </div>
    </div>

    <div className="PhasesGateReviewLoader__footer">
      <div className="PhasesGateReviewLoader__footer-row">
        <div className="PhasesGateReviewLoader__footer-item">
          <p className="PhasesGateReviewLoader__footer-item-title shadow-background" />
        </div>
        <div className="PhasesGateReviewLoader__footer-itemEnd">
          <p className="PhasesGateReviewLoader__footer-itemEnd-title shadow-background" />
        </div>
      </div>
      <div className="PhasesGateReviewLoader__footer-row">
        <div className="PhasesGateReviewLoader__footer-item">
          <p className="PhasesGateReviewLoader__footer-item-desc shadow-background" />
        </div>
      </div>
      <div className="PhasesGateReviewLoader__footer-row">
        <div className="PhasesGateReviewLoader__footer-item">
          <p className="PhasesGateReviewLoader__footer-item-desc shadow-background" />
        </div>
      </div>
      <div className="PhasesGateReviewLoader__footer-row">
        <div className="PhasesGateReviewLoader__footer-item">
          <p className="PhasesGateReviewLoader__footer-item-desc shadow-background" />
        </div>
      </div>
    </div>
  </div>
);
