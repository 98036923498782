import React from 'react';
import Modal from '../Modal';
import {
  getClassificationName,
  getClassification,
  getClassificationColor,
  getIssueClassification,
  getImpactString,
  getProbabiltyString,
  // formattedDate,
} from '../../Helpers';
import Table from '../Table';
import Chip from '../Chip';
import './style.scss';

function RisksIssuesPopUp({ data, intl, locale, theme, onClose, type }) {
  const classification =
    type === 'risks' ? theme.RisksClassifications.item.Items : theme.IssuesClassifications.item.Items;

  const getType = item => {
    if (item.Program) {
      return 'Program';
    }
    if (item.Portfolio) {
      return 'Portfolio';
    }
    return 'Project';
  };
  const getName = (item, leveltype) => {
    return locale === 'ar'
      ? item[leveltype]?.TitleArabic || item[leveltype]?.NameArabic || ''
      : item[leveltype]?.Title || item[leveltype]?.NameEnglish || '';
  };

  const getRisksColumns = () => {
    return [
      {
        accessor: dataItem => (
          <div className="risks-issues-pop-up-title-container">
            <div className="status-title-container">
              <div className="status-container">
                <Chip
                  text={getClassificationName(
                    theme.RisksClassifications.item.Items,
                    getClassification(dataItem),
                    locale,
                  )}
                  color={getClassificationColor(classification, getClassification(dataItem))}
                />
              </div>
              <div className="title-container">{dataItem.Title}</div>
            </div>
            <div className="risks-issues-description">{dataItem.Description}</div>
          </div>
        ),
        Header: intl.formatMessage({ id: 'projectrisk.title' }),
        id: 'title',
        width: 400,
      },

      {
        accessor: dataItem => (
          <div className="risks-issues-pop-up-title-container">
            <div className="response-container">{dataItem.MitigationPlan}</div>
          </div>
        ),
        Header: intl.formatMessage({ id: 'projectrisk.mitigation-plan' }),
        id: 'response',
      },

      {
        accessor: dataItem =>
          intl.formatMessage({
            id: `statuses.${getImpactString(parseInt(dataItem.Impact, 10))}`,
          }),
        Header: intl.formatMessage({ id: 'project-issues.impact' }),
        maxWidth: 100,
        id: 'impact',
      },

      {
        accessor: dataItem =>
          intl.formatMessage({
            id: `statuses.${getProbabiltyString(parseFloat(dataItem.Probability))}`,
          }),
        Header: intl.formatMessage({ id: 'projectrisks-details.probability' }),
        maxWidth: 100,
        id: 'probability',
      },

      {
        accessor: dataItem => dataItem.Owner,
        Header: intl.formatMessage({ id: 'projectrisk.administrator' }),
        maxWidth: 150,
        id: 'administrator',
      },

      {
        accessor: dataItem => intl.formatMessage({ id: `common.${getType(dataItem).toLowerCase()}` }),
        Header: intl.formatMessage({ id: 'common.type' }),
        maxWidth: 100,
        id: 'type',
      },
      {
        accessor: dataItem => <div className="level-name">{getName(dataItem, getType(dataItem))}</div>,
        Header: intl.formatMessage({ id: 'common.level-name' }),
        id: 'levelName',
      },
    ];
  };

  const getIssuesColumns = () => {
    return [
      {
        accessor: dataItem => (
          <div className="risks-issues-pop-up-title-container">
            <div className="status-title-container">
              <div className="status-container">
                <Chip
                  text={getClassificationName(
                    theme.IssuesClassifications.item.Items,
                    getIssueClassification(dataItem),
                    locale,
                  )}
                  color={getClassificationColor(
                    theme.IssuesClassifications.item.Items,
                    getIssueClassification(dataItem),
                  )}
                />
              </div>
              <div className="title-container">{dataItem.Title}</div>
            </div>
            <div className="risks-issues-description">{dataItem.Discussion}</div>
          </div>
        ),
        Header: intl.formatMessage({ id: 'projectrisk.title' }),
        id: 'title',
        width: 400,
      },

      {
        accessor: dataItem => (
          <div className="risks-issues-pop-up-title-container">
            <div className="response-container">{dataItem.Resolution}</div>
          </div>
        ),
        Header: intl.formatMessage({ id: 'project-issues-add.resolution-plan-label' }),
        id: 'response',
        width: 150,
      },

      {
        accessor: dataItem =>
          intl.formatMessage({
            id: `statuses.${dataItem.Priority.split(' ')[dataItem.Priority.split(' ').length - 1].toLowerCase()}`,
          }),
        Header: intl.formatMessage({ id: 'project-issues.impact' }),
        id: 'impact',
      },

      {
        accessor: dataItem => dataItem.Owner,
        Header: intl.formatMessage({ id: 'projectrisk.administrator' }),
        id: 'administrator',
      },
      {
        accessor: dataItem => intl.formatMessage({ id: `common.${getType(dataItem).toLowerCase()}` }),
        Header: intl.formatMessage({ id: 'common.type' }),
        id: 'type',
      },

      {
        accessor: dataItem => getName(dataItem, getType(dataItem)),
        Header: intl.formatMessage({ id: 'common.level-name' }),
        id: 'levelName',
      },
    ];
  };
  return (
    <div className="risks-issues-pop-up">
      <Modal
        title={intl.formatMessage({ id: type === 'risks' ? 'common.risks' : 'common.issues' })}
        isOpen
        onClose={() => onClose()}
        withoutPadding
        dashBoardPopUp
      >
        <div className="risks-issues-pop-up-table">
          <Table
            columns={type === 'risks' ? getRisksColumns() : getIssuesColumns()}
            data={data}
            showPagination={false}
            sortable={false}
            numOfLoadingRows={3}
            pageSize={data.length}
            headerBackgroundColor={theme.ThemeColor.item.Text}
            withoutBorder
          />
        </div>
      </Modal>
    </div>
  );
}

export default RisksIssuesPopUp;
