import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import ProcessesProjectInfo from '../ProcessesProjectInfo';
import Deliverables from '../DeliverablesAcceptanceList';
import { ProcessesTextArea, ProcessesTextField } from '../ProcessesTextArea';
import CheckBox from '../Field/Control/CheckBox';
import Evaluation from './Evaluation';
import ProcessesAttachments from '../ProcessesAttachments';

import './style.scss';

function ProjectClosureForm({
  project,
  saveData,
  request,
  processType,
  readOnly,
  intl,
  themeColor,
  showDeliverablesHint,
}) {
  function handleAttachments(files) {
    saveData({ Attachments: files });
  }

  function handleCheckbox() {
    saveData({ IsClose: !request.Data.IsClose });
  }

  return (
    <>
      <ProcessesProjectInfo request={request} project={project} processType={processType} />
      <div className="processes-project-info">
        <ProcessesTextField
          title={intl.formatMessage({ id: 'change-request.arabic-title' })}
          classes="half rtl"
          name="TitleArabic"
          data={request.Data.TitleArabic}
          onSave={saveData}
          readOnly={readOnly}
          required
        />
        <ProcessesTextField
          title={intl.formatMessage({ id: 'change-request.english-title' })}
          classes="half ltr"
          name="TitleEnglish"
          data={request.Data.TitleEnglish}
          onSave={saveData}
          readOnly={readOnly}
          required
        />
      </div>
      <Deliverables
        data={request.Data.Deliverables}
        saveData={saveData}
        readOnly={readOnly}
        showCheckbox={false}
        showDeliverablesHint={showDeliverablesHint}
      />

      <ProcessesTextArea
        title="common.lessons-learned"
        data={request.Data.LessonsLearned}
        name="LessonsLearned"
        onSave={saveData}
        readOnly={readOnly}
        titleColor={themeColor}
        required
      />
      <div className="project-closure-form__checkbox">
        <CheckBox
          label={intl.formatMessage({ id: 'common.end-project' })}
          checked={request.Data.IsClose}
          onChangeCheckBox={handleCheckbox}
          disabled={readOnly}
          vibrant
        />
      </div>
      <ProcessesTextArea
        title="common.notes"
        data={request.Data.Notes}
        name="Notes"
        onSave={saveData}
        readOnly={readOnly}
      />
      <Evaluation project={project} request={request} saveData={saveData} readOnly={readOnly} />
      <ProcessesAttachments attachments={request.Data.Attachments} onChange={handleAttachments} readOnly={readOnly} />
    </>
  );
}

const mapStateToProps = ({ theme }) => ({
  themeColor: theme.ThemeColor.item.Text,
});

export default injectIntl(connect(mapStateToProps)(ProjectClosureForm));
