import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import Table from '../Table';
import ProgressBar from '../ProgressBar';

import { getProjectLocalizedName, getPhaseName, formattedDate, nFormatter, getStatusColor } from '../../Helpers';

function Projects({ projects = [], intl, locale, theme, match, history }) {
  function onRowClick(rowData) {
    const { initiativeCode, programCode } = match.params;
    const { ProjectUID } = rowData.original;

    history.push(`/dashboard/initiatives/${initiativeCode}/${programCode}/${ProjectUID}`);
  }
  const columns = [
    {
      id: 'projectName',
      accessor: data => getProjectLocalizedName(data, locale),
      Header: intl.formatMessage({ id: 'dashboard.project-name' }),
    },
    {
      id: 'startDate',
      accessor: data => formattedDate(data.StartDate),
      Header: intl.formatMessage({ id: 'common.startDate' }),
    },
    {
      id: 'finishDate',
      accessor: data => formattedDate(data.FinishDate),
      Header: intl.formatMessage({ id: 'common.finishDate' }),
    },
    {
      id: 'projectPhase',
      accessor: data => getPhaseName(theme.Project_Phases.item.Items, data.ProjectPhase, locale),
      Header: intl.formatMessage({ id: 'project.details-project-phase' }),
    },
    {
      id: 'budget',
      accessor: data => nFormatter(data.Budget, 1, locale),
      Header: intl.formatMessage({ id: 'pdb.budget' }),
    },
    {
      id: 'spent',
      accessor: data => nFormatter(data.Consumed, 1, locale),
      Header: intl.formatMessage({ id: 'dashboard.spent' }),
    },
    {
      id: 'projectManager',
      accessor: data => data.Manager?.DisplayName,
      Header: intl.formatMessage({ id: 'common.projectManager' }),
    },
    {
      accessor: 'Progress',
      Header: intl.formatMessage({ id: 'dashboard.progress' }),
      Cell: data => (
        <ProgressBar
          asideNumber={`${data.original.Progress}%`}
          firstLineValue={data.original.Progress}
          firstLineColor={getStatusColor(theme, data.original.Status)}
        />
      ),
    },
  ];

  return (
    <div className="initiative-details__projects">
      <div className="projects-title">
        <FormattedMessage id="dashboard.projects-list" />
      </div>
      <div className="projects-list">
        <Table
          columns={columns}
          data={projects}
          onRowClick={onRowClick}
          numOfLoadingRows={3}
          showPagination={false}
          sortable={false}
        />
      </div>
    </div>
  );
}

const mapStateToProps = ({ language, theme }) => ({
  locale: language.locale,
  theme,
});

export default injectIntl(connect(mapStateToProps)(withRouter(Projects)));
