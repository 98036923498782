import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Modal from '../Modal';
import Table from '../Table';
import './style.scss';
import { formattedDate } from '../../Helpers';

function DetailsModal({ toggleModal, item, projectTasks, nonProjectTasks, day, themeColor, intl, locale }) {
  const projectTasksColumns = projectsTasks => {
    let projectsTasksData = [];
    if (projectsTasks) {
      projectsTasksData = [
        {
          accessor: data => (locale === 'ar' ? data.ProjectnameArabic : data.Projectname),
          Header: intl.formatMessage({ id: 'common.projectTitleAr' }),
          id: 'Title',
        },
      ];
    }
    return projectsTasksData.concat([
      {
        accessor: data => data.Title,
        Header: intl.formatMessage({ id: 'common.task' }),
        id: 'task',
      },

      {
        accessor: data => formattedDate(data.StartDate),
        Header: intl.formatMessage({ id: 'common.startDate' }),
        id: 'StartDate',
      },
      {
        accessor: data => formattedDate(data.FinishDate),
        Header: intl.formatMessage({ id: 'common.finishDate' }),
        id: 'FinishDate',
      },
      {
        accessor: data => formattedDate(data.FinishDate),
        Header: intl.formatMessage({ id: 'common.finishDate' }),
        id: 'FinishDate',
      },

      {
        accessor: data => data.Progress,
        Header: intl.formatMessage({ id: 'dashboard.progress' }),
        id: 'Progress',
      },
    ]);
  };

  return (
    <Modal
      title={`${intl.formatMessage({ id: 'project-tasks.title' })} - ${item.DisplayName} - ${moment(day).format(
        'll',
      )}`}
      isOpen
      onClose={() => toggleModal()}
      dashBoardPopUp
      width="90%"
    >
      <div className="capacity-planner-DetailsModal-modal">
        <div className="text-container">
          <span className="capacity-planner-label flex-v-center " style={{ backgroundColor: themeColor }}>
            <FormattedMessage id="tasksCenter.project-tasks" />
          </span>
        </div>
        <div className="capacity-planner-text">
          <Table
            columns={projectTasksColumns(true)}
            data={projectTasks}
            showPagination={false}
            sortable={false}
            numOfLoadingRows={3}
            pageSize={projectTasks?.length}
            headerBackgroundColor={themeColor}
            rowBorderColor="transparent"
          />
        </div>

        <div className="text-container">
          <span className="capacity-planner-label flex-v-center " style={{ backgroundColor: themeColor }}>
            <FormattedMessage id="common.other-tasks" />
          </span>
        </div>
        <div className="capacity-planner-text">
          <Table
            columns={projectTasksColumns()}
            data={nonProjectTasks}
            showPagination={false}
            sortable={false}
            numOfLoadingRows={3}
            pageSize={nonProjectTasks?.length}
            headerBackgroundColor={themeColor}
            rowBorderColor="transparent"
          />
        </div>
      </div>
    </Modal>
  );
}

export default DetailsModal;
