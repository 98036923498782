import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';
import enData from 'react-intl/locale-data/en';
import arData from 'react-intl/locale-data/ar';
import en from '../../localization/en';
import ar from '../../localization/ar';

addLocaleData([...enData, ...arData]);

function mapStateToProps(state) {
  const { locale } = state.language;
  const messages = locale === 'en' ? en : ar;
  return { locale, messages };
}

export default connect(mapStateToProps)(IntlProvider);
