import React from 'react';
import './style.scss';

export default () => (
  <div className="ProjectPreview__container animated-background">
    <div className="ProjectPreview__content">
      <div className="section-one">
        <div className="ProjectPreview__content-status flex-h-v-center">
          <p className="ProjectPreview__details-item-title shadow-background" />
        </div>
      </div>
      <div className="section-two">
        <div className="ProjectPreview__content-status flex-h-v-center">
          <div className="shadow-background" />
        </div>
        <div className="ProjectPreview__content-status flex-h-v-center">
          <div className="shadow-background" />
        </div>
      </div>
    </div>

    <div className="ProjectPreview__details">
      <div className="ProjectPreview__details-row">
        <div className="ProjectPreview__details-item">
          <p className="ProjectPreview__details-item-title shadow-background" />
          <p className="ProjectPreview__details-item-title shadow-background" />
          <p className="ProjectPreview__details-item-title shadow-background" />
          <p className="ProjectPreview__details-item-title shadow-background" />
          <p className="ProjectPreview__details-item-title shadow-background" />
        </div>

        <div className="ProjectPreview__details-item">
          <p className="ProjectPreview__details-item-title shadow-background" />
          <p className="ProjectPreview__details-item-title shadow-background" />
          <p className="ProjectPreview__details-item-title shadow-background" />
          <p className="ProjectPreview__details-item-title shadow-background" />
          <p className="ProjectPreview__details-item-title shadow-background" />
        </div>
      </div>
    </div>
  </div>
);
