import React from 'react';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Modal from '../Modal';
import Button from '../Button';
import TitledContent from '../TitledContent';
import Slider from '../Slider';
import Field from '../Field';
import { postUpdatedTasks } from '../../actions/tasksCenter';
import {
  encodeAttachments,
  getTaskStatus,
  getTaskClassification,
  taskClassificationName,
  taskClassificationColor,
  addToast,
} from '../../Helpers';
import './styles.scss';

class ModifyTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveDisabled: true,
      submitting: false,
      percentage: props.task.PercentComplete,
      attachments: props.task.Attachments || [],
    };
  }

  handlePercentageChange = val => {
    this.setState({
      percentage: val,
      saveDisabled: false,
    });
  };

  handleSave = async () => {
    const {
      state: { percentage, attachments },
      props: { task, postUpdatedTasksAction, onClose, updateData, index, updateStatus },
    } = this;
    if (updateData) {
      updateData(percentage, attachments, index);
    }
    if (updateStatus) {
      updateStatus();
    }

    this.setState({
      submitting: true,
      saveDisabled: true,
    });

    let _attachments = attachments.reduce(
      (accum, attachment) => {
        if (attachment instanceof File) {
          accum.newFiles.push(attachment);
        } else {
          accum.oldFiles.push({
            ...attachment,
            UID: 'some-unique-id',
          });
        }
        return accum;
      },
      { newFiles: [], oldFiles: [] },
    );

    let newFiles = await encodeAttachments(_attachments.newFiles);
    newFiles = newFiles.map((base64Attachment, i) => {
      return {
        AttachmentBase64: base64Attachment.replace(/^data:(.*,)?/, ''),
        AttachmentDownloadURL: '',
        Base64Data: base64Attachment,
        FileType: 'text/plain',
        ID: 0,
        Name: _attachments.newFiles[i].name,
        Type: 'text/plain',
        URL: base64Attachment,
        isDeleted: false,
      };
    });

    _attachments = [...newFiles, ..._attachments.oldFiles];

    const body = {
      data: [
        {
          ...task,
          Attachments: _attachments,
          PercentComplete: percentage,
          StatusID: 2, // pending
        },
      ],
    };
    postUpdatedTasksAction(body, () => {
      this.setState({
        submitting: false,
      });

      addToast.success('common.changesHaveBeenSaved');
      onClose();
    });
  };

  render() {
    const {
      props: { isOpen, onClose, task, intl, theme, taskApprovalStatuses, locale, tasksClassifications },
      state: { attachments, saveDisabled, percentage, submitting },
    } = this;
    if (!isOpen) return null;

    const statusText = taskClassificationName(tasksClassifications, getTaskClassification(task), locale);
    const statusColor = taskClassificationColor(tasksClassifications, getTaskClassification(task));
    const themeColor = theme.ThemeColor.item.Text;
    const lastChangeStatus = getTaskStatus(taskApprovalStatuses, task.StatusID, locale);

    return (
      <div>
        <Modal title={intl.formatMessage({ id: 'common.details' })} isOpen={isOpen} onClose={onClose}>
          <div className={`modifyTaskModal__content${submitting ? ' loading' : ''}`}>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'common.title' })}>
                <span className="modifyTaskModal__dataSpan">{task.Title}</span>
              </TitledContent>
            </div>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'common.startDate' })}>
                <span className="modifyTaskModal__dataSpan">{moment(task.StartDate).format('DD-MM-YYYY')}</span>
              </TitledContent>
              <TitledContent title={intl.formatMessage({ id: 'common.finishDate' })}>
                <span className="modifyTaskModal__dataSpan">{moment(task.FinishDate).format('DD-MM-YYYY')}</span>
              </TitledContent>
              <TitledContent title={intl.formatMessage({ id: 'common.status' })}>
                <span className="modifyTaskModal__dataSpan" style={{ color: statusColor, fontWeight: 600 }}>
                  {statusText}
                </span>
              </TitledContent>
            </div>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'task.taskActualCompletion' })}>
                <span className="modifyTaskModal__dataSpan">{task.OriginalPercentComplete}%</span>
              </TitledContent>
              <TitledContent title={intl.formatMessage({ id: 'task.lastChangeStatus' })}>
                <span className="modifyTaskModal__dataSpan" style={{ color: lastChangeStatus.style, fontWeight: 600 }}>
                  {lastChangeStatus.name}
                </span>
              </TitledContent>
              <TitledContent title={intl.formatMessage({ id: 'common.completion' })}>
                <div className="modifyTaskModal__slider-wrapper">
                  <Slider
                    value={percentage}
                    onChangePercent={e => this.handlePercentageChange(e)}
                    showPercentage={false}
                    width="260px"
                    theme={theme}
                    item={task}
                    miniPercentage
                  />
                </div>
              </TitledContent>
            </div>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'common.expectedDate' })}>
                <span className="modifyTaskModal__dataSpan">
                  {task.ExpectedDate
                    ? moment(task.ExpectedDate).format('DD-MM-YYYY')
                    : intl.formatMessage({ id: 'common.not-available' })}
                </span>
              </TitledContent>
              <TitledContent title={intl.formatMessage({ id: 'common.statusManager' })}>
                <span className="modifyTaskModal__dataSpan">{task.StatusManager.DisplayName}</span>
              </TitledContent>
            </div>
            <div className="modifyTaskModal__row">
              <TitledContent title={intl.formatMessage({ id: 'common.attachments' })}>
                <Field
                  control={{
                    type: 'attachment',
                    onChangeAction: e => {
                      this.setState({ saveDisabled: false, attachments: [...e] });
                    },
                    value: attachments,
                    // validation: this.inputValidation,
                    validateOnExite: false,
                  }}
                />
              </TitledContent>
            </div>
            <div className="modifyTaskModal__row modifyTaskModal__control">
              <Button type="button" className="modifyTaskModal__cancelButton" onClick={onClose} disabled={submitting}>
                {intl.formatMessage({ id: 'common.cancel' })}
              </Button>
              <Button
                className={submitting ? ' loading' : ''}
                type="button"
                disabled={saveDisabled}
                onClick={this.handleSave}
                loading={submitting}
                backgroundColor={themeColor}
              >
                {intl.formatMessage({ id: 'common.save' })}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { postUpdatedTasksAction: postUpdatedTasks })(injectIntl(ModifyTaskModal));
