import React from 'react';
import { FormattedMessage } from 'react-intl';
import Table from '../../../../components/Table';
import CheckBox from '../../../../components/Field/Control/CheckBox';

const IDTable = ({ type, list = [], isLoading, formData, setFormData, preview = false }) => {
  const onChangeCheckBox = (risk, isChecked) => {
    if (isChecked) {
      setFormData({ ...formData, [type]: formData[type].filter(item => item.ItemID !== risk.ID) });
    } else {
      setFormData({ ...formData, [type]: [...formData[type], { ItemID: risk.ID, ItemDescription: risk.Title }] });
    }
  };

  const getColumns = () => {
    return [
      {
        accessor: data => (
          <div className="id-table-row">
            {!preview ? (
              <CheckBox
                checked={formData[type].map(item => item.ItemID).includes(data.ID)}
                id={data}
                onChangeCheckBox={(id, isChecked) => onChangeCheckBox(id, isChecked)}
              />
            ) : null}
            {preview ? data.ItemDescription : data.Title}
          </div>
        ),
        Header: <FormattedMessage id={`portfolio.${type.toLowerCase()}`} />,
        id: 'Title',
      },
    ];
  };
  return (
    <Table
      rowBorderColor="transparent"
      columns={getColumns()}
      data={list}
      showPagination={false}
      sortable={false}
      isLoading={isLoading}
      numOfLoadingRows={3}
    />
  );
};

export default IDTable;
