import React from 'react';
import { connect } from 'react-redux';
import { getStatusName } from '../../../Helpers';
import './style.scss';

function Lookup(props) {
  const { data, locale, theme } = props;
  const lookup = data.Style.split(',')[1];

  return (
    <div className="text-info-item">
      <div className="text-info-item__title bold-text">{locale === 'ar' ? data.Arabic : data.English}</div>
      <span className="text-info-item__value text-gray">
        {data.itemData ? getStatusName(theme[lookup].item.Items, data.itemData, locale) : '---'}
      </span>
    </div>
  );
}

const mapStateToProps = ({ theme }) => ({
  theme,
});

export default connect(mapStateToProps)(Lookup);
