import React, { Component } from 'react';
import moment from 'moment';
import { injectIntl, FormattedMessage } from 'react-intl';
import Table from '../../Table';
import Button from '../../Button';
import { ReactComponent as BackIcon } from '../../../assets/images/back-arrow.svg';
import { ReactComponent as AttachmentIcon } from '../../../assets/images/attachment.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock.svg';
import { ReactComponent as PowerIcon } from '../../../assets/images/power.svg';
import { ReactComponent as EscalationIcon } from '../../../assets/images/esc.svg';
import { ReactComponent as ConvertIcon } from '../../../assets/images/convert.svg';
// import { ReactComponent as ConvertIcon } from '../../../assets/images/convert.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import AttachmentsList from '../../AttachmentsList';
import RiskIssueModalLoading from '../../CommonLoadingComponents/RiskIssueModalLoading';
import ProjectAPIController from '../../../providers/controllers/ProjectAPIController';
import {
  getClassification,
  getClassificationColor,
  getClassificationName,
  getImpactString,
  getProbabiltyString,
  risksConstants,
  getCategoryText,
  getIsClosed,
  formattedDate,
} from '../../../Helpers';

import './style.scss';
import { ReportsUrl } from '../../../providers/config/urlServiceConfig';

class ProjectRisksDetails extends Component {
  state = {
    isSubmitting: false,
    detailsData: null,
    detailsLoading: false,
    escalationData: null,
    isTransferring: false,
  };

  componentDidMount() {
    this.getRiskByID();
  }

  getColumns = () => {
    const { intl } = this.props;

    return [
      {
        accessor: data => <p title={data.Description}>{data.Description}</p>, // String-based value accessors!
        Header: intl.formatMessage({ id: 'common.description' }),
        id: 'Description',
      },

      {
        accessor: data => data.EscalatedByUserModel.DisplayName,
        Header: intl.formatMessage({ id: 'common.escalated-by' }),
        id: 'DisplayName',
      },
      {
        accessor: data => formattedDate(data.OpenDate),
        Header: intl.formatMessage({ id: 'project-details.escalation-date' }),
        id: 'OpenDate',
        width: 200,
      },
    ];
  };

  transferRisk = () => {
    const { match } = this.props;

    this.setState(
      {
        isTransferring: true,
      },
      () => {
        new ProjectAPIController()
          .transferRisk(match.params.code, match.params.id)
          .then(res => {
            const { StatusCode } = res;
            if (StatusCode === 'Success') {
              const { onCloseRisk } = this.props;
              onCloseRisk();
            }
          })
          .catch(err => {
            return err;
          })
          .finally(() => {
            this.setState({ isTransferring: false });
          });
      },
    );
  };

  closeRisk = () => {
    const { match, risksList } = this.props;

    this.setState({
      isSubmitting: true,
    });

    const riskDetails = risksList.find(risk => risk.ID === parseInt(match.params.id, 10));

    const data = {
      projectUID: match.params.code,
      risk: {
        ...riskDetails,
        AssignedTo: riskDetails.AssignedTo.Username,
        DueDate: moment(riskDetails.DueDate)
          .locale('en')
          .format('DD-MM-YYYY'),
        Status: risksConstants.statusClosed,
      },
    };
    new ProjectAPIController()
      .addRisk(data)
      .then(res => {
        const { StatusCode } = res;
        if (StatusCode === 'Success') {
          const { onCloseRisk } = this.props;
          onCloseRisk();
        }
        this.setState({
          isSubmitting: false,
        });
      })
      .catch(err => {
        this.setState({
          isSubmitting: false,
        });
        return err;
      });
  };

  getRiskByID = () => {
    const { match, changeView, details } = this.props;

    this.setState({ detailsLoading: true }, () => {
      new ProjectAPIController()
        .getRiskByID(details ? details.ProjectUID : match.params.code, details ? details.ID : match.params.id)
        .then(res => {
          if (!res.Data || (res.Data.UniqueID === '00000000-0000-0000-0000-000000000000' && !details)) {
            changeView('base');
          } else {
            if (window.env.ESCALATION && res.Data.IsEscalated && !details) {
              this.getEscalations();
            }
            this.setState({ detailsLoading: details ? false : res.Data.IsEscalated, detailsData: res.Data });
          }
        });
    });
  };

  getEscalations = () => {
    const { match } = this.props;
    new ProjectAPIController().getAllEscalation(match.params.code, 'Risks', match.params.id).then(res => {
      this.setState({ detailsLoading: false, escalationData: res.Data });
    });
  };

  render() {
    const {
      changeView,
      theme,
      locale,
      intl,
      modalView,
      wrapperClassname = '',
      user,
      projectDetails,
      details,
      match,
    } = this.props;
    const { isSubmitting, detailsLoading, detailsData, escalationData, isTransferring } = this.state;
    if (detailsLoading || !detailsData) return <RiskIssueModalLoading />;
    const riskDetails = detailsData || details;
    const Attachments = riskDetails.Attachments ? riskDetails.Attachments : [];
    const isProject = match?.url?.includes('project');
    const isClosed = getIsClosed(riskDetails);
    const RiskInfo = locale === 'en' ? 'PPlusRiskInfo-EN' : 'PPlusRiskInfo-AR';
    const isManager = projectDetails && projectDetails.ProjectManager.Username === user.user.Username;
    return (
      <section className={`project-risks-details-container ${wrapperClassname} ${isSubmitting ? 'loading' : ''}`}>
        {!modalView ? (
          <div className="sec-header-container tab-header">
            <div className="back-button colorBlue cursor" onClick={() => changeView('base')}>
              <BackIcon width="15px" height="15px" fill={theme.ThemeColor.item.Text} />
            </div>
            <div className="title bold-text">
              <FormattedMessage id="projectrisks-details.risk-details" />
            </div>
            <div className="section-actions">
              {ReportsUrl ? (
                <a
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${ReportsUrl}${RiskInfo}&rs:Command=Render&RiskID=${riskDetails.UniqueID}&LoginUserName=${user.user.Username}&rs:Format=PDF`}
                  className="action report-link"
                  style={{ background: theme.ThemeColor.item.Text }}
                >
                  <FormattedMessage id="projectrisk.risk-info-report" />
                </a>
              ) : null}
              {!isClosed && !riskDetails.IsTransferred ? (
                <>
                  <Button
                    className="action secondary-action"
                    type="button"
                    color={theme.ThemeColor.item.Text}
                    backgroundColor="white"
                    onClick={() => this.closeRisk()}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    Icon={() => <PowerIcon width="11px" height="11px" fill={theme.ThemeColor.item.Text} />}
                    border
                  >
                    <FormattedMessage id="projectrisks-details.close-risks" />
                  </Button>
                  <Button
                    type="button"
                    style={{ background: theme.ThemeColor.item.Text }}
                    className="action"
                    loading={isTransferring}
                    onClick={() => this.transferRisk()}
                  >
                    <ConvertIcon className="action-icon" width="11px" height="11px" />
                    <FormattedMessage id="project-details.transfer-to-issue" />
                  </Button>
                </>
              ) : null}
              {window.env.ESCALATION && isProject && !isClosed && !riskDetails.IsTransferred && isManager && (
                <Button type="button" className="action" onClick={() => changeView('escalations', '', riskDetails.ID)}>
                  <EscalationIcon className="action-icon" width="11px" height="11px" />
                  <FormattedMessage id="project-details.escalation" />
                </Button>
              )}

              {!isClosed && !riskDetails.IsTransferred ? (
                <Button
                  className="action"
                  type="button"
                  backgroundColor={theme.ThemeColor.item.Text}
                  Icon={() => <EditIcon className="action-icon" width="11px" stroke="#fff" fill="#fff" height="11px" />}
                  onClick={() => changeView('edit', riskDetails.ID)}
                >
                  <FormattedMessage id="projectrisks-details.edit" />
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="tTab-content-container">
          <div className="main-content">
            <div className="list-container">
              <div className="list-item">
                <span
                  className="label"
                  style={{
                    background: getClassificationColor(
                      theme.RisksClassifications.item.Items,
                      getClassification(riskDetails),
                    ),
                  }}
                >
                  {getClassificationName(theme.RisksClassifications.item.Items, getClassification(riskDetails), locale)}
                </span>
                <div className="item-title">
                  <p>{riskDetails.Title}</p>
                  {Attachments.length ? (
                    <span className="attachment text-blue">
                      <AttachmentIcon fill={theme.ThemeColor.item.Text} />
                    </span>
                  ) : null}
                  {riskDetails.IsEscalated ? (
                    <span className="attachment text-blue">
                      <EscalationIcon width="13px" height="13px" fill={theme.ThemeColor.item.Text} />
                    </span>
                  ) : null}
                </div>
                <div className="time">
                  <ClockIcon width="13px" height="13px" fill="#A3A3A3" />
                  <span>{moment(riskDetails.LastUpdated).fromNow()}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="item-info">
            {Attachments.length ? (
              <div className="sub-section">
                <AttachmentsList attachments={Attachments} />
              </div>
            ) : null}
            <div className="sub-section">
              <div className="bold-text">
                <FormattedMessage id="projectrisks-details.description" />
              </div>
              <div className="colorGray small-text">{riskDetails.Description}</div>
              <div className="section-secodary-data">
                <div>
                  <div className="bold-text">
                    <FormattedMessage id="projectrisks-details.category" />
                  </div>
                  <div className="colorGray small-text">
                    <FormattedMessage id={`statuses.${getCategoryText(riskDetails.Category)}`} />
                  </div>
                </div>
                <div>
                  <div className="bold-text">
                    <FormattedMessage id="projectrisks-details.added-by" />
                  </div>
                  <div className="colorGray small-text">{riskDetails.Owner}</div>
                </div>
                <div>
                  <div className="bold-text">
                    <FormattedMessage id="projectrisks-details.impact" />
                  </div>
                  <div className="colorGray small-text">
                    {`${intl.formatMessage({
                      id: `statuses.${getImpactString(parseInt(riskDetails.Impact, 10))}`,
                    })} `}
                  </div>
                </div>
                <div>
                  <div className="bold-text">
                    <FormattedMessage id="projectrisks-details.probability" />
                  </div>
                  <div className="colorGray small-text">
                    {`${intl.formatMessage({
                      id: `statuses.${getProbabiltyString(parseFloat(riskDetails.Probability))}`,
                    })} `}
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-section">
              <div className="bold-text">
                <FormattedMessage id="projectrisks-details.mitigation-plan" />
              </div>
              <div className="colorGray small-text">{riskDetails.MitigationPlan || '---'}</div>
              <div className="section-secodary-data">
                <div>
                  <div className="bold-text">
                    <FormattedMessage id="projectrisks-details.due-date" />
                  </div>
                  <div className="colorGray small-text">{moment(riskDetails.DueDate).format('DD-MMM-YYYY')}</div>
                </div>
                <div>
                  <div className="bold-text">
                    <FormattedMessage id="common.assignedTo" />
                  </div>
                  <div className="colorGray small-text">
                    {riskDetails.AssignedTo && riskDetails.AssignedTo.DisplayName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {window.env.ESCALATION && !modalView && isProject && escalationData ? (
          <Table
            columns={this.getColumns()}
            data={escalationData}
            showPagination={false}
            sortable={false}
            rowBorderColor={theme.ThemeColor.item.Text}
            onRowClick={data => changeView('escalations', data.original.ID, riskDetails.ID)}
          />
        ) : null}
      </section>
    );
  }
}

export default injectIntl(ProjectRisksDetails);
